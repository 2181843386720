import React, { FC, Fragment, useMemo, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

import RestoreAction from '../restore-action'
import RestorePlayerModal from './restore-player-modal'
import ArchivalReasonPopover from './archival-reason-popover'

import AreaType from '../../../../components/area-type'
import InlineDate from '../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../components/table'

import { PlayerListFragment } from '../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../../utils/data-manipulation'

const ArchivedPlayersListTable: FC<TableDataProps<PlayerListFragment>> = ({
  data,
  ...props
}) => {
  const [isRestoreModalOpen, openRestoreModal] = useState<boolean>(false)
  const [
    selectedPlayer,
    setSelectedPlayer,
  ] = useState<PlayerListFragment | null>(null)

  const onCloseModal = () => {
    openRestoreModal(false)
    setSelectedPlayer(null)
  }

  const onSelectPlayer = (player: PlayerListFragment) => {
    setSelectedPlayer(player)
    openRestoreModal(true)
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((player) => ({
        info: {
          content: (
            <ArchivalReasonPopover reason={player.archived?.why || ''} />
          ),
        },
        name: {
          value: player?.name || '',
          content: player?.name || '',
        },
        orientation: {
          value: player.settings?.orientation,
          content: player.settings?.orientation,
        },
        areaType: {
          content: <AreaType areaTypeId={player.demographics?.area_type_id} />,
        },
        createdAt: {
          value: player.created?.at,
          content: <InlineDate date={player.created?.at} />,
        },
        archivedAt: {
          value: player.archived?.at,
          content: <InlineDate date={player.archived?.at} />,
        },
        restore: {
          content: <RestoreAction onClick={() => onSelectPlayer(player)} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Player"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.players_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'info', width: 70 },
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'orientation',
            label: 'Orientation',
            isSortable: true,
            alignment: 'center',
            width: 100,
          },
          { key: 'areaType', label: 'Area Type', width: 100 },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'archivedAt',
            label: 'Archived At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'restore', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RestorePlayerModal
        open={isRestoreModalOpen}
        player={selectedPlayer || undefined}
        onClose={onCloseModal}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default ArchivedPlayersListTable
