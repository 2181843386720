import {
  split,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import axios from 'axios'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { fromPromise, toPromise, Operation, NextLink } from 'apollo-link'

import history from '../history'
import { API_GRAPHQL_URL } from '../config'

import { authentication } from '../stores/authentication'
import { alertsManager, AlertVariant } from '../stores/alerts-manager'

export function getHeaders(origHeaders: any = {}) {
  const headers: any = { ...origHeaders }

  if (authentication.access_token) {
    headers.authorization = `Bearer ${authentication.access_token}`
  }

  if (authentication.selected_workspace_id) {
    headers['X-Workspace'] = authentication.selected_workspace_id
  }

  return headers
}

export const subscriptionClient = new SubscriptionClient(
  API_GRAPHQL_URL.replace(/^http/, 'ws'),
  {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      if (!authentication.is_authenticated)
        throw new Error('Not authenticated!')
      return { headers: getHeaders() }
    },
  }
)

const httpLink = createHttpLink({
  uri: API_GRAPHQL_URL,
})

export const wsLink = new WebSocketLink(subscriptionClient)

const authLink = setContext(({ context }, prevContext) => ({
  ...context,
  headers: getHeaders(prevContext.headers),
}))

const errorLink = onError(({ operation, graphQLErrors, forward }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      if (
        typeof graphQLError === 'object' &&
        typeof graphQLError.message === 'string' &&
        graphQLError.message.includes('Not Authorised')
      ) {
        return refreshTokenObserver(operation, forward)
      }
    }
  }
})

function refreshTokenObserver(operation: Operation, forward: NextLink) {
  return fromPromise(
    (async () => {
      try {
        // refresh the token
        await authentication.memRefreshAuthToken()
      } catch (err) {
        console.warn(`Logging out due to: ${err?.message}`)

        // in case we have an issue refreshing the token, logout and throw the error
        await authentication.logout(false)
        alertsManager.emit({
          dismissable: true,
          id: 'expired-session-alert',
          variant: AlertVariant.ERROR,
          message: `Session expired. Please login again.`,
        })

        history.push('/')
        return {}
      }
      operation.setContext(getHeaders())
      return toPromise(forward(operation))
    })()
  )
}

const link = split(
  ({ query, getContext }) => {
    const context = getContext()
    if (typeof context?.headers?.['X-Recaptcha-Token'] === 'string') {
      return false
    }

    const definition = getMainDefinition(query)
    return (
      authentication.is_authenticated ||
      (definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription')
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

const apolloClient = new ApolloClient({
  link: ApolloLink.from([(errorLink as unknown) as ApolloLink, link]),
  cache: new InMemoryCache(),
})

export const axiosClient = axios.create({
  method: 'POST',
  url: API_GRAPHQL_URL,
})

export default apolloClient
