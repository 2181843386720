import React, { FC, useState } from 'react'

import 'twin.macro'
import { useParams } from 'react-router-dom'

import ButtonLink from '../../../../../ui-blocks/button-link'
import PaymentMethod, {
  PaymentMethodType,
} from '../../../../../ui-blocks/payment-method'

import Address from '../../../../../components/address'

import {
  ViewWorkspaceDocument,
  useWorkspaceRemovePaymentMethodMutation,
  useWorkspaceSetDefaultPaymentMethodMutation,
} from '../../../../../graphql/components'

export interface BillingInformationType {
  address?: {
    city?: string
    country?: string
    line1?: string
    postal_code?: string
    state?: string
  }
  name?: string
  phone?: string
  company: string
  vat?: string
}

export interface BillingInformationProps {
  paymentMethods: PaymentMethodType[]
  billingInformation: BillingInformationType
  defaultPaymentMethod: string
  refetchData?: () => Promise<any>
}

const BillingInformation: FC<BillingInformationProps> = ({
  paymentMethods,
  billingInformation,
  defaultPaymentMethod,
  refetchData,
}) => {
  const { workspace_id } = useParams()
  const [cardState, setCardState] = useState<{ [key: string]: boolean }>({})
  // const { line1, state, postal_code, country } = billingInformation.address

  const pms = paymentMethods.map((pm) => pm)
  // Make sure the first rendered card is the default one
  pms.sort((pm) => (pm.id === defaultPaymentMethod ? -1 : 0))

  const [
    setDefaultPaymentMethod,
  ] = useWorkspaceSetDefaultPaymentMethodMutation()
  const [removePaymentMethod] = useWorkspaceRemovePaymentMethodMutation()

  const setPrimary = async (id: string) => {
    setCardState({ ...cardState, [id]: true })
    await setDefaultPaymentMethod({
      variables: {
        paymentMethodId: id,
      },
      refetchQueries: [
        { query: ViewWorkspaceDocument, variables: { _id: workspace_id } },
      ],
    })
    setCardState({ ...cardState, [id]: false })
    refetchData?.()
  }

  const removePayment = async (id: string) => {
    setCardState({ ...cardState, [id]: true })
    await removePaymentMethod({
      variables: {
        paymentMethodId: id,
      },
      refetchQueries: [
        { query: ViewWorkspaceDocument, variables: { _id: workspace_id } },
      ],
    })
    setCardState({ ...cardState, [id]: false })
    refetchData?.()
  }

  return (
    <div>
      {pms.map((method, index) => (
        <PaymentMethod
          key={`payment-method#${index}`}
          paymentMethods={method}
          loading={cardState[method.id]}
          defaultPaymentMethod={defaultPaymentMethod}
          onSetPrimary={(id) => setPrimary(id)}
          onRemove={(id) => removePayment(id)}
        />
      ))}
      <ButtonLink
        secondary
        to={`/workspaces/${workspace_id}/settings/billing/payment-methods`}
      >
        Add payment method
      </ButtonLink>
      <hr tw="bg-platinum my-6" />
      <h2 tw="font-medium text-charcoal mb-6">
        Additional billing information
      </h2>
      <div tw="text-charcoal max-w-xl">
        <div tw="flex flex-row">
          <div tw="w-2/4 mb-2">
            {billingInformation.name && (
              <b tw="block">{billingInformation.name}</b>
            )}
            {billingInformation.company && (
              <b tw="block">{billingInformation.company}</b>
            )}
            {billingInformation.vat && (
              <span tw="block">{billingInformation.vat}</span>
            )}
          </div>
          {!!billingInformation.address && (
            <div tw="w-2/4">
              <Address
                address={{
                  street: billingInformation.address?.line1 || '',
                  state: billingInformation.address?.state || '',
                  city: billingInformation.address?.city || '',
                  zip: billingInformation.address?.postal_code || '',
                  country_id: billingInformation.address?.country || '',
                }}
              />
            </div>
          )}
        </div>
        <ButtonLink
          menu
          to={`/workspaces/${workspace_id}/settings/billing/payment-methods`}
          tw="p-0 underline"
        >
          Change billing information
        </ButtonLink>
      </div>
    </div>
  )
}

export default BillingInformation
