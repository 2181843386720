import React, { FC, useEffect, useState, useMemo } from 'react'

import { observer } from 'mobx-react'
import { Route as RRoute, Routes } from 'react-router-dom'
import { Router, Navigate, RouteProps } from 'react-router'

import { authentication } from './stores'

import MainLayout from './layouts/main'
import AuthLayout from './layouts/auth'
import AccountPanelLayout from './layouts/account-panel'
import WorkspacePanelLayout from './layouts/workspace-panel'

import AccountContacts from './pages/account/contacts'
import AccountSecurity from './pages/account/security'
import AccountPersonalInformation from './pages/account/personal-information'

import AuthSignInPage from './pages/auth/sign-in'
import AuthSignUpPage from './pages/auth/sign-up'
import AuthSignOutPage from './pages/auth/sign-out'
import NetworksManagementPage from './pages/auth/networks'
import AuthForgotPasswordPage from './pages/auth/forgot-password'
import EmailCodeVerification from './pages/auth/sign-up/verify-email'

import AuthWorkspacePendingInvites from './pages/auth/invites/auth-workspace-pending-invites'
import AccountWorkspacePendingInvites from './pages/auth/invites/account-pending-workspace-invites'

import ViewPlayerPage, {
  playerPageRoutes,
  PlayerPageTabKeys,
} from './pages/players/view'
import BindPlayerPage from './pages/players/bind'
import ListPlayersPage from './pages/players/list'

import ViewWorkspaceCampaignPage, {
  campaignPageRoutes,
  CampaignPageTabKeys,
} from './pages/campaigns/view'
import ViewCampaignAdGroupPage, {
  adGroupPageRoutes,
  AdGroupPageTabKeys,
} from './pages/campaigns/ad-groups/view'
import ViewExternalCampaignPage, {
  externalCampaignPageRoutes,
  ExternalCampaignPageTabKeys,
} from './pages/campaigns/view/external'
import ListCampaignsPage from './pages/campaigns/list'
import CreateCampaignPage from './pages/campaigns/create'
import ReviewExternalCampaignPage from './pages/campaigns/view/review'
import CreateCampaignAdGroupPage from './pages/campaigns/ad-groups/create'

import ViewAdCreativePage, {
  creativePageRoutes,
  CreativePageTabKeys,
} from './pages/creatives/view'
import ListAdCreativesPage from './pages/creatives/list'

import ViewNetworkPage, {
  networkPageRoutes,
  NetworkPageTabKeys,
} from './pages/networks/view'
import ListNetworksPage from './pages/networks/list'
import CreateNetworkPage from './pages/networks/create'

import ComponentsCatalogPage from './pages/dev/catalog'

import ArchivedItemsPage, {
  archivedItemsPageRoutes,
  ArchivedItemsPageTabKeys,
} from './pages/archived'

import WorkspaceCreatePage from './pages/workspaces/create'
import WorkspaceOverviewPage from './pages/workspaces/overview'
import WorkspaceSettingsDetailsPage from './pages/workspaces/settings/details'
import WorkspaceSettingsMembersPage from './pages/workspaces/settings/members'
import WorkspaceSettingsAddMemberPage from './pages/workspaces/settings/members/add'
import WorkspaceSettingsBillingPage from './pages/workspaces/settings/billing'
import WorkspaceSettingsPaymentMethodsPage from './pages/workspaces/settings/billing/payment-methods'
import WorkspaceSettingsWebhooksPage from './pages/workspaces/settings/webhooks'
import WorkspaceSettingsAddWebhookPage from './pages/workspaces/settings/webhooks/add'
import WorkspaceSettingsEditWebhookPage from './pages/workspaces/settings/webhooks/edit'

import NoInternetConnection from './components/no-internet-connection'

import { Update } from 'history'
import history from './history'
import { NODE_ENV } from './config'

import ScrollToTop from './utils/scroll-to-top'
import isValidUrl from './utils/is-valid-url'

const BrowserRouter: FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (_: Update, action: Update) => action,
    {
      action: history.action,
      location: history.location,
    }
  )

  React.useLayoutEffect(() => history.listen(dispatch), [history])

  return (
    <Router action={state.action} location={state.location} navigator={history}>
      <ScrollToTop />
      {children}
    </Router>
  )
}

export interface BrowserRouterProps {
  children?: React.ReactNode
  window?: Window
}

const AppRouter: FC = observer(() => {
  const [ready, setReady] = useState<boolean>(false)
  const [hasInternetConnection, setHasInternetConnection] = useState(true)

  const internetConnectionEventListener = () => {
    setHasInternetConnection(navigator.onLine)
  }

  useEffect(() => {
    if (!ready) {
      authentication.ready().then(() => {
        setReady(true)
      })
    }

    window.addEventListener('load', internetConnectionEventListener)
    window.addEventListener('online', internetConnectionEventListener)
    window.addEventListener('offline', internetConnectionEventListener)

    return () => {
      window.removeEventListener('load', internetConnectionEventListener)
      window.removeEventListener('online', internetConnectionEventListener)
      window.removeEventListener('offline', internetConnectionEventListener)
    }
  }, [])

  const isAuthenticated = useMemo(
    () => !!authentication && authentication.is_authenticated,
    [authentication, authentication.is_authenticated]
  )

  const defaultRoute = useMemo(() => {
    if (isAuthenticated) {
      if (
        !authentication.has_a_verified_email &&
        !location.pathname.includes('/auth/sign-up/verify-email')
      ) {
        return '/auth/sign-up/verify-email/0'
      }

      const searchParams = new URLSearchParams(location.search)
      if (searchParams.has('redirect_to')) {
        const redirectTo = searchParams.get('redirect_to')
        //protect against redirecting to other websites
        if (redirectTo && !isValidUrl(redirectTo)) {
          return redirectTo
        }
      }

      if (!authentication.workspaces.length) {
        return '/workspaces/create'
      }

      return `/workspaces/${authentication.selected_workspace?._id}/overview`
    }
    return '/auth/sign-in'
  }, [
    authentication,
    isAuthenticated,
    authentication.workspaces,
    authentication.has_a_verified_email,
    authentication.selected_workspace,
    authentication.hasInvites,
    authentication.user_data,
  ])

  if (!ready) {
    return null
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* @ts-ignore */}
        {(NODE_ENV === 'development' || NODE_ENV === 'next') && (
          <Route
            isAuthenticated={isAuthenticated}
            layout={MainLayout}
            component={ComponentsCatalogPage}
            path="/dev/components"
          />
        )}
        <Route
          isAuthenticated={isAuthenticated}
          publicOnly
          layout={AuthLayout}
          component={AuthSignInPage}
          path="/auth/sign-in"
        />
        <Route
          isAuthenticated={isAuthenticated}
          publicOnly
          layout={AuthLayout}
          component={AuthSignUpPage}
          path="/auth/sign-up"
        />
        <Route
          isAuthenticated={isAuthenticated}
          layout={AuthLayout}
          component={AuthForgotPasswordPage}
          path="/auth/forgot-password"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          layout={AuthLayout}
          component={EmailCodeVerification}
          path="/auth/sign-up/verify-email/:email_index"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          layout={AuthLayout}
          component={AuthWorkspacePendingInvites}
          path="/auth/pending-workspace-invites"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          layout={AuthLayout}
          component={AccountWorkspacePendingInvites}
          path="/account/pending-workspace-invites"
        />
        <Route
          isAuthenticated={isAuthenticated}
          layout={AuthLayout}
          component={NetworksManagementPage}
          path="/auth/networks/:invite_token"
        />
        <Route
          isAuthenticated={isAuthenticated}
          layout={isAuthenticated ? MainLayout : AuthLayout}
          component={AuthSignOutPage}
          path="/auth/sign-out"
        />

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={AccountPanelLayout}
          component={AccountPersonalInformation}
          path="/account/personal-information"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={AccountPanelLayout}
          component={AccountContacts}
          path="/account/contacts"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={AccountPanelLayout}
          component={AccountSecurity}
          path="/account/security"
        />
        <RRoute
          path="/account"
          element={<Navigate to="/account/personal-information" />}
        />

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={AuthLayout}
          component={WorkspaceCreatePage}
          path="/workspaces/create"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={WorkspaceOverviewPage}
          path="/workspaces/:workspace_id/overview"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsDetailsPage}
          path="/workspaces/:workspace_id/settings/details"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsMembersPage}
          path="/workspaces/:workspace_id/settings/members"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsAddMemberPage}
          path="/workspaces/:workspace_id/settings/members/add"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsBillingPage}
          path="/workspaces/:workspace_id/settings/billing"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsPaymentMethodsPage}
          path="/workspaces/:workspace_id/settings/billing/payment-methods"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsWebhooksPage}
          path="/workspaces/:workspace_id/settings/webhooks"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsAddWebhookPage}
          path="/workspaces/:workspace_id/settings/webhooks/add"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsEditWebhookPage}
          path="/workspaces/:workspace_id/settings/webhooks/:webhook_id/edit"
        />
        <RRoute
          path="/workspaces/:workspace_id/settings"
          element={<Navigate to="/workspaces/:workspace_id/settings/details" />}
        />

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={ListPlayersPage}
          path="/players"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={BindPlayerPage}
          path="/players/bind"
        />
        {Object.values(PlayerPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            component={ViewPlayerPage}
            isAuthenticated={isAuthenticated}
            showNoInternetConnection={!hasInternetConnection}
            path={`/players/:player_id/${playerPageRoutes[tabKey]}`}
          />
        ))}

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={ListCampaignsPage}
          path="/campaigns"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={CreateCampaignPage}
          path="/campaigns/create"
        />
        {Object.values(CampaignPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            isAuthenticated={isAuthenticated}
            component={ViewWorkspaceCampaignPage}
            showNoInternetConnection={!hasInternetConnection}
            path={`/campaigns/:campaign_id/${campaignPageRoutes[tabKey]}`}
          />
        ))}
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={CreateCampaignAdGroupPage}
          path="/campaigns/:campaign_id/ad-groups/create"
        />
        {Object.values(AdGroupPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            isAuthenticated={isAuthenticated}
            component={ViewCampaignAdGroupPage}
            showNoInternetConnection={!hasInternetConnection}
            path={`/campaigns/:campaign_id/ad-groups/:campaign_ad_group_id/${adGroupPageRoutes[tabKey]}`}
          />
        ))}
        {Object.values(ExternalCampaignPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            isAuthenticated={isAuthenticated}
            component={ViewExternalCampaignPage}
            showNoInternetConnection={!hasInternetConnection}
            path={`/campaigns/external/:campaign_id/:broadcast_target_id/${externalCampaignPageRoutes[tabKey]}`}
          />
        ))}

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={ReviewExternalCampaignPage}
          path="/review-campaign/:campaign_id/:network_id"
        />

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={ListAdCreativesPage}
          path="/creatives"
        />
        {Object.values(CreativePageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            component={ViewAdCreativePage}
            isAuthenticated={isAuthenticated}
            showNoInternetConnection={!hasInternetConnection}
            path={`/creatives/:creative_id/${creativePageRoutes[tabKey]}`}
          />
        ))}

        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={ListNetworksPage}
          path="/networks"
        />
        <Route
          isAuthenticated={isAuthenticated}
          shielded
          showNoInternetConnection={!hasInternetConnection}
          layout={MainLayout}
          component={CreateNetworkPage}
          path="/networks/create"
        />
        {Object.values(NetworkPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            component={ViewNetworkPage}
            isAuthenticated={isAuthenticated}
            showNoInternetConnection={!hasInternetConnection}
            path={`/networks/:network_id/${networkPageRoutes[tabKey]}`}
          />
        ))}

        <Route
          shielded
          layout={MainLayout}
          component={ArchivedItemsPage}
          isAuthenticated={isAuthenticated}
          showNoInternetConnection={!hasInternetConnection}
          path="/archived-items"
        />
        {Object.values(ArchivedItemsPageTabKeys).map((tabKey) => (
          <Route
            shielded
            key={tabKey}
            layout={MainLayout}
            component={ArchivedItemsPage}
            isAuthenticated={isAuthenticated}
            showNoInternetConnection={!hasInternetConnection}
            path={`/archived-items/${archivedItemsPageRoutes[tabKey]}`}
          />
        ))}

        <RRoute path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </BrowserRouter>
  )
})

const Route: FC<
  RouteProps & {
    layout?: any
    component: any
    shielded?: boolean
    publicOnly?: boolean
    isAuthenticated?: boolean
    showNoInternetConnection?: boolean
  }
> = ({
  shielded,
  publicOnly,
  isAuthenticated,
  showNoInternetConnection,
  layout: Layout,
  component: Component,
  ...routerProps
}) => {
  let RenderComponent = Component

  if (showNoInternetConnection) {
    RenderComponent = NoInternetConnection
  }

  const Element: FC = () => (
    <>
      {(((shielded && !isAuthenticated) || (publicOnly && isAuthenticated)) && (
        <Navigate to="/" state={{ from: location.pathname }} />
      )) ||
        (Layout ? (
          <Layout>
            <RenderComponent />
          </Layout>
        ) : (
          <RenderComponent />
        ))}
    </>
  )

  return <RRoute {...routerProps} element={<Element />} />
}

export default AppRouter
