import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'

import Text from '../../../ui-blocks/text'
import Switch from '../../../ui-blocks/switch'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { TabPane, Tabs } from '../../../ui-blocks/tabs'

import PlayersSection from './components/players-section'
import OverviewSection from './components/overview-section'
import SettingsSection from './components/settings/section'
import SharedWorkspacesSection from './components/shared-workspaces/section'

import NotFound from '../../../components/not-found'
import LoadingPage from '../../../components/loading-page'

import {
  EnumNetworkStatus,
  useViewNetworkQuery,
  useUpdateNetworkActivationMutation,
} from '../../../graphql/components'

import { getKPIIntervals } from '../../../utils/stats-intervals'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export enum NetworkPageTabKeys {
  OVERVIEW = 'overview',
  PLAYERS = 'players',
  SHARED_WORKSPACES = 'shared-workspaces',
  SETTINGS = 'settings',
}

export const networkPageRoutes = {
  [NetworkPageTabKeys.OVERVIEW]: '',
  [NetworkPageTabKeys.PLAYERS]: 'players',
  [NetworkPageTabKeys.SHARED_WORKSPACES]: 'shared-workspaces',
  [NetworkPageTabKeys.SETTINGS]: 'settings',
}

const ViewNetworkPage: FC = () => {
  const navigate = useNavigate()
  const { network_id } = useParams()

  const intervals = useMemo(getKPIIntervals, [])
  const [updateNetworkActivation] = useUpdateNetworkActivationMutation()

  const {
    data: networkData,
    loading: loadingNetwork,
    refetch: refetchNetwork,
  } = useViewNetworkQuery({
    skip: !network_id,
    variables: {
      _id: network_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a network's page if it is archived
  const { network } = networkData || {}
  useEffect(() => {
    if (network?.status === EnumNetworkStatus.Archived)
      navigate('/networks', { replace: true })
  }, [network])

  const refetchNetworkListener = async () => await refetchNetwork()
  useEffect(() => {
    document.addEventListener('refetch-players', refetchNetworkListener)
    return () => {
      document.removeEventListener('refetch-players', refetchNetworkListener)
    }
  }, [])

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-network-status-error-alert',
      message: `We couldn't ${action} this network. Please try again later.`,
    })
  }

  const onUpdateNetworkActivation = async (activate: boolean) => {
    try {
      const res = await updateNetworkActivation({
        variables: { ids: [network_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-networks'))
      await refetchNetwork()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/networks/${network?._id}/${
        networkPageRoutes[tabKey as NetworkPageTabKeys]
      }`
    )
  }

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${NetworkPageTabKeys.PLAYERS}`) &&
        NetworkPageTabKeys.PLAYERS) ||
      (location.pathname.endsWith(`/${NetworkPageTabKeys.SHARED_WORKSPACES}`) &&
        NetworkPageTabKeys.SHARED_WORKSPACES) ||
      (location.pathname.endsWith(`/${NetworkPageTabKeys.SETTINGS}`) &&
        NetworkPageTabKeys.SETTINGS) ||
      NetworkPageTabKeys.OVERVIEW,
    [location]
  )

  return (
    <Suspense ready={!loadingNetwork} fallback={<LoadingPage />}>
      <Suspense ready={!!network} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              { label: 'Networks', linkTo: '/networks' },
              { label: network?.name || '' },
            ]}
          />
          <div tw="flex flex-row items-center mb-6">
            <Switch
              disabled={network?.status === EnumNetworkStatus.Archived}
              checked={network?.status === EnumNetworkStatus.Activated}
              onSelect={onUpdateNetworkActivation}
              tw="-ml-2 mr-2"
            />
            <Text as="h1" preset="h3">
              {network?.name}
            </Text>
          </div>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane
              key={NetworkPageTabKeys.OVERVIEW}
              tab="Overview"
              tw="mt-10"
            >
              <OverviewSection network={network || undefined} />
            </TabPane>
            <TabPane key={NetworkPageTabKeys.PLAYERS} tab="Players" tw="mt-10">
              <PlayersSection
                loading={loadingNetwork}
                network={network || undefined}
                refetchNetwork={refetchNetwork}
              />
            </TabPane>
            <TabPane
              key={NetworkPageTabKeys.SHARED_WORKSPACES}
              tab="Shared Workspaces"
              tw="mt-10"
            >
              <SharedWorkspacesSection
                loading={loadingNetwork}
                network={network || undefined}
                refetchNetwork={refetchNetwork}
              />
            </TabPane>
            <TabPane
              key={NetworkPageTabKeys.SETTINGS}
              tab="Settings"
              tw="mt-10"
            >
              <SettingsSection
                network={network || undefined}
                refetchNetwork={refetchNetwork}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewNetworkPage
