import 'twin.macro'

import React, { FC, useMemo, useState } from 'react'
import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../../utils/use-controlled-or-internal-value'
import { timetableRuleSchema, timetableSchema } from './schemas'
import { TimetableRuleInput } from './timetable-rule-input'
import { ITimetable } from './types'
import { cloneDeep } from 'lodash'
import Button from '../../ui-blocks/button'
import { FormikErrors } from 'formik'
import ErrorMessage from '../error-message'

export interface ITimetableInputProps
  extends IUseControlledOrInternalValueProps<ITimetable> {
  disabled?: boolean
  error?: FormikErrors<ITimetable>
}

export const TimetableInput: FC<ITimetableInputProps> = ({
  value: origValue,
  onChange: origOnChange,
  disabled,
  error,
  ...props
}) => {
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  )
  const { value, onChange } = useControlledOrInternalValue<ITimetable>({
    value: origValue,
    onChange: origOnChange,
  })

  const timetable = useMemo(() => {
    return timetableSchema.cast(value)
  }, [value, editingIndex])

  return (
    <div {...props} tw="w-full">
      {timetable.rules.map((timetableRule, idx) => {
        return (
          <>
            <div key={idx} tw="p-4 flex flex-row w-full">
              <div tw="flex-auto">
                {/* {(idx === editingIndex && ( */}
                <TimetableRuleInput
                  disabled={disabled}
                  value={timetableRule}
                  error={(error?.rules && error.rules[idx]) || undefined}
                  onChange={(timetableRule) => {
                    const newTimetable = cloneDeep(timetable)
                    newTimetable.rules[idx] = timetableRule
                    onChange(newTimetable)
                  }}
                />
                {/* )) || <TimetableRuleViewer rule={timetableRule} />} */}
              </div>

              <div tw="flex-initial flex flex-col">
                {/* {idx !== editingIndex && ( */}
                {/* <Button
                  secondary
                  tw="mb-4"
                  type="button"
                  disabled={disabled}
                  onClick={() => setEditingIndex(idx)}
                >
                  Edit
                </Button> */}
                {/* )} */}

                <Button
                  secondary
                  disabled={disabled}
                  type="button"
                  onClick={() => {
                    const newTimetable = cloneDeep(timetable)
                    newTimetable.rules.splice(idx, 1)
                    onChange(newTimetable)
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
            <div tw="mx-4 border-b border-platinum" />
          </>
        )
      })}
      <div tw="pt-4 px-4">
        <Button
          secondary
          disabled={disabled}
          type="button"
          onClick={() => {
            const newTimetable = cloneDeep(timetable)
            newTimetable.rules.push(
              timetableRuleSchema.cast({ start: '00:00', end: '00:00' })
            )

            onChange(newTimetable)
            setEditingIndex(newTimetable.rules.length - 1)
          }}
        >
          Add new timetable timeframe
        </Button>
        {!!error && typeof error === 'string' && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
      </div>
    </div>
  )
}
