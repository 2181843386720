import React, { FC, ReactNode } from 'react'
import 'twin.macro'

import Button from './button'
import ModalBase from './modal-base'

export interface ModalActionProps {
  open?: boolean
  loading?: boolean
  cancelDisabled?: boolean
  confirmDisabled?: boolean
  title?: string
  width?: string
  content?: string | ReactNode
  cancelButtonText?: string
  confirmButtonText?: string
  onCancel?: () => void
  onConfirm?: () => void
  onBackdropClick?: () => void
}

const ModalAction: FC<ModalActionProps> = ({
  open,
  loading,
  cancelDisabled,
  confirmDisabled,
  title,
  width,
  content,
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
  onBackdropClick,
  children,
}) => (
  <ModalBase
    open={open}
    title={title}
    width={width}
    onBackdropClick={onBackdropClick}
  >
    {content ? (
      <p tw="whitespace-pre-line text-charcoal">{content}</p>
    ) : (
      children
    )}
    <div tw="flex flex-wrap justify-end space-x-6 mt-4">
      {!!onCancel && (
        <Button
          secondary
          onClick={onCancel}
          disabled={cancelDisabled}
          className="cancel-btn"
        >
          {(!!cancelButtonText && cancelButtonText) || 'Cancel'}
        </Button>
      )}
      {!!onConfirm && (
        <Button
          loading={loading}
          onClick={onConfirm}
          disabled={confirmDisabled}
          className="confirm-btn"
        >
          {(!!confirmButtonText && confirmButtonText) || 'Confirm'}
        </Button>
      )}
    </div>
  </ModalBase>
)

export default ModalAction
