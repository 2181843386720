import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Status from '../../ui-blocks/status'
import Illustration, { IllustrationEnum } from '../../ui-blocks/illustration'

import InlineDate from '../inline-date'
import UploadDuration from '../upload/duration'
import UploadThumbnail from '../upload/thumbnail'
import Table, { TableDataProps } from '../table'

import {
  EnumCreativeStatus,
  CreativeListFragment,
} from '../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
  getDurationFromUpload,
} from '../../utils/data-manipulation'

export interface CreativesListTableProps
  extends TableDataProps<CreativeListFragment> {
  onClickThumbnail?: (index: number) => void
}

const CreativesListTable: FC<CreativesListTableProps> = ({
  data,
  onClickThumbnail,
  ...props
}) => {
  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((creative, index) => ({
        preview: {
          content: (
            <div css={['width: 7.5rem;']}>
              <UploadThumbnail
                upload={creative.upload as any}
                onClick={
                  !!onClickThumbnail ? () => onClickThumbnail(index) : undefined
                }
              />
            </div>
          ),
        },
        name: {
          value: creative.name,
          content: (
            <Link to={`/creatives/${creative._id}`} tw="text-purple">
              {creative.name}
            </Link>
          ),
        },
        status: {
          value: creative?.status,
          content: <Status value={creative?.status as EnumCreativeStatus} />,
        },
        uploadType: {
          value: creative.upload?.type,
          content: creative.upload?.type.toUpperCase(),
        },
        uploadTime: {
          value: getDurationFromUpload(creative.upload as any),
          content: <UploadDuration upload={creative.upload as any} />,
        },
        createdAt: {
          value: creative.created?.at,
          content: <InlineDate date={creative.created?.at} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Table
      itemName="Creative"
      data={chronologicalData}
      emptyStateIllustration={
        <Illustration name={IllustrationEnum.creatives_empty_results} />
      }
      layout="fluid"
      cols={[
        { key: 'preview', label: 'Preview', width: 142 },
        { key: 'name', label: 'Name', isSortable: true },
        {
          key: 'status',
          label: 'Status',
          isSortable: true,
          alignment: 'center',
          width: 142,
        },
        {
          key: 'uploadType',
          label: 'Type',
          isSortable: true,
          alignment: 'center',
          width: 100,
        },
        {
          key: 'uploadTime',
          label: 'Time',
          isSortable: true,
          alignment: 'right',
          width: 100,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          isSortable: true,
          alignment: 'right',
          width: 150,
        },
      ]}
      {...props}
    />
  )
}

export default CreativesListTable
