import React, { FC, Fragment, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router'

import Button from '../../../../../../../ui-blocks/button'

import ArchiveWarningModal from '../../../../../../../components/archive-warning-modal'

import {
  CampaignAdGroupFullFragment,
  useArchiveCampaignAdGroupMutation,
} from '../../../../../../../graphql/components'

import { alertsManager } from '../../../../../../../stores'
import { AlertVariant } from '../../../../../../../stores/alerts-manager'

export interface ArchiveAdGroupFormProps {
  campaignAdGroup?: CampaignAdGroupFullFragment
}

const ArchiveAdGroupForm: FC<ArchiveAdGroupFormProps> = ({
  campaignAdGroup,
}) => {
  const navigate = useNavigate()
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)

  const [
    archiveAdGroup,
    { loading: archivingAdGroup },
  ] = useArchiveCampaignAdGroupMutation()

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'archive-ad-group-error-alert',
    })
  }

  const onArchiveAdGroup = async () => {
    try {
      const { data } = await archiveAdGroup({
        variables: { ids: [campaignAdGroup?._id] },
      })
      if (!data?.areArchived) {
        return handleError(
          "We couldn't archive this ad group. Please try again later."
        )
      }
      navigate(`/campaigns/${campaignAdGroup?.campaign_id}`, {
        state: { refetch: true },
      })
    } catch {
      handleError(
        'Something went wrong while trying to archive this ad group. Please try again later.'
      )
    }
  }

  return (
    <Fragment>
      <span tw="font-medium text-charcoal leading-tight block mb-2">
        Archive
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-10">
        Once you archive a ad group it will no longer be available.
      </span>
      <Button danger type="button" onClick={() => openWarningModal(true)}>
        Archive ad group
      </Button>
      <ArchiveWarningModal
        target="ad group"
        redirectPage="Campaign"
        open={isWarningModalOpen}
        loading={archivingAdGroup}
        onConfirm={onArchiveAdGroup}
        onCancel={() => openWarningModal(false)}
      />
    </Fragment>
  )
}

export default ArchiveAdGroupForm
