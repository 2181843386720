import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'

import {
  SelectCardFormik,
  SelectCardFormikProps,
} from '../../ui-blocks/select-card'
import Icon from '../../ui-blocks/icon'

import UploadThumbnail from '../upload/thumbnail'

import { EnumUploadType, CreativeListFragment } from '../../graphql/components'

import { formatDuration } from '../../utils/formaters'
import { getDurationFromUpload } from '../../utils/data-manipulation'

import { ReactComponent as PlayFilledIcon } from '../../assets/icons/play_filled.svg'

interface CreativeSelectCardProps extends SelectCardFormikProps {
  value: any
  selectable?: boolean
  creative: Pick<CreativeListFragment, '_id' | 'name' | 'upload'>
}

const CreativeSelectCard: FC<CreativeSelectCardProps> = ({
  creative,
  ...props
}) => (
  <SelectCardFormik tw="flex flex-row p-0 min-h-0" {...props}>
    <div css={[tw`flex-shrink-0`, 'width: 8.125rem;']}>
      <UploadThumbnail
        ratio="13/9"
        upload={creative?.upload as any}
        tw="rounded-l"
      />
    </div>
    <div tw="p-4 flex flex-grow flex-col justify-between max-w-full overflow-hidden">
      <p
        title={creative.name}
        tw="font-medium text-charcoal leading-tight truncate mb-1"
      >
        {creative.name}
      </p>
      <div tw="flex flex-row items-end justify-between">
        {creative.upload?.type === EnumUploadType.Image && (
          <Icon icon="image" tw="flex-shrink-0 text-sm text-dark-blue-gray" />
        )}
        {creative.upload?.type === EnumUploadType.Video && (
          <div tw="flex flex-row flex-shrink-0 items-center text-dark-blue-gray">
            <ColoredPlayFilledIcon />
            <span tw="ml-1 text-xs">
              {formatDuration(
                getDurationFromUpload((creative.upload as any) || undefined)
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  </SelectCardFormik>
)

const ColoredPlayFilledIcon = styled(PlayFilledIcon)`
  ${tw`w-3.5 h-3.5`}
  & path {
    ${tw`fill-current`}
  }
`

export default CreativeSelectCard
