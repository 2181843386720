import React, {
  FC,
  Fragment,
  useState,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
} from 'react'

import tw, { styled } from 'twin.macro'
import OutsideClickHandler from 'react-outside-click-handler'

import Icon from './icon'
import Card from './card'
import Spinner from './spinner'
import Suspense from './suspense'

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  primary?: boolean
  secondary?: boolean
  menu?: boolean
  danger?: boolean
  loading?: boolean
  dropdown?: React.ReactNode
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
}

const Button: FC<ButtonProps> = ({
  secondary,
  menu,
  danger,
  loading,
  dropdown,
  iconLeft,
  iconRight,
  onClick,
  children,
  ...props
}) => {
  const [isDropdownOpen, openDropdown] = useState<boolean>(false)

  let FinalButton = PrimaryButton
  if (secondary) FinalButton = SecondaryButton
  else if (menu) FinalButton = MenuButton
  else if (danger) FinalButton = DangerButton

  const hasDropdown = !!dropdown
  const Wrapper = hasDropdown ? 'div' : Fragment

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!loading) {
      if (hasDropdown) return openDropdown(!isDropdownOpen)
      onClick?.(e)
    }
  }

  return (
    <Wrapper {...(hasDropdown ? { css: ['position: relative;'] } : {})}>
      <FinalButton loading={loading} onClick={handleClick} {...props}>
        <Suspense
          ready={!loading}
          fallback={<Spinner center tw="w-4 h-4 border" />}
        >
          <div tw="flex flex-row items-center justify-center space-x-1">
            {iconLeft}
            <div>{children}</div>
            {iconRight}
            {!!dropdown && (
              <Icon
                icon="chevron-down"
                css={[
                  tw`text-base transition transform duration-200`,
                  isDropdownOpen ? tw`rotate-180` : tw`rotate-0`,
                ]}
              />
            )}
          </div>
        </Suspense>
      </FinalButton>
      {hasDropdown && (
        <OutsideClickHandler onOutsideClick={() => openDropdown(false)}>
          <div
            css={[
              tw`absolute mt-2 right-0`,
              'width: 25rem; z-index: 100;',
              !isDropdownOpen && tw`hidden`,
            ]}
          >
            <Card tw="px-10 py-8">{dropdown}</Card>
          </div>
        </OutsideClickHandler>
      )}
    </Wrapper>
  )
}

const ButtonBase = styled.button<ButtonProps>`
  min-width: 7.5rem;
  min-height: 2.5rem;
  padding: 0.625rem 1rem;
  ${({ primary, secondary }) =>
    (primary || secondary) &&
    'box-shadow: 0px 5px 14px -1px rgba(138, 63, 193, 0.3), 0px 2px 12px -1px rgba(0, 0, 0, 0.08);'}
  ${({ disabled }) => disabled && tw`cursor-not-allowed`}
  ${tw`
    border rounded leading-none
    transition-all ease-in-out duration-200
    focus:(ring-2 ring-offset-2 ring-bright-blue outline-none)
  `}
`

const ButtonBasePropsStrip: FC<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { loading?: boolean }
> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loading,
  ...props
}) => {
  // stripping loading
  return <ButtonBase {...props} />
}

const PrimaryButton = styled(ButtonBasePropsStrip)`
  ${tw`
    font-medium text-white bg-purple border-purple
    hover:(bg-dark-orchid border-dark-orchid)
    focus:(bg-dark-orchid border-dark-orchid)
    active:(bg-spanish-violet border-spanish-violet)
  `}
  ${({ loading }) =>
    loading &&
    tw`
      bg-spanish-violet border-spanish-violet
      hover:(bg-spanish-violet border-spanish-violet)
    `}
  ${({ disabled }) =>
    disabled &&
    tw`
      font-light text-purple-40 bg-off-white border-purple-20
      hover:(bg-off-white border-purple-20)
    `}
`

const SecondaryButton = styled(ButtonBasePropsStrip)`
  ${tw`
    font-medium text-charcoal bg-white border-charcoal
    hover:bg-paler-purple
    focus:(bg-paler-purple border-dark-blue-gray)
    active:(bg-light-purple border-dark-blue-gray)
  `}
  ${({ loading }) =>
    loading &&
    tw`
      bg-light-purple border-dark-blue-gray
      hover:(bg-light-purple border-dark-blue-gray)
    `}
  ${({ disabled }) =>
    disabled &&
    tw`
      font-light text-dark-blue-gray bg-white border-pale-blue
      hover:(bg-white border-pale-blue)
    `}
`

const MenuButton = styled(ButtonBasePropsStrip)`
  ${tw`
    font-normal text-charcoal bg-transparent border-none
    hover:text-purple focus:text-purple active:text-spanish-violet
  `}
  ${({ disabled }) =>
    disabled && tw`font-light text-dark-blue-gray hover:text-dark-blue-gray`}
`

const DangerButton = styled(ButtonBasePropsStrip)`
  ${tw`
    font-medium text-white bg-danger border-danger
    hover:(bg-light-danger border-light-danger)
    focus:(bg-light-danger border-light-danger)
    active:(bg-dark-danger border-dark-danger)
  `}
  ${({ loading }) =>
    loading &&
    tw`
      bg-dark-danger border-dark-danger
      hover:(bg-dark-danger border-dark-danger)
    `}
  ${({ disabled }) =>
    disabled &&
    tw`
      font-light text-dark-blue-gray bg-off-white border-pale-blue
      hover:(bg-off-white border-pale-blue)
    `}
`

Button.defaultProps = {
  primary: true,
}

export default Button
