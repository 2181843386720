import React, { FC, Ref, useMemo, useState } from 'react'
import tw, { css } from 'twin.macro'

import Icon from './icon'
import Text from './text'

import ErrorMessage from '../components/error-message'

import type { IFormikObject } from '../typings'

type InputBaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface InputProps extends InputBaseProps {
  inputRef?: Ref<HTMLInputElement>
  id?: string
  truncate?: boolean
  initialValue?: string
  hideSteppers?: boolean
  disabled?: boolean
  placeholder?: string
  label?: string | React.ReactNode
  labelExtra?: string | React.ReactNode
  leftLabel?: string
  helperText?: string
  success?: boolean
  error?: string
}

const Input: FC<InputProps> = ({
  inputRef,
  id,
  truncate,
  initialValue,
  hideSteppers,
  type,
  placeholder,
  disabled,
  label,
  labelExtra,
  leftLabel,
  helperText,
  success,
  error,
  className,
  required,
  ...props
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )

  const hasState = !!error || !!success
  const isPassword = type === 'password'
  const hasIcon = isPassword || hasState

  return (
    <div tw="w-full" className={className}>
      {label && (
        <Text
          as="label"
          tw="flex flex-row items-center justify-between pb-2"
          htmlFor={inputId}
        >
          {typeof label === 'string' ? (
            <Text as="span" preset="caption">
              {label} {!!required && '*'}
            </Text>
          ) : (
            <>
              {label} {!!required && '*'}
            </>
          )}
          {typeof labelExtra === 'string' ? (
            <Text as="span" preset="caption" transform="lowercase">
              {labelExtra}
            </Text>
          ) : (
            labelExtra
          )}
        </Text>
      )}
      <div tw="relative">
        <div tw="flex flex-row items-center">
          {leftLabel && (
            <Text as="span" preset="p1" tw="mr-3">
              {leftLabel}
            </Text>
          )}
          <input
            id={inputId}
            ref={inputRef}
            defaultValue={initialValue}
            type={isHidden ? type || 'text' : 'text'}
            onChange={() => null}
            disabled={disabled}
            placeholder={placeholder}
            css={css`
              transition: all 100ms ease-in-out;
              ${truncate && tw`truncate`}
              ${tw`bg-white text-charcoal border border-platinum rounded py-2 px-4 block w-full appearance-none leading-normal`}
              ${hasIcon && tw`pr-10`}
              &:hover {
                ${(!disabled && tw`border-light-peri`) || ``}
              }
              ${tw`focus:outline-none focus:border-spanish-violet`}
              ${(success && tw`border-metallic-seaweed`) || ``}
              ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
              ${(!!error && tw`border-brick-red bg-white`) || ``}
              ${!!hideSteppers &&
              `
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              `}
            `}
            {...props}
          />
        </div>
        {!isPassword && hasState && (
          <div
            css={[
              tw`w-1 h-1 rounded-full`,
              'background-color: currentColor;',
              inputIconStyle(!!error, success, false),
            ]}
          />
        )}
        {isPassword &&
          (isHidden ? (
            <Icon
              icon="eye"
              css={[tw`text-lg`, inputIconStyle(!!error, success, true)]}
              onClick={() => setIsHidden(false)}
            />
          ) : (
            <Icon
              icon="eye-off"
              css={[tw`text-lg`, inputIconStyle(!!error, success, true)]}
              onClick={() => setIsHidden(true)}
            />
          ))}
      </div>
      {!!helperText && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mt-1">
          {helperText}
        </span>
      )}
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

const inputIconStyle = (
  error?: boolean,
  success?: boolean,
  clickable?: boolean
) => css`
  ${tw`text-dark-blue-gray absolute top-2/4 right-4 transform -translate-y-1/2`}
  ${success && tw`text-metallic-seaweed`}
  ${error && tw`text-brick-red`}
  ${clickable ? tw`cursor-pointer` : tw`pointer-events-none`}
`

export default Input

export const InputFormik: FC<
  InputProps & {
    formik: IFormikObject<any>
    name: string
  }
> = ({ formik, ...props }) => {
  const meta = formik.getFieldMeta(props.name)
  return (
    <Input
      success={meta.touched && !meta.error}
      error={meta.error}
      value={meta.value}
      {...props}
    />
  )
}
