import React, { FC, Fragment, ReactNode, useState } from 'react'

import tw from 'twin.macro'
import Collapsible from 'react-collapsible'

import Icon from './icon'

import { ReactComponent as CircleCheckFilledIcon } from '../assets/icons/circle_check_filled.svg'

export interface AccordionProps {
  steps: AccordionStepProps[]
}

const Accordion: FC<AccordionProps> = ({ steps }) => (
  <div tw="w-full">
    {steps.map((step, index) => (
      <AccordionStep
        key={`accordion-step#${index}`}
        isLast={index === steps.length - 1}
        {...step}
      />
    ))}
  </div>
)

export interface AccordionStepProps {
  title: string
  done: boolean
  content: ReactNode
  isLast?: boolean
}

export const AccordionStep: FC<AccordionStepProps> = ({
  title,
  done,
  content,
  isLast,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  return (
    <Fragment>
      <div tw="w-full">
        <div
          onClick={() => !done && setIsExpanded(!isExpanded)}
          css={[
            tw`flex flex-row items-center justify-between`,
            !done && tw`hover:cursor-pointer`,
          ]}
        >
          <div tw="flex flex-row items-center space-x-2">
            {done ? (
              <CircleCheckFilledIcon width={20} height={20} />
            ) : (
              <div tw="w-4 h-4 rounded-full bg-pale-blue m-px" />
            )}
            <p tw="font-medium text-charcoal leading-tight">{title}</p>
          </div>
          {!done && (
            <Icon
              icon="chevron-down"
              css={[
                tw`text-xl text-charcoal transition duration-200 transform`,
                isExpanded ? tw`rotate-180` : tw`rotate-0`,
              ]}
            />
          )}
        </div>
        {!done && (
          <Collapsible
            open={isExpanded}
            transitionTime={350}
            trigger={<React.Fragment />}
          >
            {content}
          </Collapsible>
        )}
      </div>
      {!isLast && <hr tw="my-4 bg-platinum" />}
    </Fragment>
  )
}

export default Accordion
