import React, { FC, Fragment } from 'react'

import without from 'lodash/without'
import tw from 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import Text from '../../../../ui-blocks/text'
import Search from '../../../../ui-blocks/search'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import Grid from '../../../../components/grid'
import NetworkSelectCard from '../../../../components/network/select-card'

import { CreateCampaignFormikValues } from '..'
import { useSearchInternalNetworks } from './search-internal-network'
import { useSearchExternalNetworks } from './search-external-network'

export const targetNetworksValidationSchema = object().shape({
  networks_ids: array(string()).notRequired(),
})

interface TargetNetworksStepProps {
  external?: boolean
}

const TargetNetworksStep: FC<TargetNetworksStepProps> = ({ external }) => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateCampaignFormikValues>()
  const network_ids = external
    ? values.external_networks_ids
    : values.networks_ids

  const fieldToSet = external ? 'external_networks_ids' : 'networks_ids'
  const internalNetworks = useSearchInternalNetworks()
  const externalNetworks = useSearchExternalNetworks()

  const networksToDisplay = external ? externalNetworks : internalNetworks

  const {
    totalNetworksCount,
    loadingTotalCount,
    loadingCount,
    searchText,
    networks,
    setSearchText,
    loadingNetworks,
    pagination,
  } = networksToDisplay

  const handleSelectNetwork = (networkId: string) => {
    if (!network_ids.includes(networkId))
      return setFieldValue(fieldToSet, [...network_ids, networkId])
    setFieldValue(fieldToSet, without(network_ids, networkId))
  }

  return (
    <Fragment>
      <div tw="flex flex-wrap items-center justify-between mb-6">
        <p tw="text-dark-blue-gray text-xs uppercase">
          {loadingTotalCount
            ? 'Loading...'
            : `${totalNetworksCount} Networks, ${network_ids.length} Selected`}
        </p>
        <div tw="w-80">
          <Search
            value={searchText}
            loading={loadingNetworks && loadingCount}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense
        ready={!loadingNetworks && !loadingCount}
        fallback={<Spinner center />}
      >
        <Grid rows={3} cols={3} gap="1rem">
          {networks?.map((network) => (
            <NetworkSelectCard
              name="networks_ids"
              network={network}
              value={network?._id}
              key={`network-${network._id}`}
              onClick={() => handleSelectNetwork(network?._id)}
              checked={network_ids.includes(network?._id)}
            />
          ))}
        </Grid>
      </Suspense>
      <div tw="flex flex-row items-center justify-between space-x-8 mt-4">
        <Text
          as="span"
          preset="p2"
          css={[tw`underline`, 'text-decoration-style: dashed;']}
        >
          {/* NOTE: At least one network MUST be selected! */}
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </Fragment>
  )
}

export default TargetNetworksStep
