import React, { Component } from 'react'

import 'twin.macro'
import { inject, observer } from 'mobx-react'

import UploadsCard from './components/uploads-card'

import UploadsManager from '../../../stores/uploads-manager'

export interface UploadsDashboardProps {
  uploadsManager?: UploadsManager
}

@inject('uploadsManager')
@observer
export default class UploadsDashboard extends Component<UploadsDashboardProps> {
  public render() {
    const { uploadsManager } = this.props
    return (
      <div tw="fixed left-8 bottom-8 overflow-visible">
        {!!uploadsManager?.uploadsBar.length && (
          <UploadsCard
            manager={uploadsManager}
            isExpanded={uploadsManager.isExpanded}
          />
        )}
      </div>
    )
  }
}
