import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Icon from './icon'
import Spinner from './spinner'

export interface PaymentMethodType {
  id: string
  card: {
    brand: string
    country: string
    funding: string
    exp_year: number
    exp_month: number
    fingerprint: string
    last4: string
  }
}

export interface PaymentMethodProps {
  loading?: boolean
  paymentMethods: PaymentMethodType
  defaultPaymentMethod: string
  onSetPrimary?: (id: string) => void
  onRemove?: (id: string) => void
}

const PaymentMethod: FC<PaymentMethodProps> = ({
  loading,
  defaultPaymentMethod,
  paymentMethods: {
    id,
    card: { brand, exp_month, exp_year, last4 },
  },
  onSetPrimary,
  onRemove,
}) => {
  const isPrimary = id === defaultPaymentMethod
  return (
    <div tw="rounded-md bg-white border border-platinum py-4 pl-4 pr-6 mb-4 max-w-md relative">
      {loading && (
        <div tw="w-full h-full bg-white-60 flex items-center justify-center absolute top-0 left-0">
          <Spinner />
        </div>
      )}
      <div tw="flex flex-row justify-start">
        {isPrimary && (
          <span tw="font-normal text-flickr-blue leading-tight mr-2">
            Primary card
          </span>
        )}
        <span
          css={css`
            margin-bottom: 0.125rem;
            ${tw`font-normal text-charcoal leading-tight block`}
          `}
        >
          {brand.toUpperCase()} ending in {last4}
        </span>
      </div>
      <div tw="flex flex-row items-center justify-between">
        <div tw="flex flex-row">
          <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
            {`Expiration: ${
              exp_month < 10 ? '0' + exp_month : exp_month
            }/${exp_year}`}
          </span>
        </div>
        <div tw="flex flex-row font-normal text-dark-blue-gray leading-tight">
          {!isPrimary && (
            <span
              tw="mr-6 underline hover:cursor-pointer"
              onClick={() => onSetPrimary?.(id)}
            >
              Set as primary card
            </span>
          )}
          <span tw="flex flex-row items-center hover:(text-purple cursor-pointer)">
            <Icon icon="close" tw="text-lg transition duration-200" />
            <span
              tw="transition duration-200 ml-1"
              onClick={() => onRemove?.(id)}
            >
              Remove
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
