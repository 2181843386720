import React, { FC, Fragment, useState } from 'react'

import 'twin.macro'
import { useParams } from 'react-router-dom'

import Icon from '../../../../ui-blocks/icon'
import Text from '../../../../ui-blocks/text'
import Search from '../../../../ui-blocks/search'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import ButtonLink from '../../../../ui-blocks/button-link'

import NoSearchResults from './components/no-search-results'
import RemoveMemberModal from './components/remove-member-modal'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import {
  WorkspaceMembers,
  useListInvitesQuery,
  useViewWorkspaceQuery,
} from '../../../../graphql/components'
import { MutationResult } from '../../../../typings'

import { useSearchTerms } from '../../../../utils/use-search'

import { authentication } from '../../../../stores'
import Gravatar from '../../../../ui-blocks/gravatar'
import CancelInviteModal from './components/cancel-invite-modal'
import { regexpFromString } from '../../../../utils/regexp-from-string'

export type Member = WorkspaceMembers & { status: string; invite_id: string }

const WorkspaceSettingsMembersPage: FC = () => {
  const { workspace_id } = useParams()

  const [memberEmail, setMemberEmail] = useState<string>('')
  const [
    removeMemberMutationResult,
    setRemoveMemberMutationResult,
  ] = useState<MutationResult>(null)
  const [
    cancelInviteMutationResult,
    setCancelInviteMutationResult,
  ] = useState<MutationResult>(null)
  const [inviteId, setInviteId] = useState<string>('')

  const {
    data: workspaceData,
    loading: loadingWorkspace,
    refetch: refetchWorkspace,
  } = useViewWorkspaceQuery({
    skip: !workspace_id,
    fetchPolicy: 'cache-first',
    variables: { _id: workspace_id },
  })
  const workspaceMembers = (workspaceData?.workspace?.members || []).filter(
    (member) => !member?.removed?.at
  )

  const {
    data: invitesData,
    loading: loadingInvites,
    refetch: refetchInvites,
  } = useListInvitesQuery({
    fetchPolicy: 'cache-first',
  })
  const pendingInvites = (invitesData?.invites || []).filter(
    (invite) => invite.status === 'PENDING'
  )

  const onCloseCancelInviteModal = async (result: MutationResult) => {
    setMemberEmail('')
    setInviteId('')
    setCancelInviteMutationResult(result)
    if (result === 'success') await refetchInvites()
  }

  const onCloseRemoveMemberModal = async (result: MutationResult) => {
    setMemberEmail('')
    setRemoveMemberMutationResult(result)
    if (result === 'success') await refetchWorkspace()
  }

  const allMembers: any[] = [
    ...workspaceMembers,
    ...pendingInvites.map((invite) => ({
      status: invite.status || '',
      user_id: invite.target?.user_id || '',
      invite_id: invite._id || '',
      user: {
        _id: invite.target?.user_id || '',
        emails: [{ address: invite.target?.email || '' }],
        name: {
          first: invite.target?.name?.first || '',
          last: invite.target?.name?.last || '',
        },
      },
    })),
  ]

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const filteredMembers: any[] = allMembers.filter((member) => {
    const firstName = member?.user?.name?.first || ''
    const lastName = member?.user?.name?.last || ''

    const emails = member?.user?.emails || []
    const email = (emails.length > 0 && emails[0]?.address) || ''

    const regex = regexpFromString(searchTextDebounced, 'i')
    return regex.test(firstName) || regex.test(lastName) || regex.test(email)
  })

  return (
    <Fragment>
      {removeMemberMutationResult && (
        <div tw="mb-6">
          <AlertMessage
            alert={{
              id: 'remove-member-update-alert',
              variant:
                removeMemberMutationResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                removeMemberMutationResult === 'error'
                  ? "We couldn't remove your team member. Please try again later."
                  : 'Team member removed successfully.',
            }}
          />
        </div>
      )}
      {cancelInviteMutationResult && (
        <div tw="mb-6">
          <AlertMessage
            alert={{
              id: 'cancel-invite-update-alert',
              variant:
                cancelInviteMutationResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                cancelInviteMutationResult === 'error'
                  ? "We couldn't revoke the invite. Please try again later."
                  : 'Invite cancelled sucessfully.',
            }}
          />
        </div>
      )}
      <Text as="h2" preset="h4" tw="mb-2">
        Manage members
      </Text>
      <Text as="h3" preset="p2" tw="mb-6">
        Manage members that collaborate in this workspace.
      </Text>
      <div tw="flex flex-row items-center justify-between mb-8">
        <span tw="text-dark-blue-gray">
          {`${workspaceMembers.length} Member(s), ${pendingInvites.length} Invite(s)`}
        </span>
        <div tw="flex flex-row items-center space-x-4">
          <div tw="w-80">
            <Search
              value={searchText}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
          </div>
          <ButtonLink
            className="add-member-btn"
            iconLeft={<Icon icon="add" tw="text-lg" />}
            to={`/workspaces/${workspace_id}/settings/members/add`}
          >
            Add member
          </ButtonLink>
        </div>
      </div>
      <Suspense
        ready={!loadingWorkspace && !loadingInvites}
        fallback={<Spinner center />}
      >
        <Suspense
          ready={!!filteredMembers.length}
          fallback={<NoSearchResults searchQuery={searchTextDebounced} />}
        >
          <table tw="w-full">
            <thead>
              <tr tw="border-b border-platinum font-medium text-sm text-dark-blue-gray leading-tight tracking-wider uppercase">
                <th tw="pb-4 text-left">User</th>
                {/* <th tw="pb-4 text-left">Access Level</th> */}
                <th tw="pb-4"></th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers.map((member, i) => (
                <MemberRow
                  key={i}
                  member={member}
                  onOpenRemoveMemberModal={(email: string) => {
                    setMemberEmail(email)
                  }}
                  onOpenCancelInviteModal={(
                    email: string,
                    inviteId: string
                  ) => {
                    setMemberEmail(email)
                    setInviteId(inviteId)
                  }}
                />
              ))}
            </tbody>
          </table>
        </Suspense>
      </Suspense>
      <RemoveMemberModal
        open={!!memberEmail && !inviteId}
        memberEmail={memberEmail}
        onClose={onCloseRemoveMemberModal}
      />
      <CancelInviteModal
        open={!!memberEmail && !!inviteId}
        inviteId={inviteId}
        memberEmail={memberEmail}
        onClose={onCloseCancelInviteModal}
      />
    </Fragment>
  )
}

export default WorkspaceSettingsMembersPage

const MemberRow: FC<{
  member: Member
  onOpenRemoveMemberModal: (email: string) => void
  onOpenCancelInviteModal: (email: string, inviteId: string) => void
}> = ({ member, onOpenRemoveMemberModal, onOpenCancelInviteModal }) => {
  const { user, status, invite_id } = member
  const names = [user?.name?.first || '', user?.name?.last || ''].filter(
    Boolean
  )

  const fullName = names.length ? names.join(' ') : 'Unknown'
  const initials = names.length
    ? names.map((name) => name.charAt(0)).join('')
    : 'U'

  const isInvitePending = status === 'PENDING'

  const email = (user?.emails?.length && user?.emails[0]?.address) || ''
  const isEmailVerified = !authentication.user?.emails.find(
    (userEmail) => userEmail?.address === email
  )
  const canRemove = isEmailVerified || isInvitePending

  return (
    <tr tw="border-b border-platinum">
      <td tw="py-2">
        <div tw="flex flex-row items-center">
          <Gravatar
            initials={initials}
            email={email}
            variant="profile"
            tw="mr-2"
          />
          <div tw="flex flex-col">
            <p tw="text-charcoal font-medium">
              {`${fullName}${status ? ` (${status})` : ''}`}
            </p>
            <p tw="text-xs text-charcoal">{email}</p>
          </div>
        </div>
      </td>
      <td tw="py-2">
        {canRemove && (
          <div tw="flex flex-row justify-end">
            <a
              tw="text-dark-blue-gray leading-tight transition duration-200 hover:(text-purple cursor-pointer)"
              onClick={() =>
                isInvitePending
                  ? onOpenCancelInviteModal(email, invite_id)
                  : onOpenRemoveMemberModal(email)
              }
            >
              <span tw="flex flex-row items-center space-x-1">
                <Icon icon="close" tw="text-base" />
                <span tw="leading-tight">
                  {isInvitePending ? 'Cancel Invite' : 'Remove'}
                </span>
              </span>
            </a>
          </div>
        )}
      </td>
    </tr>
  )
}
