import React, { FC, useState } from 'react'

import tw, { styled } from 'twin.macro'
import Collapsible from 'react-collapsible'

import Icon from '../../../../ui-blocks/icon'
import Card from '../../../../ui-blocks/card'
import Link from '../../../../ui-blocks/link'
import Spinner from '../../../../ui-blocks/spinner'
import ModalAction from '../../../../ui-blocks/modal-action'

import UploadProgress from './upload-progress'
import UploadsManager, { UploadState } from '../../../../stores/uploads-manager'

export interface UploadsCardProps {
  isExpanded: boolean
  manager: UploadsManager
}

const UploadsCard: FC<UploadsCardProps> = ({ manager, isExpanded }) => {
  const [isDismissModalOpen, openDismissModal] = useState<boolean>(false)

  let minProgress = 100
  for (const upload of manager.uploadsBar) {
    minProgress = Math.min(minProgress, upload.progress || 0)
  }
  const isUploading = manager.uploadsBar.some(
    (upload) => upload.state === UploadState.UPLOADING
  )

  const dismissAll = () => {
    manager.uploadsBar.forEach((upload) => manager.dismiss(upload.id))
  }

  const requestDismissAll = () => {
    const fails = !!manager.uploadsBar.find(
      (upload) =>
        upload.state === UploadState.FAILED ||
        upload.state === UploadState.FINALIZING
    )
    if (fails) return openDismissModal(true)
    dismissAll()
  }

  return (
    <StyledCard>
      <div tw="flex flex-row items-center justify-between bg-charcoal text-white px-4 py-3">
        <div tw="flex flex-row items-center">
          <span tw="font-medium">Upload</span>
          <span tw="ml-6">{`${manager.uploadsBar.length} files`}</span>
        </div>
        <div tw="flex flex-row items-center">
          {isUploading && (
            <div tw="flex flex-row items-center">
              <Spinner tw="w-3.5 h-3.5 border" />
              <span tw="ml-1">{`${Math.round(minProgress * 100)}%`}</span>
            </div>
          )}
          <div tw="flex flex-row items-center ml-10 space-x-4">
            <Icon
              icon="chevron-down"
              onClick={manager.triggerExpansion}
              css={[
                tw`text-lg transition duration-200 transform hover:cursor-pointer`,
                !isExpanded ? tw`rotate-180` : tw`rotate-0`,
              ]}
            />
            <Icon
              icon="close"
              tw="text-lg hover:cursor-pointer"
              onClick={requestDismissAll}
            />
          </div>
        </div>
      </div>
      <Collapsible
        open={isExpanded}
        transitionTime={350}
        trigger={<React.Fragment />}
      >
        <div tw="p-4 space-y-4">
          {manager.uploadsBar.map((upload, index) => (
            <UploadProgress
              upload={upload}
              manager={manager}
              key={`upload#${index}`}
            />
          ))}
          <Link
            secondary
            tw="inline-flex min-h-auto no-underline p-0 mt-5!"
            onClick={requestDismissAll}
          >
            Dismiss all
          </Link>
        </div>
      </Collapsible>
      <ModalAction
        open={isDismissModalOpen}
        title="Dismiss errors?"
        cancelButtonText="Go back"
        confirmButtonText="Dismiss"
        content="Some of your files weren't successfully uploaded or are still processing. Do you still want to dismiss all files?"
        onCancel={() => openDismissModal(false)}
        onConfirm={() => {
          dismissAll()
          openDismissModal(false)
        }}
      />
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  width: 26rem;
  z-index: 100;
  border-radius: 0.25rem;
  ${tw`overflow-hidden select-none`}
`

export default UploadsCard
