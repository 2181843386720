import { FC, useEffect } from 'react'
import React from 'react'
import WorkspacePendingInvites, { usePendingInvites } from '.'
import { useNavigate } from 'react-router-dom'
import { authentication } from '../../../stores'

const AuthWorkspacePendingInvites: FC = () => {
  const navigate = useNavigate()
  const pendingInviteProps = usePendingInvites('/', 'Ignore')

  useEffect(() => {
    if (pendingInviteProps.invites?.length === 0) {
      if (authentication.selected_workspace?._id) {
        navigate(`/workspace/${authentication.selected_workspace?._id}`)
      } else {
        navigate('/workspace/create')
      }
    }
  }, [
    pendingInviteProps.invites,
    authentication.selected_workspace,
    authentication.selected_workspace?._id,
  ])

  return (
    <WorkspacePendingInvites {...pendingInviteProps}></WorkspacePendingInvites>
  )
}

export default AuthWorkspacePendingInvites
