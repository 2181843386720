import React, {
  FC,
  useMemo,
  useState,
  Fragment,
  ReactNode,
  TableHTMLAttributes,
} from 'react'

import _ from 'lodash'
import tw, { css } from 'twin.macro'

import Divider from '../../ui-blocks/divider'
import Suspense from '../../ui-blocks/suspense'

import ColumnHead from './components/column-head'
import ColumnCell from './components/column-cell'
import FullTableRow from './components/full-table-row'

import NoResults from '../no-results'
import LoadingPage from '../loading-page'

import { SortSettings } from '../../typings'

export interface TableDataProps<T> {
  data: T[]
  loading?: boolean
  searchQuery?: string
}

export interface TableData {
  [key: string]: {
    value?: any
    content: ReactNode
  }
}

export type TableCellAlignment = 'left' | 'center' | 'right'
export interface TableColumn {
  key: string
  label?: string
  isSortable?: boolean
  alignment?: TableCellAlignment
  span?: number
  width?: string | number
}

export interface SectionTableData {
  [section: string]: TableData[]
}
export interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  itemName: string
  loading?: boolean
  layout?: 'fixed' | 'fluid'
  cols: TableColumn[]
  data: SectionTableData
  searchQuery?: string
  hasVerticalPadding?: boolean
  emptyStateIllustration?: ReactNode
}

const Table: FC<TableProps> = ({
  itemName,
  data,
  cols,
  loading,
  layout,
  searchQuery,
  hasVerticalPadding,
  emptyStateIllustration,
  ...props
}) => {
  const [sortSettings, setSortSettings] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const sortedData = useMemo(() => {
    if (!sortSettings.column) return data
    const sortedRes = Object.keys(data).reduce<SectionTableData>(
      (acc, section) => {
        let sortedArr = _.sortBy(data[section], [
          `${sortSettings.column}.value`,
        ])
        sortedArr = !sortSettings.isAscending ? sortedArr.reverse() : sortedArr
        return { ...acc, [section]: sortedArr }
      },
      {}
    )
    return sortedRes
  }, [data, sortSettings])

  const fullColSpan = useMemo(
    () => cols.map((col) => col.span || 1, [cols]).reduce((a, b) => a + b, 0),
    [cols]
  )

  const loadingState = () => (
    <FullTableRow colSpan={fullColSpan}>
      <LoadingPage />
    </FullTableRow>
  )

  const emptyState = () => (
    <FullTableRow colSpan={fullColSpan}>
      <NoResults
        tw="mt-8"
        model={itemName}
        searchQuery={searchQuery}
        Illustration={emptyStateIllustration}
      />
    </FullTableRow>
  )

  return (
    <table
      role="table"
      css={css`
        border-spacing: 0 0.5rem;
        ${tw`w-full -my-2 border-separate`}
        ${layout === 'fixed' && tw`table-fixed`}
      `}
      {...props}
    >
      <thead>
        <tr role="row">
          {cols.map((col, index) => (
            <ColumnHead
              key={`table-head-col#${index}`}
              label={col.label}
              alignment={col.alignment}
              isSortable={col.isSortable}
              isSelected={sortSettings.column === col.key}
              colSpan={(layout === 'fixed' && col.span) || 1}
              onClick={(isAscending) =>
                setSortSettings({ column: col.key, isAscending })
              }
              style={{
                width: col.width,
                maxWidth:
                  (layout !== 'fixed' && `${(col.span || 1) / fullColSpan}%`) ||
                  undefined,
              }}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        <Suspense ready={!loading} fallback={loadingState()}>
          <Suspense
            ready={!!Object.keys(sortedData).length}
            fallback={emptyState()}
          >
            {Object.keys(sortedData)
              .reverse()
              .map((section, index) => (
                <Fragment key={`section#${index}`}>
                  {index !== 0 && (
                    <FullTableRow colSpan={fullColSpan}>
                      <Divider text={section} tw="-my-2" />
                    </FullTableRow>
                  )}
                  {sortedData[section].map((row, rowIndex) => (
                    <tr
                      role="row"
                      tw="bg-white"
                      key={`table-body-row#${rowIndex}`}
                    >
                      {cols.map((col, colIndex) => (
                        <ColumnCell
                          alignment={col.alignment}
                          key={`table-body-cell#${colIndex}`}
                          hasVerticalPadding={hasVerticalPadding}
                          colSpan={(layout === 'fixed' && col.span) || 1}
                          style={{
                            width: col.width,
                            maxWidth:
                              (layout !== 'fixed' &&
                                `${(col.span || 1) / fullColSpan}%`) ||
                              undefined,
                          }}
                        >
                          {row?.[col.key]?.content}
                        </ColumnCell>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
          </Suspense>
        </Suspense>
      </tbody>
    </table>
  )
}

export default Table
