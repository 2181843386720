import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Text from '../../../../ui-blocks/text'
import Spacer from '../../../../ui-blocks/spacer'
import CodeInput from '../../../../ui-blocks/code-input'

import useElementStates from '../../../../utils/use-element-states'

const CodeInputSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'success', 'disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <CodeInput
        length={6}
        label="Label"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        length={6}
        label="Label"
        placeholder="123456"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        length={6}
        value="123456"
        label="Prefilled input"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        length={6}
        label="Label"
        placeholder="123456"
        id="code-input-with-custom-label"
        error={error ? 'Error message!' : ''}
        labelExtra={
          <Text as="span" preset="caption" transform="lowercase">
            optional
          </Text>
        }
        {...values}
      />
    </ElementSection>
  )
}

export default CodeInputSection
