import React, { FC } from 'react'
import 'twin.macro'

import Spinner from '../ui-blocks/spinner'

const LoadingPage: FC = () => (
  <div tw="flex items-center justify-center w-full p-16">
    <Spinner />
  </div>
)

export default LoadingPage
