import React, { FC, Fragment } from 'react'
import 'twin.macro'

import { object, string } from 'yup'
import { useFormikContext } from 'formik'

import CardResource from '../../../../../ui-blocks/card-resource'

import { EnumCampaignAdGroupAudienceArea_Type } from '../../../../../graphql/components'

import { ReactComponent as IndoorIcon } from '../../../../../assets/icons/indoor_audience.svg'
import { ReactComponent as OutdoorIcon } from '../../../../../assets/icons/outdoor_audience.svg'

import { CreateAdGroupFormikValues } from '..'

export const audienceValidationSchema = object().shape({
  audience: object().shape({
    area_type: string()
      .oneOf([
        EnumCampaignAdGroupAudienceArea_Type.Both,
        EnumCampaignAdGroupAudienceArea_Type.Indoor,
        EnumCampaignAdGroupAudienceArea_Type.Outdoor,
      ])
      .required(),
  }),
})

export const AUDIENCE_AREA_TYPES = [
  {
    title: 'Indoor',
    description: 'Explore different locations',
    value: EnumCampaignAdGroupAudienceArea_Type.Indoor,
    icon: <IndoorIcon width={24} height={24} tw="text-purple" />,
  },
  {
    title: 'Outdoor',
    description: 'Streets and Billboards',
    value: EnumCampaignAdGroupAudienceArea_Type.Outdoor,
    icon: <OutdoorIcon width={24} height={24} tw="text-purple" />,
  },
  {
    title: 'Both',
    description: 'Both indoor and outdoor',
    value: EnumCampaignAdGroupAudienceArea_Type.Both,
  },
]

const AudienceStep: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateAdGroupFormikValues>()

  return (
    <Fragment>
      <p tw="font-medium mb-4">Area Type</p>
      <div tw="flex flex-row flex-wrap items-stretch justify-around gap-6">
        {AUDIENCE_AREA_TYPES.map((areaType, index) => (
          <CardResource
            type="radio"
            name="audience.area_type"
            key={`area-type#${index}`}
            icon={areaType.icon}
            title={areaType.title}
            value={areaType.value}
            description={areaType.description}
            checked={areaType.value === values.audience.area_type}
            onClick={() => setFieldValue('audience.area_type', areaType.value)}
          />
        ))}
      </div>
    </Fragment>
  )
}

export default AudienceStep
