import React, { FC, useMemo } from 'react'
import 'twin.macro'

import { string, object, array } from 'yup'
import { useFormik, FormikHelpers } from 'formik'

import Button from '../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../ui-blocks/input'
import {
  EnumWebhookEvent_Types,
  EnumWebhookType,
} from '../../../../../graphql/components'
import Checkbox, { CheckboxFormik } from '../../../../../ui-blocks/checkbox'
import { startCase } from 'lodash'
import Text from '../../../../../ui-blocks/text'
import ErrorMessage from '../../../../../components/error-message'
import { RadioFormik } from '../../../../../ui-blocks/radio'

export interface WebhookFormikValues {
  name: string
  url: string
  type: EnumWebhookType
  event_types: EnumWebhookEvent_Types[]
}

export const WEBHOOK_TYPES = Object.values(EnumWebhookType)
export const EVENT_TYPES = Object.values(EnumWebhookEvent_Types)

const SECTIONATED_EVENT_TYPES: EnumWebhookEvent_Types[][] = []
EVENT_TYPES.forEach((eventTypes) => {
  const key =
    (eventTypes.match(/^player_/) && 1) ||
    (eventTypes.match(/^creative_/) && 2) ||
    (eventTypes.match(/^network_/) && 3) ||
    (eventTypes.match(/^campaign_ad_/) && 4) ||
    (eventTypes.match(/^campaign_/) && 5) ||
    0

  const targetArr = (SECTIONATED_EVENT_TYPES[key] =
    SECTIONATED_EVENT_TYPES[key] || [])
  targetArr.push(eventTypes)
})

// sort by greater to lower
SECTIONATED_EVENT_TYPES.sort((a, b) => (a.length < b.length ? 1 : -1))

const webhookValidationSchema = object().shape({
  name: string().required('Friendly Name is required').strict(true),
  url: string()
    .trim('Value cannot have leading or trailing white spaces')
    .url('Must be a valid URL')
    .required('URL is required')
    .strict(true),
  type: string().required('Webhook Type is required').oneOf(WEBHOOK_TYPES),
  event_types: array()
    .of(string().oneOf(EVENT_TYPES))
    .min(1, 'please define some event types'),
})

export interface WebhookFormProps {
  disabled?: boolean
  submitting?: boolean
  initialValues?: Partial<WebhookFormikValues>
  onSubmit: (
    values: WebhookFormikValues,
    formikHelpers: FormikHelpers<WebhookFormikValues>
  ) => void | Promise<any>
}

const defaultInitialValues = {
  name: '',
  type: EnumWebhookType.Ids,
  url: '',
  event_types: [...EVENT_TYPES],
}

const WebhookForm: FC<WebhookFormProps> = ({
  disabled,
  submitting,
  initialValues,
  onSubmit,
}) => {
  const formik = useFormik<WebhookFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: webhookValidationSchema,
    initialValues: useMemo(
      () => ({ ...defaultInitialValues, ...initialValues }),
      [initialValues]
    ),
    onSubmit,
  })

  const allSelected = useMemo(() => {
    return formik.values.event_types.length === EVENT_TYPES.length
  }, [formik.values.event_types])

  return (
    <form
      onChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
      tw="flex flex-col space-y-4"
    >
      <div tw="flex flex-col space-y-2 items-start">
        <Text as="label" tw="flex flex-row items-center justify-between">
          <Text as="span" preset="caption">
            Webhook Type
          </Text>
        </Text>

        <div tw="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-2 xl:grid-cols-4 xl:gap-4">
          <div tw="space-y-2">
            <RadioFormik
              formik={formik}
              label="Only IDs"
              name="type"
              value={EnumWebhookType.Ids}
            />
            <Text as="p" preset="p1">
              This webhook delivers a payload with the event type and the
              associated Identifiers.
            </Text>
          </div>
          <div tw="space-y-2">
            <RadioFormik
              formik={formik}
              label="Slack compatible"
              name="type"
              value={EnumWebhookType.SlackMessage}
            />
            <Text as="p" preset="p1">
              This webhook delivers a Slack-compatible payload with custom
              messaging. Use it with the{' '}
              <a
                href="https://slack.com/apps/A0F7XDUAZ-incoming-webhooks?tab=more_info"
                target="_blank"
                rel="noopener noreferrer"
              >
                Incoming Webhook
              </a>{' '}
              plugin to integrate with Slack.
            </Text>
          </div>
        </div>
      </div>
      <div tw="max-w-xs">
        <InputFormik
          type="text"
          name="name"
          label="Friendly Name"
          placeholder="Custom Funky API webhook"
          disabled={disabled}
          formik={formik}
        />
      </div>
      <div tw="max-w-xs">
        <InputFormik
          type="text"
          name="url"
          label="URL"
          placeholder="https://your-domain.com"
          disabled={disabled}
          formik={formik}
        />
      </div>

      <div tw="flex flex-col space-y-2">
        <Text as="label" tw="flex flex-row items-center justify-between">
          <Text as="span" preset="caption">
            Event Types
          </Text>
        </Text>

        <div>
          <Checkbox
            key="all"
            label="All events"
            name="event_types[]"
            checked={allSelected}
            onChange={() =>
              formik.setFieldValue(
                'event_types',
                allSelected ? [] : EVENT_TYPES
              )
            }
          />
        </div>

        <div tw="flex flex-col space-y-2">
          <div tw="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 xl:grid-cols-4">
            {SECTIONATED_EVENT_TYPES.map((SECTION_EVENT_TYPES, i) => (
              <div key={i}>
                {SECTION_EVENT_TYPES.map((eventType) => (
                  <CheckboxFormik
                    key={eventType}
                    name="event_types"
                    value={eventType}
                    // disabled={allSelected}
                    label={startCase(eventType)}
                    formik={formik}
                  />
                ))}
              </div>
            ))}
          </div>

          {!!formik.errors.event_types && (
            <ErrorMessage>
              You need to select, at least, one Event Type
            </ErrorMessage>
          )}
        </div>
      </div>

      <div>
        <Button type="submit" loading={submitting} disabled={!formik.dirty}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default WebhookForm
