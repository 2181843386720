import React, { FC } from 'react'
import 'twin.macro'

import EditCreativeForm from './components/edit-creative-form'
import ArchiveCreativeForm from './components/archive-creative-form'

import SettingsPanel from '../../../../../components/settings-panel'

import { CreativeFullFragment } from '../../../../../graphql/components'

export interface SettingsSectionProps {
  creative?: CreativeFullFragment
  refetchCreative?: () => Promise<any>
}

const SettingsSection: FC<SettingsSectionProps> = ({
  creative,
  refetchCreative,
}) => (
  <section>
    <SettingsPanel
      sections={[
        {
          title: 'Details',
          content: (
            <EditCreativeForm
              creative={creative}
              refetchCreative={refetchCreative}
            />
          ),
        },
        {
          title: 'Archive',
          content: <ArchiveCreativeForm creative={creative} />,
        },
      ]}
    />
  </section>
)

export default SettingsSection
