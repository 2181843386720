import React, { FC, useState, useEffect } from 'react'

import tw from 'twin.macro'
import moment from 'moment'

import { useFormik } from 'formik'
import { string, object, date, number } from 'yup'

import Button from '../../../../../../../ui-blocks/button'
import Spacer from '../../../../../../../ui-blocks/spacer'
import Checkbox from '../../../../../../../ui-blocks/checkbox'
import Dropdown from '../../../../../../../ui-blocks/dropdown'
import CardResource from '../../../../../../../ui-blocks/card-resource'
import Input, { InputFormik } from '../../../../../../../ui-blocks/input'
import { DateTimeRangePicker } from '../../../../../../../ui-blocks/datetime-picker'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../../components/alert-component'
import ErrorMessage from '../../../../../../../components/error-message'

import {
  SATURATION_TYPES,
  SATURATION_LABELS,
  SATURATION_HELPER_TEXTS,
} from '../../../../create/steps/schedule'
import { AUDIENCE_AREA_TYPES } from '../../../../create/steps/audience'

import {
  TimeframeInput,
  CampaignAdGroupFullFragment,
  CampaignAdGroupAudienceInput,
  useUpdateCampaignAdGroupByIdMutation,
  EnumCampaignAdGroupAudienceArea_Type,
} from '../../../../../../../graphql/components'

import { trimValues } from '../../../../../../../utils/data-manipulation'
import { ITimetable } from '../../../../../../../components/timetable/types'
import {
  timetableRuleSchema,
  timetableSchema,
} from '../../../../../../../components/timetable/schemas'
import { TimetableInput as TimetableEditorInput } from '../../../../../../../components/timetable/timetable-input'

interface EditAdGroupFormikValues {
  name: string
  redirect_to: string
  saturation?: number | string
  timeframe: TimeframeInput
  audience: CampaignAdGroupAudienceInput
  timetable: ITimetable
}

const editAdGroupValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  saturation: number()
    .positive('Must be a number greater than zero')
    .nullable(),
  redirect_to: string()
    .trim('Value cannot have leading or trailing white spaces')
    .url('Must be a valid URL')
    .required('Redirect to is required')
    .strict(true),
  timeframe: object()
    .shape({
      starts_at: date().required('Start date is required'),
      ends_at: date().required('End date is required'),
    })
    .required(),
  audience: object().shape({
    area_type: string()
      .required()
      .oneOf([
        EnumCampaignAdGroupAudienceArea_Type.Indoor,
        EnumCampaignAdGroupAudienceArea_Type.Outdoor,
        EnumCampaignAdGroupAudienceArea_Type.Both,
      ]),
  }),
  timetable: timetableSchema,
})

export interface EditAdGroupFormProps {
  campaignAdGroup?: CampaignAdGroupFullFragment
  refetchCampaignAdGroup?: () => Promise<any>
}

const EditAdGroupForm: FC<EditAdGroupFormProps> = ({
  campaignAdGroup,
  refetchCampaignAdGroup,
}) => {
  const [updateAdGroupError, setUpdateAdGroupError] = useState<string>('')
  const [isSaturationEnabled, setIsSaturationEnabled] = useState<boolean>(
    !!campaignAdGroup?.saturation
  )

  const [isTimetableEnabled, setIsTimetableEnabled] = useState<boolean>(
    (campaignAdGroup?.timetable?.rules || []).length > 0 || false
  )

  const [multiplierValue, setMultiplierValue] = useState<number | ''>('')
  const [percentageValue, setPercentageValue] = useState<number | ''>('')
  const [selectedSaturation, setSelectedSaturation] = useState<string>(
    SATURATION_TYPES.MULTIPLIER
  )

  const [updateAdGroup] = useUpdateCampaignAdGroupByIdMutation()
  const formik = useFormik<EditAdGroupFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editAdGroupValidationSchema,
    initialValues: {
      name: campaignAdGroup?.name || '',
      saturation: campaignAdGroup?.saturation || '',
      redirect_to: campaignAdGroup?.redirect_to || '',
      timeframe: {
        starts_at: campaignAdGroup?.timeframe?.starts_at || undefined,
        ends_at: campaignAdGroup?.timeframe?.ends_at || undefined,
      },
      audience: {
        area_type:
          campaignAdGroup?.audience?.area_type ||
          EnumCampaignAdGroupAudienceArea_Type.Indoor,
      },
      //TODO: Is there a better way of doing this?
      timetable: {
        rules: campaignAdGroup?.timetable?.rules
          ? campaignAdGroup?.timetable.rules?.map((rule) => {
              const ruleCast = trimValues(timetableRuleSchema.cast(rule), [
                '__typename',
              ])
              return {
                weekdays: ruleCast.weekdays,
                allowed: ruleCast.allowed,
                start: ruleCast.start,
                end: ruleCast.end,
              }
            })
          : [],
        defaultAllowance: campaignAdGroup?.timetable?.defaultAllowance || true,
      },
    },
    async onSubmit(values) {
      try {
        setUpdateAdGroupError('')
        const { timeframe, ...newValues } = trimValues(values, [
          'name',
          'redirect_to',
        ])

        const updatedTimeframe =
          !moment(timeframe.starts_at).isSame(
            moment(formik.initialValues.timeframe.starts_at)
          ) ||
          !moment(timeframe.ends_at).isSame(
            moment(formik.initialValues.timeframe.ends_at)
          )

        const res = await updateAdGroup({
          variables: {
            _id: campaignAdGroup?._id,
            input: {
              ...newValues,
              ...(updatedTimeframe ? { timeframe } : {}),
              saturation:
                !!newValues.saturation &&
                typeof newValues.saturation === 'number'
                  ? newValues.saturation
                  : null,
              timetable: newValues.timetable,
            },
          },
        })

        if (!!(res.errors || []).length) {
          return setUpdateAdGroupError(
            "We couldn't update this ad group. Please try again later."
          )
        }

        await refetchCampaignAdGroup?.()
      } catch {
        setUpdateAdGroupError(
          'Something went wrong while trying to update this ad group. Please try again later.'
        )
      }
    },
  })

  useEffect(() => {
    if (!!campaignAdGroup?.saturation) {
      setMultiplierValue(campaignAdGroup.saturation)
      setPercentageValue(campaignAdGroup.saturation * 100)
    }
  }, [campaignAdGroup])

  useEffect(() => {
    if (!formik.values.saturation) {
      setMultiplierValue('')
      setPercentageValue('')
    }
  }, [formik.values.saturation])

  const handleSaturation = (prevSaturation: boolean) => {
    prevSaturation && formik.setFieldValue('saturation', '')
    return !prevSaturation
  }

  const handleChangeSaturation = (e: React.ChangeEvent<HTMLInputElement>) => {
    let actualValue: number | '' = '',
      percentageValue: number | '' = ''
    if (!!e.target.value) {
      const inputValue = Number(e.target.value)
      actualValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? inputValue
          : inputValue / 100
      percentageValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? actualValue * 100
          : inputValue
    }

    setMultiplierValue(actualValue)
    setPercentageValue(percentageValue)
    formik.setFieldValue('saturation', actualValue)
  }

  const saturationOptions = Object.keys(SATURATION_LABELS).map((type) => ({
    value: type,
    label: SATURATION_LABELS[type],
  }))

  const handleEnableTimetable = (prevTimetable: boolean) => {
    formik.setValues({
      ...formik.values,
      timetable: prevTimetable
        ? { rules: [], defaultAllowance: true }
        : formik.initialValues.timetable,
    })

    setIsTimetableEnabled?.(!prevTimetable)
    return !prevTimetable
  }

  return (
    <form onSubmit={formik.handleSubmit} tw="max-w-xl">
      {!!updateAdGroupError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              message: updateAdGroupError,
              id: 'update-ad-group-error-alert',
            }}
          />
        </div>
      )}
      <section tw="mb-12 max-w-xs">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Information
        </span>
        <InputFormik
          type="text"
          name="name"
          label="Name"
          onChange={formik.handleChange}
          formik={formik}
        />
        <Spacer size="1rem" />
        <InputFormik
          type="text"
          name="redirect_to"
          label="URL"
          placeholder="https://your-domain.com"
          helperText="Insert the URL that you desire your target audience to interact with."
          onChange={formik.handleChange}
          formik={formik}
        />
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Schedule
        </span>
        <DateTimeRangePicker
          label="Date"
          minDate={
            !!formik.values.timeframe.starts_at &&
            moment(formik.values.timeframe.starts_at).isBefore(moment())
              ? moment(formik.values.timeframe.starts_at)
              : moment()
          }
          startDate={
            !!formik.values.timeframe.starts_at
              ? moment(formik.values.timeframe.starts_at)
              : undefined
          }
          endDate={
            !!formik.values.timeframe.ends_at
              ? moment(formik.values.timeframe.ends_at)
              : undefined
          }
          success={
            !!formik.touched.timeframe?.starts_at &&
            !!formik.touched.timeframe?.ends_at &&
            !formik.errors.timeframe?.starts_at &&
            !formik.errors.timeframe?.ends_at
          }
          error={
            formik.errors.timeframe?.starts_at?.toString() ||
            formik.errors.timeframe?.ends_at?.toString()
          }
          onChange={(start, end) =>
            formik.setValues({
              ...formik.values,
              timeframe: { starts_at: start, ends_at: end },
            })
          }
        />
      </section>
      <section tw="mb-12">
        <p tw="font-medium mb-4">Timetable Restrictions</p>

        <Checkbox
          checked={isTimetableEnabled}
          onChange={() =>
            setIsTimetableEnabled(handleEnableTimetable(isTimetableEnabled))
          }
          label="Assign specific run times"
        />

        {isTimetableEnabled && (
          <TimetableEditorInput
            tw="mt-4"
            value={timetableSchema.cast(formik.values.timetable)}
            onChange={(timetable: ITimetable) =>
              formik.setValues({
                ...formik.values,
                timetable: {
                  rules: timetable.rules,
                  defaultAllowance: true,
                },
              })
            }
            error={formik.errors?.timetable}
          />
        )}
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Saturation
        </span>
        <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
          The Ad Group's saturation will define how many times your Ad Group
          will be displayed related to the other Ad Groups inside your campaign.
          For instance, if you choose a multiplier of 2, this means your Ad
          Group will be shown two times more than a Ad Group with a multiplier
          of 1.
        </span>
        <Checkbox
          checked={isSaturationEnabled}
          onChange={() => setIsSaturationEnabled(handleSaturation)}
          label="Assign saturation"
        />
        <div
          css={[
            tw`mt-4`,
            !isSaturationEnabled && tw`opacity-50 cursor-not-allowed`,
          ]}
        >
          <div tw="flex flex-row items-center mb-2">
            <div css={['max-width: 12.5rem;', tw`flex-grow mr-2`]}>
              <Dropdown
                isClearable={false}
                isSearchable={false}
                placeholder="Choose type"
                options={saturationOptions}
                isDisabled={!isSaturationEnabled}
                success={formik.touched.saturation && !formik.errors.saturation}
                defaultValue={saturationOptions.find(
                  (option) => option.value === selectedSaturation
                )}
                onChange={(option) =>
                  setSelectedSaturation(option ? option.value : '')
                }
              />
            </div>
            <div css={['max-width: 7.5rem;', tw`flex-grow`]}>
              {selectedSaturation === SATURATION_TYPES.MULTIPLIER && (
                <Input
                  step="0.01"
                  type="number"
                  placeholder="x"
                  hideSteppers
                  value={multiplierValue}
                  disabled={!isSaturationEnabled}
                  required={isSaturationEnabled && !!selectedSaturation}
                  success={
                    formik.touched.saturation && !formik.errors.saturation
                  }
                  onChange={handleChangeSaturation}
                />
              )}
              {selectedSaturation === SATURATION_TYPES.PERCENTAGE && (
                <Input
                  step="1"
                  type="number"
                  placeholder="%"
                  hideSteppers
                  value={percentageValue}
                  disabled={!isSaturationEnabled}
                  required={isSaturationEnabled && !!selectedSaturation}
                  success={
                    formik.touched.saturation && !formik.errors.saturation
                  }
                  onChange={handleChangeSaturation}
                />
              )}
            </div>
          </div>
          <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
            {SATURATION_HELPER_TEXTS[selectedSaturation] ||
              'Choose a number based on a multiplier (ex: 1.5x, 1.0x, 0.5x) or a percentage (ex: 100%, 200%, 50%)'}
          </span>
          {!!formik.errors.saturation && (
            <ErrorMessage>{formik.errors.saturation}</ErrorMessage>
          )}
        </div>
      </section>
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Audience
        </span>
        <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
          Improve your Ad Group by defining your Target Audience.
        </span>
        <span tw="font-medium text-xs text-dark-blue-gray leading-tight tracking-wider uppercase block mb-6">
          Area type
        </span>
        <div tw="flex flex-row flex-wrap items-stretch justify-around gap-6">
          {AUDIENCE_AREA_TYPES.map((areaType, index) => (
            <CardResource
              type="radio"
              name="audience.area_type"
              key={`area-type#${index}`}
              icon={areaType.icon}
              title={areaType.title}
              value={areaType.value}
              description={areaType.description}
              checked={areaType.value === formik.values.audience.area_type}
              onClick={() =>
                formik.setFieldValue('audience.area_type', areaType.value)
              }
            />
          ))}
        </div>
      </section>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update Ad Group details
      </Button>
    </form>
  )
}

export default EditAdGroupForm
