import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'

export interface TooltipProps {
  content: string
}

const Tooltip: FC<TooltipProps> = ({ content, children }) => (
  <TooltipWrapper>
    <div data-tooltip={content}>{children}</div>
  </TooltipWrapper>
)

const TooltipWrapper = styled.div`
  [data-tooltip] {
    ${tw`relative`}
  }
  [data-tooltip]:hover::before {
    top: 100%;
    left: -8.25rem;
    width: 16.5rem;
    content: attr(data-tooltip);
    ${tw`absolute p-4 mt-3 bg-white border text-dark-blue-gray border-platinum`}
  }
`

export default Tooltip
