import React, { FC, Fragment } from 'react'
import 'twin.macro'

import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

const ExternalCampaignsEmpty: FC = () => (
  <Fragment>
    <div tw="flex flex-col items-center">
      <Illustration name={IllustrationEnum.campaigns_empty_state} tw="mb-10" />
      <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
        You have no external campaigns yet.
      </h1>
      <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
        But you are all set for when you do!
      </p>
    </div>
  </Fragment>
)

export default ExternalCampaignsEmpty
