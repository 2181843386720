import React, { FC, useState } from 'react'

import tw from 'twin.macro'
import { useParams, useNavigate } from 'react-router'

import Suspense from '../../../../ui-blocks/suspense'
import Container from '../../../../ui-blocks/container'

import NotFound from '../../../../components/not-found'
import SetupForm from '../../../../components/setup-form'
import LoadingPage from '../../../../components/loading-page'

import AdGroupDetailsStep, {
  adGroupDetailsValidationSchema,
} from './steps/details'
import CreativesStep, {
  creativesValidationSchema,
} from './steps/target-creatives'
import AudienceStep, { audienceValidationSchema } from './steps/audience'
import ScheduleStep, { scheduleValidationSchema } from './steps/schedule'

import {
  TimeframeInput,
  useViewCampaignQuery,
  CampaignAdGroupAudienceInput,
  EnumCampaignAdGroupAudienceArea_Type,
  useCreateCampaignAdGroupMutation,
} from '../../../../graphql/components'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'
import { ITimetable } from '../../../../components/timetable/types'

export interface CreateAdGroupFormikValues {
  name: string
  saturation: number
  redirect_to: string
  creatives_ids: string[]
  timeframe: TimeframeInput
  audience: CampaignAdGroupAudienceInput
  timetable: ITimetable
}

const ViewCampaignAdGroupPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_id } = useParams()

  const [isSaturationEnabled, enableSaturation] = useState<boolean>(false)
  const { data: campaignData, loading } = useViewCampaignQuery({
    skip: !campaign_id,
    fetchPolicy: 'cache-first', // @TODO: check why this is necessary
    variables: { _id: campaign_id },
  })

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-ad-group-error-alert',
      message: "We couldn't create the ad group. Please try again later.",
    })
  }

  const [createAdGroup] = useCreateCampaignAdGroupMutation()
  const handleSubmit = async (values: CreateAdGroupFormikValues) => {
    try {
      const res = await createAdGroup({
        variables: {
          input: {
            campaign_id,
            name: values.name,
            audience: values.audience,
            timeframe: values.timeframe,
            redirect_to: values.redirect_to,
            creatives_ids: values.creatives_ids,
            saturation: !!values.saturation
              ? Number(values.saturation)
              : undefined,
            timetable: values.timetable,
          },
        },
      })

      const adGroupId = res.data?.campaignAdGroup?._id
      if (!adGroupId) {
        handleError()
        return false
      }

      document.dispatchEvent(new CustomEvent('refetch-campaign-ad-groups'))
      navigate(`/campaigns/${campaign_id}/ad-groups/${adGroupId}`)
      return true
    } catch {
      handleError()
      return false
    }
  }

  const { campaign } = campaignData || {}
  return (
    <Suspense ready={!loading} fallback={<LoadingPage />}>
      <Suspense ready={!!campaign} fallback={<NotFound />}>
        <Container>
          <div css={[tw`w-full self-center`, 'max-width: 68rem;']}>
            <SetupForm
              title="New Ad Group"
              description="Group your creatives, schedule when and to which audience you want to target these to and what's the URL you want the audience to engage with."
              onSubmit={{ 3: handleSubmit }}
              initialValues={{
                name: '',
                saturation: '',
                redirect_to: '',
                creatives_ids: [],
                timeframe: { starts_at: undefined, ends_at: undefined },
                audience: {
                  area_type: EnumCampaignAdGroupAudienceArea_Type.Indoor,
                },
                timetable: {
                  rules: [],
                  defaultAllowance: true,
                },
              }}
              steps={[
                {
                  canGoBack: true,
                  title: 'Details',
                  description: 'Enter the Ad Group details',
                  content: <AdGroupDetailsStep />,
                  validationSchema: adGroupDetailsValidationSchema,
                },
                {
                  canGoBack: true,
                  title: 'Creatives',
                  description:
                    'Select one or more creatives for your Ad Group.',
                  content: <CreativesStep />,
                  disabledTriggers: ['creatives_ids'],
                  validationSchema: creativesValidationSchema,
                },
                {
                  canGoBack: true,
                  title: 'Audience',
                  description:
                    'Improve your Ad Group by defining your Target Audience.',
                  content: <AudienceStep />,
                  validationSchema: audienceValidationSchema,
                },
                {
                  canGoBack: true,
                  title: 'Schedule',
                  description:
                    'Determine "when" and the "frequency" that your ads will play',
                  validationSchema: scheduleValidationSchema,
                  disabledTriggers: isSaturationEnabled ? ['saturation'] : [],
                  content: (
                    <ScheduleStep onEnableSaturation={enableSaturation} />
                  ),
                },
              ]}
            />
          </div>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewCampaignAdGroupPage
