import React, { FC } from 'react'
import 'twin.macro'

import Logo from '../ui-blocks/logo'

import Cover from '../components/cover'

const AuthLayout: FC = ({ children }) => (
  <div tw="flex bg-white h-full min-h-screen">
    <div tw="w-2/4 flex justify-center">
      <div tw="w-full max-w-3/4 p-6">
        <div tw="inline-block self-start mb-20">
          <Logo />
        </div>
        <div tw="max-w-md">{children}</div>
      </div>
    </div>
    <div
      title="Diffuse.tv"
      tw="relative w-2/4 bg-cover bg-center bg-no-repeat bg-purple"
    >
      <Cover />
    </div>
  </div>
)

export default AuthLayout
