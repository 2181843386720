import React, { FC } from 'react'

import tw, { styled } from 'twin.macro'
import { Link } from 'react-router-dom'

import Icon from '../../ui-blocks/icon'
import Card from '../../ui-blocks/card'
import Status from '../../ui-blocks/status'

import InlineDate from '../inline-date'
import UploadThumbnail from '../upload/thumbnail'

import {
  EnumUploadType,
  EnumCreativeStatus,
  CreativeListFragment,
} from '../../graphql/components'

import { formatDuration } from '../../utils/formaters'
import { getDurationFromUpload } from '../../utils/data-manipulation'

import { ReactComponent as PlayFilledIcon } from '../../assets/icons/play_filled.svg'

export interface CreativeCardProps {
  creative: CreativeListFragment
  onClickThumbnail?: () => void
}

const CreativeCard: FC<CreativeCardProps> = ({
  creative,
  onClickThumbnail,
}) => {
  const isArchived = creative.status === EnumCreativeStatus.Archived
  const Wrapper = !isArchived ? Link : 'div'

  return (
    <Card css={[tw`overflow-hidden`, 'transform: translateZ(0);']}>
      <div tw="w-full">
        <UploadThumbnail
          upload={creative.upload as any}
          onClick={onClickThumbnail}
        />
      </div>
      <Wrapper to={`/creatives/${creative._id}`} tw="block p-4 truncate">
        <div tw="flex flex-row items-center justify-between mb-1">
          <p title={creative.name} tw="font-medium text-charcoal truncate">
            {creative.name}
          </p>
          {creative.upload?.type === EnumUploadType.Image && (
            <Icon
              icon="image"
              tw="flex-shrink-0 text-sm text-dark-blue-gray ml-2"
            />
          )}
          {creative.upload?.type === EnumUploadType.Video && (
            <div tw="flex flex-row flex-shrink-0 items-center text-dark-blue-gray ml-2">
              <ColoredPlayFilledIcon />
              <span tw="ml-1 text-xs">
                {formatDuration(
                  getDurationFromUpload((creative.upload as any) || undefined)
                )}
              </span>
            </div>
          )}
        </div>
        <div tw="flex flex-row justify-between mb-2">
          <InlineDate
            date={creative?.upload?.created?.at}
            tw="font-normal text-xs text-dark-blue-gray"
          />
        </div>
        <Status compact value={creative.status as EnumCreativeStatus} />
      </Wrapper>
    </Card>
  )
}

const ColoredPlayFilledIcon = styled(PlayFilledIcon)`
  ${tw`w-3.5 h-3.5`}
  & path {
    ${tw`fill-current`}
  }
`

export default CreativeCard
