import React, { FC, useEffect, useMemo, useState } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router'

import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import Text from '../../../ui-blocks/text'
import Link from '../../../ui-blocks/link'
import Button from '../../../ui-blocks/button'
import Suspense from '../../../ui-blocks/suspense'
import ButtonLink from '../../../ui-blocks/button-link'

import WorkspaceCard from './components/workspace-card'

import LoadingPage from '../../../components/loading-page'
import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import {
  useViewNetworkShareLinkQuery,
  useAddExternalNetworkToWorkspacesMutation,
  useViewNetworkQuery,
} from '../../../graphql/components'
import { MutationResult } from '../../../typings'

import { authentication } from '../../../stores'

interface AddExternalNetworkFormikValues {
  workspaceIds: string[]
}

const addExternalNetworkValidationSchema = object().shape({
  workspaceIds: array(string()).min(1).required(),
})

const NetworksManagement: FC = () => {
  const navigate = useNavigate()
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const { invite_token } = useParams()
  const decodedToken = decodeURIComponent(invite_token)

  const {
    data: shareLinkData,
    loading: loadingShareLink,
    error: shareLinkError,
  } = useViewNetworkShareLinkQuery({
    skip: !decodedToken,
    variables: { invite_token: decodedToken },
  })

  const networkId = useMemo(() => shareLinkData?.info?.network_id || '', [
    shareLinkData,
  ])

  const { data: networkData, loading: loadingNetwork } = useViewNetworkQuery({
    skip: !networkId,
    variables: {
      _id: networkId,
    },
  })

  const network = useMemo(() => networkData?.network || undefined, [
    networkData,
  ])
  const overviewLocation = authentication.is_authenticated
    ? `/workspaces/${authentication.selected_workspace._id}/overview`
    : '/'
  useEffect(() => {
    if (!loadingShareLink) {
      // Store the token if the user is not yet authenticated
      if (!authentication.is_authenticated) {
        authentication.addNetworkToken(decodedToken)
        return navigate('/auth/sign-in')
      } else if (!shareLinkData && !shareLinkError) {
        return navigate(overviewLocation)
      }
    }
  }, [loadingShareLink])

  const [
    addExternalNetwork,
    { loading: addingExternalNetwork },
  ] = useAddExternalNetworkToWorkspacesMutation()
  const formik = useFormik<AddExternalNetworkFormikValues>({
    validateOnChange: false,
    validationSchema: addExternalNetworkValidationSchema,
    initialValues: { workspaceIds: [] },
    async onSubmit({ workspaceIds }) {
      try {
        setMutationResult(null)
        const { data } = await addExternalNetwork({
          variables: {
            invite_token: decodedToken,
            ids: workspaceIds,
          },
        })
        if (!data?.areAdded) return setMutationResult('error')
        authentication.removeNetworkToken(decodedToken)
        setMutationResult('success')
      } catch {
        setMutationResult('error')
      }
    },
  })

  const onCancel = () => {
    authentication.removeNetworkToken(decodedToken)
    navigate(-1)
  }

  const doesWorkspaceHaveTheNetwork = (workspaceId: string): boolean => {
    const isOwnerOfNetwork =
      workspaceId === String(network?.ownership?.workspace_id)

    const hasBeenSharedWith = network?.sharedWithWorkspaces?.some(
      (wspace) =>
        String(wspace?.workspace_id) === workspaceId && !wspace?.revoked?.at
    )

    return isOwnerOfNetwork || !!hasBeenSharedWith
  }

  const handleSelectWorkspace = (workspaceId: string) => {
    if (doesWorkspaceHaveTheNetwork(workspaceId)) {
      return
    }

    if (!formik.values.workspaceIds.includes(workspaceId)) {
      formik.setFieldValue('workspaceIds', [
        ...formik.values.workspaceIds,
        workspaceId,
      ])
      return
    }

    formik.setFieldValue(
      'workspaceIds',
      formik.values.workspaceIds.filter((id) => id !== workspaceId)
    )
  }

  // const assignedWorkspaceIds = (shareLinkData?.info?.workspaces || []).map(
  //   (workspace) => !workspace?.revoked?.at && workspace?.workspace_id
  // )
  // const revokedWorkspaceIds = (shareLinkData?.info?.workspaces || []).map(
  //   (workspace) => !!workspace?.revoked?.at && workspace?.workspace_id
  // )

  return !shareLinkError ? (
    <Suspense ready={!loadingShareLink} fallback={<LoadingPage />}>
      <Text as="h1" preset="h2" tw="mb-1">
        New External Network
      </Text>
      <Text as="h2" preset="h5" tw="mb-10">
        {mutationResult === 'success'
          ? 'The external network was successfully introduced to your workspaces. It can now be selected as a campaign target.'
          : 'You have been granted access to an external network!'}
      </Text>
      {mutationResult === 'error' && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              dismissable: true,
              variant: AlertVariant.ERROR,
              id: 'add-external-network-error-alert',
              message:
                "We couldn't add that external network to the workspaces. Please try again later.",
            }}
          />
        </div>
      )}
      <Suspense
        ready={mutationResult !== 'success' && !loadingNetwork}
        fallback={<ButtonLink to={overviewLocation}>Ok</ButtonLink>}
      >
        <Text as="h3" preset="p1" tw="mb-1">
          {shareLinkData?.info?.network_name}
        </Text>
        <Text as="h3" preset="p2" tw="mb-12">
          Sent by <b>{shareLinkData?.info?.sent_by}</b>
        </Text>
        <Text as="h2" preset="h5" tw="mb-6">
          Select the Workspaces you want to introduce this network to:
        </Text>
        <form onSubmit={formik.handleSubmit}>
          <div tw="w-full space-y-4 mb-20">
            {authentication.workspaces.map((workspace) => (
              <WorkspaceCard
                workspace={workspace}
                key={`workspace-${workspace._id}`}
                isAdded={doesWorkspaceHaveTheNetwork(String(workspace._id))}
                //isRevoked={revokedWorkspaceIds.includes(workspace._id)}
                isSelected={formik.values.workspaceIds.includes(workspace._id)}
                onClick={() => handleSelectWorkspace(workspace._id)}
              />
            ))}
          </div>
          <div tw="flex flex-row items-center justify-between">
            <Link
              type="button"
              tw="p-0 min-w-auto min-h-auto inline-flex"
              onClick={onCancel}
            >
              Cancel
            </Link>
            <Button
              type="submit"
              disabled={!formik.dirty}
              loading={addingExternalNetwork}
            >
              Confirm
            </Button>
          </div>
        </form>
      </Suspense>
    </Suspense>
  ) : (
    <>
      <Text as="h1" preset="h2" tw="mb-1">
        New External Network
      </Text>
      <div tw="mb-4 mt-4">
        <AlertMessage
          alert={{
            dismissable: false,
            variant: AlertVariant.ERROR,
            id: 'add-external-network-error-alert',
            message: 'The invite link you tried to use is no longer valid.',
          }}
        />
      </div>
      <div tw="flex flex-row items-center justify-between">
        <Button type="button" onClick={onCancel}>
          Go Back
        </Button>
      </div>
    </>
  )
}

export default NetworksManagement
