import React, { FC, ReactNode } from 'react'
import tw from 'twin.macro'

import Card from '../ui-blocks/card'

export interface SettingsPanelSection {
  title: string
  content: ReactNode
}

export interface SettingsPanelProps {
  sections: SettingsPanelSection[]
}

const SettingsPanel: FC<SettingsPanelProps> = ({ sections }) => (
  <Card>
    {sections.map((section, index) => (
      <section key={`section#${index}`} tw="flex flex-row">
        <div
          css={[
            'width: 19rem;',
            tw`flex-grow-0 flex-shrink-0 bg-lighter-blue px-8`,
            index === 0 && tw`pt-10`,
            index === sections.length - 1 && tw`pb-10`,
          ]}
        >
          <span tw="font-medium text-charcoal leading-tight block">
            {section.title}
          </span>
        </div>
        <div
          css={[
            tw`px-8 flex-grow overflow-auto`,
            index === 0 && tw`pt-10`,
            index === sections.length - 1 && tw`pb-10`,
          ]}
        >
          {section.content}
          {index !== sections.length - 1 && <hr tw="bg-platinum my-6" />}
        </div>
      </section>
    ))}
  </Card>
)

export default SettingsPanel
