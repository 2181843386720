import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from '../ui-blocks/text'
import Card from '../ui-blocks/card'
import Icon from '../ui-blocks/icon'
import Spinner from '../ui-blocks/spinner'
import Suspense from '../ui-blocks/suspense'

import { Network, Campaign } from '../graphql/components'

export type AffectedNetwork = Pick<Network, 'name'>
export type AffectedCampaign = Pick<Campaign, 'name'>

export interface ItemsCardProps {
  title: string
  items: (AffectedNetwork | AffectedCampaign)[]
  loading?: boolean
}

const ItemsCard: FC<ItemsCardProps> = ({ title, items, loading }) => (
  <Card
    css={css`
      height: 18.5rem;
      /* Hide scrollbar for IE, Edge and Firefox */
      scrollbar-width: none;
      -ms-overflow-style: none;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      ${tw`relative flex-1 p-4 space-y-4 overflow-y-scroll`}
    `}
  >
    {loading && (
      <div tw="flex items-center justify-center absolute inset-0 bg-white-60">
        <Spinner />
      </div>
    )}
    <Text as="h4" weight="light" align="center" tw="leading-snug mt-0!">
      {title}
    </Text>
    <Suspense
      ready={!!items.length}
      fallback={
        <Text
          as="p"
          preset="p2"
          align="center"
        >{`No ${title} will be affected!`}</Text>
      }
    >
      {items.map((item, index) => (
        <Card
          hasShadow={false}
          key={`item#${index}`}
          tw="flex flex-row items-center w-full space-x-2 p-4"
        >
          <Icon icon="check" tw="flex-shrink-0 text-lg text-dark-blue-gray" />
          <Text as="p" preset="h4" tw="truncate">
            {item.name}
          </Text>
        </Card>
      ))}
    </Suspense>
  </Card>
)

export default ItemsCard
