import React, { FC } from 'react'
import 'twin.macro'
import { useParams } from 'react-router-dom'
import NoPaymentMethods from '../components/no-payment-methods'
import BillingInformation, {
  BillingInformationType,
} from '../components/billing-information'
import { useViewWorkspaceQuery } from '../../../../../graphql/components'
import history from '../../../../../history'
import { PaymentMethodType } from '../../../../../ui-blocks/payment-method'

const PaymentMethods: FC = () => {
  const hasPaymentMethods = true
  const params = useParams()
  const workspaceId = params?.workspace_id || ''

  if (!workspaceId) history.replace('/')
  const { data, refetch: refetchWorkspace } = useViewWorkspaceQuery({
    skip: !workspaceId,
    fetchPolicy: 'cache-first',
    variables: { _id: workspaceId },
  })
  const paymentMethods = data?.workspace?.payment_methods?.data || []
  const defaultPaymentMethod = data?.workspace?.default_payment_method || ''
  const billingInformation =
    paymentMethods.find((pm) => pm?.id === defaultPaymentMethod)
      ?.billing_details || {}
  const metadata =
    paymentMethods.find((pm) => pm?.id === defaultPaymentMethod)?.metadata || {}

  return (
    <div tw="mt-6">
      <h2 tw="font-medium text-charcoal mb-2">Payment methods</h2>
      <p tw="font-normal text-xs text-dark-blue-gray mb-6">
        Manage how you pay your bills in Diffuse.Tv.
      </p>
      {hasPaymentMethods ? (
        <BillingInformation
          refetchData={refetchWorkspace}
          paymentMethods={paymentMethods as PaymentMethodType[]}
          defaultPaymentMethod={defaultPaymentMethod}
          billingInformation={
            { ...billingInformation, ...metadata } as BillingInformationType
          }
        />
      ) : (
        <NoPaymentMethods />
      )}
    </div>
  )
}

export default PaymentMethods
