import React, { FC, useMemo, useState } from 'react'
import tw from 'twin.macro'

import jwtDecode from 'jwt-decode'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { object } from 'yup'
import { useFormik } from 'formik'

import Text from '../../../../ui-blocks/text'
import Link from '../../../../ui-blocks/link'
import Button from '../../../../ui-blocks/button'
import { InputFormik } from '../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'
import PasswordRestrictions from '../../../../components/password-restrictions'

import getInitials from '../../../../utils/get-initials'

import { useAuthForgotPasswordResetMutation } from '../../../../graphql/components'

import { authentication } from '../../../../stores'

import { passwordSchema } from '../../sign-up'
import Gravatar from '../../../../ui-blocks/gravatar'
import translateError from '../../../../utils/translate-error'

interface RecoveryTokenPayload {
  uid: string
  iat: number
  exp: number
  email: string
  name: {
    first: string
    last: string
  }
}

interface NewPasswordFormikValues {
  newPassword: string
  confirmPassword: string
}

const newPasswordValidationSchema = object().shape({
  newPassword: passwordSchema.clone().required('New password is required'),
  confirmPassword: passwordSchema
    .clone()
    .required('Confirm password is required'),
})

export interface NewPasswordStepProps {
  recoveryToken: string
  onNext?: (email: string, newPassword: string, reCaptchaToken?: string) => void
}

const NewPasswordStep: FC<NewPasswordStepProps> = ({
  recoveryToken,
  onNext,
}) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const user = useMemo(() => jwtDecode<RecoveryTokenPayload>(recoveryToken), [
    recoveryToken,
  ])
  const fullName = useMemo(() => `${user.name.first} ${user.name.last}`, [user])

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [resetPassword] = useAuthForgotPasswordResetMutation()

  const formik = useFormik<NewPasswordFormikValues>({
    validateOnChange: false,
    validationSchema: newPasswordValidationSchema,
    initialValues: { newPassword: '', confirmPassword: '' },
    async onSubmit({ newPassword, confirmPassword }, { setFieldError }) {
      setHasError(false)
      setErrorMessage('')
      if (confirmPassword !== newPassword)
        return setFieldError('confirmPassword', 'Passwords do not match')

      try {
        const reCaptchaToken = await executeRecaptcha?.(
          'auth/forgot_password/reset_password'
        )
        const res = await resetPassword({
          variables: { recoveryToken, password: newPassword },
          context: {
            headers: {
              ...(!!reCaptchaToken
                ? { 'X-Recaptcha-Token': reCaptchaToken }
                : {}),
            },
          },
        })
        if (!res.data?.authAccess) return setHasError(true)

        onNext?.(user.email, newPassword)
      } catch (err) {
        setHasError(true)
        setErrorMessage(translateError(err))
      }
    },
  })

  const redirectTo = authentication.is_authenticated
    ? `/workspaces/${authentication.selected_workspace._id}/overview`
    : '/auth/sign-in'

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      <Text as="h1" preset="h2" tw="mb-1">
        New password
      </Text>
      <Text
        as="p"
        preset="p1"
        css={[tw`text-dark-blue-gray`, hasError ? tw`mb-4` : tw`mb-10`]}
      >
        Set a new password to your account.
      </Text>
      {hasError && (
        <div tw="mb-10">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              id: 'forgot-password-reset-password-error-alert',
              message: errorMessage
                ? errorMessage
                : "We couldn't reset your password. Please try again later.",
            }}
          />
        </div>
      )}
      <div tw="flex flex-row items-center mb-10">
        <Gravatar
          largeFont
          size="4rem"
          variant="profile"
          email={user.email}
          initials={getInitials(fullName)}
        />
        <div tw="flex flex-col ml-4">
          <Text as="p" preset="p1" tw="text-dark-blue-gray">
            {fullName}
          </Text>
          <Text as="p" preset="p1">
            {user.email}
          </Text>
        </div>
      </div>
      <div tw="space-y-8 mb-4">
        <InputFormik
          type="password"
          name="newPassword"
          label="New Password"
          placeholder="*************"
          formik={formik}
        />
        <InputFormik
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          placeholder="*************"
          formik={formik}
        />
      </div>
      <PasswordRestrictions tw="mb-12" />
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
        tw="block mb-6"
      >
        Set new password
      </Button>
      <Link router={{ to: redirectTo }} tw="inline-flex min-h-auto p-0">
        Back to {authentication.is_authenticated ? 'overview' : 'sign in'}
      </Link>
    </form>
  )
}

export default NewPasswordStep
