import React, { FC } from 'react'
import tw from 'twin.macro'

export interface ContainerProps {
  hasBreakpoints?: boolean
}

const Container: FC<ContainerProps> = ({
  hasBreakpoints,
  children,
  ...props
}) => (
  <main
    css={[
      tw`w-full flex flex-col p-4 lg:p-8 lg:mx-auto`,
      hasBreakpoints && tw`lg:container`,
    ]}
    {...props}
  >
    {children}
  </main>
)

Container.defaultProps = {
  hasBreakpoints: true,
}

export default Container
