import React, { FC } from 'react'
import 'twin.macro'

import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

export interface NoSearchResultsProps {
  searchQuery: string
}

const NoSearchResults: FC<NoSearchResultsProps> = ({ searchQuery }) => (
  <div tw="w-full flex flex-col items-center mt-12">
    <Illustration name={IllustrationEnum.members_empty_results} tw="mb-5" />
    <p tw="text-charcoal">No Matches for &quot;{searchQuery}&quot;</p>
  </div>
)

export default NoSearchResults
