import React, { FC } from 'react'

import tw, { styled } from 'twin.macro'
import ReactPaginate from 'react-paginate'

import Icon from './icon'

export interface PaginationNavProps {
  page: number
  count: number
  limit?: number
  setPage?: (page: number) => void
}

const PaginationNav: FC<PaginationNavProps> = ({
  page,
  count,
  limit = 15,
  setPage,
  ...props
}) =>
  !!count ? (
    <PaginationNavWrapper {...props}>
      <span tw="font-normal text-dark-blue-gray leading-tight mr-6">
        {`${limit * page + 1} - ${Math.min(
          limit * page + limit,
          count
        )} out of ${count} total`}
      </span>
      <ReactPaginate
        initialPage={0}
        forcePage={page}
        pageClassName="page"
        pageRangeDisplayed={5}
        marginPagesDisplayed={5}
        pageCount={Math.ceil(count / limit)}
        onPageChange={({ selected }) => setPage?.(selected)}
        nextLabel={<Icon icon="chevron-right" tw="text-lg" title="Next page" />}
        previousLabel={
          <Icon icon="chevron-left" tw="text-lg" title="Previous page" />
        }
      />
    </PaginationNavWrapper>
  ) : null

const PaginationNavWrapper = styled.div`
  ${tw`flex flex-row flex-grow flex-shrink-0 items-center justify-end`}
  ul {
    ${tw`select-none flex flex-row items-center`}
    & > li {
      ${tw`text-dark-blue-gray`}
      & > a {
        outline: none;
      }
      &.selected {
        ${tw`text-charcoal`}
      }
      &.disabled {
        ${tw`text-platinum`}
        & i {
          ${tw`hover:cursor-not-allowed`}
        }
      }
      &.page {
        ${tw`mx-1`}
      }
    }
  }
`

export default PaginationNav
