import React, { FC, useState } from 'react'

import tw from 'twin.macro'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import Text from '../../../../ui-blocks/text'
import Link from '../../../../ui-blocks/link'
import Button from '../../../../ui-blocks/button'
import CodeInput from '../../../../ui-blocks/code-input'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import {
  ForgotPasswordMethod,
  useAuthForgotPasswordSendCodeMutation,
  useAuthForgotPasswordVerifyCodeMutation,
} from '../../../../graphql/components'

interface VerifyCodeFormikValues {
  code: string
}

const verifyCodeValidationSchema = object().shape({
  code: string().required(),
})

export interface VerifyCodeStepProps {
  contact: string
  method: ForgotPasswordMethod
  goBack?: () => void
  onNext?: (recoveryToken: string) => void
}

const VerifyCodeStep: FC<VerifyCodeStepProps> = ({
  contact,
  method,
  onNext,
  goBack,
}) => {
  const [hasError, setHasError] = useState<boolean>(false)

  const [
    sendCode,
    { loading: sendingCode },
  ] = useAuthForgotPasswordSendCodeMutation()
  const resendCode = async () => {
    await sendCode({
      variables: { method, identifier: contact },
    })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [verifyCode] = useAuthForgotPasswordVerifyCodeMutation()

  const formik = useFormik<VerifyCodeFormikValues>({
    validateOnChange: false,
    validationSchema: verifyCodeValidationSchema,
    initialValues: { code: '' },
    async onSubmit({ code }) {
      setHasError(false)
      try {
        const reCaptchaToken = await executeRecaptcha?.(
          'auth/forgot_password/verify_code'
        )
        const res = await verifyCode({
          variables: { code, method, identifier: contact },
          context: {
            headers: {
              ...(!!reCaptchaToken
                ? { 'X-Recaptcha-Token': reCaptchaToken }
                : {}),
            },
          },
        })

        const token = res.data?.recoveryToken
        if (!token) return setHasError(true)
        onNext?.(token)
      } catch {
        setHasError(true)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text as="h1" preset="h2" tw="mb-1">
        Verification code
      </Text>
      <Text
        as="p"
        preset="p1"
        css={[tw`text-dark-blue-gray`, hasError ? tw`mb-4` : tw`mb-10`]}
      >
        We sent a six-digit code to <b tw="text-charcoal">{contact}</b>. Enter
        the code below to confirm your
        {` ${
          method === ForgotPasswordMethod.Email
            ? 'email address'
            : 'phone number'
        }.`}
      </Text>
      {hasError && (
        <div tw="mb-10">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              id: 'forgot-password-verify-code-error-alert',
              message:
                "We couldn't verify the specified code. Please try again later.",
            }}
          />
        </div>
      )}
      <CodeInput
        length={6}
        label="Verification code"
        value={formik.values.code}
        error={formik.errors.code}
        success={formik.touched.code && !formik.errors.code}
        onChange={(code) => formik.setFieldValue('code', code)}
        labelExtra={
          <Link
            secondary
            disabled={sendingCode}
            onClick={resendCode}
            tw="p-0 inline-flex min-w-auto min-h-auto"
          >
            Resend code
          </Link>
        }
      />
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
        tw="block mt-20 mb-6"
      >
        Confirm
      </Button>
      <Link tw="inline-flex min-w-auto min-h-auto p-0 mb-6" onClick={goBack}>
        Back
      </Link>
      <span tw="font-normal text-xs text-dark-blue-gray leading-snug block">
        Make sure to keep this window open while you check your inbox.
        {method === ForgotPasswordMethod.Email
          ? ' and that the email address you provided is linked to an account. If you don’t receive any email in the next minutes try a differente email account or the phone number recovery method.'
          : ' and that the phone number you provided is linked to an account. If you don’t receive any text in the next minutes try a differente phone number or the email recovery method.'}
      </span>
      {method === ForgotPasswordMethod.Email && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-snug block mt-2">
          <span tw="underline">NOTE:</span> The email may take up to 2 minutes
          to land on your inbox. Do not forget verify your SPAM folder in case
          you do not see the email before that time is up.
        </span>
      )}
    </form>
  )
}

export default VerifyCodeStep
