import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../../ui-blocks/icon'

import { NetworkSharedWithWorkspaces } from '../../../../graphql/components'

export interface ShareLinkInfoProps {
  workspaces: NetworkSharedWithWorkspaces[]
}

const ShareLinkInfo: FC<ShareLinkInfoProps> = ({ workspaces }) => {
  if (!workspaces.length) return <span>-</span>
  const activeWorkspaces = workspaces.filter(
    (workspace) => !workspace.revoked?.at
  )

  return (
    <div tw="flex flex-col items-end">
      <div tw="flex flex-row items-center text-charcoal">
        <Icon
          icon="link"
          tw="text-base text-charcoal mr-1 transform rotate-45"
        />
        <span>Shared</span>
      </div>
      <span tw="font-medium text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
        {`${activeWorkspaces.length} Workspace(s)`}
      </span>
    </div>
  )
}

export default ShareLinkInfo
