import React, { FC } from 'react'
import 'twin.macro'

import Text from '../../../../../ui-blocks/text'

import UploadDuration from '../../../../../components/upload/duration'
import UploadThumbnail from '../../../../../components/upload/thumbnail'

import { CreativeListFragment } from '../../../../../graphql/components'

export interface CreativesTableProps {
  creatives: CreativeListFragment[]
}

const CreativesTable: FC<CreativesTableProps> = ({ creatives }) => (
  <table tw="w-full">
    <tbody tw="border-t border-platinum-50">
      {creatives.map((creative) => (
        <tr key={creative._id} tw="border-b border-platinum-50">
          <td>
            <div tw="w-16">
              <UploadThumbnail upload={creative.upload || undefined} />
            </div>
          </td>
          <td tw="py-2">
            <Text as="span" preset="p1" tw="text-xs">
              {creative.name}
            </Text>
          </td>
          <td tw="py-2">
            <Text as="span" preset="p1" tw="text-xs">
              {creative.upload?.type.toUpperCase()}
            </Text>
          </td>
          <td tw="py-2">
            <UploadDuration upload={creative?.upload as any} tw="text-xs" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default CreativesTable
