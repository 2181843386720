import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../../../../../ui-blocks/icon'

const ACTIONS = {
  clear: { label: 'Clear', icon: <Icon icon="circle-error" tw="text-base" /> },
  submit: {
    label: 'Submit',
    icon: <Icon icon="circle-arrow-right" tw="text-base" />,
  },
  cancel: {
    label: 'Cancel',
    icon: <Icon icon="ban" tw="text-base transform rotate-180" />,
  },
}

export interface ExternalNetworksActionProps {
  action: 'submit' | 'cancel' | 'clear'
  onClick?: () => void
}

const ExternalNetworksAction: FC<ExternalNetworksActionProps> = ({
  action,
  onClick,
}) => {
  const { label, icon } = ACTIONS[action]
  return (
    <div
      tw="flex flex-row items-center text-dark-blue-gray transition duration-200 space-x-1 hover:(text-purple cursor-pointer)"
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </div>
  )
}

export default ExternalNetworksAction
