import React, { FC } from 'react'
import 'twin.macro'

import EditCampaignForm from './components/edit-campaign-form'
import ArchiveCampaignForm from './components/archive-campaign-form'

import SettingsPanel from '../../../../../components/settings-panel'

import { CampaignFullFragment } from '../../../../../graphql/components'

export interface SettingsSectionProps {
  campaign?: CampaignFullFragment
  refetchCampaign?: () => Promise<any>
}

const SettingsSection: FC<SettingsSectionProps> = ({
  campaign,
  refetchCampaign,
}) => (
  <section>
    <SettingsPanel
      sections={[
        {
          title: 'Details',
          content: (
            <EditCampaignForm
              campaign={campaign}
              refetchCampaign={refetchCampaign}
            />
          ),
        },
        {
          title: 'Archive',
          content: <ArchiveCampaignForm campaign={campaign} />,
        },
      ]}
    />
  </section>
)

export default SettingsSection
