import React, { FC } from 'react'
import 'twin.macro'
import { Navigate, useParams } from 'react-router-dom'

import { EnumWorkspacePlatform_Type } from '../../../../graphql/components'
import { authentication } from '../../../../stores'
import WorkspaceSettingsBillingMainPage from './main'

const WorkspaceSettingsBillingPage: FC = () => {
  const { workspace_id } = useParams()

  const workspace = authentication.workspaces.find(
    ({ _id }) => _id === workspace_id
  )

  if (
    workspace &&
    workspace.platform_type !== EnumWorkspacePlatform_Type.Enterprise
  ) {
    return <Navigate to={`/workspaces/${workspace._id}/settings`} />
  }

  return <WorkspaceSettingsBillingMainPage />
}

export default WorkspaceSettingsBillingPage
