import React, { FC } from 'react'

import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Text from '../../../../ui-blocks/text'
import Button from '../../../../ui-blocks/button'
import Textarea from '../../../../ui-blocks/textarea'

import { useRevokeExternalCampaignMutation } from '../../../../graphql/components'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

interface RevokeCampaignFormikValues {
  reviewNotes: string
}

const revokeCampaignValidationSchema = object().shape({
  reviewNotes: string().required('Revoking note is required'),
})

export interface RevokeFormProps {
  networkId: string
  campaignId: string
  onRevoke?: () => void
}

const RevokeForm: FC<RevokeFormProps> = ({
  networkId,
  campaignId,
  onRevoke,
}) => {
  const [
    revokeCampaign,
    { loading: revokingCampaign },
  ] = useRevokeExternalCampaignMutation()

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'revoke-campaign-error-alert',
      message: "We couldn't revoke this campaign. Please try again later.",
    })
  }

  const formik = useFormik<RevokeCampaignFormikValues>({
    validateOnChange: false,
    validationSchema: revokeCampaignValidationSchema,
    initialValues: { reviewNotes: '' },
    async onSubmit({ reviewNotes }) {
      try {
        const res = await revokeCampaign({
          variables: {
            campaign_id: campaignId,
            network_id: networkId,
            reviewNotes,
          },
        })
        if (!res.data?.isRevoked) return handleError()
        onRevoke?.()
      } catch {
        handleError()
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      <Text as="p" preset="p1" tw="mb-2">
        Please provide a note about why you are revoking submission:
      </Text>
      <Textarea rows={4} name="reviewNotes" tw="mb-8"></Textarea>
      <Button type="submit" disabled={!formik.dirty} loading={revokingCampaign}>
        Revoke
      </Button>
    </form>
  )
}

export default RevokeForm
