import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Icon from '../../../../ui-blocks/icon'
import Link from '../../../../ui-blocks/link'

import useElementStates from '../../../../utils/use-element-states'

const LinkSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const { states, values } = useElementStates(['disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="flex flex-row items-center space-x-8">
        <Link href="#" {...values}>
          Primary Link
        </Link>
        <Link
          href="#"
          iconLeft={<Icon icon="add" tw="text-base" />}
          {...values}
        >
          Primary Link
        </Link>
        <Link
          href="#"
          iconRight={<Icon icon="add" tw="text-base" />}
          {...values}
        >
          Primary Link
        </Link>
        <Link href="#" secondary {...values}>
          Secondary Link
        </Link>
        <Link
          href="#"
          secondary
          iconLeft={<Icon icon="add" tw="text-base" />}
          {...values}
        >
          Secondary Link
        </Link>
        <Link
          href="#"
          secondary
          iconRight={<Icon icon="add" tw="text-base" />}
          {...values}
        >
          Secondary Link
        </Link>
      </div>
    </ElementSection>
  )
}

export default LinkSection
