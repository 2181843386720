import tw from 'twin.macro'
import React, { FC, useMemo, useState } from 'react'

import _ from 'lodash'
import { useParams } from 'react-router-dom'

import Text from '../../../../../../../ui-blocks/text'
import Status from '../../../../../../../ui-blocks/status'
import ModalAction from '../../../../../../../ui-blocks/modal-action'
import Illustration, {
  IllustrationEnum,
} from '../../../../../../../ui-blocks/illustration'

import ExternalNetworksAction from './action'

import InlineDate from '../../../../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../../../../components/table'

import {
  EnumBroadcastTargetStatus,
  BroadcastTargetFullFragment,
  useClearBroadcastTargetsMutation,
  useSubmitBroadcastTargetsMutation,
  useCancelBroadcastTargetsMutation,
} from '../../../../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../../../../../utils/data-manipulation'

import { alertsManager } from '../../../../../../../stores'
import { AlertVariant } from '../../../../../../../stores/alerts-manager'

const ExternalNetworksListTable: FC<
  TableDataProps<BroadcastTargetFullFragment>
> = ({ data, ...props }) => {
  const { campaign_id } = useParams()

  const [clearBroadcastTarget] = useClearBroadcastTargetsMutation()
  const [submitBroadcastTarget] = useSubmitBroadcastTargetsMutation()
  const [cancelBroadcastTarget] = useCancelBroadcastTargetsMutation()

  const [modalTargetId, setModalTargetId] = useState<string>('')

  const handleUpdateError = (action: string) => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-target-status-error-alert',
      message: `We couldn't ${action} this network. Please try again later.`,
    })
  }

  const onClickAction = async (action: string, targetId: string) => {
    try {
      if (action === 'submit') {
        const res = await submitBroadcastTarget({
          variables: { campaign_id, broadcastTargetIds: [targetId] },
        })
        if (!res.data?.areSubmitted) return handleUpdateError(action)
      } else if (action === 'cancel') {
        onOpenModal(targetId)
      } else if (action === 'clear') {
        const res = await clearBroadcastTarget({
          variables: { campaign_id, broadcastTargetIds: [targetId] },
        })
        if (!res.data?.areCleared) return handleUpdateError(action)
      }
      document.dispatchEvent(new CustomEvent('refetch-internal-campaign'))
    } catch {
      handleUpdateError(action)
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((target) => {
        const action =
          (target?.status === EnumBroadcastTargetStatus.NotSubmitted &&
            'submit') ||
          ((target?.status === EnumBroadcastTargetStatus.Approved ||
            target?.status === EnumBroadcastTargetStatus.PendingApproval) &&
            'cancel') ||
          ((target?.status === EnumBroadcastTargetStatus.Rejected ||
            target?.status === EnumBroadcastTargetStatus.Revoked) &&
            'clear')

        return {
          name: {
            value: target.externalNetwork?.name,
            content: target.externalNetwork?.name,
          },
          status: {
            value: target?.status,
            content: (
              <Status value={target?.status as EnumBroadcastTargetStatus} />
            ),
          },
          impressions: {
            value: target.externalNetwork?.stats?.[0]?.reach ?? 0,
            content: target.externalNetwork?.stats?.[0]?.reach ?? 0,
          },
          scans: {
            value: target.externalNetwork?.stats?.[0]?.engagement ?? 0,
            content: target.externalNetwork?.stats?.[0]?.engagement ?? 0,
          },
          players: {
            value: target.externalNetwork?.players.length,
            content: target.externalNetwork?.players.length,
          },
          createdAt: {
            value: target.created?.at,
            content: <InlineDate date={target.created?.at} />,
          },
          action: {
            content: !!action && (
              <ExternalNetworksAction
                action={action}
                onClick={() => onClickAction(action, target._id)}
              />
            ),
          },
        }
      }),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  const onCloseModal = () => {
    setModalTargetId('')
  }

  const onOpenModal = (targetId: string) => {
    setModalTargetId(targetId)
  }

  const onConfirmModal = async () => {
    const res = await cancelBroadcastTarget({
      variables: { campaign_id, broadcastTargetIds: [modalTargetId] },
    })
    onCloseModal()
    document.dispatchEvent(new CustomEvent('refetch-internal-campaign'))
    if (!res.data?.areCancelled) return handleUpdateError('cancel')
  }

  return (
    <>
      <ModalAction
        title="Cancel submission"
        open={!!modalTargetId}
        cancelButtonText="Back"
        confirmButtonText="Cancel Submission"
        onConfirm={onConfirmModal}
        onCancel={onCloseModal}
        onBackdropClick={onCloseModal}
        width="28rem"
      >
        <Text as="p" preset="h5" css={tw`mb-24 break-words`}>
          Are you sure you want to cancel submission of this campaign to the
          network{' '}
          <b>
            {sortedData.find((target) => target?._id === modalTargetId)
              ?.externalNetwork?.name || ''}
          </b>
          {'? '}
          The status will return to not submitted.
        </Text>
      </ModalAction>
      <Table
        itemName="network"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.networks_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'status',
            label: 'Status',
            isSortable: true,
            alignment: 'center',
            width: 142,
          },
          {
            key: 'impressions',
            label: 'Impressions',
            isSortable: true,
            alignment: 'right',
            width: 100,
          },
          {
            key: 'scans',
            label: 'Scans',
            isSortable: true,
            alignment: 'right',
            width: 100,
          },
          {
            key: 'players',
            label: 'Players',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'createdAt',
            label: 'Created On',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'action', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
    </>
  )
}

export default ExternalNetworksListTable
