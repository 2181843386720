import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'

import { ReactComponent as CircleCheckFilledIcon } from '../../../assets/icons/circle_check_filled.svg'

export interface StepIndicatorProps {
  index: number
  label: string
  isLast: boolean
  state: 'done' | 'current' | 'todo'
  subSteps?: string[]
  onClick?: () => void
}

const StepIndicator: FC<StepIndicatorProps> = ({
  index,
  label,
  isLast,
  state,
  subSteps,
  onClick,
}) => {
  const hasSubSteps = !!subSteps && !!subSteps.length
  return (
    <div
      tw="w-full transition duration-200"
      css={[onClick && tw`cursor-pointer`, !onClick && tw`cursor-not-allowed`]}
      onClick={onClick}
    >
      <div
        css={[
          tw`flex flex-row items-center`,
          !isLast && tw`mb-8`,
          hasSubSteps && tw`mb-4`,
        ]}
      >
        {state === 'done' ? (
          <ColoredCircleCheckFilledIcon tw="text-charcoal" />
        ) : (
          <div
            css={[
              'margin: 0.125rem;',
              tw`w-5 h-5 flex items-center justify-center text-xs text-charcoal rounded-full`,
              state === 'current' && tw`bg-purple text-white`,
              state === 'todo' && tw`bg-cultured`,
            ]}
          >
            {index}
          </div>
        )}
        <div tw="flex flex-col ml-4">
          <p
            css={[
              tw`font-normal text-xs text-dark-blue-gray uppercase leading-tight mb-1`,
              state === 'done' && tw`line-through`,
              state === 'current' && tw`text-purple`,
            ]}
          >
            Step {index}
          </p>
          <p
            css={[
              tw`font-medium text-charcoal leading-tight`,
              state === 'current' && tw`text-purple`,
            ]}
          >
            {label}
          </p>
        </div>
      </div>
      {state === 'current' && hasSubSteps && (
        <ul css={['padding-left: 2.25rem;', !isLast && tw`mb-8`]}>
          {subSteps?.map((subStep, index) => (
            <li
              key={`sub-step#${index}`}
              css={[
                'margin-bottom: 0.625rem;',
                tw`font-normal text-charcoal leading-tight`,
              ]}
            >
              {subStep}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const ColoredCircleCheckFilledIcon = styled(CircleCheckFilledIcon)`
  & circle {
    ${tw`fill-current`}
  }
`

export default StepIndicator
