import React, { FC, Fragment } from 'react'
import 'twin.macro'

import { object, string } from 'yup'
import { useFormikContext } from 'formik'

import Input from '../../../../../ui-blocks/input'
import Spacer from '../../../../../ui-blocks/spacer'

import { CreateAdGroupFormikValues } from '..'

export const adGroupDetailsValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  redirect_to: string()
    .trim('Value cannot have leading or trailing white spaces')
    .url('Must be a valid URL')
    // .required('Redirect to is required')
    .strict(true),
})

const AdGroupDetailsStep: FC = () => {
  const {
    values,
    errors,
    isSubmitting,
    getFieldMeta,
    handleChange,
  } = useFormikContext<CreateAdGroupFormikValues>()

  const nameMeta = getFieldMeta<CreateAdGroupFormikValues>('name')
  const urlMeta = getFieldMeta<CreateAdGroupFormikValues>('redirect_to')

  return (
    <Fragment>
      <p tw="font-medium mb-4">Information</p>
      <div tw="max-w-xs">
        <Input
          type="text"
          name="name"
          label="Name"
          value={values.name}
          error={errors.name}
          disabled={isSubmitting}
          success={nameMeta.touched && !nameMeta.error}
          onChange={handleChange}
          required
        />
        <Spacer size="1.5rem" />
        <Input
          type="text"
          label="URL"
          name="redirect_to"
          placeholder="https://your-domain.com/path"
          value={values.redirect_to}
          error={errors.redirect_to}
          disabled={isSubmitting}
          success={urlMeta.touched && !urlMeta.error}
          onChange={handleChange}
        />
      </div>
      <p tw="text-dark-blue-gray text-xs mt-2 mb-8">
        Insert the URL that you desire your target audience to interact with
      </p>
    </Fragment>
  )
}

export default AdGroupDetailsStep
