import React, { FC } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import Icon from '../../../../ui-blocks/icon'
import Spinner from '../../../../ui-blocks/spinner'

import {
  UserEmail,
  useAccountEmailRemoveMutation,
  useAccountEmailSetPrimaryMutation,
} from '../../../../graphql/components'

import { tryCatch } from '../../../../utils/try-catch'

import { AlertVariant } from '../../../../stores/alerts-manager'
import { alertsManager, authentication } from '../../../../stores'

export interface EmailCardProps {
  index: number
  email: UserEmail
}

const EmailCard: FC<EmailCardProps> = ({ index, email }) => {
  const isVerified = !!email.verified_at
  const isPrimary = authentication.user?.primary_email_index === index
  const verifiedDate = new Date(email.verified_at).toLocaleDateString(
    'default',
    { month: 'short', day: 'numeric', year: 'numeric' }
  )

  const [
    removeEmail,
    { loading: removingEmail },
  ] = useAccountEmailRemoveMutation()
  const onRemoveEmail = async () => {
    const [res, error] = await tryCatch(
      removeEmail({ variables: { email: email.address || '' } })
    )
    if (!!error || !res?.data?.removed) {
      return handleError(
        'We couldn’t remove the email address. Please try again later.'
      )
    }
    await authentication.loadUserData()
  }

  const [
    setEmailAsPrimary,
    { loading: updatingEmail },
  ] = useAccountEmailSetPrimaryMutation()
  const onSetEmailAsPrimary = async () => {
    const [res, error] = await tryCatch(
      setEmailAsPrimary({ variables: { email: email.address || '' } })
    )
    if (!!error || !res?.data?.isPrimary) {
      return handleError(
        'We couldn’t update your primary email. Please try again later.'
      )
    }
    await authentication.loadUserData()
  }

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-email-error-alert',
    })
  }

  const loading = removingEmail || updatingEmail
  return (
    <div tw="p-4 border border-platinum rounded max-w-md w-full relative overflow-hidden">
      {loading && (
        <div tw="flex items-center justify-center absolute inset-0 bg-white-60">
          <Spinner />
        </div>
      )}
      <p tw="font-normal text-charcoal leading-tight">{email.address}</p>
      {isVerified && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
          {`Verified ${verifiedDate}`}
        </span>
      )}
      <div tw="flex flex-row items-center justify-between mt-2">
        <div tw="flex flex-row items-center">
          {isVerified ? (
            <>
              <span tw="font-normal text-metallic-seaweed leading-tight">
                Verified
              </span>
              {!isPrimary && (
                <span
                  tw="font-normal text-dark-blue-gray leading-tight cursor-pointer underline ml-4 transition duration-200 hover:text-purple"
                  onClick={onSetEmailAsPrimary}
                >
                  Set as primary email
                </span>
              )}
            </>
          ) : (
            <>
              <span tw="font-normal text-golden-brown leading-tight">
                Unverified
              </span>
              <Link
                to={`/auth/sign-up/verify-email/${index}`}
                tw="font-normal text-dark-blue-gray leading-tight cursor-pointer underline ml-4 transition duration-200 hover:text-purple"
              >
                Verify email
              </Link>
            </>
          )}
        </div>
        {isPrimary ? (
          <span tw="font-normal text-flickr-blue leading-tight">
            Primary email
          </span>
        ) : (
          <div
            className="group remove-email-btn"
            tw="flex flex-row items-center cursor-pointer text-dark-blue-gray"
            onClick={onRemoveEmail}
          >
            <Icon
              icon="close"
              tw="text-lg transition duration-200 group-hover:text-purple"
            />
            <span tw="font-normal leading-tight ml-1 transition duration-200 group-hover:text-purple">
              Remove
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmailCard
