import React, { FC, Fragment, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router'

import Button from '../../../../../../ui-blocks/button'

import ArchiveWarningModal from '../../../../../../components/archive-warning-modal'

import {
  CreativeFullFragment,
  useArchiveCreativeMutation,
} from '../../../../../../graphql/components'

import { alertsManager } from '../../../../../../stores'
import { AlertVariant } from '../../../../../../stores/alerts-manager'

export interface ArchiveCreativeFormProps {
  creative?: CreativeFullFragment
}

const ArchiveCreativeForm: FC<ArchiveCreativeFormProps> = ({ creative }) => {
  const navigate = useNavigate()
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)

  const [
    archiveCreative,
    { loading: archivingCreative },
  ] = useArchiveCreativeMutation()

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'archive-creative-error-alert',
    })
  }

  const onArchiveCreative = async () => {
    try {
      const { data } = await archiveCreative({
        variables: { ids: [creative?._id] },
      })
      if (!data?.areArchived) {
        return handleError(
          "We couldn't archive this creative. Please try again later."
        )
      }
      navigate('/creatives', { state: { refetch: true } })
    } catch {
      handleError(
        'Something went wrong while trying to archive this creative. Please try again later.'
      )
    }
  }

  return (
    <Fragment>
      <span tw="font-medium text-charcoal leading-tight block mb-2">
        Archive
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-10">
        Once you archive a creative it will no longer be available.
      </span>
      <Button danger type="button" onClick={() => openWarningModal(true)}>
        Archive creative
      </Button>
      <ArchiveWarningModal
        target="creative"
        redirectPage="Creatives"
        open={isWarningModalOpen}
        loading={archivingCreative}
        onConfirm={onArchiveCreative}
        onCancel={() => openWarningModal(false)}
      />
    </Fragment>
  )
}

export default ArchiveCreativeForm
