import { action, computed, observable } from 'mobx'
import { persist } from 'mobx-persist'
import mem from 'mem'
import jwtDecode from 'jwt-decode'
import { Hydrated } from './utils/hydrate'
import {
  AuthSignInDocument,
  AuthSignInMutation,
  AuthSignInMutationVariables,
  AuthSignUpDocument,
  AuthSignUpMutation,
  WhoAmIDocument,
  WhoAmIQuery,
  WhoAmIQueryVariables,
  WhoAmIUserWorkspaceResult,
  AuthRefreshDocument,
  AuthRefreshMutation,
  AuthSignOutMutation,
  AuthSignOutDocument,
  AuthAccess,
  UserEmail,
  WhoAmIUserInviteResult,
} from '../graphql/components'
import apolloClient, { subscriptionClient } from '../graphql/client'
import { uploadsManager } from '.'

export default class Authentication extends Hydrated {
  public error: Error | string | null = null

  public async ready() {
    try {
      await super.ready()

      if (this.is_authenticated) {
        await this.loadUserData()
      }
    } catch (err) {
      // @TODO: register the error
      console.error(`Error on getting auth ready: ${err}`)
      this.logout(true)
    }
  }

  @computed public get is_authenticated(): boolean {
    return !!(this.access_token && this.refresh_token)
  }

  @computed public get access_token_payload() {
    if (!this.is_authenticated) {
      return false
    }

    return jwtDecode(`${this.access_token}`)
  }

  @computed public get primary_email(): UserEmail | undefined {
    const user = this.user_data
    return user?.emails[user?.primary_email_index || 0] || undefined
  }

  @persist @observable public access_token: string | null = null

  @persist @observable public refresh_token: string | null = null

  @persist @observable public client_id: string | null = null

  @persist @observable public user_id: string | null = null

  @persist @observable public selected_workspace_id: string | null = null

  @persist('list') @observable public network_tokens: string[] = []

  @observable public user_data?: WhoAmIQuery['user']

  @action addNetworkToken(networkToken: string) {
    if (!this.network_tokens.includes(networkToken)) {
      this.network_tokens = [...this.network_tokens, networkToken]
    }
  }

  @action removeNetworkToken(networkToken: string) {
    if (this.network_tokens.includes(networkToken)) {
      this.network_tokens = [
        ...this.network_tokens.filter((token) => token !== networkToken),
      ]
    }
  }

  @action clearNetworkTokens() {
    this.network_tokens = []
  }

  @computed public get invites() {
    const invites: Array<
      { __typename?: 'WhoAmIUserInviteResult' } & Pick<
        WhoAmIUserInviteResult,
        '_id' | 'status' | 'workspace_id'
      >
    > = []

    if (this.user_data?.invites) {
      for (const inv of this.user_data?.invites) {
        if (inv) {
          invites.push(inv)
        }
      }
    }

    return invites
  }

  @computed public get hasInvites(): boolean {
    return (this.user_data?.invites || []).length > 0
  }

  // TODO: base this computed element from real data
  @computed public get is_trial_active(): boolean {
    return false
  }

  // TODO: base this computed element from real data
  @computed public get trial_remaining_days(): number {
    return 0
  }

  @computed public get has_a_verified_email(): boolean {
    return !!(
      this.user_data?.emails.length &&
      this.user_data?.emails.find((email) => !!email?.verified_at)
    )
  }

  @computed public get has_a_verified_phone_number(): boolean {
    return !!(
      this.user_data?.phones.length &&
      this.user_data?.phones.find((phone) => !!phone?.verified_at)
    )
  }

  @computed public get user() {
    if (!this.is_authenticated) {
      return undefined
    }

    return this.user_data
  }

  @computed public get workspaces() {
    const workspaces = this.user?.workspaces || []
    return workspaces as WhoAmIUserWorkspaceResult[]
  }

  @computed public get selected_workspace() {
    const workspace =
      this.selected_workspace_id &&
      this.workspaces.find(
        (workspace) =>
          String(workspace._id) === String(this.selected_workspace_id)
      )
    return workspace || this.workspaces[0]
  }

  constructor() {
    super('Authentication')
  }

  @action public async login(
    email: string,
    password: string,
    reCaptchaToken?: string
  ) {
    const { data } = await apolloClient.mutate<
      AuthSignInMutation,
      AuthSignInMutationVariables
    >({
      mutation: AuthSignInDocument,
      variables: { email, password },
      context: {
        headers: {
          ...(!!reCaptchaToken ? { 'X-Recaptcha-Token': reCaptchaToken } : {}),
        },
      },
    })

    if (!data || !data.tokens || !data.tokens.access_token) {
      throw new Error('Something went wrong')
    }

    const { access_token, refresh_token, user_id, client_id } =
      data.tokens || {}
    await this.setAuthAccess({
      access_token,
      refresh_token,
      user_id,
      client_id,
    })
  }

  @action public async setAuthAccess({
    access_token,
    refresh_token,
    user_id,
    client_id,
  }: AuthAccess) {
    await this.clean()
    await this.loadUserData(access_token)

    // load user data
    this.access_token = access_token || null
    this.refresh_token = refresh_token || null
    this.client_id = client_id || null
    this.user_id = user_id || null
  }

  @action public async selectWorkspace(workspace_id: string) {
    subscriptionClient.close()
    await this.clean()

    this.selected_workspace_id = workspace_id
  }

  @action public async signup(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    reCaptchaToken?: string
  ) {
    const { data } = await apolloClient.mutate<AuthSignUpMutation>({
      mutation: AuthSignUpDocument,
      variables: {
        first_name: firstname,
        last_name: lastname,
        email,
        password,
      },
      context: {
        headers: {
          ...(!!reCaptchaToken ? { 'X-Recaptcha-Token': reCaptchaToken } : {}),
        },
      },
    })

    if (!data || !data.tokens || !data.tokens.access_token) {
      throw new Error('Something went wrong')
    }

    const { access_token, refresh_token, user_id, client_id } =
      data.tokens || {}
    await this.setAuthAccess({
      access_token,
      refresh_token,
      user_id,
      client_id,
    })
  }

  @action public async logout(invalidateSession = true) {
    // @TODO: kill user session

    if (invalidateSession) {
      await apolloClient.mutate<AuthSignOutMutation>({
        mutation: AuthSignOutDocument,
        variables: { refreshToken: this.refresh_token },
      })
    }

    await this.clean()

    this.error = null
    this.access_token = null
    this.refresh_token = null
    this.client_id = null
    this.user_id = null
    this.selected_workspace_id = null
    this.user_data = null
    this.clearNetworkTokens()

    // Close WS after logout
    subscriptionClient.close()
    await uploadsManager?.clear()
  }

  public async clean() {
    await apolloClient.cache.reset()
    await apolloClient.clearStore()
  }

  @action public async loadUserData(accessToken = this.access_token) {
    const res = await apolloClient.query<WhoAmIQuery, WhoAmIQueryVariables>({
      query: WhoAmIDocument,
      fetchPolicy: 'network-only',
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    })

    this.user_data = res.data?.user || null
  }

  public memRefreshAuthToken = mem(() => this.refreshAuthToken(), {
    maxAge: 500,
  })

  @action public async refreshAuthToken(): Promise<void> {
    try {
      const { data } = await apolloClient.mutate<AuthRefreshMutation>({
        mutation: AuthRefreshDocument,
        variables: {
          accessToken: this.access_token,
          refreshToken: this.refresh_token,
        },
      })

      if (!data || !data.authRefreshResult) {
        throw new Error('Something went wrong')
      }

      const { accessToken, refreshToken } = data.authRefreshResult

      this.access_token = accessToken
      this.refresh_token = refreshToken
      subscriptionClient.close()
    } catch (error) {
      throw new Error('An error has occured while renewing the access token')
    }
  }
}

export const authentication = new Authentication()
