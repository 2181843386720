import React, { FC, useEffect, useState } from 'react'

import { useObserver } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import UploadsDashboard from '../components/upload/dashboard'

import Header from '../components/header'
import AlertEmitter from '../components/alerts-manager'
import SignOutModal from '../components/sign-out-modal'

import withEndTrialModal from '../utils/with-end-trial-modal'

import { authentication } from '../stores'

import { NODE_ENV } from '../config'
import { EnumWorkspacePlatform_Type } from '../graphql/components'
import tw from 'twin.macro'

const MainLayout: FC = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false)

  useEffect(() => {
    // Check if the user is authenticated and has registered workspaces
    if (authentication.is_authenticated && !!authentication.workspaces.length) {
      // Check if the user has any unseen network tokens and isn't already in the assignment page
      if (
        !pathname.includes('/auth/networks/') &&
        !!authentication.network_tokens.length
      ) {
        // Redirect them to the assignment page if they do
        const token = authentication.network_tokens[0]
        return navigate(`/auth/networks/${encodeURIComponent(token)}`)
      }
    }
  }, [authentication])

  return useObserver(() => (
    <>
      <Header
        displayLogo
        navItems={[
          {
            label: 'Overview',
            to: `/workspaces/${
              authentication.selected_workspace?._id || ''
            }/overview`,
          },
          {
            label: 'Campaigns',
            to: '/campaigns',
          },
          { label: 'Creatives', to: '/creatives' },
          ...(authentication.selected_workspace?.platform_type !==
          EnumWorkspacePlatform_Type.Advertiser
            ? [
                { label: 'Networks', to: '/networks' },
                { label: 'Players', to: '/players' },
              ]
            : []),
        ]}
        workspaceMenuActions={[
          {
            label: 'Account Settings',
            linkTo: '/account/personal-information',
            isActive: pathname.includes('/account/'),
          },
          {
            label: 'Workspace Settings',
            linkTo: `/workspaces/${authentication.selected_workspace._id}/settings/details`,
            isActive: pathname.includes(
              `/workspaces/${authentication.selected_workspace._id}/settings/`
            ),
          },
          {
            label: 'Archived Items',
            linkTo: '/archived-items',
            isActive: pathname.includes('/archived-items'),
          },
          {
            label: `Manage Pending Workspace Invites`,
            linkTo: '/account/pending-workspace-invites',
            isActive: false,
            extra: authentication.invites.length > 0 && (
              <span css={tw`ml-16 text-purple`}>
                {authentication.invites.length}
              </span>
            ),
          },
          ...(NODE_ENV !== 'development' && NODE_ENV !== 'next'
            ? []
            : [
                {
                  label: 'Dev Components',
                  linkTo: '/dev/components',
                  isActive: pathname.includes(`/dev/`),
                },
              ]),
          {
            label: 'Logout',
            onClick: () => setSignOutModalOpen(true),
          },
        ]}
      />
      {children}
      <AlertEmitter />
      <UploadsDashboard />
      <SignOutModal
        open={signOutModalOpen}
        onCancel={() => setSignOutModalOpen(false)}
      />
    </>
  ))
}

export default withEndTrialModal(MainLayout)
