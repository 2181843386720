import React, { FC } from 'react'
import 'twin.macro'

import Dots from '../ui-blocks/dots'

import NotDefined from './not-defined'

import { Address as TAddress, useViewCountryQuery } from '../graphql/components'

const Address: FC<{ address?: TAddress }> = ({ address, ...props }) => {
  const { data, loading } = useViewCountryQuery({
    skip: !address?.country_id,
    variables: {
      _id: address?.country_id?.length > 2 ? address?.country_id : null,
      code: address?.country_id?.length === 2 ? address?.country_id : null,
    },
  })

  const addressData = [
    [address?.street, address?.number].filter(Boolean).join(', '),
    [
      [address?.zip, address?.city].filter(Boolean).join(' '),
      address?.state,
      data?.country?.name,
    ]
      .filter(Boolean)
      .join(', '),
  ].filter(Boolean)

  if (loading) return <Dots />
  return (
    <address tw="not-italic" {...props}>
      {addressData.length ? (
        addressData.map((addressLine, index) => (
          <span key={`address-line#${index}`} tw="block">
            {addressLine}
          </span>
        ))
      ) : (
        <NotDefined />
      )}
    </address>
  )
}

export default Address
