import { FC } from 'react'
import React from 'react'
import WorkspacePendingInvites, { usePendingInvites } from '.'
const AccountWorkspacePendingInvites: FC = () => {
  const pendingInviteProps = usePendingInvites('-1', 'Back')
  return (
    <WorkspacePendingInvites {...pendingInviteProps}></WorkspacePendingInvites>
  )
}

export default AccountWorkspacePendingInvites
