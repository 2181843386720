import React, { FC, useState, FormEvent } from 'react'
import tw, { css } from 'twin.macro'

import AutoSuggest, {
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest'

import Icon from './icon'
import Pill from './pill'
import Spinner from './spinner'

import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../utils/use-controlled-or-internal-value'

const Search: FC<
  IUseControlledOrInternalValueProps<string> & {
    entries?: string[]
    onSelect?: (v: string | string[]) => void
    filter?: boolean
    initialQuery?: string
    initialFilters?: string[]
    disabled?: boolean
    placeholder?: string
    loading?: boolean
    suggestionsMaxHeight?: string
  }
> = (props) => {
  const {
    entries,
    onSelect,
    filter,
    initialFilters,
    disabled,
    placeholder,
    loading,
    suggestionsMaxHeight,
  } = props
  const { value, onChange } = useControlledOrInternalValue<string>({
    value: props.value,
    initialValue: props.initialValue || '',
    onChange: props.onChange,
  })

  const [suggestions, setSuggestions] = useState<string[]>([])
  const [filters, setFilters] = useState<string[]>(initialFilters || [])

  const onSuggestionsFetchRequested = ({
    value,
  }: SuggestionsFetchRequestedParams) =>
    setSuggestions(
      entries
        ? entries.filter((e: string) => cleanStr(e).match(cleanStr(value)))
        : []
    )
  const onSuggestionsClearRequested = () => setSuggestions([])
  const getSuggestionValue = (suggestion: string) => suggestion
  const onSuggestionSelected = (
    _ev: FormEvent<string>,
    { suggestion }: SuggestionSelectedEventData<string>
  ) => {
    let selectedValue: string | string[] = suggestion

    if (filter) {
      const filterValue = suggestions.find(
        (r) => cleanStr(r) === cleanStr(suggestion)
      )
      if (!filterValue) {
        return
      }

      const newFilters = [...filters]

      if (filters.includes(filterValue)) {
        const index = filters.findIndex((f) => f === filterValue)
        newFilters.splice(index, 1)
      } else {
        newFilters.push(filterValue)
      }
      setFilters(newFilters)
      selectedValue = newFilters
    }

    if (onSelect) {
      onSelect(selectedValue)
    }
  }

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => (
    <div
      {...containerProps}
      css={css`
        ${tw`
          bg-white
          text-charcoal
          absolute
          z-10
          w-full
          mt-1
          py-2
          border
          rounded
          border-platinum
          shadow-search-results
          overflow-auto
        `}
        ${(value === '' || children === null) && tw`hidden`}
        max-height: ${suggestionsMaxHeight || '6.25rem'};
      `}
      className={containerProps.className}
    >
      {suggestions.length > 0 ? (
        children
      ) : (
        <div
          css={css`
            ${tw`text-charcoal px-4 pb-4`}
          `}
        >
          {`No matches for "${value}"`}
        </div>
      )}
    </div>
  )

  const renderSuggestion = (suggestion: string) => {
    const isFilter = filters.includes(suggestion)
    return (
      <div
        css={css`
          ${tw`cursor-pointer px-4`}
          &:hover {
            ${tw`bg-cultured`}
          }
        `}
      >
        <div
          css={css`
            ${tw`w-full`}
            ${isFilter &&
            tw`border-b border-platinum text-charcoal flex justify-between items-center`}
          `}
        >
          <span>{suggestion}</span>
          {isFilter && <Icon icon="check" tw="text-sm" />}
        </div>
      </div>
    )
  }

  const onDeleteFilter = (index: number) => {
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
    if (onSelect) {
      onSelect(newFilters)
    }
  }

  return (
    <div
      css={css`
        ${tw`relative w-full`}
      `}
    >
      <AutoSuggest
        suggestions={entries ? suggestions : []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderInputComponent={InputComponent}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: placeholder || 'search',
          value: value || '',
          disabled,

          // @ts-ignore
          onChange: (e: SyntheticEvent) => {
            onChange(e?.target?.value || undefined)
            if (onSelect) {
              onSelect(e?.target?.value || undefined)
            }
          },
          // this damn types dont let me pass on the "loading" attribute, using checked instead
          // @ts-ignore
          loading,
        }}
      />
      {filter && filters.length > 0 && (
        <Filters
          filters={filters}
          onDelete={onDeleteFilter}
          disabled={disabled}
        />
      )}
    </div>
  )
}

const Filters: FC<{
  filters: string[]
  onDelete: (i: number) => void
  disabled?: boolean
}> = ({ filters, onDelete, disabled }) => {
  return (
    <div
      css={css`
        ${tw`w-full flex flex-wrap mt-1`}
      `}
    >
      {filters.map((f, i) => (
        <div
          key={i}
          css={css`
            ${tw`mr-1`}
          `}
        >
          <Pill
            text={f.toUpperCase()}
            onDelete={() => onDelete(i)}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  )
}

const InputComponent: FC<InputProps<string>> = ({
  className,
  value,
  onChange,
  disabled,
  placeholder,
  // @ts-ignore
  loading,
  ...props
}) => {
  return (
    <div
      css={css`
        ${tw`relative flex w-full items-center`}
      `}
    >
      <Icon
        icon="search"
        css={css`
          ${tw`text-lg absolute ml-4 text-charcoal pointer-events-none`}
        `}
      />
      <input
        {...props}
        value={value}
        // @ts-ignore
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder || 'search'}
        css={css`
          ${tw`
            bg-white
            text-charcoal
            border
            border-platinum
            rounded
            py-2
            px-10
            block
            w-full
            appearance-none
            leading-normal
          `}
          &:hover {
            ${(!disabled && tw`border-light-peri`) || ``}
          }
          ${tw`focus:outline-none focus:border-spanish-violet`}
          ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
          ${(!!loading && tw`pr-16`) || ``}
          transition: all 100ms ease-in-out;
        `}
        className={className}
      />
      {value !== '' && (
        <Icon
          icon="close"
          onClick={() => {
            // @ts-ignore
            onChange({ target: { value: '' } })
          }}
          css={css`
            ${tw`text-lg absolute mr-4 text-charcoal right-0 hover:cursor-pointer`}
            ${(!!loading && tw`mr-8`) || ``}
          `}
        />
      )}
      {!!loading && (
        <Spinner
          css={css`
            ${tw`w-3 h-3 border absolute mr-4 text-charcoal right-0`}
          `}
        />
      )}
    </div>
  )
}

function cleanStr(str: string) {
  return str.trim().toLowerCase()
}

export default Search
