import React, { FC } from 'react'
import tw from 'twin.macro'

export interface UpcomingBillProps {
  freePlan?: boolean
  nextChargeDate?: Date | null
  nextChargeAmount?: number | null
  hasFailedPayments?: boolean
  lastChargeAmount?: number | null
  onDetailsClick?: () => void
}

const UpcomingBill: FC<UpcomingBillProps> = ({
  freePlan,
  nextChargeDate,
  nextChargeAmount,
  hasFailedPayments,
  lastChargeAmount,
  onDetailsClick,
}) => {
  const formattedDate =
    (nextChargeDate &&
      nextChargeDate.toLocaleString('default', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })) ||
    ''

  return (
    <div
      css={[
        tw`py-4 px-6 max-w-xs rounded-md bg-cultured`,
        hasFailedPayments && tw`border-red-700 border-solid border bg-red-100`,
      ]}
    >
      <b
        css={[
          tw`text-sm text-dark-blue-gray leading-tight tracking-wider uppercase block mb-3 tracking-widest`,
          hasFailedPayments && tw`text-red-700`,
        ]}
      >
        {hasFailedPayments ? 'Payment Failed' : 'Upcoming Bill'}
      </b>
      <div
        css={[
          tw`font-normal text-sm`,
          hasFailedPayments ? tw`text-red-700` : tw`text-charcoal`,
        ]}
      >
        {freePlan && !hasFailedPayments && (
          <span>
            You're on a Free plan, so you don't have any upcoming charges.
          </span>
        )}{' '}
        {!freePlan && !hasFailedPayments && (
          <span>
            {'Your next charge will be on '}
            <b>{formattedDate}</b>
            {', in the estimated amount of '}
            <b>{`${nextChargeAmount}€`}</b>
            {'.'}
          </span>
        )}
        {hasFailedPayments && (
          <span>
            To keep your Enterprise subscription update your payment details.
          </span>
        )}
        <br></br>
        <span tw="mt-4 block">
          {!hasFailedPayments ? 'Last charge: ' : 'Charge: '}
          <b>{`${!!lastChargeAmount ? lastChargeAmount / 100 : '--'}€`}</b>
        </span>
        <a
          onClick={onDetailsClick}
          tw="mt-1 block text-dark-blue-gray leading-snug underline cursor-pointer"
        >
          Payment details
        </a>
      </div>
    </div>
  )
}

export default UpcomingBill
