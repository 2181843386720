import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useLocation } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Search from '../../../ui-blocks/search'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import PageHeader from '../../../ui-blocks/page-header'
import PaginationNav from '../../../ui-blocks/pagination-nav'

import NetworksEmpty from './components/networks-empty'
import NetworksListTable from '../../../components/network/table'

import {
  useCountNetworksQuery,
  useListNetworksQuery,
} from '../../../graphql/components'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'

const ListNetworksPage: FC = () => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: countData,
    // loading: loadingCount,
    refetch: refetchCount,
  } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: filteredCountData,
    loading: loadingFilteredCount,
    refetch: refetchFilteredCountData,
  } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: { filter: { search: searchTextDebounced } },
  })

  const networksCount = useMemo(() => filteredCountData?.networks || 0, [
    filteredCountData,
  ])

  const totalNetworksCount = useMemo(() => countData?.networks || 0, [
    countData,
  ])
  const pagination = usePagination(networksCount, 15, searchTextDebounced)

  const {
    data: networksData,
    loading: loadingNetworks,
    refetch: refetchNetworks,
  } = useListNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchNetworks()
    await refetchFilteredCountData()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-networks', refetchData)
    return () => {
      document.removeEventListener('refetch-networks', refetchData)
    }
  }, [])

  const allNetworks = (networksData?.networks || []).filter(
    (network) => !network.is_external
  )
  return (
    <Container>
      <PageHeader
        title="Networks"
        description={
          loadingNetworks ? 'Loading...' : `${totalNetworksCount} TOTAL`
        }
      >
        {!!totalNetworksCount && (
          <div tw="max-w-80 justify-self-end">
            <Search
              value={searchText}
              loading={loadingNetworks && loadingFilteredCount}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
          </div>
        )}

        {!!totalNetworksCount && (
          <ButtonLink
            tw="justify-self-end"
            to="/networks/create"
            iconLeft={<Icon icon="add" tw="text-lg" />}
          >
            Add Network
          </ButtonLink>
        )}
      </PageHeader>

      <Suspense ready={!!totalNetworksCount} fallback={<NetworksEmpty />}>
        <NetworksListTable
          data={allNetworks}
          loading={loadingNetworks && loadingFilteredCount}
          searchQuery={searchTextDebounced}
        />
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
          tw="mt-4"
        />
      </Suspense>
    </Container>
  )
}

export default ListNetworksPage
