import React, { FC, useState, CSSProperties } from 'react'
import tw from 'twin.macro'

import Icon from '../../../ui-blocks/icon'

import { TableCellAlignment } from '..'

export interface ColumnHeadProps {
  label?: string
  isSelected?: boolean
  isSortable?: boolean
  alignment?: TableCellAlignment
  colSpan?: number
  onClick?: (isAscending: boolean) => void
  style?: CSSProperties
}

const ColumnHead: FC<ColumnHeadProps> = ({
  label,
  alignment,
  isSelected,
  isSortable,
  onClick,
  colSpan,
  style,
}) => {
  const [isAscending, setIsAscending] = useState<boolean>(false)

  const handleClick = () => {
    if (!isSortable) return
    if (!!isSelected) setIsAscending(!isAscending)
    onClick?.(!!isSelected && !isAscending)
  }

  return (
    <th tw="px-4" colSpan={colSpan || 1} style={style}>
      {!!label && (
        <div
          onClick={handleClick}
          css={[
            tw`flex flex-row items-center justify-start select-none whitespace-nowrap`,
            alignment === 'left' && tw`justify-start`,
            alignment === 'center' && tw`justify-center`,
            alignment === 'right' && tw`justify-end`,
            !!isSortable && tw`cursor-pointer`,
          ]}
        >
          <span tw="whitespace-nowrap font-medium text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
            {label}
          </span>
          {!!isSortable && !!isSelected && (
            <Icon
              title="Sort"
              icon="chevron-down"
              css={[
                tw`ml-1 transition duration-200 transform text-base text-dark-blue-gray`,
                isAscending && tw`-rotate-180`,
              ]}
            />
          )}
        </div>
      )}
    </th>
  )
}

export default ColumnHead
