import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { NavLink } from 'react-router-dom'

import Card from '../ui-blocks/card'
import Container from '../ui-blocks/container'

import MainLayout from './main'

const NAV_LINKS = [
  { linkTo: '/account/personal-information', label: 'Personal Information' },
  { linkTo: '/account/contacts', label: 'Contacts' },
  { linkTo: '/account/security', label: 'Security' },
]

const AccountPanelLayout: FC = ({ children }) => (
  <MainLayout>
    <Container>
      <Card
        css={[tw`grid text-charcoal`, 'grid-template-columns: 19rem auto;']}
      >
        <div
          css={css`
            ${tw`bg-lighter-blue p-8 flex flex-col rounded-tl rounded-bl space-y-4 text-base`}
            a.active {
              ${tw`text-purple`}
            }
          `}
        >
          <p tw="text-xs uppercase">Account Settings</p>
          {NAV_LINKS.map((navLink, index) => (
            <NavLink
              to={navLink.linkTo}
              key={`account-panel-nav-link#${index}`}
            >
              {navLink.label}
            </NavLink>
          ))}
        </div>
        <div tw="p-8 flex flex-col flex-auto overflow-auto">{children}</div>
      </Card>
    </Container>
  </MainLayout>
)

export default AccountPanelLayout
