import React, { FC, useMemo } from 'react'

import tw, { css } from 'twin.macro'
import moment, { Moment } from 'moment'

// @ts-ignore
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import TimePicker, { TimePickerValue } from 'react-time-picker'

import Icon from './icon'
import Text from './text'

import ErrorMessage from '../components/error-message'

import { PickerProps } from '../utils/use-date-picker'

const convertValueToMoment = (value: TimePickerValue) => {
  if (typeof value === 'string' && value.includes(':')) {
    const [hour, minute] = value.split(':')
    return moment()
      .hour(Number.parseInt(hour))
      .minute(Number.parseInt(minute))
      .second(0)
      .millisecond(0)
  }
}

export const HourPickerContainer: FC<PickerProps> = ({
  id,
  label,
  error,
  children,
}) => (
  <div id={id} tw="w-full">
    {!!label && (
      <Text as="label" preset="caption" tw="pb-2" htmlFor={id}>
        {label}
      </Text>
    )}
    <div tw="relative">
      {children}
      <Icon
        icon="clock"
        tw="text-lg text-dark-blue-gray absolute left-0 top-2/4 transform -translate-y-2/4 ml-4 pointer-events-none"
      />
    </div>
    {!!error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
)

export interface HourPickerProps extends PickerProps {
  time?: Moment
  onChange?: (time?: Moment) => void
}

export const HourPicker: FC<HourPickerProps> = ({
  id,
  name,
  time,
  label,
  error,
  success,
  disabled,
  onChange,
}) => {
  const handleChange = (value: TimePickerValue) => {
    if (!value) return onChange?.()
    onChange?.(convertValueToMoment(value))
  }

  const hasValue = !!time
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )
  return (
    <HourPickerContainer id={inputId} label={label} error={error}>
      <TimePicker
        name={name}
        disableClock
        clockIcon={null}
        disabled={disabled}
        value={!!time ? time.toDate() : ''}
        clearIcon={<Icon icon="close" tw="text-base text-dark-blue-gray" />}
        onChange={handleChange}
        css={css`
          .react-time-picker__wrapper {
            ${tw`h-10 py-2 pl-10 pr-4 bg-white text-charcoal outline-none border rounded border-platinum leading-normal transition duration-100`}
            ${!!disabled && tw`opacity-75 cursor-not-allowed`}
            ${!disabled && tw`hover:border-light-peri`}
            ${success && tw`border-metallic-seaweed`}
            ${!!error && tw`border-brick-red`}
          }
          .react-time-picker__inputGroup {
            ${tw`p-0`}
          }
          .react-time-picker__clear-button {
            ${tw`pr-0 py-0 outline-none`}
            ${!hasValue && tw`hidden`}
            ${!!disabled && tw`cursor-not-allowed`}
          }
          .react-time-picker__inputGroup__input {
            ${!!disabled && tw`cursor-not-allowed`}
          }
        `}
      />
    </HourPickerContainer>
  )
}

export interface HourRangePickerProps extends PickerProps {
  startTime?: Moment
  endTime?: Moment
  onChange?: (startTime?: Moment, endTime?: Moment) => void
}

export const HourRangePicker: FC<HourRangePickerProps> = ({
  id,
  name,
  label,
  error,
  success,
  disabled,
  startTime,
  endTime,
  onChange,
}) => {
  const handleChange = (value: [TimePickerValue, TimePickerValue]) => {
    if (!value || !value.filter(Boolean).length) return onChange?.()
    onChange?.(
      convertValueToMoment(value?.[0]),
      convertValueToMoment(value?.[1])
    )
  }

  const hasValue = !!startTime && !!endTime
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )
  return (
    <HourPickerContainer id={inputId} label={label} error={error}>
      <TimeRangePicker
        name={name}
        disableClock
        clockIcon={null}
        disabled={disabled}
        onChange={handleChange}
        clearIcon={<Icon icon="close" tw="text-base text-dark-blue-gray" />}
        value={[startTime?.format('HH:mm'), endTime?.format('HH:mm')].filter(
          Boolean
        )}
        css={css`
          .react-timerange-picker__wrapper {
            ${tw`h-10 py-2 pl-10 pr-4 bg-white text-charcoal outline-none border rounded border-platinum leading-normal transition duration-100`}
            ${!!disabled && tw`opacity-75 cursor-not-allowed`}
            ${!disabled && tw`hover:border-light-peri`}
            ${success && tw`border-metallic-seaweed`}
            ${!!error && tw`border-brick-red`}
          }
          .react-timerange-picker__range-divider {
            ${tw`px-2`}
          }
          .react-timerange-picker__inputGroup {
            ${tw`p-0`}
          }
          .react-timerange-picker__clear-button {
            ${tw`pr-0 py-0 outline-none`}
            ${!hasValue && tw`hidden`}
            ${!!disabled && tw`cursor-not-allowed`}
          }
          .react-timerange-picker__inputGroup__input {
            ${!!disabled && tw`cursor-not-allowed`}
          }
        `}
      />
    </HourPickerContainer>
  )
}
