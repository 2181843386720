import React, { FC, useState } from 'react'

import { startCase } from 'lodash'
import tw from 'twin.macro'

import { useFormik } from 'formik'
import { string, object, number } from 'yup'

import Radio from '../../../../../../ui-blocks/radio'
import Button from '../../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../../ui-blocks/input'
import { SliderFormik } from '../../../../../../ui-blocks/slider'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../components/alert-component'

import {
  PlayerFullFragment,
  useUpdatePlayerByIdMutation,
  EnumPlayerSettingsOrientation,
} from '../../../../../../graphql/components'

import { cleanGraphqlTypenames } from '../../../../../../utils/data-manipulation'
import { NODE_ENV } from '../../../../../../config'

interface EditPlayerFormikValues {
  settings: {
    debug: boolean // defaults false
    volume: number
    brightness: number
    orientation: EnumPlayerSettingsOrientation
    size: { width: number; height: number } // defaults { width: 0, height: 0 }
    density: number // defaults 0
    overscan: { top: number; right: number; bottom: number; left: number } // defaults { top: 0, right: 0, bottom: 0, left: 0 }
    storage_medium_id: string | null

    // players
    image_player: string
    video_player: string
    audio_player: string
  }
}

const editPlayerValidationSchema = object().shape({
  settings: object()
    .shape({
      volume: number().min(0).max(100).default(0),
      brightness: number().min(0).max(100).default(100),
      orientation: string()
        .oneOf([
          EnumPlayerSettingsOrientation.Landscape,
          EnumPlayerSettingsOrientation.Portrait,
          EnumPlayerSettingsOrientation.ReversedLandscape,
          EnumPlayerSettingsOrientation.ReversedPortrait,
        ])
        .default(EnumPlayerSettingsOrientation.Landscape)
        .required(),
      size: object().shape({
        width: number().min(0).default(0).required(),
        height: number().min(0).default(0).required(),
      }),
      density: number().min(0).default(0).required(),
      overscan: object().shape({
        top: number().min(0).default(0).required(),
        right: number().min(0).default(0).required(),
        bottom: number().min(0).default(0).required(),
        left: number().min(0).default(0).required(),
      }),
      storage_medium_id: string().required(),

      image_player: string().required().default('default'),
      video_player: string().required().default('default'),
      audio_player: string().required().default('default'),
    })
    .required(),
})

export interface EditPlayerFormProps {
  player?: PlayerFullFragment
  refetchPlayer?: () => Promise<any>
}

const EditPlayerForm: FC<EditPlayerFormProps> = ({ player, refetchPlayer }) => {
  const [updatePlayerError, setUpdatePlayerError] = useState<string>('')

  const [updatePlayer] = useUpdatePlayerByIdMutation()
  const formik = useFormik<EditPlayerFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editPlayerValidationSchema,
    initialValues: {
      settings: {
        debug: false,
        volume: 0,
        brightness: 100,
        orientation: EnumPlayerSettingsOrientation.Landscape,
        size: { width: 0, height: 0 },
        density: 0,
        overscan: { top: 0, right: 0, bottom: 0, left: 0 },

        ...cleanGraphqlTypenames<any>(player?.settings),
      },
    },
    async onSubmit(values) {
      try {
        setUpdatePlayerError('')

        const res = await updatePlayer({
          variables: { _id: player?._id, input: values },
        })

        if (!!(res.errors || []).length) {
          return setUpdatePlayerError(
            "We couldn't update this player. Please try again later."
          )
        }

        await refetchPlayer?.()
      } catch {
        setUpdatePlayerError(
          'Something went wrong while trying to update this player. Please try again later.'
        )
      }
    },
  })

  const screenOrientations = Object.values(EnumPlayerSettingsOrientation)

  return (
    <form onSubmit={formik.handleSubmit} tw="max-w-xl">
      {!!updatePlayerError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              message: updatePlayerError,
              variant: AlertVariant.ERROR,
              id: 'update-network-error-alert',
            }}
          />
        </div>
      )}
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Storage
        </span>
        <div tw="mb-8">
          {[...(player?.specs?.storage || [])]
            .filter(
              (storageAdapter) =>
                storageAdapter?.adapter !== 'ipfs' || NODE_ENV === 'development'
            )
            .map((storageAdapter) => (
              <div
                key={`storage-medium-${String(storageAdapter?.id)}`}
                css={tw`mr-2`}
              >
                <Radio
                  value={String(storageAdapter?.id)}
                  label={`${storageAdapter?.id} - ${
                    storageAdapter?.adapter
                  } - (total ${storageAdapter?.size?.total || 0} MB)`}
                  name="settings.orientation"
                  checked={
                    formik.values.settings?.storage_medium_id ===
                    String(storageAdapter?.id)
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      'settings.storage_medium_id',
                      String(storageAdapter?.id)
                    )
                  }
                />
              </div>
            ))}
        </div>
      </section>

      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Choose Embedded Players
        </span>

        <span tw="font-medium text-xs text-dark-blue-gray uppercase leading-tight">
          Image Player
        </span>
        <div tw="w-full flex mb-8">
          {['default', ...(player?.specs?.players?.image || [])].map(
            (playerIdentification) => (
              <div
                key={`storage-medium-${String(playerIdentification)}`}
                css={tw`mr-2`}
              >
                <Radio
                  value={String(playerIdentification)}
                  label={startCase(playerIdentification || '')}
                  name="settings.image_player"
                  checked={
                    formik.values.settings?.image_player ===
                    String(playerIdentification)
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      'settings.image_player',
                      String(playerIdentification)
                    )
                  }
                />
              </div>
            )
          )}
        </div>

        <span tw="font-medium text-xs text-dark-blue-gray uppercase leading-tight">
          Video Player
        </span>
        <div tw="w-full flex mb-8">
          {['default', ...(player?.specs?.players?.video || [])].map(
            (playerIdentification) => (
              <div
                key={`storage-medium-${String(playerIdentification)}`}
                css={tw`mr-2`}
              >
                <Radio
                  value={String(playerIdentification)}
                  label={startCase(playerIdentification || '')}
                  name="settings.video_player"
                  checked={
                    formik.values.settings?.video_player ===
                    String(playerIdentification)
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      'settings.video_player',
                      String(playerIdentification)
                    )
                  }
                />
              </div>
            )
          )}
        </div>

        {/* <span tw="font-medium text-xs text-dark-blue-gray uppercase leading-tight">
          Audio Player
        </span>
        <div tw="w-full flex mb-8">
          {['default', ...(player?.specs?.players?.audio || [])].map(
            (playerIdentification) => (
              <div
                key={`storage-medium-${String(playerIdentification)}`}
                css={tw`mr-2`}
              >
                <Radio
                  value={String(playerIdentification)}
                  label={startCase(playerIdentification || '')}
                  name="settings.audio_player"
                  checked={
                    formik.values.settings?.audio_player ===
                    String(playerIdentification)
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      'settings.audio_player',
                      String(playerIdentification)
                    )
                  }
                />
              </div>
            )
          )}
        </div> */}
      </section>

      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Screen
        </span>
        <div tw="mb-8">
          <SliderFormik
            name="settings.brightness"
            label="Brightness"
            onChange={formik.handleChange}
            formik={formik}
          />
          <span tw="text-xs tracking-wider block text-dark-blue-gray uppercase">
            Adjusting to {formik.values.settings.brightness}%
          </span>
        </div>
        <div tw="mb-8">
          <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
            Target Orientation
          </span>
          <div tw="w-full flex mt-2 mb-8">
            {screenOrientations.map((value) => (
              <div key={`orientation-${value.toLowerCase()}`} css={tw`mr-2`}>
                <Radio
                  value={value}
                  label={value.replace('_', ' ')}
                  name="settings.orientation"
                  checked={formik.values.settings?.orientation === value}
                  onChange={() =>
                    formik.setFieldValue('settings.orientation', value)
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div tw="mb-8">
          <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
            Screen Size
          </span>
          <div tw="w-full flex mt-2 mb-2">
            <div tw="w-24 mr-10">
              <InputFormik
                type="number"
                label="Width"
                name="settings.size.width"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
            <div tw="w-24">
              <InputFormik
                type="number"
                label="Height"
                name="settings.size.height"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
          </div>
          <span tw="text-xs tracking-wider block text-dark-blue-gray uppercase">
            Zero on both defaults to the sytems default screen density
          </span>
        </div>
        <div tw="mb-8">
          <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
            Custom Screen Density
          </span>
          <div tw="w-full flex mt-2 mb-2">
            <div tw="w-24 mr-10">
              <InputFormik
                type="number"
                label="Density"
                name="settings.density"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
          </div>
          <span tw="text-xs tracking-wider block text-dark-blue-gray uppercase">
            Zero defaults to the sytems default screen density
          </span>
        </div>
        <div tw="mb-8">
          <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
            Screen Overscan
          </span>
          <div tw="w-full flex mt-2 mb-2">
            <div tw="w-24 mr-10">
              <InputFormik
                type="number"
                label="Top"
                name="settings.overscan.top"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
            <div tw="w-24 mr-10">
              <InputFormik
                type="number"
                label="Right"
                name="settings.overscan.right"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
            <div tw="w-24 mr-10">
              <InputFormik
                type="number"
                label="Bottom"
                name="settings.overscan.bottom"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
            <div tw="w-24">
              <InputFormik
                type="number"
                label="Left"
                name="settings.overscan.left"
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
          </div>
          <span tw="text-xs tracking-wider block text-dark-blue-gray uppercase">
            Overscan allows you to add a border around the screen. You can
            control the border size by adjusting the values to positive numbers.
          </span>
        </div>
      </section>
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Audio
        </span>
        <div tw="mb-8">
          <SliderFormik
            name="settings.volume"
            label="Volume"
            onChange={formik.handleChange}
            formik={formik}
          />
          <span tw="text-xs tracking-wider block text-dark-blue-gray uppercase">
            Adjusting to {formik.values.settings.volume}%
          </span>
        </div>
      </section>
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Other
        </span>
        <div tw="mb-8">
          <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
            Debug Overlay
          </span>
          <div tw="w-full flex mt-2">
            {[
              { label: 'Activated', value: true },
              { label: 'Deactivated', value: false },
            ].map(({ label, value }) => (
              <div key={`debug-${String(value)}`} css={tw`mr-2`}>
                <Radio
                  value={String(value)}
                  label={label}
                  name="settings.debug"
                  checked={formik.values.settings?.debug === value}
                  onChange={() => formik.setFieldValue('settings.debug', value)}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update player settings
      </Button>
    </form>
  )
}

export default EditPlayerForm
