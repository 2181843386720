import React, { FC } from 'react'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Input from '../../../../ui-blocks/input'
import Spacer from '../../../../ui-blocks/spacer'

import useElementStates from '../../../../utils/use-element-states'

const InputSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'success', 'disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <Input label="Label" error={error ? 'Error message!' : ''} {...values} />
      <Spacer size="1.5rem" />
      <Input
        label="Label"
        placeholder="Placeholder..."
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <Input
        label="Label"
        initialValue="Initial value"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <Input
        label="Label"
        labelExtra="optional"
        initialValue="Initial value"
        error={error ? 'Error message!' : ''}
        {...values}
      />
    </ElementSection>
  )
}

export default InputSection
