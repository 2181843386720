import React, { FC, Fragment, useEffect, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router-dom'

import Link from '../../ui-blocks/link'
import Button from '../../ui-blocks/button'
import ButtonLink from '../../ui-blocks/button-link'

import SignOutModal from '../../components/sign-out-modal'

import { authentication } from '../../stores'

const AuthSignOutPage: FC = () => {
  const navigate = useNavigate()
  const [timer, setTimer] = useState<number>(30)

  useEffect(() => {
    const interval = setInterval(() => setTimer((timer) => timer - 1), 1000)
    return () => clearInterval(interval)
  }, [])

  if (timer <= 0) navigate('/', { replace: true })
  return authentication.is_authenticated ? (
    <SignOutModal open />
  ) : (
    <Fragment>
      <p tw="mb-8">
        You are not currently signed in to any Diffuse.tv account. We'll be
        redirecting you to the{' '}
        <Link
          router={{ to: '/auth/sign-in' }}
          tw="p-0 inline-flex min-h-auto min-w-auto"
        >
          Sign In
        </Link>{' '}
        page in {timer} second(s).
      </p>
      <p tw="mb-4">Or you can choose one of the actions below:</p>
      <div tw="flex flex-row items-center justify-center space-x-4">
        <ButtonLink to="/auth/sign-in">Go to Sign In page</ButtonLink>
        <Button
          secondary
          onClick={() => location.assign('https://diffuse.tv/')}
        >
          Go to the Website
        </Button>
      </div>
    </Fragment>
  )
}

export default AuthSignOutPage
