import React, { FC, HTMLAttributes, DetailedHTMLProps } from 'react'
import 'twin.macro'

import MonoIcons from 'mono-icons/iconfont/icons.json'
export const ICON_NAMES = Object.keys(MonoIcons)

export interface IconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon: string
}

const Icon: FC<IconProps> = ({ icon, className, ...props }) => {
  console.assert(
    ICON_NAMES.includes(icon),
    `Invalid icon name! Icon must be one of: ${ICON_NAMES.join(' | ')}`
  )
  return (
    <i
      title={icon}
      className={[`mi-${icon}`, className].filter(Boolean).join(' ')}
      tw="leading-none inline-flex"
      {...props}
    ></i>
  )
}

export default Icon
