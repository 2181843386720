import React, { FC, useEffect, useMemo } from 'react'
import 'twin.macro'

import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ArchivedAdGroupsListTable from './table'

import {
  useListArchivedCampaignAdGroupsQuery,
  useCountArchivedCampaignAdGroupsQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { SearchSettings } from '../../../../utils/use-search'

export interface AdGroupsSectionProps {
  onSearch?: (settings: SearchSettings) => void
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  searchTextDebounced: string
}

const AdGroupsSection: FC<AdGroupsSectionProps> = ({
  onSearch,
  searchText,
  searchTextDebounced,
  setSearchText,
}) => {
  const {
    data: archivedAdGroupsCountData,
    loading: loadingArchivedAdGroupsCount,
  } = useCountArchivedCampaignAdGroupsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const count = useMemo(() => archivedAdGroupsCountData?.adGroups || 0, [
    archivedAdGroupsCountData,
  ])
  const pagination = usePagination(count, 15, searchTextDebounced)
  const {
    data: archivedAdGroupsData,
    loading: loadingArchivedAdGroups,
    refetch: refetchArchivedAdGroups,
  } = useListArchivedCampaignAdGroupsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const archivedAdGroups = useMemo(() => archivedAdGroupsData?.adGroups || [], [
    archivedAdGroupsData,
  ])

  const refetchData = async () => await refetchArchivedAdGroups()
  useEffect(() => {
    document.addEventListener('refetch-archived-ad-groups', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-ad-groups', refetchData)
    }
  }, [])

  useEffect(() => {
    onSearch?.({
      text: searchText,
      loading: loadingArchivedAdGroups && loadingArchivedAdGroupsCount,
      entries: archivedAdGroups.map((adGroup) => adGroup.name),
      onSelect: (value) =>
        setSearchText(!Array.isArray(value) ? value || '' : ''),
    })
  }, [
    searchText,
    archivedAdGroups,
    loadingArchivedAdGroups,
    loadingArchivedAdGroupsCount,
  ])

  return (
    <section>
      <ArchivedAdGroupsListTable
        data={archivedAdGroups}
        loading={loadingArchivedAdGroups && loadingArchivedAdGroupsCount}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default AdGroupsSection
