import React, { FC, useMemo } from 'react'

import tw from 'twin.macro'
import { useNavigate } from 'react-router'

import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'

import SetupForm, { Step } from '../../../components/setup-form'

import NetworkDetailsStep, {
  networkDetailsValidationSchema,
} from './steps/1-network-details'
import TargetPlayersStep, {
  targetPlayersValidationSchema,
} from './steps/2-target-players'

import {
  useCountItemsQuery,
  useCreateNetworkMutation,
} from '../../../graphql/components'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export interface CreateNetworkFormikValues {
  name: string
  players_ids: string[]
}

const CreateNetworkPage: FC = () => {
  const navigate = useNavigate()
  const { data: countData, loading: loadingCount } = useCountItemsQuery({
    fetchPolicy: 'cache-first',
  })

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-network-error-alert',
      message: "We couldn't create the network. Please try again later.",
    })
  }

  const [createNetwork] = useCreateNetworkMutation()
  const handleSubmit = async (values: CreateNetworkFormikValues) => {
    try {
      const res = await createNetwork({
        variables: {
          input: {
            name: values.name,
            players: values.players_ids.map((player_id) => ({ player_id })),
          },
        },
      })

      if (!res.data?.network?._id) {
        handleError()
        return false
      }

      document.dispatchEvent(new CustomEvent('refetch-networks'))
      navigate(`/networks/${res.data?.network?._id}`)
      return true
    } catch {
      handleError()
      return false
    }
  }

  const hasPlayers = useMemo(() => !!countData?.players, [countData])
  const steps: Step[] = [
    {
      canGoBack: true,
      title: 'Create a new Network',
      content: <NetworkDetailsStep />,
      validationSchema: networkDetailsValidationSchema,
    },
    ...(hasPlayers
      ? [
          {
            canGoBack: true,
            title: 'Target players',
            content: <TargetPlayersStep />,
            disabledTriggers: ['players_ids'],
            validationSchema: targetPlayersValidationSchema,
          },
        ]
      : []),
  ]

  return (
    <Container>
      <div css={[tw`self-center w-full`, 'max-width: 68rem;']}>
        <Suspense ready={!loadingCount} fallback={<Spinner center />}>
          <SetupForm
            title="New Network"
            description="Create a network to group players."
            initialValues={{ name: '', players_ids: [] }}
            onSubmit={{ [steps.length - 1]: handleSubmit }}
            steps={steps}
          />
        </Suspense>
      </div>
    </Container>
  )
}

export default CreateNetworkPage
