import React, { FC, ReactNode, useState } from 'react'

import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'

export interface INavItem {
  label: string
  to: string
  childItems?: INavItem[]
}

export interface NavItemProps extends INavItem {
  isSelected?: boolean
  icon?: ReactNode
}

const NavItem: FC<NavItemProps> = ({
  label,
  to,
  isSelected,
  icon,
  childItems,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const Wrapper = !!childItems?.length ? 'div' : Link
  return (
    <Wrapper
      to={to}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      css={[
        css`
          line-height: 1.375rem;
          ${tw`py-4 font-medium text-dark-blue-gray hover:cursor-pointer hover:text-spanish-violet focus:text-spanish-violet relative transition duration-200`}
          ${(!!icon || (!!childItems && !!childItems.length)) &&
          tw`flex flex-row items-center`}
          &:hover {
            box-shadow: inset 0px -2px 0px 0px #572779;
          }
        `,
        ...(isSelected
          ? [
              tw`text-spanish-violet`,
              'box-shadow: inset 0px -2px 0px 0px #572779;',
            ]
          : []),
      ]}
      {...props}
    >
      {icon}
      {label}
      {!!childItems && childItems.length > 0 && (
        <>
          <Icon
            icon="chevron-down"
            css={[
              tw`text-base ml-1 transition transform duration-200 rotate-0`,
              isOpen && tw`rotate-180`,
            ]}
          />
          <div css={[!isOpen && tw`hidden`, tw`pt-1 absolute top-full`]}>
            <ul tw="w-40 bg-white border border-platinum rounded">
              {childItems.map((childItem, index) => (
                <li key={`child-item#${index}`}>
                  <Link
                    to={childItem.to}
                    tw="py-2 px-4 block text-dark-blue-gray hover:text-spanish-violet focus:text-spanish-violet transition duration-200"
                  >
                    {childItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default NavItem
