import React, { FC } from 'react'
import tw from 'twin.macro'

import Text from './text'
import Card from './card'
import Button from './button'

export interface SetupCardProps {
  title?: string
  description?: string
  disabled?: boolean
  buttonText?: string
  Image?: React.ReactNode
  onButtonClick?: () => void
}

const SetupCard: FC<SetupCardProps> = ({
  Image,
  title,
  description,
  buttonText,
  onButtonClick,
  disabled,
}) => (
  <Card
    css={[
      tw`p-6`,
      'width: 100%;',
      'max-width: 33rem;',
      !!disabled && tw`opacity-50 cursor-not-allowed`,
    ]}
  >
    <div tw="flex flex-col items-center">
      {Image}
      {!!title && (
        <Text as="h1" preset="h4" tw="mt-3">
          {title}
        </Text>
      )}
      {!!description && (
        <Text as="p" preset="p1" tw="mt-2" align="center">
          {description}
        </Text>
      )}
      <div tw="mt-8 mb-2">
        <Button secondary disabled={disabled} onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  </Card>
)

export default SetupCard
