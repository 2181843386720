import React, { FC, Fragment } from 'react'
import tw, { styled } from 'twin.macro'

import Icon from '../../../../ui-blocks/icon'
import Spinner from '../../../../ui-blocks/spinner'

import UploadsManager, {
  Upload,
  UploadState,
} from '../../../../stores/uploads-manager'

import { formatFileSize } from '../../../../utils/formaters'

import { ReactComponent as CircleCheckFilledIcon } from '../../../../assets/icons/circle_check_filled.svg'
import { ReactComponent as CircleWarningFilledIcon } from '../../../../assets/icons/circle_warning_filled.svg'

export interface UploadProgressProps {
  upload: Upload
  manager: UploadsManager
}

const UploadProgress: FC<UploadProgressProps> = ({ upload, manager }) => {
  const progress = Math.round((upload?.progress || 0) * 100)
  return (
    <div tw="w-full">
      <div tw="flex flex-row items-center justify-between">
        <div tw="flex flex-row items-center">
          <div
            css={[
              'max-width: 13rem;',
              tw`flex flex-row items-center space-x-2`,
            ]}
          >
            <span tw="text-dark-blue-gray truncate" title={upload.file.name}>
              {upload.file.name}
            </span>
            <span tw="flex-shrink-0 text-dark-blue-gray text-xs">
              {`| ${formatFileSize(upload.file.size)}`}
            </span>
          </div>

          <div
            css={[
              tw`flex flex-row items-center flex-shrink-0 space-x-1 ml-8`,
              upload.state === UploadState.FAILED && tw`text-brick-red`,
              upload.state === UploadState.CANCELLED && tw`text-dark-yellow`,
              upload.state === UploadState.FINALIZING && tw`text-flickr-blue`,
              upload.state === UploadState.COMPLETED &&
                tw`text-metallic-seaweed`,
            ]}
          >
            {upload.state === UploadState.FAILED && (
              <Fragment>
                <ColoredCircleWarningFilledIcon width={16} height={16} />
                <span tw="text-xs">Error</span>
              </Fragment>
            )}
            {upload.state === UploadState.COMPLETED && (
              <Fragment>
                <CircleCheckFilledIcon width={16} height={16} />
                <span tw="text-xs">Completed</span>
              </Fragment>
            )}
            {upload.state === UploadState.CANCELLED && (
              <Fragment>
                <ColoredCircleWarningFilledIcon width={16} height={16} />
                <span tw="text-xs">Cancelled</span>
              </Fragment>
            )}
            {upload.state === UploadState.FINALIZING && (
              <Fragment>
                <Icon icon="database" tw="text-base" />
                <span tw="text-xs">Finalizing</span>
              </Fragment>
            )}
          </div>
        </div>

        <div tw="flex flex-row items-center flex-shrink-0 space-x-2">
          {(upload.state === UploadState.WAITING ||
            upload.state === UploadState.UPLOADING ||
            //TODO: Maybe remove finalizing from this?
            upload.state === UploadState.FINALIZING) && (
            <Spinner tw="w-3.5 h-3.5 border text-dark-blue-gray" />
          )}
          {(upload.state === UploadState.FAILED ||
            upload.state === UploadState.CANCELLED) && (
            <Icon
              icon="refresh"
              title="Retry"
              tw="text-lg text-dark-blue-gray transition duration-200 hover:(text-dark-orchid cursor-pointer)"
              onClick={() => manager.retry(upload.id)}
            />
          )}
          {upload.state === UploadState.COMPLETED ? (
            <span
              tw="font-normal text-xs text-dark-blue-gray transition duration-200 hover:(text-dark-orchid cursor-pointer)"
              onClick={() => manager.dismiss(upload.id)}
            >
              dismiss
            </span>
          ) : (
            <Icon
              icon="close"
              title="Cancel"
              tw="text-lg text-dark-blue-gray transition duration-200 hover:(text-dark-orchid cursor-pointer)"
              onClick={() => manager.cancel(upload.id)}
            />
          )}
        </div>
      </div>

      {upload.state !== UploadState.COMPLETED && (
        <div tw="w-full relative mt-1 h-1 block">
          <hr tw="h-1 w-full absolute left-0 border-0 rounded-full bg-platinum" />
          <hr
            css={[
              `width: ${progress}%;`,
              tw`h-1 absolute left-0 border-0 rounded-full transition duration-200`,
              upload.state === UploadState.FAILED && tw`bg-brick-red`,
              upload.state === UploadState.CANCELLED && tw`bg-dark-yellow`,
              upload.state === UploadState.FINALIZING && tw`bg-flickr-blue`,
              upload.state === UploadState.UPLOADING && tw`bg-metallic-seaweed`,
            ]}
          />
        </div>
      )}
    </div>
  )
}

const ColoredCircleWarningFilledIcon = styled(CircleWarningFilledIcon)`
  & circle {
    ${tw`fill-current`}
  }
`

export default UploadProgress
