import React, { FC, Fragment, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router'

import Button from '../../../../../../ui-blocks/button'

import ArchiveWarningModal from '../../../../../../components/archive-warning-modal'

import {
  NetworkFullFragment,
  useArchiveNetworkMutation,
} from '../../../../../../graphql/components'

import { alertsManager } from '../../../../../../stores'
import { AlertVariant } from '../../../../../../stores/alerts-manager'

export interface ArchiveNetworkFormProps {
  network?: NetworkFullFragment
}

const ArchiveNetworkForm: FC<ArchiveNetworkFormProps> = ({ network }) => {
  const navigate = useNavigate()
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)

  const [
    archiveNetwork,
    { loading: archivingNetwork },
  ] = useArchiveNetworkMutation()

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'archive-network-error-alert',
    })
  }

  const onArchiveNetwork = async () => {
    try {
      const { data } = await archiveNetwork({
        variables: { ids: [network?._id] },
      })
      if (!data?.areArchived) {
        return handleError(
          "We couldn't archive this network. Please try again later."
        )
      }
      navigate('/networks', { state: { refetch: true } })
    } catch {
      handleError(
        'Something went wrong while trying to archive this network. Please try again later.'
      )
    }
  }

  return (
    <Fragment>
      <span tw="font-medium text-charcoal leading-tight block mb-2">
        Archive
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-10">
        Once you archive a network it will no longer be available.
      </span>
      <Button danger type="button" onClick={() => openWarningModal(true)}>
        Archive network
      </Button>
      <ArchiveWarningModal
        target="network"
        redirectPage="Networks"
        open={isWarningModalOpen}
        loading={archivingNetwork}
        onConfirm={onArchiveNetwork}
        onCancel={() => openWarningModal(false)}
      />
    </Fragment>
  )
}

export default ArchiveNetworkForm
