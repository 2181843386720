import React, { FC, RefObject, useMemo } from 'react'

import tw from 'twin.macro'
import { Moment } from 'moment'

import Icon from './icon'
import Text from './text'

import ErrorMessage from '../components/error-message'

import useDatePicker, { PickerProps } from '../utils/use-date-picker'

export interface DatePickerContainerProps extends PickerProps {
  value?: string
  maxWidth?: string
  placeholder?: string
  clearable?: boolean
  inputRef: RefObject<HTMLInputElement>
  onClear?: () => void
}

export const DatePickerContainer: FC<DatePickerContainerProps> = ({
  id,
  name,
  label,
  value,
  error,
  success,
  disabled,
  maxWidth,
  inputRef,
  placeholder,
  clearable,
  onClear,
}) => (
  <div
    id={`parent-${id}`}
    css={[
      tw`relative w-full`,
      !!maxWidth ? `max-width: ${maxWidth};` : tw`max-w-full`,
    ]}
  >
    {!!label && (
      <Text as="label" preset="caption" tw="pb-2" htmlFor={id}>
        {label}
      </Text>
    )}
    <div tw="relative">
      <input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={() => null}
        title={value}
        ref={inputRef}
        disabled={disabled}
        placeholder={placeholder}
        css={[
          tw`block w-full truncate h-10 px-10 py-2 leading-normal transition duration-100 border rounded outline-none cursor-pointer text-charcoal border-platinum`,
          !!disabled && tw`opacity-75 cursor-not-allowed`,
          !disabled && tw`hover:border-light-peri`,
          success && tw`border-metallic-seaweed`,
          !!error && tw`border-brick-red`,
        ]}
      />
      <Icon
        icon="calendar"
        tw="text-lg text-dark-blue-gray absolute left-0 top-2/4 transform -translate-y-2/4 ml-4 pointer-events-none"
      />
      {clearable && (
        <Icon
          icon="close"
          onClick={onClear}
          tw="text-base text-dark-blue-gray absolute right-0 top-2/4 transform -translate-y-2/4 mr-4 hover:cursor-pointer"
        />
      )}
    </div>
    {!!error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
)

export interface DatePickerProps extends PickerProps {
  id?: string
  date?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (date?: Moment) => void
}

export const DatePicker: FC<DatePickerProps> = ({
  id,
  name,
  date,
  label,
  error,
  success,
  disabled,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )
  const { value, hasValue, inputRef } = useDatePicker(false, {
    parentEl: `#parent-${inputId}`,
    minDate,
    maxDate,
    startDate: date,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="date"
      maxWidth="12rem"
    />
  )
}

export interface DateRangePickerProps extends PickerProps {
  id?: string
  startDate?: Moment
  endDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (startDate?: Moment, endDate?: Moment) => void
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  id,
  name,
  label,
  error,
  success,
  disabled,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )
  const { value, hasValue, inputRef } = useDatePicker(true, {
    parentEl: `#parent-${inputId}`,
    startDate,
    endDate,
    minDate,
    maxDate,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="start date - end date"
      maxWidth="18rem"
    />
  )
}
