import React, { FC, Fragment, useMemo, useState } from 'react'
import 'twin.macro'

import { useNavigate } from 'react-router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Text from '../../../ui-blocks/text'
import Link from '../../../ui-blocks/link'
import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'

import WorkspaceInvite from './components/workspace-invite'

import {
  Workspace,
  useListWorkspacesQuery,
  useAcceptWorkspaceInvitesMutation,
  useRejectWorkspaceInvitesMutation,
  WhoAmIUserInviteResult,
} from '../../../graphql/components'

import { authentication } from '../../../stores'
import { To } from 'history'
import Maybe from 'graphql/tsutils/Maybe'

export type InviteAction = 'accept' | 'decline' | ''
export interface IPendingInviteProps {
  onAcceptInvite: (inviteId: string) => Promise<void>
  onRejectInvite: (inviteId: string) => Promise<void>
  ready: boolean
  workspaces: {
    [key: string]: Workspace
  }
  invites: Maybe<
    { __typename?: 'WhoAmIUserInviteResult' | undefined } & Pick<
      WhoAmIUserInviteResult,
      '_id' | 'status' | 'workspace_id'
    >
  >[]
  errors: { [key: string]: string }
  loaders: { [key: string]: InviteAction }
  to: To
  linkName: string
}

const WorkspacePendingInvites: FC<IPendingInviteProps> = ({
  onAcceptInvite,
  onRejectInvite,
  ready,
  workspaces,
  invites,
  errors,
  loaders,
  to,
  linkName,
}: IPendingInviteProps) => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <Text as="h1" preset="h2" tw="mb-1">
        You’ve been invited
      </Text>
      <Text as="h2" preset="p1" tw="text-dark-blue-gray mb-10">
        While you were gone the following teams invited you to colaborate.
      </Text>
      <Text as="h3" preset="p1" weight="medium" tw="mb-4">
        Workspaces
      </Text>
      <Text as="h4" preset="p2" transform="uppercase" tw="tracking-wider mb-4">
        {`${invites.length} Pending Invitation(s)`}
      </Text>
      <Suspense
        ready={ready}
        fallback={
          <div tw="w-full flex items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <div tw="space-y-4">
          {invites.map((invite) => (
            <WorkspaceInvite
              key={`invite-${invite?._id}`}
              error={errors[invite?._id]}
              name={workspaces[invite?.workspace_id]?.name}
              loadingAccept={loaders[invite?._id] === 'accept'}
              loadingDecline={loaders[invite?._id] === 'decline'}
              members={
                workspaces[invite?.workspace_id]?.members?.filter(
                  (member) => member?.removed?.at === null
                ).length
              }
              onAccept={() => onAcceptInvite(invite?._id)}
              onDecline={() => onRejectInvite(invite?._id)}
            />
          ))}
        </div>
      </Suspense>
      <Link
        onClick={() => navigate(to)}
        tw="mt-10 p-0 min-w-auto min-h-auto inline-flex"
      >
        {linkName}
      </Link>
    </Fragment>
  )
}

export const usePendingInvites = (
  to: To = '-1',
  linkName = 'Back'
): IPendingInviteProps => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [loaders, setLoaders] = useState<{ [key: string]: InviteAction }>({})

  const invites = useMemo(() => authentication.user?.invites || [], [
    authentication,
    authentication.user,
  ])
  const workspacesIds = useMemo(
    () => invites.map((invite) => invite?.workspace_id),
    [invites]
  )

  // @NOTE:
  // - This probably won't stay this way
  // - The info should probaly be on the invites themselves
  const {
    data: workspacesData,
    loading: loadingWorkspaces,
  } = useListWorkspacesQuery({
    fetchPolicy: 'cache-first',
    skip: !workspacesIds.length,
    variables: { _ids: workspacesIds },
  })
  const workspaces = useMemo(
    () =>
      (workspacesData?.workspaces || []).reduce<{
        [key: string]: Workspace
      }>((acc, workspace) => ({ ...acc, [workspace._id]: workspace }), {}),
    [workspacesData]
  )

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [acceptWorkspaceInvites] = useAcceptWorkspaceInvitesMutation()
  const [rejectWorkspaceInvites] = useRejectWorkspaceInvitesMutation()

  const onProcessInvite = async (
    action: InviteAction,
    inviteId: string,
    mutation: (options?: any) => Promise<any>,
    errorMessage: string
  ) => {
    setErrors({ ...errors, [inviteId]: '' })
    setLoaders({ ...loaders, [inviteId]: action })

    const token = await executeRecaptcha?.(`invites/management/${action}`)
    const { data } = await mutation({
      variables: { ids: [inviteId] },
      context: {
        headers: {
          ...(!!token ? { 'X-Recaptcha-Token': token } : {}),
        },
      },
    })

    setLoaders({ ...loaders, [inviteId]: '' })
    if (data?.invites.length === 0)
      setErrors({ ...errors, [inviteId]: errorMessage })

    await authentication.loadUserData()
  }

  const onAcceptInvite = async (inviteId: string) => {
    await onProcessInvite(
      'accept',
      inviteId,
      acceptWorkspaceInvites,
      'A problem occurred while accepting the invite. Please try again later.'
    )
  }

  const onRejectInvite = async (inviteId: string) => {
    await onProcessInvite(
      'decline',
      inviteId,
      rejectWorkspaceInvites,
      'A problem occurred while declining the invite. Please try again later.'
    )
  }

  return {
    onAcceptInvite,
    onRejectInvite,
    ready: !loadingWorkspaces && !!workspaces,
    invites,
    workspaces,
    errors,
    to,
    linkName,
    loaders,
  }
}

export default WorkspacePendingInvites
