import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../components/alert-component'

import {
  CampaignFullFragment,
  useUpdateCampaignByIdMutation,
} from '../../../../../../graphql/components'

import { trimValues } from '../../../../../../utils/data-manipulation'

interface EditCampaignFormikValues {
  name: string
}

const editCampaignValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

export interface EditCampaignFormProps {
  campaign?: CampaignFullFragment
  refetchCampaign?: () => Promise<any>
}

const EditCampaignForm: FC<EditCampaignFormProps> = ({
  campaign,
  refetchCampaign,
}) => {
  const [updateCampaignError, setUpdateCampaignError] = useState<string>('')

  const [
    updateCampaign,
    { loading: updatingCampaign },
  ] = useUpdateCampaignByIdMutation()
  const formik = useFormik<EditCampaignFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editCampaignValidationSchema,
    initialValues: { name: campaign?.name || '' },
    async onSubmit(values) {
      setUpdateCampaignError('')
      try {
        const newValues = trimValues(values, ['name'])
        const res = await updateCampaign({
          variables: { _id: campaign?._id, input: newValues },
        })
        if ((res.errors || []).length > 0) {
          return setUpdateCampaignError(
            "We couldn't update this campaign. Please try again later."
          )
        }
        await refetchCampaign?.()
      } catch {
        setUpdateCampaignError(
          'Something went wrong while trying to update this campaign. Please try again later.'
        )
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {!!updateCampaignError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              message: updateCampaignError,
              id: 'update-campaign-error-alert',
            }}
          />
        </div>
      )}
      <div tw="max-w-xs mb-10">
        <InputFormik type="text" name="name" label="Name" formik={formik} />
      </div>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={updatingCampaign || formik.isSubmitting}
      >
        Update campaign details
      </Button>
    </form>
  )
}

export default EditCampaignForm
