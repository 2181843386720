import 'twin.macro'
import React, { FC, useMemo } from 'react'

import ListExternalCampaignsPage from './external'
import ListInternalCampaignsPage from './internal'

import {
  useCountItemsQuery,
  EnumWorkspacePlatform_Type,
} from '../../../graphql/components'

import { authentication } from '../../../stores'

const ListCampaignsPage: FC = () => {
  const { data, loading, refetch } = useCountItemsQuery({
    fetchPolicy: 'cache-first',
  })

  const playersCount = useMemo(() => data?.players ?? 0, [data])
  const creativesCount = useMemo(() => data?.creatives ?? 0, [data])
  const networksCount = useMemo(() => data?.networks ?? 0, [data])
  const campaignsCount = useMemo(() => data?.campaigns ?? 0, [data])

  const hasPlayers = useMemo(() => !!playersCount, [playersCount])
  const hasCreatives = useMemo(() => !!creativesCount, [creativesCount])
  const hasCampaigns = useMemo(() => !!campaignsCount, [campaignsCount])

  return (
    <>
      <ListInternalCampaignsPage
        refetchCount={refetch}
        countData={{
          loading,
          players: playersCount,
          creatives: creativesCount,
          networks: networksCount,
          campaigns: campaignsCount,
        }}
      />
      {(hasCampaigns ||
        ((hasPlayers ||
          authentication.selected_workspace.platform_type ===
            EnumWorkspacePlatform_Type.Advertiser) &&
          hasCreatives)) && <ListExternalCampaignsPage />}
    </>
  )
}

export default ListCampaignsPage
