import React, { FC } from 'react'
import 'twin.macro'

import Container from '../ui-blocks/container'
import ButtonLink from '../ui-blocks/button-link'
import Illustration, { IllustrationEnum } from '../ui-blocks/illustration'

import { authentication } from '../stores'

export interface NotFoundProps {
  title?: string
  description?: string | string[]
}

const NotFound: FC<NotFoundProps> = ({
  title,
  description = [
    "The content you tried to access doesn't exist or we couldn't retrieve it.",
    'Please try again later or check if the page you tried to access is correct',
  ],
}) => (
  <Container>
    <div tw="flex flex-col items-center justify-center">
      <Illustration name={IllustrationEnum.players_empty_results} />
      <h1 tw="font-light text-3xl leading-tight my-4">
        {title || 'This page is unavailable'}
      </h1>
      <div tw="font-normal text-center leading-tight mb-10">
        {Array.isArray(description) ? (
          description.map((line, index) => <p key={`line#${index}`}>{line}</p>)
        ) : (
          <p>{description}</p>
        )}
      </div>
      <ButtonLink
        to={`/workspaces/${authentication.selected_workspace_id}/overview`}
      >
        Back to Workspace Overview
      </ButtonLink>
    </div>
  </Container>
)

export default NotFound
