import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { Link, useLocation } from 'react-router-dom'

import Text from '../../ui-blocks/text'
import Icon from '../../ui-blocks/icon'
import ButtonLink from '../../ui-blocks/button-link'

import WorkspaceMenu, { WorkspaceMenuAction } from './components/workspace-menu'
import NavItem, { INavItem } from './components/nav-item'

import { ReactComponent as DiffuseLogo } from '../../assets/diffuse-logo.svg'
import { ReactComponent as DiffuseIcon } from '../../assets/diffuse-icon.svg'

import { authentication } from '../../stores'

import { useCrisp } from '../../crisp'

export interface HeaderProps {
  displayLogo?: boolean
  navItems: INavItem[]
  workspaceMenuActions?: WorkspaceMenuAction[]
}

const Header: FC<HeaderProps> = ({
  displayLogo,
  navItems,
  workspaceMenuActions,
}) => {
  const { pathname } = useLocation()

  const checkSelection = (navItem: INavItem) => {
    if (!navItem.childItems?.length) return pathname.startsWith(navItem.to)
    return navItem.childItems.some((childItem) =>
      pathname.startsWith(childItem.to)
    )
  }

  const {
    isActive: isCrispActive,
    isOpen: isCrispOpen,
    toggle: toggleCrisp,
  } = useCrisp()

  return (
    <header
      css={['z-index: 100;', tw`w-full bg-white border-b border-platinum`]}
    >
      <div tw="lg:container px-4 lg:px-8 lg:mx-auto flex flex-row items-center justify-between min-h-12">
        <nav tw="flex flex-row items-center h-full space-x-4 lg:space-x-8">
          {displayLogo && (
            <Link to="/">
              <DiffuseLogo tw="h-8 hidden lg:block" />
              <DiffuseIcon tw="h-8 block lg:hidden" />
            </Link>
          )}
          {navItems.map((navItem, index) => (
            <NavItem
              key={`nav-item#${index}`}
              isSelected={checkSelection(navItem)}
              {...navItem}
            />
          ))}
        </nav>
        <nav tw="flex flex-row items-center space-x-4 lg:space-x-8 ml-2">
          {authentication.is_trial_active && (
            <>
              <Text
                as="span"
                preset="p2"
                decoration="underline"
                tw="text-spanish-violet mr-4 lg:mr-8"
              >
                {!!authentication.trial_remaining_days
                  ? `${authentication.trial_remaining_days} days left in your trial`
                  : 'Your trial reached the end'}
              </Text>
              <ButtonLink to="#" tw="mr-12">
                Select Plan
              </ButtonLink>
            </>
          )}

          {isCrispActive && (
            <a
              css={[
                css`
                  ${tw`py-4 font-medium text-xs flex flex-row items-center no-underline text-dark-blue-gray hover:cursor-pointer hover:text-spanish-violet focus:text-spanish-violet relative transition duration-200`}

                  &:hover {
                    box-shadow: inset 0px -2px 0px 0px #572779;
                  }
                `,
                ...(isCrispOpen
                  ? [
                      tw`text-spanish-violet`,
                      'box-shadow: inset 0px -2px 0px 0px #572779;',
                    ]
                  : []),
              ]}
              onClick={toggleCrisp}
            >
              <Icon icon="message-alt" tw="no-underline pr-1" />
              <span tw="hidden md:block">Help Center</span>
            </a>
          )}

          <WorkspaceMenu actions={workspaceMenuActions} />
        </nav>
      </div>
    </header>
  )
}

export default Header
