import { useMemo } from 'react'
import moment from 'moment'
import mem from 'mem'

export function useTimeParser(clock: string) {
  return useMemo(() => {
    const [hh, mm] = clock.split(':')
    return moment().hours(Number(hh)).minutes(Number(mm))
  }, [clock])
}

export const getLocaleWeekday = mem((weekday: number) => {
  return moment().weekday(weekday).format('dddd')
})
