import React, { FC } from 'react'
import 'twin.macro'

export interface ElementSectionProps {
  id: string
  title: string
  description?: string
}

const ElementSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  children,
}) => (
  <div id={id}>
    <h3 tw="text-3xl font-light leading-tight mb-3">{title}</h3>
    {!!description && <p tw="font-normal leading-tight mb-5">{description}</p>}
    {children}
  </div>
)

export default ElementSection
