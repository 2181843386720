import React, { FC, useRef, ReactNode } from 'react'
import tw, { css } from 'twin.macro'

import Icon from './icon'
import Text from './text'
import Card from './card'

import { isChecked, useOnToggle } from '../utils/formik-helpers'

import { IFormikObject } from '../typings'

export interface CardResourceProps {
  type?: 'radio' | 'checkbox'
  value?: string
  checked?: boolean
  onClick?: () => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  disabled?: boolean

  title?: string
  description?: string
  icon?: ReactNode
}

const CardResource: FC<CardResourceProps> = ({
  type,
  value,
  disabled,
  name,
  checked,
  onClick,
  onChange,
  title,
  description,
  icon,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const finalIcon = icon || <Icon icon="favorite" tw="text-2xl text-purple" />

  return (
    <Card
      css={css`
        ${tw`flex`}
        width: 9.25rem;
        transition: border 0.15s ease-in-out, transform 0.15s ease-in-out;
        ${checked && tw`border-purple`}
        &:hover {
          transform: scale(1.05);
          ${!disabled && tw`border-purple cursor-not-allowed`}
        }
      `}
    >
      <div
        onClick={() => {
          inputRef?.current?.click()
          onClick && onClick()
        }}
        css={[
          tw`w-full p-4 relative cursor-pointer`,
          !!disabled && tw`opacity-75 cursor-not-allowed`,
        ]}
      >
        <input
          ref={inputRef}
          name={name}
          type={type || 'checkbox'}
          checked={checked}
          value={value}
          onChange={onChange}
          readOnly
          tw="hidden"
        />

        <div tw="flex flex-col items-center">
          <div tw="w-12 h-12 bg-cultured rounded-full flex justify-center items-center">
            {finalIcon}
          </div>
          <Text as="p" preset="p1" weight="medium" tw="mt-4 mb-2">
            {title}
          </Text>
          <Text as="span" preset="p1" align="center">
            {description}
          </Text>
        </div>

        {checked && (
          <div tw="w-4 h-4 bg-purple rounded-full flex justify-center items-center absolute -top-2 -right-2">
            <Icon icon="check" css={[tw`text-white`, 'font-size: 0.625rem;']} />
          </div>
        )}
      </div>
    </Card>
  )
}

export default CardResource

export interface CardResourceFormikProps extends CardResourceProps {
  formik: IFormikObject<any>
  name: Exclude<CardResourceProps['name'], undefined>
  value: Exclude<CardResourceProps['value'], undefined>
}

export const CardResourceFormik: FC<CardResourceFormikProps> = ({
  formik,
  ...props
}) => {
  const onToggle = useOnToggle()

  return (
    <CardResource
      {...props}
      checked={isChecked(formik, props)}
      onChange={onToggle}
    />
  )
}
