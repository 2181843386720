import React, { FC, InputHTMLAttributes, useMemo, useRef } from 'react'
import tw, { css } from 'twin.macro'

import ErrorMessage from '../components/error-message'
import { IFormikObject } from '../typings'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

const Radio: FC<RadioProps> = ({
  id,
  label,
  error,
  checked,
  disabled,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const inputId = useMemo(
    () => id || `radio-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )

  return (
    <div tw="w-full">
      <div
        css={css`
          ${tw`flex flex-row items-center`}
          &:hover {
            .input-circle {
              ${!disabled && tw`border-purple`}
            }
          }
        `}
      >
        <input
          tw="hidden"
          type="radio"
          id={inputId}
          ref={inputRef}
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <div
          className="input-circle"
          onClick={() => inputRef.current?.click()}
          css={[
            tw`flex items-center justify-center w-4 h-4 bg-white rounded-full border border-dark-blue-gray transition duration-200 hover:cursor-pointer`,
            !!checked && tw`bg-purple border-purple`,
            !!disabled && tw`hover:cursor-not-allowed`,
            !!checked &&
              !!disabled &&
              tw`bg-dark-blue-gray border-dark-blue-gray`,
          ]}
        >
          {!!checked && (
            <div
              css={[
                'width: 0.375rem;',
                'height: 0.375rem;',
                tw`bg-white rounded-full`,
              ]}
            />
          )}
        </div>
        {!!label && (
          <label
            htmlFor={inputId}
            css={[
              tw`font-normal text-charcoal leading-tight pl-2 hover:cursor-pointer`,
              !!disabled &&
                tw`font-light text-dark-blue-gray hover:cursor-not-allowed`,
            ]}
          >
            {label}
          </label>
        )}
      </div>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

export default Radio

export interface RadioFormikProps extends RadioProps {
  formik?: IFormikObject<any>
}

export const RadioFormik: FC<RadioFormikProps> = ({ formik, ...props }) => {
  const meta = formik?.getFieldMeta(props.name || '')
  return (
    <Radio
      {...props}
      checked={meta?.value === props.value}
      onChange={(e) => {
        if (!formik || !props.name) {
          return
        }

        if (typeof e.preventDefault === 'function') {
          e.preventDefault()
        }

        if (typeof e.stopPropagation === 'function') {
          e.stopPropagation()
        }

        const { name, value } = props

        // set the new array with formik
        formik.setFieldValue(name, value)
      }}
    />
  )
}
