import { createHash } from 'crypto'
import React, { FC, useMemo } from 'react'
import Avatar, { AvatarProps } from './avatar'

export type GravatarProps = AvatarProps & { email?: string }

const Gravatar: FC<GravatarProps> = ({ email, ...props }) => {
  const hash = useMemo(() => (email && getHashFromEmail(email)) || '', [email])
  const size = (typeof props.size === 'number' && props.size) || 128

  const src = email
    ? `https://gravatar.com/avatar/${hash}?s=${size}&default=identicon`
    : props.src
  return <Avatar {...props} src={src}></Avatar>
}

const getHashFromEmail = (email: string): string => {
  return createHash('md5').update(email.trim().toLowerCase()).digest('hex')
}

export default Gravatar
