import React, { FC, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import tw from 'twin.macro'
import { Link, useNavigate } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Text from '../../../ui-blocks/text'

import getInitials from '../../../utils/get-initials'

import {
  useViewWorkspaceQuery,
  WhoAmIUserWorkspaceResult,
} from '../../../graphql/components'

import { authentication } from '../../../stores'
import Gravatar from '../../../ui-blocks/gravatar'

export interface WorkspaceMenuAction {
  label: string
  linkTo?: string
  isActive?: boolean
  onClick?: () => void
  extra?: any
}

export interface WorkspaceMenuProps {
  actions?: WorkspaceMenuAction[]
}

const WorkspaceMenu: FC<WorkspaceMenuProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <OutsideClickHandler onOutsideClick={() => isOpen && setIsOpen(false)}>
      <div tw="relative">
        <div
          tw="max-w-56 flex flex-row items-center hover:cursor-pointer select-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Gravatar
            size="2rem"
            tw="min-w-8 text-xs"
            email={authentication.selected_workspace.billing_email}
          />
          <div tw="flex flex-col flex-grow ml-2 overflow-hidden">
            <Text
              as="p"
              css={[
                'white-space: nowrap;',
                'text-overflow: ellipsis;',
                tw`font-medium text-xs text-charcoal leading-tight overflow-hidden`,
              ]}
            >
              {authentication.selected_workspace.name}
            </Text>
            <div tw="flex flex-row items-center justify-between text-dark-blue-gray">
              <Text as="p" tw="font-normal text-xxs leading-tight">
                {`${authentication.user?.name?.first} ${authentication.user?.name?.last}`}
              </Text>
              <Icon
                icon="chevron-down"
                css={[
                  tw`ml-2 text-base transition transform duration-200 rotate-0`,
                  isOpen && tw`rotate-180`,
                ]}
              />
            </div>
          </div>
        </div>
        <div
          css={[
            !isOpen && tw`hidden`,
            tw`w-96 pt-4 absolute top-full right-0 select-none`,
            'z-index: 100;',
          ]}
        >
          <div tw="bg-white border border-platinum rounded w-full">
            <ul css={['max-height: 15rem;', 'overflow-y: scroll;']}>
              {authentication.workspaces.map((workspace) => (
                <li key={workspace._id}>
                  <WorkspaceItem workspace={workspace} />
                </li>
              ))}
              <li>
                <WorkspaceItem />
              </li>
            </ul>
            <hr tw="bg-platinum" />
            <ul tw="py-2">
              {actions?.map((action, index) => (
                <li key={`action#${index}`}>
                  <Link
                    to={action.linkTo || '#'}
                    onClick={action.onClick}
                    css={[
                      tw`px-6 py-3 block text-charcoal hover:text-spanish-violet focus:text-spanish-violet bg-transparent hover:bg-cultured`,
                      action.isActive && tw`text-spanish-violet`,
                    ]}
                  >
                    {action.label}
                    {action.extra}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default WorkspaceMenu

interface WorkspaceItemProps {
  workspace?: WhoAmIUserWorkspaceResult
}

const WorkspaceItem: FC<WorkspaceItemProps> = ({ workspace }) => {
  const navigate = useNavigate()

  const {
    data: workspaceData,
    loading: loadingWorkspace,
  } = useViewWorkspaceQuery({
    skip: !workspace?._id,
    variables: { _id: workspace?._id },
  })

  const onSelectWorkspace = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    workspace: WhoAmIUserWorkspaceResult
  ) => {
    e.preventDefault()
    e.stopPropagation()
    await authentication.selectWorkspace(workspace._id)
    navigate(`/workspaces/${workspace._id}/overview`)
  }

  return (
    <Link
      to={
        !!workspace
          ? `/workspaces/${workspace._id}/overview`
          : '/workspaces/create'
      }
      tw="block py-4 px-6 flex flex-row items-center justify-between bg-transparent hover:bg-cultured select-none"
      onClick={(e) => !!workspace && onSelectWorkspace(e, workspace)}
    >
      <Gravatar
        size="3rem"
        add={!workspace}
        email={workspace?.billing_email}
        src={!workspace ? undefined : workspace?.billing_email}
        initials={getInitials(workspace?.name || '')}
      />
      <div tw="flex flex-col flex-grow ml-2">
        <Text
          as="p"
          css={[
            'white-space: nowrap;',
            'text-overflow: ellipsis;',
            tw`font-medium text-charcoal leading-tight mb-1 overflow-hidden`,
          ]}
        >
          {!!workspace ? workspace.name : 'Create or Join Workspace'}
        </Text>
        {!!workspace && (
          <Text as="p" tw="font-normal text-xs leading-tight">
            {loadingWorkspace
              ? 'Loading members...'
              : `${
                  workspaceData?.workspace?.members?.filter(
                    (member) => member?.removed?.at === null
                  ).length ?? 0
                } Member(s)`}
          </Text>
        )}
      </div>
      {workspace?._id === authentication.selected_workspace._id && (
        <Icon icon="check" tw="text-dark-blue-gray transform scale-125" />
      )}
    </Link>
  )
}
