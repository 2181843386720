import { useEffect, useMemo, useState } from 'react'

export interface Cover {
  photoUrl: string
  photoLink: string
  authorName: string
  authorLink: string
  locationName: string
}

export const covers: Cover[] = [
  {
    photoUrl: require('./TI-4WfrXnJ8.jpg'),
    photoLink: 'https://unsplash.com/photos/TI-4WfrXnJ8',
    authorName: 'Sung Shin',
    authorLink: 'https://unsplash.com/@ironstagram',
    locationName: 'Piccadilly Circus, London, UK',
  },
  {
    photoUrl: require('./LEikIOMSxfs.jpg'),
    photoLink: 'https://unsplash.com/photos/LEikIOMSxfs',
    authorName: 'Andre Benz',
    authorLink: 'https://unsplash.com/@trapnation',
    locationName: 'New York, USA',
  },
  {
    photoUrl: require('./X_roZ7toBJY.jpg'),
    photoLink: 'https://unsplash.com/photos/X_roZ7toBJY',
    authorName: 'Joshua Earle',
    authorLink: 'https://unsplash.com/@joshuaearle',
    locationName: 'Times Square, New York, USA',
  },
  {
    photoUrl: require('./Cc4sToR2Oc0.jpg'),
    photoLink: 'https://unsplash.com/photos/Cc4sToR2Oc0',
    authorName: 'Joe Yates',
    authorLink: 'https://unsplash.com/@josephyates_',
    locationName: 'Theater District, New York, USA',
  },
  {
    photoUrl: require('./x7hbiQr9XDM.jpg'),
    photoLink: 'https://unsplash.com/photos/x7hbiQr9XDM',
    authorName: 'Carson Materson',
    authorLink: 'https://unsplash.com/@sonson',
    locationName: 'New York, USA',
  },
  {
    photoUrl: require('./jy41u6_nBmk.jpg'),
    photoLink: 'https://unsplash.com/photos/jy41u6_nBmk',
    authorName: 'Ahmad Shahzad',
    authorLink: 'https://unsplash.com/@iahmadshahzad07',
    locationName: 'Punjab, Pakistan',
  },
  {
    photoUrl: require('./UmaojK7erQo.jpg'),
    photoLink: 'https://unsplash.com/photos/UmaojK7erQo',
    authorName: 'Sigmund',
    authorLink: 'https://unsplash.com/@sigmund',
    locationName: 'Unknown Location',
  },
  {
    photoUrl: require('./3RnkZpDqsEI.jpg'),
    photoLink: 'https://unsplash.com/photos/3RnkZpDqsEI',
    authorName: 'Geert Pieters',
    authorLink: 'https://unsplash.com/@shotsbywolf',
    locationName: 'Belgium',
  },
  {
    photoUrl: require('./j14sr5KkTJ0.jpg'),
    photoLink: 'https://unsplash.com/photos/j14sr5KkTJ0',
    authorName: 'Christian Mendoza',
    authorLink: 'https://unsplash.com/@buningmendoza',
    locationName: 'Toronto, Ontario, Canada',
  },
  {
    photoUrl: require('./Wbkl9Rgv3OI.jpg'),
    photoLink: 'https://unsplash.com/photos/Wbkl9Rgv3OI',
    authorName: 'Andre Tan',
    authorLink: 'https://unsplash.com/@andredantan19',
    locationName: 'Miami, FL',
  },
  {
    photoUrl: require('./3k5cAmxjXl4.jpg'),
    photoLink: 'https://unsplash.com/photos/3k5cAmxjXl4',
    authorName: 'Nigel Tadyanehondo',
    authorLink: 'https://unsplash.com/@nxvision',
    locationName: 'London, United Kingdom',
  },
  {
    photoUrl: require('./1yLt0422fYQ.jpg'),
    photoLink: 'https://unsplash.com/photos/1yLt0422fYQ',
    authorName: 'dominik hofbauer',
    authorLink: 'https://unsplash.com/@dominikhofbauer',
    locationName: 'Traunstein',
  },
  {
    photoUrl: require('./gLU8GZpHtRA.jpg'),
    photoLink: 'https://unsplash.com/photos/gLU8GZpHtRA',
    authorName: 'Eleni Afiontzi',
    authorLink: 'https://unsplash.com/@eleniafiontzi',
    locationName: 'Piccadilly Circus, London, UK',
  },
  {
    photoUrl: require('./K64q3XQsZk4.jpg'),
    photoLink: 'https://unsplash.com/photos/K64q3XQsZk4',
    authorName: 'Yüksel Göz',
    authorLink: 'https://unsplash.com/@ygoez',
    locationName: 'New York, USA',
  },
]

export function getRandomCover() {
  const random = Math.floor(Math.random() * covers.length)
  return covers[random]
}

export function useCover(cover: Cover) {
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    const img = new Image(0, 0)

    img.src = cover.photoUrl
    img.onload = () => {
      setLoaded(true)
    }

    return () => {
      img.remove()
    }
  }, [cover])

  return { loaded }
}

export function useRandomCover() {
  const cover = useMemo(() => getRandomCover(), [])
  const { loaded } = useCover(cover)
  return { cover, loaded }
}
