import React, { FC } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import Icon from './icon'

interface Breadcrumb {
  label: string
  linkTo?: string
}

export interface BreadcrumbNavProps {
  crumbs: Breadcrumb[]
}

const BreadcrumbNav: FC<BreadcrumbNavProps> = ({ crumbs, ...props }) => (
  <ul
    tw="w-full flex flex-row items-center mb-2 font-medium text-xs text-dark-blue-gray"
    {...props}
  >
    {crumbs.map(({ label, linkTo }, index) => (
      <li key={`crumb#${index}`} tw="flex flex-row items-center">
        {!!linkTo ? (
          <Link
            to={linkTo}
            tw="transition duration-200 no-underline hover:text-purple hover:underline"
          >
            {label}
          </Link>
        ) : (
          <p>{label}</p>
        )}
        {index !== crumbs.length - 1 && (
          <Icon icon="chevron-right" tw="text-base" />
        )}
      </li>
    ))}
  </ul>
)

export default BreadcrumbNav
