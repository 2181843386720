import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../../../ui-blocks/icon'
import Container from '../../../../../ui-blocks/container'
import ButtonLink from '../../../../../ui-blocks/button-link'
import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

const CampaignsEmpty: FC = () => (
  <Container>
    <div tw="flex flex-col items-center">
      <Illustration name={IllustrationEnum.campaigns_empty_state} tw="mb-10" />
      <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
        Create your first campaign
      </h1>
      <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
        All set! You are ready to create a new fresh campaign.
      </p>
      <ButtonLink
        to="/campaigns/create"
        iconLeft={<Icon icon="add" tw="text-lg" />}
      >
        Add Campaign
      </ButtonLink>
    </div>
  </Container>
)

export default CampaignsEmpty
