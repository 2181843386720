import React, { FC } from 'react'
import tw from 'twin.macro'

import Text from '../../../../ui-blocks/text'
import ColoredSquare from '../../../../ui-blocks/colored-square'

export interface AdGroupTotalProps {
  title: string
  count: number
  color?: string
  isLast?: boolean
}

const AdGroupTotal: FC<AdGroupTotalProps> = ({
  title,
  color,
  count,
  isLast,
}) => (
  <div css={[tw`inline-block`, !isLast && tw`mr-4`]}>
    <div tw="flex flex-row items-center">
      {color && (
        <ColoredSquare size="0.5rem" color={color} tw="rounded-sm mr-1" />
      )}
      <Text as="p" preset="p1" weight="medium" tw="text-xs mr-1">
        {title}
      </Text>
      <Text as="p" preset="p2">
        {count}
      </Text>
    </div>
  </div>
)

export default AdGroupTotal
