import React, { FC, useMemo } from 'react'

import tw, { css } from 'twin.macro'
import { NavLink, useParams } from 'react-router-dom'

import Card from '../ui-blocks/card'
import Container from '../ui-blocks/container'

import MainLayout from './main'
import { authentication } from '../stores'
import { WhoAmIUserWorkspaceResult } from '../graphql/components'

function generateWorkspaceSettingsNavLinks(
  workspace?: WhoAmIUserWorkspaceResult
) {
  const links: { linkTo: string; label: string }[] = []

  if (!workspace) {
    return links
  }

  const BASE_LINK = `/workspaces/${workspace._id}/settings`

  links.push(
    { linkTo: `${BASE_LINK}/details`, label: 'Details' },
    { linkTo: `${BASE_LINK}/members`, label: 'Members' }
  )

  // Only show the Billing and Plans link to the Enterprise workspace type
  // if (workspace.platform_type === EnumWorkspacePlatform_Type.Enterprise) {
  //   links.push({
  //     linkTo: `${BASE_LINK}/billing`,
  //     label: 'Billing & Plans',
  //   })
  // }

  links.push({ linkTo: `${BASE_LINK}/webhooks`, label: 'Webhooks' })

  return links
}

const WorkspacePanelLayout: FC = ({ children }) => {
  const { workspace_id } = useParams()
  const workspace = authentication.workspaces.find(
    ({ _id }) => _id === workspace_id
  )

  const workspaceSettingsNavLinks = useMemo(
    () => generateWorkspaceSettingsNavLinks(workspace),
    [workspace]
  )

  return (
    <MainLayout>
      <Container>
        <Card
          css={[tw`grid text-charcoal`, 'grid-template-columns: 19rem auto;']}
        >
          <div
            css={css`
              ${tw`bg-lighter-blue p-8 flex flex-col rounded-tl rounded-bl space-y-4 text-base`}
              a.active {
                ${tw`text-purple`}
              }
            `}
          >
            <p tw="text-xs uppercase">Workspace Settings</p>
            {workspaceSettingsNavLinks.map((navLink, index) => (
              <NavLink
                to={navLink.linkTo}
                key={`workspace-panel-nav-link#${index}`}
              >
                {navLink.label}
              </NavLink>
            ))}
          </div>

          <div tw="p-8 flex flex-col flex-auto overflow-auto">{children}</div>
        </Card>
      </Container>
    </MainLayout>
  )
}

export default WorkspacePanelLayout
