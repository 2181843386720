import React, { FC, useState } from 'react'
import 'twin.macro'

import { useStripe } from '@stripe/react-stripe-js'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import { string, boolean, object } from 'yup'

import Button from '../../../../../ui-blocks/button'

import AlertMessage, {
  AlertVariant,
} from '../../../../../components/alert-component'

import PaymentMethodForm from './components/payment-method-form'
import BillingInformationForm from './components/billing-information-form'

import {
  useWorkspaceSetupIntentMutation,
  useWorkspaceSetDefaultPaymentMethodMutation,
} from '../../../../../graphql/components'

import { trimValues } from '../../../../../utils/data-manipulation'

export interface PaymentMethodFormikValues {
  cardName: string
  cardNumber: string
  expMonth: string
  expYear: string
  securityCode: string
  isPrimary: boolean
  company: string
  vatNumber: string
  address: string
  zipCode: string
  city: string
  country: string
}

const paymentMethodValidationSchema = object().shape({
  cardName: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Card name is required')
    .strict(true),
  cardNumber: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Card number is required')
    .strict(true),
  expMonth: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(2, 'Value must be exactly 2 digits long')
    .required('Expiration month is required')
    .strict(true),
  expYear: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(2, 'Value must be exactly 2 digits long')
    .required('Expiration year is required')
    .strict(true),
  securityCode: string()
    .trim('Value cannot have leading or trailing white spaces')
    .min(3, 'Value must be between 3 to 4 digits long')
    .max(4, 'Value must be between 3 to 4 digits long')
    .required('Security code is required')
    .strict(true),
  isPrimary: boolean().default(false),
  company: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Company is required')
    .strict(true),
  vatNumber: string()
    .trim('Value cannot have leading or trailing white spaces')
    .notRequired()
    .strict(true),
  address: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Address is required')
    .strict(true),
  zipCode: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('ZIP Code is required')
    .strict(true),
  city: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('City is required')
    .strict(true),
  country: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Country is required')
    .strict(true),
})

const WorkspaceSettingsPaymentMethodsPage: FC = () => {
  const navigate = useNavigate()

  const { workspace_id } = useParams()
  const [error, setError] = useState<string>('')

  const stripe = useStripe()
  const [setupIntent] = useWorkspaceSetupIntentMutation()
  const [
    setPrimaryPaymentMethod,
  ] = useWorkspaceSetDefaultPaymentMethodMutation()

  const formik = useFormik<PaymentMethodFormikValues>({
    validateOnChange: false,
    validationSchema: paymentMethodValidationSchema,
    initialValues: {
      cardName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      securityCode: '',
      isPrimary: false,
      company: '',
      vatNumber: '',
      address: '',
      zipCode: '',
      city: '',
      country: '',
    },
    async onSubmit(values) {
      const newValues = trimValues(values, [
        'cardName',
        'cardNumber',
        'expMonth',
        'expYear',
        'securityCode',
        'company',
        'vatNumber',
        'address',
        'zipCode',
        'city',
        'country',
      ])

      try {
        const res = await setupIntent({
          variables: {
            setupIntentArgs: {
              card: {
                card_number: newValues.cardNumber,
                exp_month: parseInt(newValues.expMonth),
                exp_year: parseInt(newValues.expYear),
                cvc: newValues.securityCode,
              },
              billing_details: {
                country: newValues.country,
                city: newValues.city,
                line1: newValues.address,
                postal_code: newValues.zipCode,
                name: newValues.cardName,
                company: newValues.company,
                vat: newValues.vatNumber,
              },
            },
          },
        })
        if (!res.data?.setupIntent || !!res.errors?.length) {
          return setError(
            'An error ocurred while setting up your card. Please try again later or a different card.'
          )
        }

        const cardSetup = await stripe?.confirmCardSetup(
          res.data.setupIntent.client_secret,
          { payment_method: res.data.setupIntent.payment_method }
        )
        if (!!cardSetup?.error) {
          return setError(
            'An error ocurred while setting up your card. Please try again later or a different card.'
          )
        }

        if (newValues.isPrimary) {
          await setPrimaryPaymentMethod({
            variables: { paymentMethodId: res.data.setupIntent.payment_method },
          })
        }
        navigate(`/workspaces/${workspace_id}/settings/billing`)
      } catch {
        return setError(
          'The card you provided was declined. Please try a different card.'
        )
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {error && (
        <div tw="mb-6">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              id: 'payment-method-failure-alert',
              message: error,
            }}
          />
        </div>
      )}
      <h1 tw="font-medium text-xl leading-tight mb-8">
        <Link
          to={`/workspaces/${workspace_id}/settings/billing`}
          tw="text-purple"
        >
          Billing & Plans
        </Link>
        <span tw="text-charcoal">{' / Payment Methods'}</span>
      </h1>
      <h2 tw="font-medium text-charcoal leading-tight mb-6">Payment details</h2>
      <PaymentMethodForm formik={formik} />
      <hr tw="bg-platinum my-6" />
      <h2 tw="font-medium text-charcoal leading-tight mb-6">
        Additional billing information
      </h2>
      <BillingInformationForm formik={formik} />
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Save
      </Button>
    </form>
  )
}

export default WorkspaceSettingsPaymentMethodsPage
