import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from '../../../../../../ui-blocks/text'
import Spinner from '../../../../../../ui-blocks/spinner'
import Suspense from '../../../../../../ui-blocks/suspense'
import ModalAction from '../../../../../../ui-blocks/modal-action'

import { useListCampaignsByRevokedNetworkQuery } from '../../../../../../graphql/components'

export interface RevokeAccessModalProps {
  open?: boolean
  loading?: boolean
  networkId: string
  workspaceId: string
  onCancel: () => void
  onConfirm: () => void
}

const RevokeAccessModal: FC<RevokeAccessModalProps> = ({
  open,
  loading,
  networkId,
  workspaceId,
  onCancel,
  onConfirm,
}) => {
  const {
    data: campaignsData,
    loading: loadingCampaigns,
  } = useListCampaignsByRevokedNetworkQuery({
    skip: !networkId || !workspaceId,
    variables: { network_id: networkId, workspace_id: workspaceId },
  })

  const campaigns = campaignsData?.campaigns || []
  return (
    <ModalAction
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Revoke Access"
      confirmButtonText="Revoke Access"
      width="26rem"
    >
      <Suspense ready={!loadingCampaigns} fallback={<Spinner center />}>
        <Text as="p" preset="h5" tw="mb-6">
          By revoking access this workspace will no longer have this network
          available as a broadcast target.
        </Text>
        <Suspense
          ready={!!campaigns.length}
          fallback={
            <Text as="p" preset="h5" tw="mb-6">
              No campaigns will be affected!
            </Text>
          }
        >
          <Text as="p" preset="h5" tw="mb-6">
            The following active campaigns will pause:
          </Text>
          <ul
            css={css`
              ${tw`ml-6 mb-10 list-disc`}
              & > li::marker {
                ${tw`text-dark-blue-gray`}
              }
            `}
          >
            {campaigns.map((campaign, index) => (
              <li key={`campaign#${index}`} tw="mb-2">
                <Text as="span" preset="h5" weight="bold" tw="break-words">
                  {campaign}
                </Text>
              </li>
            ))}
          </ul>
        </Suspense>
      </Suspense>
    </ModalAction>
  )
}

export default RevokeAccessModal
