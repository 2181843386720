import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'

import Text from '../../../ui-blocks/text'
import Switch from '../../../ui-blocks/switch'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { Tabs, TabPane } from '../../../ui-blocks/tabs'

import OverviewSection from './components/overview-section'
import SettingsSection from './components/settings/section'

import NotFound from '../../../components/not-found'
import LoadingPage from '../../../components/loading-page'

import {
  EnumPlayerStatus,
  useViewPlayerQuery,
  useUpdatePlayerActivationMutation,
} from '../../../graphql/components'

import { getKPIIntervals } from '../../../utils/stats-intervals'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export enum PlayerPageTabKeys {
  OVERVIEW = 'overview',
  SETTINGS = 'settings',
}

export const playerPageRoutes = {
  [PlayerPageTabKeys.OVERVIEW]: '',
  [PlayerPageTabKeys.SETTINGS]: 'settings',
}

const ViewPlayerPage: FC = () => {
  const navigate = useNavigate()
  const { player_id } = useParams()

  const [updatePlayerActivation] = useUpdatePlayerActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: playerData,
    loading: loadingPlayer,
    refetch: refetchPlayer,
  } = useViewPlayerQuery({
    skip: !player_id,
    variables: {
      _id: player_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a player's page if it is archived
  const { player } = playerData || {}
  useEffect(() => {
    if (player?.status === EnumPlayerStatus.Archived)
      navigate('/players', { replace: true })
  }, [player])

  const handleUpdateError = (action: 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-player-status-error-alert',
      message: `We couldn't ${action} this player. Please try again later.`,
    })
  }

  const onUpdatePlayerActivation = async (activate: boolean) => {
    try {
      const res = await updatePlayerActivation({
        variables: { ids: [player_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-players'))
      await refetchPlayer()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/players/${player?._id}/${playerPageRoutes[tabKey as PlayerPageTabKeys]}`
    )
  }

  const isSwitchDisabled = useMemo(
    () =>
      player?.status === EnumPlayerStatus.Binded ||
      player?.status === EnumPlayerStatus.Registered ||
      player?.status === EnumPlayerStatus.Archived,
    [player]
  )

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${PlayerPageTabKeys.SETTINGS}`) &&
        PlayerPageTabKeys.SETTINGS) ||
      PlayerPageTabKeys.OVERVIEW,
    [location]
  )

  return (
    <Suspense ready={!loadingPlayer} fallback={<LoadingPage />}>
      <Suspense ready={!!player} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              { label: 'Players', linkTo: '/players' },
              { label: player?.name || '' },
            ]}
          />
          <div tw="flex flex-row items-center mb-6">
            <Switch
              disabled={isSwitchDisabled}
              checked={player?.status === EnumPlayerStatus.Activated}
              onSelect={onUpdatePlayerActivation}
              tw="-ml-2 mr-2"
            />
            <Text as="h1" preset="h3" tw="flex-1">
              {player?.name}
            </Text>
          </div>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane key={PlayerPageTabKeys.OVERVIEW} tab="Overview" tw="mt-10">
              <OverviewSection player={player || undefined} />
            </TabPane>
            <TabPane key={PlayerPageTabKeys.SETTINGS} tab="Settings" tw="mt-10">
              <SettingsSection
                player={player || undefined}
                refetchPlayer={refetchPlayer}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewPlayerPage
