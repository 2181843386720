/**
 * Wraps any promise in a try/catch statement to simplify
 * and make your code more readable as it removes the repetitive
 * try/catch statements in the codebase.
 * @param promise the promise that you wish to await for
 * @returns `[data, error]` an array of two elements where the first is always
 * the data and the second is always the error. If successful,
 * the error will always be `null`, otherwise the data will always
 * be `null`
 */
export const tryCatch = async <T>(
  promise: Promise<T>
): Promise<[T | null, {} | null]> => {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    console.error(error)
    return [null, error]
  }
}
