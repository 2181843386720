import 'react-map-gl'
import hexToRgb from 'hex-rgb'

import { ViewType } from '..'

export const MAX_ZOOM_LEVEL = 9

export const generateHeatmapPaint = (
  viewType: ViewType,
  hexColor: string,
  maxValue: number
): mapboxgl.HeatmapPaint => {
  const { red, green, blue } = hexToRgb(hexColor)
  const rgbColor = [red, green, blue].join(',')
  return {
    // Increase the heatmap weight based on frequency and property impressions
    'heatmap-weight': {
      property: viewType,
      type: 'exponential',
      stops: [
        [1, 0],
        [maxValue, 1],
      ],
    },

    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': {
      stops: [
        [5, 1],
        [MAX_ZOOM_LEVEL, 3],
      ],
    },

    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      `rgba(${rgbColor},0)`,
      1,
      `rgba(${rgbColor},1)`,
    ],

    // Adjust the heatmap radius by zoom level
    'heatmap-radius': {
      stops: [
        [5, MAX_ZOOM_LEVEL],
        [MAX_ZOOM_LEVEL, 20],
      ],
    },

    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': {
      default: 1,
      stops: [
        [MAX_ZOOM_LEVEL - 1, 1],
        [MAX_ZOOM_LEVEL, 0],
      ],
    },
  }
}

export const generateCirclePaint = (
  viewType: ViewType,
  hexColor: string,
  maxValue: number
): mapboxgl.CirclePaint => ({
  // Size circle radius by view type and zoom level
  'circle-radius': {
    property: viewType,
    type: 'exponential',
    stops: [
      [{ zoom: MAX_ZOOM_LEVEL, value: 1 }, 5],
      [{ zoom: MAX_ZOOM_LEVEL, value: maxValue }, 10],
      [{ zoom: MAX_ZOOM_LEVEL + 7, value: 1 }, 20],
      [{ zoom: MAX_ZOOM_LEVEL + 7, value: maxValue }, 50],
    ],
  },

  // Color circle by earthquake magnitude
  'circle-color': hexColor,
  'circle-stroke-color': 'white',
  'circle-stroke-width': 1,

  // Transition from heatmap to circle layer by zoom level
  'circle-opacity': {
    stops: [
      [MAX_ZOOM_LEVEL - 1, 0],
      [MAX_ZOOM_LEVEL, 1],
    ],
  },
})
