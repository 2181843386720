import React, { FC, useMemo } from 'react'
import 'twin.macro'

import Search from '../../../../ui-blocks/search'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import CampaignAdGroupsListTable from '../../../../components/campaign-ad-group/table'

import { CreativeFullFragment } from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'
import { regexpFromString } from '../../../../utils/regexp-from-string'

export interface AdGroupsSectionProps {
  loading?: boolean
  creative?: CreativeFullFragment
}

const AdGroupsSection: FC<AdGroupsSectionProps> = ({ loading, creative }) => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const adGroups = useMemo(() => creative?.ad_groups || [], [creative])
  const filteredAdGroups = useMemo(
    () =>
      adGroups.filter((adGroup) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(adGroup.name)
          : adGroup
      ),
    [adGroups, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredAdGroups.length,
    15,
    searchTextDebounced
  )

  return (
    <section>
      <div tw="flex flex-row items-center justify-end mb-4">
        <div tw="w-80">
          <Search
            loading={loading}
            value={searchText}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <CampaignAdGroupsListTable
        interactable
        loading={loading}
        data={filteredAdGroups}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default AdGroupsSection
