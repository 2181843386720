import React, { FC, Fragment } from 'react'
import tw, { css } from 'twin.macro'

import Icon from '../ui-blocks/icon'
import Link from '../ui-blocks/link'

import { useRandomCover } from '../assets/covers'

import { useCrisp } from '../crisp'

const Cover: FC = () => {
  const { loaded, cover } = useRandomCover()
  const { isActive: isCrispActive, toggle: toggleCrisp } = useCrisp()

  return (
    <Fragment>
      <div
        css={css`
          z-index: 1;
          opacity: ${loaded ? 0.7 : 0};
          transition: opacity 1s linear;
          ${loaded && `background-image: url(${cover.photoUrl})`};
          ${tw`min-h-screen absolute inset-0 bg-cover bg-center`}
        `}
      />
      <div
        css={[
          'z-index: 2;',
          tw`flex flex-col justify-end items-end absolute right-4 bottom-4`,
        ]}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          tw="flex flex-row items-center text-platinum space-x-1"
          href={cover.authorLink}
        >
          <span>{cover.authorName}</span>
          <Icon icon="user" tw="text-lg" />
        </a>
        <span tw="flex flex-row items-center text-light-peri space-x-1">
          <span>{cover.locationName}</span>
          <Icon icon="location" tw="text-lg" />
        </span>
      </div>
      {isCrispActive && (
        <div
          css={[
            'z-index: 2;',
            tw`flex flex-col justify-end items-end absolute right-4 top-4`,
          ]}
        >
          <Link
            tw="flex flex-row items-center text-platinum space-x-1"
            onClick={toggleCrisp}
          >
            <span>Need Help?</span>
            <Icon icon="circle-help" tw="text-lg" />
          </Link>
        </div>
      )}
    </Fragment>
  )
}

export default React.memo(Cover)
