import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import useElementStates from '../../../../utils/use-element-states'

const AlertSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const {
    states,
    values: { withActionText, ...values },
  } = useElementStates(['dismissable', 'withActionText'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="space-y-4">
        <AlertMessage
          alert={{
            id: 'alert-message',
            message: 'Alert message.',
            variant: AlertVariant.SUCCESS,
            actionText: withActionText ? 'Action text!' : undefined,
            ...values,
          }}
        />
        <AlertMessage
          alert={{
            id: 'alert-message',
            message: 'Alert message.',
            variant: AlertVariant.WARNING,
            actionText: withActionText ? 'Action text!' : undefined,
            ...values,
          }}
        />
        <AlertMessage
          alert={{
            id: 'alert-message',
            message: 'Alert message.',
            variant: AlertVariant.ERROR,
            actionText: withActionText ? 'Action text!' : undefined,
            ...values,
          }}
        />
      </div>
    </ElementSection>
  )
}

export default AlertSection
