import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../components/alert-component'

import {
  NetworkFullFragment,
  useUpdateNetworkByIdMutation,
} from '../../../../../../graphql/components'

import { trimValues } from '../../../../../../utils/data-manipulation'

interface EditNetworkFormikValues {
  name: string
}

const editNetworkValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

export interface EditNetworkFormProps {
  network?: NetworkFullFragment
  refetchNetwork?: () => Promise<any>
}

const EditNetworkForm: FC<EditNetworkFormProps> = ({
  network,
  refetchNetwork,
}) => {
  const [updateNetworkError, setUpdateNetworkError] = useState<string>('')

  const [updateNetwork] = useUpdateNetworkByIdMutation()
  const formik = useFormik<EditNetworkFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editNetworkValidationSchema,
    initialValues: { name: network?.name || '' },
    async onSubmit(values) {
      setUpdateNetworkError('')
      try {
        const newValues = trimValues(values, ['name'])
        const res = await updateNetwork({
          variables: { _id: network?._id, input: newValues },
        })
        if ((res.errors || []).length > 0) {
          return setUpdateNetworkError(
            "We couldn't update this network. Please try again later."
          )
        }
        await refetchNetwork?.()
      } catch {
        setUpdateNetworkError(
          'Something went wrong while trying to update this network. Please try again later.'
        )
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {!!updateNetworkError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              message: updateNetworkError,
              id: 'update-network-error-alert',
            }}
          />
        </div>
      )}
      <div tw="max-w-xs mb-10">
        <InputFormik type="text" name="name" label="Name" formik={formik} />
      </div>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update network details
      </Button>
    </form>
  )
}

export default EditNetworkForm
