import React, { FC } from 'react'
import Text, { TextProps } from './text'
import 'twin.macro'

export interface PageHeaderProps {
  breadcrumbs?: string[]
  title?: string
  titlePreset?: TextProps['preset']
  description?: string
  // children: aligned to right
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  children,
  titlePreset = 'h2',
}) => {
  const titleAs = (['p1', 'p2'].indexOf(titlePreset) === -1
    ? titlePreset
    : 'p') as TextProps['as']
  return (
    <div tw="flex flex-row items-center justify-end mb-4 lg:mb-8 space-x-4 lg:space-x-8 w-full">
      {!!title && (
        <div tw="justify-self-start">
          <Text as={titleAs} preset={titlePreset}>
            {title}
          </Text>
          {!!description && (
            <span tw="justify-self-start text-charcoal text-xs tracking-wide">
              {description}
            </span>
          )}
        </div>
      )}

      <div tw="flex-grow">{/* spacer */}</div>

      {children}
    </div>
  )
}

export default PageHeader
