import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'

import Text from '../../../ui-blocks/text'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { Tabs, TabPane } from '../../../ui-blocks/tabs'

import OverviewSection from './components/overview-section'
import CampaignsSection from './components/campaigns-section'
import AdGroupsSection from './components/ad-groups-section'
import SettingsSection from './components/settings/section'

import NotFound from '../../../components/not-found'
import LoadingPage from '../../../components/loading-page'

import {
  EnumCreativeStatus,
  OnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscriptionVariables,
  useOnCreativesStatusChangeSubscription,
  useViewCreativeQuery,
} from '../../../graphql/components'

import { getKPIIntervals } from '../../../utils/stats-intervals'
import useCallBackOnSubscriptionChange, {
  IUseCallBackOnSubscriptionChange,
} from '../../../utils/use-callback-on-subscription-change'

export enum CreativePageTabKeys {
  OVERVIEW = 'overview',
  CAMPAIGNS = 'campaigns',
  AD_GROUPS = 'ad-groups',
  SETTINGS = 'settings',
}

export const creativePageRoutes = {
  [CreativePageTabKeys.OVERVIEW]: '',
  [CreativePageTabKeys.CAMPAIGNS]: 'campaigns',
  [CreativePageTabKeys.AD_GROUPS]: 'ad-groups',
  [CreativePageTabKeys.SETTINGS]: 'settings',
}

const ViewCreativePage: FC = () => {
  const navigate = useNavigate()
  const { creative_id } = useParams()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: creativeData,
    loading: loadingCreative,
    refetch: refetchCreative,
  } = useViewCreativeQuery({
    skip: !creative_id,
    fetchPolicy: 'cache-first',
    variables: {
      _id: creative_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a player's page if it is archived
  const { creative } = creativeData || {}
  useEffect(() => {
    if (creative?.status === EnumCreativeStatus.Archived)
      navigate('/creatives', { replace: true })
  }, [creative])

  const subscriptionListener: IUseCallBackOnSubscriptionChange<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  > = useMemo(() => {
    return {
      callback: refetchCreative,
      subscription: useOnCreativesStatusChangeSubscription,
      subscriptionOptions: {
        fetchPolicy: 'no-cache',
        skip: creative === undefined,
        variables: {
          _ids: [creative?._id || ''],
        },
      },
    }
  }, [creative, refetchCreative])

  useCallBackOnSubscriptionChange(subscriptionListener)

  const refetchCreativeListener = async () => await refetchCreative()
  useEffect(() => {
    document.addEventListener('refetch-campaigns', refetchCreativeListener)
    document.addEventListener(
      'refetch-campaign-ad-groups',
      refetchCreativeListener
    )
    return () => {
      document.removeEventListener('refetch-campaigns', refetchCreativeListener)
      document.removeEventListener(
        'refetch-campaign-ad-groups',
        refetchCreativeListener
      )
    }
  }, [])

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/creatives/${creative?._id}/${
        creativePageRoutes[tabKey as CreativePageTabKeys]
      }`
    )
  }

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${CreativePageTabKeys.CAMPAIGNS}`) &&
        CreativePageTabKeys.CAMPAIGNS) ||
      (location.pathname.endsWith(`/${CreativePageTabKeys.AD_GROUPS}`) &&
        CreativePageTabKeys.AD_GROUPS) ||
      (location.pathname.endsWith(`/${CreativePageTabKeys.SETTINGS}`) &&
        CreativePageTabKeys.SETTINGS) ||
      CreativePageTabKeys.OVERVIEW,
    [location]
  )

  return (
    <Suspense ready={!loadingCreative} fallback={<LoadingPage />}>
      <Suspense ready={!!creative} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              { label: 'Creatives', linkTo: '/creatives' },
              { label: creative?.name || '' },
            ]}
          />
          <Text as="h1" preset="h3" tw="mb-6">
            {creative?.name}
          </Text>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane
              key={CreativePageTabKeys.OVERVIEW}
              tab="Overview"
              tw="mt-10"
            >
              <OverviewSection creative={creative || undefined} />
            </TabPane>
            <TabPane
              key={CreativePageTabKeys.CAMPAIGNS}
              tab="Campaigns"
              tw="mt-10"
            >
              <CampaignsSection creative={creative || undefined} />
            </TabPane>
            <TabPane
              key={CreativePageTabKeys.AD_GROUPS}
              tab="Ad Groups"
              tw="mt-10"
            >
              <AdGroupsSection
                loading={loadingCreative}
                creative={creative || undefined}
              />
            </TabPane>
            <TabPane
              key={CreativePageTabKeys.SETTINGS}
              tab="Settings"
              tw="mt-10"
            >
              <SettingsSection
                creative={creative || undefined}
                refetchCreative={refetchCreative}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewCreativePage
