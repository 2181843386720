import React, { FC, DetailedHTMLProps, AnchorHTMLAttributes } from 'react'
import tw, { css, styled } from 'twin.macro'

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

export interface LinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  router?: RouterLinkProps
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
}

const Link: FC<LinkProps> = ({
  secondary,
  disabled,
  router,
  onClick,
  iconLeft,
  iconRight,
  ref,
  children,
  ...props
}) => {
  let FinalLink = PrimaryLink
  let FinalRouterLink = PrimaryRouterLink

  if (secondary) {
    FinalLink = SecondaryLink
    FinalRouterLink = SecondaryRouterLink
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!disabled) return onClick?.(e)
    e.preventDefault()
  }

  const renderContent = () => (
    <div tw="flex flex-row items-center justify-center space-x-1">
      {iconLeft}
      <div className="content">{children}</div>
      {iconRight}
    </div>
  )

  return !!router?.to ? (
    <FinalRouterLink
      to={router.to}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {renderContent()}
    </FinalRouterLink>
  ) : (
    <FinalLink ref={ref} disabled={disabled} onClick={handleClick} {...props}>
      {renderContent()}
    </FinalLink>
  )
}

const baseStyle = (disabled?: boolean) => css`
  min-width: 5rem;
  min-height: 2rem;
  padding: 0.3125rem 1rem;
  ${tw`border-none rounded transition-all ease-in-out duration-200`}
  ${!disabled
    ? tw`hover:cursor-pointer focus:(ring-2 ring-offset-2 ring-bright-blue outline-none)`
    : tw`hover:cursor-not-allowed`}
  & .content {
    ${disabled ? tw`no-underline` : tw`underline`}
  }
`

const primaryStyle = (disabled?: boolean) => css`
  ${tw`text-purple hover:text-dark-orchid active:text-spanish-violet`}
  ${disabled && tw`font-light text-dark-blue-gray hover:text-dark-blue-gray`}
`

const secondaryStyle = (disabled?: boolean) => css`
  ${tw`text-dark-blue-gray hover:text-dark-orchid active:text-spanish-violet`}
  ${disabled && tw`font-light text-dark-blue-gray hover:text-dark-blue-gray`}
`

const LinkBase = styled.a<LinkProps>`
  ${({ disabled }) => baseStyle(disabled)}
`
const RouterLinkBase = styled(RouterLink)<LinkProps>`
  ${({ disabled }) => baseStyle(disabled)}
`

const PrimaryLink = styled(LinkBase)`
  ${({ disabled }) => primaryStyle(disabled)}
`
const PrimaryRouterLink = styled(RouterLinkBase)`
  ${({ disabled }) => primaryStyle(disabled)}
`

const SecondaryLink = styled(LinkBase)`
  ${({ disabled }) => secondaryStyle(disabled)}
`
const SecondaryRouterLink = styled(RouterLinkBase)`
  ${({ disabled }) => secondaryStyle(disabled)}
`

Link.defaultProps = {
  primary: true,
}

export default Link
