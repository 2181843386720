import React, { FC, useMemo, useState } from 'react'

import Dialog from 'rc-dialog'
import tw, { css } from 'twin.macro'

import VideoWrapper from './components/video-wrapper'
import ImageWrapper from './components/image-wrapper'
import CreativeSelector from './components/creative-selector'

import {
  EnumUploadType,
  useViewCreativeQuery,
  CreativeListFragment,
  useOnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscriptionVariables,
} from '../../graphql/components'

import useKeyPress from '../../utils/use-key-press'
import useCallBackOnSubscriptionChange, {
  IUseCallBackOnSubscriptionChange,
} from '../../utils/use-callback-on-subscription-change'

export interface PreviewScreenProps {
  open?: boolean
  currentIndex: number
  creatives: CreativeListFragment[]
  onClose?: () => void
  onPrevious?: () => void
  onNext?: () => void
}

const PreviewScreen: FC<PreviewScreenProps> = ({
  open,
  currentIndex,
  creatives,
  onClose,
  onPrevious,
  onNext,
}) => {
  const [scale, setScale] = useState<number>(1)

  useKeyPress('+', () => open && zoomIn())
  useKeyPress('-', () => open && zoomOut())
  useKeyPress('ArrowRight', () => open && onNext?.())
  useKeyPress('ArrowLeft', () => open && onPrevious?.())

  const {
    data: currentCreativeData,
    loading: currentCreativeLoading,
    refetch: refetchCreative,
  } = useViewCreativeQuery({
    fetchPolicy: 'cache-first',
    variables: {
      _id: creatives[currentIndex]?._id || '',
    },
  })

  const currentCreative = useMemo(
    () => currentCreativeData?.creative || undefined,
    [currentCreativeData]
  )
  const upload = useMemo(() => {
    const versions = currentCreative?.upload?.versions || []
    return (
      versions.find((v) => v?.name === 'transcoded-h264') ||
      versions.find((v) => v?.name === 'original')
    )
  }, [currentCreative])

  const subscriptionListener = useMemo(
    () =>
      ({
        callback: refetchCreative,
        subscription: useOnCreativesStatusChangeSubscription,
        subscriptionOptions: {
          fetchPolicy: 'no-cache',
          skip: currentCreative === undefined,
          variables: {
            _ids: [currentCreative?._id || ''],
          },
        },
      } as IUseCallBackOnSubscriptionChange<
        OnCreativesStatusChangeSubscription,
        OnCreativesStatusChangeSubscriptionVariables
      >),
    [currentCreative, refetchCreative]
  )

  useCallBackOnSubscriptionChange(subscriptionListener)

  const zoomIn = () =>
    currentCreative?.upload?.type === EnumUploadType.Image &&
    setScale(scale + 0.1)
  const zoomOut = () =>
    currentCreative?.upload?.type === EnumUploadType.Image &&
    setScale(scale - 0.1)

  return (
    <Dialog
      visible={open}
      closable={false}
      animation="zoom"
      maskAnimation="fade"
      onClose={onClose}
      focusTriggerAfterClose={false}
      css={css`
        ${tw`m-0 w-full h-full max-w-full`}
        .rc-dialog-body {
          font-family: 'Fira Sans';
          ${tw`flex flex-col justify-center w-full h-full p-0 relative`}
        }
        .rc-dialog-content {
          ${tw`w-full h-full bg-black rounded-none`}
        }
        & .react-transform-wrapper,
        & .react-transform-component {
          ${tw`w-full h-full`}
        }
        & .react-transform-component {
          ${tw`z-0 items-center justify-center`}
        }
      `}
    >
      <CreativeSelector
        currentIndex={currentIndex}
        totalCreatives={creatives.length}
        creativeName={currentCreative?.name || ''}
        onDecrease={onPrevious}
        onIncrease={onNext}
        onClose={onClose}
      />
      {!currentCreativeLoading && (
        <>
          {open && currentCreative?.upload?.type === EnumUploadType.Image && (
            <ImageWrapper
              url={upload?.public_url ?? ''}
              creativeName={currentCreative.name}
            />
          )}
          {open && currentCreative?.upload?.type === EnumUploadType.Video && (
            <VideoWrapper url={upload?.public_url || undefined} />
          )}
        </>
      )}
    </Dialog>
  )
}

export default PreviewScreen
