/* eslint-disable */
import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any
  Date: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
}

export type _IdOperatorsFilterAreaTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyAreaTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookEventInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookEventResponseInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWorkspaceInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneWorkspaceInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterWorkspaceInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterWorkspaceInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type AccountUpdateDetailsInput = {
  name: AccountUpdateDetailsNameInput
}

export type AccountUpdateDetailsNameInput = {
  first: Scalars['String']
  last: Scalars['String']
}

export type ActionDateAndUser = {
  __typename?: 'ActionDateAndUser'
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
}

export type ActionDateAndUserInput = {
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
}

export type Address = {
  __typename?: 'Address'
  street?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  country_id?: Maybe<Scalars['MongoID']>
}

export type AddressInput = {
  street?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  country_id?: Maybe<Scalars['MongoID']>
}

export type Analytics = {
  __typename?: 'Analytics'
  _id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['Date']>
  locations?: Maybe<Array<Maybe<CoordinatesData>>>
}

export type AreaType = {
  __typename?: 'AreaType'
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type AuthAccess = {
  __typename?: 'AuthAccess'
  user_id: Scalars['String']
  client_id: Scalars['String']
  access_token: Scalars['String']
  refresh_token: Scalars['String']
}

export type AuthRefreshResult = {
  __typename?: 'AuthRefreshResult'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type BillingDetails = {
  __typename?: 'BillingDetails'
  address?: Maybe<BillingDetailsAddress>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type BillingDetailsAddress = {
  __typename?: 'BillingDetailsAddress'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type BroadcastTarget = {
  __typename?: 'BroadcastTarget'
  type: EnumBroadcastTargetType
  ref_id: Scalars['MongoID']
  created?: Maybe<ActionDateAndUser>
  submitted?: Maybe<ActionDateAndUser>
  approved?: Maybe<ActionDateAndUser>
  rejected?: Maybe<ActionDateAndUser>
  revoked?: Maybe<ActionDateAndUser>
  removed?: Maybe<Removed>
  review_notes?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  status?: Maybe<EnumBroadcastTargetStatus>
  submittedBy?: Maybe<Scalars['String']>
  player?: Maybe<Player>
  network?: Maybe<Network>
  externalNetwork?: Maybe<Network>
}

export type BroadcastTargetInput = {
  type: EnumBroadcastTargetType
  ref_id: Scalars['MongoID']
  created?: Maybe<ActionDateAndUserInput>
  submitted?: Maybe<ActionDateAndUserInput>
  approved?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  removed?: Maybe<RemovedInput>
  review_notes?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
}

export type Campaign = {
  __typename?: 'Campaign'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTarget>>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  timeframe?: Maybe<Timeframe>
  is_external?: Maybe<Scalars['Boolean']>
  ad_groups?: Maybe<Array<CampaignAdGroup>>
  status?: Maybe<EnumCampaignStatus>
}

export type CampaignGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CampaignAd_GroupsArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type Campaign_IdOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type Campaign_IdOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type Campaign_IdOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type Campaign_IdOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type CampaignAdGroup = {
  __typename?: 'CampaignAdGroup'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<Timetable>
  timeframe?: Maybe<Timeframe>
  created?: Maybe<ActionDateAndUser>
  audience?: Maybe<CampaignAdGroupAudience>
  archived?: Maybe<ActionDateAndUser>
  saturation?: Maybe<Scalars['Float']>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  campaign?: Maybe<Campaign>
  creatives?: Maybe<Array<Creative>>
  status?: Maybe<EnumCampaignAdGroupStatus>
}

export type CampaignAdGroupGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CampaignAdGroupCreativesArgs = {
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsCreativeInput>
}

export type CampaignAdGroupAudience = {
  __typename?: 'CampaignAdGroupAudience'
  area_type: EnumCampaignAdGroupAudienceArea_Type
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  geofences?: Maybe<Array<Maybe<Geofence>>>
}

export type CampaignAdGroupAudienceInput = {
  area_type: EnumCampaignAdGroupAudienceArea_Type
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  geofences?: Maybe<Array<Maybe<GeofenceInput>>>
}

export type Card = {
  __typename?: 'Card'
  brand?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  exp_month?: Maybe<Scalars['Int']>
  exp_year?: Maybe<Scalars['Int']>
  last4?: Maybe<Scalars['String']>
}

export type Category = {
  __typename?: 'Category'
  name: Scalars['String']
  _id: Scalars['MongoID']
}

export type CoordinatesData = {
  __typename?: 'CoordinatesData'
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>
  scans?: Maybe<Scalars['Int']>
  impressions?: Maybe<Scalars['Int']>
}

export type Count = {
  __typename?: 'Count'
  campaigns?: Maybe<Scalars['Int']>
  networks?: Maybe<Scalars['Int']>
  creatives?: Maybe<Scalars['Int']>
  players?: Maybe<Scalars['Int']>
}

export type Country = {
  __typename?: 'Country'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type CreateManyWebhookInput = {
  name: Scalars['String']
  url: Scalars['String']
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyWebhookPayload = {
  __typename?: 'CreateManyWebhookPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Webhook>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateOneCampaignAdGroupInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type CreateOneCampaignAdGroupPayload = {
  __typename?: 'CreateOneCampaignAdGroupPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<CampaignAdGroup>
}

export type CreateOneCampaignInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneCampaignPayload = {
  __typename?: 'CreateOneCampaignPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Campaign>
}

export type CreateOneNetworkInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  players: Array<Maybe<NetworkPlayersInput>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
}

export type CreateOneNetworkPayload = {
  __typename?: 'CreateOneNetworkPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Network>
}

export type CreateOneWebhookInput = {
  name: Scalars['String']
  url: Scalars['String']
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneWebhookPayload = {
  __typename?: 'CreateOneWebhookPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Webhook>
}

export type Creative = {
  __typename?: 'Creative'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  type: EnumCreativeType
  upload_id: Scalars['MongoID']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  ad_groups?: Maybe<Array<CampaignAdGroup>>
  upload?: Maybe<Upload>
  status?: Maybe<EnumCreativeStatus>
}

export type CreativeGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CreativeAd_GroupsArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type Currency = {
  __typename?: 'Currency'
  name: Scalars['String']
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id: Scalars['MongoID']
}

export type DistanceUnit = {
  __typename?: 'DistanceUnit'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id: Scalars['MongoID']
}

export enum EnumBroadcastTargetStatus {
  NotSubmitted = 'NOT_SUBMITTED',
  PendingApproval = 'PENDING_APPROVAL',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED',
}

export enum EnumBroadcastTargetType {
  Marketplace = 'marketplace',
  Network = 'network',
  Player = 'player',
  ExternalNetwork = 'external_network',
}

export enum EnumCampaignAdGroupAudienceArea_Type {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
  Both = 'BOTH',
}

export enum EnumCampaignAdGroupStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Waiting = 'WAITING',
  Finished = 'FINISHED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
}

export enum EnumCampaignStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Waiting = 'WAITING',
  Finished = 'FINISHED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
}

export enum EnumCreativeStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Archived = 'ARCHIVED',
  Processing = 'PROCESSING',
  Faulty = 'FAULTY',
}

export enum EnumCreativeType {
  Ad = 'AD',
  Content = 'CONTENT',
}

export enum EnumCurrencyPayment_Gateways {
  Credit = 'credit',
  Stripe = 'stripe',
  Ifthenpay = 'ifthenpay',
  Bitpay = 'bitpay',
  Paypal = 'paypal',
}

export enum EnumNetworkStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Archived = 'ARCHIVED',
}

export enum EnumPlayerSettingsOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  ReversedLandscape = 'REVERSED_LANDSCAPE',
  ReversedPortrait = 'REVERSED_PORTRAIT',
}

export enum EnumPlayerSettingsTimezone {
  AfricaAbidjan = 'Africa_Abidjan',
  AfricaAccra = 'Africa_Accra',
  AfricaAddisAbaba = 'Africa_Addis_Ababa',
  AfricaAlgiers = 'Africa_Algiers',
  AfricaAsmara = 'Africa_Asmara',
  AfricaAsmera = 'Africa_Asmera',
  AfricaBamako = 'Africa_Bamako',
  AfricaBangui = 'Africa_Bangui',
  AfricaBanjul = 'Africa_Banjul',
  AfricaBissau = 'Africa_Bissau',
  AfricaBlantyre = 'Africa_Blantyre',
  AfricaBrazzaville = 'Africa_Brazzaville',
  AfricaBujumbura = 'Africa_Bujumbura',
  AfricaCairo = 'Africa_Cairo',
  AfricaCasablanca = 'Africa_Casablanca',
  AfricaCeuta = 'Africa_Ceuta',
  AfricaConakry = 'Africa_Conakry',
  AfricaDakar = 'Africa_Dakar',
  AfricaDarEsSalaam = 'Africa_Dar_es_Salaam',
  AfricaDjibouti = 'Africa_Djibouti',
  AfricaDouala = 'Africa_Douala',
  AfricaElAaiun = 'Africa_El_Aaiun',
  AfricaFreetown = 'Africa_Freetown',
  AfricaGaborone = 'Africa_Gaborone',
  AfricaHarare = 'Africa_Harare',
  AfricaJohannesburg = 'Africa_Johannesburg',
  AfricaJuba = 'Africa_Juba',
  AfricaKampala = 'Africa_Kampala',
  AfricaKhartoum = 'Africa_Khartoum',
  AfricaKigali = 'Africa_Kigali',
  AfricaKinshasa = 'Africa_Kinshasa',
  AfricaLagos = 'Africa_Lagos',
  AfricaLibreville = 'Africa_Libreville',
  AfricaLome = 'Africa_Lome',
  AfricaLuanda = 'Africa_Luanda',
  AfricaLubumbashi = 'Africa_Lubumbashi',
  AfricaLusaka = 'Africa_Lusaka',
  AfricaMalabo = 'Africa_Malabo',
  AfricaMaputo = 'Africa_Maputo',
  AfricaMaseru = 'Africa_Maseru',
  AfricaMbabane = 'Africa_Mbabane',
  AfricaMogadishu = 'Africa_Mogadishu',
  AfricaMonrovia = 'Africa_Monrovia',
  AfricaNairobi = 'Africa_Nairobi',
  AfricaNdjamena = 'Africa_Ndjamena',
  AfricaNiamey = 'Africa_Niamey',
  AfricaNouakchott = 'Africa_Nouakchott',
  AfricaOuagadougou = 'Africa_Ouagadougou',
  AfricaPortoNovo = 'Africa_Porto_Novo',
  AfricaSaoTome = 'Africa_Sao_Tome',
  AfricaTimbuktu = 'Africa_Timbuktu',
  AfricaTripoli = 'Africa_Tripoli',
  AfricaTunis = 'Africa_Tunis',
  AfricaWindhoek = 'Africa_Windhoek',
  AmericaAdak = 'America_Adak',
  AmericaAnchorage = 'America_Anchorage',
  AmericaAnguilla = 'America_Anguilla',
  AmericaAntigua = 'America_Antigua',
  AmericaAraguaina = 'America_Araguaina',
  AmericaArgentinaBuenosAires = 'America_Argentina_Buenos_Aires',
  AmericaArgentinaCatamarca = 'America_Argentina_Catamarca',
  AmericaArgentinaComodRivadavia = 'America_Argentina_ComodRivadavia',
  AmericaArgentinaCordoba = 'America_Argentina_Cordoba',
  AmericaArgentinaJujuy = 'America_Argentina_Jujuy',
  AmericaArgentinaLaRioja = 'America_Argentina_La_Rioja',
  AmericaArgentinaMendoza = 'America_Argentina_Mendoza',
  AmericaArgentinaRioGallegos = 'America_Argentina_Rio_Gallegos',
  AmericaArgentinaSalta = 'America_Argentina_Salta',
  AmericaArgentinaSanJuan = 'America_Argentina_San_Juan',
  AmericaArgentinaSanLuis = 'America_Argentina_San_Luis',
  AmericaArgentinaTucuman = 'America_Argentina_Tucuman',
  AmericaArgentinaUshuaia = 'America_Argentina_Ushuaia',
  AmericaAruba = 'America_Aruba',
  AmericaAsuncion = 'America_Asuncion',
  AmericaAtikokan = 'America_Atikokan',
  AmericaAtka = 'America_Atka',
  AmericaBahia = 'America_Bahia',
  AmericaBahiaBanderas = 'America_Bahia_Banderas',
  AmericaBarbados = 'America_Barbados',
  AmericaBelem = 'America_Belem',
  AmericaBelize = 'America_Belize',
  AmericaBlancSablon = 'America_Blanc_Sablon',
  AmericaBoaVista = 'America_Boa_Vista',
  AmericaBogota = 'America_Bogota',
  AmericaBoise = 'America_Boise',
  AmericaBuenosAires = 'America_Buenos_Aires',
  AmericaCambridgeBay = 'America_Cambridge_Bay',
  AmericaCampoGrande = 'America_Campo_Grande',
  AmericaCancun = 'America_Cancun',
  AmericaCaracas = 'America_Caracas',
  AmericaCatamarca = 'America_Catamarca',
  AmericaCayenne = 'America_Cayenne',
  AmericaCayman = 'America_Cayman',
  AmericaChicago = 'America_Chicago',
  AmericaChihuahua = 'America_Chihuahua',
  AmericaCoralHarbour = 'America_Coral_Harbour',
  AmericaCordoba = 'America_Cordoba',
  AmericaCostaRica = 'America_Costa_Rica',
  AmericaCreston = 'America_Creston',
  AmericaCuiaba = 'America_Cuiaba',
  AmericaCuracao = 'America_Curacao',
  AmericaDanmarkshavn = 'America_Danmarkshavn',
  AmericaDawson = 'America_Dawson',
  AmericaDawsonCreek = 'America_Dawson_Creek',
  AmericaDenver = 'America_Denver',
  AmericaDetroit = 'America_Detroit',
  AmericaDominica = 'America_Dominica',
  AmericaEdmonton = 'America_Edmonton',
  AmericaEirunepe = 'America_Eirunepe',
  AmericaElSalvador = 'America_El_Salvador',
  AmericaEnsenada = 'America_Ensenada',
  AmericaFortNelson = 'America_Fort_Nelson',
  AmericaFortWayne = 'America_Fort_Wayne',
  AmericaFortaleza = 'America_Fortaleza',
  AmericaGlaceBay = 'America_Glace_Bay',
  AmericaGodthab = 'America_Godthab',
  AmericaGooseBay = 'America_Goose_Bay',
  AmericaGrandTurk = 'America_Grand_Turk',
  AmericaGrenada = 'America_Grenada',
  AmericaGuadeloupe = 'America_Guadeloupe',
  AmericaGuatemala = 'America_Guatemala',
  AmericaGuayaquil = 'America_Guayaquil',
  AmericaGuyana = 'America_Guyana',
  AmericaHalifax = 'America_Halifax',
  AmericaHavana = 'America_Havana',
  AmericaHermosillo = 'America_Hermosillo',
  AmericaIndianaIndianapolis = 'America_Indiana_Indianapolis',
  AmericaIndianaKnox = 'America_Indiana_Knox',
  AmericaIndianaMarengo = 'America_Indiana_Marengo',
  AmericaIndianaPetersburg = 'America_Indiana_Petersburg',
  AmericaIndianaTellCity = 'America_Indiana_Tell_City',
  AmericaIndianaVevay = 'America_Indiana_Vevay',
  AmericaIndianaVincennes = 'America_Indiana_Vincennes',
  AmericaIndianaWinamac = 'America_Indiana_Winamac',
  AmericaIndianapolis = 'America_Indianapolis',
  AmericaInuvik = 'America_Inuvik',
  AmericaIqaluit = 'America_Iqaluit',
  AmericaJamaica = 'America_Jamaica',
  AmericaJujuy = 'America_Jujuy',
  AmericaJuneau = 'America_Juneau',
  AmericaKentuckyLouisville = 'America_Kentucky_Louisville',
  AmericaKentuckyMonticello = 'America_Kentucky_Monticello',
  AmericaKnoxIn = 'America_Knox_IN',
  AmericaKralendijk = 'America_Kralendijk',
  AmericaLaPaz = 'America_La_Paz',
  AmericaLima = 'America_Lima',
  AmericaLosAngeles = 'America_Los_Angeles',
  AmericaLouisville = 'America_Louisville',
  AmericaLowerPrinces = 'America_Lower_Princes',
  AmericaMaceio = 'America_Maceio',
  AmericaManagua = 'America_Managua',
  AmericaManaus = 'America_Manaus',
  AmericaMarigot = 'America_Marigot',
  AmericaMartinique = 'America_Martinique',
  AmericaMatamoros = 'America_Matamoros',
  AmericaMazatlan = 'America_Mazatlan',
  AmericaMendoza = 'America_Mendoza',
  AmericaMenominee = 'America_Menominee',
  AmericaMerida = 'America_Merida',
  AmericaMetlakatla = 'America_Metlakatla',
  AmericaMexicoCity = 'America_Mexico_City',
  AmericaMiquelon = 'America_Miquelon',
  AmericaMoncton = 'America_Moncton',
  AmericaMonterrey = 'America_Monterrey',
  AmericaMontevideo = 'America_Montevideo',
  AmericaMontreal = 'America_Montreal',
  AmericaMontserrat = 'America_Montserrat',
  AmericaNassau = 'America_Nassau',
  AmericaNewYork = 'America_New_York',
  AmericaNipigon = 'America_Nipigon',
  AmericaNome = 'America_Nome',
  AmericaNoronha = 'America_Noronha',
  AmericaNorthDakotaBeulah = 'America_North_Dakota_Beulah',
  AmericaNorthDakotaCenter = 'America_North_Dakota_Center',
  AmericaNorthDakotaNewSalem = 'America_North_Dakota_New_Salem',
  AmericaNuuk = 'America_Nuuk',
  AmericaOjinaga = 'America_Ojinaga',
  AmericaPanama = 'America_Panama',
  AmericaPangnirtung = 'America_Pangnirtung',
  AmericaParamaribo = 'America_Paramaribo',
  AmericaPhoenix = 'America_Phoenix',
  AmericaPortAuPrince = 'America_Port_au_Prince',
  AmericaPortOfSpain = 'America_Port_of_Spain',
  AmericaPortoAcre = 'America_Porto_Acre',
  AmericaPortoVelho = 'America_Porto_Velho',
  AmericaPuertoRico = 'America_Puerto_Rico',
  AmericaPuntaArenas = 'America_Punta_Arenas',
  AmericaRainyRiver = 'America_Rainy_River',
  AmericaRankinInlet = 'America_Rankin_Inlet',
  AmericaRecife = 'America_Recife',
  AmericaRegina = 'America_Regina',
  AmericaResolute = 'America_Resolute',
  AmericaRioBranco = 'America_Rio_Branco',
  AmericaRosario = 'America_Rosario',
  AmericaSantaIsabel = 'America_Santa_Isabel',
  AmericaSantarem = 'America_Santarem',
  AmericaSantiago = 'America_Santiago',
  AmericaSantoDomingo = 'America_Santo_Domingo',
  AmericaSaoPaulo = 'America_Sao_Paulo',
  AmericaScoresbysund = 'America_Scoresbysund',
  AmericaShiprock = 'America_Shiprock',
  AmericaSitka = 'America_Sitka',
  AmericaStBarthelemy = 'America_St_Barthelemy',
  AmericaStJohns = 'America_St_Johns',
  AmericaStKitts = 'America_St_Kitts',
  AmericaStLucia = 'America_St_Lucia',
  AmericaStThomas = 'America_St_Thomas',
  AmericaStVincent = 'America_St_Vincent',
  AmericaSwiftCurrent = 'America_Swift_Current',
  AmericaTegucigalpa = 'America_Tegucigalpa',
  AmericaThule = 'America_Thule',
  AmericaThunderBay = 'America_Thunder_Bay',
  AmericaTijuana = 'America_Tijuana',
  AmericaToronto = 'America_Toronto',
  AmericaTortola = 'America_Tortola',
  AmericaVancouver = 'America_Vancouver',
  AmericaVirgin = 'America_Virgin',
  AmericaWhitehorse = 'America_Whitehorse',
  AmericaWinnipeg = 'America_Winnipeg',
  AmericaYakutat = 'America_Yakutat',
  AmericaYellowknife = 'America_Yellowknife',
  AntarcticaCasey = 'Antarctica_Casey',
  AntarcticaDavis = 'Antarctica_Davis',
  AntarcticaDumontDUrville = 'Antarctica_DumontDUrville',
  AntarcticaMacquarie = 'Antarctica_Macquarie',
  AntarcticaMawson = 'Antarctica_Mawson',
  AntarcticaMcMurdo = 'Antarctica_McMurdo',
  AntarcticaPalmer = 'Antarctica_Palmer',
  AntarcticaRothera = 'Antarctica_Rothera',
  AntarcticaSouthPole = 'Antarctica_South_Pole',
  AntarcticaSyowa = 'Antarctica_Syowa',
  AntarcticaTroll = 'Antarctica_Troll',
  AntarcticaVostok = 'Antarctica_Vostok',
  ArcticLongyearbyen = 'Arctic_Longyearbyen',
  AsiaAden = 'Asia_Aden',
  AsiaAlmaty = 'Asia_Almaty',
  AsiaAmman = 'Asia_Amman',
  AsiaAnadyr = 'Asia_Anadyr',
  AsiaAqtau = 'Asia_Aqtau',
  AsiaAqtobe = 'Asia_Aqtobe',
  AsiaAshgabat = 'Asia_Ashgabat',
  AsiaAshkhabad = 'Asia_Ashkhabad',
  AsiaAtyrau = 'Asia_Atyrau',
  AsiaBaghdad = 'Asia_Baghdad',
  AsiaBahrain = 'Asia_Bahrain',
  AsiaBaku = 'Asia_Baku',
  AsiaBangkok = 'Asia_Bangkok',
  AsiaBarnaul = 'Asia_Barnaul',
  AsiaBeirut = 'Asia_Beirut',
  AsiaBishkek = 'Asia_Bishkek',
  AsiaBrunei = 'Asia_Brunei',
  AsiaCalcutta = 'Asia_Calcutta',
  AsiaChita = 'Asia_Chita',
  AsiaChoibalsan = 'Asia_Choibalsan',
  AsiaChongqing = 'Asia_Chongqing',
  AsiaChungking = 'Asia_Chungking',
  AsiaColombo = 'Asia_Colombo',
  AsiaDacca = 'Asia_Dacca',
  AsiaDamascus = 'Asia_Damascus',
  AsiaDhaka = 'Asia_Dhaka',
  AsiaDili = 'Asia_Dili',
  AsiaDubai = 'Asia_Dubai',
  AsiaDushanbe = 'Asia_Dushanbe',
  AsiaFamagusta = 'Asia_Famagusta',
  AsiaGaza = 'Asia_Gaza',
  AsiaHarbin = 'Asia_Harbin',
  AsiaHebron = 'Asia_Hebron',
  AsiaHoChiMinh = 'Asia_Ho_Chi_Minh',
  AsiaHongKong = 'Asia_Hong_Kong',
  AsiaHovd = 'Asia_Hovd',
  AsiaIrkutsk = 'Asia_Irkutsk',
  AsiaIstanbul = 'Asia_Istanbul',
  AsiaJakarta = 'Asia_Jakarta',
  AsiaJayapura = 'Asia_Jayapura',
  AsiaJerusalem = 'Asia_Jerusalem',
  AsiaKabul = 'Asia_Kabul',
  AsiaKamchatka = 'Asia_Kamchatka',
  AsiaKarachi = 'Asia_Karachi',
  AsiaKashgar = 'Asia_Kashgar',
  AsiaKathmandu = 'Asia_Kathmandu',
  AsiaKatmandu = 'Asia_Katmandu',
  AsiaKhandyga = 'Asia_Khandyga',
  AsiaKolkata = 'Asia_Kolkata',
  AsiaKrasnoyarsk = 'Asia_Krasnoyarsk',
  AsiaKualaLumpur = 'Asia_Kuala_Lumpur',
  AsiaKuching = 'Asia_Kuching',
  AsiaKuwait = 'Asia_Kuwait',
  AsiaMacao = 'Asia_Macao',
  AsiaMacau = 'Asia_Macau',
  AsiaMagadan = 'Asia_Magadan',
  AsiaMakassar = 'Asia_Makassar',
  AsiaManila = 'Asia_Manila',
  AsiaMuscat = 'Asia_Muscat',
  AsiaNicosia = 'Asia_Nicosia',
  AsiaNovokuznetsk = 'Asia_Novokuznetsk',
  AsiaNovosibirsk = 'Asia_Novosibirsk',
  AsiaOmsk = 'Asia_Omsk',
  AsiaOral = 'Asia_Oral',
  AsiaPhnomPenh = 'Asia_Phnom_Penh',
  AsiaPontianak = 'Asia_Pontianak',
  AsiaPyongyang = 'Asia_Pyongyang',
  AsiaQatar = 'Asia_Qatar',
  AsiaQostanay = 'Asia_Qostanay',
  AsiaQyzylorda = 'Asia_Qyzylorda',
  AsiaRangoon = 'Asia_Rangoon',
  AsiaRiyadh = 'Asia_Riyadh',
  AsiaSaigon = 'Asia_Saigon',
  AsiaSakhalin = 'Asia_Sakhalin',
  AsiaSamarkand = 'Asia_Samarkand',
  AsiaSeoul = 'Asia_Seoul',
  AsiaShanghai = 'Asia_Shanghai',
  AsiaSingapore = 'Asia_Singapore',
  AsiaSrednekolymsk = 'Asia_Srednekolymsk',
  AsiaTaipei = 'Asia_Taipei',
  AsiaTashkent = 'Asia_Tashkent',
  AsiaTbilisi = 'Asia_Tbilisi',
  AsiaTehran = 'Asia_Tehran',
  AsiaTelAviv = 'Asia_Tel_Aviv',
  AsiaThimbu = 'Asia_Thimbu',
  AsiaThimphu = 'Asia_Thimphu',
  AsiaTokyo = 'Asia_Tokyo',
  AsiaTomsk = 'Asia_Tomsk',
  AsiaUjungPandang = 'Asia_Ujung_Pandang',
  AsiaUlaanbaatar = 'Asia_Ulaanbaatar',
  AsiaUlanBator = 'Asia_Ulan_Bator',
  AsiaUrumqi = 'Asia_Urumqi',
  AsiaUstNera = 'Asia_Ust_Nera',
  AsiaVientiane = 'Asia_Vientiane',
  AsiaVladivostok = 'Asia_Vladivostok',
  AsiaYakutsk = 'Asia_Yakutsk',
  AsiaYangon = 'Asia_Yangon',
  AsiaYekaterinburg = 'Asia_Yekaterinburg',
  AsiaYerevan = 'Asia_Yerevan',
  AtlanticAzores = 'Atlantic_Azores',
  AtlanticBermuda = 'Atlantic_Bermuda',
  AtlanticCanary = 'Atlantic_Canary',
  AtlanticCapeVerde = 'Atlantic_Cape_Verde',
  AtlanticFaeroe = 'Atlantic_Faeroe',
  AtlanticFaroe = 'Atlantic_Faroe',
  AtlanticJanMayen = 'Atlantic_Jan_Mayen',
  AtlanticMadeira = 'Atlantic_Madeira',
  AtlanticReykjavik = 'Atlantic_Reykjavik',
  AtlanticSouthGeorgia = 'Atlantic_South_Georgia',
  AtlanticStHelena = 'Atlantic_St_Helena',
  AtlanticStanley = 'Atlantic_Stanley',
  AustraliaAct = 'Australia_ACT',
  AustraliaAdelaide = 'Australia_Adelaide',
  AustraliaBrisbane = 'Australia_Brisbane',
  AustraliaBrokenHill = 'Australia_Broken_Hill',
  AustraliaCanberra = 'Australia_Canberra',
  AustraliaCurrie = 'Australia_Currie',
  AustraliaDarwin = 'Australia_Darwin',
  AustraliaEucla = 'Australia_Eucla',
  AustraliaHobart = 'Australia_Hobart',
  AustraliaLhi = 'Australia_LHI',
  AustraliaLindeman = 'Australia_Lindeman',
  AustraliaLordHowe = 'Australia_Lord_Howe',
  AustraliaMelbourne = 'Australia_Melbourne',
  AustraliaNsw = 'Australia_NSW',
  AustraliaNorth = 'Australia_North',
  AustraliaPerth = 'Australia_Perth',
  AustraliaQueensland = 'Australia_Queensland',
  AustraliaSouth = 'Australia_South',
  AustraliaSydney = 'Australia_Sydney',
  AustraliaTasmania = 'Australia_Tasmania',
  AustraliaVictoria = 'Australia_Victoria',
  AustraliaWest = 'Australia_West',
  AustraliaYancowinna = 'Australia_Yancowinna',
  BrazilAcre = 'Brazil_Acre',
  BrazilDeNoronha = 'Brazil_DeNoronha',
  BrazilEast = 'Brazil_East',
  BrazilWest = 'Brazil_West',
  Cet = 'CET',
  Cst6Cdt = 'CST6CDT',
  CanadaAtlantic = 'Canada_Atlantic',
  CanadaCentral = 'Canada_Central',
  CanadaEastern = 'Canada_Eastern',
  CanadaMountain = 'Canada_Mountain',
  CanadaNewfoundland = 'Canada_Newfoundland',
  CanadaPacific = 'Canada_Pacific',
  CanadaSaskatchewan = 'Canada_Saskatchewan',
  CanadaYukon = 'Canada_Yukon',
  ChileContinental = 'Chile_Continental',
  ChileEasterIsland = 'Chile_EasterIsland',
  Cuba = 'Cuba',
  Eet = 'EET',
  Est = 'EST',
  Est5Edt = 'EST5EDT',
  Egypt = 'Egypt',
  Eire = 'Eire',
  EtcGmt = 'Etc_GMT',
  EtcGmt_0 = 'Etc_GMT_0',
  EtcGmt_1 = 'Etc_GMT_1',
  EtcGmt_10 = 'Etc_GMT_10',
  EtcGmt_11 = 'Etc_GMT_11',
  EtcGmt_12 = 'Etc_GMT_12',
  EtcGmt_2 = 'Etc_GMT_2',
  EtcGmt_3 = 'Etc_GMT_3',
  EtcGmt_4 = 'Etc_GMT_4',
  EtcGmt_5 = 'Etc_GMT_5',
  EtcGmt_6 = 'Etc_GMT_6',
  EtcGmt_7 = 'Etc_GMT_7',
  EtcGmt_8 = 'Etc_GMT_8',
  EtcGmt_9 = 'Etc_GMT_9',
  EtcGmt_13 = 'Etc_GMT_13',
  EtcGmt_14 = 'Etc_GMT_14',
  EtcGmt0 = 'Etc_GMT0',
  EtcGreenwich = 'Etc_Greenwich',
  EtcUct = 'Etc_UCT',
  EtcUtc = 'Etc_UTC',
  EtcUniversal = 'Etc_Universal',
  EtcZulu = 'Etc_Zulu',
  EuropeAmsterdam = 'Europe_Amsterdam',
  EuropeAndorra = 'Europe_Andorra',
  EuropeAstrakhan = 'Europe_Astrakhan',
  EuropeAthens = 'Europe_Athens',
  EuropeBelfast = 'Europe_Belfast',
  EuropeBelgrade = 'Europe_Belgrade',
  EuropeBerlin = 'Europe_Berlin',
  EuropeBratislava = 'Europe_Bratislava',
  EuropeBrussels = 'Europe_Brussels',
  EuropeBucharest = 'Europe_Bucharest',
  EuropeBudapest = 'Europe_Budapest',
  EuropeBusingen = 'Europe_Busingen',
  EuropeChisinau = 'Europe_Chisinau',
  EuropeCopenhagen = 'Europe_Copenhagen',
  EuropeDublin = 'Europe_Dublin',
  EuropeGibraltar = 'Europe_Gibraltar',
  EuropeGuernsey = 'Europe_Guernsey',
  EuropeHelsinki = 'Europe_Helsinki',
  EuropeIsleOfMan = 'Europe_Isle_of_Man',
  EuropeIstanbul = 'Europe_Istanbul',
  EuropeJersey = 'Europe_Jersey',
  EuropeKaliningrad = 'Europe_Kaliningrad',
  EuropeKiev = 'Europe_Kiev',
  EuropeKirov = 'Europe_Kirov',
  EuropeLisbon = 'Europe_Lisbon',
  EuropeLjubljana = 'Europe_Ljubljana',
  EuropeLondon = 'Europe_London',
  EuropeLuxembourg = 'Europe_Luxembourg',
  EuropeMadrid = 'Europe_Madrid',
  EuropeMalta = 'Europe_Malta',
  EuropeMariehamn = 'Europe_Mariehamn',
  EuropeMinsk = 'Europe_Minsk',
  EuropeMonaco = 'Europe_Monaco',
  EuropeMoscow = 'Europe_Moscow',
  EuropeNicosia = 'Europe_Nicosia',
  EuropeOslo = 'Europe_Oslo',
  EuropeParis = 'Europe_Paris',
  EuropePodgorica = 'Europe_Podgorica',
  EuropePrague = 'Europe_Prague',
  EuropeRiga = 'Europe_Riga',
  EuropeRome = 'Europe_Rome',
  EuropeSamara = 'Europe_Samara',
  EuropeSanMarino = 'Europe_San_Marino',
  EuropeSarajevo = 'Europe_Sarajevo',
  EuropeSaratov = 'Europe_Saratov',
  EuropeSimferopol = 'Europe_Simferopol',
  EuropeSkopje = 'Europe_Skopje',
  EuropeSofia = 'Europe_Sofia',
  EuropeStockholm = 'Europe_Stockholm',
  EuropeTallinn = 'Europe_Tallinn',
  EuropeTirane = 'Europe_Tirane',
  EuropeTiraspol = 'Europe_Tiraspol',
  EuropeUlyanovsk = 'Europe_Ulyanovsk',
  EuropeUzhgorod = 'Europe_Uzhgorod',
  EuropeVaduz = 'Europe_Vaduz',
  EuropeVatican = 'Europe_Vatican',
  EuropeVienna = 'Europe_Vienna',
  EuropeVilnius = 'Europe_Vilnius',
  EuropeVolgograd = 'Europe_Volgograd',
  EuropeWarsaw = 'Europe_Warsaw',
  EuropeZagreb = 'Europe_Zagreb',
  EuropeZaporozhye = 'Europe_Zaporozhye',
  EuropeZurich = 'Europe_Zurich',
  Gb = 'GB',
  GbEire = 'GB_Eire',
  Gmt = 'GMT',
  Gmt_0 = 'GMT_0',
  Gmt0 = 'GMT0',
  Greenwich = 'Greenwich',
  Hst = 'HST',
  Hongkong = 'Hongkong',
  Iceland = 'Iceland',
  IndianAntananarivo = 'Indian_Antananarivo',
  IndianChagos = 'Indian_Chagos',
  IndianChristmas = 'Indian_Christmas',
  IndianCocos = 'Indian_Cocos',
  IndianComoro = 'Indian_Comoro',
  IndianKerguelen = 'Indian_Kerguelen',
  IndianMahe = 'Indian_Mahe',
  IndianMaldives = 'Indian_Maldives',
  IndianMauritius = 'Indian_Mauritius',
  IndianMayotte = 'Indian_Mayotte',
  IndianReunion = 'Indian_Reunion',
  Iran = 'Iran',
  Israel = 'Israel',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Kwajalein = 'Kwajalein',
  Libya = 'Libya',
  Met = 'MET',
  Mst = 'MST',
  Mst7Mdt = 'MST7MDT',
  MexicoBajaNorte = 'Mexico_BajaNorte',
  MexicoBajaSur = 'Mexico_BajaSur',
  MexicoGeneral = 'Mexico_General',
  Nz = 'NZ',
  NzChat = 'NZ_CHAT',
  Navajo = 'Navajo',
  Prc = 'PRC',
  Pst8Pdt = 'PST8PDT',
  PacificApia = 'Pacific_Apia',
  PacificAuckland = 'Pacific_Auckland',
  PacificBougainville = 'Pacific_Bougainville',
  PacificChatham = 'Pacific_Chatham',
  PacificChuuk = 'Pacific_Chuuk',
  PacificEaster = 'Pacific_Easter',
  PacificEfate = 'Pacific_Efate',
  PacificEnderbury = 'Pacific_Enderbury',
  PacificFakaofo = 'Pacific_Fakaofo',
  PacificFiji = 'Pacific_Fiji',
  PacificFunafuti = 'Pacific_Funafuti',
  PacificGalapagos = 'Pacific_Galapagos',
  PacificGambier = 'Pacific_Gambier',
  PacificGuadalcanal = 'Pacific_Guadalcanal',
  PacificGuam = 'Pacific_Guam',
  PacificHonolulu = 'Pacific_Honolulu',
  PacificJohnston = 'Pacific_Johnston',
  PacificKanton = 'Pacific_Kanton',
  PacificKiritimati = 'Pacific_Kiritimati',
  PacificKosrae = 'Pacific_Kosrae',
  PacificKwajalein = 'Pacific_Kwajalein',
  PacificMajuro = 'Pacific_Majuro',
  PacificMarquesas = 'Pacific_Marquesas',
  PacificMidway = 'Pacific_Midway',
  PacificNauru = 'Pacific_Nauru',
  PacificNiue = 'Pacific_Niue',
  PacificNorfolk = 'Pacific_Norfolk',
  PacificNoumea = 'Pacific_Noumea',
  PacificPagoPago = 'Pacific_Pago_Pago',
  PacificPalau = 'Pacific_Palau',
  PacificPitcairn = 'Pacific_Pitcairn',
  PacificPohnpei = 'Pacific_Pohnpei',
  PacificPonape = 'Pacific_Ponape',
  PacificPortMoresby = 'Pacific_Port_Moresby',
  PacificRarotonga = 'Pacific_Rarotonga',
  PacificSaipan = 'Pacific_Saipan',
  PacificSamoa = 'Pacific_Samoa',
  PacificTahiti = 'Pacific_Tahiti',
  PacificTarawa = 'Pacific_Tarawa',
  PacificTongatapu = 'Pacific_Tongatapu',
  PacificTruk = 'Pacific_Truk',
  PacificWake = 'Pacific_Wake',
  PacificWallis = 'Pacific_Wallis',
  PacificYap = 'Pacific_Yap',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Roc = 'ROC',
  Rok = 'ROK',
  Singapore = 'Singapore',
  Turkey = 'Turkey',
  Uct = 'UCT',
  UsAlaska = 'US_Alaska',
  UsAleutian = 'US_Aleutian',
  UsArizona = 'US_Arizona',
  UsCentral = 'US_Central',
  UsEastIndiana = 'US_East_Indiana',
  UsEastern = 'US_Eastern',
  UsHawaii = 'US_Hawaii',
  UsIndianaStarke = 'US_Indiana_Starke',
  UsMichigan = 'US_Michigan',
  UsMountain = 'US_Mountain',
  UsPacific = 'US_Pacific',
  UsSamoa = 'US_Samoa',
  Utc = 'UTC',
  Universal = 'Universal',
  WSu = 'W_SU',
  Wet = 'WET',
  Zulu = 'Zulu',
}

export enum EnumPlayerSpecsOsPlatform {
  WebApp = 'WEB_APP',
  AndroidApp = 'ANDROID_APP',
  Unknown = 'UNKNOWN',
}

export enum EnumPlayerSpecsOsType {
  Windows = 'WINDOWS',
  Linux = 'LINUX',
  Macos = 'MACOS',
  Android = 'ANDROID',
  Ios = 'IOS',
  Chromeos = 'CHROMEOS',
  Unknown = 'UNKNOWN',
}

export enum EnumPlayerSpecsStorageAdapter {
  Cachefs = 'cachefs',
  Ipfs = 'ipfs',
}

export enum EnumPlayerStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Binded = 'BINDED',
  Registered = 'REGISTERED',
  Archived = 'ARCHIVED',
}

export enum EnumPointType {
  Point = 'Point',
}

export enum EnumRemovedReason {
  Archived = 'ARCHIVED',
  Removed = 'REMOVED',
  NotRemoved = 'NOT_REMOVED',
}

export enum EnumUploadStatus {
  Uploading = 'UPLOADING',
  Uploaded = 'UPLOADED',
  Verifying = 'VERIFYING',
  VerifyingFailed = 'VERIFYING_FAILED',
  Ready = 'READY',
}

export enum EnumUploadType {
  Image = 'image',
  Video = 'video',
}

export enum EnumUserGender {
  Female = 'female',
  Male = 'male',
  None = 'none',
}

export enum EnumWebhookEvent_Types {
  PlayerBinded = 'player_binded',
  PlayerCreated = 'player_created',
  PlayerActivated = 'player_activated',
  PlayerArchived = 'player_archived',
  PlayerUnarchived = 'player_unarchived',
  PlayerOnline = 'player_online',
  PlayerOffline = 'player_offline',
  CreativeUploaded = 'creative_uploaded',
  CreativeArchived = 'creative_archived',
  CreativeUnarchived = 'creative_unarchived',
  NetworkCreated = 'network_created',
  NetworkChanged = 'network_changed',
  NetworkArchived = 'network_archived',
  NetworkUnarchived = 'network_unarchived',
  CampaignCreated = 'campaign_created',
  CampaignChanged = 'campaign_changed',
  CampaignArchived = 'campaign_archived',
  CampaignUnarchived = 'campaign_unarchived',
  CampaignAdGroupCreated = 'campaign_ad_group_created',
  CampaignAdGroupChanged = 'campaign_ad_group_changed',
  CampaignAdGroupArchived = 'campaign_ad_group_archived',
  CampaignAdGroupUnarchived = 'campaign_ad_group_unarchived',
  WebhookCreated = 'webhook_created',
  WebhookChanged = 'webhook_changed',
  WebhookArchived = 'webhook_archived',
  Engagement = 'engagement',
}

export enum EnumWebhookType {
  Ids = 'ids',
  SlackMessage = 'slack_message',
}

export enum EnumWorkspaceInviteStatus {
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum EnumWorkspaceMembersRole {
  Administrator = 'ADMINISTRATOR',
  Publisher = 'PUBLISHER',
  Editor = 'EDITOR',
  Guest = 'GUEST',
}

export enum EnumWorkspacePlatform_Type {
  Enterprise = 'ENTERPRISE',
  Advertiser = 'ADVERTISER',
  ContentCreator = 'CONTENT_CREATOR',
  Venue = 'VENUE',
}

export enum EnumWorkspaceStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Archived = 'ARCHIVED',
}

export type FilterAreaTypeInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterAreaTypeInput>
  OR?: Maybe<Array<FilterAreaTypeInput>>
  AND?: Maybe<Array<FilterAreaTypeInput>>
}

export type FilterCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCampaignAdGroupInput>
  OR?: Maybe<Array<FilterCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCampaignInput>
  OR?: Maybe<Array<FilterCampaignInput>>
  AND?: Maybe<Array<FilterCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCategoryInput>
  OR?: Maybe<Array<FilterCategoryInput>>
  AND?: Maybe<Array<FilterCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCountryInput>
  OR?: Maybe<Array<FilterCountryInput>>
  AND?: Maybe<Array<FilterCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCreativeInput>
  OR?: Maybe<Array<FilterCreativeInput>>
  AND?: Maybe<Array<FilterCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCurrencyInput>
  OR?: Maybe<Array<FilterCurrencyInput>>
  AND?: Maybe<Array<FilterCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterDistanceUnitInput>
  OR?: Maybe<Array<FilterDistanceUnitInput>>
  AND?: Maybe<Array<FilterDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyAreaTypeInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAreaTypeInput>
  OR?: Maybe<Array<FilterFindManyAreaTypeInput>>
  AND?: Maybe<Array<FilterFindManyAreaTypeInput>>
}

export type FilterFindManyCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignAdGroupInput>
  OR?: Maybe<Array<FilterFindManyCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterFindManyCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignInput>
  OR?: Maybe<Array<FilterFindManyCampaignInput>>
  AND?: Maybe<Array<FilterFindManyCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCategoryInput>
  OR?: Maybe<Array<FilterFindManyCategoryInput>>
  AND?: Maybe<Array<FilterFindManyCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCountryInput>
  OR?: Maybe<Array<FilterFindManyCountryInput>>
  AND?: Maybe<Array<FilterFindManyCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCreativeInput>
  OR?: Maybe<Array<FilterFindManyCreativeInput>>
  AND?: Maybe<Array<FilterFindManyCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCurrencyInput>
  OR?: Maybe<Array<FilterFindManyCurrencyInput>>
  AND?: Maybe<Array<FilterFindManyCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDistanceUnitInput>
  OR?: Maybe<Array<FilterFindManyDistanceUnitInput>>
  AND?: Maybe<Array<FilterFindManyDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyLanguageInput>
  OR?: Maybe<Array<FilterFindManyLanguageInput>>
  AND?: Maybe<Array<FilterFindManyLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyNetworkInput>
  OR?: Maybe<Array<FilterFindManyNetworkInput>>
  AND?: Maybe<Array<FilterFindManyNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyPlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  specs?: Maybe<PlayerSpecsInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPlayerInput>
  OR?: Maybe<Array<FilterFindManyPlayerInput>>
  AND?: Maybe<Array<FilterFindManyPlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUploadInput>
  OR?: Maybe<Array<FilterFindManyUploadInput>>
  AND?: Maybe<Array<FilterFindManyUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyVenueTypeInput>
  OR?: Maybe<Array<FilterFindManyVenueTypeInput>>
  AND?: Maybe<Array<FilterFindManyVenueTypeInput>>
}

export type FilterFindManyWebhookEventInput = {
  webhook_id?: Maybe<Scalars['MongoID']>
  type?: Maybe<Scalars['String']>
  payload?: Maybe<WebhookEventPayloadInput>
  at?: Maybe<Scalars['Date']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookEventInput>
  OR?: Maybe<Array<FilterFindManyWebhookEventInput>>
  AND?: Maybe<Array<FilterFindManyWebhookEventInput>>
}

export type FilterFindManyWebhookEventResponseInput = {
  webhook_event_id?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  statusCode?: Maybe<Scalars['Float']>
  body?: Maybe<Scalars['String']>
  headers?: Maybe<Scalars['JSON']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookEventResponseInput>
  OR?: Maybe<Array<FilterFindManyWebhookEventResponseInput>>
  AND?: Maybe<Array<FilterFindManyWebhookEventResponseInput>>
}

export type FilterFindManyWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookInput>
  OR?: Maybe<Array<FilterFindManyWebhookInput>>
  AND?: Maybe<Array<FilterFindManyWebhookInput>>
}

export type FilterFindManyWorkspaceInviteInput = {
  status?: Maybe<EnumWorkspaceInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<WorkspaceInviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWorkspaceInviteInput>
  OR?: Maybe<Array<FilterFindManyWorkspaceInviteInput>>
  AND?: Maybe<Array<FilterFindManyWorkspaceInviteInput>>
}

export type FilterFindOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignAdGroupInput>
  OR?: Maybe<Array<FilterFindOneCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterFindOneCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignInput>
  OR?: Maybe<Array<FilterFindOneCampaignInput>>
  AND?: Maybe<Array<FilterFindOneCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCategoryInput>
  OR?: Maybe<Array<FilterFindOneCategoryInput>>
  AND?: Maybe<Array<FilterFindOneCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCountryInput>
  OR?: Maybe<Array<FilterFindOneCountryInput>>
  AND?: Maybe<Array<FilterFindOneCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCreativeInput>
  OR?: Maybe<Array<FilterFindOneCreativeInput>>
  AND?: Maybe<Array<FilterFindOneCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCurrencyInput>
  OR?: Maybe<Array<FilterFindOneCurrencyInput>>
  AND?: Maybe<Array<FilterFindOneCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneDistanceUnitInput>
  OR?: Maybe<Array<FilterFindOneDistanceUnitInput>>
  AND?: Maybe<Array<FilterFindOneDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneLanguageInput>
  OR?: Maybe<Array<FilterFindOneLanguageInput>>
  AND?: Maybe<Array<FilterFindOneLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneNetworkInput>
  OR?: Maybe<Array<FilterFindOneNetworkInput>>
  AND?: Maybe<Array<FilterFindOneNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  specs?: Maybe<PlayerSpecsInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOnePlayerInput>
  OR?: Maybe<Array<FilterFindOnePlayerInput>>
  AND?: Maybe<Array<FilterFindOnePlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUploadInput>
  OR?: Maybe<Array<FilterFindOneUploadInput>>
  AND?: Maybe<Array<FilterFindOneUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneWebhookInput>
  OR?: Maybe<Array<FilterFindOneWebhookInput>>
  AND?: Maybe<Array<FilterFindOneWebhookInput>>
}

export type FilterFindOneWorkspaceInviteInput = {
  status?: Maybe<EnumWorkspaceInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<WorkspaceInviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneWorkspaceInviteInput>
  OR?: Maybe<Array<FilterFindOneWorkspaceInviteInput>>
  AND?: Maybe<Array<FilterFindOneWorkspaceInviteInput>>
}

export type FilterLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterLanguageInput>
  OR?: Maybe<Array<FilterLanguageInput>>
  AND?: Maybe<Array<FilterLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterNetworkInput>
  OR?: Maybe<Array<FilterNetworkInput>>
  AND?: Maybe<Array<FilterNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterPlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  specs?: Maybe<PlayerSpecsInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterPlayerInput>
  OR?: Maybe<Array<FilterPlayerInput>>
  AND?: Maybe<Array<FilterPlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterUpdateManyWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyWebhookInput>
  OR?: Maybe<Array<FilterUpdateManyWebhookInput>>
  AND?: Maybe<Array<FilterUpdateManyWebhookInput>>
}

export type FilterUpdateOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCampaignAdGroupInput>
  OR?: Maybe<Array<FilterUpdateOneCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterUpdateOneCampaignAdGroupInput>>
}

export type FilterUpdateOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCampaignInput>
  OR?: Maybe<Array<FilterUpdateOneCampaignInput>>
  AND?: Maybe<Array<FilterUpdateOneCampaignInput>>
}

export type FilterUpdateOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCreativeInput>
  OR?: Maybe<Array<FilterUpdateOneCreativeInput>>
  AND?: Maybe<Array<FilterUpdateOneCreativeInput>>
}

export type FilterUpdateOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneNetworkInput>
  OR?: Maybe<Array<FilterUpdateOneNetworkInput>>
  AND?: Maybe<Array<FilterUpdateOneNetworkInput>>
}

export type FilterUpdateOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  specs?: Maybe<PlayerSpecsInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOnePlayerInput>
  OR?: Maybe<Array<FilterUpdateOnePlayerInput>>
  AND?: Maybe<Array<FilterUpdateOnePlayerInput>>
}

export type FilterUpdateOneWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneWebhookInput>
  OR?: Maybe<Array<FilterUpdateOneWebhookInput>>
  AND?: Maybe<Array<FilterUpdateOneWebhookInput>>
}

export type FilterUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUploadInput>
  OR?: Maybe<Array<FilterUploadInput>>
  AND?: Maybe<Array<FilterUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterVenueTypeInput>
  OR?: Maybe<Array<FilterVenueTypeInput>>
  AND?: Maybe<Array<FilterVenueTypeInput>>
}

export type FilterWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterWebhookInput>
  OR?: Maybe<Array<FilterWebhookInput>>
  AND?: Maybe<Array<FilterWebhookInput>>
}

export type FilterWorkspaceInput = {
  name?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
  is_active?: Maybe<Scalars['Boolean']>
  members?: Maybe<Array<Maybe<WorkspaceMembersInput>>>
  invoice_id?: Maybe<Scalars['String']>
  billing_email?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterWorkspaceInput>
  OR?: Maybe<Array<FilterWorkspaceInput>>
  AND?: Maybe<Array<FilterWorkspaceInput>>
}

export type FilterWorkspaceInviteInput = {
  status?: Maybe<EnumWorkspaceInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<WorkspaceInviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterWorkspaceInviteInput>
  OR?: Maybe<Array<FilterWorkspaceInviteInput>>
  AND?: Maybe<Array<FilterWorkspaceInviteInput>>
}

export type FindManyExternalCampaignsResponse = {
  __typename?: 'FindManyExternalCampaignsResponse'
  network_id?: Maybe<Scalars['MongoID']>
  network_name?: Maybe<Scalars['String']>
  campaign?: Maybe<Campaign>
  broadcast_target?: Maybe<BroadcastTarget>
}

export enum ForgotPasswordMethod {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type Geofence = {
  __typename?: 'Geofence'
  location?: Maybe<Point>
  range?: Maybe<Scalars['Float']>
  distance_unit_id?: Maybe<Scalars['MongoID']>
}

export type GeofenceInput = {
  location?: Maybe<PointInput>
  range?: Maybe<Scalars['Float']>
  distance_unit_id?: Maybe<Scalars['MongoID']>
}

export type InvoiceData = {
  __typename?: 'InvoiceData'
  id?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  invoice_pdf?: Maybe<Scalars['String']>
  attempt_count?: Maybe<Scalars['Int']>
  attemped?: Maybe<Scalars['Boolean']>
  charge?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  default_payment_method?: Maybe<Scalars['String']>
  default_source?: Maybe<Scalars['String']>
  days_until_due?: Maybe<Scalars['Int']>
  hosted_invoice_url?: Maybe<Scalars['String']>
  subscription?: Maybe<Scalars['String']>
  paid?: Maybe<Scalars['Boolean']>
  status?: Maybe<Scalars['String']>
  payment_intent?: Maybe<Scalars['String']>
}

export type Is_ActiveOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['Boolean']>
  gte?: Maybe<Scalars['Boolean']>
  lt?: Maybe<Scalars['Boolean']>
  lte?: Maybe<Scalars['Boolean']>
  ne?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>
}

export type Is_ActiveOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['Boolean']>
  gte?: Maybe<Scalars['Boolean']>
  lt?: Maybe<Scalars['Boolean']>
  lte?: Maybe<Scalars['Boolean']>
  ne?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>
}

export type Is_ActiveOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['Boolean']>
  gte?: Maybe<Scalars['Boolean']>
  lt?: Maybe<Scalars['Boolean']>
  lte?: Maybe<Scalars['Boolean']>
  ne?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>
}

export type Is_ActiveOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['Boolean']>
  gte?: Maybe<Scalars['Boolean']>
  lt?: Maybe<Scalars['Boolean']>
  lte?: Maybe<Scalars['Boolean']>
  ne?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>
}

export type Language = {
  __typename?: 'Language'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type Mutation = {
  __typename?: 'Mutation'
  AccountChangePassword?: Maybe<Scalars['Boolean']>
  DisableAccount?: Maybe<Scalars['Boolean']>
  AccountEmailAdd?: Maybe<Scalars['Boolean']>
  AccountEmailRemove?: Maybe<Scalars['Boolean']>
  AccountEmailSendVerificationCode?: Maybe<Scalars['Boolean']>
  AccountEmailSetPrimary?: Maybe<Scalars['Boolean']>
  AccountEmailVerifyCode?: Maybe<Scalars['Boolean']>
  AccountAcceptWorkspaceInvites: Array<WorkspaceInvite>
  AccountRejectWorkspaceInvites: Array<WorkspaceInvite>
  AccountPhoneAdd?: Maybe<Scalars['Boolean']>
  AccountPhoneRemove?: Maybe<Scalars['Boolean']>
  AccountPhoneSendVerificationCode?: Maybe<Scalars['Boolean']>
  AccountPhoneSetPrimary?: Maybe<Scalars['Boolean']>
  AccountPhoneVerifyCode?: Maybe<Scalars['Boolean']>
  UpdateAccountDetails?: Maybe<User>
  AuthForgotPasswordReset?: Maybe<AuthAccess>
  AuthForgotPasswordSendCode?: Maybe<Scalars['Boolean']>
  AuthForgotPasswordVerifyCode: Scalars['String']
  AuthRefresh?: Maybe<AuthRefreshResult>
  AuthSignIn?: Maybe<AuthAccess>
  AuthSignOut: Scalars['Boolean']
  AuthSignUp?: Maybe<UserSignUpResult>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CreateCampaignAdGroup?: Maybe<CreateOneCampaignAdGroupPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdateCampaignAdGroup?: Maybe<UpdateOneCampaignAdGroupPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CreateCampaign?: Maybe<CreateOneCampaignPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdateCampaign?: Maybe<UpdateOneCampaignPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdateCreative?: Maybe<UpdateOneCreativePayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CreateWebhook?: Maybe<CreateOneWebhookPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  BulkCreateWebhooks?: Maybe<CreateManyWebhookPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdateWebhook?: Maybe<UpdateOneWebhookPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  BulkUpdateWebhooks?: Maybe<UpdateManyWebhookPayload>
  PreSignCreativeUpload?: Maybe<PreSignCreativeUploadResponse>
  ShredCreative?: Maybe<Scalars['Boolean']>
  NotifyCreativeUploadCompleted?: Maybe<Scalars['Boolean']>
  ArchiveCreatives?: Maybe<Scalars['Boolean']>
  UnarchiveCreatives?: Maybe<Scalars['Boolean']>
  SetIsActiveCreatives?: Maybe<Scalars['Boolean']>
  ArchiveNetworks?: Maybe<Scalars['Boolean']>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CreateNetwork?: Maybe<CreateOneNetworkPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdateNetwork?: Maybe<UpdateOneNetworkPayload>
  NetworkRevokeWorkspaceAccess?: Maybe<Scalars['Boolean']>
  SetIsActiveNetworks?: Maybe<Scalars['Boolean']>
  UpdatePlayersFromNetwork?: Maybe<Scalars['Boolean']>
  UnarchiveNetworks?: Maybe<Scalars['Boolean']>
  ArchiveCampaignAdGroups?: Maybe<Scalars['Boolean']>
  SetIsActiveCampaignAdGroups?: Maybe<Scalars['Boolean']>
  UnarchiveCampaignAdGroups?: Maybe<Scalars['Boolean']>
  ArchiveCampaigns?: Maybe<Scalars['Boolean']>
  CancelBroadcastTargets?: Maybe<Scalars['Boolean']>
  ClearBroadcastTargets?: Maybe<Scalars['Boolean']>
  RevokeBroadcastTargets?: Maybe<Scalars['Boolean']>
  SubmitBroadcastTargets?: Maybe<Scalars['Boolean']>
  UpdateBroadcastTargetsFromCampaign?: Maybe<Scalars['Boolean']>
  ApproveExternalCampaign?: Maybe<Scalars['Boolean']>
  RejectExternalCampaign?: Maybe<Scalars['Boolean']>
  RevokeExternalCampaign?: Maybe<Scalars['Boolean']>
  SetIsActiveCampaigns?: Maybe<Scalars['Boolean']>
  UnarchiveCampaigns?: Maybe<Scalars['Boolean']>
  AddPlayerToNetworks: Array<Scalars['Boolean']>
  ArchivePlayers?: Maybe<Scalars['Boolean']>
  PlayerBind?: Maybe<Player>
  FinishPlayerSetup?: Maybe<Scalars['Boolean']>
  SetIsActivePlayers?: Maybe<Scalars['Boolean']>
  PlayerUnarchiveFromNetworks?: Maybe<Scalars['Boolean']>
  UnarchivePlayer?: Maybe<Scalars['Boolean']>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UpdatePlayer?: Maybe<UpdateOnePlayerPayload>
  DisableWorkspace?: Maybe<Scalars['Boolean']>
  InviteWorkspaceMember: Array<WorkspaceInvite>
  RemoveWorkspaceMember?: Maybe<Scalars['Boolean']>
  RevokeWorkspaceMemberInvite: Array<WorkspaceInvite>
  ArchiveWebhooks?: Maybe<Scalars['Boolean']>
  SetupWorkspace?: Maybe<Workspace>
  UpdateWorkspaceDetails?: Maybe<Workspace>
  WorkspaceAddExternalNetwork?: Maybe<Scalars['Boolean']>
  WorkspaceAddPaymentMethod?: Maybe<Scalars['String']>
  WorkspaceSetDefaultPaymentMethod?: Maybe<Scalars['Boolean']>
  WorkspaceSetupIntent: SetupIntentObject
  WorkspaceRemovePaymentMethod?: Maybe<Scalars['Boolean']>
  WorkspacePayInvoice?: Maybe<Scalars['Boolean']>
}

export type MutationAccountChangePasswordArgs = {
  refreshToken: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationAccountEmailAddArgs = {
  email: Scalars['String']
}

export type MutationAccountEmailRemoveArgs = {
  email: Scalars['String']
}

export type MutationAccountEmailSendVerificationCodeArgs = {
  email: Scalars['String']
}

export type MutationAccountEmailSetPrimaryArgs = {
  email: Scalars['String']
}

export type MutationAccountEmailVerifyCodeArgs = {
  email: Scalars['String']
  code: Scalars['String']
}

export type MutationAccountAcceptWorkspaceInvitesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationAccountRejectWorkspaceInvitesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationAccountPhoneAddArgs = {
  phone: Scalars['String']
}

export type MutationAccountPhoneRemoveArgs = {
  phone: Scalars['String']
}

export type MutationAccountPhoneSendVerificationCodeArgs = {
  phone: Scalars['String']
}

export type MutationAccountPhoneSetPrimaryArgs = {
  phone: Scalars['String']
}

export type MutationAccountPhoneVerifyCodeArgs = {
  phone: Scalars['String']
  code: Scalars['String']
}

export type MutationUpdateAccountDetailsArgs = {
  details?: Maybe<AccountUpdateDetailsInput>
}

export type MutationAuthForgotPasswordResetArgs = {
  recoveryToken: Scalars['String']
  password: Scalars['String']
}

export type MutationAuthForgotPasswordSendCodeArgs = {
  method?: Maybe<ForgotPasswordMethod>
  identifier?: Maybe<Scalars['String']>
}

export type MutationAuthForgotPasswordVerifyCodeArgs = {
  method?: Maybe<ForgotPasswordMethod>
  identifier: Scalars['String']
  code: Scalars['String']
}

export type MutationAuthRefreshArgs = {
  refreshToken: Scalars['String']
}

export type MutationAuthSignInArgs = {
  _id?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}

export type MutationAuthSignOutArgs = {
  refreshToken: Scalars['String']
}

export type MutationAuthSignUpArgs = {
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}

export type MutationCreateCampaignAdGroupArgs = {
  record: CreateOneCampaignAdGroupInput
}

export type MutationUpdateCampaignAdGroupArgs = {
  record: UpdateOneCampaignAdGroupInput
  filter?: Maybe<FilterUpdateOneCampaignAdGroupInput>
  sort?: Maybe<SortUpdateOneCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationCreateCampaignArgs = {
  record: CreateOneCampaignInput
}

export type MutationUpdateCampaignArgs = {
  record: UpdateOneCampaignInput
  filter?: Maybe<FilterUpdateOneCampaignInput>
  sort?: Maybe<SortUpdateOneCampaignInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationUpdateCreativeArgs = {
  record: UpdateOneCreativeInput
  filter?: Maybe<FilterUpdateOneCreativeInput>
  sort?: Maybe<SortUpdateOneCreativeInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationCreateWebhookArgs = {
  record: CreateOneWebhookInput
}

export type MutationBulkCreateWebhooksArgs = {
  records: Array<CreateManyWebhookInput>
}

export type MutationUpdateWebhookArgs = {
  record: UpdateOneWebhookInput
  filter?: Maybe<FilterUpdateOneWebhookInput>
  sort?: Maybe<SortUpdateOneWebhookInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationBulkUpdateWebhooksArgs = {
  record: UpdateManyWebhookInput
  filter?: Maybe<FilterUpdateManyWebhookInput>
  sort?: Maybe<SortUpdateManyWebhookInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationPreSignCreativeUploadArgs = {
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  filename?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  creative_type?: Maybe<EnumCreativeType>
}

export type MutationShredCreativeArgs = {
  id: Scalars['MongoID']
  shred_token: Scalars['String']
}

export type MutationNotifyCreativeUploadCompletedArgs = {
  creative_id?: Maybe<Scalars['MongoID']>
}

export type MutationArchiveCreativesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationUnarchiveCreativesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationSetIsActiveCreativesArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationArchiveNetworksArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationCreateNetworkArgs = {
  record: CreateOneNetworkInput
}

export type MutationUpdateNetworkArgs = {
  record: UpdateOneNetworkInput
  filter?: Maybe<FilterUpdateOneNetworkInput>
  sort?: Maybe<SortUpdateOneNetworkInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationNetworkRevokeWorkspaceAccessArgs = {
  workspaceId: Scalars['MongoID']
  networkId: Scalars['MongoID']
}

export type MutationSetIsActiveNetworksArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationUpdatePlayersFromNetworkArgs = {
  player_ids: Array<Scalars['MongoID']>
  network_id: Scalars['MongoID']
}

export type MutationUnarchiveNetworksArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationArchiveCampaignAdGroupsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationSetIsActiveCampaignAdGroupsArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationUnarchiveCampaignAdGroupsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationArchiveCampaignsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationCancelBroadcastTargetsArgs = {
  broadcastTargetIds: Array<Scalars['MongoID']>
  campaign_id: Scalars['MongoID']
}

export type MutationClearBroadcastTargetsArgs = {
  broadcastTargetIds: Array<Scalars['MongoID']>
  campaign_id: Scalars['MongoID']
}

export type MutationRevokeBroadcastTargetsArgs = {
  broadcastTargetIds: Array<Scalars['MongoID']>
  campaign_id: Scalars['MongoID']
}

export type MutationSubmitBroadcastTargetsArgs = {
  broadcastTargetIds: Array<Scalars['MongoID']>
  campaign_id: Scalars['MongoID']
}

export type MutationUpdateBroadcastTargetsFromCampaignArgs = {
  _id: Scalars['MongoID']
  targets: Array<UpdateBroadcastTargetsFromCampaignTargets>
}

export type MutationApproveExternalCampaignArgs = {
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
}

export type MutationRejectExternalCampaignArgs = {
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
  reviewNotes?: Maybe<Scalars['String']>
}

export type MutationRevokeExternalCampaignArgs = {
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
  reviewNotes?: Maybe<Scalars['String']>
}

export type MutationSetIsActiveCampaignsArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationUnarchiveCampaignsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationAddPlayerToNetworksArgs = {
  player_id: Scalars['MongoID']
  network_ids: Array<Scalars['MongoID']>
}

export type MutationArchivePlayersArgs = {
  ids: Array<Scalars['MongoID']>
  why?: Maybe<Scalars['String']>
}

export type MutationPlayerBindArgs = {
  token?: Maybe<Scalars['String']>
}

export type MutationFinishPlayerSetupArgs = {
  _id: Scalars['MongoID']
}

export type MutationSetIsActivePlayersArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationPlayerUnarchiveFromNetworksArgs = {
  network_ids: Array<Scalars['MongoID']>
  player_id: Scalars['MongoID']
}

export type MutationUnarchivePlayerArgs = {
  _id: Scalars['MongoID']
  network_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type MutationUpdatePlayerArgs = {
  record: UpdateOnePlayerInput
  filter?: Maybe<FilterUpdateOnePlayerInput>
  sort?: Maybe<SortUpdateOnePlayerInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDisableWorkspaceArgs = {
  _id: Scalars['String']
}

export type MutationInviteWorkspaceMemberArgs = {
  emails: Array<Scalars['String']>
}

export type MutationRemoveWorkspaceMemberArgs = {
  email: Scalars['String']
}

export type MutationRevokeWorkspaceMemberInviteArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationArchiveWebhooksArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationSetupWorkspaceArgs = {
  name?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
  billing_email?: Maybe<Scalars['String']>
}

export type MutationUpdateWorkspaceDetailsArgs = {
  _id: Scalars['String']
  details?: Maybe<WorkspaceUpdateDetailsInput>
}

export type MutationWorkspaceAddExternalNetworkArgs = {
  invite_token: Scalars['String']
  workspace_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type MutationWorkspaceAddPaymentMethodArgs = {
  paymentMethod?: Maybe<PaymentMethodInput>
}

export type MutationWorkspaceSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String']
}

export type MutationWorkspaceSetupIntentArgs = {
  setupIntentArgs?: Maybe<SetupIntentInput>
}

export type MutationWorkspaceRemovePaymentMethodArgs = {
  paymentMethodId?: Maybe<Scalars['String']>
}

export type MutationWorkspacePayInvoiceArgs = {
  invoiceId: Scalars['String']
}

export type NameOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterWorkspaceInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Network = {
  __typename?: 'Network'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  players: Array<Maybe<NetworkPlayers>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspaces>>>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  status?: Maybe<EnumNetworkStatus>
  is_external?: Maybe<Scalars['Boolean']>
  invite_token?: Maybe<Scalars['String']>
}

export type NetworkGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type NetworkInviteLinkInfo = {
  __typename?: 'NetworkInviteLinkInfo'
  network_id?: Maybe<Scalars['MongoID']>
  network_name?: Maybe<Scalars['String']>
  sent_by?: Maybe<Scalars['String']>
}

export type NetworkPlayers = {
  __typename?: 'NetworkPlayers'
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  removed?: Maybe<Removed>
  player?: Maybe<Player>
}

export type NetworkPlayersInput = {
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
  removed?: Maybe<RemovedInput>
}

export type NetworkSharedWithWorkspaces = {
  __typename?: 'NetworkSharedWithWorkspaces'
  workspace_id?: Maybe<Scalars['MongoID']>
  created?: Maybe<ActionDateAndUser>
  revoked?: Maybe<ActionDateAndUser>
  sent_by?: Maybe<Scalars['String']>
  invite_timestamp?: Maybe<Scalars['Float']>
}

export type NetworkSharedWithWorkspacesInput = {
  workspace_id?: Maybe<Scalars['MongoID']>
  created?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  sent_by?: Maybe<Scalars['String']>
  invite_timestamp?: Maybe<Scalars['Float']>
}

export type OnCreativeStatusChange = {
  __typename?: 'OnCreativeStatusChange'
  creative_id?: Maybe<Scalars['String']>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterAreaTypeInput = {
  _id?: Maybe<_IdOperatorsFilterAreaTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterCampaignAdGroupInput>
  is_active?: Maybe<Is_ActiveOperatorsFilterCampaignAdGroupInput>
  campaign_id?: Maybe<Campaign_IdOperatorsFilterCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCampaignInput = {
  name?: Maybe<NameOperatorsFilterCampaignInput>
  _id?: Maybe<_IdOperatorsFilterCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCategoryInput = {
  name?: Maybe<NameOperatorsFilterCategoryInput>
  _id?: Maybe<_IdOperatorsFilterCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCountryInput = {
  name?: Maybe<NameOperatorsFilterCountryInput>
  _id?: Maybe<_IdOperatorsFilterCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCreativeInput = {
  name?: Maybe<NameOperatorsFilterCreativeInput>
  _id?: Maybe<_IdOperatorsFilterCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCurrencyInput = {
  name?: Maybe<NameOperatorsFilterCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAreaTypeInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAreaTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterFindManyCampaignAdGroupInput>
  is_active?: Maybe<Is_ActiveOperatorsFilterFindManyCampaignAdGroupInput>
  campaign_id?: Maybe<Campaign_IdOperatorsFilterFindManyCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignInput = {
  name?: Maybe<NameOperatorsFilterFindManyCampaignInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCategoryInput = {
  name?: Maybe<NameOperatorsFilterFindManyCategoryInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCountryInput = {
  name?: Maybe<NameOperatorsFilterFindManyCountryInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCreativeInput = {
  name?: Maybe<NameOperatorsFilterFindManyCreativeInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCurrencyInput = {
  name?: Maybe<NameOperatorsFilterFindManyCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterFindManyDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterFindManyDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyLanguageInput = {
  name?: Maybe<NameOperatorsFilterFindManyLanguageInput>
  _id?: Maybe<_IdOperatorsFilterFindManyLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyNetworkInput = {
  name?: Maybe<NameOperatorsFilterFindManyNetworkInput>
  _id?: Maybe<_IdOperatorsFilterFindManyNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPlayerInput = {
  name?: Maybe<NameOperatorsFilterFindManyPlayerInput>
  secret?: Maybe<SecretOperatorsFilterFindManyPlayerInput>
  _id?: Maybe<_IdOperatorsFilterFindManyPlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUploadInput = {
  name?: Maybe<NameOperatorsFilterFindManyUploadInput>
  _id?: Maybe<_IdOperatorsFilterFindManyUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterFindManyVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterFindManyVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookEventInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookEventInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookEventResponseInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookEventResponseInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWorkspaceInviteInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWorkspaceInviteInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterFindOneCampaignAdGroupInput>
  is_active?: Maybe<Is_ActiveOperatorsFilterFindOneCampaignAdGroupInput>
  campaign_id?: Maybe<Campaign_IdOperatorsFilterFindOneCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignInput = {
  name?: Maybe<NameOperatorsFilterFindOneCampaignInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCategoryInput = {
  name?: Maybe<NameOperatorsFilterFindOneCategoryInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCountryInput = {
  name?: Maybe<NameOperatorsFilterFindOneCountryInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCreativeInput = {
  name?: Maybe<NameOperatorsFilterFindOneCreativeInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCurrencyInput = {
  name?: Maybe<NameOperatorsFilterFindOneCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterFindOneDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterFindOneDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneLanguageInput = {
  name?: Maybe<NameOperatorsFilterFindOneLanguageInput>
  _id?: Maybe<_IdOperatorsFilterFindOneLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneNetworkInput = {
  name?: Maybe<NameOperatorsFilterFindOneNetworkInput>
  _id?: Maybe<_IdOperatorsFilterFindOneNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOnePlayerInput = {
  name?: Maybe<NameOperatorsFilterFindOnePlayerInput>
  secret?: Maybe<SecretOperatorsFilterFindOnePlayerInput>
  _id?: Maybe<_IdOperatorsFilterFindOnePlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUploadInput = {
  name?: Maybe<NameOperatorsFilterFindOneUploadInput>
  _id?: Maybe<_IdOperatorsFilterFindOneUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneWorkspaceInviteInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneWorkspaceInviteInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterLanguageInput = {
  name?: Maybe<NameOperatorsFilterLanguageInput>
  _id?: Maybe<_IdOperatorsFilterLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterNetworkInput = {
  name?: Maybe<NameOperatorsFilterNetworkInput>
  _id?: Maybe<_IdOperatorsFilterNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterPlayerInput = {
  name?: Maybe<NameOperatorsFilterPlayerInput>
  secret?: Maybe<SecretOperatorsFilterPlayerInput>
  _id?: Maybe<_IdOperatorsFilterPlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateManyWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCampaignAdGroupInput>
  is_active?: Maybe<Is_ActiveOperatorsFilterUpdateOneCampaignAdGroupInput>
  campaign_id?: Maybe<Campaign_IdOperatorsFilterUpdateOneCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCampaignInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCampaignInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCreativeInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCreativeInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneNetworkInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneNetworkInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOnePlayerInput = {
  name?: Maybe<NameOperatorsFilterUpdateOnePlayerInput>
  secret?: Maybe<SecretOperatorsFilterUpdateOnePlayerInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOnePlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUploadInput = {
  name?: Maybe<NameOperatorsFilterUploadInput>
  _id?: Maybe<_IdOperatorsFilterUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterWorkspaceInput = {
  name?: Maybe<NameOperatorsFilterWorkspaceInput>
  _id?: Maybe<_IdOperatorsFilterWorkspaceInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterWorkspaceInviteInput = {
  _id?: Maybe<_IdOperatorsFilterWorkspaceInviteInput>
}

export type Ownership = {
  __typename?: 'Ownership'
  workspace_id?: Maybe<Scalars['MongoID']>
  workspace_name?: Maybe<Scalars['String']>
}

export type PaymentMethodBillingDetails = {
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  vat?: Maybe<Scalars['String']>
}

export type PaymentMethodCard = {
  card_number?: Maybe<Scalars['String']>
  exp_month?: Maybe<Scalars['Int']>
  exp_year?: Maybe<Scalars['Int']>
  cvc?: Maybe<Scalars['String']>
}

export type PaymentMethodData = {
  __typename?: 'paymentMethodData'
  id?: Maybe<Scalars['String']>
  object?: Maybe<Scalars['String']>
  card?: Maybe<Card>
  billing_details?: Maybe<BillingDetails>
  metadata?: Maybe<PaymentMethodsMetada>
}

export type PaymentMethodInput = {
  setupIntentId?: Maybe<Scalars['String']>
  makeDefault?: Maybe<Scalars['Boolean']>
}

export type PaymentMethodsMetada = {
  __typename?: 'PaymentMethodsMetada'
  vat?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
}

export type Player = {
  __typename?: 'Player'
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  ownership?: Maybe<Ownership>
  capabilities?: Maybe<PlayerCapabilities>
  demographics?: Maybe<PlayerDemographics>
  geographics?: Maybe<PlayerGeographics>
  settings?: Maybe<PlayerSettings>
  specs?: Maybe<PlayerSpecs>
  binded?: Maybe<ActionDateAndUser>
  activated?: Maybe<ActionDateAndUser>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<PlayerArchived>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  status?: Maybe<EnumPlayerStatus>
}

export type PlayerGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type PlayerAffectedResourcesByArchive = {
  __typename?: 'PlayerAffectedResourcesByArchive'
  networks?: Maybe<Array<Maybe<Network>>>
}

export type PlayerArchived = {
  __typename?: 'PlayerArchived'
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
  why?: Maybe<Scalars['String']>
}

export type PlayerCapabilities = {
  __typename?: 'PlayerCapabilities'
  playback?: Maybe<PlayerCapabilitiesPlayback>
}

export type PlayerCapabilitiesInput = {
  playback?: Maybe<PlayerCapabilitiesPlaybackInput>
}

export type PlayerCapabilitiesPlayback = {
  __typename?: 'PlayerCapabilitiesPlayback'
  videos?: Maybe<Scalars['Boolean']>
  audios?: Maybe<Scalars['Boolean']>
  images?: Maybe<Scalars['Boolean']>
}

export type PlayerCapabilitiesPlaybackInput = {
  videos?: Maybe<Scalars['Boolean']>
  audios?: Maybe<Scalars['Boolean']>
  images?: Maybe<Scalars['Boolean']>
}

export type PlayerDemographics = {
  __typename?: 'PlayerDemographics'
  language_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  country_id?: Maybe<Scalars['MongoID']>
  area_type_id?: Maybe<Scalars['MongoID']>
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type PlayerDemographicsInput = {
  language_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  country_id?: Maybe<Scalars['MongoID']>
  area_type_id?: Maybe<Scalars['MongoID']>
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type PlayerGeographics = {
  __typename?: 'PlayerGeographics'
  location?: Maybe<Point>
  address?: Maybe<Address>
}

export type PlayerGeographicsInput = {
  location?: Maybe<PointInput>
  address?: Maybe<AddressInput>
}

export type PlayerSettings = {
  __typename?: 'PlayerSettings'
  timezone?: Maybe<EnumPlayerSettingsTimezone>
  debug?: Maybe<Scalars['Boolean']>
  volume?: Maybe<Scalars['Float']>
  brightness?: Maybe<Scalars['Float']>
  orientation?: Maybe<EnumPlayerSettingsOrientation>
  size?: Maybe<PlayerSettingsSize>
  density?: Maybe<Scalars['Float']>
  overscan?: Maybe<PlayerSettingsOverscan>
  video_player?: Maybe<Scalars['String']>
  image_player?: Maybe<Scalars['String']>
  audio_player?: Maybe<Scalars['String']>
  storage_medium_id?: Maybe<Scalars['String']>
}

export type PlayerSettingsInput = {
  timezone?: Maybe<EnumPlayerSettingsTimezone>
  debug?: Maybe<Scalars['Boolean']>
  volume?: Maybe<Scalars['Float']>
  brightness?: Maybe<Scalars['Float']>
  orientation?: Maybe<EnumPlayerSettingsOrientation>
  size?: Maybe<PlayerSettingsSizeInput>
  density?: Maybe<Scalars['Float']>
  overscan?: Maybe<PlayerSettingsOverscanInput>
  video_player?: Maybe<Scalars['String']>
  image_player?: Maybe<Scalars['String']>
  audio_player?: Maybe<Scalars['String']>
  storage_medium_id?: Maybe<Scalars['String']>
}

export type PlayerSettingsOverscan = {
  __typename?: 'PlayerSettingsOverscan'
  top?: Maybe<Scalars['Float']>
  right?: Maybe<Scalars['Float']>
  bottom?: Maybe<Scalars['Float']>
  left?: Maybe<Scalars['Float']>
}

export type PlayerSettingsOverscanInput = {
  top?: Maybe<Scalars['Float']>
  right?: Maybe<Scalars['Float']>
  bottom?: Maybe<Scalars['Float']>
  left?: Maybe<Scalars['Float']>
}

export type PlayerSettingsSize = {
  __typename?: 'PlayerSettingsSize'
  width?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
}

export type PlayerSettingsSizeInput = {
  width?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
}

export type PlayerSpecs = {
  __typename?: 'PlayerSpecs'
  cpu?: Maybe<PlayerSpecsCpu>
  memory?: Maybe<PlayerSpecsMemory>
  os?: Maybe<PlayerSpecsOs>
  viewport?: Maybe<PlayerSpecsViewport>
  storage?: Maybe<Array<Maybe<PlayerSpecsStorage>>>
  players?: Maybe<PlayerSpecsPlayers>
}

export type PlayerSpecsCpu = {
  __typename?: 'PlayerSpecsCpu'
  name?: Maybe<Scalars['String']>
  serial?: Maybe<Scalars['String']>
  cores?: Maybe<Array<Maybe<PlayerSpecsCpuCores>>>
}

export type PlayerSpecsCpuCores = {
  __typename?: 'PlayerSpecsCpuCores'
  speed?: Maybe<Scalars['Float']>
}

export type PlayerSpecsCpuCoresInput = {
  speed?: Maybe<Scalars['Float']>
}

export type PlayerSpecsCpuInput = {
  name?: Maybe<Scalars['String']>
  serial?: Maybe<Scalars['String']>
  cores?: Maybe<Array<Maybe<PlayerSpecsCpuCoresInput>>>
}

export type PlayerSpecsInput = {
  cpu?: Maybe<PlayerSpecsCpuInput>
  memory?: Maybe<PlayerSpecsMemoryInput>
  os?: Maybe<PlayerSpecsOsInput>
  viewport?: Maybe<PlayerSpecsViewportInput>
  storage?: Maybe<Array<Maybe<PlayerSpecsStorageInput>>>
  players?: Maybe<PlayerSpecsPlayersInput>
}

export type PlayerSpecsMemory = {
  __typename?: 'PlayerSpecsMemory'
  total?: Maybe<Scalars['Float']>
}

export type PlayerSpecsMemoryInput = {
  total?: Maybe<Scalars['Float']>
}

export type PlayerSpecsOs = {
  __typename?: 'PlayerSpecsOs'
  platform?: Maybe<EnumPlayerSpecsOsPlatform>
  type?: Maybe<EnumPlayerSpecsOsType>
  name?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export type PlayerSpecsOsInput = {
  platform?: Maybe<EnumPlayerSpecsOsPlatform>
  type?: Maybe<EnumPlayerSpecsOsType>
  name?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export type PlayerSpecsPlayers = {
  __typename?: 'PlayerSpecsPlayers'
  video?: Maybe<Array<Maybe<Scalars['String']>>>
  image?: Maybe<Array<Maybe<Scalars['String']>>>
  audio?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PlayerSpecsPlayersInput = {
  video?: Maybe<Array<Maybe<Scalars['String']>>>
  image?: Maybe<Array<Maybe<Scalars['String']>>>
  audio?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PlayerSpecsStorage = {
  __typename?: 'PlayerSpecsStorage'
  id?: Maybe<Scalars['String']>
  adapter?: Maybe<EnumPlayerSpecsStorageAdapter>
  size?: Maybe<PlayerSpecsStorageSize>
}

export type PlayerSpecsStorageInput = {
  id?: Maybe<Scalars['String']>
  adapter?: Maybe<EnumPlayerSpecsStorageAdapter>
  size?: Maybe<PlayerSpecsStorageSizeInput>
}

export type PlayerSpecsStorageSize = {
  __typename?: 'PlayerSpecsStorageSize'
  total?: Maybe<Scalars['Float']>
  used?: Maybe<Scalars['Float']>
  free?: Maybe<Scalars['Float']>
  available?: Maybe<Scalars['Float']>
}

export type PlayerSpecsStorageSizeInput = {
  total?: Maybe<Scalars['Float']>
  used?: Maybe<Scalars['Float']>
  free?: Maybe<Scalars['Float']>
  available?: Maybe<Scalars['Float']>
}

export type PlayerSpecsViewport = {
  __typename?: 'PlayerSpecsViewport'
  width?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  dpi?: Maybe<Scalars['Float']>
}

export type PlayerSpecsViewportInput = {
  width?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  dpi?: Maybe<Scalars['Float']>
}

export type Point = {
  __typename?: 'Point'
  type?: Maybe<EnumPointType>
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type PointInput = {
  type?: Maybe<EnumPointType>
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type PreSignCreativeUploadResponse = {
  __typename?: 'PreSignCreativeUploadResponse'
  creative_id?: Maybe<Scalars['MongoID']>
  presigned_url?: Maybe<Scalars['String']>
  upload_id?: Maybe<Scalars['MongoID']>
  shred_token?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  FindAreaType?: Maybe<AreaType>
  FindAreaTypes?: Maybe<Array<AreaType>>
  CountAreaTypes?: Maybe<Scalars['Int']>
  FindCampaignAdGroup?: Maybe<CampaignAdGroup>
  FindCampaignAdGroups?: Maybe<Array<CampaignAdGroup>>
  CountCampaignAdGroups?: Maybe<Scalars['Int']>
  FindArchivedCampaignAdGroups?: Maybe<Array<CampaignAdGroup>>
  CountArchivedCampaignAdGroups?: Maybe<Scalars['Int']>
  FindCampaign?: Maybe<Campaign>
  FindExternalCampaign?: Maybe<Campaign>
  FindCampaigns?: Maybe<Array<Campaign>>
  FindExternalCampaigns?: Maybe<Array<Campaign>>
  CountCampaigns?: Maybe<Scalars['Int']>
  FindArchivedCampaigns?: Maybe<Array<Campaign>>
  CountArchivedCampaigns?: Maybe<Scalars['Int']>
  FindCategory?: Maybe<Category>
  FindCategories?: Maybe<Array<Category>>
  CountCategories?: Maybe<Scalars['Int']>
  FindCountry?: Maybe<Country>
  FindCountries?: Maybe<Array<Country>>
  CountCountries?: Maybe<Scalars['Int']>
  FindCreative?: Maybe<Creative>
  FindCreatives?: Maybe<Array<Creative>>
  CountCreatives?: Maybe<Scalars['Int']>
  FindArchivedCreatives?: Maybe<Array<Creative>>
  CountArchivedCreatives?: Maybe<Scalars['Int']>
  FindCurrency?: Maybe<Currency>
  FindCurrencies?: Maybe<Array<Currency>>
  CountCurrencies?: Maybe<Scalars['Int']>
  FindDistanceUnit?: Maybe<DistanceUnit>
  FindDistanceUnits?: Maybe<Array<DistanceUnit>>
  CountDistanceUnits?: Maybe<Scalars['Int']>
  FindLanguage?: Maybe<Language>
  FindLanguages?: Maybe<Array<Language>>
  CountLanguages?: Maybe<Scalars['Int']>
  FindNetwork?: Maybe<Network>
  FindNetworks?: Maybe<Array<Network>>
  FindExternalNetwork?: Maybe<Network>
  FindExternalNetworks?: Maybe<Array<Network>>
  CountNetworks?: Maybe<Scalars['Int']>
  CountExternalNetworks?: Maybe<Scalars['Int']>
  FindArchivedNetworks?: Maybe<Array<Network>>
  CountArchivedNetworks?: Maybe<Scalars['Int']>
  FindPlayer?: Maybe<Player>
  FindPlayers?: Maybe<Array<Player>>
  CountPlayers?: Maybe<Scalars['Int']>
  FindArchivedPlayers?: Maybe<Array<Player>>
  CountArchivedPlayers?: Maybe<Scalars['Int']>
  FindUpload?: Maybe<Upload>
  FindUploads?: Maybe<Array<Upload>>
  CountUploads?: Maybe<Scalars['Int']>
  FindVenueType?: Maybe<VenueType>
  FindVenueTypes?: Maybe<Array<VenueType>>
  CountVenueTypes?: Maybe<Scalars['Int']>
  FindWebhook?: Maybe<Webhook>
  FindWebhooks?: Maybe<Array<Webhook>>
  CountWebhooks?: Maybe<Scalars['Int']>
  FindWorkspaceInvite?: Maybe<WorkspaceInvite>
  FindWorkspaceInvites?: Maybe<Array<WorkspaceInvite>>
  CountWorkspaceInvites?: Maybe<Scalars['Int']>
  FindWorkspace?: Maybe<Workspace>
  FindWorkspaces?: Maybe<Array<Workspace>>
  CountWorkspaces?: Maybe<Scalars['Int']>
  WhoAmI?: Maybe<WhoAmIUserResult>
  PlayerNetworksBeforeArchive?: Maybe<Array<Maybe<Network>>>
  PlayerAffectedResourcesByArchive?: Maybe<PlayerAffectedResourcesByArchive>
  CampaignAnalytics?: Maybe<Array<Maybe<Analytics>>>
  FindManyExternalCampaigns?: Maybe<
    Array<Maybe<FindManyExternalCampaignsResponse>>
  >
  NetworkInviteLinkInfo?: Maybe<NetworkInviteLinkInfo>
  AffectedCampaignsByNetworkRevoke?: Maybe<Array<Maybe<Scalars['String']>>>
  Count?: Maybe<Count>
}

export type QueryFindAreaTypeArgs = {
  _id: Scalars['MongoID']
}

export type QueryFindAreaTypesArgs = {
  filter?: Maybe<FilterFindManyAreaTypeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyAreaTypeInput>
}

export type QueryCountAreaTypesArgs = {
  filter?: Maybe<FilterAreaTypeInput>
}

export type QueryFindCampaignAdGroupArgs = {
  filter?: Maybe<FilterFindOneCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCampaignAdGroupInput>
}

export type QueryFindCampaignAdGroupsArgs = {
  filter?: Maybe<FilterFindManyCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type QueryCountCampaignAdGroupsArgs = {
  filter?: Maybe<FilterCampaignAdGroupInput>
}

export type QueryFindArchivedCampaignAdGroupsArgs = {
  filter?: Maybe<FilterFindManyCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type QueryCountArchivedCampaignAdGroupsArgs = {
  filter?: Maybe<FilterCampaignAdGroupInput>
}

export type QueryFindCampaignArgs = {
  filter?: Maybe<FilterFindOneCampaignInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCampaignInput>
}

export type QueryFindExternalCampaignArgs = {
  filter?: Maybe<FilterFindOneCampaignInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCampaignInput>
}

export type QueryFindCampaignsArgs = {
  filter?: Maybe<FilterFindManyCampaignInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignInput>
}

export type QueryFindExternalCampaignsArgs = {
  filter?: Maybe<FilterFindManyCampaignInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignInput>
}

export type QueryCountCampaignsArgs = {
  filter?: Maybe<FilterCampaignInput>
}

export type QueryFindArchivedCampaignsArgs = {
  filter?: Maybe<FilterFindManyCampaignInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignInput>
}

export type QueryCountArchivedCampaignsArgs = {
  filter?: Maybe<FilterCampaignInput>
}

export type QueryFindCategoryArgs = {
  filter?: Maybe<FilterFindOneCategoryInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCategoryInput>
}

export type QueryFindCategoriesArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCategoryInput>
}

export type QueryCountCategoriesArgs = {
  filter?: Maybe<FilterCategoryInput>
}

export type QueryFindCountryArgs = {
  filter?: Maybe<FilterFindOneCountryInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCountryInput>
}

export type QueryFindCountriesArgs = {
  filter?: Maybe<FilterFindManyCountryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCountryInput>
}

export type QueryCountCountriesArgs = {
  filter?: Maybe<FilterCountryInput>
}

export type QueryFindCreativeArgs = {
  filter?: Maybe<FilterFindOneCreativeInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCreativeInput>
}

export type QueryFindCreativesArgs = {
  filter?: Maybe<FilterFindManyCreativeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCreativeInput>
}

export type QueryCountCreativesArgs = {
  filter?: Maybe<FilterCreativeInput>
}

export type QueryFindArchivedCreativesArgs = {
  filter?: Maybe<FilterFindManyCreativeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCreativeInput>
}

export type QueryCountArchivedCreativesArgs = {
  filter?: Maybe<FilterCreativeInput>
}

export type QueryFindCurrencyArgs = {
  filter?: Maybe<FilterFindOneCurrencyInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCurrencyInput>
}

export type QueryFindCurrenciesArgs = {
  filter?: Maybe<FilterFindManyCurrencyInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCurrencyInput>
}

export type QueryCountCurrenciesArgs = {
  filter?: Maybe<FilterCurrencyInput>
}

export type QueryFindDistanceUnitArgs = {
  filter?: Maybe<FilterFindOneDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneDistanceUnitInput>
}

export type QueryFindDistanceUnitsArgs = {
  filter?: Maybe<FilterFindManyDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyDistanceUnitInput>
}

export type QueryCountDistanceUnitsArgs = {
  filter?: Maybe<FilterDistanceUnitInput>
}

export type QueryFindLanguageArgs = {
  filter?: Maybe<FilterFindOneLanguageInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneLanguageInput>
}

export type QueryFindLanguagesArgs = {
  filter?: Maybe<FilterFindManyLanguageInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyLanguageInput>
}

export type QueryCountLanguagesArgs = {
  filter?: Maybe<FilterLanguageInput>
}

export type QueryFindNetworkArgs = {
  filter?: Maybe<FilterFindOneNetworkInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneNetworkInput>
}

export type QueryFindNetworksArgs = {
  filter?: Maybe<FilterFindManyNetworkInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyNetworkInput>
}

export type QueryFindExternalNetworkArgs = {
  filter?: Maybe<FilterFindOneNetworkInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneNetworkInput>
}

export type QueryFindExternalNetworksArgs = {
  filter?: Maybe<FilterFindManyNetworkInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyNetworkInput>
}

export type QueryCountNetworksArgs = {
  filter?: Maybe<FilterNetworkInput>
}

export type QueryCountExternalNetworksArgs = {
  filter?: Maybe<FilterNetworkInput>
}

export type QueryFindArchivedNetworksArgs = {
  filter?: Maybe<FilterFindManyNetworkInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyNetworkInput>
}

export type QueryCountArchivedNetworksArgs = {
  filter?: Maybe<FilterNetworkInput>
}

export type QueryFindPlayerArgs = {
  filter?: Maybe<FilterFindOnePlayerInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOnePlayerInput>
}

export type QueryFindPlayersArgs = {
  filter?: Maybe<FilterFindManyPlayerInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyPlayerInput>
}

export type QueryCountPlayersArgs = {
  filter?: Maybe<FilterPlayerInput>
}

export type QueryFindArchivedPlayersArgs = {
  filter?: Maybe<FilterFindManyPlayerInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyPlayerInput>
}

export type QueryCountArchivedPlayersArgs = {
  filter?: Maybe<FilterPlayerInput>
}

export type QueryFindUploadArgs = {
  filter?: Maybe<FilterFindOneUploadInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneUploadInput>
}

export type QueryFindUploadsArgs = {
  filter?: Maybe<FilterFindManyUploadInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyUploadInput>
}

export type QueryCountUploadsArgs = {
  filter?: Maybe<FilterUploadInput>
}

export type QueryFindVenueTypeArgs = {
  _id: Scalars['MongoID']
}

export type QueryFindVenueTypesArgs = {
  filter?: Maybe<FilterFindManyVenueTypeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyVenueTypeInput>
}

export type QueryCountVenueTypesArgs = {
  filter?: Maybe<FilterVenueTypeInput>
}

export type QueryFindWebhookArgs = {
  filter?: Maybe<FilterFindOneWebhookInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneWebhookInput>
}

export type QueryFindWebhooksArgs = {
  filter?: Maybe<FilterFindManyWebhookInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookInput>
}

export type QueryCountWebhooksArgs = {
  filter?: Maybe<FilterWebhookInput>
}

export type QueryFindWorkspaceInviteArgs = {
  filter?: Maybe<FilterFindOneWorkspaceInviteInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneWorkspaceInviteInput>
}

export type QueryFindWorkspaceInvitesArgs = {
  filter?: Maybe<FilterFindManyWorkspaceInviteInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWorkspaceInviteInput>
}

export type QueryCountWorkspaceInvitesArgs = {
  filter?: Maybe<FilterWorkspaceInviteInput>
}

export type QueryFindWorkspaceArgs = {
  _id: Scalars['MongoID']
}

export type QueryFindWorkspacesArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsWorkspaceInput>
}

export type QueryCountWorkspacesArgs = {
  filter?: Maybe<FilterWorkspaceInput>
}

export type QueryPlayerNetworksBeforeArchiveArgs = {
  _id: Scalars['MongoID']
}

export type QueryPlayerAffectedResourcesByArchiveArgs = {
  _id: Scalars['MongoID']
}

export type QueryCampaignAnalyticsArgs = {
  _id: Scalars['MongoID']
  intervals?: Maybe<ScansGeoLocationInterval>
}

export type QueryNetworkInviteLinkInfoArgs = {
  invite_token: Scalars['String']
}

export type QueryAffectedCampaignsByNetworkRevokeArgs = {
  network_id: Scalars['MongoID']
  workspace_id: Scalars['MongoID']
}

export type Removed = {
  __typename?: 'Removed'
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
  reason?: Maybe<EnumRemovedReason>
}

export type RemovedInput = {
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
  reason?: Maybe<EnumRemovedReason>
}

export type ScansGeoLocationInterval = {
  starts_at?: Maybe<Scalars['Date']>
  ends_at?: Maybe<Scalars['Date']>
}

export type SecretOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SetupIntentInput = {
  card?: Maybe<PaymentMethodCard>
  billing_details?: Maybe<PaymentMethodBillingDetails>
}

export type SetupIntentObject = {
  __typename?: 'SetupIntentObject'
  id: Scalars['String']
  client_secret: Scalars['String']
  payment_method: Scalars['String']
  status: Scalars['String']
}

export enum SortFindByIdsCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindByIdsWorkspaceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyAreaTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
}

export enum SortFindManyCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyPlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortFindManyUploadInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyVenueTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyWebhookEventInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyWebhookEventResponseInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyWorkspaceInviteInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
}

export enum SortFindOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOnePlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortFindOneUploadInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneWorkspaceInviteInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortUpdateManyWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortUpdateOneCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
}

export enum SortUpdateOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOnePlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortUpdateOneWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export type StatsInterval = {
  __typename?: 'StatsInterval'
  engagement: Scalars['Int']
  reach: Scalars['Int']
}

export type StatsIntervalInput = {
  starts_at: Scalars['Date']
  ends_at: Scalars['Date']
}

export type StripeCustomerIncomingInvoice = {
  __typename?: 'StripeCustomerIncomingInvoice'
  amount_due?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  subscription?: Maybe<Scalars['String']>
  next_payment_attempt?: Maybe<Scalars['Int']>
}

export type StripeCustomerInvoices = {
  __typename?: 'StripeCustomerInvoices'
  data?: Maybe<Array<Maybe<InvoiceData>>>
}

export type StripeCustomerPaymentMethods = {
  __typename?: 'StripeCustomerPaymentMethods'
  data?: Maybe<Array<Maybe<PaymentMethodData>>>
}

export type Subscription = {
  __typename?: 'Subscription'
  OnCreativesStatusChange?: Maybe<OnCreativeStatusChange>
}

export type SubscriptionOnCreativesStatusChangeArgs = {
  _ids: Array<Scalars['MongoID']>
}

export type Timeframe = {
  __typename?: 'Timeframe'
  starts_at?: Maybe<Scalars['Date']>
  ends_at?: Maybe<Scalars['Date']>
}

export type TimeframeInput = {
  starts_at?: Maybe<Scalars['Date']>
  ends_at?: Maybe<Scalars['Date']>
}

export type Timetable = {
  __typename?: 'Timetable'
  rules?: Maybe<Array<Maybe<TimetableRules>>>
  defaultAllowance?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['MongoID']>
}

export type TimetableInput = {
  rules?: Maybe<Array<Maybe<TimetableRulesInput>>>
  defaultAllowance?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['MongoID']>
}

export type TimetableRules = {
  __typename?: 'TimetableRules'
  weekdays?: Maybe<Array<Maybe<Scalars['Float']>>>
  start: Scalars['String']
  end: Scalars['String']
  allowed?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['MongoID']>
}

export type TimetableRulesInput = {
  weekdays?: Maybe<Array<Maybe<Scalars['Float']>>>
  start: Scalars['String']
  end: Scalars['String']
  allowed?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['MongoID']>
}

export type UpdateBroadcastTargetsFromCampaignTargets = {
  ref_id?: Maybe<Scalars['MongoID']>
  type?: Maybe<EnumBroadcastTargetType>
}

export type UpdateManyWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyWebhookPayload = {
  __typename?: 'UpdateManyWebhookPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timetable?: Maybe<TimetableInput>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type UpdateOneCampaignAdGroupPayload = {
  __typename?: 'UpdateOneCampaignAdGroupPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<CampaignAdGroup>
}

export type UpdateOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcast_targets?: Maybe<Array<Maybe<BroadcastTargetInput>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneCampaignPayload = {
  __typename?: 'UpdateOneCampaignPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Campaign>
}

export type UpdateOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneCreativePayload = {
  __typename?: 'UpdateOneCreativePayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Creative>
}

export type UpdateOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  sharedWithWorkspaces?: Maybe<Array<Maybe<NetworkSharedWithWorkspacesInput>>>
}

export type UpdateOneNetworkPayload = {
  __typename?: 'UpdateOneNetworkPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Network>
}

export type UpdateOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  specs?: Maybe<PlayerSpecsInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOnePlayerPayload = {
  __typename?: 'UpdateOnePlayerPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Player>
}

export type UpdateOneWebhookInput = {
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneWebhookPayload = {
  __typename?: 'UpdateOneWebhookPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Webhook>
}

export type Upload = {
  __typename?: 'Upload'
  type: EnumUploadType
  mimetype: Scalars['String']
  status: EnumUploadStatus
  name: Scalars['String']
  versions?: Maybe<Array<Maybe<UploadVersions>>>
  created?: Maybe<ActionDateAndUser>
  uploaded?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getVersions?: Maybe<Array<Maybe<UploadVersions>>>
}

export type UploadGetVersionsArgs = {
  names?: Maybe<Array<Scalars['String']>>
}

export type UploadVersions = {
  __typename?: 'UploadVersions'
  name: Scalars['String']
  mimetype: Scalars['String']
  format?: Maybe<Scalars['String']>
  metadata?: Maybe<UploadVersionsMetadata>
  bytes?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Float']>
  storage_path: Scalars['String']
  public_url?: Maybe<Scalars['String']>
  ipfs_hash?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  processed_at?: Maybe<Scalars['Date']>
}

export type UploadVersionsInput = {
  name: Scalars['String']
  mimetype: Scalars['String']
  format?: Maybe<Scalars['String']>
  metadata?: Maybe<UploadVersionsMetadataInput>
  bytes?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Float']>
  storage_path: Scalars['String']
  public_url?: Maybe<Scalars['String']>
  ipfs_hash?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  processed_at?: Maybe<Scalars['Date']>
}

export type UploadVersionsMetadata = {
  __typename?: 'UploadVersionsMetadata'
  video?: Maybe<UploadVersionsMetadataVideo>
  audio?: Maybe<UploadVersionsMetadataAudio>
}

export type UploadVersionsMetadataAudio = {
  __typename?: 'UploadVersionsMetadataAudio'
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  frequency?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataAudioInput = {
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  frequency?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataInput = {
  video?: Maybe<UploadVersionsMetadataVideoInput>
  audio?: Maybe<UploadVersionsMetadataAudioInput>
}

export type UploadVersionsMetadataVideo = {
  __typename?: 'UploadVersionsMetadataVideo'
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  resolution?: Maybe<Scalars['String']>
  fps?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataVideoInput = {
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  resolution?: Maybe<Scalars['String']>
  fps?: Maybe<Scalars['Float']>
}

export type User = {
  __typename?: 'User'
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserName>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<Address>
  emails?: Maybe<Array<Maybe<UserEmail>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhone>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_Roles>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
}

export type UserEmail = {
  __typename?: 'UserEmail'
  address?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserName = {
  __typename?: 'UserName'
  first: Scalars['String']
  last: Scalars['String']
}

export type UserPhone = {
  __typename?: 'UserPhone'
  number?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserPlatform_Roles = {
  __typename?: 'UserPlatform_roles'
  admin?: Maybe<Scalars['Boolean']>
  curator?: Maybe<Scalars['Boolean']>
}

export type UserSignUpResult = {
  __typename?: 'UserSignUpResult'
  user_id: Scalars['String']
  client_id: Scalars['String']
  access_token: Scalars['String']
  refresh_token: Scalars['String']
}

export type VenueType = {
  __typename?: 'VenueType'
  name: Scalars['String']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id: Scalars['MongoID']
}

export type Webhook = {
  __typename?: 'Webhook'
  name: Scalars['String']
  url: Scalars['String']
  ownership?: Maybe<Ownership>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<Array<Maybe<EnumWebhookEvent_Types>>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  events?: Maybe<Array<WebhookEvent>>
}

export type WebhookEventsArgs = {
  filter?: Maybe<FilterFindManyWebhookEventInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookEventInput>
}

export type WebhookEvent = {
  __typename?: 'WebhookEvent'
  webhook_id: Scalars['MongoID']
  type?: Maybe<Scalars['String']>
  payload?: Maybe<WebhookEventPayload>
  at?: Maybe<Scalars['Date']>
  _id: Scalars['MongoID']
  responses?: Maybe<Array<WebhookEventResponse>>
}

export type WebhookEventResponsesArgs = {
  filter?: Maybe<FilterFindManyWebhookEventResponseInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookEventResponseInput>
}

export type WebhookEventPayload = {
  __typename?: 'WebhookEventPayload'
  workspace_id?: Maybe<Scalars['MongoID']>
  action_by_user_id?: Maybe<Scalars['MongoID']>
  related_to_user_id?: Maybe<Scalars['MongoID']>
  campaign_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id?: Maybe<Scalars['MongoID']>
  creative_id?: Maybe<Scalars['MongoID']>
  network_id?: Maybe<Scalars['MongoID']>
  player_id?: Maybe<Scalars['MongoID']>
  broadcast_plan_id?: Maybe<Scalars['MongoID']>
  webhook_id?: Maybe<Scalars['MongoID']>
  engagement_id?: Maybe<Scalars['MongoID']>
}

export type WebhookEventPayloadInput = {
  workspace_id?: Maybe<Scalars['MongoID']>
  action_by_user_id?: Maybe<Scalars['MongoID']>
  related_to_user_id?: Maybe<Scalars['MongoID']>
  campaign_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id?: Maybe<Scalars['MongoID']>
  creative_id?: Maybe<Scalars['MongoID']>
  network_id?: Maybe<Scalars['MongoID']>
  player_id?: Maybe<Scalars['MongoID']>
  broadcast_plan_id?: Maybe<Scalars['MongoID']>
  webhook_id?: Maybe<Scalars['MongoID']>
  engagement_id?: Maybe<Scalars['MongoID']>
}

export type WebhookEventResponse = {
  __typename?: 'WebhookEventResponse'
  webhook_event_id: Scalars['String']
  url: Scalars['String']
  statusCode?: Maybe<Scalars['Float']>
  body?: Maybe<Scalars['String']>
  headers?: Maybe<Scalars['JSON']>
  _id: Scalars['MongoID']
}

export type WhoAmIUserInviteResult = {
  __typename?: 'WhoAmIUserInviteResult'
  _id: Scalars['MongoID']
  workspace_id: Scalars['MongoID']
  status: Scalars['String']
}

export type WhoAmIUserResult = {
  __typename?: 'WhoAmIUserResult'
  _id: Scalars['MongoID']
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserName>
  platform_roles?: Maybe<UserPlatform_Roles>
  workspaces: Array<Maybe<WhoAmIUserWorkspaceResult>>
  emails: Array<Maybe<UserEmail>>
  phones: Array<Maybe<UserPhone>>
  primary_email_index?: Maybe<Scalars['Int']>
  primary_phone_index?: Maybe<Scalars['Int']>
  invites: Array<Maybe<WhoAmIUserInviteResult>>
}

export type WhoAmIUserWorkspaceResult = {
  __typename?: 'WhoAmIUserWorkspaceResult'
  _id: Scalars['MongoID']
  name: Scalars['String']
  role: Scalars['String']
  billing_email: Scalars['String']
  platform_type: EnumWorkspacePlatform_Type
  added_at?: Maybe<Scalars['Date']>
}

export type Workspace = {
  __typename?: 'Workspace'
  name: Scalars['String']
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
  is_active?: Maybe<Scalars['Boolean']>
  members?: Maybe<Array<Maybe<WorkspaceMembers>>>
  invoice_id?: Maybe<Scalars['String']>
  billing_email?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  payment_methods?: Maybe<StripeCustomerPaymentMethods>
  invoices?: Maybe<StripeCustomerInvoices>
  upcoming_invoice?: Maybe<StripeCustomerIncomingInvoice>
  default_payment_method?: Maybe<Scalars['String']>
  status?: Maybe<EnumWorkspaceStatus>
}

export type WorkspaceGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type WorkspaceInvite = {
  __typename?: 'WorkspaceInvite'
  ownership?: Maybe<Ownership>
  status?: Maybe<EnumWorkspaceInviteStatus>
  accepted?: Maybe<ActionDateAndUser>
  rejected?: Maybe<ActionDateAndUser>
  revoked?: Maybe<ActionDateAndUser>
  created?: Maybe<ActionDateAndUser>
  target?: Maybe<WorkspaceInviteTarget>
  _id: Scalars['MongoID']
}

export type WorkspaceInviteTarget = {
  __typename?: 'WorkspaceInviteTarget'
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['MongoID']>
  name?: Maybe<WorkspaceInviteTargetName>
}

export type WorkspaceInviteTargetInput = {
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['MongoID']>
  name?: Maybe<WorkspaceInviteTargetNameInput>
}

export type WorkspaceInviteTargetName = {
  __typename?: 'WorkspaceInviteTargetName'
  first?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['String']>
}

export type WorkspaceInviteTargetNameInput = {
  first?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['String']>
}

export type WorkspaceMembers = {
  __typename?: 'WorkspaceMembers'
  role?: Maybe<EnumWorkspaceMembersRole>
  user_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  removed?: Maybe<ActionDateAndUser>
  user?: Maybe<User>
  added_by_user?: Maybe<User>
  removed_by_user?: Maybe<User>
}

export type WorkspaceMembersInput = {
  role?: Maybe<EnumWorkspaceMembersRole>
  user_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
  removed?: Maybe<ActionDateAndUserInput>
}

export type WorkspaceUpdateDetailsInput = {
  name: Scalars['String']
  billing_email?: Maybe<Scalars['String']>
}

export type AreaTypeFullFragment = { __typename?: 'AreaType' } & Pick<
  AreaType,
  '_id' | 'name' | 'description'
>

export type VenueTypeFullFragment = { __typename?: 'VenueType' } & Pick<
  VenueType,
  '_id' | 'name'
>

export type ExternalCampaignFullFragment = {
  __typename?: 'FindManyExternalCampaignsResponse'
} & Pick<FindManyExternalCampaignsResponse, 'network_id' | 'network_name'> & {
    campaign?: Maybe<{ __typename?: 'Campaign' } & CampaignFullFragment>
    broadcast_target?: Maybe<
      { __typename?: 'BroadcastTarget' } & BroadcastTargetFullFragment
    >
  }

export type ExternalCampaignListFragment = {
  __typename?: 'FindManyExternalCampaignsResponse'
} & Pick<FindManyExternalCampaignsResponse, 'network_id' | 'network_name'> & {
    campaign?: Maybe<
      { __typename?: 'Campaign' } & Pick<Campaign, '_id' | 'name'> & {
          ownership?: Maybe<
            { __typename?: 'Ownership' } & Pick<
              Ownership,
              'workspace_id' | 'workspace_name'
            >
          >
          ad_groups?: Maybe<
            Array<
              { __typename?: 'CampaignAdGroup' } & Pick<
                CampaignAdGroup,
                '_id'
              > & {
                  timeframe?: Maybe<
                    { __typename?: 'Timeframe' } & Pick<
                      Timeframe,
                      'starts_at' | 'ends_at'
                    >
                  >
                }
            >
          >
        }
    >
    broadcast_target?: Maybe<
      { __typename?: 'BroadcastTarget' } & Pick<
        BroadcastTarget,
        '_id' | 'status' | 'submittedBy'
      > & {
          submitted?: Maybe<
            { __typename?: 'ActionDateAndUser' } & Pick<
              ActionDateAndUser,
              'at' | 'by_user_id'
            >
          >
        }
    >
  }

export type BroadcastTargetFullFragment = {
  __typename?: 'BroadcastTarget'
} & Pick<
  BroadcastTarget,
  '_id' | 'type' | 'status' | 'ref_id' | 'submittedBy' | 'review_notes'
> & {
    player?: Maybe<{ __typename?: 'Player' } & PlayerFullFragment>
    network?: Maybe<{ __typename?: 'Network' } & NetworkFullFragment>
    externalNetwork?: Maybe<{ __typename?: 'Network' } & NetworkFullFragment>
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    submitted?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    approved?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    rejected?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    revoked?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    removed?: Maybe<
      { __typename?: 'Removed' } & Pick<Removed, 'at' | 'reason' | 'by_user_id'>
    >
  }

export type CampaignFullFragment = { __typename?: 'Campaign' } & Pick<
  Campaign,
  '_id' | 'name' | 'status' | 'is_external'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<
        Ownership,
        'workspace_id' | 'workspace_name'
      >
    >
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    broadcast_targets?: Maybe<
      Array<
        Maybe<{ __typename?: 'BroadcastTarget' } & BroadcastTargetFullFragment>
      >
    >
    ad_groups?: Maybe<
      Array<{ __typename?: 'CampaignAdGroup' } & CampaignAdGroupFullFragment>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CampaignListFragment = { __typename?: 'Campaign' } & Pick<
  Campaign,
  '_id' | 'name' | 'status' | 'is_external'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<
        Ownership,
        'workspace_id' | 'workspace_name'
      >
    >
    broadcast_targets?: Maybe<
      Array<
        Maybe<
          { __typename?: 'BroadcastTarget' } & Pick<
            BroadcastTarget,
            '_id' | 'type' | 'ref_id'
          > & {
              removed?: Maybe<
                { __typename?: 'Removed' } & Pick<
                  Removed,
                  'at' | 'reason' | 'by_user_id'
                >
              >
            }
        >
      >
    >
    ad_groups?: Maybe<
      Array<
        { __typename?: 'CampaignAdGroup' } & Pick<CampaignAdGroup, '_id'> & {
            timeframe?: Maybe<
              { __typename?: 'Timeframe' } & Pick<
                Timeframe,
                'starts_at' | 'ends_at'
              >
            >
          }
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
  }

export type CampaignAdGroupFullFragment = {
  __typename?: 'CampaignAdGroup'
} & Pick<
  CampaignAdGroup,
  | '_id'
  | 'is_active'
  | 'name'
  | 'status'
  | 'campaign_id'
  | 'audiences_ids'
  | 'creatives_ids'
  | 'redirect_to'
  | 'saturation'
> & {
    creatives?: Maybe<Array<{ __typename?: 'Creative' } & CreativeListFragment>>
    campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'name'>>
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    timetable?: Maybe<
      { __typename?: 'Timetable' } & Pick<Timetable, 'defaultAllowance'> & {
          rules?: Maybe<
            Array<
              Maybe<
                { __typename?: 'TimetableRules' } & Pick<
                  TimetableRules,
                  'weekdays' | 'start' | 'end' | 'allowed'
                >
              >
            >
          >
        }
    >
    audience?: Maybe<
      { __typename?: 'CampaignAdGroupAudience' } & Pick<
        CampaignAdGroupAudience,
        'area_type' | 'venue_type_ids'
      > & {
          geofences?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Geofence' } & Pick<
                  Geofence,
                  'range' | 'distance_unit_id'
                > & {
                    location?: Maybe<
                      { __typename?: 'Point' } & Pick<
                        Point,
                        'type' | 'coordinates'
                      >
                    >
                  }
              >
            >
          >
        }
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CampaignAdGroupListFragment = {
  __typename?: 'CampaignAdGroup'
} & Pick<
  CampaignAdGroup,
  '_id' | 'name' | 'status' | 'is_active' | 'campaign_id' | 'saturation'
> & {
    audience?: Maybe<
      { __typename?: 'CampaignAdGroupAudience' } & Pick<
        CampaignAdGroupAudience,
        'area_type'
      >
    >
    creatives?: Maybe<
      Array<{ __typename?: 'Creative' } & Pick<Creative, '_id'>>
    >
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
  }

export type CreativeFullFragment = { __typename?: 'Creative' } & Pick<
  Creative,
  '_id' | 'name' | 'type' | 'status' | 'upload_id' | 'categories_ids'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    upload?: Maybe<{ __typename?: 'Upload' } & UploadFullFragment>
    ad_groups?: Maybe<
      Array<{ __typename?: 'CampaignAdGroup' } & CampaignAdGroupFullFragment>
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CreativeListFragment = { __typename?: 'Creative' } & Pick<
  Creative,
  '_id' | 'name' | 'type' | 'status' | 'upload_id'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    upload?: Maybe<{ __typename?: 'Upload' } & UploadMinimumFragment>
  }

export type NetworkFullFragment = { __typename?: 'Network' } & Pick<
  Network,
  '_id' | 'name' | 'status' | 'is_external' | 'invite_token'
> & {
    sharedWithWorkspaces?: Maybe<
      Array<
        Maybe<
          { __typename?: 'NetworkSharedWithWorkspaces' } & Pick<
            NetworkSharedWithWorkspaces,
            'sent_by' | 'workspace_id'
          > & {
              created?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
              revoked?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
            }
        >
      >
    >
    players: Array<
      Maybe<
        { __typename?: 'NetworkPlayers' } & Pick<
          NetworkPlayers,
          'player_id'
        > & {
            added?: Maybe<
              { __typename?: 'ActionDateAndUser' } & Pick<
                ActionDateAndUser,
                'at' | 'by_user_id'
              >
            >
            removed?: Maybe<
              { __typename?: 'Removed' } & Pick<
                Removed,
                'at' | 'reason' | 'by_user_id'
              >
            >
            player?: Maybe<{ __typename?: 'Player' } & PlayerListFragment>
          }
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'by_user_id' | 'at'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
  }

export type NetworkListFragment = { __typename?: 'Network' } & Pick<
  Network,
  '_id' | 'name' | 'status' | 'is_external' | 'invite_token'
> & {
    sharedWithWorkspaces?: Maybe<
      Array<
        Maybe<
          { __typename?: 'NetworkSharedWithWorkspaces' } & Pick<
            NetworkSharedWithWorkspaces,
            'sent_by' | 'workspace_id'
          > & {
              created?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
              revoked?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
            }
        >
      >
    >
    players: Array<
      Maybe<
        { __typename?: 'NetworkPlayers' } & Pick<
          NetworkPlayers,
          'player_id'
        > & {
            added?: Maybe<
              { __typename?: 'ActionDateAndUser' } & Pick<
                ActionDateAndUser,
                'at' | 'by_user_id'
              >
            >
            removed?: Maybe<
              { __typename?: 'Removed' } & Pick<
                Removed,
                'at' | 'reason' | 'by_user_id'
              >
            >
            player?: Maybe<{ __typename?: 'Player' } & PlayerListFragment>
          }
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'by_user_id' | 'at'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
  }

export type PlayerFullFragment = { __typename?: 'Player' } & Pick<
  Player,
  '_id' | 'unique_id' | 'name' | 'status' | 'last_seen_at'
> & {
    capabilities?: Maybe<
      { __typename?: 'PlayerCapabilities' } & {
        playback?: Maybe<
          { __typename?: 'PlayerCapabilitiesPlayback' } & Pick<
            PlayerCapabilitiesPlayback,
            'videos' | 'audios' | 'images'
          >
        >
      }
    >
    demographics?: Maybe<
      { __typename?: 'PlayerDemographics' } & Pick<
        PlayerDemographics,
        'language_ids' | 'country_id' | 'area_type_id' | 'venue_type_ids'
      >
    >
    geographics?: Maybe<
      { __typename?: 'PlayerGeographics' } & {
        location?: Maybe<
          { __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>
        >
        address?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            'street' | 'number' | 'zip' | 'city' | 'state' | 'country_id'
          >
        >
      }
    >
    settings?: Maybe<
      { __typename?: 'PlayerSettings' } & Pick<
        PlayerSettings,
        | 'debug'
        | 'volume'
        | 'brightness'
        | 'orientation'
        | 'density'
        | 'storage_medium_id'
        | 'video_player'
        | 'image_player'
        | 'audio_player'
      > & {
          size?: Maybe<
            { __typename?: 'PlayerSettingsSize' } & Pick<
              PlayerSettingsSize,
              'width' | 'height'
            >
          >
          overscan?: Maybe<
            { __typename?: 'PlayerSettingsOverscan' } & Pick<
              PlayerSettingsOverscan,
              'top' | 'bottom' | 'left' | 'right'
            >
          >
        }
    >
    specs?: Maybe<
      { __typename?: 'PlayerSpecs' } & {
        players?: Maybe<
          { __typename?: 'PlayerSpecsPlayers' } & Pick<
            PlayerSpecsPlayers,
            'video' | 'image' | 'audio'
          >
        >
        storage?: Maybe<
          Array<
            Maybe<
              { __typename?: 'PlayerSpecsStorage' } & Pick<
                PlayerSpecsStorage,
                'id' | 'adapter'
              > & {
                  size?: Maybe<
                    { __typename?: 'PlayerSpecsStorageSize' } & Pick<
                      PlayerSpecsStorageSize,
                      'total' | 'used' | 'free' | 'available'
                    >
                  >
                }
            >
          >
        >
      }
    >
    activated?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'PlayerArchived' } & Pick<
        PlayerArchived,
        'at' | 'why' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type PlayerListFragment = { __typename?: 'Player' } & Pick<
  Player,
  '_id' | 'name' | 'status'
> & {
    demographics?: Maybe<
      { __typename?: 'PlayerDemographics' } & Pick<
        PlayerDemographics,
        'language_ids' | 'country_id' | 'area_type_id' | 'venue_type_ids'
      >
    >
    geographics?: Maybe<
      { __typename?: 'PlayerGeographics' } & {
        location?: Maybe<
          { __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>
        >
        address?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            'street' | 'number' | 'zip' | 'city' | 'state' | 'country_id'
          >
        >
      }
    >
    settings?: Maybe<
      { __typename?: 'PlayerSettings' } & Pick<PlayerSettings, 'orientation'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    archived?: Maybe<
      { __typename?: 'PlayerArchived' } & Pick<
        PlayerArchived,
        'at' | 'why' | 'by_user_id'
      >
    >
  }

export type UploadMinimumFragment = { __typename?: 'Upload' } & Pick<
  Upload,
  '_id' | 'type' | 'status'
> & {
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    versions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UploadVersions' } & Pick<
            UploadVersions,
            'name' | 'duration' | 'public_url'
          >
        >
      >
    >
  }

export type UploadFullFragment = { __typename?: 'Upload' } & Pick<
  Upload,
  '_id' | 'type' | 'status' | 'name'
> & {
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    versions?: Maybe<
      Array<
        Maybe<{ __typename?: 'UploadVersions' } & UploadVersionsFullFragment>
      >
    >
  }

export type UploadVersionsFullFragment = {
  __typename?: 'UploadVersions'
} & Pick<
  UploadVersions,
  | 'name'
  | 'bytes'
  | 'format'
  | 'mimetype'
  | 'duration'
  | 'ipfs_hash'
  | 'public_url'
  | 'storage_path'
> & {
    metadata?: Maybe<
      {
        __typename?: 'UploadVersionsMetadata'
      } & UploadVersionsMetadataFullFragment
    >
  }

export type UploadVersionsMetadataFullFragment = {
  __typename?: 'UploadVersionsMetadata'
} & {
  audio?: Maybe<
    { __typename?: 'UploadVersionsMetadataAudio' } & Pick<
      UploadVersionsMetadataAudio,
      'codec' | 'bitrate' | 'frequency'
    >
  >
  video?: Maybe<
    { __typename?: 'UploadVersionsMetadataVideo' } & Pick<
      UploadVersionsMetadataVideo,
      'fps' | 'codec' | 'bitrate' | 'resolution'
    >
  >
}

export type CountryFullFragment = { __typename?: 'Country' } & Pick<
  Country,
  '_id' | 'name' | 'code'
>

export type WorkspaceFullFragment = { __typename?: 'Workspace' } & Pick<
  Workspace,
  | '_id'
  | 'name'
  | 'billing_email'
  | 'is_active'
  | 'platform_type'
  | 'default_payment_method'
> & {
    members?: Maybe<
      Array<
        Maybe<
          { __typename?: 'WorkspaceMembers' } & Pick<
            WorkspaceMembers,
            'role' | 'user_id'
          > & {
              user?: Maybe<{ __typename?: 'User' } & UserListFragment>
              added?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
              removed?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
            }
        >
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
    payment_methods?: Maybe<
      { __typename?: 'StripeCustomerPaymentMethods' } & {
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: 'paymentMethodData' } & Pick<
                PaymentMethodData,
                'id'
              > & {
                  card?: Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'
                    >
                  >
                  billing_details?: Maybe<
                    { __typename?: 'BillingDetails' } & Pick<
                      BillingDetails,
                      'name'
                    > & {
                        address?: Maybe<
                          { __typename?: 'BillingDetailsAddress' } & Pick<
                            BillingDetailsAddress,
                            | 'city'
                            | 'country'
                            | 'line1'
                            | 'postal_code'
                            | 'state'
                          >
                        >
                      }
                  >
                  metadata?: Maybe<
                    { __typename?: 'PaymentMethodsMetada' } & Pick<
                      PaymentMethodsMetada,
                      'vat' | 'company'
                    >
                  >
                }
            >
          >
        >
      }
    >
    invoices?: Maybe<
      { __typename?: 'StripeCustomerInvoices' } & {
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceData' } & Pick<
                InvoiceData,
                | 'id'
                | 'created'
                | 'invoice_pdf'
                | 'hosted_invoice_url'
                | 'total'
                | 'status'
              >
            >
          >
        >
      }
    >
    upcoming_invoice?: Maybe<
      { __typename?: 'StripeCustomerIncomingInvoice' } & Pick<
        StripeCustomerIncomingInvoice,
        'amount_due' | 'status' | 'next_payment_attempt'
      >
    >
  }

export type UserListFragment = { __typename?: 'User' } & Pick<User, '_id'> & {
    name?: Maybe<{ __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>>
    emails?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserEmail' } & Pick<
            UserEmail,
            'address' | 'verified_at'
          >
        >
      >
    >
  }

export type UserFullFragment = { __typename?: 'User' } & Pick<
  User,
  '_id' | 'is_active' | 'dob' | 'gender' | 'last_seen_at'
> & {
    name?: Maybe<{ __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>>
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'street' | 'number' | 'city' | 'state' | 'zip' | 'country_id'
      >
    >
    phones?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserPhone' } & Pick<
            UserPhone,
            'number' | 'verified_at'
          >
        >
      >
    >
    platform_roles?: Maybe<
      { __typename?: 'UserPlatform_roles' } & Pick<
        UserPlatform_Roles,
        'admin' | 'curator'
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    emails?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserEmail' } & Pick<
            UserEmail,
            'address' | 'verified_at'
          >
        >
      >
    >
  }

export type InviteFullFragment = { __typename?: 'WorkspaceInvite' } & Pick<
  WorkspaceInvite,
  '_id' | 'status'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    target?: Maybe<
      { __typename?: 'WorkspaceInviteTarget' } & Pick<
        WorkspaceInviteTarget,
        'email' | 'user_id'
      > & {
          name?: Maybe<
            { __typename?: 'WorkspaceInviteTargetName' } & Pick<
              WorkspaceInviteTargetName,
              'first' | 'last'
            >
          >
        }
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    revoked?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    rejected?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    accepted?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
  }

export type WebhookFullFragment = { __typename?: 'Webhook' } & Pick<
  Webhook,
  '_id' | 'name' | 'url' | 'type' | 'retries' | 'event_types'
> & {
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    events?: Maybe<
      Array<
        { __typename?: 'WebhookEvent' } & Pick<
          WebhookEvent,
          '_id' | 'at' | 'type' | 'webhook_id'
        > & {
            responses?: Maybe<
              Array<
                { __typename?: 'WebhookEventResponse' } & Pick<
                  WebhookEventResponse,
                  '_id' | 'url' | 'body' | 'statusCode' | 'webhook_event_id'
                >
              >
            >
          }
      >
    >
  }

export type SendEmailVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SendEmailVerificationCodeMutation = { __typename?: 'Mutation' } & {
  isCodeSent: Mutation['AccountEmailSendVerificationCode']
}

export type VerifyEmailCodeMutationVariables = Exact<{
  code: Scalars['String']
  email: Scalars['String']
}>

export type VerifyEmailCodeMutation = { __typename?: 'Mutation' } & {
  isCodeValid: Mutation['AccountEmailVerifyCode']
}

export type UpdateAccountDetailsMutationVariables = Exact<{
  details: AccountUpdateDetailsInput
}>

export type UpdateAccountDetailsMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        name?: Maybe<
          { __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>
        >
      }
  >
}

export type AuthForgotPasswordSendCodeMutationVariables = Exact<{
  method: ForgotPasswordMethod
  identifier: Scalars['String']
}>

export type AuthForgotPasswordSendCodeMutation = { __typename?: 'Mutation' } & {
  codeSent: Mutation['AuthForgotPasswordSendCode']
}

export type AuthForgotPasswordVerifyCodeMutationVariables = Exact<{
  method: ForgotPasswordMethod
  identifier: Scalars['String']
  code: Scalars['String']
}>

export type AuthForgotPasswordVerifyCodeMutation = {
  __typename?: 'Mutation'
} & { recoveryToken: Mutation['AuthForgotPasswordVerifyCode'] }

export type AuthForgotPasswordResetMutationVariables = Exact<{
  recoveryToken: Scalars['String']
  password: Scalars['String']
}>

export type AuthForgotPasswordResetMutation = { __typename?: 'Mutation' } & {
  authAccess?: Maybe<
    { __typename?: 'AuthAccess' } & Pick<
      AuthAccess,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type AuthRefreshMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type AuthRefreshMutation = { __typename?: 'Mutation' } & {
  authRefreshResult?: Maybe<
    { __typename?: 'AuthRefreshResult' } & Pick<
      AuthRefreshResult,
      'accessToken' | 'refreshToken'
    >
  >
}

export type AuthSignInMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthSignInMutation = { __typename?: 'Mutation' } & {
  tokens?: Maybe<
    { __typename?: 'AuthAccess' } & Pick<
      AuthAccess,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type AuthSignOutMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type AuthSignOutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'AuthSignOut'
>

export type AuthSignUpMutationVariables = Exact<{
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthSignUpMutation = { __typename?: 'Mutation' } & {
  tokens?: Maybe<
    { __typename?: 'UserSignUpResult' } & Pick<
      UserSignUpResult,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type ArchiveCreativeMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCreativeMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchiveCreatives']
}

export type PreSignCreativeUploadMutationVariables = Exact<{
  filename: Scalars['String']
  mimetype: Scalars['String']
  creative_type: EnumCreativeType
}>

export type PreSignCreativeUploadMutation = { __typename?: 'Mutation' } & {
  upload?: Maybe<
    { __typename?: 'PreSignCreativeUploadResponse' } & Pick<
      PreSignCreativeUploadResponse,
      'upload_id' | 'creative_id' | 'presigned_url' | 'shred_token'
    >
  >
}

export type NotifyCreativeUploadCompletedMutationVariables = Exact<{
  creative_id: Scalars['MongoID']
}>

export type NotifyCreativeUploadCompletedMutation = {
  __typename?: 'Mutation'
} & { isNotified: Mutation['NotifyCreativeUploadCompleted'] }

export type CreateCampaignMutationVariables = Exact<{
  input: CreateOneCampaignInput
}>

export type CreateCampaignMutation = { __typename?: 'Mutation' } & {
  campaign?: Maybe<
    { __typename?: 'CreateOneCampaignPayload' } & {
      _id: CreateOneCampaignPayload['recordId']
    }
  >
}

export type UpdateCampaignByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCampaignInput
}>

export type UpdateCampaignByIdMutation = { __typename?: 'Mutation' } & {
  campaign?: Maybe<
    { __typename?: 'UpdateOneCampaignPayload' } & {
      _id: UpdateOneCampaignPayload['recordId']
    }
  >
}

export type UpdateCampaignActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateCampaignActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['SetIsActiveCampaigns']
}

export type ArchiveCampaignMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCampaignMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchiveCampaigns']
}

export type ShredCreativeMutationVariables = Exact<{
  id: Scalars['MongoID']
  shred_token: Scalars['String']
}>

export type ShredCreativeMutation = { __typename?: 'Mutation' } & {
  isShredded: Mutation['ShredCreative']
}

export type UpdateCampaignAdGroupByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCampaignAdGroupInput
}>

export type UpdateCampaignAdGroupByIdMutation = { __typename?: 'Mutation' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'UpdateOneCampaignAdGroupPayload' } & {
      _id: UpdateOneCampaignAdGroupPayload['recordId']
    }
  >
}

export type UpdateCampaignAdGroupActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateCampaignAdGroupActivationMutation = {
  __typename?: 'Mutation'
} & { areUpdated: Mutation['SetIsActiveCampaignAdGroups'] }

export type ArchiveCampaignAdGroupMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCampaignAdGroupMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchiveCampaignAdGroups']
}

export type CreateCampaignAdGroupMutationVariables = Exact<{
  input: CreateOneCampaignAdGroupInput
}>

export type CreateCampaignAdGroupMutation = { __typename?: 'Mutation' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'CreateOneCampaignAdGroupPayload' } & {
      _id: CreateOneCampaignAdGroupPayload['recordId']
    }
  >
}

export type CreateNetworkMutationVariables = Exact<{
  input: CreateOneNetworkInput
}>

export type CreateNetworkMutation = { __typename?: 'Mutation' } & {
  network?: Maybe<
    { __typename?: 'CreateOneNetworkPayload' } & {
      _id: CreateOneNetworkPayload['recordId']
    }
  >
}

export type UpdateNetworkByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneNetworkInput
}>

export type UpdateNetworkByIdMutation = { __typename?: 'Mutation' } & {
  network?: Maybe<
    { __typename?: 'UpdateOneNetworkPayload' } & {
      _id: UpdateOneNetworkPayload['recordId']
    }
  >
}

export type UpdateNetworkActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateNetworkActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['SetIsActiveNetworks']
}

export type ArchiveNetworkMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveNetworkMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchiveNetworks']
}

export type AddExternalNetworkToWorkspacesMutationVariables = Exact<{
  invite_token: Scalars['String']
  ids: Array<Scalars['MongoID']>
}>

export type AddExternalNetworkToWorkspacesMutation = {
  __typename?: 'Mutation'
} & { areAdded: Mutation['WorkspaceAddExternalNetwork'] }

export type BindPlayerMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>
}>

export type BindPlayerMutation = { __typename?: 'Mutation' } & {
  player?: Maybe<{ __typename?: 'Player' } & Pick<Player, '_id' | 'name'>>
}

export type AddPlayerToNetworksMutationVariables = Exact<{
  player_id: Scalars['MongoID']
  network_ids: Array<Scalars['MongoID']>
}>

export type AddPlayerToNetworksMutation = { __typename?: 'Mutation' } & {
  wasAddedByNetwork: Mutation['AddPlayerToNetworks']
}

export type FinishPlayerSetupMutationVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type FinishPlayerSetupMutation = { __typename?: 'Mutation' } & {
  activated: Mutation['FinishPlayerSetup']
}

export type UpdatePlayerByIdMutationVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  _id: Scalars['MongoID']
  input: UpdateOnePlayerInput
}>

export type UpdatePlayerByIdMutation = { __typename?: 'Mutation' } & {
  player?: Maybe<
    { __typename?: 'UpdateOnePlayerPayload' } & {
      _id: UpdateOnePlayerPayload['recordId']
    }
  >
}

export type UpdatePlayerActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdatePlayerActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['SetIsActivePlayers']
}

export type ArchivePlayerMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  reason?: Maybe<Scalars['String']>
}>

export type ArchivePlayerMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchivePlayers']
}

export type SetupWorkspaceMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>
  billing_email?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
}>

export type SetupWorkspaceMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & Pick<
      Workspace,
      '_id' | 'name' | 'billing_email' | 'platform_type'
    >
  >
}

export type UpdateWorkspaceByIdMutationVariables = Exact<{
  _id: Scalars['String']
  details: WorkspaceUpdateDetailsInput
}>

export type UpdateWorkspaceByIdMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & Pick<
      Workspace,
      '_id' | 'name' | 'billing_email' | 'platform_type'
    >
  >
}

export type AccountEmailAddMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailAddMutation = { __typename?: 'Mutation' } & {
  added: Mutation['AccountEmailAdd']
}

export type AccountEmailVerifyCodeMutationVariables = Exact<{
  email: Scalars['String']
  code: Scalars['String']
}>

export type AccountEmailVerifyCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'AccountEmailVerifyCode'
>

export type AccountEmailSendVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailSendVerificationCodeMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'AccountEmailSendVerificationCode'>

export type AccountEmailSetPrimaryMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailSetPrimaryMutation = { __typename?: 'Mutation' } & {
  isPrimary: Mutation['AccountEmailSetPrimary']
}

export type AccountEmailRemoveMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailRemoveMutation = { __typename?: 'Mutation' } & {
  removed: Mutation['AccountEmailRemove']
}

export type AccountChangePasswordMutationVariables = Exact<{
  refreshToken: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}>

export type AccountChangePasswordMutation = { __typename?: 'Mutation' } & {
  isUpdated: Mutation['AccountChangePassword']
}

export type AddWorkspaceMemberMutationVariables = Exact<{
  emails: Array<Scalars['String']>
}>

export type AddWorkspaceMemberMutation = { __typename?: 'Mutation' } & {
  addedMember: Array<
    { __typename?: 'WorkspaceInvite' } & Pick<WorkspaceInvite, '_id'>
  >
}

export type RemoveWorkspaceMemberMutationVariables = Exact<{
  email: Scalars['String']
}>

export type RemoveWorkspaceMemberMutation = { __typename?: 'Mutation' } & {
  removedMember: Mutation['RemoveWorkspaceMember']
}

export type InviteToWorkspaceMutationVariables = Exact<{
  emails: Array<Scalars['String']>
}>

export type InviteToWorkspaceMutation = { __typename?: 'Mutation' } & {
  isInviteSent: Array<{ __typename?: 'WorkspaceInvite' } & InviteFullFragment>
}

export type AcceptWorkspaceInvitesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type AcceptWorkspaceInvitesMutation = { __typename?: 'Mutation' } & {
  invites: Array<{ __typename?: 'WorkspaceInvite' } & InviteFullFragment>
}

export type RejectWorkspaceInvitesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RejectWorkspaceInvitesMutation = { __typename?: 'Mutation' } & {
  invites: Array<{ __typename?: 'WorkspaceInvite' } & InviteFullFragment>
}

export type RevokeWorkspaceInvitesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RevokeWorkspaceInvitesMutation = { __typename?: 'Mutation' } & {
  cancelledInvite: Array<
    { __typename?: 'WorkspaceInvite' } & InviteFullFragment
  >
}

export type RevokeWorkspaceAccessMutationVariables = Exact<{
  networkId: Scalars['MongoID']
  workspaceId: Scalars['MongoID']
}>

export type RevokeWorkspaceAccessMutation = { __typename?: 'Mutation' } & {
  isRevoked: Mutation['NetworkRevokeWorkspaceAccess']
}

export type WorkspaceSetupIntentMutationVariables = Exact<{
  setupIntentArgs?: Maybe<SetupIntentInput>
}>

export type WorkspaceSetupIntentMutation = { __typename?: 'Mutation' } & {
  setupIntent: { __typename?: 'SetupIntentObject' } & Pick<
    SetupIntentObject,
    'id' | 'client_secret' | 'payment_method' | 'status'
  >
}

export type WorkspaceAddPaymentMethodMutationVariables = Exact<{
  paymentMethod?: Maybe<PaymentMethodInput>
}>

export type WorkspaceAddPaymentMethodMutation = { __typename?: 'Mutation' } & {
  paymentMethod: Mutation['WorkspaceAddPaymentMethod']
}

export type WorkspaceSetDefaultPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']
}>

export type WorkspaceSetDefaultPaymentMethodMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'WorkspaceSetDefaultPaymentMethod'>

export type WorkspaceRemovePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']
}>

export type WorkspaceRemovePaymentMethodMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'WorkspaceRemovePaymentMethod'>

export type WorkspacePayInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']
}>

export type WorkspacePayInvoiceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'WorkspacePayInvoice'
>

export type CreateWebhookMutationVariables = Exact<{
  input: CreateOneWebhookInput
}>

export type CreateWebhookMutation = { __typename?: 'Mutation' } & {
  webhook?: Maybe<
    { __typename?: 'CreateOneWebhookPayload' } & {
      _id: CreateOneWebhookPayload['recordId']
    }
  >
}

export type UpdateWebhookByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneWebhookInput
}>

export type UpdateWebhookByIdMutation = { __typename?: 'Mutation' } & {
  webhook?: Maybe<
    { __typename?: 'UpdateOneWebhookPayload' } & {
      _id: UpdateOneWebhookPayload['recordId']
    }
  >
}

export type ArchiveWebhookMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveWebhookMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['ArchiveWebhooks']
}

export type UpdateCreativeByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCreativeInput
}>

export type UpdateCreativeByIdMutation = { __typename?: 'Mutation' } & {
  creative?: Maybe<
    { __typename?: 'UpdateOneCreativePayload' } & {
      _id: UpdateOneCreativePayload['recordId']
    }
  >
}

export type RestoreCampaignsMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RestoreCampaignsMutation = { __typename?: 'Mutation' } & {
  areRestored: Mutation['UnarchiveCampaigns']
}

export type RestoreCampaignAdGroupsMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RestoreCampaignAdGroupsMutation = { __typename?: 'Mutation' } & {
  areRestored: Mutation['UnarchiveCampaignAdGroups']
}

export type RestoreCreativesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RestoreCreativesMutation = { __typename?: 'Mutation' } & {
  areRestored: Mutation['UnarchiveCreatives']
}

export type RestoreNetworksMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RestoreNetworksMutation = { __typename?: 'Mutation' } & {
  areRestored: Mutation['UnarchiveNetworks']
}

export type RestorePlayerMutationVariables = Exact<{
  id: Scalars['MongoID']
  network_ids: Array<Maybe<Scalars['MongoID']>>
}>

export type RestorePlayerMutation = { __typename?: 'Mutation' } & {
  isRestored: Mutation['UnarchivePlayer']
}

export type ManagePlayersByNetworkMutationVariables = Exact<{
  network_id: Scalars['MongoID']
  players_ids: Array<Scalars['MongoID']>
}>

export type ManagePlayersByNetworkMutation = { __typename?: 'Mutation' } & {
  isUpdated: Mutation['UpdatePlayersFromNetwork']
}

export type ManageBroadcastTargetsByCampaignMutationVariables = Exact<{
  _id: Scalars['MongoID']
  targets: Array<UpdateBroadcastTargetsFromCampaignTargets>
}>

export type ManageBroadcastTargetsByCampaignMutation = {
  __typename?: 'Mutation'
} & { isUpdated: Mutation['UpdateBroadcastTargetsFromCampaign'] }

export type CancelBroadcastTargetsMutationVariables = Exact<{
  campaign_id: Scalars['MongoID']
  broadcastTargetIds: Array<Scalars['MongoID']>
}>

export type CancelBroadcastTargetsMutation = { __typename?: 'Mutation' } & {
  areCancelled: Mutation['CancelBroadcastTargets']
}

export type SubmitBroadcastTargetsMutationVariables = Exact<{
  campaign_id: Scalars['MongoID']
  broadcastTargetIds: Array<Scalars['MongoID']>
}>

export type SubmitBroadcastTargetsMutation = { __typename?: 'Mutation' } & {
  areSubmitted: Mutation['SubmitBroadcastTargets']
}

export type RevokeBroadcastTargetsMutationVariables = Exact<{
  campaign_id: Scalars['MongoID']
  broadcastTargetIds: Array<Scalars['MongoID']>
}>

export type RevokeBroadcastTargetsMutation = { __typename?: 'Mutation' } & {
  areRevoked: Mutation['RevokeBroadcastTargets']
}

export type ClearBroadcastTargetsMutationVariables = Exact<{
  campaign_id: Scalars['MongoID']
  broadcastTargetIds: Array<Scalars['MongoID']>
}>

export type ClearBroadcastTargetsMutation = { __typename?: 'Mutation' } & {
  areCleared: Mutation['ClearBroadcastTargets']
}

export type ApproveExternalCampaignMutationVariables = Exact<{
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
}>

export type ApproveExternalCampaignMutation = { __typename?: 'Mutation' } & {
  isApproved: Mutation['ApproveExternalCampaign']
}

export type RejectExternalCampaignMutationVariables = Exact<{
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
  reviewNotes?: Maybe<Scalars['String']>
}>

export type RejectExternalCampaignMutation = { __typename?: 'Mutation' } & {
  isRejected: Mutation['RejectExternalCampaign']
}

export type RevokeExternalCampaignMutationVariables = Exact<{
  network_id: Scalars['MongoID']
  campaign_id: Scalars['MongoID']
  reviewNotes?: Maybe<Scalars['String']>
}>

export type RevokeExternalCampaignMutation = { __typename?: 'Mutation' } & {
  isRevoked: Mutation['RevokeExternalCampaign']
}

export type CountItemsQueryVariables = Exact<{ [key: string]: never }>

export type CountItemsQuery = { __typename?: 'Query' } & {
  campaigns: Query['CountCampaigns']
  adGroups: Query['CountCampaignAdGroups']
  creatives: Query['CountCreatives']
  networks: Query['CountNetworks']
  players: Query['CountPlayers']
}

export type CountCreativesQueryVariables = Exact<{
  filter?: FilterCreativeInput
}>

export type CountCreativesQuery = { __typename?: 'Query' } & {
  creatives: Query['CountCreatives']
}

export type CountCampaignsQueryVariables = Exact<{
  filter?: FilterCampaignInput
}>

export type CountCampaignsQuery = { __typename?: 'Query' } & {
  campaigns: Query['CountCampaigns']
}

export type CountNetworksQueryVariables = Exact<{
  filter?: FilterNetworkInput
}>

export type CountNetworksQuery = { __typename?: 'Query' } & {
  networks: Query['CountNetworks']
}

export type CountPlayersQueryVariables = Exact<{
  filter?: FilterPlayerInput
}>

export type CountPlayersQuery = { __typename?: 'Query' } & {
  players: Query['CountPlayers']
}

export type CountCampaignAdGroupsQueryVariables = Exact<{
  filter?: FilterCampaignAdGroupInput
}>

export type CountCampaignAdGroupsQuery = { __typename?: 'Query' } & {
  adGroups: Query['CountCampaignAdGroups']
}

export type CountActiveItemsQueryVariables = Exact<{ [key: string]: never }>

export type CountActiveItemsQuery = { __typename?: 'Query' } & {
  count?: Maybe<
    { __typename?: 'Count' } & Pick<
      Count,
      'players' | 'networks' | 'creatives' | 'campaigns'
    >
  >
}

export type CountArchivedItemsQueryVariables = Exact<{ [key: string]: never }>

export type CountArchivedItemsQuery = { __typename?: 'Query' } & {
  campaigns: Query['CountArchivedCampaigns']
  adGroups: Query['CountArchivedCampaignAdGroups']
  creatives: Query['CountArchivedCreatives']
  networks: Query['CountArchivedNetworks']
  players: Query['CountArchivedPlayers']
}

export type CountArchivedCreativesQueryVariables = Exact<{
  filter?: FilterCreativeInput
}>

export type CountArchivedCreativesQuery = { __typename?: 'Query' } & {
  creatives: Query['CountArchivedCreatives']
}

export type CountArchivedCampaignsQueryVariables = Exact<{
  filter?: FilterCampaignInput
}>

export type CountArchivedCampaignsQuery = { __typename?: 'Query' } & {
  campaigns: Query['CountArchivedCampaigns']
}

export type CountArchivedNetworksQueryVariables = Exact<{
  filter?: FilterNetworkInput
}>

export type CountArchivedNetworksQuery = { __typename?: 'Query' } & {
  networks: Query['CountArchivedNetworks']
}

export type CountArchivedPlayersQueryVariables = Exact<{
  filter?: FilterPlayerInput
}>

export type CountArchivedPlayersQuery = { __typename?: 'Query' } & {
  players: Query['CountArchivedPlayers']
}

export type CountArchivedCampaignAdGroupsQueryVariables = Exact<{
  filter?: FilterCampaignAdGroupInput
}>

export type CountArchivedCampaignAdGroupsQuery = { __typename?: 'Query' } & {
  adGroups: Query['CountArchivedCampaignAdGroups']
}

export type CountExternalNetworksQueryVariables = Exact<{
  filter?: FilterNetworkInput
}>

export type CountExternalNetworksQuery = { __typename?: 'Query' } & {
  networks: Query['CountExternalNetworks']
}

export type WhoAmIQueryVariables = Exact<{ [key: string]: never }>

export type WhoAmIQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'WhoAmIUserResult' } & Pick<
      WhoAmIUserResult,
      '_id' | 'is_active' | 'primary_email_index' | 'primary_phone_index'
    > & {
        name?: Maybe<
          { __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>
        >
        platform_roles?: Maybe<
          { __typename?: 'UserPlatform_roles' } & Pick<
            UserPlatform_Roles,
            'admin' | 'curator'
          >
        >
        workspaces: Array<
          Maybe<
            { __typename?: 'WhoAmIUserWorkspaceResult' } & Pick<
              WhoAmIUserWorkspaceResult,
              | '_id'
              | 'name'
              | 'role'
              | 'added_at'
              | 'billing_email'
              | 'platform_type'
            >
          >
        >
        invites: Array<
          Maybe<
            { __typename?: 'WhoAmIUserInviteResult' } & Pick<
              WhoAmIUserInviteResult,
              '_id' | 'status' | 'workspace_id'
            >
          >
        >
        phones: Array<
          Maybe<
            { __typename?: 'UserPhone' } & Pick<
              UserPhone,
              'number' | 'verified_at'
            >
          >
        >
        emails: Array<
          Maybe<
            { __typename?: 'UserEmail' } & Pick<
              UserEmail,
              'address' | 'verified_at'
            >
          >
        >
      }
  >
}

export type ViewAreaTypeQueryVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type ViewAreaTypeQuery = { __typename?: 'Query' } & {
  areaType?: Maybe<{ __typename?: 'AreaType' } & AreaTypeFullFragment>
}

export type ListAreaTypesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyAreaTypeInput>
  filter?: Maybe<FilterFindManyAreaTypeInput>
}>

export type ListAreaTypesQuery = { __typename?: 'Query' } & {
  areaTypes?: Maybe<Array<{ __typename?: 'AreaType' } & AreaTypeFullFragment>>
}

export type ViewVenueTypeQueryVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type ViewVenueTypeQuery = { __typename?: 'Query' } & {
  venueType?: Maybe<{ __typename?: 'VenueType' } & VenueTypeFullFragment>
}

export type ListVenueTypesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyVenueTypeInput>
  filter?: Maybe<FilterFindManyVenueTypeInput>
}>

export type ListVenueTypesQuery = { __typename?: 'Query' } & {
  venueTypes?: Maybe<
    Array<{ __typename?: 'VenueType' } & VenueTypeFullFragment>
  >
}

export type ViewCampaignQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCampaignQuery = { __typename?: 'Query' } & {
  campaign?: Maybe<{ __typename?: 'Campaign' } & CampaignFullFragment>
}

export type ViewExternalCampaignQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewExternalCampaignQuery = { __typename?: 'Query' } & {
  campaign?: Maybe<{ __typename?: 'Campaign' } & CampaignFullFragment>
}

export type ListCampaignsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignInput
}>

export type ListCampaignsQuery = { __typename?: 'Query' } & {
  campaigns?: Maybe<Array<{ __typename?: 'Campaign' } & CampaignListFragment>>
}

export type ListArchivedCampaignsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignInput
}>

export type ListArchivedCampaignsQuery = { __typename?: 'Query' } & {
  campaigns?: Maybe<Array<{ __typename?: 'Campaign' } & CampaignListFragment>>
}

export type ListExternalCampaignsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListExternalCampaignsQuery = { __typename?: 'Query' } & {
  campaigns?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'FindManyExternalCampaignsResponse'
        } & ExternalCampaignListFragment
      >
    >
  >
}

export type ListCampaignsByRevokedNetworkQueryVariables = Exact<{
  network_id: Scalars['MongoID']
  workspace_id: Scalars['MongoID']
}>

export type ListCampaignsByRevokedNetworkQuery = { __typename?: 'Query' } & {
  campaigns: Query['AffectedCampaignsByNetworkRevoke']
}

export type ViewCampaignAdGroupQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCampaignAdGroupQuery = { __typename?: 'Query' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'CampaignAdGroup' } & CampaignAdGroupFullFragment
  >
}

export type ListCampaignAdGroupsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignAdGroupInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListCampaignAdGroupsQuery = { __typename?: 'Query' } & {
  campaignAdGroups?: Maybe<
    Array<{ __typename?: 'CampaignAdGroup' } & CampaignAdGroupListFragment>
  >
}

export type ListArchivedCampaignAdGroupsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignAdGroupInput
}>

export type ListArchivedCampaignAdGroupsQuery = { __typename?: 'Query' } & {
  adGroups?: Maybe<
    Array<{ __typename?: 'CampaignAdGroup' } & CampaignAdGroupListFragment>
  >
}

export type ViewCampaignAnalyticsQueryVariables = Exact<{
  _id: Scalars['MongoID']
  interval: ScansGeoLocationInterval
}>

export type ViewCampaignAnalyticsQuery = { __typename?: 'Query' } & {
  analytics?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Analytics' } & Pick<
          Analytics,
          '_id' | 'name' | 'date'
        > & {
            locations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'CoordinatesData' } & Pick<
                    CoordinatesData,
                    'scans' | 'impressions' | 'coordinates'
                  >
                >
              >
            >
          }
      >
    >
  >
}

export type ViewCreativeQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCreativeQuery = { __typename?: 'Query' } & {
  creative?: Maybe<{ __typename?: 'Creative' } & CreativeFullFragment>
}

export type ListCreativesQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCreativeInput
}>

export type ListCreativesQuery = { __typename?: 'Query' } & {
  creatives?: Maybe<Array<{ __typename?: 'Creative' } & CreativeListFragment>>
}

export type ListArchivedCreativesQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCreativeInput
}>

export type ListArchivedCreativesQuery = { __typename?: 'Query' } & {
  creatives?: Maybe<Array<{ __typename?: 'Creative' } & CreativeListFragment>>
}

export type ViewNetworkQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewNetworkQuery = { __typename?: 'Query' } & {
  network?: Maybe<{ __typename?: 'Network' } & NetworkFullFragment>
}

export type ViewExternalNetworkQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewExternalNetworkQuery = { __typename?: 'Query' } & {
  network?: Maybe<{ __typename?: 'Network' } & NetworkFullFragment>
}

export type ViewNetworkShareLinkQueryVariables = Exact<{
  invite_token: Scalars['String']
}>

export type ViewNetworkShareLinkQuery = { __typename?: 'Query' } & {
  info?: Maybe<
    { __typename?: 'NetworkInviteLinkInfo' } & Pick<
      NetworkInviteLinkInfo,
      'sent_by' | 'network_id' | 'network_name'
    >
  >
}

export type ListNetworksQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyNetworkInput
}>

export type ListNetworksQuery = { __typename?: 'Query' } & {
  networks?: Maybe<Array<{ __typename?: 'Network' } & NetworkListFragment>>
}

export type ListExternalNetworksQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyNetworkInput
}>

export type ListExternalNetworksQuery = { __typename?: 'Query' } & {
  networks?: Maybe<Array<{ __typename?: 'Network' } & NetworkListFragment>>
}

export type ListArchivedNetworksQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyNetworkInput
}>

export type ListArchivedNetworksQuery = { __typename?: 'Query' } & {
  networks?: Maybe<Array<{ __typename?: 'Network' } & NetworkListFragment>>
}

export type ViewPlayerQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewPlayerQuery = { __typename?: 'Query' } & {
  player?: Maybe<{ __typename?: 'Player' } & PlayerFullFragment>
}

export type ListPlayersQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyPlayerInput
}>

export type ListPlayersQuery = { __typename?: 'Query' } & {
  players?: Maybe<Array<{ __typename?: 'Player' } & PlayerListFragment>>
}

export type ListArchivedPlayersQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyPlayerInput
}>

export type ListArchivedPlayersQuery = { __typename?: 'Query' } & {
  players?: Maybe<Array<{ __typename?: 'Player' } & PlayerListFragment>>
}

export type ViewCountryQueryVariables = Exact<{
  _id?: Maybe<Scalars['MongoID']>
  code?: Maybe<Scalars['String']>
}>

export type ViewCountryQuery = { __typename?: 'Query' } & {
  country?: Maybe<{ __typename?: 'Country' } & CountryFullFragment>
}

export type ListCountriesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  filter?: Maybe<FilterFindManyCountryInput>
}>

export type ListCountriesQuery = { __typename?: 'Query' } & {
  countries?: Maybe<Array<{ __typename?: 'Country' } & CountryFullFragment>>
}

export type ViewWorkspaceQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewWorkspaceQuery = { __typename?: 'Query' } & {
  workspace?: Maybe<{ __typename?: 'Workspace' } & WorkspaceFullFragment>
}

export type ListWorkspacesQueryVariables = Exact<{
  _ids: Array<Maybe<Scalars['MongoID']>>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsWorkspaceInput>
  intervals?: Array<StatsIntervalInput>
}>

export type ListWorkspacesQuery = { __typename?: 'Query' } & {
  workspaces?: Maybe<
    Array<{ __typename?: 'Workspace' } & WorkspaceFullFragment>
  >
}

export type GetWorkspaceStatsQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals: Array<StatsIntervalInput>
}>

export type GetWorkspaceStatsQuery = { __typename?: 'Query' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & {
      stats: Array<
        { __typename?: 'StatsInterval' } & Pick<
          StatsInterval,
          'reach' | 'engagement'
        >
      >
    }
  >
}

export type ListInvitesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWorkspaceInviteInput>
  filter?: Maybe<FilterFindManyWorkspaceInviteInput>
}>

export type ListInvitesQuery = { __typename?: 'Query' } & {
  invites?: Maybe<
    Array<{ __typename?: 'WorkspaceInvite' } & InviteFullFragment>
  >
}

export type ViewWebhookQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneWebhookInput>
  filter?: Maybe<FilterFindOneWebhookInput>
}>

export type ViewWebhookQuery = { __typename?: 'Query' } & {
  webhook?: Maybe<{ __typename?: 'Webhook' } & WebhookFullFragment>
}

export type ListWebhooksQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookInput>
  filter?: Maybe<FilterFindManyWebhookInput>
}>

export type ListWebhooksQuery = { __typename?: 'Query' } & {
  webhooks?: Maybe<Array<{ __typename?: 'Webhook' } & WebhookFullFragment>>
}

export type ViewCategoryQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCategoryInput>
  filter?: Maybe<FilterFindOneCategoryInput>
}>

export type ViewCategoryQuery = { __typename?: 'Query' } & {
  category?: Maybe<{ __typename?: 'Category' } & Pick<Category, '_id' | 'name'>>
}

export type ListCategoriesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCategoryInput>
  filter?: Maybe<FilterFindManyCategoryInput>
}>

export type ListCategoriesQuery = { __typename?: 'Query' } & {
  categories?: Maybe<
    Array<{ __typename?: 'Category' } & Pick<Category, '_id' | 'name'>>
  >
}

export type AffectedResourcesByArchiveQueryVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type AffectedResourcesByArchiveQuery = { __typename?: 'Query' } & {
  resources?: Maybe<
    { __typename?: 'PlayerAffectedResourcesByArchive' } & {
      networks?: Maybe<
        Array<Maybe<{ __typename?: 'Network' } & Pick<Network, 'name'>>>
      >
    }
  >
}

export type OnCreativesStatusChangeSubscriptionVariables = Exact<{
  _ids: Array<Scalars['MongoID']>
}>

export type OnCreativesStatusChangeSubscription = {
  __typename?: 'Subscription'
} & {
  creatives?: Maybe<
    { __typename?: 'OnCreativeStatusChange' } & Pick<
      OnCreativeStatusChange,
      'creative_id'
    >
  >
}

export const AreaTypeFullFragmentDoc = gql`
  fragment AreaTypeFull on AreaType {
    _id
    name
    description
  }
`
export const VenueTypeFullFragmentDoc = gql`
  fragment VenueTypeFull on VenueType {
    _id
    name
  }
`
export const PlayerFullFragmentDoc = gql`
  fragment PlayerFull on Player {
    _id
    unique_id
    name
    status
    capabilities {
      playback {
        videos
        audios
        images
      }
    }
    demographics {
      language_ids
      country_id
      area_type_id
      venue_type_ids
    }
    geographics {
      location {
        type
        coordinates
      }
      address {
        street
        number
        zip
        city
        state
        country_id
      }
    }
    settings {
      debug
      volume
      brightness
      orientation
      size {
        width
        height
      }
      density
      overscan {
        top
        bottom
        left
        right
      }
      storage_medium_id
      video_player
      image_player
      audio_player
    }
    specs {
      players {
        video
        image
        audio
      }
      storage {
        id
        adapter
        size {
          total
          used
          free
          available
        }
      }
    }
    last_seen_at
    activated {
      at
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      why
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const PlayerListFragmentDoc = gql`
  fragment PlayerList on Player {
    _id
    name
    status
    demographics {
      language_ids
      country_id
      area_type_id
      venue_type_ids
    }
    geographics {
      location {
        type
        coordinates
      }
      address {
        street
        number
        zip
        city
        state
        country_id
      }
    }
    settings {
      orientation
    }
    created {
      at
    }
    archived {
      at
      why
      by_user_id
    }
  }
`
export const NetworkFullFragmentDoc = gql`
  fragment NetworkFull on Network {
    _id
    name
    status
    is_external
    invite_token
    sharedWithWorkspaces {
      sent_by
      workspace_id
      created {
        at
        by_user_id
      }
      revoked {
        at
        by_user_id
      }
    }
    players {
      player_id
      added {
        at
        by_user_id
      }
      removed {
        at
        reason
        by_user_id
      }
      player {
        ...PlayerList
      }
    }
    created {
      by_user_id
      at
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
    ownership {
      workspace_id
    }
  }
  ${PlayerListFragmentDoc}
`
export const BroadcastTargetFullFragmentDoc = gql`
  fragment BroadcastTargetFull on BroadcastTarget {
    _id
    type
    status
    ref_id
    submittedBy
    review_notes
    player {
      ...PlayerFull
    }
    network {
      ...NetworkFull
    }
    externalNetwork {
      ...NetworkFull
    }
    created {
      at
      by_user_id
    }
    submitted {
      at
      by_user_id
    }
    approved {
      at
      by_user_id
    }
    rejected {
      at
      by_user_id
    }
    revoked {
      at
      by_user_id
    }
    removed {
      at
      reason
      by_user_id
    }
  }
  ${PlayerFullFragmentDoc}
  ${NetworkFullFragmentDoc}
`
export const UploadMinimumFragmentDoc = gql`
  fragment UploadMinimum on Upload {
    _id
    type
    status
    created {
      at
      by_user_id
    }
    versions: getVersions(names: ["thumbnail-300w"]) {
      name
      duration
      public_url
    }
  }
`
export const CreativeListFragmentDoc = gql`
  fragment CreativeList on Creative {
    _id
    name
    type
    status
    upload_id
    ownership {
      workspace_id
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    upload {
      ...UploadMinimum
    }
  }
  ${UploadMinimumFragmentDoc}
`
export const CampaignAdGroupFullFragmentDoc = gql`
  fragment CampaignAdGroupFull on CampaignAdGroup {
    _id
    is_active
    name
    status
    campaign_id
    audiences_ids
    creatives_ids
    redirect_to
    saturation
    creatives {
      ...CreativeList
    }
    campaign {
      name
    }
    timeframe {
      starts_at
      ends_at
    }
    timetable {
      rules {
        weekdays
        start
        end
        allowed
      }
      defaultAllowance
    }
    audience {
      area_type
      venue_type_ids
      geofences {
        location {
          type
          coordinates
        }
        range
        distance_unit_id
      }
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${CreativeListFragmentDoc}
`
export const CampaignFullFragmentDoc = gql`
  fragment CampaignFull on Campaign {
    _id
    name
    status
    is_external
    ownership {
      workspace_id
      workspace_name
    }
    timeframe {
      starts_at
      ends_at
    }
    broadcast_targets {
      ...BroadcastTargetFull
    }
    ad_groups {
      ...CampaignAdGroupFull
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${BroadcastTargetFullFragmentDoc}
  ${CampaignAdGroupFullFragmentDoc}
`
export const ExternalCampaignFullFragmentDoc = gql`
  fragment ExternalCampaignFull on FindManyExternalCampaignsResponse {
    network_id
    network_name
    campaign {
      ...CampaignFull
    }
    broadcast_target {
      ...BroadcastTargetFull
    }
  }
  ${CampaignFullFragmentDoc}
  ${BroadcastTargetFullFragmentDoc}
`
export const ExternalCampaignListFragmentDoc = gql`
  fragment ExternalCampaignList on FindManyExternalCampaignsResponse {
    network_id
    network_name
    campaign {
      _id
      name
      ownership {
        workspace_id
        workspace_name
      }
      ad_groups {
        _id
        timeframe {
          starts_at
          ends_at
        }
      }
    }
    broadcast_target {
      _id
      status
      submittedBy
      submitted {
        at
        by_user_id
      }
    }
  }
`
export const CampaignListFragmentDoc = gql`
  fragment CampaignList on Campaign {
    _id
    name
    status
    is_external
    ownership {
      workspace_id
      workspace_name
    }
    broadcast_targets {
      _id
      type
      ref_id
      removed {
        at
        reason
        by_user_id
      }
    }
    ad_groups {
      _id
      timeframe {
        starts_at
        ends_at
      }
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
  }
`
export const CampaignAdGroupListFragmentDoc = gql`
  fragment CampaignAdGroupList on CampaignAdGroup {
    _id
    name
    status
    is_active
    campaign_id
    saturation
    audience {
      area_type
    }
    creatives {
      _id
    }
    timeframe {
      starts_at
      ends_at
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
  }
`
export const UploadVersionsMetadataFullFragmentDoc = gql`
  fragment UploadVersionsMetadataFull on UploadVersionsMetadata {
    audio {
      codec
      bitrate
      frequency
    }
    video {
      fps
      codec
      bitrate
      resolution
    }
  }
`
export const UploadVersionsFullFragmentDoc = gql`
  fragment UploadVersionsFull on UploadVersions {
    name
    bytes
    format
    mimetype
    duration
    ipfs_hash
    public_url
    storage_path
    metadata {
      ...UploadVersionsMetadataFull
    }
  }
  ${UploadVersionsMetadataFullFragmentDoc}
`
export const UploadFullFragmentDoc = gql`
  fragment UploadFull on Upload {
    _id
    type
    status
    name
    created {
      at
      by_user_id
    }
    versions: getVersions(
      names: ["thumbnail-300w", "original", "transcoded-h264"]
    ) {
      ...UploadVersionsFull
    }
  }
  ${UploadVersionsFullFragmentDoc}
`
export const CreativeFullFragmentDoc = gql`
  fragment CreativeFull on Creative {
    _id
    name
    type
    status
    upload_id
    categories_ids
    ownership {
      workspace_id
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    upload {
      ...UploadFull
    }
    ad_groups {
      ...CampaignAdGroupFull
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${UploadFullFragmentDoc}
  ${CampaignAdGroupFullFragmentDoc}
`
export const NetworkListFragmentDoc = gql`
  fragment NetworkList on Network {
    _id
    name
    status
    is_external
    invite_token
    sharedWithWorkspaces {
      sent_by
      workspace_id
      created {
        at
        by_user_id
      }
      revoked {
        at
        by_user_id
      }
    }
    players {
      player_id
      added {
        at
        by_user_id
      }
      removed {
        at
        reason
        by_user_id
      }
      player {
        ...PlayerList
      }
    }
    created {
      by_user_id
      at
    }
    archived {
      at
      by_user_id
    }
    ownership {
      workspace_id
    }
  }
  ${PlayerListFragmentDoc}
`
export const CountryFullFragmentDoc = gql`
  fragment CountryFull on Country {
    _id
    name
    code
  }
`
export const UserListFragmentDoc = gql`
  fragment UserList on User {
    _id
    name {
      first
      last
    }
    emails {
      address
      verified_at
    }
  }
`
export const WorkspaceFullFragmentDoc = gql`
  fragment WorkspaceFull on Workspace {
    _id
    name
    billing_email
    is_active
    platform_type
    members {
      role
      user_id
      user {
        ...UserList
      }
      added {
        at
        by_user_id
      }
      removed {
        at
        by_user_id
      }
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
    payment_methods {
      data {
        id
        card {
          brand
          country
          exp_year
          exp_month
          last4
        }
        billing_details {
          address {
            city
            country
            line1
            postal_code
            state
          }
          name
        }
        metadata {
          vat
          company
        }
      }
    }
    default_payment_method
    invoices {
      data {
        id
        created
        invoice_pdf
        hosted_invoice_url
        total
        status
      }
    }
    upcoming_invoice {
      amount_due
      status
      next_payment_attempt
    }
  }
  ${UserListFragmentDoc}
`
export const UserFullFragmentDoc = gql`
  fragment UserFull on User {
    _id
    is_active
    name {
      first
      last
    }
    dob
    gender
    address {
      street
      number
      city
      state
      zip
      country_id
    }
    phones {
      number
      verified_at
    }
    platform_roles {
      admin
      curator
    }
    last_seen_at
    created {
      at
      by_user_id
    }
    emails {
      address
      verified_at
    }
  }
`
export const InviteFullFragmentDoc = gql`
  fragment InviteFull on WorkspaceInvite {
    _id
    status
    ownership {
      workspace_id
    }
    target {
      email
      user_id
      name {
        first
        last
      }
    }
    created {
      at
      by_user_id
    }
    revoked {
      at
      by_user_id
    }
    rejected {
      at
      by_user_id
    }
    accepted {
      at
      by_user_id
    }
  }
`
export const WebhookFullFragmentDoc = gql`
  fragment WebhookFull on Webhook {
    _id
    name
    url
    type
    retries
    event_types
    archived {
      at
      by_user_id
    }
    events {
      _id
      at
      type
      webhook_id
      responses {
        _id
        url
        body
        statusCode
        webhook_event_id
      }
    }
  }
`
export const SendEmailVerificationCodeDocument = gql`
  mutation SendEmailVerificationCode($email: String!) {
    isCodeSent: AccountEmailSendVerificationCode(email: $email)
  }
`
export type SendEmailVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>
export type SendEmailVerificationCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >,
  'mutation'
>

export const SendEmailVerificationCodeComponent = (
  props: SendEmailVerificationCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
    mutation={SendEmailVerificationCodeDocument}
    {...props}
  />
)

export type SendEmailVerificationCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
} &
  TChildProps
export function withSendEmailVerificationCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables,
    SendEmailVerificationCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables,
    SendEmailVerificationCodeProps<TChildProps, TDataName>
  >(SendEmailVerificationCodeDocument, {
    alias: 'sendEmailVerificationCode',
    ...operationOptions,
  })
}

/**
 * __useSendEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationCodeMutation, { data, loading, error }] = useSendEmailVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendEmailVerificationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >(SendEmailVerificationCodeDocument, baseOptions)
}
export type SendEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useSendEmailVerificationCodeMutation
>
export type SendEmailVerificationCodeMutationResult = ApolloReactCommon.MutationResult<SendEmailVerificationCodeMutation>
export type SendEmailVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>
export const VerifyEmailCodeDocument = gql`
  mutation VerifyEmailCode($code: String!, $email: String!) {
    isCodeValid: AccountEmailVerifyCode(code: $code, email: $email)
  }
`
export type VerifyEmailCodeMutationFn = ApolloReactCommon.MutationFunction<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>
export type VerifyEmailCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >,
  'mutation'
>

export const VerifyEmailCodeComponent = (
  props: VerifyEmailCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
    mutation={VerifyEmailCodeDocument}
    {...props}
  />
)

export type VerifyEmailCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
} &
  TChildProps
export function withVerifyEmailCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables,
    VerifyEmailCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables,
    VerifyEmailCodeProps<TChildProps, TDataName>
  >(VerifyEmailCodeDocument, {
    alias: 'verifyEmailCode',
    ...operationOptions,
  })
}

/**
 * __useVerifyEmailCodeMutation__
 *
 * To run a mutation, you first call `useVerifyEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailCodeMutation, { data, loading, error }] = useVerifyEmailCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyEmailCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >(VerifyEmailCodeDocument, baseOptions)
}
export type VerifyEmailCodeMutationHookResult = ReturnType<
  typeof useVerifyEmailCodeMutation
>
export type VerifyEmailCodeMutationResult = ApolloReactCommon.MutationResult<VerifyEmailCodeMutation>
export type VerifyEmailCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>
export const UpdateAccountDetailsDocument = gql`
  mutation UpdateAccountDetails($details: AccountUpdateDetailsInput!) {
    workspace: UpdateAccountDetails(details: $details) {
      _id
      name {
        first
        last
      }
    }
  }
`
export type UpdateAccountDetailsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>
export type UpdateAccountDetailsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >,
  'mutation'
>

export const UpdateAccountDetailsComponent = (
  props: UpdateAccountDetailsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
    mutation={UpdateAccountDetailsDocument}
    {...props}
  />
)

export type UpdateAccountDetailsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
} &
  TChildProps
export function withUpdateAccountDetails<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables,
    UpdateAccountDetailsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables,
    UpdateAccountDetailsProps<TChildProps, TDataName>
  >(UpdateAccountDetailsDocument, {
    alias: 'updateAccountDetails',
    ...operationOptions,
  })
}

/**
 * __useUpdateAccountDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDetailsMutation, { data, loading, error }] = useUpdateAccountDetailsMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateAccountDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >(UpdateAccountDetailsDocument, baseOptions)
}
export type UpdateAccountDetailsMutationHookResult = ReturnType<
  typeof useUpdateAccountDetailsMutation
>
export type UpdateAccountDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateAccountDetailsMutation>
export type UpdateAccountDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>
export const AuthForgotPasswordSendCodeDocument = gql`
  mutation AuthForgotPasswordSendCode(
    $method: ForgotPasswordMethod!
    $identifier: String!
  ) {
    codeSent: AuthForgotPasswordSendCode(
      method: $method
      identifier: $identifier
    )
  }
`
export type AuthForgotPasswordSendCodeMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordSendCodeMutation,
  AuthForgotPasswordSendCodeMutationVariables
>
export type AuthForgotPasswordSendCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordSendCodeComponent = (
  props: AuthForgotPasswordSendCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
    mutation={AuthForgotPasswordSendCodeDocument}
    {...props}
  />
)

export type AuthForgotPasswordSendCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordSendCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables,
    AuthForgotPasswordSendCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables,
    AuthForgotPasswordSendCodeProps<TChildProps, TDataName>
  >(AuthForgotPasswordSendCodeDocument, {
    alias: 'authForgotPasswordSendCode',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordSendCodeMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordSendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordSendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordSendCodeMutation, { data, loading, error }] = useAuthForgotPasswordSendCodeMutation({
 *   variables: {
 *      method: // value for 'method'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useAuthForgotPasswordSendCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >(AuthForgotPasswordSendCodeDocument, baseOptions)
}
export type AuthForgotPasswordSendCodeMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordSendCodeMutation
>
export type AuthForgotPasswordSendCodeMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordSendCodeMutation>
export type AuthForgotPasswordSendCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordSendCodeMutation,
  AuthForgotPasswordSendCodeMutationVariables
>
export const AuthForgotPasswordVerifyCodeDocument = gql`
  mutation AuthForgotPasswordVerifyCode(
    $method: ForgotPasswordMethod!
    $identifier: String!
    $code: String!
  ) {
    recoveryToken: AuthForgotPasswordVerifyCode(
      method: $method
      identifier: $identifier
      code: $code
    )
  }
`
export type AuthForgotPasswordVerifyCodeMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordVerifyCodeMutation,
  AuthForgotPasswordVerifyCodeMutationVariables
>
export type AuthForgotPasswordVerifyCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordVerifyCodeComponent = (
  props: AuthForgotPasswordVerifyCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
    mutation={AuthForgotPasswordVerifyCodeDocument}
    {...props}
  />
)

export type AuthForgotPasswordVerifyCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordVerifyCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables,
    AuthForgotPasswordVerifyCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables,
    AuthForgotPasswordVerifyCodeProps<TChildProps, TDataName>
  >(AuthForgotPasswordVerifyCodeDocument, {
    alias: 'authForgotPasswordVerifyCode',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordVerifyCodeMutation, { data, loading, error }] = useAuthForgotPasswordVerifyCodeMutation({
 *   variables: {
 *      method: // value for 'method'
 *      identifier: // value for 'identifier'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthForgotPasswordVerifyCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >(AuthForgotPasswordVerifyCodeDocument, baseOptions)
}
export type AuthForgotPasswordVerifyCodeMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordVerifyCodeMutation
>
export type AuthForgotPasswordVerifyCodeMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordVerifyCodeMutation>
export type AuthForgotPasswordVerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordVerifyCodeMutation,
  AuthForgotPasswordVerifyCodeMutationVariables
>
export const AuthForgotPasswordResetDocument = gql`
  mutation AuthForgotPasswordReset(
    $recoveryToken: String!
    $password: String!
  ) {
    authAccess: AuthForgotPasswordReset(
      recoveryToken: $recoveryToken
      password: $password
    ) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthForgotPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordResetMutation,
  AuthForgotPasswordResetMutationVariables
>
export type AuthForgotPasswordResetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordResetComponent = (
  props: AuthForgotPasswordResetComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
    mutation={AuthForgotPasswordResetDocument}
    {...props}
  />
)

export type AuthForgotPasswordResetProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordReset<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables,
    AuthForgotPasswordResetProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables,
    AuthForgotPasswordResetProps<TChildProps, TDataName>
  >(AuthForgotPasswordResetDocument, {
    alias: 'authForgotPasswordReset',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordResetMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordResetMutation, { data, loading, error }] = useAuthForgotPasswordResetMutation({
 *   variables: {
 *      recoveryToken: // value for 'recoveryToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthForgotPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >(AuthForgotPasswordResetDocument, baseOptions)
}
export type AuthForgotPasswordResetMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordResetMutation
>
export type AuthForgotPasswordResetMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordResetMutation>
export type AuthForgotPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordResetMutation,
  AuthForgotPasswordResetMutationVariables
>
export const AuthRefreshDocument = gql`
  mutation AuthRefresh($refreshToken: String!) {
    authRefreshResult: AuthRefresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`
export type AuthRefreshMutationFn = ApolloReactCommon.MutationFunction<
  AuthRefreshMutation,
  AuthRefreshMutationVariables
>
export type AuthRefreshComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >,
  'mutation'
>

export const AuthRefreshComponent = (props: AuthRefreshComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
    mutation={AuthRefreshDocument}
    {...props}
  />
)

export type AuthRefreshProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
} &
  TChildProps
export function withAuthRefresh<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthRefreshMutation,
    AuthRefreshMutationVariables,
    AuthRefreshProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthRefreshMutation,
    AuthRefreshMutationVariables,
    AuthRefreshProps<TChildProps, TDataName>
  >(AuthRefreshDocument, {
    alias: 'authRefresh',
    ...operationOptions,
  })
}

/**
 * __useAuthRefreshMutation__
 *
 * To run a mutation, you first call `useAuthRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshMutation, { data, loading, error }] = useAuthRefreshMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthRefreshMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >(AuthRefreshDocument, baseOptions)
}
export type AuthRefreshMutationHookResult = ReturnType<
  typeof useAuthRefreshMutation
>
export type AuthRefreshMutationResult = ApolloReactCommon.MutationResult<AuthRefreshMutation>
export type AuthRefreshMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthRefreshMutation,
  AuthRefreshMutationVariables
>
export const AuthSignInDocument = gql`
  mutation AuthSignIn($email: String!, $password: String!) {
    tokens: AuthSignIn(email: $email, password: $password) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthSignInMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignInMutation,
  AuthSignInMutationVariables
>
export type AuthSignInComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >,
  'mutation'
>

export const AuthSignInComponent = (props: AuthSignInComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
    mutation={AuthSignInDocument}
    {...props}
  />
)

export type AuthSignInProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
} &
  TChildProps
export function withAuthSignIn<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignInMutation,
    AuthSignInMutationVariables,
    AuthSignInProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignInMutation,
    AuthSignInMutationVariables,
    AuthSignInProps<TChildProps, TDataName>
  >(AuthSignInDocument, {
    alias: 'authSignIn',
    ...operationOptions,
  })
}

/**
 * __useAuthSignInMutation__
 *
 * To run a mutation, you first call `useAuthSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignInMutation, { data, loading, error }] = useAuthSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >(AuthSignInDocument, baseOptions)
}
export type AuthSignInMutationHookResult = ReturnType<
  typeof useAuthSignInMutation
>
export type AuthSignInMutationResult = ApolloReactCommon.MutationResult<AuthSignInMutation>
export type AuthSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignInMutation,
  AuthSignInMutationVariables
>
export const AuthSignOutDocument = gql`
  mutation AuthSignOut($refreshToken: String!) {
    AuthSignOut(refreshToken: $refreshToken)
  }
`
export type AuthSignOutMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignOutMutation,
  AuthSignOutMutationVariables
>
export type AuthSignOutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >,
  'mutation'
>

export const AuthSignOutComponent = (props: AuthSignOutComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
    mutation={AuthSignOutDocument}
    {...props}
  />
)

export type AuthSignOutProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
} &
  TChildProps
export function withAuthSignOut<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignOutMutation,
    AuthSignOutMutationVariables,
    AuthSignOutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignOutMutation,
    AuthSignOutMutationVariables,
    AuthSignOutProps<TChildProps, TDataName>
  >(AuthSignOutDocument, {
    alias: 'authSignOut',
    ...operationOptions,
  })
}

/**
 * __useAuthSignOutMutation__
 *
 * To run a mutation, you first call `useAuthSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignOutMutation, { data, loading, error }] = useAuthSignOutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthSignOutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >(AuthSignOutDocument, baseOptions)
}
export type AuthSignOutMutationHookResult = ReturnType<
  typeof useAuthSignOutMutation
>
export type AuthSignOutMutationResult = ApolloReactCommon.MutationResult<AuthSignOutMutation>
export type AuthSignOutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignOutMutation,
  AuthSignOutMutationVariables
>
export const AuthSignUpDocument = gql`
  mutation AuthSignUp(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
  ) {
    tokens: AuthSignUp(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
    ) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthSignUpMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>
export type AuthSignUpComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >,
  'mutation'
>

export const AuthSignUpComponent = (props: AuthSignUpComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
    mutation={AuthSignUpDocument}
    {...props}
  />
)

export type AuthSignUpProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
} &
  TChildProps
export function withAuthSignUp<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignUpMutation,
    AuthSignUpMutationVariables,
    AuthSignUpProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignUpMutation,
    AuthSignUpMutationVariables,
    AuthSignUpProps<TChildProps, TDataName>
  >(AuthSignUpDocument, {
    alias: 'authSignUp',
    ...operationOptions,
  })
}

/**
 * __useAuthSignUpMutation__
 *
 * To run a mutation, you first call `useAuthSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpMutation, { data, loading, error }] = useAuthSignUpMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >(AuthSignUpDocument, baseOptions)
}
export type AuthSignUpMutationHookResult = ReturnType<
  typeof useAuthSignUpMutation
>
export type AuthSignUpMutationResult = ApolloReactCommon.MutationResult<AuthSignUpMutation>
export type AuthSignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>
export const ArchiveCreativeDocument = gql`
  mutation ArchiveCreative($ids: [MongoID!]!) {
    areArchived: ArchiveCreatives(ids: $ids)
  }
`
export type ArchiveCreativeMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCreativeMutation,
  ArchiveCreativeMutationVariables
>
export type ArchiveCreativeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >,
  'mutation'
>

export const ArchiveCreativeComponent = (
  props: ArchiveCreativeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
    mutation={ArchiveCreativeDocument}
    {...props}
  />
)

export type ArchiveCreativeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
} &
  TChildProps
export function withArchiveCreative<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables,
    ArchiveCreativeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables,
    ArchiveCreativeProps<TChildProps, TDataName>
  >(ArchiveCreativeDocument, {
    alias: 'archiveCreative',
    ...operationOptions,
  })
}

/**
 * __useArchiveCreativeMutation__
 *
 * To run a mutation, you first call `useArchiveCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCreativeMutation, { data, loading, error }] = useArchiveCreativeMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCreativeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >(ArchiveCreativeDocument, baseOptions)
}
export type ArchiveCreativeMutationHookResult = ReturnType<
  typeof useArchiveCreativeMutation
>
export type ArchiveCreativeMutationResult = ApolloReactCommon.MutationResult<ArchiveCreativeMutation>
export type ArchiveCreativeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCreativeMutation,
  ArchiveCreativeMutationVariables
>
export const PreSignCreativeUploadDocument = gql`
  mutation PreSignCreativeUpload(
    $filename: String!
    $mimetype: String!
    $creative_type: EnumCreativeType!
  ) {
    upload: PreSignCreativeUpload(
      filename: $filename
      mimetype: $mimetype
      creative_type: $creative_type
    ) {
      upload_id
      creative_id
      presigned_url
      shred_token
    }
  }
`
export type PreSignCreativeUploadMutationFn = ApolloReactCommon.MutationFunction<
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables
>
export type PreSignCreativeUploadComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >,
  'mutation'
>

export const PreSignCreativeUploadComponent = (
  props: PreSignCreativeUploadComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
    mutation={PreSignCreativeUploadDocument}
    {...props}
  />
)

export type PreSignCreativeUploadProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
} &
  TChildProps
export function withPreSignCreativeUpload<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables,
    PreSignCreativeUploadProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables,
    PreSignCreativeUploadProps<TChildProps, TDataName>
  >(PreSignCreativeUploadDocument, {
    alias: 'preSignCreativeUpload',
    ...operationOptions,
  })
}

/**
 * __usePreSignCreativeUploadMutation__
 *
 * To run a mutation, you first call `usePreSignCreativeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreSignCreativeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preSignCreativeUploadMutation, { data, loading, error }] = usePreSignCreativeUploadMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimetype: // value for 'mimetype'
 *      creative_type: // value for 'creative_type'
 *   },
 * });
 */
export function usePreSignCreativeUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >(PreSignCreativeUploadDocument, baseOptions)
}
export type PreSignCreativeUploadMutationHookResult = ReturnType<
  typeof usePreSignCreativeUploadMutation
>
export type PreSignCreativeUploadMutationResult = ApolloReactCommon.MutationResult<PreSignCreativeUploadMutation>
export type PreSignCreativeUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables
>
export const NotifyCreativeUploadCompletedDocument = gql`
  mutation NotifyCreativeUploadCompleted($creative_id: MongoID!) {
    isNotified: NotifyCreativeUploadCompleted(creative_id: $creative_id)
  }
`
export type NotifyCreativeUploadCompletedMutationFn = ApolloReactCommon.MutationFunction<
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables
>
export type NotifyCreativeUploadCompletedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >,
  'mutation'
>

export const NotifyCreativeUploadCompletedComponent = (
  props: NotifyCreativeUploadCompletedComponentProps
) => (
  <ApolloReactComponents.Mutation<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
    mutation={NotifyCreativeUploadCompletedDocument}
    {...props}
  />
)

export type NotifyCreativeUploadCompletedProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
} &
  TChildProps
export function withNotifyCreativeUploadCompleted<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables,
    NotifyCreativeUploadCompletedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables,
    NotifyCreativeUploadCompletedProps<TChildProps, TDataName>
  >(NotifyCreativeUploadCompletedDocument, {
    alias: 'notifyCreativeUploadCompleted',
    ...operationOptions,
  })
}

/**
 * __useNotifyCreativeUploadCompletedMutation__
 *
 * To run a mutation, you first call `useNotifyCreativeUploadCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyCreativeUploadCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyCreativeUploadCompletedMutation, { data, loading, error }] = useNotifyCreativeUploadCompletedMutation({
 *   variables: {
 *      creative_id: // value for 'creative_id'
 *   },
 * });
 */
export function useNotifyCreativeUploadCompletedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >(NotifyCreativeUploadCompletedDocument, baseOptions)
}
export type NotifyCreativeUploadCompletedMutationHookResult = ReturnType<
  typeof useNotifyCreativeUploadCompletedMutation
>
export type NotifyCreativeUploadCompletedMutationResult = ApolloReactCommon.MutationResult<NotifyCreativeUploadCompletedMutation>
export type NotifyCreativeUploadCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables
>
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($input: CreateOneCampaignInput!) {
    campaign: CreateCampaign(record: $input) {
      _id: recordId
    }
  }
`
export type CreateCampaignMutationFn = ApolloReactCommon.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>
export type CreateCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >,
  'mutation'
>

export const CreateCampaignComponent = (
  props: CreateCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
    mutation={CreateCampaignDocument}
    {...props}
  />
)

export type CreateCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
} &
  TChildProps
export function withCreateCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps, TDataName>
  >(CreateCampaignDocument, {
    alias: 'createCampaign',
    ...operationOptions,
  })
}

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >(CreateCampaignDocument, baseOptions)
}
export type CreateCampaignMutationHookResult = ReturnType<
  typeof useCreateCampaignMutation
>
export type CreateCampaignMutationResult = ApolloReactCommon.MutationResult<CreateCampaignMutation>
export type CreateCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>
export const UpdateCampaignByIdDocument = gql`
  mutation UpdateCampaignById($_id: MongoID!, $input: UpdateOneCampaignInput!) {
    campaign: UpdateCampaign(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateCampaignByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignByIdMutation,
  UpdateCampaignByIdMutationVariables
>
export type UpdateCampaignByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignByIdComponent = (
  props: UpdateCampaignByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
    mutation={UpdateCampaignByIdDocument}
    {...props}
  />
)

export type UpdateCampaignByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables,
    UpdateCampaignByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables,
    UpdateCampaignByIdProps<TChildProps, TDataName>
  >(UpdateCampaignByIdDocument, {
    alias: 'updateCampaignById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignByIdMutation, { data, loading, error }] = useUpdateCampaignByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >(UpdateCampaignByIdDocument, baseOptions)
}
export type UpdateCampaignByIdMutationHookResult = ReturnType<
  typeof useUpdateCampaignByIdMutation
>
export type UpdateCampaignByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignByIdMutation>
export type UpdateCampaignByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignByIdMutation,
  UpdateCampaignByIdMutationVariables
>
export const UpdateCampaignActivationDocument = gql`
  mutation UpdateCampaignActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: SetIsActiveCampaigns(ids: $ids, is_active: $is_active)
  }
`
export type UpdateCampaignActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignActivationMutation,
  UpdateCampaignActivationMutationVariables
>
export type UpdateCampaignActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignActivationComponent = (
  props: UpdateCampaignActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
    mutation={UpdateCampaignActivationDocument}
    {...props}
  />
)

export type UpdateCampaignActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables,
    UpdateCampaignActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables,
    UpdateCampaignActivationProps<TChildProps, TDataName>
  >(UpdateCampaignActivationDocument, {
    alias: 'updateCampaignActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignActivationMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignActivationMutation, { data, loading, error }] = useUpdateCampaignActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateCampaignActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >(UpdateCampaignActivationDocument, baseOptions)
}
export type UpdateCampaignActivationMutationHookResult = ReturnType<
  typeof useUpdateCampaignActivationMutation
>
export type UpdateCampaignActivationMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignActivationMutation>
export type UpdateCampaignActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignActivationMutation,
  UpdateCampaignActivationMutationVariables
>
export const ArchiveCampaignDocument = gql`
  mutation ArchiveCampaign($ids: [MongoID!]!) {
    areArchived: ArchiveCampaigns(ids: $ids)
  }
`
export type ArchiveCampaignMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCampaignMutation,
  ArchiveCampaignMutationVariables
>
export type ArchiveCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >,
  'mutation'
>

export const ArchiveCampaignComponent = (
  props: ArchiveCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
    mutation={ArchiveCampaignDocument}
    {...props}
  />
)

export type ArchiveCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
} &
  TChildProps
export function withArchiveCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables,
    ArchiveCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables,
    ArchiveCampaignProps<TChildProps, TDataName>
  >(ArchiveCampaignDocument, {
    alias: 'archiveCampaign',
    ...operationOptions,
  })
}

/**
 * __useArchiveCampaignMutation__
 *
 * To run a mutation, you first call `useArchiveCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCampaignMutation, { data, loading, error }] = useArchiveCampaignMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >(ArchiveCampaignDocument, baseOptions)
}
export type ArchiveCampaignMutationHookResult = ReturnType<
  typeof useArchiveCampaignMutation
>
export type ArchiveCampaignMutationResult = ApolloReactCommon.MutationResult<ArchiveCampaignMutation>
export type ArchiveCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCampaignMutation,
  ArchiveCampaignMutationVariables
>
export const ShredCreativeDocument = gql`
  mutation ShredCreative($id: MongoID!, $shred_token: String!) {
    isShredded: ShredCreative(id: $id, shred_token: $shred_token)
  }
`
export type ShredCreativeMutationFn = ApolloReactCommon.MutationFunction<
  ShredCreativeMutation,
  ShredCreativeMutationVariables
>
export type ShredCreativeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ShredCreativeMutation,
    ShredCreativeMutationVariables
  >,
  'mutation'
>

export const ShredCreativeComponent = (props: ShredCreativeComponentProps) => (
  <ApolloReactComponents.Mutation<
    ShredCreativeMutation,
    ShredCreativeMutationVariables
  >
    mutation={ShredCreativeDocument}
    {...props}
  />
)

export type ShredCreativeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ShredCreativeMutation,
    ShredCreativeMutationVariables
  >
} &
  TChildProps
export function withShredCreative<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ShredCreativeMutation,
    ShredCreativeMutationVariables,
    ShredCreativeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ShredCreativeMutation,
    ShredCreativeMutationVariables,
    ShredCreativeProps<TChildProps, TDataName>
  >(ShredCreativeDocument, {
    alias: 'shredCreative',
    ...operationOptions,
  })
}

/**
 * __useShredCreativeMutation__
 *
 * To run a mutation, you first call `useShredCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShredCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shredCreativeMutation, { data, loading, error }] = useShredCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shred_token: // value for 'shred_token'
 *   },
 * });
 */
export function useShredCreativeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ShredCreativeMutation,
    ShredCreativeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ShredCreativeMutation,
    ShredCreativeMutationVariables
  >(ShredCreativeDocument, baseOptions)
}
export type ShredCreativeMutationHookResult = ReturnType<
  typeof useShredCreativeMutation
>
export type ShredCreativeMutationResult = ApolloReactCommon.MutationResult<ShredCreativeMutation>
export type ShredCreativeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ShredCreativeMutation,
  ShredCreativeMutationVariables
>
export const UpdateCampaignAdGroupByIdDocument = gql`
  mutation UpdateCampaignAdGroupById(
    $_id: MongoID!
    $input: UpdateOneCampaignAdGroupInput!
  ) {
    campaignAdGroup: UpdateCampaignAdGroup(
      filter: { _id: $_id }
      record: $input
    ) {
      _id: recordId
    }
  }
`
export type UpdateCampaignAdGroupByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignAdGroupByIdMutation,
  UpdateCampaignAdGroupByIdMutationVariables
>
export type UpdateCampaignAdGroupByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignAdGroupByIdComponent = (
  props: UpdateCampaignAdGroupByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
    mutation={UpdateCampaignAdGroupByIdDocument}
    {...props}
  />
)

export type UpdateCampaignAdGroupByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignAdGroupById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables,
    UpdateCampaignAdGroupByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables,
    UpdateCampaignAdGroupByIdProps<TChildProps, TDataName>
  >(UpdateCampaignAdGroupByIdDocument, {
    alias: 'updateCampaignAdGroupById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignAdGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAdGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAdGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAdGroupByIdMutation, { data, loading, error }] = useUpdateCampaignAdGroupByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignAdGroupByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >(UpdateCampaignAdGroupByIdDocument, baseOptions)
}
export type UpdateCampaignAdGroupByIdMutationHookResult = ReturnType<
  typeof useUpdateCampaignAdGroupByIdMutation
>
export type UpdateCampaignAdGroupByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignAdGroupByIdMutation>
export type UpdateCampaignAdGroupByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignAdGroupByIdMutation,
  UpdateCampaignAdGroupByIdMutationVariables
>
export const UpdateCampaignAdGroupActivationDocument = gql`
  mutation UpdateCampaignAdGroupActivation(
    $ids: [MongoID!]!
    $is_active: Boolean!
  ) {
    areUpdated: SetIsActiveCampaignAdGroups(ids: $ids, is_active: $is_active)
  }
`
export type UpdateCampaignAdGroupActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignAdGroupActivationMutation,
  UpdateCampaignAdGroupActivationMutationVariables
>
export type UpdateCampaignAdGroupActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignAdGroupActivationComponent = (
  props: UpdateCampaignAdGroupActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
    mutation={UpdateCampaignAdGroupActivationDocument}
    {...props}
  />
)

export type UpdateCampaignAdGroupActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignAdGroupActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables,
    UpdateCampaignAdGroupActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables,
    UpdateCampaignAdGroupActivationProps<TChildProps, TDataName>
  >(UpdateCampaignAdGroupActivationDocument, {
    alias: 'updateCampaignAdGroupActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignAdGroupActivationMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAdGroupActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAdGroupActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAdGroupActivationMutation, { data, loading, error }] = useUpdateCampaignAdGroupActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateCampaignAdGroupActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >(UpdateCampaignAdGroupActivationDocument, baseOptions)
}
export type UpdateCampaignAdGroupActivationMutationHookResult = ReturnType<
  typeof useUpdateCampaignAdGroupActivationMutation
>
export type UpdateCampaignAdGroupActivationMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignAdGroupActivationMutation>
export type UpdateCampaignAdGroupActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignAdGroupActivationMutation,
  UpdateCampaignAdGroupActivationMutationVariables
>
export const ArchiveCampaignAdGroupDocument = gql`
  mutation ArchiveCampaignAdGroup($ids: [MongoID!]!) {
    areArchived: ArchiveCampaignAdGroups(ids: $ids)
  }
`
export type ArchiveCampaignAdGroupMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCampaignAdGroupMutation,
  ArchiveCampaignAdGroupMutationVariables
>
export type ArchiveCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >,
  'mutation'
>

export const ArchiveCampaignAdGroupComponent = (
  props: ArchiveCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
    mutation={ArchiveCampaignAdGroupDocument}
    {...props}
  />
)

export type ArchiveCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
} &
  TChildProps
export function withArchiveCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables,
    ArchiveCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables,
    ArchiveCampaignAdGroupProps<TChildProps, TDataName>
  >(ArchiveCampaignAdGroupDocument, {
    alias: 'archiveCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useArchiveCampaignAdGroupMutation__
 *
 * To run a mutation, you first call `useArchiveCampaignAdGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCampaignAdGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCampaignAdGroupMutation, { data, loading, error }] = useArchiveCampaignAdGroupMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCampaignAdGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >(ArchiveCampaignAdGroupDocument, baseOptions)
}
export type ArchiveCampaignAdGroupMutationHookResult = ReturnType<
  typeof useArchiveCampaignAdGroupMutation
>
export type ArchiveCampaignAdGroupMutationResult = ApolloReactCommon.MutationResult<ArchiveCampaignAdGroupMutation>
export type ArchiveCampaignAdGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCampaignAdGroupMutation,
  ArchiveCampaignAdGroupMutationVariables
>
export const CreateCampaignAdGroupDocument = gql`
  mutation CreateCampaignAdGroup($input: CreateOneCampaignAdGroupInput!) {
    campaignAdGroup: CreateCampaignAdGroup(record: $input) {
      _id: recordId
    }
  }
`
export type CreateCampaignAdGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateCampaignAdGroupMutation,
  CreateCampaignAdGroupMutationVariables
>
export type CreateCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >,
  'mutation'
>

export const CreateCampaignAdGroupComponent = (
  props: CreateCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
    mutation={CreateCampaignAdGroupDocument}
    {...props}
  />
)

export type CreateCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
} &
  TChildProps
export function withCreateCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables,
    CreateCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables,
    CreateCampaignAdGroupProps<TChildProps, TDataName>
  >(CreateCampaignAdGroupDocument, {
    alias: 'createCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useCreateCampaignAdGroupMutation__
 *
 * To run a mutation, you first call `useCreateCampaignAdGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignAdGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignAdGroupMutation, { data, loading, error }] = useCreateCampaignAdGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignAdGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >(CreateCampaignAdGroupDocument, baseOptions)
}
export type CreateCampaignAdGroupMutationHookResult = ReturnType<
  typeof useCreateCampaignAdGroupMutation
>
export type CreateCampaignAdGroupMutationResult = ApolloReactCommon.MutationResult<CreateCampaignAdGroupMutation>
export type CreateCampaignAdGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCampaignAdGroupMutation,
  CreateCampaignAdGroupMutationVariables
>
export const CreateNetworkDocument = gql`
  mutation CreateNetwork($input: CreateOneNetworkInput!) {
    network: CreateNetwork(record: $input) {
      _id: recordId
    }
  }
`
export type CreateNetworkMutationFn = ApolloReactCommon.MutationFunction<
  CreateNetworkMutation,
  CreateNetworkMutationVariables
>
export type CreateNetworkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >,
  'mutation'
>

export const CreateNetworkComponent = (props: CreateNetworkComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
    mutation={CreateNetworkDocument}
    {...props}
  />
)

export type CreateNetworkProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
} &
  TChildProps
export function withCreateNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateNetworkMutation,
    CreateNetworkMutationVariables,
    CreateNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateNetworkMutation,
    CreateNetworkMutationVariables,
    CreateNetworkProps<TChildProps, TDataName>
  >(CreateNetworkDocument, {
    alias: 'createNetwork',
    ...operationOptions,
  })
}

/**
 * __useCreateNetworkMutation__
 *
 * To run a mutation, you first call `useCreateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkMutation, { data, loading, error }] = useCreateNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >(CreateNetworkDocument, baseOptions)
}
export type CreateNetworkMutationHookResult = ReturnType<
  typeof useCreateNetworkMutation
>
export type CreateNetworkMutationResult = ApolloReactCommon.MutationResult<CreateNetworkMutation>
export type CreateNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNetworkMutation,
  CreateNetworkMutationVariables
>
export const UpdateNetworkByIdDocument = gql`
  mutation UpdateNetworkById($_id: MongoID!, $input: UpdateOneNetworkInput!) {
    network: UpdateNetwork(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateNetworkByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNetworkByIdMutation,
  UpdateNetworkByIdMutationVariables
>
export type UpdateNetworkByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >,
  'mutation'
>

export const UpdateNetworkByIdComponent = (
  props: UpdateNetworkByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
    mutation={UpdateNetworkByIdDocument}
    {...props}
  />
)

export type UpdateNetworkByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateNetworkById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables,
    UpdateNetworkByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables,
    UpdateNetworkByIdProps<TChildProps, TDataName>
  >(UpdateNetworkByIdDocument, {
    alias: 'updateNetworkById',
    ...operationOptions,
  })
}

/**
 * __useUpdateNetworkByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkByIdMutation, { data, loading, error }] = useUpdateNetworkByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNetworkByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >(UpdateNetworkByIdDocument, baseOptions)
}
export type UpdateNetworkByIdMutationHookResult = ReturnType<
  typeof useUpdateNetworkByIdMutation
>
export type UpdateNetworkByIdMutationResult = ApolloReactCommon.MutationResult<UpdateNetworkByIdMutation>
export type UpdateNetworkByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNetworkByIdMutation,
  UpdateNetworkByIdMutationVariables
>
export const UpdateNetworkActivationDocument = gql`
  mutation UpdateNetworkActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: SetIsActiveNetworks(ids: $ids, is_active: $is_active)
  }
`
export type UpdateNetworkActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNetworkActivationMutation,
  UpdateNetworkActivationMutationVariables
>
export type UpdateNetworkActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >,
  'mutation'
>

export const UpdateNetworkActivationComponent = (
  props: UpdateNetworkActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
    mutation={UpdateNetworkActivationDocument}
    {...props}
  />
)

export type UpdateNetworkActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateNetworkActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables,
    UpdateNetworkActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables,
    UpdateNetworkActivationProps<TChildProps, TDataName>
  >(UpdateNetworkActivationDocument, {
    alias: 'updateNetworkActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateNetworkActivationMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkActivationMutation, { data, loading, error }] = useUpdateNetworkActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateNetworkActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >(UpdateNetworkActivationDocument, baseOptions)
}
export type UpdateNetworkActivationMutationHookResult = ReturnType<
  typeof useUpdateNetworkActivationMutation
>
export type UpdateNetworkActivationMutationResult = ApolloReactCommon.MutationResult<UpdateNetworkActivationMutation>
export type UpdateNetworkActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNetworkActivationMutation,
  UpdateNetworkActivationMutationVariables
>
export const ArchiveNetworkDocument = gql`
  mutation ArchiveNetwork($ids: [MongoID!]!) {
    areArchived: ArchiveNetworks(ids: $ids)
  }
`
export type ArchiveNetworkMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveNetworkMutation,
  ArchiveNetworkMutationVariables
>
export type ArchiveNetworkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >,
  'mutation'
>

export const ArchiveNetworkComponent = (
  props: ArchiveNetworkComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
    mutation={ArchiveNetworkDocument}
    {...props}
  />
)

export type ArchiveNetworkProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
} &
  TChildProps
export function withArchiveNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables,
    ArchiveNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables,
    ArchiveNetworkProps<TChildProps, TDataName>
  >(ArchiveNetworkDocument, {
    alias: 'archiveNetwork',
    ...operationOptions,
  })
}

/**
 * __useArchiveNetworkMutation__
 *
 * To run a mutation, you first call `useArchiveNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNetworkMutation, { data, loading, error }] = useArchiveNetworkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveNetworkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >(ArchiveNetworkDocument, baseOptions)
}
export type ArchiveNetworkMutationHookResult = ReturnType<
  typeof useArchiveNetworkMutation
>
export type ArchiveNetworkMutationResult = ApolloReactCommon.MutationResult<ArchiveNetworkMutation>
export type ArchiveNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveNetworkMutation,
  ArchiveNetworkMutationVariables
>
export const AddExternalNetworkToWorkspacesDocument = gql`
  mutation AddExternalNetworkToWorkspaces(
    $invite_token: String!
    $ids: [MongoID!]!
  ) {
    areAdded: WorkspaceAddExternalNetwork(
      invite_token: $invite_token
      workspace_ids: $ids
    )
  }
`
export type AddExternalNetworkToWorkspacesMutationFn = ApolloReactCommon.MutationFunction<
  AddExternalNetworkToWorkspacesMutation,
  AddExternalNetworkToWorkspacesMutationVariables
>
export type AddExternalNetworkToWorkspacesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables
  >,
  'mutation'
>

export const AddExternalNetworkToWorkspacesComponent = (
  props: AddExternalNetworkToWorkspacesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables
  >
    mutation={AddExternalNetworkToWorkspacesDocument}
    {...props}
  />
)

export type AddExternalNetworkToWorkspacesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables
  >
} &
  TChildProps
export function withAddExternalNetworkToWorkspaces<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables,
    AddExternalNetworkToWorkspacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables,
    AddExternalNetworkToWorkspacesProps<TChildProps, TDataName>
  >(AddExternalNetworkToWorkspacesDocument, {
    alias: 'addExternalNetworkToWorkspaces',
    ...operationOptions,
  })
}

/**
 * __useAddExternalNetworkToWorkspacesMutation__
 *
 * To run a mutation, you first call `useAddExternalNetworkToWorkspacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalNetworkToWorkspacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalNetworkToWorkspacesMutation, { data, loading, error }] = useAddExternalNetworkToWorkspacesMutation({
 *   variables: {
 *      invite_token: // value for 'invite_token'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAddExternalNetworkToWorkspacesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddExternalNetworkToWorkspacesMutation,
    AddExternalNetworkToWorkspacesMutationVariables
  >(AddExternalNetworkToWorkspacesDocument, baseOptions)
}
export type AddExternalNetworkToWorkspacesMutationHookResult = ReturnType<
  typeof useAddExternalNetworkToWorkspacesMutation
>
export type AddExternalNetworkToWorkspacesMutationResult = ApolloReactCommon.MutationResult<AddExternalNetworkToWorkspacesMutation>
export type AddExternalNetworkToWorkspacesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddExternalNetworkToWorkspacesMutation,
  AddExternalNetworkToWorkspacesMutationVariables
>
export const BindPlayerDocument = gql`
  mutation BindPlayer($token: String) {
    player: PlayerBind(token: $token) {
      _id
      name
    }
  }
`
export type BindPlayerMutationFn = ApolloReactCommon.MutationFunction<
  BindPlayerMutation,
  BindPlayerMutationVariables
>
export type BindPlayerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >,
  'mutation'
>

export const BindPlayerComponent = (props: BindPlayerComponentProps) => (
  <ApolloReactComponents.Mutation<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
    mutation={BindPlayerDocument}
    {...props}
  />
)

export type BindPlayerProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
} &
  TChildProps
export function withBindPlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BindPlayerMutation,
    BindPlayerMutationVariables,
    BindPlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BindPlayerMutation,
    BindPlayerMutationVariables,
    BindPlayerProps<TChildProps, TDataName>
  >(BindPlayerDocument, {
    alias: 'bindPlayer',
    ...operationOptions,
  })
}

/**
 * __useBindPlayerMutation__
 *
 * To run a mutation, you first call `useBindPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindPlayerMutation, { data, loading, error }] = useBindPlayerMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useBindPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >(BindPlayerDocument, baseOptions)
}
export type BindPlayerMutationHookResult = ReturnType<
  typeof useBindPlayerMutation
>
export type BindPlayerMutationResult = ApolloReactCommon.MutationResult<BindPlayerMutation>
export type BindPlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BindPlayerMutation,
  BindPlayerMutationVariables
>
export const AddPlayerToNetworksDocument = gql`
  mutation AddPlayerToNetworks(
    $player_id: MongoID!
    $network_ids: [MongoID!]!
  ) {
    wasAddedByNetwork: AddPlayerToNetworks(
      player_id: $player_id
      network_ids: $network_ids
    )
  }
`
export type AddPlayerToNetworksMutationFn = ApolloReactCommon.MutationFunction<
  AddPlayerToNetworksMutation,
  AddPlayerToNetworksMutationVariables
>
export type AddPlayerToNetworksComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables
  >,
  'mutation'
>

export const AddPlayerToNetworksComponent = (
  props: AddPlayerToNetworksComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables
  >
    mutation={AddPlayerToNetworksDocument}
    {...props}
  />
)

export type AddPlayerToNetworksProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables
  >
} &
  TChildProps
export function withAddPlayerToNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables,
    AddPlayerToNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables,
    AddPlayerToNetworksProps<TChildProps, TDataName>
  >(AddPlayerToNetworksDocument, {
    alias: 'addPlayerToNetworks',
    ...operationOptions,
  })
}

/**
 * __useAddPlayerToNetworksMutation__
 *
 * To run a mutation, you first call `useAddPlayerToNetworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayerToNetworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayerToNetworksMutation, { data, loading, error }] = useAddPlayerToNetworksMutation({
 *   variables: {
 *      player_id: // value for 'player_id'
 *      network_ids: // value for 'network_ids'
 *   },
 * });
 */
export function useAddPlayerToNetworksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddPlayerToNetworksMutation,
    AddPlayerToNetworksMutationVariables
  >(AddPlayerToNetworksDocument, baseOptions)
}
export type AddPlayerToNetworksMutationHookResult = ReturnType<
  typeof useAddPlayerToNetworksMutation
>
export type AddPlayerToNetworksMutationResult = ApolloReactCommon.MutationResult<AddPlayerToNetworksMutation>
export type AddPlayerToNetworksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPlayerToNetworksMutation,
  AddPlayerToNetworksMutationVariables
>
export const FinishPlayerSetupDocument = gql`
  mutation FinishPlayerSetup($_id: MongoID!) {
    activated: FinishPlayerSetup(_id: $_id)
  }
`
export type FinishPlayerSetupMutationFn = ApolloReactCommon.MutationFunction<
  FinishPlayerSetupMutation,
  FinishPlayerSetupMutationVariables
>
export type FinishPlayerSetupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables
  >,
  'mutation'
>

export const FinishPlayerSetupComponent = (
  props: FinishPlayerSetupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables
  >
    mutation={FinishPlayerSetupDocument}
    {...props}
  />
)

export type FinishPlayerSetupProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables
  >
} &
  TChildProps
export function withFinishPlayerSetup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables,
    FinishPlayerSetupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables,
    FinishPlayerSetupProps<TChildProps, TDataName>
  >(FinishPlayerSetupDocument, {
    alias: 'finishPlayerSetup',
    ...operationOptions,
  })
}

/**
 * __useFinishPlayerSetupMutation__
 *
 * To run a mutation, you first call `useFinishPlayerSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPlayerSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPlayerSetupMutation, { data, loading, error }] = useFinishPlayerSetupMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useFinishPlayerSetupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    FinishPlayerSetupMutation,
    FinishPlayerSetupMutationVariables
  >(FinishPlayerSetupDocument, baseOptions)
}
export type FinishPlayerSetupMutationHookResult = ReturnType<
  typeof useFinishPlayerSetupMutation
>
export type FinishPlayerSetupMutationResult = ApolloReactCommon.MutationResult<FinishPlayerSetupMutation>
export type FinishPlayerSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishPlayerSetupMutation,
  FinishPlayerSetupMutationVariables
>
export const UpdatePlayerByIdDocument = gql`
  mutation UpdatePlayerById(
    $skip: Int
    $_id: MongoID!
    $input: UpdateOnePlayerInput!
  ) {
    player: UpdatePlayer(skip: $skip, filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdatePlayerByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlayerByIdMutation,
  UpdatePlayerByIdMutationVariables
>
export type UpdatePlayerByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >,
  'mutation'
>

export const UpdatePlayerByIdComponent = (
  props: UpdatePlayerByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
    mutation={UpdatePlayerByIdDocument}
    {...props}
  />
)

export type UpdatePlayerByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
} &
  TChildProps
export function withUpdatePlayerById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables,
    UpdatePlayerByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables,
    UpdatePlayerByIdProps<TChildProps, TDataName>
  >(UpdatePlayerByIdDocument, {
    alias: 'updatePlayerById',
    ...operationOptions,
  })
}

/**
 * __useUpdatePlayerByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerByIdMutation, { data, loading, error }] = useUpdatePlayerByIdMutation({
 *   variables: {
 *      skip: // value for 'skip'
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlayerByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >(UpdatePlayerByIdDocument, baseOptions)
}
export type UpdatePlayerByIdMutationHookResult = ReturnType<
  typeof useUpdatePlayerByIdMutation
>
export type UpdatePlayerByIdMutationResult = ApolloReactCommon.MutationResult<UpdatePlayerByIdMutation>
export type UpdatePlayerByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePlayerByIdMutation,
  UpdatePlayerByIdMutationVariables
>
export const UpdatePlayerActivationDocument = gql`
  mutation UpdatePlayerActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: SetIsActivePlayers(ids: $ids, is_active: $is_active)
  }
`
export type UpdatePlayerActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlayerActivationMutation,
  UpdatePlayerActivationMutationVariables
>
export type UpdatePlayerActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >,
  'mutation'
>

export const UpdatePlayerActivationComponent = (
  props: UpdatePlayerActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
    mutation={UpdatePlayerActivationDocument}
    {...props}
  />
)

export type UpdatePlayerActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
} &
  TChildProps
export function withUpdatePlayerActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables,
    UpdatePlayerActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables,
    UpdatePlayerActivationProps<TChildProps, TDataName>
  >(UpdatePlayerActivationDocument, {
    alias: 'updatePlayerActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdatePlayerActivationMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerActivationMutation, { data, loading, error }] = useUpdatePlayerActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdatePlayerActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >(UpdatePlayerActivationDocument, baseOptions)
}
export type UpdatePlayerActivationMutationHookResult = ReturnType<
  typeof useUpdatePlayerActivationMutation
>
export type UpdatePlayerActivationMutationResult = ApolloReactCommon.MutationResult<UpdatePlayerActivationMutation>
export type UpdatePlayerActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePlayerActivationMutation,
  UpdatePlayerActivationMutationVariables
>
export const ArchivePlayerDocument = gql`
  mutation ArchivePlayer($ids: [MongoID!]!, $reason: String) {
    areArchived: ArchivePlayers(ids: $ids, why: $reason)
  }
`
export type ArchivePlayerMutationFn = ApolloReactCommon.MutationFunction<
  ArchivePlayerMutation,
  ArchivePlayerMutationVariables
>
export type ArchivePlayerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >,
  'mutation'
>

export const ArchivePlayerComponent = (props: ArchivePlayerComponentProps) => (
  <ApolloReactComponents.Mutation<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
    mutation={ArchivePlayerDocument}
    {...props}
  />
)

export type ArchivePlayerProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
} &
  TChildProps
export function withArchivePlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables,
    ArchivePlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables,
    ArchivePlayerProps<TChildProps, TDataName>
  >(ArchivePlayerDocument, {
    alias: 'archivePlayer',
    ...operationOptions,
  })
}

/**
 * __useArchivePlayerMutation__
 *
 * To run a mutation, you first call `useArchivePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePlayerMutation, { data, loading, error }] = useArchivePlayerMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useArchivePlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >(ArchivePlayerDocument, baseOptions)
}
export type ArchivePlayerMutationHookResult = ReturnType<
  typeof useArchivePlayerMutation
>
export type ArchivePlayerMutationResult = ApolloReactCommon.MutationResult<ArchivePlayerMutation>
export type ArchivePlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchivePlayerMutation,
  ArchivePlayerMutationVariables
>
export const SetupWorkspaceDocument = gql`
  mutation SetupWorkspace(
    $name: String
    $billing_email: String
    $platform_type: EnumWorkspacePlatform_type
  ) {
    workspace: SetupWorkspace(
      name: $name
      billing_email: $billing_email
      platform_type: $platform_type
    ) {
      _id
      name
      billing_email
      platform_type
    }
  }
`
export type SetupWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  SetupWorkspaceMutation,
  SetupWorkspaceMutationVariables
>
export type SetupWorkspaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >,
  'mutation'
>

export const SetupWorkspaceComponent = (
  props: SetupWorkspaceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
    mutation={SetupWorkspaceDocument}
    {...props}
  />
)

export type SetupWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
} &
  TChildProps
export function withSetupWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables,
    SetupWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables,
    SetupWorkspaceProps<TChildProps, TDataName>
  >(SetupWorkspaceDocument, {
    alias: 'setupWorkspace',
    ...operationOptions,
  })
}

/**
 * __useSetupWorkspaceMutation__
 *
 * To run a mutation, you first call `useSetupWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupWorkspaceMutation, { data, loading, error }] = useSetupWorkspaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      billing_email: // value for 'billing_email'
 *      platform_type: // value for 'platform_type'
 *   },
 * });
 */
export function useSetupWorkspaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >(SetupWorkspaceDocument, baseOptions)
}
export type SetupWorkspaceMutationHookResult = ReturnType<
  typeof useSetupWorkspaceMutation
>
export type SetupWorkspaceMutationResult = ApolloReactCommon.MutationResult<SetupWorkspaceMutation>
export type SetupWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetupWorkspaceMutation,
  SetupWorkspaceMutationVariables
>
export const UpdateWorkspaceByIdDocument = gql`
  mutation UpdateWorkspaceById(
    $_id: String!
    $details: WorkspaceUpdateDetailsInput!
  ) {
    workspace: UpdateWorkspaceDetails(_id: $_id, details: $details) {
      _id
      name
      billing_email
      platform_type
    }
  }
`
export type UpdateWorkspaceByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWorkspaceByIdMutation,
  UpdateWorkspaceByIdMutationVariables
>
export type UpdateWorkspaceByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >,
  'mutation'
>

export const UpdateWorkspaceByIdComponent = (
  props: UpdateWorkspaceByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
    mutation={UpdateWorkspaceByIdDocument}
    {...props}
  />
)

export type UpdateWorkspaceByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateWorkspaceById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables,
    UpdateWorkspaceByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables,
    UpdateWorkspaceByIdProps<TChildProps, TDataName>
  >(UpdateWorkspaceByIdDocument, {
    alias: 'updateWorkspaceById',
    ...operationOptions,
  })
}

/**
 * __useUpdateWorkspaceByIdMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceByIdMutation, { data, loading, error }] = useUpdateWorkspaceByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateWorkspaceByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >(UpdateWorkspaceByIdDocument, baseOptions)
}
export type UpdateWorkspaceByIdMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceByIdMutation
>
export type UpdateWorkspaceByIdMutationResult = ApolloReactCommon.MutationResult<UpdateWorkspaceByIdMutation>
export type UpdateWorkspaceByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWorkspaceByIdMutation,
  UpdateWorkspaceByIdMutationVariables
>
export const AccountEmailAddDocument = gql`
  mutation AccountEmailAdd($email: String!) {
    added: AccountEmailAdd(email: $email)
  }
`
export type AccountEmailAddMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailAddMutation,
  AccountEmailAddMutationVariables
>
export type AccountEmailAddComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >,
  'mutation'
>

export const AccountEmailAddComponent = (
  props: AccountEmailAddComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
    mutation={AccountEmailAddDocument}
    {...props}
  />
)

export type AccountEmailAddProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
} &
  TChildProps
export function withAccountEmailAdd<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables,
    AccountEmailAddProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables,
    AccountEmailAddProps<TChildProps, TDataName>
  >(AccountEmailAddDocument, {
    alias: 'accountEmailAdd',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailAddMutation__
 *
 * To run a mutation, you first call `useAccountEmailAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailAddMutation, { data, loading, error }] = useAccountEmailAddMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >(AccountEmailAddDocument, baseOptions)
}
export type AccountEmailAddMutationHookResult = ReturnType<
  typeof useAccountEmailAddMutation
>
export type AccountEmailAddMutationResult = ApolloReactCommon.MutationResult<AccountEmailAddMutation>
export type AccountEmailAddMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailAddMutation,
  AccountEmailAddMutationVariables
>
export const AccountEmailVerifyCodeDocument = gql`
  mutation AccountEmailVerifyCode($email: String!, $code: String!) {
    AccountEmailVerifyCode(email: $email, code: $code)
  }
`
export type AccountEmailVerifyCodeMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailVerifyCodeMutation,
  AccountEmailVerifyCodeMutationVariables
>
export type AccountEmailVerifyCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >,
  'mutation'
>

export const AccountEmailVerifyCodeComponent = (
  props: AccountEmailVerifyCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
    mutation={AccountEmailVerifyCodeDocument}
    {...props}
  />
)

export type AccountEmailVerifyCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
} &
  TChildProps
export function withAccountEmailVerifyCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables,
    AccountEmailVerifyCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables,
    AccountEmailVerifyCodeProps<TChildProps, TDataName>
  >(AccountEmailVerifyCodeDocument, {
    alias: 'accountEmailVerifyCode',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAccountEmailVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailVerifyCodeMutation, { data, loading, error }] = useAccountEmailVerifyCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAccountEmailVerifyCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >(AccountEmailVerifyCodeDocument, baseOptions)
}
export type AccountEmailVerifyCodeMutationHookResult = ReturnType<
  typeof useAccountEmailVerifyCodeMutation
>
export type AccountEmailVerifyCodeMutationResult = ApolloReactCommon.MutationResult<AccountEmailVerifyCodeMutation>
export type AccountEmailVerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailVerifyCodeMutation,
  AccountEmailVerifyCodeMutationVariables
>
export const AccountEmailSendVerificationCodeDocument = gql`
  mutation AccountEmailSendVerificationCode($email: String!) {
    AccountEmailSendVerificationCode(email: $email)
  }
`
export type AccountEmailSendVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailSendVerificationCodeMutation,
  AccountEmailSendVerificationCodeMutationVariables
>
export type AccountEmailSendVerificationCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >,
  'mutation'
>

export const AccountEmailSendVerificationCodeComponent = (
  props: AccountEmailSendVerificationCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
    mutation={AccountEmailSendVerificationCodeDocument}
    {...props}
  />
)

export type AccountEmailSendVerificationCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
} &
  TChildProps
export function withAccountEmailSendVerificationCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables,
    AccountEmailSendVerificationCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables,
    AccountEmailSendVerificationCodeProps<TChildProps, TDataName>
  >(AccountEmailSendVerificationCodeDocument, {
    alias: 'accountEmailSendVerificationCode',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useAccountEmailSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailSendVerificationCodeMutation, { data, loading, error }] = useAccountEmailSendVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailSendVerificationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >(AccountEmailSendVerificationCodeDocument, baseOptions)
}
export type AccountEmailSendVerificationCodeMutationHookResult = ReturnType<
  typeof useAccountEmailSendVerificationCodeMutation
>
export type AccountEmailSendVerificationCodeMutationResult = ApolloReactCommon.MutationResult<AccountEmailSendVerificationCodeMutation>
export type AccountEmailSendVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailSendVerificationCodeMutation,
  AccountEmailSendVerificationCodeMutationVariables
>
export const AccountEmailSetPrimaryDocument = gql`
  mutation AccountEmailSetPrimary($email: String!) {
    isPrimary: AccountEmailSetPrimary(email: $email)
  }
`
export type AccountEmailSetPrimaryMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailSetPrimaryMutation,
  AccountEmailSetPrimaryMutationVariables
>
export type AccountEmailSetPrimaryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >,
  'mutation'
>

export const AccountEmailSetPrimaryComponent = (
  props: AccountEmailSetPrimaryComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
    mutation={AccountEmailSetPrimaryDocument}
    {...props}
  />
)

export type AccountEmailSetPrimaryProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
} &
  TChildProps
export function withAccountEmailSetPrimary<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables,
    AccountEmailSetPrimaryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables,
    AccountEmailSetPrimaryProps<TChildProps, TDataName>
  >(AccountEmailSetPrimaryDocument, {
    alias: 'accountEmailSetPrimary',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailSetPrimaryMutation__
 *
 * To run a mutation, you first call `useAccountEmailSetPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailSetPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailSetPrimaryMutation, { data, loading, error }] = useAccountEmailSetPrimaryMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailSetPrimaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >(AccountEmailSetPrimaryDocument, baseOptions)
}
export type AccountEmailSetPrimaryMutationHookResult = ReturnType<
  typeof useAccountEmailSetPrimaryMutation
>
export type AccountEmailSetPrimaryMutationResult = ApolloReactCommon.MutationResult<AccountEmailSetPrimaryMutation>
export type AccountEmailSetPrimaryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailSetPrimaryMutation,
  AccountEmailSetPrimaryMutationVariables
>
export const AccountEmailRemoveDocument = gql`
  mutation AccountEmailRemove($email: String!) {
    removed: AccountEmailRemove(email: $email)
  }
`
export type AccountEmailRemoveMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailRemoveMutation,
  AccountEmailRemoveMutationVariables
>
export type AccountEmailRemoveComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >,
  'mutation'
>

export const AccountEmailRemoveComponent = (
  props: AccountEmailRemoveComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
    mutation={AccountEmailRemoveDocument}
    {...props}
  />
)

export type AccountEmailRemoveProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
} &
  TChildProps
export function withAccountEmailRemove<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables,
    AccountEmailRemoveProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables,
    AccountEmailRemoveProps<TChildProps, TDataName>
  >(AccountEmailRemoveDocument, {
    alias: 'accountEmailRemove',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailRemoveMutation__
 *
 * To run a mutation, you first call `useAccountEmailRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailRemoveMutation, { data, loading, error }] = useAccountEmailRemoveMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >(AccountEmailRemoveDocument, baseOptions)
}
export type AccountEmailRemoveMutationHookResult = ReturnType<
  typeof useAccountEmailRemoveMutation
>
export type AccountEmailRemoveMutationResult = ApolloReactCommon.MutationResult<AccountEmailRemoveMutation>
export type AccountEmailRemoveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailRemoveMutation,
  AccountEmailRemoveMutationVariables
>
export const AccountChangePasswordDocument = gql`
  mutation AccountChangePassword(
    $refreshToken: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    isUpdated: AccountChangePassword(
      refreshToken: $refreshToken
      currentPassword: $currentPassword
      newPassword: $newPassword
    )
  }
`
export type AccountChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  AccountChangePasswordMutation,
  AccountChangePasswordMutationVariables
>
export type AccountChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >,
  'mutation'
>

export const AccountChangePasswordComponent = (
  props: AccountChangePasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
    mutation={AccountChangePasswordDocument}
    {...props}
  />
)

export type AccountChangePasswordProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
} &
  TChildProps
export function withAccountChangePassword<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables,
    AccountChangePasswordProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables,
    AccountChangePasswordProps<TChildProps, TDataName>
  >(AccountChangePasswordDocument, {
    alias: 'accountChangePassword',
    ...operationOptions,
  })
}

/**
 * __useAccountChangePasswordMutation__
 *
 * To run a mutation, you first call `useAccountChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountChangePasswordMutation, { data, loading, error }] = useAccountChangePasswordMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useAccountChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >(AccountChangePasswordDocument, baseOptions)
}
export type AccountChangePasswordMutationHookResult = ReturnType<
  typeof useAccountChangePasswordMutation
>
export type AccountChangePasswordMutationResult = ApolloReactCommon.MutationResult<AccountChangePasswordMutation>
export type AccountChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountChangePasswordMutation,
  AccountChangePasswordMutationVariables
>
export const AddWorkspaceMemberDocument = gql`
  mutation AddWorkspaceMember($emails: [String!]!) {
    addedMember: InviteWorkspaceMember(emails: $emails) {
      _id
    }
  }
`
export type AddWorkspaceMemberMutationFn = ApolloReactCommon.MutationFunction<
  AddWorkspaceMemberMutation,
  AddWorkspaceMemberMutationVariables
>
export type AddWorkspaceMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >,
  'mutation'
>

export const AddWorkspaceMemberComponent = (
  props: AddWorkspaceMemberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
    mutation={AddWorkspaceMemberDocument}
    {...props}
  />
)

export type AddWorkspaceMemberProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
} &
  TChildProps
export function withAddWorkspaceMember<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables,
    AddWorkspaceMemberProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables,
    AddWorkspaceMemberProps<TChildProps, TDataName>
  >(AddWorkspaceMemberDocument, {
    alias: 'addWorkspaceMember',
    ...operationOptions,
  })
}

/**
 * __useAddWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceMemberMutation, { data, loading, error }] = useAddWorkspaceMemberMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useAddWorkspaceMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >(AddWorkspaceMemberDocument, baseOptions)
}
export type AddWorkspaceMemberMutationHookResult = ReturnType<
  typeof useAddWorkspaceMemberMutation
>
export type AddWorkspaceMemberMutationResult = ApolloReactCommon.MutationResult<AddWorkspaceMemberMutation>
export type AddWorkspaceMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddWorkspaceMemberMutation,
  AddWorkspaceMemberMutationVariables
>
export const RemoveWorkspaceMemberDocument = gql`
  mutation RemoveWorkspaceMember($email: String!) {
    removedMember: RemoveWorkspaceMember(email: $email)
  }
`
export type RemoveWorkspaceMemberMutationFn = ApolloReactCommon.MutationFunction<
  RemoveWorkspaceMemberMutation,
  RemoveWorkspaceMemberMutationVariables
>
export type RemoveWorkspaceMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >,
  'mutation'
>

export const RemoveWorkspaceMemberComponent = (
  props: RemoveWorkspaceMemberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
    mutation={RemoveWorkspaceMemberDocument}
    {...props}
  />
)

export type RemoveWorkspaceMemberProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
} &
  TChildProps
export function withRemoveWorkspaceMember<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables,
    RemoveWorkspaceMemberProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables,
    RemoveWorkspaceMemberProps<TChildProps, TDataName>
  >(RemoveWorkspaceMemberDocument, {
    alias: 'removeWorkspaceMember',
    ...operationOptions,
  })
}

/**
 * __useRemoveWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceMemberMutation, { data, loading, error }] = useRemoveWorkspaceMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveWorkspaceMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >(RemoveWorkspaceMemberDocument, baseOptions)
}
export type RemoveWorkspaceMemberMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceMemberMutation
>
export type RemoveWorkspaceMemberMutationResult = ApolloReactCommon.MutationResult<RemoveWorkspaceMemberMutation>
export type RemoveWorkspaceMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveWorkspaceMemberMutation,
  RemoveWorkspaceMemberMutationVariables
>
export const InviteToWorkspaceDocument = gql`
  mutation InviteToWorkspace($emails: [String!]!) {
    isInviteSent: InviteWorkspaceMember(emails: $emails) {
      ...InviteFull
    }
  }
  ${InviteFullFragmentDoc}
`
export type InviteToWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>
export type InviteToWorkspaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >,
  'mutation'
>

export const InviteToWorkspaceComponent = (
  props: InviteToWorkspaceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
    mutation={InviteToWorkspaceDocument}
    {...props}
  />
)

export type InviteToWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
} &
  TChildProps
export function withInviteToWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables,
    InviteToWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables,
    InviteToWorkspaceProps<TChildProps, TDataName>
  >(InviteToWorkspaceDocument, {
    alias: 'inviteToWorkspace',
    ...operationOptions,
  })
}

/**
 * __useInviteToWorkspaceMutation__
 *
 * To run a mutation, you first call `useInviteToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToWorkspaceMutation, { data, loading, error }] = useInviteToWorkspaceMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteToWorkspaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >(InviteToWorkspaceDocument, baseOptions)
}
export type InviteToWorkspaceMutationHookResult = ReturnType<
  typeof useInviteToWorkspaceMutation
>
export type InviteToWorkspaceMutationResult = ApolloReactCommon.MutationResult<InviteToWorkspaceMutation>
export type InviteToWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>
export const AcceptWorkspaceInvitesDocument = gql`
  mutation AcceptWorkspaceInvites($ids: [MongoID!]!) {
    invites: AccountAcceptWorkspaceInvites(ids: $ids) {
      ...InviteFull
    }
  }
  ${InviteFullFragmentDoc}
`
export type AcceptWorkspaceInvitesMutationFn = ApolloReactCommon.MutationFunction<
  AcceptWorkspaceInvitesMutation,
  AcceptWorkspaceInvitesMutationVariables
>
export type AcceptWorkspaceInvitesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >,
  'mutation'
>

export const AcceptWorkspaceInvitesComponent = (
  props: AcceptWorkspaceInvitesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
    mutation={AcceptWorkspaceInvitesDocument}
    {...props}
  />
)

export type AcceptWorkspaceInvitesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
} &
  TChildProps
export function withAcceptWorkspaceInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables,
    AcceptWorkspaceInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables,
    AcceptWorkspaceInvitesProps<TChildProps, TDataName>
  >(AcceptWorkspaceInvitesDocument, {
    alias: 'acceptWorkspaceInvites',
    ...operationOptions,
  })
}

/**
 * __useAcceptWorkspaceInvitesMutation__
 *
 * To run a mutation, you first call `useAcceptWorkspaceInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptWorkspaceInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptWorkspaceInvitesMutation, { data, loading, error }] = useAcceptWorkspaceInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcceptWorkspaceInvitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >(AcceptWorkspaceInvitesDocument, baseOptions)
}
export type AcceptWorkspaceInvitesMutationHookResult = ReturnType<
  typeof useAcceptWorkspaceInvitesMutation
>
export type AcceptWorkspaceInvitesMutationResult = ApolloReactCommon.MutationResult<AcceptWorkspaceInvitesMutation>
export type AcceptWorkspaceInvitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcceptWorkspaceInvitesMutation,
  AcceptWorkspaceInvitesMutationVariables
>
export const RejectWorkspaceInvitesDocument = gql`
  mutation RejectWorkspaceInvites($ids: [MongoID!]!) {
    invites: AccountRejectWorkspaceInvites(ids: $ids) {
      ...InviteFull
    }
  }
  ${InviteFullFragmentDoc}
`
export type RejectWorkspaceInvitesMutationFn = ApolloReactCommon.MutationFunction<
  RejectWorkspaceInvitesMutation,
  RejectWorkspaceInvitesMutationVariables
>
export type RejectWorkspaceInvitesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >,
  'mutation'
>

export const RejectWorkspaceInvitesComponent = (
  props: RejectWorkspaceInvitesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
    mutation={RejectWorkspaceInvitesDocument}
    {...props}
  />
)

export type RejectWorkspaceInvitesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
} &
  TChildProps
export function withRejectWorkspaceInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables,
    RejectWorkspaceInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables,
    RejectWorkspaceInvitesProps<TChildProps, TDataName>
  >(RejectWorkspaceInvitesDocument, {
    alias: 'rejectWorkspaceInvites',
    ...operationOptions,
  })
}

/**
 * __useRejectWorkspaceInvitesMutation__
 *
 * To run a mutation, you first call `useRejectWorkspaceInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectWorkspaceInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectWorkspaceInvitesMutation, { data, loading, error }] = useRejectWorkspaceInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRejectWorkspaceInvitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >(RejectWorkspaceInvitesDocument, baseOptions)
}
export type RejectWorkspaceInvitesMutationHookResult = ReturnType<
  typeof useRejectWorkspaceInvitesMutation
>
export type RejectWorkspaceInvitesMutationResult = ApolloReactCommon.MutationResult<RejectWorkspaceInvitesMutation>
export type RejectWorkspaceInvitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectWorkspaceInvitesMutation,
  RejectWorkspaceInvitesMutationVariables
>
export const RevokeWorkspaceInvitesDocument = gql`
  mutation RevokeWorkspaceInvites($ids: [MongoID!]!) {
    cancelledInvite: RevokeWorkspaceMemberInvite(ids: $ids) {
      ...InviteFull
    }
  }
  ${InviteFullFragmentDoc}
`
export type RevokeWorkspaceInvitesMutationFn = ApolloReactCommon.MutationFunction<
  RevokeWorkspaceInvitesMutation,
  RevokeWorkspaceInvitesMutationVariables
>
export type RevokeWorkspaceInvitesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables
  >,
  'mutation'
>

export const RevokeWorkspaceInvitesComponent = (
  props: RevokeWorkspaceInvitesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables
  >
    mutation={RevokeWorkspaceInvitesDocument}
    {...props}
  />
)

export type RevokeWorkspaceInvitesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables
  >
} &
  TChildProps
export function withRevokeWorkspaceInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables,
    RevokeWorkspaceInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables,
    RevokeWorkspaceInvitesProps<TChildProps, TDataName>
  >(RevokeWorkspaceInvitesDocument, {
    alias: 'revokeWorkspaceInvites',
    ...operationOptions,
  })
}

/**
 * __useRevokeWorkspaceInvitesMutation__
 *
 * To run a mutation, you first call `useRevokeWorkspaceInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeWorkspaceInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeWorkspaceInvitesMutation, { data, loading, error }] = useRevokeWorkspaceInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRevokeWorkspaceInvitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RevokeWorkspaceInvitesMutation,
    RevokeWorkspaceInvitesMutationVariables
  >(RevokeWorkspaceInvitesDocument, baseOptions)
}
export type RevokeWorkspaceInvitesMutationHookResult = ReturnType<
  typeof useRevokeWorkspaceInvitesMutation
>
export type RevokeWorkspaceInvitesMutationResult = ApolloReactCommon.MutationResult<RevokeWorkspaceInvitesMutation>
export type RevokeWorkspaceInvitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeWorkspaceInvitesMutation,
  RevokeWorkspaceInvitesMutationVariables
>
export const RevokeWorkspaceAccessDocument = gql`
  mutation RevokeWorkspaceAccess($networkId: MongoID!, $workspaceId: MongoID!) {
    isRevoked: NetworkRevokeWorkspaceAccess(
      networkId: $networkId
      workspaceId: $workspaceId
    )
  }
`
export type RevokeWorkspaceAccessMutationFn = ApolloReactCommon.MutationFunction<
  RevokeWorkspaceAccessMutation,
  RevokeWorkspaceAccessMutationVariables
>
export type RevokeWorkspaceAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables
  >,
  'mutation'
>

export const RevokeWorkspaceAccessComponent = (
  props: RevokeWorkspaceAccessComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables
  >
    mutation={RevokeWorkspaceAccessDocument}
    {...props}
  />
)

export type RevokeWorkspaceAccessProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables
  >
} &
  TChildProps
export function withRevokeWorkspaceAccess<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables,
    RevokeWorkspaceAccessProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables,
    RevokeWorkspaceAccessProps<TChildProps, TDataName>
  >(RevokeWorkspaceAccessDocument, {
    alias: 'revokeWorkspaceAccess',
    ...operationOptions,
  })
}

/**
 * __useRevokeWorkspaceAccessMutation__
 *
 * To run a mutation, you first call `useRevokeWorkspaceAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeWorkspaceAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeWorkspaceAccessMutation, { data, loading, error }] = useRevokeWorkspaceAccessMutation({
 *   variables: {
 *      networkId: // value for 'networkId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useRevokeWorkspaceAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RevokeWorkspaceAccessMutation,
    RevokeWorkspaceAccessMutationVariables
  >(RevokeWorkspaceAccessDocument, baseOptions)
}
export type RevokeWorkspaceAccessMutationHookResult = ReturnType<
  typeof useRevokeWorkspaceAccessMutation
>
export type RevokeWorkspaceAccessMutationResult = ApolloReactCommon.MutationResult<RevokeWorkspaceAccessMutation>
export type RevokeWorkspaceAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeWorkspaceAccessMutation,
  RevokeWorkspaceAccessMutationVariables
>
export const WorkspaceSetupIntentDocument = gql`
  mutation WorkspaceSetupIntent($setupIntentArgs: setupIntentInput) {
    setupIntent: WorkspaceSetupIntent(setupIntentArgs: $setupIntentArgs) {
      id
      client_secret
      payment_method
      status
    }
  }
`
export type WorkspaceSetupIntentMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceSetupIntentMutation,
  WorkspaceSetupIntentMutationVariables
>
export type WorkspaceSetupIntentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >,
  'mutation'
>

export const WorkspaceSetupIntentComponent = (
  props: WorkspaceSetupIntentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
    mutation={WorkspaceSetupIntentDocument}
    {...props}
  />
)

export type WorkspaceSetupIntentProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
} &
  TChildProps
export function withWorkspaceSetupIntent<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables,
    WorkspaceSetupIntentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables,
    WorkspaceSetupIntentProps<TChildProps, TDataName>
  >(WorkspaceSetupIntentDocument, {
    alias: 'workspaceSetupIntent',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceSetupIntentMutation__
 *
 * To run a mutation, you first call `useWorkspaceSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceSetupIntentMutation, { data, loading, error }] = useWorkspaceSetupIntentMutation({
 *   variables: {
 *      setupIntentArgs: // value for 'setupIntentArgs'
 *   },
 * });
 */
export function useWorkspaceSetupIntentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >(WorkspaceSetupIntentDocument, baseOptions)
}
export type WorkspaceSetupIntentMutationHookResult = ReturnType<
  typeof useWorkspaceSetupIntentMutation
>
export type WorkspaceSetupIntentMutationResult = ApolloReactCommon.MutationResult<WorkspaceSetupIntentMutation>
export type WorkspaceSetupIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceSetupIntentMutation,
  WorkspaceSetupIntentMutationVariables
>
export const WorkspaceAddPaymentMethodDocument = gql`
  mutation WorkspaceAddPaymentMethod($paymentMethod: PaymentMethodInput) {
    paymentMethod: WorkspaceAddPaymentMethod(paymentMethod: $paymentMethod)
  }
`
export type WorkspaceAddPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceAddPaymentMethodMutation,
  WorkspaceAddPaymentMethodMutationVariables
>
export type WorkspaceAddPaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceAddPaymentMethodComponent = (
  props: WorkspaceAddPaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
    mutation={WorkspaceAddPaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceAddPaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceAddPaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables,
    WorkspaceAddPaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables,
    WorkspaceAddPaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceAddPaymentMethodDocument, {
    alias: 'workspaceAddPaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceAddPaymentMethodMutation, { data, loading, error }] = useWorkspaceAddPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useWorkspaceAddPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >(WorkspaceAddPaymentMethodDocument, baseOptions)
}
export type WorkspaceAddPaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceAddPaymentMethodMutation
>
export type WorkspaceAddPaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceAddPaymentMethodMutation>
export type WorkspaceAddPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceAddPaymentMethodMutation,
  WorkspaceAddPaymentMethodMutationVariables
>
export const WorkspaceSetDefaultPaymentMethodDocument = gql`
  mutation WorkspaceSetDefaultPaymentMethod($paymentMethodId: String!) {
    WorkspaceSetDefaultPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`
export type WorkspaceSetDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceSetDefaultPaymentMethodMutation,
  WorkspaceSetDefaultPaymentMethodMutationVariables
>
export type WorkspaceSetDefaultPaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceSetDefaultPaymentMethodComponent = (
  props: WorkspaceSetDefaultPaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
    mutation={WorkspaceSetDefaultPaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceSetDefaultPaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceSetDefaultPaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables,
    WorkspaceSetDefaultPaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables,
    WorkspaceSetDefaultPaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceSetDefaultPaymentMethodDocument, {
    alias: 'workspaceSetDefaultPaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceSetDefaultPaymentMethodMutation, { data, loading, error }] = useWorkspaceSetDefaultPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useWorkspaceSetDefaultPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >(WorkspaceSetDefaultPaymentMethodDocument, baseOptions)
}
export type WorkspaceSetDefaultPaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceSetDefaultPaymentMethodMutation
>
export type WorkspaceSetDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceSetDefaultPaymentMethodMutation>
export type WorkspaceSetDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceSetDefaultPaymentMethodMutation,
  WorkspaceSetDefaultPaymentMethodMutationVariables
>
export const WorkspaceRemovePaymentMethodDocument = gql`
  mutation WorkspaceRemovePaymentMethod($paymentMethodId: String!) {
    WorkspaceRemovePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`
export type WorkspaceRemovePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceRemovePaymentMethodMutation,
  WorkspaceRemovePaymentMethodMutationVariables
>
export type WorkspaceRemovePaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceRemovePaymentMethodComponent = (
  props: WorkspaceRemovePaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
    mutation={WorkspaceRemovePaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceRemovePaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceRemovePaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables,
    WorkspaceRemovePaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables,
    WorkspaceRemovePaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceRemovePaymentMethodDocument, {
    alias: 'workspaceRemovePaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceRemovePaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceRemovePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceRemovePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceRemovePaymentMethodMutation, { data, loading, error }] = useWorkspaceRemovePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useWorkspaceRemovePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >(WorkspaceRemovePaymentMethodDocument, baseOptions)
}
export type WorkspaceRemovePaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceRemovePaymentMethodMutation
>
export type WorkspaceRemovePaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceRemovePaymentMethodMutation>
export type WorkspaceRemovePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceRemovePaymentMethodMutation,
  WorkspaceRemovePaymentMethodMutationVariables
>
export const WorkspacePayInvoiceDocument = gql`
  mutation WorkspacePayInvoice($invoiceId: String!) {
    WorkspacePayInvoice(invoiceId: $invoiceId)
  }
`
export type WorkspacePayInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  WorkspacePayInvoiceMutation,
  WorkspacePayInvoiceMutationVariables
>
export type WorkspacePayInvoiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >,
  'mutation'
>

export const WorkspacePayInvoiceComponent = (
  props: WorkspacePayInvoiceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
    mutation={WorkspacePayInvoiceDocument}
    {...props}
  />
)

export type WorkspacePayInvoiceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
} &
  TChildProps
export function withWorkspacePayInvoice<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables,
    WorkspacePayInvoiceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables,
    WorkspacePayInvoiceProps<TChildProps, TDataName>
  >(WorkspacePayInvoiceDocument, {
    alias: 'workspacePayInvoice',
    ...operationOptions,
  })
}

/**
 * __useWorkspacePayInvoiceMutation__
 *
 * To run a mutation, you first call `useWorkspacePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePayInvoiceMutation, { data, loading, error }] = useWorkspacePayInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useWorkspacePayInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >(WorkspacePayInvoiceDocument, baseOptions)
}
export type WorkspacePayInvoiceMutationHookResult = ReturnType<
  typeof useWorkspacePayInvoiceMutation
>
export type WorkspacePayInvoiceMutationResult = ApolloReactCommon.MutationResult<WorkspacePayInvoiceMutation>
export type WorkspacePayInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspacePayInvoiceMutation,
  WorkspacePayInvoiceMutationVariables
>
export const CreateWebhookDocument = gql`
  mutation CreateWebhook($input: CreateOneWebhookInput!) {
    webhook: CreateWebhook(record: $input) {
      _id: recordId
    }
  }
`
export type CreateWebhookMutationFn = ApolloReactCommon.MutationFunction<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>
export type CreateWebhookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >,
  'mutation'
>

export const CreateWebhookComponent = (props: CreateWebhookComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
    mutation={CreateWebhookDocument}
    {...props}
  />
)

export type CreateWebhookProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
} &
  TChildProps
export function withCreateWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateWebhookMutation,
    CreateWebhookMutationVariables,
    CreateWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateWebhookMutation,
    CreateWebhookMutationVariables,
    CreateWebhookProps<TChildProps, TDataName>
  >(CreateWebhookDocument, {
    alias: 'createWebhook',
    ...operationOptions,
  })
}

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >(CreateWebhookDocument, baseOptions)
}
export type CreateWebhookMutationHookResult = ReturnType<
  typeof useCreateWebhookMutation
>
export type CreateWebhookMutationResult = ApolloReactCommon.MutationResult<CreateWebhookMutation>
export type CreateWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>
export const UpdateWebhookByIdDocument = gql`
  mutation UpdateWebhookById($_id: MongoID!, $input: UpdateOneWebhookInput!) {
    webhook: UpdateWebhook(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateWebhookByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWebhookByIdMutation,
  UpdateWebhookByIdMutationVariables
>
export type UpdateWebhookByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >,
  'mutation'
>

export const UpdateWebhookByIdComponent = (
  props: UpdateWebhookByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
    mutation={UpdateWebhookByIdDocument}
    {...props}
  />
)

export type UpdateWebhookByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateWebhookById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables,
    UpdateWebhookByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables,
    UpdateWebhookByIdProps<TChildProps, TDataName>
  >(UpdateWebhookByIdDocument, {
    alias: 'updateWebhookById',
    ...operationOptions,
  })
}

/**
 * __useUpdateWebhookByIdMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookByIdMutation, { data, loading, error }] = useUpdateWebhookByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >(UpdateWebhookByIdDocument, baseOptions)
}
export type UpdateWebhookByIdMutationHookResult = ReturnType<
  typeof useUpdateWebhookByIdMutation
>
export type UpdateWebhookByIdMutationResult = ApolloReactCommon.MutationResult<UpdateWebhookByIdMutation>
export type UpdateWebhookByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWebhookByIdMutation,
  UpdateWebhookByIdMutationVariables
>
export const ArchiveWebhookDocument = gql`
  mutation ArchiveWebhook($ids: [MongoID!]!) {
    areArchived: ArchiveWebhooks(ids: $ids)
  }
`
export type ArchiveWebhookMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveWebhookMutation,
  ArchiveWebhookMutationVariables
>
export type ArchiveWebhookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >,
  'mutation'
>

export const ArchiveWebhookComponent = (
  props: ArchiveWebhookComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
    mutation={ArchiveWebhookDocument}
    {...props}
  />
)

export type ArchiveWebhookProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
} &
  TChildProps
export function withArchiveWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables,
    ArchiveWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables,
    ArchiveWebhookProps<TChildProps, TDataName>
  >(ArchiveWebhookDocument, {
    alias: 'archiveWebhook',
    ...operationOptions,
  })
}

/**
 * __useArchiveWebhookMutation__
 *
 * To run a mutation, you first call `useArchiveWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWebhookMutation, { data, loading, error }] = useArchiveWebhookMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >(ArchiveWebhookDocument, baseOptions)
}
export type ArchiveWebhookMutationHookResult = ReturnType<
  typeof useArchiveWebhookMutation
>
export type ArchiveWebhookMutationResult = ApolloReactCommon.MutationResult<ArchiveWebhookMutation>
export type ArchiveWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveWebhookMutation,
  ArchiveWebhookMutationVariables
>
export const UpdateCreativeByIdDocument = gql`
  mutation UpdateCreativeById($_id: MongoID!, $input: UpdateOneCreativeInput!) {
    creative: UpdateCreative(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateCreativeByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCreativeByIdMutation,
  UpdateCreativeByIdMutationVariables
>
export type UpdateCreativeByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCreativeByIdComponent = (
  props: UpdateCreativeByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
    mutation={UpdateCreativeByIdDocument}
    {...props}
  />
)

export type UpdateCreativeByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCreativeById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables,
    UpdateCreativeByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables,
    UpdateCreativeByIdProps<TChildProps, TDataName>
  >(UpdateCreativeByIdDocument, {
    alias: 'updateCreativeById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCreativeByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeByIdMutation, { data, loading, error }] = useUpdateCreativeByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreativeByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >(UpdateCreativeByIdDocument, baseOptions)
}
export type UpdateCreativeByIdMutationHookResult = ReturnType<
  typeof useUpdateCreativeByIdMutation
>
export type UpdateCreativeByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCreativeByIdMutation>
export type UpdateCreativeByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCreativeByIdMutation,
  UpdateCreativeByIdMutationVariables
>
export const RestoreCampaignsDocument = gql`
  mutation RestoreCampaigns($ids: [MongoID!]!) {
    areRestored: UnarchiveCampaigns(ids: $ids)
  }
`
export type RestoreCampaignsMutationFn = ApolloReactCommon.MutationFunction<
  RestoreCampaignsMutation,
  RestoreCampaignsMutationVariables
>
export type RestoreCampaignsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables
  >,
  'mutation'
>

export const RestoreCampaignsComponent = (
  props: RestoreCampaignsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables
  >
    mutation={RestoreCampaignsDocument}
    {...props}
  />
)

export type RestoreCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables
  >
} &
  TChildProps
export function withRestoreCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables,
    RestoreCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables,
    RestoreCampaignsProps<TChildProps, TDataName>
  >(RestoreCampaignsDocument, {
    alias: 'restoreCampaigns',
    ...operationOptions,
  })
}

/**
 * __useRestoreCampaignsMutation__
 *
 * To run a mutation, you first call `useRestoreCampaignsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCampaignsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCampaignsMutation, { data, loading, error }] = useRestoreCampaignsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreCampaignsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreCampaignsMutation,
    RestoreCampaignsMutationVariables
  >(RestoreCampaignsDocument, baseOptions)
}
export type RestoreCampaignsMutationHookResult = ReturnType<
  typeof useRestoreCampaignsMutation
>
export type RestoreCampaignsMutationResult = ApolloReactCommon.MutationResult<RestoreCampaignsMutation>
export type RestoreCampaignsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreCampaignsMutation,
  RestoreCampaignsMutationVariables
>
export const RestoreCampaignAdGroupsDocument = gql`
  mutation RestoreCampaignAdGroups($ids: [MongoID!]!) {
    areRestored: UnarchiveCampaignAdGroups(ids: $ids)
  }
`
export type RestoreCampaignAdGroupsMutationFn = ApolloReactCommon.MutationFunction<
  RestoreCampaignAdGroupsMutation,
  RestoreCampaignAdGroupsMutationVariables
>
export type RestoreCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables
  >,
  'mutation'
>

export const RestoreCampaignAdGroupsComponent = (
  props: RestoreCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables
  >
    mutation={RestoreCampaignAdGroupsDocument}
    {...props}
  />
)

export type RestoreCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables
  >
} &
  TChildProps
export function withRestoreCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables,
    RestoreCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables,
    RestoreCampaignAdGroupsProps<TChildProps, TDataName>
  >(RestoreCampaignAdGroupsDocument, {
    alias: 'restoreCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useRestoreCampaignAdGroupsMutation__
 *
 * To run a mutation, you first call `useRestoreCampaignAdGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCampaignAdGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCampaignAdGroupsMutation, { data, loading, error }] = useRestoreCampaignAdGroupsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreCampaignAdGroupsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreCampaignAdGroupsMutation,
    RestoreCampaignAdGroupsMutationVariables
  >(RestoreCampaignAdGroupsDocument, baseOptions)
}
export type RestoreCampaignAdGroupsMutationHookResult = ReturnType<
  typeof useRestoreCampaignAdGroupsMutation
>
export type RestoreCampaignAdGroupsMutationResult = ApolloReactCommon.MutationResult<RestoreCampaignAdGroupsMutation>
export type RestoreCampaignAdGroupsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreCampaignAdGroupsMutation,
  RestoreCampaignAdGroupsMutationVariables
>
export const RestoreCreativesDocument = gql`
  mutation RestoreCreatives($ids: [MongoID!]!) {
    areRestored: UnarchiveCreatives(ids: $ids)
  }
`
export type RestoreCreativesMutationFn = ApolloReactCommon.MutationFunction<
  RestoreCreativesMutation,
  RestoreCreativesMutationVariables
>
export type RestoreCreativesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables
  >,
  'mutation'
>

export const RestoreCreativesComponent = (
  props: RestoreCreativesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables
  >
    mutation={RestoreCreativesDocument}
    {...props}
  />
)

export type RestoreCreativesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables
  >
} &
  TChildProps
export function withRestoreCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables,
    RestoreCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables,
    RestoreCreativesProps<TChildProps, TDataName>
  >(RestoreCreativesDocument, {
    alias: 'restoreCreatives',
    ...operationOptions,
  })
}

/**
 * __useRestoreCreativesMutation__
 *
 * To run a mutation, you first call `useRestoreCreativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCreativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCreativesMutation, { data, loading, error }] = useRestoreCreativesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreCreativesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreCreativesMutation,
    RestoreCreativesMutationVariables
  >(RestoreCreativesDocument, baseOptions)
}
export type RestoreCreativesMutationHookResult = ReturnType<
  typeof useRestoreCreativesMutation
>
export type RestoreCreativesMutationResult = ApolloReactCommon.MutationResult<RestoreCreativesMutation>
export type RestoreCreativesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreCreativesMutation,
  RestoreCreativesMutationVariables
>
export const RestoreNetworksDocument = gql`
  mutation RestoreNetworks($ids: [MongoID!]!) {
    areRestored: UnarchiveNetworks(ids: $ids)
  }
`
export type RestoreNetworksMutationFn = ApolloReactCommon.MutationFunction<
  RestoreNetworksMutation,
  RestoreNetworksMutationVariables
>
export type RestoreNetworksComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables
  >,
  'mutation'
>

export const RestoreNetworksComponent = (
  props: RestoreNetworksComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables
  >
    mutation={RestoreNetworksDocument}
    {...props}
  />
)

export type RestoreNetworksProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables
  >
} &
  TChildProps
export function withRestoreNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables,
    RestoreNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables,
    RestoreNetworksProps<TChildProps, TDataName>
  >(RestoreNetworksDocument, {
    alias: 'restoreNetworks',
    ...operationOptions,
  })
}

/**
 * __useRestoreNetworksMutation__
 *
 * To run a mutation, you first call `useRestoreNetworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreNetworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreNetworksMutation, { data, loading, error }] = useRestoreNetworksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreNetworksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreNetworksMutation,
    RestoreNetworksMutationVariables
  >(RestoreNetworksDocument, baseOptions)
}
export type RestoreNetworksMutationHookResult = ReturnType<
  typeof useRestoreNetworksMutation
>
export type RestoreNetworksMutationResult = ApolloReactCommon.MutationResult<RestoreNetworksMutation>
export type RestoreNetworksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreNetworksMutation,
  RestoreNetworksMutationVariables
>
export const RestorePlayerDocument = gql`
  mutation RestorePlayer($id: MongoID!, $network_ids: [MongoID]!) {
    isRestored: UnarchivePlayer(_id: $id, network_ids: $network_ids)
  }
`
export type RestorePlayerMutationFn = ApolloReactCommon.MutationFunction<
  RestorePlayerMutation,
  RestorePlayerMutationVariables
>
export type RestorePlayerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RestorePlayerMutation,
    RestorePlayerMutationVariables
  >,
  'mutation'
>

export const RestorePlayerComponent = (props: RestorePlayerComponentProps) => (
  <ApolloReactComponents.Mutation<
    RestorePlayerMutation,
    RestorePlayerMutationVariables
  >
    mutation={RestorePlayerDocument}
    {...props}
  />
)

export type RestorePlayerProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RestorePlayerMutation,
    RestorePlayerMutationVariables
  >
} &
  TChildProps
export function withRestorePlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestorePlayerMutation,
    RestorePlayerMutationVariables,
    RestorePlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestorePlayerMutation,
    RestorePlayerMutationVariables,
    RestorePlayerProps<TChildProps, TDataName>
  >(RestorePlayerDocument, {
    alias: 'restorePlayer',
    ...operationOptions,
  })
}

/**
 * __useRestorePlayerMutation__
 *
 * To run a mutation, you first call `useRestorePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePlayerMutation, { data, loading, error }] = useRestorePlayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      network_ids: // value for 'network_ids'
 *   },
 * });
 */
export function useRestorePlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestorePlayerMutation,
    RestorePlayerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestorePlayerMutation,
    RestorePlayerMutationVariables
  >(RestorePlayerDocument, baseOptions)
}
export type RestorePlayerMutationHookResult = ReturnType<
  typeof useRestorePlayerMutation
>
export type RestorePlayerMutationResult = ApolloReactCommon.MutationResult<RestorePlayerMutation>
export type RestorePlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestorePlayerMutation,
  RestorePlayerMutationVariables
>
export const ManagePlayersByNetworkDocument = gql`
  mutation ManagePlayersByNetwork(
    $network_id: MongoID!
    $players_ids: [MongoID!]!
  ) {
    isUpdated: UpdatePlayersFromNetwork(
      network_id: $network_id
      player_ids: $players_ids
    )
  }
`
export type ManagePlayersByNetworkMutationFn = ApolloReactCommon.MutationFunction<
  ManagePlayersByNetworkMutation,
  ManagePlayersByNetworkMutationVariables
>
export type ManagePlayersByNetworkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables
  >,
  'mutation'
>

export const ManagePlayersByNetworkComponent = (
  props: ManagePlayersByNetworkComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables
  >
    mutation={ManagePlayersByNetworkDocument}
    {...props}
  />
)

export type ManagePlayersByNetworkProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables
  >
} &
  TChildProps
export function withManagePlayersByNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables,
    ManagePlayersByNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables,
    ManagePlayersByNetworkProps<TChildProps, TDataName>
  >(ManagePlayersByNetworkDocument, {
    alias: 'managePlayersByNetwork',
    ...operationOptions,
  })
}

/**
 * __useManagePlayersByNetworkMutation__
 *
 * To run a mutation, you first call `useManagePlayersByNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePlayersByNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePlayersByNetworkMutation, { data, loading, error }] = useManagePlayersByNetworkMutation({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      players_ids: // value for 'players_ids'
 *   },
 * });
 */
export function useManagePlayersByNetworkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ManagePlayersByNetworkMutation,
    ManagePlayersByNetworkMutationVariables
  >(ManagePlayersByNetworkDocument, baseOptions)
}
export type ManagePlayersByNetworkMutationHookResult = ReturnType<
  typeof useManagePlayersByNetworkMutation
>
export type ManagePlayersByNetworkMutationResult = ApolloReactCommon.MutationResult<ManagePlayersByNetworkMutation>
export type ManagePlayersByNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ManagePlayersByNetworkMutation,
  ManagePlayersByNetworkMutationVariables
>
export const ManageBroadcastTargetsByCampaignDocument = gql`
  mutation ManageBroadcastTargetsByCampaign(
    $_id: MongoID!
    $targets: [UpdateBroadcastTargetsFromCampaignTargets!]!
  ) {
    isUpdated: UpdateBroadcastTargetsFromCampaign(_id: $_id, targets: $targets)
  }
`
export type ManageBroadcastTargetsByCampaignMutationFn = ApolloReactCommon.MutationFunction<
  ManageBroadcastTargetsByCampaignMutation,
  ManageBroadcastTargetsByCampaignMutationVariables
>
export type ManageBroadcastTargetsByCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables
  >,
  'mutation'
>

export const ManageBroadcastTargetsByCampaignComponent = (
  props: ManageBroadcastTargetsByCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables
  >
    mutation={ManageBroadcastTargetsByCampaignDocument}
    {...props}
  />
)

export type ManageBroadcastTargetsByCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables
  >
} &
  TChildProps
export function withManageBroadcastTargetsByCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables,
    ManageBroadcastTargetsByCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables,
    ManageBroadcastTargetsByCampaignProps<TChildProps, TDataName>
  >(ManageBroadcastTargetsByCampaignDocument, {
    alias: 'manageBroadcastTargetsByCampaign',
    ...operationOptions,
  })
}

/**
 * __useManageBroadcastTargetsByCampaignMutation__
 *
 * To run a mutation, you first call `useManageBroadcastTargetsByCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageBroadcastTargetsByCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageBroadcastTargetsByCampaignMutation, { data, loading, error }] = useManageBroadcastTargetsByCampaignMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      targets: // value for 'targets'
 *   },
 * });
 */
export function useManageBroadcastTargetsByCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ManageBroadcastTargetsByCampaignMutation,
    ManageBroadcastTargetsByCampaignMutationVariables
  >(ManageBroadcastTargetsByCampaignDocument, baseOptions)
}
export type ManageBroadcastTargetsByCampaignMutationHookResult = ReturnType<
  typeof useManageBroadcastTargetsByCampaignMutation
>
export type ManageBroadcastTargetsByCampaignMutationResult = ApolloReactCommon.MutationResult<ManageBroadcastTargetsByCampaignMutation>
export type ManageBroadcastTargetsByCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ManageBroadcastTargetsByCampaignMutation,
  ManageBroadcastTargetsByCampaignMutationVariables
>
export const CancelBroadcastTargetsDocument = gql`
  mutation CancelBroadcastTargets(
    $campaign_id: MongoID!
    $broadcastTargetIds: [MongoID!]!
  ) {
    areCancelled: CancelBroadcastTargets(
      campaign_id: $campaign_id
      broadcastTargetIds: $broadcastTargetIds
    )
  }
`
export type CancelBroadcastTargetsMutationFn = ApolloReactCommon.MutationFunction<
  CancelBroadcastTargetsMutation,
  CancelBroadcastTargetsMutationVariables
>
export type CancelBroadcastTargetsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables
  >,
  'mutation'
>

export const CancelBroadcastTargetsComponent = (
  props: CancelBroadcastTargetsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables
  >
    mutation={CancelBroadcastTargetsDocument}
    {...props}
  />
)

export type CancelBroadcastTargetsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables
  >
} &
  TChildProps
export function withCancelBroadcastTargets<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables,
    CancelBroadcastTargetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables,
    CancelBroadcastTargetsProps<TChildProps, TDataName>
  >(CancelBroadcastTargetsDocument, {
    alias: 'cancelBroadcastTargets',
    ...operationOptions,
  })
}

/**
 * __useCancelBroadcastTargetsMutation__
 *
 * To run a mutation, you first call `useCancelBroadcastTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBroadcastTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBroadcastTargetsMutation, { data, loading, error }] = useCancelBroadcastTargetsMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      broadcastTargetIds: // value for 'broadcastTargetIds'
 *   },
 * });
 */
export function useCancelBroadcastTargetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelBroadcastTargetsMutation,
    CancelBroadcastTargetsMutationVariables
  >(CancelBroadcastTargetsDocument, baseOptions)
}
export type CancelBroadcastTargetsMutationHookResult = ReturnType<
  typeof useCancelBroadcastTargetsMutation
>
export type CancelBroadcastTargetsMutationResult = ApolloReactCommon.MutationResult<CancelBroadcastTargetsMutation>
export type CancelBroadcastTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelBroadcastTargetsMutation,
  CancelBroadcastTargetsMutationVariables
>
export const SubmitBroadcastTargetsDocument = gql`
  mutation SubmitBroadcastTargets(
    $campaign_id: MongoID!
    $broadcastTargetIds: [MongoID!]!
  ) {
    areSubmitted: SubmitBroadcastTargets(
      campaign_id: $campaign_id
      broadcastTargetIds: $broadcastTargetIds
    )
  }
`
export type SubmitBroadcastTargetsMutationFn = ApolloReactCommon.MutationFunction<
  SubmitBroadcastTargetsMutation,
  SubmitBroadcastTargetsMutationVariables
>
export type SubmitBroadcastTargetsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables
  >,
  'mutation'
>

export const SubmitBroadcastTargetsComponent = (
  props: SubmitBroadcastTargetsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables
  >
    mutation={SubmitBroadcastTargetsDocument}
    {...props}
  />
)

export type SubmitBroadcastTargetsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables
  >
} &
  TChildProps
export function withSubmitBroadcastTargets<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables,
    SubmitBroadcastTargetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables,
    SubmitBroadcastTargetsProps<TChildProps, TDataName>
  >(SubmitBroadcastTargetsDocument, {
    alias: 'submitBroadcastTargets',
    ...operationOptions,
  })
}

/**
 * __useSubmitBroadcastTargetsMutation__
 *
 * To run a mutation, you first call `useSubmitBroadcastTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBroadcastTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBroadcastTargetsMutation, { data, loading, error }] = useSubmitBroadcastTargetsMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      broadcastTargetIds: // value for 'broadcastTargetIds'
 *   },
 * });
 */
export function useSubmitBroadcastTargetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SubmitBroadcastTargetsMutation,
    SubmitBroadcastTargetsMutationVariables
  >(SubmitBroadcastTargetsDocument, baseOptions)
}
export type SubmitBroadcastTargetsMutationHookResult = ReturnType<
  typeof useSubmitBroadcastTargetsMutation
>
export type SubmitBroadcastTargetsMutationResult = ApolloReactCommon.MutationResult<SubmitBroadcastTargetsMutation>
export type SubmitBroadcastTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitBroadcastTargetsMutation,
  SubmitBroadcastTargetsMutationVariables
>
export const RevokeBroadcastTargetsDocument = gql`
  mutation RevokeBroadcastTargets(
    $campaign_id: MongoID!
    $broadcastTargetIds: [MongoID!]!
  ) {
    areRevoked: RevokeBroadcastTargets(
      campaign_id: $campaign_id
      broadcastTargetIds: $broadcastTargetIds
    )
  }
`
export type RevokeBroadcastTargetsMutationFn = ApolloReactCommon.MutationFunction<
  RevokeBroadcastTargetsMutation,
  RevokeBroadcastTargetsMutationVariables
>
export type RevokeBroadcastTargetsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables
  >,
  'mutation'
>

export const RevokeBroadcastTargetsComponent = (
  props: RevokeBroadcastTargetsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables
  >
    mutation={RevokeBroadcastTargetsDocument}
    {...props}
  />
)

export type RevokeBroadcastTargetsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables
  >
} &
  TChildProps
export function withRevokeBroadcastTargets<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables,
    RevokeBroadcastTargetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables,
    RevokeBroadcastTargetsProps<TChildProps, TDataName>
  >(RevokeBroadcastTargetsDocument, {
    alias: 'revokeBroadcastTargets',
    ...operationOptions,
  })
}

/**
 * __useRevokeBroadcastTargetsMutation__
 *
 * To run a mutation, you first call `useRevokeBroadcastTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeBroadcastTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeBroadcastTargetsMutation, { data, loading, error }] = useRevokeBroadcastTargetsMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      broadcastTargetIds: // value for 'broadcastTargetIds'
 *   },
 * });
 */
export function useRevokeBroadcastTargetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RevokeBroadcastTargetsMutation,
    RevokeBroadcastTargetsMutationVariables
  >(RevokeBroadcastTargetsDocument, baseOptions)
}
export type RevokeBroadcastTargetsMutationHookResult = ReturnType<
  typeof useRevokeBroadcastTargetsMutation
>
export type RevokeBroadcastTargetsMutationResult = ApolloReactCommon.MutationResult<RevokeBroadcastTargetsMutation>
export type RevokeBroadcastTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeBroadcastTargetsMutation,
  RevokeBroadcastTargetsMutationVariables
>
export const ClearBroadcastTargetsDocument = gql`
  mutation ClearBroadcastTargets(
    $campaign_id: MongoID!
    $broadcastTargetIds: [MongoID!]!
  ) {
    areCleared: ClearBroadcastTargets(
      campaign_id: $campaign_id
      broadcastTargetIds: $broadcastTargetIds
    )
  }
`
export type ClearBroadcastTargetsMutationFn = ApolloReactCommon.MutationFunction<
  ClearBroadcastTargetsMutation,
  ClearBroadcastTargetsMutationVariables
>
export type ClearBroadcastTargetsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables
  >,
  'mutation'
>

export const ClearBroadcastTargetsComponent = (
  props: ClearBroadcastTargetsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables
  >
    mutation={ClearBroadcastTargetsDocument}
    {...props}
  />
)

export type ClearBroadcastTargetsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables
  >
} &
  TChildProps
export function withClearBroadcastTargets<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables,
    ClearBroadcastTargetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables,
    ClearBroadcastTargetsProps<TChildProps, TDataName>
  >(ClearBroadcastTargetsDocument, {
    alias: 'clearBroadcastTargets',
    ...operationOptions,
  })
}

/**
 * __useClearBroadcastTargetsMutation__
 *
 * To run a mutation, you first call `useClearBroadcastTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearBroadcastTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearBroadcastTargetsMutation, { data, loading, error }] = useClearBroadcastTargetsMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      broadcastTargetIds: // value for 'broadcastTargetIds'
 *   },
 * });
 */
export function useClearBroadcastTargetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ClearBroadcastTargetsMutation,
    ClearBroadcastTargetsMutationVariables
  >(ClearBroadcastTargetsDocument, baseOptions)
}
export type ClearBroadcastTargetsMutationHookResult = ReturnType<
  typeof useClearBroadcastTargetsMutation
>
export type ClearBroadcastTargetsMutationResult = ApolloReactCommon.MutationResult<ClearBroadcastTargetsMutation>
export type ClearBroadcastTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearBroadcastTargetsMutation,
  ClearBroadcastTargetsMutationVariables
>
export const ApproveExternalCampaignDocument = gql`
  mutation ApproveExternalCampaign(
    $network_id: MongoID!
    $campaign_id: MongoID!
  ) {
    isApproved: ApproveExternalCampaign(
      network_id: $network_id
      campaign_id: $campaign_id
    )
  }
`
export type ApproveExternalCampaignMutationFn = ApolloReactCommon.MutationFunction<
  ApproveExternalCampaignMutation,
  ApproveExternalCampaignMutationVariables
>
export type ApproveExternalCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables
  >,
  'mutation'
>

export const ApproveExternalCampaignComponent = (
  props: ApproveExternalCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables
  >
    mutation={ApproveExternalCampaignDocument}
    {...props}
  />
)

export type ApproveExternalCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables
  >
} &
  TChildProps
export function withApproveExternalCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables,
    ApproveExternalCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables,
    ApproveExternalCampaignProps<TChildProps, TDataName>
  >(ApproveExternalCampaignDocument, {
    alias: 'approveExternalCampaign',
    ...operationOptions,
  })
}

/**
 * __useApproveExternalCampaignMutation__
 *
 * To run a mutation, you first call `useApproveExternalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveExternalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveExternalCampaignMutation, { data, loading, error }] = useApproveExternalCampaignMutation({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      campaign_id: // value for 'campaign_id'
 *   },
 * });
 */
export function useApproveExternalCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ApproveExternalCampaignMutation,
    ApproveExternalCampaignMutationVariables
  >(ApproveExternalCampaignDocument, baseOptions)
}
export type ApproveExternalCampaignMutationHookResult = ReturnType<
  typeof useApproveExternalCampaignMutation
>
export type ApproveExternalCampaignMutationResult = ApolloReactCommon.MutationResult<ApproveExternalCampaignMutation>
export type ApproveExternalCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApproveExternalCampaignMutation,
  ApproveExternalCampaignMutationVariables
>
export const RejectExternalCampaignDocument = gql`
  mutation RejectExternalCampaign(
    $network_id: MongoID!
    $campaign_id: MongoID!
    $reviewNotes: String
  ) {
    isRejected: RejectExternalCampaign(
      network_id: $network_id
      campaign_id: $campaign_id
      reviewNotes: $reviewNotes
    )
  }
`
export type RejectExternalCampaignMutationFn = ApolloReactCommon.MutationFunction<
  RejectExternalCampaignMutation,
  RejectExternalCampaignMutationVariables
>
export type RejectExternalCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables
  >,
  'mutation'
>

export const RejectExternalCampaignComponent = (
  props: RejectExternalCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables
  >
    mutation={RejectExternalCampaignDocument}
    {...props}
  />
)

export type RejectExternalCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables
  >
} &
  TChildProps
export function withRejectExternalCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables,
    RejectExternalCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables,
    RejectExternalCampaignProps<TChildProps, TDataName>
  >(RejectExternalCampaignDocument, {
    alias: 'rejectExternalCampaign',
    ...operationOptions,
  })
}

/**
 * __useRejectExternalCampaignMutation__
 *
 * To run a mutation, you first call `useRejectExternalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectExternalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectExternalCampaignMutation, { data, loading, error }] = useRejectExternalCampaignMutation({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      campaign_id: // value for 'campaign_id'
 *      reviewNotes: // value for 'reviewNotes'
 *   },
 * });
 */
export function useRejectExternalCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RejectExternalCampaignMutation,
    RejectExternalCampaignMutationVariables
  >(RejectExternalCampaignDocument, baseOptions)
}
export type RejectExternalCampaignMutationHookResult = ReturnType<
  typeof useRejectExternalCampaignMutation
>
export type RejectExternalCampaignMutationResult = ApolloReactCommon.MutationResult<RejectExternalCampaignMutation>
export type RejectExternalCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectExternalCampaignMutation,
  RejectExternalCampaignMutationVariables
>
export const RevokeExternalCampaignDocument = gql`
  mutation RevokeExternalCampaign(
    $network_id: MongoID!
    $campaign_id: MongoID!
    $reviewNotes: String
  ) {
    isRevoked: RevokeExternalCampaign(
      network_id: $network_id
      campaign_id: $campaign_id
      reviewNotes: $reviewNotes
    )
  }
`
export type RevokeExternalCampaignMutationFn = ApolloReactCommon.MutationFunction<
  RevokeExternalCampaignMutation,
  RevokeExternalCampaignMutationVariables
>
export type RevokeExternalCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables
  >,
  'mutation'
>

export const RevokeExternalCampaignComponent = (
  props: RevokeExternalCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables
  >
    mutation={RevokeExternalCampaignDocument}
    {...props}
  />
)

export type RevokeExternalCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables
  >
} &
  TChildProps
export function withRevokeExternalCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables,
    RevokeExternalCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables,
    RevokeExternalCampaignProps<TChildProps, TDataName>
  >(RevokeExternalCampaignDocument, {
    alias: 'revokeExternalCampaign',
    ...operationOptions,
  })
}

/**
 * __useRevokeExternalCampaignMutation__
 *
 * To run a mutation, you first call `useRevokeExternalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeExternalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeExternalCampaignMutation, { data, loading, error }] = useRevokeExternalCampaignMutation({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      campaign_id: // value for 'campaign_id'
 *      reviewNotes: // value for 'reviewNotes'
 *   },
 * });
 */
export function useRevokeExternalCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RevokeExternalCampaignMutation,
    RevokeExternalCampaignMutationVariables
  >(RevokeExternalCampaignDocument, baseOptions)
}
export type RevokeExternalCampaignMutationHookResult = ReturnType<
  typeof useRevokeExternalCampaignMutation
>
export type RevokeExternalCampaignMutationResult = ApolloReactCommon.MutationResult<RevokeExternalCampaignMutation>
export type RevokeExternalCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeExternalCampaignMutation,
  RevokeExternalCampaignMutationVariables
>
export const CountItemsDocument = gql`
  query CountItems {
    campaigns: CountCampaigns
    adGroups: CountCampaignAdGroups
    creatives: CountCreatives
    networks: CountNetworks
    players: CountPlayers
  }
`
export type CountItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountItemsQuery,
    CountItemsQueryVariables
  >,
  'query'
>

export const CountItemsComponent = (props: CountItemsComponentProps) => (
  <ApolloReactComponents.Query<CountItemsQuery, CountItemsQueryVariables>
    query={CountItemsDocument}
    {...props}
  />
)

export type CountItemsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountItemsQuery,
    CountItemsQueryVariables
  >
} &
  TChildProps
export function withCountItems<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountItemsQuery,
    CountItemsQueryVariables,
    CountItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountItemsQuery,
    CountItemsQueryVariables,
    CountItemsProps<TChildProps, TDataName>
  >(CountItemsDocument, {
    alias: 'countItems',
    ...operationOptions,
  })
}

/**
 * __useCountItemsQuery__
 *
 * To run a query within a React component, call `useCountItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountItemsQuery,
    CountItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CountItemsQuery, CountItemsQueryVariables>(
    CountItemsDocument,
    baseOptions
  )
}
export function useCountItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountItemsQuery,
    CountItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountItemsQuery,
    CountItemsQueryVariables
  >(CountItemsDocument, baseOptions)
}
export type CountItemsQueryHookResult = ReturnType<typeof useCountItemsQuery>
export type CountItemsLazyQueryHookResult = ReturnType<
  typeof useCountItemsLazyQuery
>
export type CountItemsQueryResult = ApolloReactCommon.QueryResult<
  CountItemsQuery,
  CountItemsQueryVariables
>
export const CountCreativesDocument = gql`
  query CountCreatives($filter: FilterCreativeInput! = {}) {
    creatives: CountCreatives(filter: $filter)
  }
`
export type CountCreativesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountCreativesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountCreativesComponent = (
  props: CountCreativesComponentProps
) => (
  <ApolloReactComponents.Query<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >
    query={CountCreativesDocument}
    {...props}
  />
)

export type CountCreativesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >
} &
  TChildProps
export function withCountCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountCreativesQuery,
    CountCreativesQueryVariables,
    CountCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountCreativesQuery,
    CountCreativesQueryVariables,
    CountCreativesProps<TChildProps, TDataName>
  >(CountCreativesDocument, {
    alias: 'countCreatives',
    ...operationOptions,
  })
}

/**
 * __useCountCreativesQuery__
 *
 * To run a query within a React component, call `useCountCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCreativesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountCreativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >(CountCreativesDocument, baseOptions)
}
export function useCountCreativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountCreativesQuery,
    CountCreativesQueryVariables
  >(CountCreativesDocument, baseOptions)
}
export type CountCreativesQueryHookResult = ReturnType<
  typeof useCountCreativesQuery
>
export type CountCreativesLazyQueryHookResult = ReturnType<
  typeof useCountCreativesLazyQuery
>
export type CountCreativesQueryResult = ApolloReactCommon.QueryResult<
  CountCreativesQuery,
  CountCreativesQueryVariables
>
export const CountCampaignsDocument = gql`
  query CountCampaigns($filter: FilterCampaignInput! = {}) {
    campaigns: CountCampaigns(filter: $filter)
  }
`
export type CountCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountCampaignsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountCampaignsComponent = (
  props: CountCampaignsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >
    query={CountCampaignsDocument}
    {...props}
  />
)

export type CountCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >
} &
  TChildProps
export function withCountCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountCampaignsQuery,
    CountCampaignsQueryVariables,
    CountCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountCampaignsQuery,
    CountCampaignsQueryVariables,
    CountCampaignsProps<TChildProps, TDataName>
  >(CountCampaignsDocument, {
    alias: 'countCampaigns',
    ...operationOptions,
  })
}

/**
 * __useCountCampaignsQuery__
 *
 * To run a query within a React component, call `useCountCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >(CountCampaignsDocument, baseOptions)
}
export function useCountCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountCampaignsQuery,
    CountCampaignsQueryVariables
  >(CountCampaignsDocument, baseOptions)
}
export type CountCampaignsQueryHookResult = ReturnType<
  typeof useCountCampaignsQuery
>
export type CountCampaignsLazyQueryHookResult = ReturnType<
  typeof useCountCampaignsLazyQuery
>
export type CountCampaignsQueryResult = ApolloReactCommon.QueryResult<
  CountCampaignsQuery,
  CountCampaignsQueryVariables
>
export const CountNetworksDocument = gql`
  query CountNetworks($filter: FilterNetworkInput! = {}) {
    networks: CountNetworks(filter: $filter)
  }
`
export type CountNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountNetworksComponent = (props: CountNetworksComponentProps) => (
  <ApolloReactComponents.Query<CountNetworksQuery, CountNetworksQueryVariables>
    query={CountNetworksDocument}
    {...props}
  />
)

export type CountNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >
} &
  TChildProps
export function withCountNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountNetworksQuery,
    CountNetworksQueryVariables,
    CountNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountNetworksQuery,
    CountNetworksQueryVariables,
    CountNetworksProps<TChildProps, TDataName>
  >(CountNetworksDocument, {
    alias: 'countNetworks',
    ...operationOptions,
  })
}

/**
 * __useCountNetworksQuery__
 *
 * To run a query within a React component, call `useCountNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountNetworksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >(CountNetworksDocument, baseOptions)
}
export function useCountNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountNetworksQuery,
    CountNetworksQueryVariables
  >(CountNetworksDocument, baseOptions)
}
export type CountNetworksQueryHookResult = ReturnType<
  typeof useCountNetworksQuery
>
export type CountNetworksLazyQueryHookResult = ReturnType<
  typeof useCountNetworksLazyQuery
>
export type CountNetworksQueryResult = ApolloReactCommon.QueryResult<
  CountNetworksQuery,
  CountNetworksQueryVariables
>
export const CountPlayersDocument = gql`
  query CountPlayers($filter: FilterPlayerInput! = {}) {
    players: CountPlayers(filter: $filter)
  }
`
export type CountPlayersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountPlayersQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountPlayersComponent = (props: CountPlayersComponentProps) => (
  <ApolloReactComponents.Query<CountPlayersQuery, CountPlayersQueryVariables>
    query={CountPlayersDocument}
    {...props}
  />
)

export type CountPlayersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >
} &
  TChildProps
export function withCountPlayers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountPlayersQuery,
    CountPlayersQueryVariables,
    CountPlayersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountPlayersQuery,
    CountPlayersQueryVariables,
    CountPlayersProps<TChildProps, TDataName>
  >(CountPlayersDocument, {
    alias: 'countPlayers',
    ...operationOptions,
  })
}

/**
 * __useCountPlayersQuery__
 *
 * To run a query within a React component, call `useCountPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPlayersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountPlayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >(CountPlayersDocument, baseOptions)
}
export function useCountPlayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountPlayersQuery,
    CountPlayersQueryVariables
  >(CountPlayersDocument, baseOptions)
}
export type CountPlayersQueryHookResult = ReturnType<
  typeof useCountPlayersQuery
>
export type CountPlayersLazyQueryHookResult = ReturnType<
  typeof useCountPlayersLazyQuery
>
export type CountPlayersQueryResult = ApolloReactCommon.QueryResult<
  CountPlayersQuery,
  CountPlayersQueryVariables
>
export const CountCampaignAdGroupsDocument = gql`
  query CountCampaignAdGroups($filter: FilterCampaignAdGroupInput! = {}) {
    adGroups: CountCampaignAdGroups(filter: $filter)
  }
`
export type CountCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountCampaignAdGroupsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountCampaignAdGroupsComponent = (
  props: CountCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >
    query={CountCampaignAdGroupsDocument}
    {...props}
  />
)

export type CountCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >
} &
  TChildProps
export function withCountCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables,
    CountCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables,
    CountCampaignAdGroupsProps<TChildProps, TDataName>
  >(CountCampaignAdGroupsDocument, {
    alias: 'countCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useCountCampaignAdGroupsQuery__
 *
 * To run a query within a React component, call `useCountCampaignAdGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCampaignAdGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCampaignAdGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountCampaignAdGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >(CountCampaignAdGroupsDocument, baseOptions)
}
export function useCountCampaignAdGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountCampaignAdGroupsQuery,
    CountCampaignAdGroupsQueryVariables
  >(CountCampaignAdGroupsDocument, baseOptions)
}
export type CountCampaignAdGroupsQueryHookResult = ReturnType<
  typeof useCountCampaignAdGroupsQuery
>
export type CountCampaignAdGroupsLazyQueryHookResult = ReturnType<
  typeof useCountCampaignAdGroupsLazyQuery
>
export type CountCampaignAdGroupsQueryResult = ApolloReactCommon.QueryResult<
  CountCampaignAdGroupsQuery,
  CountCampaignAdGroupsQueryVariables
>
export const CountActiveItemsDocument = gql`
  query CountActiveItems {
    count: Count {
      players
      networks
      creatives
      campaigns
    }
  }
`
export type CountActiveItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >,
  'query'
>

export const CountActiveItemsComponent = (
  props: CountActiveItemsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >
    query={CountActiveItemsDocument}
    {...props}
  />
)

export type CountActiveItemsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >
} &
  TChildProps
export function withCountActiveItems<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables,
    CountActiveItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables,
    CountActiveItemsProps<TChildProps, TDataName>
  >(CountActiveItemsDocument, {
    alias: 'countActiveItems',
    ...operationOptions,
  })
}

/**
 * __useCountActiveItemsQuery__
 *
 * To run a query within a React component, call `useCountActiveItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountActiveItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountActiveItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountActiveItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >(CountActiveItemsDocument, baseOptions)
}
export function useCountActiveItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountActiveItemsQuery,
    CountActiveItemsQueryVariables
  >(CountActiveItemsDocument, baseOptions)
}
export type CountActiveItemsQueryHookResult = ReturnType<
  typeof useCountActiveItemsQuery
>
export type CountActiveItemsLazyQueryHookResult = ReturnType<
  typeof useCountActiveItemsLazyQuery
>
export type CountActiveItemsQueryResult = ApolloReactCommon.QueryResult<
  CountActiveItemsQuery,
  CountActiveItemsQueryVariables
>
export const CountArchivedItemsDocument = gql`
  query CountArchivedItems {
    campaigns: CountArchivedCampaigns
    adGroups: CountArchivedCampaignAdGroups
    creatives: CountArchivedCreatives
    networks: CountArchivedNetworks
    players: CountArchivedPlayers
  }
`
export type CountArchivedItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >,
  'query'
>

export const CountArchivedItemsComponent = (
  props: CountArchivedItemsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >
    query={CountArchivedItemsDocument}
    {...props}
  />
)

export type CountArchivedItemsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >
} &
  TChildProps
export function withCountArchivedItems<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables,
    CountArchivedItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables,
    CountArchivedItemsProps<TChildProps, TDataName>
  >(CountArchivedItemsDocument, {
    alias: 'countArchivedItems',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedItemsQuery__
 *
 * To run a query within a React component, call `useCountArchivedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountArchivedItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >(CountArchivedItemsDocument, baseOptions)
}
export function useCountArchivedItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedItemsQuery,
    CountArchivedItemsQueryVariables
  >(CountArchivedItemsDocument, baseOptions)
}
export type CountArchivedItemsQueryHookResult = ReturnType<
  typeof useCountArchivedItemsQuery
>
export type CountArchivedItemsLazyQueryHookResult = ReturnType<
  typeof useCountArchivedItemsLazyQuery
>
export type CountArchivedItemsQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedItemsQuery,
  CountArchivedItemsQueryVariables
>
export const CountArchivedCreativesDocument = gql`
  query CountArchivedCreatives($filter: FilterCreativeInput! = {}) {
    creatives: CountArchivedCreatives(filter: $filter)
  }
`
export type CountArchivedCreativesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountArchivedCreativesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountArchivedCreativesComponent = (
  props: CountArchivedCreativesComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >
    query={CountArchivedCreativesDocument}
    {...props}
  />
)

export type CountArchivedCreativesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >
} &
  TChildProps
export function withCountArchivedCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables,
    CountArchivedCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables,
    CountArchivedCreativesProps<TChildProps, TDataName>
  >(CountArchivedCreativesDocument, {
    alias: 'countArchivedCreatives',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedCreativesQuery__
 *
 * To run a query within a React component, call `useCountArchivedCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedCreativesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountArchivedCreativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >(CountArchivedCreativesDocument, baseOptions)
}
export function useCountArchivedCreativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedCreativesQuery,
    CountArchivedCreativesQueryVariables
  >(CountArchivedCreativesDocument, baseOptions)
}
export type CountArchivedCreativesQueryHookResult = ReturnType<
  typeof useCountArchivedCreativesQuery
>
export type CountArchivedCreativesLazyQueryHookResult = ReturnType<
  typeof useCountArchivedCreativesLazyQuery
>
export type CountArchivedCreativesQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedCreativesQuery,
  CountArchivedCreativesQueryVariables
>
export const CountArchivedCampaignsDocument = gql`
  query CountArchivedCampaigns($filter: FilterCampaignInput! = {}) {
    campaigns: CountArchivedCampaigns(filter: $filter)
  }
`
export type CountArchivedCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountArchivedCampaignsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountArchivedCampaignsComponent = (
  props: CountArchivedCampaignsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >
    query={CountArchivedCampaignsDocument}
    {...props}
  />
)

export type CountArchivedCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >
} &
  TChildProps
export function withCountArchivedCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables,
    CountArchivedCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables,
    CountArchivedCampaignsProps<TChildProps, TDataName>
  >(CountArchivedCampaignsDocument, {
    alias: 'countArchivedCampaigns',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedCampaignsQuery__
 *
 * To run a query within a React component, call `useCountArchivedCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountArchivedCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >(CountArchivedCampaignsDocument, baseOptions)
}
export function useCountArchivedCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedCampaignsQuery,
    CountArchivedCampaignsQueryVariables
  >(CountArchivedCampaignsDocument, baseOptions)
}
export type CountArchivedCampaignsQueryHookResult = ReturnType<
  typeof useCountArchivedCampaignsQuery
>
export type CountArchivedCampaignsLazyQueryHookResult = ReturnType<
  typeof useCountArchivedCampaignsLazyQuery
>
export type CountArchivedCampaignsQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedCampaignsQuery,
  CountArchivedCampaignsQueryVariables
>
export const CountArchivedNetworksDocument = gql`
  query CountArchivedNetworks($filter: FilterNetworkInput! = {}) {
    networks: CountArchivedNetworks(filter: $filter)
  }
`
export type CountArchivedNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountArchivedNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountArchivedNetworksComponent = (
  props: CountArchivedNetworksComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >
    query={CountArchivedNetworksDocument}
    {...props}
  />
)

export type CountArchivedNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >
} &
  TChildProps
export function withCountArchivedNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables,
    CountArchivedNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables,
    CountArchivedNetworksProps<TChildProps, TDataName>
  >(CountArchivedNetworksDocument, {
    alias: 'countArchivedNetworks',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedNetworksQuery__
 *
 * To run a query within a React component, call `useCountArchivedNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedNetworksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountArchivedNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >(CountArchivedNetworksDocument, baseOptions)
}
export function useCountArchivedNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedNetworksQuery,
    CountArchivedNetworksQueryVariables
  >(CountArchivedNetworksDocument, baseOptions)
}
export type CountArchivedNetworksQueryHookResult = ReturnType<
  typeof useCountArchivedNetworksQuery
>
export type CountArchivedNetworksLazyQueryHookResult = ReturnType<
  typeof useCountArchivedNetworksLazyQuery
>
export type CountArchivedNetworksQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedNetworksQuery,
  CountArchivedNetworksQueryVariables
>
export const CountArchivedPlayersDocument = gql`
  query CountArchivedPlayers($filter: FilterPlayerInput! = {}) {
    players: CountArchivedPlayers(filter: $filter)
  }
`
export type CountArchivedPlayersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountArchivedPlayersQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountArchivedPlayersComponent = (
  props: CountArchivedPlayersComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >
    query={CountArchivedPlayersDocument}
    {...props}
  />
)

export type CountArchivedPlayersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >
} &
  TChildProps
export function withCountArchivedPlayers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables,
    CountArchivedPlayersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables,
    CountArchivedPlayersProps<TChildProps, TDataName>
  >(CountArchivedPlayersDocument, {
    alias: 'countArchivedPlayers',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedPlayersQuery__
 *
 * To run a query within a React component, call `useCountArchivedPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedPlayersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountArchivedPlayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >(CountArchivedPlayersDocument, baseOptions)
}
export function useCountArchivedPlayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedPlayersQuery,
    CountArchivedPlayersQueryVariables
  >(CountArchivedPlayersDocument, baseOptions)
}
export type CountArchivedPlayersQueryHookResult = ReturnType<
  typeof useCountArchivedPlayersQuery
>
export type CountArchivedPlayersLazyQueryHookResult = ReturnType<
  typeof useCountArchivedPlayersLazyQuery
>
export type CountArchivedPlayersQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedPlayersQuery,
  CountArchivedPlayersQueryVariables
>
export const CountArchivedCampaignAdGroupsDocument = gql`
  query CountArchivedCampaignAdGroups(
    $filter: FilterCampaignAdGroupInput! = {}
  ) {
    adGroups: CountArchivedCampaignAdGroups(filter: $filter)
  }
`
export type CountArchivedCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountArchivedCampaignAdGroupsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountArchivedCampaignAdGroupsComponent = (
  props: CountArchivedCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >
    query={CountArchivedCampaignAdGroupsDocument}
    {...props}
  />
)

export type CountArchivedCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >
} &
  TChildProps
export function withCountArchivedCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables,
    CountArchivedCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables,
    CountArchivedCampaignAdGroupsProps<TChildProps, TDataName>
  >(CountArchivedCampaignAdGroupsDocument, {
    alias: 'countArchivedCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useCountArchivedCampaignAdGroupsQuery__
 *
 * To run a query within a React component, call `useCountArchivedCampaignAdGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountArchivedCampaignAdGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountArchivedCampaignAdGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountArchivedCampaignAdGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >(CountArchivedCampaignAdGroupsDocument, baseOptions)
}
export function useCountArchivedCampaignAdGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountArchivedCampaignAdGroupsQuery,
    CountArchivedCampaignAdGroupsQueryVariables
  >(CountArchivedCampaignAdGroupsDocument, baseOptions)
}
export type CountArchivedCampaignAdGroupsQueryHookResult = ReturnType<
  typeof useCountArchivedCampaignAdGroupsQuery
>
export type CountArchivedCampaignAdGroupsLazyQueryHookResult = ReturnType<
  typeof useCountArchivedCampaignAdGroupsLazyQuery
>
export type CountArchivedCampaignAdGroupsQueryResult = ApolloReactCommon.QueryResult<
  CountArchivedCampaignAdGroupsQuery,
  CountArchivedCampaignAdGroupsQueryVariables
>
export const CountExternalNetworksDocument = gql`
  query CountExternalNetworks($filter: FilterNetworkInput! = {}) {
    networks: CountExternalNetworks(filter: $filter)
  }
`
export type CountExternalNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: CountExternalNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CountExternalNetworksComponent = (
  props: CountExternalNetworksComponentProps
) => (
  <ApolloReactComponents.Query<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >
    query={CountExternalNetworksDocument}
    {...props}
  />
)

export type CountExternalNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >
} &
  TChildProps
export function withCountExternalNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables,
    CountExternalNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables,
    CountExternalNetworksProps<TChildProps, TDataName>
  >(CountExternalNetworksDocument, {
    alias: 'countExternalNetworks',
    ...operationOptions,
  })
}

/**
 * __useCountExternalNetworksQuery__
 *
 * To run a query within a React component, call `useCountExternalNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountExternalNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountExternalNetworksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountExternalNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >(CountExternalNetworksDocument, baseOptions)
}
export function useCountExternalNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountExternalNetworksQuery,
    CountExternalNetworksQueryVariables
  >(CountExternalNetworksDocument, baseOptions)
}
export type CountExternalNetworksQueryHookResult = ReturnType<
  typeof useCountExternalNetworksQuery
>
export type CountExternalNetworksLazyQueryHookResult = ReturnType<
  typeof useCountExternalNetworksLazyQuery
>
export type CountExternalNetworksQueryResult = ApolloReactCommon.QueryResult<
  CountExternalNetworksQuery,
  CountExternalNetworksQueryVariables
>
export const WhoAmIDocument = gql`
  query WhoAmI {
    user: WhoAmI {
      _id
      is_active
      name {
        first
        last
      }
      platform_roles {
        admin
        curator
      }
      workspaces {
        _id
        name
        role
        added_at
        billing_email
        platform_type
      }
      invites {
        _id
        status
        workspace_id
      }
      phones {
        number
        verified_at
      }
      emails {
        address
        verified_at
      }
      phones {
        number
        verified_at
      }
      primary_email_index
      primary_phone_index
    }
  }
`
export type WhoAmIComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >,
  'query'
>

export const WhoAmIComponent = (props: WhoAmIComponentProps) => (
  <ApolloReactComponents.Query<WhoAmIQuery, WhoAmIQueryVariables>
    query={WhoAmIDocument}
    {...props}
  />
)

export type WhoAmIProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
} &
  TChildProps
export function withWhoAmI<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WhoAmIQuery,
    WhoAmIQueryVariables,
    WhoAmIProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WhoAmIQuery,
    WhoAmIQueryVariables,
    WhoAmIProps<TChildProps, TDataName>
  >(WhoAmIDocument, {
    alias: 'whoAmI',
    ...operationOptions,
  })
}

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    baseOptions
  )
}
export function useWhoAmILazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    baseOptions
  )
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>
export type WhoAmIQueryResult = ApolloReactCommon.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>
export const ViewAreaTypeDocument = gql`
  query ViewAreaType($_id: MongoID!) {
    areaType: FindAreaType(_id: $_id) {
      ...AreaTypeFull
    }
  }
  ${AreaTypeFullFragmentDoc}
`
export type ViewAreaTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewAreaTypeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewAreaTypeComponent = (props: ViewAreaTypeComponentProps) => (
  <ApolloReactComponents.Query<ViewAreaTypeQuery, ViewAreaTypeQueryVariables>
    query={ViewAreaTypeDocument}
    {...props}
  />
)

export type ViewAreaTypeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
} &
  TChildProps
export function withViewAreaType<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables,
    ViewAreaTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables,
    ViewAreaTypeProps<TChildProps, TDataName>
  >(ViewAreaTypeDocument, {
    alias: 'viewAreaType',
    ...operationOptions,
  })
}

/**
 * __useViewAreaTypeQuery__
 *
 * To run a query within a React component, call `useViewAreaTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAreaTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAreaTypeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useViewAreaTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >(ViewAreaTypeDocument, baseOptions)
}
export function useViewAreaTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >(ViewAreaTypeDocument, baseOptions)
}
export type ViewAreaTypeQueryHookResult = ReturnType<
  typeof useViewAreaTypeQuery
>
export type ViewAreaTypeLazyQueryHookResult = ReturnType<
  typeof useViewAreaTypeLazyQuery
>
export type ViewAreaTypeQueryResult = ApolloReactCommon.QueryResult<
  ViewAreaTypeQuery,
  ViewAreaTypeQueryVariables
>
export const ListAreaTypesDocument = gql`
  query ListAreaTypes(
    $skip: Int
    $limit: Int
    $sort: SortFindManyAreaTypeInput
    $filter: FilterFindManyAreaTypeInput
  ) {
    areaTypes: FindAreaTypes(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...AreaTypeFull
    }
  }
  ${AreaTypeFullFragmentDoc}
`
export type ListAreaTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >,
  'query'
>

export const ListAreaTypesComponent = (props: ListAreaTypesComponentProps) => (
  <ApolloReactComponents.Query<ListAreaTypesQuery, ListAreaTypesQueryVariables>
    query={ListAreaTypesDocument}
    {...props}
  />
)

export type ListAreaTypesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
} &
  TChildProps
export function withListAreaTypes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables,
    ListAreaTypesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables,
    ListAreaTypesProps<TChildProps, TDataName>
  >(ListAreaTypesDocument, {
    alias: 'listAreaTypes',
    ...operationOptions,
  })
}

/**
 * __useListAreaTypesQuery__
 *
 * To run a query within a React component, call `useListAreaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAreaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAreaTypesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAreaTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >(ListAreaTypesDocument, baseOptions)
}
export function useListAreaTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >(ListAreaTypesDocument, baseOptions)
}
export type ListAreaTypesQueryHookResult = ReturnType<
  typeof useListAreaTypesQuery
>
export type ListAreaTypesLazyQueryHookResult = ReturnType<
  typeof useListAreaTypesLazyQuery
>
export type ListAreaTypesQueryResult = ApolloReactCommon.QueryResult<
  ListAreaTypesQuery,
  ListAreaTypesQueryVariables
>
export const ViewVenueTypeDocument = gql`
  query ViewVenueType($_id: MongoID!) {
    venueType: FindVenueType(_id: $_id) {
      ...VenueTypeFull
    }
  }
  ${VenueTypeFullFragmentDoc}
`
export type ViewVenueTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewVenueTypeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewVenueTypeComponent = (props: ViewVenueTypeComponentProps) => (
  <ApolloReactComponents.Query<ViewVenueTypeQuery, ViewVenueTypeQueryVariables>
    query={ViewVenueTypeDocument}
    {...props}
  />
)

export type ViewVenueTypeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
} &
  TChildProps
export function withViewVenueType<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables,
    ViewVenueTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables,
    ViewVenueTypeProps<TChildProps, TDataName>
  >(ViewVenueTypeDocument, {
    alias: 'viewVenueType',
    ...operationOptions,
  })
}

/**
 * __useViewVenueTypeQuery__
 *
 * To run a query within a React component, call `useViewVenueTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewVenueTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewVenueTypeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useViewVenueTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >(ViewVenueTypeDocument, baseOptions)
}
export function useViewVenueTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >(ViewVenueTypeDocument, baseOptions)
}
export type ViewVenueTypeQueryHookResult = ReturnType<
  typeof useViewVenueTypeQuery
>
export type ViewVenueTypeLazyQueryHookResult = ReturnType<
  typeof useViewVenueTypeLazyQuery
>
export type ViewVenueTypeQueryResult = ApolloReactCommon.QueryResult<
  ViewVenueTypeQuery,
  ViewVenueTypeQueryVariables
>
export const ListVenueTypesDocument = gql`
  query ListVenueTypes(
    $skip: Int
    $limit: Int
    $sort: SortFindManyVenueTypeInput
    $filter: FilterFindManyVenueTypeInput
  ) {
    venueTypes: FindVenueTypes(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...VenueTypeFull
    }
  }
  ${VenueTypeFullFragmentDoc}
`
export type ListVenueTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >,
  'query'
>

export const ListVenueTypesComponent = (
  props: ListVenueTypesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
    query={ListVenueTypesDocument}
    {...props}
  />
)

export type ListVenueTypesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
} &
  TChildProps
export function withListVenueTypes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables,
    ListVenueTypesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables,
    ListVenueTypesProps<TChildProps, TDataName>
  >(ListVenueTypesDocument, {
    alias: 'listVenueTypes',
    ...operationOptions,
  })
}

/**
 * __useListVenueTypesQuery__
 *
 * To run a query within a React component, call `useListVenueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVenueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVenueTypesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListVenueTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >(ListVenueTypesDocument, baseOptions)
}
export function useListVenueTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >(ListVenueTypesDocument, baseOptions)
}
export type ListVenueTypesQueryHookResult = ReturnType<
  typeof useListVenueTypesQuery
>
export type ListVenueTypesLazyQueryHookResult = ReturnType<
  typeof useListVenueTypesLazyQuery
>
export type ListVenueTypesQueryResult = ApolloReactCommon.QueryResult<
  ListVenueTypesQuery,
  ListVenueTypesQueryVariables
>
export const ViewCampaignDocument = gql`
  query ViewCampaign($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    campaign: FindCampaign(filter: { _id: $_id }) {
      ...CampaignFull
    }
  }
  ${CampaignFullFragmentDoc}
`
export type ViewCampaignComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCampaignQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCampaignComponent = (props: ViewCampaignComponentProps) => (
  <ApolloReactComponents.Query<ViewCampaignQuery, ViewCampaignQueryVariables>
    query={ViewCampaignDocument}
    {...props}
  />
)

export type ViewCampaignProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
} &
  TChildProps
export function withViewCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCampaignQuery,
    ViewCampaignQueryVariables,
    ViewCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCampaignQuery,
    ViewCampaignQueryVariables,
    ViewCampaignProps<TChildProps, TDataName>
  >(ViewCampaignDocument, {
    alias: 'viewCampaign',
    ...operationOptions,
  })
}

/**
 * __useViewCampaignQuery__
 *
 * To run a query within a React component, call `useViewCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCampaignQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCampaignQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >(ViewCampaignDocument, baseOptions)
}
export function useViewCampaignLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >(ViewCampaignDocument, baseOptions)
}
export type ViewCampaignQueryHookResult = ReturnType<
  typeof useViewCampaignQuery
>
export type ViewCampaignLazyQueryHookResult = ReturnType<
  typeof useViewCampaignLazyQuery
>
export type ViewCampaignQueryResult = ApolloReactCommon.QueryResult<
  ViewCampaignQuery,
  ViewCampaignQueryVariables
>
export const ViewExternalCampaignDocument = gql`
  query ViewExternalCampaign(
    $_id: MongoID!
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaign: FindExternalCampaign(filter: { _id: $_id }) {
      ...CampaignFull
    }
  }
  ${CampaignFullFragmentDoc}
`
export type ViewExternalCampaignComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewExternalCampaignQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewExternalCampaignComponent = (
  props: ViewExternalCampaignComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >
    query={ViewExternalCampaignDocument}
    {...props}
  />
)

export type ViewExternalCampaignProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >
} &
  TChildProps
export function withViewExternalCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables,
    ViewExternalCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables,
    ViewExternalCampaignProps<TChildProps, TDataName>
  >(ViewExternalCampaignDocument, {
    alias: 'viewExternalCampaign',
    ...operationOptions,
  })
}

/**
 * __useViewExternalCampaignQuery__
 *
 * To run a query within a React component, call `useViewExternalCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewExternalCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewExternalCampaignQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewExternalCampaignQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >(ViewExternalCampaignDocument, baseOptions)
}
export function useViewExternalCampaignLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewExternalCampaignQuery,
    ViewExternalCampaignQueryVariables
  >(ViewExternalCampaignDocument, baseOptions)
}
export type ViewExternalCampaignQueryHookResult = ReturnType<
  typeof useViewExternalCampaignQuery
>
export type ViewExternalCampaignLazyQueryHookResult = ReturnType<
  typeof useViewExternalCampaignLazyQuery
>
export type ViewExternalCampaignQueryResult = ApolloReactCommon.QueryResult<
  ViewExternalCampaignQuery,
  ViewExternalCampaignQueryVariables
>
export const ListCampaignsDocument = gql`
  query ListCampaigns(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignInput! = {}
  ) {
    campaigns: FindCampaigns(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignList
    }
  }
  ${CampaignListFragmentDoc}
`
export type ListCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCampaignsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCampaignsComponent = (props: ListCampaignsComponentProps) => (
  <ApolloReactComponents.Query<ListCampaignsQuery, ListCampaignsQueryVariables>
    query={ListCampaignsDocument}
    {...props}
  />
)

export type ListCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
} &
  TChildProps
export function withListCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCampaignsQuery,
    ListCampaignsQueryVariables,
    ListCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCampaignsQuery,
    ListCampaignsQueryVariables,
    ListCampaignsProps<TChildProps, TDataName>
  >(ListCampaignsDocument, {
    alias: 'listCampaigns',
    ...operationOptions,
  })
}

/**
 * __useListCampaignsQuery__
 *
 * To run a query within a React component, call `useListCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >(ListCampaignsDocument, baseOptions)
}
export function useListCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >(ListCampaignsDocument, baseOptions)
}
export type ListCampaignsQueryHookResult = ReturnType<
  typeof useListCampaignsQuery
>
export type ListCampaignsLazyQueryHookResult = ReturnType<
  typeof useListCampaignsLazyQuery
>
export type ListCampaignsQueryResult = ApolloReactCommon.QueryResult<
  ListCampaignsQuery,
  ListCampaignsQueryVariables
>
export const ListArchivedCampaignsDocument = gql`
  query ListArchivedCampaigns(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignInput! = {}
  ) {
    campaigns: FindArchivedCampaigns(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignList
    }
  }
  ${CampaignListFragmentDoc}
`
export type ListArchivedCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListArchivedCampaignsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListArchivedCampaignsComponent = (
  props: ListArchivedCampaignsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >
    query={ListArchivedCampaignsDocument}
    {...props}
  />
)

export type ListArchivedCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >
} &
  TChildProps
export function withListArchivedCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables,
    ListArchivedCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables,
    ListArchivedCampaignsProps<TChildProps, TDataName>
  >(ListArchivedCampaignsDocument, {
    alias: 'listArchivedCampaigns',
    ...operationOptions,
  })
}

/**
 * __useListArchivedCampaignsQuery__
 *
 * To run a query within a React component, call `useListArchivedCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArchivedCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArchivedCampaignsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListArchivedCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >(ListArchivedCampaignsDocument, baseOptions)
}
export function useListArchivedCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListArchivedCampaignsQuery,
    ListArchivedCampaignsQueryVariables
  >(ListArchivedCampaignsDocument, baseOptions)
}
export type ListArchivedCampaignsQueryHookResult = ReturnType<
  typeof useListArchivedCampaignsQuery
>
export type ListArchivedCampaignsLazyQueryHookResult = ReturnType<
  typeof useListArchivedCampaignsLazyQuery
>
export type ListArchivedCampaignsQueryResult = ApolloReactCommon.QueryResult<
  ListArchivedCampaignsQuery,
  ListArchivedCampaignsQueryVariables
>
export const ListExternalCampaignsDocument = gql`
  query ListExternalCampaigns {
    campaigns: FindManyExternalCampaigns {
      ...ExternalCampaignList
    }
  }
  ${ExternalCampaignListFragmentDoc}
`
export type ListExternalCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >,
  'query'
>

export const ListExternalCampaignsComponent = (
  props: ListExternalCampaignsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >
    query={ListExternalCampaignsDocument}
    {...props}
  />
)

export type ListExternalCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >
} &
  TChildProps
export function withListExternalCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables,
    ListExternalCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables,
    ListExternalCampaignsProps<TChildProps, TDataName>
  >(ListExternalCampaignsDocument, {
    alias: 'listExternalCampaigns',
    ...operationOptions,
  })
}

/**
 * __useListExternalCampaignsQuery__
 *
 * To run a query within a React component, call `useListExternalCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExternalCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExternalCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListExternalCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >(ListExternalCampaignsDocument, baseOptions)
}
export function useListExternalCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListExternalCampaignsQuery,
    ListExternalCampaignsQueryVariables
  >(ListExternalCampaignsDocument, baseOptions)
}
export type ListExternalCampaignsQueryHookResult = ReturnType<
  typeof useListExternalCampaignsQuery
>
export type ListExternalCampaignsLazyQueryHookResult = ReturnType<
  typeof useListExternalCampaignsLazyQuery
>
export type ListExternalCampaignsQueryResult = ApolloReactCommon.QueryResult<
  ListExternalCampaignsQuery,
  ListExternalCampaignsQueryVariables
>
export const ListCampaignsByRevokedNetworkDocument = gql`
  query ListCampaignsByRevokedNetwork(
    $network_id: MongoID!
    $workspace_id: MongoID!
  ) {
    campaigns: AffectedCampaignsByNetworkRevoke(
      network_id: $network_id
      workspace_id: $workspace_id
    )
  }
`
export type ListCampaignsByRevokedNetworkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCampaignsByRevokedNetworkQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCampaignsByRevokedNetworkComponent = (
  props: ListCampaignsByRevokedNetworkComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >
    query={ListCampaignsByRevokedNetworkDocument}
    {...props}
  />
)

export type ListCampaignsByRevokedNetworkProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >
} &
  TChildProps
export function withListCampaignsByRevokedNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables,
    ListCampaignsByRevokedNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables,
    ListCampaignsByRevokedNetworkProps<TChildProps, TDataName>
  >(ListCampaignsByRevokedNetworkDocument, {
    alias: 'listCampaignsByRevokedNetwork',
    ...operationOptions,
  })
}

/**
 * __useListCampaignsByRevokedNetworkQuery__
 *
 * To run a query within a React component, call `useListCampaignsByRevokedNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignsByRevokedNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignsByRevokedNetworkQuery({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      workspace_id: // value for 'workspace_id'
 *   },
 * });
 */
export function useListCampaignsByRevokedNetworkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >(ListCampaignsByRevokedNetworkDocument, baseOptions)
}
export function useListCampaignsByRevokedNetworkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCampaignsByRevokedNetworkQuery,
    ListCampaignsByRevokedNetworkQueryVariables
  >(ListCampaignsByRevokedNetworkDocument, baseOptions)
}
export type ListCampaignsByRevokedNetworkQueryHookResult = ReturnType<
  typeof useListCampaignsByRevokedNetworkQuery
>
export type ListCampaignsByRevokedNetworkLazyQueryHookResult = ReturnType<
  typeof useListCampaignsByRevokedNetworkLazyQuery
>
export type ListCampaignsByRevokedNetworkQueryResult = ApolloReactCommon.QueryResult<
  ListCampaignsByRevokedNetworkQuery,
  ListCampaignsByRevokedNetworkQueryVariables
>
export const ViewCampaignAdGroupDocument = gql`
  query ViewCampaignAdGroup(
    $_id: MongoID!
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaignAdGroup: FindCampaignAdGroup(filter: { _id: $_id }) {
      ...CampaignAdGroupFull
    }
  }
  ${CampaignAdGroupFullFragmentDoc}
`
export type ViewCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCampaignAdGroupQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCampaignAdGroupComponent = (
  props: ViewCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
    query={ViewCampaignAdGroupDocument}
    {...props}
  />
)

export type ViewCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
} &
  TChildProps
export function withViewCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables,
    ViewCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables,
    ViewCampaignAdGroupProps<TChildProps, TDataName>
  >(ViewCampaignAdGroupDocument, {
    alias: 'viewCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useViewCampaignAdGroupQuery__
 *
 * To run a query within a React component, call `useViewCampaignAdGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCampaignAdGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCampaignAdGroupQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCampaignAdGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >(ViewCampaignAdGroupDocument, baseOptions)
}
export function useViewCampaignAdGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >(ViewCampaignAdGroupDocument, baseOptions)
}
export type ViewCampaignAdGroupQueryHookResult = ReturnType<
  typeof useViewCampaignAdGroupQuery
>
export type ViewCampaignAdGroupLazyQueryHookResult = ReturnType<
  typeof useViewCampaignAdGroupLazyQuery
>
export type ViewCampaignAdGroupQueryResult = ApolloReactCommon.QueryResult<
  ViewCampaignAdGroupQuery,
  ViewCampaignAdGroupQueryVariables
>
export const ListCampaignAdGroupsDocument = gql`
  query ListCampaignAdGroups(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignAdGroupInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaignAdGroups: FindCampaignAdGroups(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignAdGroupList
    }
  }
  ${CampaignAdGroupListFragmentDoc}
`
export type ListCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCampaignAdGroupsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCampaignAdGroupsComponent = (
  props: ListCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
    query={ListCampaignAdGroupsDocument}
    {...props}
  />
)

export type ListCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
} &
  TChildProps
export function withListCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables,
    ListCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables,
    ListCampaignAdGroupsProps<TChildProps, TDataName>
  >(ListCampaignAdGroupsDocument, {
    alias: 'listCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useListCampaignAdGroupsQuery__
 *
 * To run a query within a React component, call `useListCampaignAdGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignAdGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignAdGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListCampaignAdGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >(ListCampaignAdGroupsDocument, baseOptions)
}
export function useListCampaignAdGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >(ListCampaignAdGroupsDocument, baseOptions)
}
export type ListCampaignAdGroupsQueryHookResult = ReturnType<
  typeof useListCampaignAdGroupsQuery
>
export type ListCampaignAdGroupsLazyQueryHookResult = ReturnType<
  typeof useListCampaignAdGroupsLazyQuery
>
export type ListCampaignAdGroupsQueryResult = ApolloReactCommon.QueryResult<
  ListCampaignAdGroupsQuery,
  ListCampaignAdGroupsQueryVariables
>
export const ListArchivedCampaignAdGroupsDocument = gql`
  query ListArchivedCampaignAdGroups(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignAdGroupInput! = {}
  ) {
    adGroups: FindArchivedCampaignAdGroups(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignAdGroupList
    }
  }
  ${CampaignAdGroupListFragmentDoc}
`
export type ListArchivedCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListArchivedCampaignAdGroupsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListArchivedCampaignAdGroupsComponent = (
  props: ListArchivedCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >
    query={ListArchivedCampaignAdGroupsDocument}
    {...props}
  />
)

export type ListArchivedCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >
} &
  TChildProps
export function withListArchivedCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables,
    ListArchivedCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables,
    ListArchivedCampaignAdGroupsProps<TChildProps, TDataName>
  >(ListArchivedCampaignAdGroupsDocument, {
    alias: 'listArchivedCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useListArchivedCampaignAdGroupsQuery__
 *
 * To run a query within a React component, call `useListArchivedCampaignAdGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArchivedCampaignAdGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArchivedCampaignAdGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListArchivedCampaignAdGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >(ListArchivedCampaignAdGroupsDocument, baseOptions)
}
export function useListArchivedCampaignAdGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListArchivedCampaignAdGroupsQuery,
    ListArchivedCampaignAdGroupsQueryVariables
  >(ListArchivedCampaignAdGroupsDocument, baseOptions)
}
export type ListArchivedCampaignAdGroupsQueryHookResult = ReturnType<
  typeof useListArchivedCampaignAdGroupsQuery
>
export type ListArchivedCampaignAdGroupsLazyQueryHookResult = ReturnType<
  typeof useListArchivedCampaignAdGroupsLazyQuery
>
export type ListArchivedCampaignAdGroupsQueryResult = ApolloReactCommon.QueryResult<
  ListArchivedCampaignAdGroupsQuery,
  ListArchivedCampaignAdGroupsQueryVariables
>
export const ViewCampaignAnalyticsDocument = gql`
  query ViewCampaignAnalytics(
    $_id: MongoID!
    $interval: ScansGeoLocationInterval!
  ) {
    analytics: CampaignAnalytics(_id: $_id, intervals: $interval) {
      _id
      name
      date
      locations {
        scans
        impressions
        coordinates
      }
    }
  }
`
export type ViewCampaignAnalyticsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCampaignAnalyticsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCampaignAnalyticsComponent = (
  props: ViewCampaignAnalyticsComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >
    query={ViewCampaignAnalyticsDocument}
    {...props}
  />
)

export type ViewCampaignAnalyticsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >
} &
  TChildProps
export function withViewCampaignAnalytics<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables,
    ViewCampaignAnalyticsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables,
    ViewCampaignAnalyticsProps<TChildProps, TDataName>
  >(ViewCampaignAnalyticsDocument, {
    alias: 'viewCampaignAnalytics',
    ...operationOptions,
  })
}

/**
 * __useViewCampaignAnalyticsQuery__
 *
 * To run a query within a React component, call `useViewCampaignAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCampaignAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCampaignAnalyticsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useViewCampaignAnalyticsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >(ViewCampaignAnalyticsDocument, baseOptions)
}
export function useViewCampaignAnalyticsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCampaignAnalyticsQuery,
    ViewCampaignAnalyticsQueryVariables
  >(ViewCampaignAnalyticsDocument, baseOptions)
}
export type ViewCampaignAnalyticsQueryHookResult = ReturnType<
  typeof useViewCampaignAnalyticsQuery
>
export type ViewCampaignAnalyticsLazyQueryHookResult = ReturnType<
  typeof useViewCampaignAnalyticsLazyQuery
>
export type ViewCampaignAnalyticsQueryResult = ApolloReactCommon.QueryResult<
  ViewCampaignAnalyticsQuery,
  ViewCampaignAnalyticsQueryVariables
>
export const ViewCreativeDocument = gql`
  query ViewCreative($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    creative: FindCreative(filter: { _id: $_id }) {
      ...CreativeFull
    }
  }
  ${CreativeFullFragmentDoc}
`
export type ViewCreativeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCreativeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCreativeComponent = (props: ViewCreativeComponentProps) => (
  <ApolloReactComponents.Query<ViewCreativeQuery, ViewCreativeQueryVariables>
    query={ViewCreativeDocument}
    {...props}
  />
)

export type ViewCreativeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
} &
  TChildProps
export function withViewCreative<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCreativeQuery,
    ViewCreativeQueryVariables,
    ViewCreativeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCreativeQuery,
    ViewCreativeQueryVariables,
    ViewCreativeProps<TChildProps, TDataName>
  >(ViewCreativeDocument, {
    alias: 'viewCreative',
    ...operationOptions,
  })
}

/**
 * __useViewCreativeQuery__
 *
 * To run a query within a React component, call `useViewCreativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCreativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCreativeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCreativeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >(ViewCreativeDocument, baseOptions)
}
export function useViewCreativeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >(ViewCreativeDocument, baseOptions)
}
export type ViewCreativeQueryHookResult = ReturnType<
  typeof useViewCreativeQuery
>
export type ViewCreativeLazyQueryHookResult = ReturnType<
  typeof useViewCreativeLazyQuery
>
export type ViewCreativeQueryResult = ApolloReactCommon.QueryResult<
  ViewCreativeQuery,
  ViewCreativeQueryVariables
>
export const ListCreativesDocument = gql`
  query ListCreatives(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCreativeInput! = {}
  ) {
    creatives: FindCreatives(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CreativeList
    }
  }
  ${CreativeListFragmentDoc}
`
export type ListCreativesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCreativesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCreativesComponent = (props: ListCreativesComponentProps) => (
  <ApolloReactComponents.Query<ListCreativesQuery, ListCreativesQueryVariables>
    query={ListCreativesDocument}
    {...props}
  />
)

export type ListCreativesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
} &
  TChildProps
export function withListCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCreativesQuery,
    ListCreativesQueryVariables,
    ListCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCreativesQuery,
    ListCreativesQueryVariables,
    ListCreativesProps<TChildProps, TDataName>
  >(ListCreativesDocument, {
    alias: 'listCreatives',
    ...operationOptions,
  })
}

/**
 * __useListCreativesQuery__
 *
 * To run a query within a React component, call `useListCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCreativesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCreativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >(ListCreativesDocument, baseOptions)
}
export function useListCreativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >(ListCreativesDocument, baseOptions)
}
export type ListCreativesQueryHookResult = ReturnType<
  typeof useListCreativesQuery
>
export type ListCreativesLazyQueryHookResult = ReturnType<
  typeof useListCreativesLazyQuery
>
export type ListCreativesQueryResult = ApolloReactCommon.QueryResult<
  ListCreativesQuery,
  ListCreativesQueryVariables
>
export const ListArchivedCreativesDocument = gql`
  query ListArchivedCreatives(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCreativeInput! = {}
  ) {
    creatives: FindArchivedCreatives(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CreativeList
    }
  }
  ${CreativeListFragmentDoc}
`
export type ListArchivedCreativesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListArchivedCreativesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListArchivedCreativesComponent = (
  props: ListArchivedCreativesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >
    query={ListArchivedCreativesDocument}
    {...props}
  />
)

export type ListArchivedCreativesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >
} &
  TChildProps
export function withListArchivedCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables,
    ListArchivedCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables,
    ListArchivedCreativesProps<TChildProps, TDataName>
  >(ListArchivedCreativesDocument, {
    alias: 'listArchivedCreatives',
    ...operationOptions,
  })
}

/**
 * __useListArchivedCreativesQuery__
 *
 * To run a query within a React component, call `useListArchivedCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArchivedCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArchivedCreativesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListArchivedCreativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >(ListArchivedCreativesDocument, baseOptions)
}
export function useListArchivedCreativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListArchivedCreativesQuery,
    ListArchivedCreativesQueryVariables
  >(ListArchivedCreativesDocument, baseOptions)
}
export type ListArchivedCreativesQueryHookResult = ReturnType<
  typeof useListArchivedCreativesQuery
>
export type ListArchivedCreativesLazyQueryHookResult = ReturnType<
  typeof useListArchivedCreativesLazyQuery
>
export type ListArchivedCreativesQueryResult = ApolloReactCommon.QueryResult<
  ListArchivedCreativesQuery,
  ListArchivedCreativesQueryVariables
>
export const ViewNetworkDocument = gql`
  query ViewNetwork($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    network: FindNetwork(filter: { _id: $_id }) {
      ...NetworkFull
    }
  }
  ${NetworkFullFragmentDoc}
`
export type ViewNetworkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >,
  'query'
> &
  ({ variables: ViewNetworkQueryVariables; skip?: boolean } | { skip: boolean })

export const ViewNetworkComponent = (props: ViewNetworkComponentProps) => (
  <ApolloReactComponents.Query<ViewNetworkQuery, ViewNetworkQueryVariables>
    query={ViewNetworkDocument}
    {...props}
  />
)

export type ViewNetworkProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
} &
  TChildProps
export function withViewNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewNetworkQuery,
    ViewNetworkQueryVariables,
    ViewNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewNetworkQuery,
    ViewNetworkQueryVariables,
    ViewNetworkProps<TChildProps, TDataName>
  >(ViewNetworkDocument, {
    alias: 'viewNetwork',
    ...operationOptions,
  })
}

/**
 * __useViewNetworkQuery__
 *
 * To run a query within a React component, call `useViewNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewNetworkQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewNetworkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewNetworkQuery, ViewNetworkQueryVariables>(
    ViewNetworkDocument,
    baseOptions
  )
}
export function useViewNetworkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >(ViewNetworkDocument, baseOptions)
}
export type ViewNetworkQueryHookResult = ReturnType<typeof useViewNetworkQuery>
export type ViewNetworkLazyQueryHookResult = ReturnType<
  typeof useViewNetworkLazyQuery
>
export type ViewNetworkQueryResult = ApolloReactCommon.QueryResult<
  ViewNetworkQuery,
  ViewNetworkQueryVariables
>
export const ViewExternalNetworkDocument = gql`
  query ViewExternalNetwork(
    $_id: MongoID!
    $intervals: [StatsIntervalInput!]! = []
  ) {
    network: FindExternalNetwork(filter: { _id: $_id }) {
      ...NetworkFull
    }
  }
  ${NetworkFullFragmentDoc}
`
export type ViewExternalNetworkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewExternalNetworkQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewExternalNetworkComponent = (
  props: ViewExternalNetworkComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >
    query={ViewExternalNetworkDocument}
    {...props}
  />
)

export type ViewExternalNetworkProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >
} &
  TChildProps
export function withViewExternalNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables,
    ViewExternalNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables,
    ViewExternalNetworkProps<TChildProps, TDataName>
  >(ViewExternalNetworkDocument, {
    alias: 'viewExternalNetwork',
    ...operationOptions,
  })
}

/**
 * __useViewExternalNetworkQuery__
 *
 * To run a query within a React component, call `useViewExternalNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewExternalNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewExternalNetworkQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewExternalNetworkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >(ViewExternalNetworkDocument, baseOptions)
}
export function useViewExternalNetworkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewExternalNetworkQuery,
    ViewExternalNetworkQueryVariables
  >(ViewExternalNetworkDocument, baseOptions)
}
export type ViewExternalNetworkQueryHookResult = ReturnType<
  typeof useViewExternalNetworkQuery
>
export type ViewExternalNetworkLazyQueryHookResult = ReturnType<
  typeof useViewExternalNetworkLazyQuery
>
export type ViewExternalNetworkQueryResult = ApolloReactCommon.QueryResult<
  ViewExternalNetworkQuery,
  ViewExternalNetworkQueryVariables
>
export const ViewNetworkShareLinkDocument = gql`
  query ViewNetworkShareLink($invite_token: String!) {
    info: NetworkInviteLinkInfo(invite_token: $invite_token) {
      sent_by
      network_id
      network_name
    }
  }
`
export type ViewNetworkShareLinkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewNetworkShareLinkQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewNetworkShareLinkComponent = (
  props: ViewNetworkShareLinkComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >
    query={ViewNetworkShareLinkDocument}
    {...props}
  />
)

export type ViewNetworkShareLinkProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >
} &
  TChildProps
export function withViewNetworkShareLink<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables,
    ViewNetworkShareLinkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables,
    ViewNetworkShareLinkProps<TChildProps, TDataName>
  >(ViewNetworkShareLinkDocument, {
    alias: 'viewNetworkShareLink',
    ...operationOptions,
  })
}

/**
 * __useViewNetworkShareLinkQuery__
 *
 * To run a query within a React component, call `useViewNetworkShareLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewNetworkShareLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewNetworkShareLinkQuery({
 *   variables: {
 *      invite_token: // value for 'invite_token'
 *   },
 * });
 */
export function useViewNetworkShareLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >(ViewNetworkShareLinkDocument, baseOptions)
}
export function useViewNetworkShareLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewNetworkShareLinkQuery,
    ViewNetworkShareLinkQueryVariables
  >(ViewNetworkShareLinkDocument, baseOptions)
}
export type ViewNetworkShareLinkQueryHookResult = ReturnType<
  typeof useViewNetworkShareLinkQuery
>
export type ViewNetworkShareLinkLazyQueryHookResult = ReturnType<
  typeof useViewNetworkShareLinkLazyQuery
>
export type ViewNetworkShareLinkQueryResult = ApolloReactCommon.QueryResult<
  ViewNetworkShareLinkQuery,
  ViewNetworkShareLinkQueryVariables
>
export const ListNetworksDocument = gql`
  query ListNetworks(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyNetworkInput! = {}
  ) {
    networks: FindNetworks(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...NetworkList
    }
  }
  ${NetworkListFragmentDoc}
`
export type ListNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListNetworksComponent = (props: ListNetworksComponentProps) => (
  <ApolloReactComponents.Query<ListNetworksQuery, ListNetworksQueryVariables>
    query={ListNetworksDocument}
    {...props}
  />
)

export type ListNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
} &
  TChildProps
export function withListNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListNetworksQuery,
    ListNetworksQueryVariables,
    ListNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListNetworksQuery,
    ListNetworksQueryVariables,
    ListNetworksProps<TChildProps, TDataName>
  >(ListNetworksDocument, {
    alias: 'listNetworks',
    ...operationOptions,
  })
}

/**
 * __useListNetworksQuery__
 *
 * To run a query within a React component, call `useListNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNetworksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >(ListNetworksDocument, baseOptions)
}
export function useListNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >(ListNetworksDocument, baseOptions)
}
export type ListNetworksQueryHookResult = ReturnType<
  typeof useListNetworksQuery
>
export type ListNetworksLazyQueryHookResult = ReturnType<
  typeof useListNetworksLazyQuery
>
export type ListNetworksQueryResult = ApolloReactCommon.QueryResult<
  ListNetworksQuery,
  ListNetworksQueryVariables
>
export const ListExternalNetworksDocument = gql`
  query ListExternalNetworks(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyNetworkInput! = {}
  ) {
    networks: FindExternalNetworks(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...NetworkList
    }
  }
  ${NetworkListFragmentDoc}
`
export type ListExternalNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListExternalNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListExternalNetworksComponent = (
  props: ListExternalNetworksComponentProps
) => (
  <ApolloReactComponents.Query<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >
    query={ListExternalNetworksDocument}
    {...props}
  />
)

export type ListExternalNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >
} &
  TChildProps
export function withListExternalNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables,
    ListExternalNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables,
    ListExternalNetworksProps<TChildProps, TDataName>
  >(ListExternalNetworksDocument, {
    alias: 'listExternalNetworks',
    ...operationOptions,
  })
}

/**
 * __useListExternalNetworksQuery__
 *
 * To run a query within a React component, call `useListExternalNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExternalNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExternalNetworksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListExternalNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >(ListExternalNetworksDocument, baseOptions)
}
export function useListExternalNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListExternalNetworksQuery,
    ListExternalNetworksQueryVariables
  >(ListExternalNetworksDocument, baseOptions)
}
export type ListExternalNetworksQueryHookResult = ReturnType<
  typeof useListExternalNetworksQuery
>
export type ListExternalNetworksLazyQueryHookResult = ReturnType<
  typeof useListExternalNetworksLazyQuery
>
export type ListExternalNetworksQueryResult = ApolloReactCommon.QueryResult<
  ListExternalNetworksQuery,
  ListExternalNetworksQueryVariables
>
export const ListArchivedNetworksDocument = gql`
  query ListArchivedNetworks(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyNetworkInput! = {}
  ) {
    networks: FindArchivedNetworks(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...NetworkList
    }
  }
  ${NetworkListFragmentDoc}
`
export type ListArchivedNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListArchivedNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListArchivedNetworksComponent = (
  props: ListArchivedNetworksComponentProps
) => (
  <ApolloReactComponents.Query<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >
    query={ListArchivedNetworksDocument}
    {...props}
  />
)

export type ListArchivedNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >
} &
  TChildProps
export function withListArchivedNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables,
    ListArchivedNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables,
    ListArchivedNetworksProps<TChildProps, TDataName>
  >(ListArchivedNetworksDocument, {
    alias: 'listArchivedNetworks',
    ...operationOptions,
  })
}

/**
 * __useListArchivedNetworksQuery__
 *
 * To run a query within a React component, call `useListArchivedNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArchivedNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArchivedNetworksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListArchivedNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >(ListArchivedNetworksDocument, baseOptions)
}
export function useListArchivedNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListArchivedNetworksQuery,
    ListArchivedNetworksQueryVariables
  >(ListArchivedNetworksDocument, baseOptions)
}
export type ListArchivedNetworksQueryHookResult = ReturnType<
  typeof useListArchivedNetworksQuery
>
export type ListArchivedNetworksLazyQueryHookResult = ReturnType<
  typeof useListArchivedNetworksLazyQuery
>
export type ListArchivedNetworksQueryResult = ApolloReactCommon.QueryResult<
  ListArchivedNetworksQuery,
  ListArchivedNetworksQueryVariables
>
export const ViewPlayerDocument = gql`
  query ViewPlayer($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    player: FindPlayer(filter: { _id: $_id }) {
      ...PlayerFull
    }
  }
  ${PlayerFullFragmentDoc}
`
export type ViewPlayerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >,
  'query'
> &
  ({ variables: ViewPlayerQueryVariables; skip?: boolean } | { skip: boolean })

export const ViewPlayerComponent = (props: ViewPlayerComponentProps) => (
  <ApolloReactComponents.Query<ViewPlayerQuery, ViewPlayerQueryVariables>
    query={ViewPlayerDocument}
    {...props}
  />
)

export type ViewPlayerProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
} &
  TChildProps
export function withViewPlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewPlayerQuery,
    ViewPlayerQueryVariables,
    ViewPlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewPlayerQuery,
    ViewPlayerQueryVariables,
    ViewPlayerProps<TChildProps, TDataName>
  >(ViewPlayerDocument, {
    alias: 'viewPlayer',
    ...operationOptions,
  })
}

/**
 * __useViewPlayerQuery__
 *
 * To run a query within a React component, call `useViewPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPlayerQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewPlayerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewPlayerQuery, ViewPlayerQueryVariables>(
    ViewPlayerDocument,
    baseOptions
  )
}
export function useViewPlayerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >(ViewPlayerDocument, baseOptions)
}
export type ViewPlayerQueryHookResult = ReturnType<typeof useViewPlayerQuery>
export type ViewPlayerLazyQueryHookResult = ReturnType<
  typeof useViewPlayerLazyQuery
>
export type ViewPlayerQueryResult = ApolloReactCommon.QueryResult<
  ViewPlayerQuery,
  ViewPlayerQueryVariables
>
export const ListPlayersDocument = gql`
  query ListPlayers(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyPlayerInput! = {}
  ) {
    players: FindPlayers(
      filter: $filter
      skip: $offset
      limit: $limit
      sort: _ID_DESC
    ) {
      ...PlayerList
    }
  }
  ${PlayerListFragmentDoc}
`
export type ListPlayersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >,
  'query'
> &
  ({ variables: ListPlayersQueryVariables; skip?: boolean } | { skip: boolean })

export const ListPlayersComponent = (props: ListPlayersComponentProps) => (
  <ApolloReactComponents.Query<ListPlayersQuery, ListPlayersQueryVariables>
    query={ListPlayersDocument}
    {...props}
  />
)

export type ListPlayersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
} &
  TChildProps
export function withListPlayers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListPlayersQuery,
    ListPlayersQueryVariables,
    ListPlayersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListPlayersQuery,
    ListPlayersQueryVariables,
    ListPlayersProps<TChildProps, TDataName>
  >(ListPlayersDocument, {
    alias: 'listPlayers',
    ...operationOptions,
  })
}

/**
 * __useListPlayersQuery__
 *
 * To run a query within a React component, call `useListPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlayersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPlayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListPlayersQuery, ListPlayersQueryVariables>(
    ListPlayersDocument,
    baseOptions
  )
}
export function useListPlayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >(ListPlayersDocument, baseOptions)
}
export type ListPlayersQueryHookResult = ReturnType<typeof useListPlayersQuery>
export type ListPlayersLazyQueryHookResult = ReturnType<
  typeof useListPlayersLazyQuery
>
export type ListPlayersQueryResult = ApolloReactCommon.QueryResult<
  ListPlayersQuery,
  ListPlayersQueryVariables
>
export const ListArchivedPlayersDocument = gql`
  query ListArchivedPlayers(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyPlayerInput! = {}
  ) {
    players: FindArchivedPlayers(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...PlayerList
    }
  }
  ${PlayerListFragmentDoc}
`
export type ListArchivedPlayersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListArchivedPlayersQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListArchivedPlayersComponent = (
  props: ListArchivedPlayersComponentProps
) => (
  <ApolloReactComponents.Query<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >
    query={ListArchivedPlayersDocument}
    {...props}
  />
)

export type ListArchivedPlayersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >
} &
  TChildProps
export function withListArchivedPlayers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables,
    ListArchivedPlayersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables,
    ListArchivedPlayersProps<TChildProps, TDataName>
  >(ListArchivedPlayersDocument, {
    alias: 'listArchivedPlayers',
    ...operationOptions,
  })
}

/**
 * __useListArchivedPlayersQuery__
 *
 * To run a query within a React component, call `useListArchivedPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArchivedPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArchivedPlayersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListArchivedPlayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >(ListArchivedPlayersDocument, baseOptions)
}
export function useListArchivedPlayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListArchivedPlayersQuery,
    ListArchivedPlayersQueryVariables
  >(ListArchivedPlayersDocument, baseOptions)
}
export type ListArchivedPlayersQueryHookResult = ReturnType<
  typeof useListArchivedPlayersQuery
>
export type ListArchivedPlayersLazyQueryHookResult = ReturnType<
  typeof useListArchivedPlayersLazyQuery
>
export type ListArchivedPlayersQueryResult = ApolloReactCommon.QueryResult<
  ListArchivedPlayersQuery,
  ListArchivedPlayersQueryVariables
>
export const ViewCountryDocument = gql`
  query ViewCountry($_id: MongoID, $code: String) {
    country: FindCountry(filter: { OR: [{ _id: $_id }, { code: $code }] }) {
      ...CountryFull
    }
  }
  ${CountryFullFragmentDoc}
`
export type ViewCountryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >,
  'query'
>

export const ViewCountryComponent = (props: ViewCountryComponentProps) => (
  <ApolloReactComponents.Query<ViewCountryQuery, ViewCountryQueryVariables>
    query={ViewCountryDocument}
    {...props}
  />
)

export type ViewCountryProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
} &
  TChildProps
export function withViewCountry<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCountryQuery,
    ViewCountryQueryVariables,
    ViewCountryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCountryQuery,
    ViewCountryQueryVariables,
    ViewCountryProps<TChildProps, TDataName>
  >(ViewCountryDocument, {
    alias: 'viewCountry',
    ...operationOptions,
  })
}

/**
 * __useViewCountryQuery__
 *
 * To run a query within a React component, call `useViewCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCountryQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useViewCountryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewCountryQuery, ViewCountryQueryVariables>(
    ViewCountryDocument,
    baseOptions
  )
}
export function useViewCountryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >(ViewCountryDocument, baseOptions)
}
export type ViewCountryQueryHookResult = ReturnType<typeof useViewCountryQuery>
export type ViewCountryLazyQueryHookResult = ReturnType<
  typeof useViewCountryLazyQuery
>
export type ViewCountryQueryResult = ApolloReactCommon.QueryResult<
  ViewCountryQuery,
  ViewCountryQueryVariables
>
export const ListCountriesDocument = gql`
  query ListCountries(
    $skip: Int = 0
    $filter: FilterFindManyCountryInput = {}
  ) {
    countries: FindCountries(filter: $filter, skip: $skip, sort: NAME_ASC) {
      ...CountryFull
    }
  }
  ${CountryFullFragmentDoc}
`
export type ListCountriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >,
  'query'
>

export const ListCountriesComponent = (props: ListCountriesComponentProps) => (
  <ApolloReactComponents.Query<ListCountriesQuery, ListCountriesQueryVariables>
    query={ListCountriesDocument}
    {...props}
  />
)

export type ListCountriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
} &
  TChildProps
export function withListCountries<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCountriesQuery,
    ListCountriesQueryVariables,
    ListCountriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCountriesQuery,
    ListCountriesQueryVariables,
    ListCountriesProps<TChildProps, TDataName>
  >(ListCountriesDocument, {
    alias: 'listCountries',
    ...operationOptions,
  })
}

/**
 * __useListCountriesQuery__
 *
 * To run a query within a React component, call `useListCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCountriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >(ListCountriesDocument, baseOptions)
}
export function useListCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >(ListCountriesDocument, baseOptions)
}
export type ListCountriesQueryHookResult = ReturnType<
  typeof useListCountriesQuery
>
export type ListCountriesLazyQueryHookResult = ReturnType<
  typeof useListCountriesLazyQuery
>
export type ListCountriesQueryResult = ApolloReactCommon.QueryResult<
  ListCountriesQuery,
  ListCountriesQueryVariables
>
export const ViewWorkspaceDocument = gql`
  query ViewWorkspace($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    workspace: FindWorkspace(_id: $_id) {
      ...WorkspaceFull
    }
  }
  ${WorkspaceFullFragmentDoc}
`
export type ViewWorkspaceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewWorkspaceQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewWorkspaceComponent = (props: ViewWorkspaceComponentProps) => (
  <ApolloReactComponents.Query<ViewWorkspaceQuery, ViewWorkspaceQueryVariables>
    query={ViewWorkspaceDocument}
    {...props}
  />
)

export type ViewWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
} &
  TChildProps
export function withViewWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables,
    ViewWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables,
    ViewWorkspaceProps<TChildProps, TDataName>
  >(ViewWorkspaceDocument, {
    alias: 'viewWorkspace',
    ...operationOptions,
  })
}

/**
 * __useViewWorkspaceQuery__
 *
 * To run a query within a React component, call `useViewWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewWorkspaceQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewWorkspaceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >(ViewWorkspaceDocument, baseOptions)
}
export function useViewWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >(ViewWorkspaceDocument, baseOptions)
}
export type ViewWorkspaceQueryHookResult = ReturnType<
  typeof useViewWorkspaceQuery
>
export type ViewWorkspaceLazyQueryHookResult = ReturnType<
  typeof useViewWorkspaceLazyQuery
>
export type ViewWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ViewWorkspaceQuery,
  ViewWorkspaceQueryVariables
>
export const ListWorkspacesDocument = gql`
  query ListWorkspaces(
    $_ids: [MongoID]!
    $limit: Int
    $sort: SortFindByIdsWorkspaceInput
    $intervals: [StatsIntervalInput!]! = []
  ) {
    workspaces: FindWorkspaces(_ids: $_ids, limit: $limit, sort: $sort) {
      ...WorkspaceFull
    }
  }
  ${WorkspaceFullFragmentDoc}
`
export type ListWorkspacesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListWorkspacesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListWorkspacesComponent = (
  props: ListWorkspacesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
    query={ListWorkspacesDocument}
    {...props}
  />
)

export type ListWorkspacesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
} &
  TChildProps
export function withListWorkspaces<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables,
    ListWorkspacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables,
    ListWorkspacesProps<TChildProps, TDataName>
  >(ListWorkspacesDocument, {
    alias: 'listWorkspaces',
    ...operationOptions,
  })
}

/**
 * __useListWorkspacesQuery__
 *
 * To run a query within a React component, call `useListWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspacesQuery({
 *   variables: {
 *      _ids: // value for '_ids'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListWorkspacesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >(ListWorkspacesDocument, baseOptions)
}
export function useListWorkspacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >(ListWorkspacesDocument, baseOptions)
}
export type ListWorkspacesQueryHookResult = ReturnType<
  typeof useListWorkspacesQuery
>
export type ListWorkspacesLazyQueryHookResult = ReturnType<
  typeof useListWorkspacesLazyQuery
>
export type ListWorkspacesQueryResult = ApolloReactCommon.QueryResult<
  ListWorkspacesQuery,
  ListWorkspacesQueryVariables
>
export const GetWorkspaceStatsDocument = gql`
  query GetWorkspaceStats($_id: MongoID!, $intervals: [StatsIntervalInput!]!) {
    workspace: FindWorkspace(_id: $_id) {
      stats: getStats(intervals: $intervals) {
        reach
        engagement
      }
    }
  }
`
export type GetWorkspaceStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetWorkspaceStatsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const GetWorkspaceStatsComponent = (
  props: GetWorkspaceStatsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
    query={GetWorkspaceStatsDocument}
    {...props}
  />
)

export type GetWorkspaceStatsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
} &
  TChildProps
export function withGetWorkspaceStats<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables,
    GetWorkspaceStatsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables,
    GetWorkspaceStatsProps<TChildProps, TDataName>
  >(GetWorkspaceStatsDocument, {
    alias: 'getWorkspaceStats',
    ...operationOptions,
  })
}

/**
 * __useGetWorkspaceStatsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceStatsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useGetWorkspaceStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >(GetWorkspaceStatsDocument, baseOptions)
}
export function useGetWorkspaceStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >(GetWorkspaceStatsDocument, baseOptions)
}
export type GetWorkspaceStatsQueryHookResult = ReturnType<
  typeof useGetWorkspaceStatsQuery
>
export type GetWorkspaceStatsLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceStatsLazyQuery
>
export type GetWorkspaceStatsQueryResult = ApolloReactCommon.QueryResult<
  GetWorkspaceStatsQuery,
  GetWorkspaceStatsQueryVariables
>
export const ListInvitesDocument = gql`
  query ListInvites(
    $skip: Int
    $limit: Int
    $sort: SortFindManyWorkspaceInviteInput
    $filter: FilterFindManyWorkspaceInviteInput
  ) {
    invites: FindWorkspaceInvites(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...InviteFull
    }
  }
  ${InviteFullFragmentDoc}
`
export type ListInvitesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >,
  'query'
>

export const ListInvitesComponent = (props: ListInvitesComponentProps) => (
  <ApolloReactComponents.Query<ListInvitesQuery, ListInvitesQueryVariables>
    query={ListInvitesDocument}
    {...props}
  />
)

export type ListInvitesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
} &
  TChildProps
export function withListInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListInvitesQuery,
    ListInvitesQueryVariables,
    ListInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListInvitesQuery,
    ListInvitesQueryVariables,
    ListInvitesProps<TChildProps, TDataName>
  >(ListInvitesDocument, {
    alias: 'listInvites',
    ...operationOptions,
  })
}

/**
 * __useListInvitesQuery__
 *
 * To run a query within a React component, call `useListInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListInvitesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListInvitesQuery, ListInvitesQueryVariables>(
    ListInvitesDocument,
    baseOptions
  )
}
export function useListInvitesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >(ListInvitesDocument, baseOptions)
}
export type ListInvitesQueryHookResult = ReturnType<typeof useListInvitesQuery>
export type ListInvitesLazyQueryHookResult = ReturnType<
  typeof useListInvitesLazyQuery
>
export type ListInvitesQueryResult = ApolloReactCommon.QueryResult<
  ListInvitesQuery,
  ListInvitesQueryVariables
>
export const ViewWebhookDocument = gql`
  query ViewWebhook(
    $skip: Int
    $sort: SortFindOneWebhookInput
    $filter: FilterFindOneWebhookInput
  ) {
    webhook: FindWebhook(skip: $skip, sort: $sort, filter: $filter) {
      ...WebhookFull
    }
  }
  ${WebhookFullFragmentDoc}
`
export type ViewWebhookComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >,
  'query'
>

export const ViewWebhookComponent = (props: ViewWebhookComponentProps) => (
  <ApolloReactComponents.Query<ViewWebhookQuery, ViewWebhookQueryVariables>
    query={ViewWebhookDocument}
    {...props}
  />
)

export type ViewWebhookProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
} &
  TChildProps
export function withViewWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewWebhookQuery,
    ViewWebhookQueryVariables,
    ViewWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewWebhookQuery,
    ViewWebhookQueryVariables,
    ViewWebhookProps<TChildProps, TDataName>
  >(ViewWebhookDocument, {
    alias: 'viewWebhook',
    ...operationOptions,
  })
}

/**
 * __useViewWebhookQuery__
 *
 * To run a query within a React component, call `useViewWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewWebhookQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewWebhookQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewWebhookQuery, ViewWebhookQueryVariables>(
    ViewWebhookDocument,
    baseOptions
  )
}
export function useViewWebhookLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >(ViewWebhookDocument, baseOptions)
}
export type ViewWebhookQueryHookResult = ReturnType<typeof useViewWebhookQuery>
export type ViewWebhookLazyQueryHookResult = ReturnType<
  typeof useViewWebhookLazyQuery
>
export type ViewWebhookQueryResult = ApolloReactCommon.QueryResult<
  ViewWebhookQuery,
  ViewWebhookQueryVariables
>
export const ListWebhooksDocument = gql`
  query ListWebhooks(
    $skip: Int
    $limit: Int
    $sort: SortFindManyWebhookInput
    $filter: FilterFindManyWebhookInput
  ) {
    webhooks: FindWebhooks(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...WebhookFull
    }
  }
  ${WebhookFullFragmentDoc}
`
export type ListWebhooksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >,
  'query'
>

export const ListWebhooksComponent = (props: ListWebhooksComponentProps) => (
  <ApolloReactComponents.Query<ListWebhooksQuery, ListWebhooksQueryVariables>
    query={ListWebhooksDocument}
    {...props}
  />
)

export type ListWebhooksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
} &
  TChildProps
export function withListWebhooks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListWebhooksQuery,
    ListWebhooksQueryVariables,
    ListWebhooksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListWebhooksQuery,
    ListWebhooksQueryVariables,
    ListWebhooksProps<TChildProps, TDataName>
  >(ListWebhooksDocument, {
    alias: 'listWebhooks',
    ...operationOptions,
  })
}

/**
 * __useListWebhooksQuery__
 *
 * To run a query within a React component, call `useListWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhooksQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListWebhooksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >(ListWebhooksDocument, baseOptions)
}
export function useListWebhooksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >(ListWebhooksDocument, baseOptions)
}
export type ListWebhooksQueryHookResult = ReturnType<
  typeof useListWebhooksQuery
>
export type ListWebhooksLazyQueryHookResult = ReturnType<
  typeof useListWebhooksLazyQuery
>
export type ListWebhooksQueryResult = ApolloReactCommon.QueryResult<
  ListWebhooksQuery,
  ListWebhooksQueryVariables
>
export const ViewCategoryDocument = gql`
  query ViewCategory(
    $skip: Int
    $sort: SortFindOneCategoryInput
    $filter: FilterFindOneCategoryInput
  ) {
    category: FindCategory(skip: $skip, sort: $sort, filter: $filter) {
      _id
      name
    }
  }
`
export type ViewCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >,
  'query'
>

export const ViewCategoryComponent = (props: ViewCategoryComponentProps) => (
  <ApolloReactComponents.Query<ViewCategoryQuery, ViewCategoryQueryVariables>
    query={ViewCategoryDocument}
    {...props}
  />
)

export type ViewCategoryProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
} &
  TChildProps
export function withViewCategory<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCategoryQuery,
    ViewCategoryQueryVariables,
    ViewCategoryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCategoryQuery,
    ViewCategoryQueryVariables,
    ViewCategoryProps<TChildProps, TDataName>
  >(ViewCategoryDocument, {
    alias: 'viewCategory',
    ...operationOptions,
  })
}

/**
 * __useViewCategoryQuery__
 *
 * To run a query within a React component, call `useViewCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCategoryQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >(ViewCategoryDocument, baseOptions)
}
export function useViewCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >(ViewCategoryDocument, baseOptions)
}
export type ViewCategoryQueryHookResult = ReturnType<
  typeof useViewCategoryQuery
>
export type ViewCategoryLazyQueryHookResult = ReturnType<
  typeof useViewCategoryLazyQuery
>
export type ViewCategoryQueryResult = ApolloReactCommon.QueryResult<
  ViewCategoryQuery,
  ViewCategoryQueryVariables
>
export const ListCategoriesDocument = gql`
  query ListCategories(
    $skip: Int
    $limit: Int
    $sort: SortFindManyCategoryInput
    $filter: FilterFindManyCategoryInput
  ) {
    categories: FindCategories(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      _id
      name
    }
  }
`
export type ListCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >,
  'query'
>

export const ListCategoriesComponent = (
  props: ListCategoriesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
    query={ListCategoriesDocument}
    {...props}
  />
)

export type ListCategoriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
} &
  TChildProps
export function withListCategories<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCategoriesQuery,
    ListCategoriesQueryVariables,
    ListCategoriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCategoriesQuery,
    ListCategoriesQueryVariables,
    ListCategoriesProps<TChildProps, TDataName>
  >(ListCategoriesDocument, {
    alias: 'listCategories',
    ...operationOptions,
  })
}

/**
 * __useListCategoriesQuery__
 *
 * To run a query within a React component, call `useListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >(ListCategoriesDocument, baseOptions)
}
export function useListCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >(ListCategoriesDocument, baseOptions)
}
export type ListCategoriesQueryHookResult = ReturnType<
  typeof useListCategoriesQuery
>
export type ListCategoriesLazyQueryHookResult = ReturnType<
  typeof useListCategoriesLazyQuery
>
export type ListCategoriesQueryResult = ApolloReactCommon.QueryResult<
  ListCategoriesQuery,
  ListCategoriesQueryVariables
>
export const AffectedResourcesByArchiveDocument = gql`
  query AffectedResourcesByArchive($_id: MongoID!) {
    resources: PlayerAffectedResourcesByArchive(_id: $_id) {
      networks {
        name
      }
    }
  }
`
export type AffectedResourcesByArchiveComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >,
  'query'
> &
  (
    | { variables: AffectedResourcesByArchiveQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const AffectedResourcesByArchiveComponent = (
  props: AffectedResourcesByArchiveComponentProps
) => (
  <ApolloReactComponents.Query<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >
    query={AffectedResourcesByArchiveDocument}
    {...props}
  />
)

export type AffectedResourcesByArchiveProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >
} &
  TChildProps
export function withAffectedResourcesByArchive<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables,
    AffectedResourcesByArchiveProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables,
    AffectedResourcesByArchiveProps<TChildProps, TDataName>
  >(AffectedResourcesByArchiveDocument, {
    alias: 'affectedResourcesByArchive',
    ...operationOptions,
  })
}

/**
 * __useAffectedResourcesByArchiveQuery__
 *
 * To run a query within a React component, call `useAffectedResourcesByArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffectedResourcesByArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffectedResourcesByArchiveQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useAffectedResourcesByArchiveQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >(AffectedResourcesByArchiveDocument, baseOptions)
}
export function useAffectedResourcesByArchiveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AffectedResourcesByArchiveQuery,
    AffectedResourcesByArchiveQueryVariables
  >(AffectedResourcesByArchiveDocument, baseOptions)
}
export type AffectedResourcesByArchiveQueryHookResult = ReturnType<
  typeof useAffectedResourcesByArchiveQuery
>
export type AffectedResourcesByArchiveLazyQueryHookResult = ReturnType<
  typeof useAffectedResourcesByArchiveLazyQuery
>
export type AffectedResourcesByArchiveQueryResult = ApolloReactCommon.QueryResult<
  AffectedResourcesByArchiveQuery,
  AffectedResourcesByArchiveQueryVariables
>
export const OnCreativesStatusChangeDocument = gql`
  subscription OnCreativesStatusChange($_ids: [MongoID!]!) {
    creatives: OnCreativesStatusChange(_ids: $_ids) {
      creative_id
    }
  }
`
export type OnCreativesStatusChangeComponentProps = Omit<
  ApolloReactComponents.SubscriptionComponentOptions<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  >,
  'subscription'
>

export const OnCreativesStatusChangeComponent = (
  props: OnCreativesStatusChangeComponentProps
) => (
  <ApolloReactComponents.Subscription<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  >
    subscription={OnCreativesStatusChangeDocument}
    {...props}
  />
)

export type OnCreativesStatusChangeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  >
} &
  TChildProps
export function withOnCreativesStatusChange<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables,
    OnCreativesStatusChangeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withSubscription<
    TProps,
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables,
    OnCreativesStatusChangeProps<TChildProps, TDataName>
  >(OnCreativesStatusChangeDocument, {
    alias: 'onCreativesStatusChange',
    ...operationOptions,
  })
}

/**
 * __useOnCreativesStatusChangeSubscription__
 *
 * To run a query within a React component, call `useOnCreativesStatusChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreativesStatusChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreativesStatusChangeSubscription({
 *   variables: {
 *      _ids: // value for '_ids'
 *   },
 * });
 */
export function useOnCreativesStatusChangeSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  >(OnCreativesStatusChangeDocument, baseOptions)
}
export type OnCreativesStatusChangeSubscriptionHookResult = ReturnType<
  typeof useOnCreativesStatusChangeSubscription
>
export type OnCreativesStatusChangeSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreativesStatusChangeSubscription>
