import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'

import Search from '../../../../ui-blocks/search'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import CampaignsListTable from '../../../../components/campaign/table'

import {
  CreativeFullFragment,
  useListCampaignsQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

export interface CampaignsSectionProps {
  creative?: CreativeFullFragment
}

const CampaignsSection: FC<CampaignsSectionProps> = ({ creative }) => {
  const adGroups = useMemo(() => creative?.ad_groups || [], [creative])
  const campaignsIds = useMemo(
    () => _.uniq(adGroups.map((adGroup) => adGroup.campaign_id)),
    [adGroups]
  )

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: campaignsData,
    loading: loadingCampaigns,
  } = useListCampaignsQuery({
    fetchPolicy: 'cache-first',
    skip: !campaignsIds.length,
    variables: {
      filter: { _ids: campaignsIds, search: searchTextDebounced },
    },
  })

  const campaigns = useMemo(() => campaignsData?.campaigns || [], [
    campaignsData,
  ])
  const pagination = usePagination(campaigns.length, 15, searchTextDebounced)

  return (
    <section>
      <div tw="flex flex-row items-center justify-end mb-4">
        <div tw="w-80">
          <Search
            value={searchText}
            loading={loadingCampaigns}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <CampaignsListTable
        data={campaigns}
        loading={loadingCampaigns}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default CampaignsSection
