import React, { FC, Fragment, ReactNode, useState } from 'react'

import tw from 'twin.macro'
import OutsideClickHandler from 'react-outside-click-handler'

import Icon from '../../../../ui-blocks/icon'

export interface Action {
  label: string
  icon?: ReactNode
  onClick: () => void
}

export interface ActionsDropdownProps {
  actions: Action[][]
}

const ActionsDropdown: FC<ActionsDropdownProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClickAction = (clickHandler: () => void) => {
    clickHandler()
    setIsOpen(false)
  }

  return (
    <div tw="relative">
      <div
        tw="flex flex-row items-center text-dark-blue-gray transition duration-200 hover:text-purple hover:cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Icon icon="options-horizontal" tw="text-xl mr-1" />
        <span>More</span>
      </div>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div
          css={[
            !isOpen && tw`hidden`,
            tw`pt-4 absolute right-0 top-full`,
            'z-index: 50;',
          ]}
        >
          <ul
            css={[
              tw`bg-white border rounded border-platinum`,
              'box-shadow: -4px 0px 18px rgba(109, 15, 177, 0.03), 0px 12px 44px rgba(109, 15, 177, 0.05);',
              'width: 9.5rem;',
            ]}
          >
            {actions.map((group, groupIndex) => (
              <Fragment key={`action-group#${groupIndex}`}>
                {group.map(({ label, icon, onClick }, actionIndex) => (
                  <li
                    key={`action#${actionIndex}`}
                    tw="px-4 py-2 space-x-1 flex flex-row items-center font-normal text-charcoal transition duration-200 hover:text-purple hover:cursor-pointer"
                    onClick={() => onClickAction(onClick)}
                  >
                    {icon}
                    <span>{label}</span>
                  </li>
                ))}
                {groupIndex !== actions.length - 1 && <hr tw="bg-platinum" />}
              </Fragment>
            ))}
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default ActionsDropdown
