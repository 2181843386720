import React, { FC, Fragment, useEffect } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Text from '../../../../ui-blocks/text'
import Button from '../../../../ui-blocks/button'
import { InputFormik } from '../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import { useUpdateWorkspaceByIdMutation } from '../../../../graphql/components'
import { MutationResult } from '../../../../typings'

import { trimValues } from '../../../../utils/data-manipulation'

import { authentication } from '../../../../stores'

interface UpdateDetailsFormikValues {
  name: string
  billing_email: string
}

const updateDetailsValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  billing_email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Billing email is required')
    .strict(true),
})

let workspaceDetailsResult: MutationResult = null
const WorkspaceSettingsDetailsPage: FC = () => {
  useEffect(() => {
    if (!!workspaceDetailsResult) workspaceDetailsResult = null
    return () => {
      workspaceDetailsResult = null
    }
  }, [])

  const [updateWorkspace] = useUpdateWorkspaceByIdMutation()
  const formik = useFormik<UpdateDetailsFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: updateDetailsValidationSchema,
    initialValues: {
      name: authentication.selected_workspace.name,
      billing_email: authentication.selected_workspace.billing_email,
    },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name', 'billing_email'])
      workspaceDetailsResult = null

      try {
        const res = await updateWorkspace({
          variables: {
            _id: authentication.selected_workspace._id,
            details: newValues,
          },
        })

        if (!!(res.errors || []).length) {
          workspaceDetailsResult = 'error'
          return
        }

        workspaceDetailsResult = 'success'
        await authentication.loadUserData()
      } catch {
        workspaceDetailsResult = 'error'
      }
    },
  })

  return (
    <Fragment>
      {!!workspaceDetailsResult && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              id: 'update-workspace-details-error-alert',
              variant:
                workspaceDetailsResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                workspaceDetailsResult === 'error'
                  ? "We couldn't update your workspace details. Please try again later."
                  : 'Workspace details successfully updated!',
            }}
          />
        </div>
      )}
      <Text as="h2" preset="h4" tw="mb-2">
        Workspace information
      </Text>
      <Text as="h3" preset="p2" tw="mb-6">
        You can use your name (best suited for solo) or the name of your
        company.
      </Text>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <div tw="max-w-xs mb-10 space-y-6">
          <InputFormik type="text" name="name" label="Name" formik={formik} />
          <InputFormik
            type="email"
            name="billing_email"
            label="Billing Email"
            formik={formik}
          />
        </div>
        <Button
          type="submit"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          Update workspace details
        </Button>
      </form>
    </Fragment>
  )
}

export default WorkspaceSettingsDetailsPage
