import React, { FC, useMemo } from 'react'

import tw from 'twin.macro'
import Dialog from 'rc-dialog'

import Text from '../ui-blocks/text'
import Icon from '../ui-blocks/icon'
import Button from '../ui-blocks/button'
import Spacer from '../ui-blocks/spacer'

export interface ProgressModalProps {
  title: string
  totalSteps: number
  currentStep: number
  open?: boolean
  loading?: boolean
  disabled?: boolean
  width?: string | number
  cancelLabel?: string
  confirmLabel?: string
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
}

const ProgressModal: FC<ProgressModalProps> = ({
  title,
  totalSteps,
  currentStep,
  open,
  loading,
  disabled,
  width,
  cancelLabel,
  confirmLabel,
  onClose,
  onCancel,
  onConfirm,
  children,
}) => {
  const progress = useMemo(() => (currentStep * 100) / totalSteps, [
    totalSteps,
    currentStep,
  ])

  return (
    <Dialog
      visible={open}
      closable={false}
      animation="zoom"
      maskAnimation="fade"
      onClose={onClose}
      focusTriggerAfterClose={false}
      style={{ width, maxWidth: '100%' }}
      bodyStyle={{ padding: 0, fontFamily: 'Fira Sans' }}
    >
      <div tw="px-12 py-5">
        <div tw="relative text-center">
          <Icon
            icon="close"
            onClick={onClose}
            tw="absolute top-2/4 left-0 text-lg text-charcoal transform -translate-y-2/4 hover:cursor-pointer"
          />
          <Text as="h2" preset="h4" tw="leading-none break-words">
            {title}
          </Text>
        </div>
      </div>
      <div tw="w-full relative pb-1">
        <hr tw="absolute left-0 top-0 w-full h-1 border-none bg-platinum" />
        <hr
          css={[
            `width: ${progress}%;`,
            tw`absolute top-0 left-0 h-1 transition-all duration-200 border-none bg-purple`,
          ]}
        />
      </div>
      <div tw="px-12 pb-6 pt-8">
        {children}
        <Spacer size="0.5rem" />
        {(!!onCancel || !!onConfirm) && (
          <div tw="flex flex-row items-center justify-end mt-6 space-x-6">
            {!!onCancel && (
              <Button secondary onClick={onCancel}>
                {currentStep === 1 ? cancelLabel || 'Cancel' : 'Back'}
              </Button>
            )}
            {!!onConfirm && (
              <Button loading={loading} disabled={disabled} onClick={onConfirm}>
                {currentStep === totalSteps
                  ? confirmLabel || 'Confirm'
                  : 'Next'}
              </Button>
            )}
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default ProgressModal
