import React, { FC, Fragment, useState } from 'react'

import ChooseMethodStep from './steps/choose-method'
import VerifyCodeStep from './steps/verify-code'
import NewPasswordStep from './steps/new-password'
import SuccessStep from './steps/success'

import { ForgotPasswordMethod } from '../../../graphql/components'

type AccountRecoveryStep =
  | 'choose-method'
  | 'verify-code'
  | 'new-password'
  | 'success'

const AuthForgotPasswordPage: FC = () => {
  const [contact, setContact] = useState<string>('')
  const [recoveryToken, setRecoveryToken] = useState<string>('')

  const [step, setStep] = useState<AccountRecoveryStep>('choose-method')
  const [method, setMethod] = useState<ForgotPasswordMethod>(
    ForgotPasswordMethod.Email
  )

  const [email, setEmail] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')

  const onChooseMethod = (method: ForgotPasswordMethod, contact: string) => {
    setMethod(method)
    setContact(contact)
    setStep('verify-code')
  }

  const onVerifyCode = (recoveryToken: string) => {
    setRecoveryToken(recoveryToken)
    setStep('new-password')
  }

  return (
    <Fragment>
      {step === 'choose-method' && <ChooseMethodStep onNext={onChooseMethod} />}
      {step === 'verify-code' && (
        <VerifyCodeStep
          method={method}
          contact={contact}
          onNext={onVerifyCode}
          goBack={() => setStep('choose-method')}
        />
      )}
      {step === 'new-password' && (
        <NewPasswordStep
          recoveryToken={recoveryToken}
          onNext={(email, password) => {
            setStep('success')
            setEmail(email)
            setNewPassword(password)
          }}
        />
      )}
      {step === 'success' && (
        <SuccessStep email={email} password={newPassword} />
      )}
    </Fragment>
  )
}

export default AuthForgotPasswordPage
