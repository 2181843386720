import React, { FC, Fragment, useMemo, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Switch from '../../../ui-blocks/switch'
import Status from '../../../ui-blocks/status'
import Illustration, { IllustrationEnum } from '../../../ui-blocks/illustration'

import ShareLinkInfo from './components/share-link-info'
import ActionsDropdown from './components/actions-dropdown'

import InlineDate from '../../inline-date'
import GetLinkModal from '../../get-link-modal'
import ArchiveWarningModal from '../../archive-warning-modal'
import Table, { TableDataProps } from '../../table'

import {
  EnumNetworkStatus,
  NetworkListFragment,
  useArchiveNetworkMutation,
  useUpdateNetworkActivationMutation,
} from '../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../utils/data-manipulation'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

const NetworksListTable: FC<TableDataProps<NetworkListFragment>> = ({
  data,
  ...props
}) => {
  const [activateNetwork] = useUpdateNetworkActivationMutation()
  const [
    archiveNetwork,
    { loading: archivingNetwork },
  ] = useArchiveNetworkMutation()

  const [isGetLinkModalOpen, openGetLinkModal] = useState<boolean>(false)
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)
  const [
    selectedNetwork,
    setSelectedNetwork,
  ] = useState<NetworkListFragment | null>(null)

  const onCloseModal = () => {
    openWarningModal(false)
    openGetLinkModal(false)
    setSelectedNetwork(null)
  }

  const onSelectNetwork = (
    network: NetworkListFragment,
    action: 'get-link' | 'archive'
  ) => {
    setSelectedNetwork(network)
    openWarningModal(action === 'archive')
    openGetLinkModal(action === 'get-link')
  }

  const handleError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'activate-network-error-alert',
      message: `We couldn't ${action} this network. Please try again later.`,
    })
  }

  const onActivateNetwork = async (networkId: string, activate: boolean) => {
    try {
      const res = await activateNetwork({
        variables: { ids: [networkId], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-networks'))
    } catch {
      handleError(activate ? 'activate' : 'deactivate')
    }
  }

  const onArchiveNetwork = async () => {
    try {
      const res = await archiveNetwork({
        variables: { ids: [selectedNetwork?._id] },
      })
      if (!res.data?.areArchived) return handleError('archive')

      onCloseModal()
      document.dispatchEvent(new CustomEvent('refetch-networks'))
    } catch {
      handleError('archive')
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((network) => {
        const playersCount = network.players
          .map((player) => player?.player)
          .filter(Boolean).length
        return {
          switch: {
            content: (
              <Switch
                checked={network.status === EnumNetworkStatus.Activated}
                disabled={network.status === EnumNetworkStatus.Archived}
                onSelect={(checked) => onActivateNetwork(network._id, checked)}
              />
            ),
          },
          name: {
            value: network.name,
            content: (
              <Link to={`/networks/${network._id}`} tw="text-purple">
                {network.name}
              </Link>
            ),
          },
          status: {
            value: network?.status,
            content: <Status value={network?.status as EnumNetworkStatus} />,
          },
          players: {
            value: playersCount,
            content: playersCount,
          },
          createdAt: {
            value: network.created?.at,
            content: <InlineDate date={network.created?.at} />,
          },
          shared: {
            content: (
              <ShareLinkInfo
                workspaces={network.sharedWithWorkspaces as any[]}
              />
            ),
          },
          getLinkModal: {
            content: (
              <ActionsDropdown
                actions={[
                  [
                    {
                      label: 'Get Link',
                      icon: (
                        <Icon icon="link" tw="text-base transform rotate-45" />
                      ),
                      onClick: () => onSelectNetwork(network, 'get-link'),
                    },
                  ],
                  [
                    {
                      label: 'Archive',
                      icon: <Icon icon="archive" tw="text-base" />,
                      onClick: () => onSelectNetwork(network, 'archive'),
                    },
                  ],
                ]}
              />
            ),
          },
        }
      }),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Network"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.networks_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'switch', width: 70 },
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'status',
            label: 'Status',
            isSortable: true,
            alignment: 'center',
            width: 142,
          },
          {
            key: 'players',
            label: 'Players',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'shared',
            label: 'Access',
            alignment: 'right',
            width: 130,
          },
          { key: 'getLinkModal', alignment: 'right', width: 70 },
        ]}
        {...props}
      />
      <GetLinkModal
        open={isGetLinkModalOpen}
        token={selectedNetwork?.invite_token || ''}
        onClose={onCloseModal}
      />
      <ArchiveWarningModal
        target="network"
        redirectPage="Networks"
        open={isWarningModalOpen}
        loading={archivingNetwork}
        onConfirm={onArchiveNetwork}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default NetworksListTable
