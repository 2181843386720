import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Avatar from '../../../../ui-blocks/avatar'

import useElementStates from '../../../../utils/use-element-states'

const AvatarSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const { states, values } = useElementStates(['largeFont'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="flex flex-row items-center space-x-4">
        <Avatar add {...values} />
        <Avatar initials="AM" {...values} />
        <Avatar src="https://via.placeholder.com/400" {...values} />
        <Avatar add variant="profile" {...values} />
        <Avatar initials="AM" variant="profile" {...values} />
        <Avatar
          variant="profile"
          src="https://via.placeholder.com/400"
          {...values}
        />
      </div>
    </ElementSection>
  )
}

export default AvatarSection
