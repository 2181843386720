import React, { FC, TextareaHTMLAttributes } from 'react'
import 'twin.macro'

const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  children,
  ...props
}) => (
  <textarea
    tw="bg-white text-charcoal border border-platinum rounded py-2 px-4 block w-full appearance-none leading-normal transition resize-y hover:border-light-peri focus:outline-none focus:border-spanish-violet"
    {...props}
  >
    {children}
  </textarea>
)

export default Textarea
