import React, { FC, useMemo } from 'react'

import Spacer from '../../../../../ui-blocks/spacer'
import InternalNetworks from './components/internal-networks'
import ExternalNetworks from './components/external-networks'
import {
  CampaignFullFragment,
  EnumBroadcastTargetType,
  EnumWorkspacePlatform_Type,
} from '../../../../../graphql/components'

import { authentication } from '../../../../../stores'

export interface BroadcastTargetsSectionProps {
  loading?: boolean
  campaign?: CampaignFullFragment
  refetchCampaign?: () => Promise<any>
}

const BroadcastTargetsSection: FC<BroadcastTargetsSectionProps> = ({
  loading,
  campaign,
  refetchCampaign,
}) => {
  const internalNetworkTargets = useMemo(
    () =>
      (campaign?.broadcast_targets || []).filter(
        (target) =>
          target?.type === EnumBroadcastTargetType.Network &&
          !target?.removed?.at
      ),
    [campaign]
  )

  const externalNetworkTargets = useMemo(
    () =>
      (campaign?.broadcast_targets || []).filter(
        (target) =>
          target?.type === EnumBroadcastTargetType.ExternalNetwork &&
          !target?.removed?.at
      ),
    [campaign]
  )

  return (
    <section>
      {authentication.selected_workspace.platform_type !==
        EnumWorkspacePlatform_Type.Advertiser && (
        <>
          <InternalNetworks
            loading={loading}
            targets={internalNetworkTargets as any[]}
            campaign={campaign}
            refetchCampaign={refetchCampaign}
          />
          <Spacer size="3.5rem" />
        </>
      )}
      <ExternalNetworks
        loading={loading}
        targets={externalNetworkTargets as any[]}
        campaign={campaign}
        refetchCampaign={refetchCampaign}
      />
    </section>
  )
}

export default BroadcastTargetsSection
