import React, { FC, useState, ReactNode } from 'react'
import tw from 'twin.macro'

const baseStyle = tw`flex items-center justify-center min-w-32 px-4 py-2 space-x-1 border transition duration-200 outline-none focus:outline-none`

export interface IControlOption {
  id: string
  label: string
  icon?: ReactNode
}

export interface ViewControlsProps {
  value?: string
  disabled?: boolean
  options: IControlOption[]
  onSelect?: (optionId: string) => void
}

const ViewControls: FC<ViewControlsProps> = ({
  value,
  options,
  disabled,
  onSelect,
}) => {
  const [selected, setSelected] = useState<string>(value || options[0].id || '')

  const cursorStyle = () =>
    disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`

  const selectionStyle = (optionId: string) =>
    selected === optionId
      ? tw`border-purple text-purple`
      : tw`border-platinum text-charcoal`

  const cornersStyle = (index: number) => [
    index === 0 && tw`rounded-tl rounded-bl`,
    index === options.length - 1 && tw`rounded-tr rounded-br`,
  ]

  const handleClick = (optionId: string) => {
    !disabled && setSelected(optionId)
    if (onSelect && !disabled) {
      onSelect(optionId)
    }
  }

  return (
    <div css={[tw`flex flex-row items-center`, disabled && tw`opacity-75`]}>
      {options.map(({ id, label, icon }, index) => (
        <button
          key={id}
          type="button"
          onClick={() => handleClick(id)}
          css={[
            baseStyle,
            cursorStyle(),
            selectionStyle(id),
            ...cornersStyle(index),
          ]}
        >
          {icon}
          <span>{label}</span>
        </button>
      ))}
    </div>
  )
}

export default ViewControls
