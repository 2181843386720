import React, { useState, FC, useMemo, useEffect } from 'react'

import 'twin.macro'
import { useLocation, useNavigate } from 'react-router-dom'

import Map from '../../../ui-blocks/map'
import Icon from '../../../ui-blocks/icon'
import Search from '../../../ui-blocks/search'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import PageHeader from '../../../ui-blocks/page-header'
import ViewControls from '../../../ui-blocks/view-controls'
import PaginationNav from '../../../ui-blocks/pagination-nav'
import PlayersPending from '../../../ui-blocks/players-pending'

import PlayersEmpty from './components/players-empty'

import LoadingPage from '../../../components/loading-page'
import PlayersListTable from '../../../components/player/table'

import {
  EnumPlayerStatus,
  PlayerListFragment,
  useCountPlayersQuery,
  useListPlayersQuery,
} from '../../../graphql/components'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'

import { IMapPoint } from '../../../typings'

const ListPlayersPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [view, setView] = useState<string>('list-view')
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountPlayersQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: filteredPlayerCountData,
    loading: loadingFiltiredPlayerCount,
  } = useCountPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const playersCount = useMemo(() => filteredPlayerCountData?.players || 0, [
    filteredPlayerCountData,
  ])
  const totalPlayersCount = useMemo(() => countData?.players || 0, [countData])
  const pagination = usePagination(playersCount, 15, searchTextDebounced)

  const {
    data: playersData,
    loading: loadingPlayers,
    refetch: refetchPlayers,
  } = useListPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchPlayers()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-players', refetchData)
    return () => {
      document.removeEventListener('refetch-players', refetchData)
    }
  }, [])

  const allPlayers = useMemo(
    () => (playersData?.players || []).filter((player) => !!player),
    [playersData]
  )

  const { active: activePlayers, pending: pendingPlayers } = allPlayers.reduce<{
    active: PlayerListFragment[]
    pending: PlayerListFragment[]
  }>(
    (acc, player) => {
      if (player.status === EnumPlayerStatus.Binded) {
        acc.pending = [...acc.pending, player]
      } else {
        acc.active = [...acc.active, player]
      }
      return acc
    },
    { active: [], pending: [] }
  )

  const points = allPlayers
    .map((player) =>
      !player?.geographics?.location?.coordinates
        ? undefined
        : {
            name: player?.name || '',
            lat: player.geographics.location.coordinates[1],
            lng: player.geographics.location.coordinates[0],
          }
    )
    .filter((point) => !!point) as IMapPoint[]

  return (
    <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
      <Container>
        <PageHeader
          title="Players"
          description={
            loadingPlayers ? 'Loading...' : `${totalPlayersCount} TOTAL`
          }
        >
          {!!totalPlayersCount && (
            <div tw="max-w-80 justify-self-end">
              <Search
                value={searchText}
                loading={loadingPlayers && loadingFiltiredPlayerCount}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          )}

          {!!totalPlayersCount && (
            <ViewControls
              value={view}
              onSelect={setView}
              options={[
                {
                  id: 'list-view',
                  label: 'List View',
                  icon: <Icon icon="three-rows" tw="text-base" />,
                },
                {
                  id: 'map-view',
                  label: 'Map View',
                  icon: <Icon icon="map" tw="text-base" />,
                },
              ]}
            />
          )}

          {!!totalPlayersCount && (
            <ButtonLink
              tw="justify-self-end"
              to="/players/bind"
              iconLeft={<Icon icon="add" tw="text-lg" />}
            >
              Add Player
            </ButtonLink>
          )}
        </PageHeader>

        <Suspense ready={!!totalPlayersCount} fallback={<PlayersEmpty />}>
          {view === 'map-view' && <Map averagePoints points={points} />}
          {view === 'list-view' && (
            <>
              {!!pendingPlayers.length && (
                <div tw="mb-10">
                  <PlayersPending
                    cards={pendingPlayers.map((player) => ({
                      player,
                      onEdit: () => navigate(`/players/bind?_id=${player._id}`),
                    }))}
                  />
                </div>
              )}
              <PlayersListTable
                data={activePlayers}
                loading={loadingPlayers && loadingFiltiredPlayerCount}
                searchQuery={searchTextDebounced}
              />
              <PaginationNav
                page={pagination.page}
                count={pagination.count}
                limit={pagination.limit}
                setPage={pagination.setPage}
                tw="mt-4"
              />
            </>
          )}
        </Suspense>
      </Container>
    </Suspense>
  )
}

export default ListPlayersPage
