import React, { FC } from 'react'

import 'twin.macro'
import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import Input from '../../../../ui-blocks/input'
import CardResourceRadio from '../../../../ui-blocks/card-resource-radio'
import { CardResourceProps } from '../../../../ui-blocks/card-resource'

import ErrorMessage from '../../../../components/error-message'

import { useListAreaTypesQuery } from '../../../../graphql/components'

import { BindPlayerFormikValues } from '..'

import { ReactComponent as BillboardIcon } from '../../../../assets/icons/billboard.svg'
import { ReactComponent as IndoorIcon } from '../../../../assets/icons/indoor_audience.svg'
import { ReactComponent as OutdoorIcon } from '../../../../assets/icons/outdoor_audience.svg'
import tw from 'twin.macro'

export const editPlayerValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  demographics: object().shape({
    language_ids: array(string()),
    country_id: string().nullable(),
    area_type_id: string().required('Area type is required'),
    venue_type_ids: array(string()),
  }),
})

const PlayerInformationStep: FC = () => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
  } = useFormikContext<BindPlayerFormikValues>()

  const { data: areaTypesData } = useListAreaTypesQuery()
  const setAreaType = (areaTypeId: string) => {
    setFieldValue('demographics', {
      ...values.demographics,
      area_type_id: areaTypeId,
    })
  }

  const areaTypes: CardResourceProps[] = (areaTypesData?.areaTypes || []).map(
    (areaType, index) => ({
      value: areaType._id,
      title: areaType.name || '',
      name: `card-resource#${index}`,
      description: areaType.description || '',
      icon: (areaType.name?.toLowerCase().includes('indoor') && (
        <IndoorIcon width={24} height={24} title={areaType.name || ''} />
      )) ||
        (areaType.name?.toLowerCase().includes('outdoor') && (
          <OutdoorIcon width={24} height={24} title={areaType.name || ''} />
        )) || (
          <BillboardIcon width={24} height={24} title={areaType.name || ''} />
        ),
    })
  )

  return (
    <div tw="max-w-xl">
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Name
        </span>
        <Input
          type="text"
          name="name"
          value={values.name}
          error={errors.name}
          onChange={handleChange}
        />
        <span tw="mt-12 mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Area Type
        </span>
        <div tw="text-charcoal text-base mb-6">
          Improve your player's reachability by defining the Area Type.
        </div>
        <CardResourceRadio
          cards={areaTypes}
          onChange={setAreaType}
          css={tw`flex-row`}
          value={values.demographics?.area_type_id}
        />
        {!!errors.demographics?.area_type_id && (
          <ErrorMessage tw="mt-2">
            {errors.demographics?.area_type_id}
          </ErrorMessage>
        )}
      </section>
    </div>
  )
}

export default PlayerInformationStep
