import React, { FC } from 'react'
import 'twin.macro'

import Container from '../ui-blocks/container'
import Illustration, { IllustrationEnum } from '../ui-blocks/illustration'

const NoInternetConnection: FC = () => (
  <Container>
    <div tw="flex flex-col items-center justify-center">
      <Illustration name={IllustrationEnum.no_wifi} />
      <h2 tw="text-charcoal font-light leading-tight text-3xl mt-10 mb-4">
        There seems to be a connection problem
      </h2>
      <p tw="text-charcoal font-medium text-sm pt-4 pb-6 w-1/2 text-center">
        Please check your internet connection. Maecenas varius tortor nibh, sit
        amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam
        hendrerit molestie.
      </p>
    </div>
  </Container>
)

export default NoInternetConnection
