import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { NODE_ENV, SENTRY_DSN } from './config'

if (NODE_ENV !== 'development') {
  Sentry.init({
    environment: NODE_ENV,
    tracesSampleRate: 1.0,
    integrations: [new Integrations.BrowserTracing()],
    release: '@diffuse.tv/dashboard@' + process.env.npm_package_version,
    dsn: SENTRY_DSN,
  })
}
