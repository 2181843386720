import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import InfoStep from './components/info-step'
import ReasonStep, { OPTIONS } from './components/reason-step'

import ProgressModal from '../../../../../../../components/progress-modal'

export interface ArchivePlayerModalProps {
  playerName: string
  open?: boolean
  loading?: boolean
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: (reason: string) => void
}

const ArchivePlayerModal: FC<ArchivePlayerModalProps> = ({
  playerName,
  onClose,
  onCancel,
  onConfirm,
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [archiveReason, setArchiveReason] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<string>('')

  const finalReason = useMemo(
    () =>
      selectedOption === 'other' ? archiveReason : OPTIONS[selectedOption],
    [selectedOption, archiveReason]
  )

  const steps = [
    <InfoStep key="info-step" />,
    <ReasonStep
      key="reason-step"
      reason={archiveReason}
      option={selectedOption}
      onChangeReason={setArchiveReason}
      onSelectOption={setSelectedOption}
    />,
  ]

  const handleClose = () => {
    setCurrentStep(1)
    setArchiveReason('')
    setSelectedOption('')
    onClose?.()
  }

  const handleCancel = () => {
    if (currentStep > 0) return setCurrentStep(currentStep - 1)
    onCancel?.()
  }

  const handleConfirm = () => {
    if (currentStep < steps.length - 1) return setCurrentStep(currentStep + 1)
    onConfirm?.(finalReason)
  }

  return (
    <ProgressModal
      width="47.125rem"
      totalSteps={steps.length}
      currentStep={currentStep + 1}
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={`Archive player "${playerName}"`}
      disabled={currentStep === steps.length && !finalReason}
      {...props}
    >
      {steps.map((step, index) => index === currentStep && step)}
    </ProgressModal>
  )
}

export default ArchivePlayerModal
