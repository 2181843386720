import React, { FC, Fragment, useMemo, useState } from 'react'
import _ from 'lodash'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

import RestoreModal from '../restore-modal'
import RestoreAction from '../restore-action'

import Saturation from '../../../../components/saturation'
import InlineDate from '../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../components/table'

import {
  CampaignAdGroupListFragment,
  useRestoreCampaignAdGroupsMutation,
} from '../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../../utils/data-manipulation'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

const ArchivedAdGroupsListTable: FC<
  TableDataProps<CampaignAdGroupListFragment>
> = ({ data, ...props }) => {
  const [
    restoreAdGroup,
    { loading: restoringAdGroup },
  ] = useRestoreCampaignAdGroupsMutation()

  const [isRestoreModalOpen, openRestoreModal] = useState<boolean>(false)
  const [
    selectedAdGroup,
    setSelectedAdGroup,
  ] = useState<CampaignAdGroupListFragment | null>(null)

  const onCloseModal = () => {
    openRestoreModal(false)
    setSelectedAdGroup(null)
  }

  const onSelectNetwork = (adGroup: CampaignAdGroupListFragment) => {
    setSelectedAdGroup(adGroup)
    openRestoreModal(true)
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'restore-ad-group-error-alert',
      message: "We couldn't restore this ad group. Please try again later.",
    })
  }

  const onRestoreAdGroup = async () => {
    try {
      const res = await restoreAdGroup({
        variables: { ids: [selectedAdGroup?._id] },
      })
      if (!res.data?.areRestored) return handleError()

      onCloseModal()
      document.dispatchEvent(new CustomEvent('refetch-ad-groups'))
      document.dispatchEvent(new CustomEvent('refetch-archived-ad-groups'))
    } catch {
      handleError()
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((adGroup) => ({
        name: {
          value: adGroup.name,
          content: adGroup.name,
        },
        creatives: {
          value: adGroup.creatives?.length ?? 0,
          content: adGroup.creatives?.length ?? 0,
        },
        saturation: {
          value: adGroup.saturation ?? 0,
          content: <Saturation saturation={adGroup.saturation} />,
        },
        areaType: {
          value: adGroup.audience?.area_type,
          content: adGroup.audience?.area_type,
        },
        startDate: {
          value: adGroup.timeframe?.starts_at,
          content: (
            <InlineDate date={adGroup.timeframe?.starts_at} format="ll" />
          ),
        },
        endDate: {
          value: adGroup.timeframe?.ends_at,
          content: <InlineDate date={adGroup.timeframe?.ends_at} format="ll" />,
        },
        createdAt: {
          value: adGroup.created?.at,
          content: <InlineDate date={adGroup.created?.at} />,
        },
        archivedAt: {
          value: adGroup.archived?.at,
          content: <InlineDate date={adGroup.archived?.at} />,
        },
        restore: {
          content: <RestoreAction onClick={() => onSelectNetwork(adGroup)} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Ad Group"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.ad_groups_empty_results} />
        }
        cols={[
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'creatives',
            label: 'Creatives',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'saturation',
            label: 'Saturation',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          { key: 'areaType', label: 'Area Type', isSortable: true, width: 100 },
          {
            key: 'startDate',
            label: 'Start Date',
            isSortable: true,
            alignment: 'right',
            width: 142,
          },
          {
            key: 'endDate',
            label: 'End Date',
            isSortable: true,
            alignment: 'right',
            width: 142,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'archivedAt',
            label: 'Archived At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'restore', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RestoreModal
        target="ad group"
        open={isRestoreModalOpen}
        loading={restoringAdGroup}
        onConfirm={onRestoreAdGroup}
        itemName={selectedAdGroup?.name || ''}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default ArchivedAdGroupsListTable
