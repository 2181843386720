import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useLocation } from 'react-router-dom'

import Icon from '../../../../ui-blocks/icon'
import Search from '../../../../ui-blocks/search'
import Suspense from '../../../../ui-blocks/suspense'
import Container from '../../../../ui-blocks/container'
import ButtonLink from '../../../../ui-blocks/button-link'
import PageHeader from '../../../../ui-blocks/page-header'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import InitialSetup from './components/initial-setup'
import CampaignsEmpty from './components/campaigns-empty'

import LoadingPage from '../../../../components/loading-page'
import CampaignsListTable from '../../../../components/campaign/table'

import {
  useCountItemsQuery,
  useListCampaignsQuery,
  useCountCampaignsQuery,
  EnumWorkspacePlatform_Type,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

import { authentication } from '../../../../stores'

type CountItemsQueryReturnType = ReturnType<typeof useCountItemsQuery>
type CountItemsQueryRefetch = CountItemsQueryReturnType['refetch']

export interface ListInternalCampaignsPageProps {
  countData: {
    loading: boolean
    campaigns: number
    players: number
    creatives: number
    networks: number
  }
  refetchCount?: CountItemsQueryRefetch
}

const ListInternalCampaignsPage: FC<ListInternalCampaignsPageProps> = ({
  countData,
  refetchCount,
}) => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: campaignCountData,
    loading: loadingCampaignCount,
  } = useCountCampaignsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const campaignsCount = useMemo(() => campaignCountData?.campaigns || 0, [
    campaignCountData,
  ])
  const pagination = usePagination(campaignsCount, 15, searchTextDebounced)

  const {
    data: campaignsData,
    loading: loadingCampaigns,
    refetch: refetchCampaigns,
  } = useListCampaignsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const refetchData = async () => {
    await refetchCount?.()
    await refetchCampaigns()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-campaigns', refetchData)
    return () => {
      document.removeEventListener('refetch-campaigns', refetchData)
    }
  }, [])

  const hasPlayers = useMemo(() => !!countData.players, [countData])
  const hasCreatives = useMemo(() => !!countData.creatives, [countData])
  const hasNetworks = useMemo(() => !!countData.networks, [countData])
  const hasCampaigns = useMemo(() => !!countData.campaigns, [countData])

  const allCampaigns = useMemo(
    () =>
      (campaignsData?.campaigns || []).filter(
        (campaign) => !campaign.is_external
      ),
    [campaignsData]
  )

  return (
    <Suspense ready={!countData.loading} fallback={<LoadingPage />}>
      <Suspense
        ready={
          hasCampaigns ||
          ((hasPlayers ||
            authentication.selected_workspace.platform_type ===
              EnumWorkspacePlatform_Type.Advertiser) &&
            hasCreatives)
        }
        fallback={
          <InitialSetup
            hasPlayers={hasPlayers}
            hasCreatives={hasCreatives}
            hasNetworks={hasNetworks}
            workspaceType={authentication.selected_workspace.platform_type}
          />
        }
      >
        <Container>
          <PageHeader
            title="Workspace Campaigns"
            description={
              countData.loading ? 'Loading...' : `${countData.campaigns} TOTAL`
            }
          >
            {hasCampaigns && (
              <div tw="max-w-80 justify-self-end">
                <Search
                  value={searchText}
                  loading={countData.loading && loadingCampaignCount}
                  onSelect={(value) =>
                    setSearchText(!Array.isArray(value) ? value || '' : '')
                  }
                />
              </div>
            )}

            {hasCampaigns && (
              <ButtonLink
                tw="justify-self-end"
                to="/campaigns/create"
                iconLeft={<Icon icon="add" tw="text-lg" />}
              >
                Add Campaign
              </ButtonLink>
            )}
          </PageHeader>

          <Suspense ready={hasCampaigns} fallback={<CampaignsEmpty />}>
            <CampaignsListTable
              data={allCampaigns}
              loading={loadingCampaigns && loadingCampaignCount}
              searchQuery={searchTextDebounced}
            />
            <PaginationNav
              page={pagination.page}
              count={pagination.count}
              limit={pagination.limit}
              setPage={pagination.setPage}
              tw="mt-4"
            />
          </Suspense>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ListInternalCampaignsPage
