import React, { FC, Fragment, useCallback, useMemo, useState } from 'react'
import sortBy from 'lodash/sortBy'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'
import RestoreModal from '../restore-modal'
import RestoreAction from '../restore-action'
import InlineDate from '../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../components/table'
import {
  EnumRemovedReason,
  CampaignListFragment,
  useRestoreCampaignsMutation,
} from '../../../../graphql/components'
import {
  sortObjectByKey,
  groupDataByYear,
  getCampaignTimeframe,
} from '../../../../utils/data-manipulation'
import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

const ArchivedCampaignsListTable: FC<TableDataProps<CampaignListFragment>> = ({
  data,
  ...props
}) => {
  const [
    restoreCampaign,
    { loading: restoringCampaign },
  ] = useRestoreCampaignsMutation()

  const [isRestoreModalOpen, openRestoreModal] = useState<boolean>(false)
  const [
    selectedCampaign,
    setSelectedCampaign,
  ] = useState<CampaignListFragment | null>(null)

  const onCloseModal = useCallback(() => {
    openRestoreModal(false)
    setSelectedCampaign(null)
  }, [openRestoreModal, setSelectedCampaign])

  const onSelectCampaign = useCallback(
    (campaign: CampaignListFragment) => {
      setSelectedCampaign(campaign)
      openRestoreModal(true)
    },
    [openRestoreModal, setSelectedCampaign]
  )

  const handleError = useCallback(() => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'restore-campaign-error-alert',
      message: "We couldn't restore this campaign. Please try again later.",
    })
  }, [])

  const onRestoreCampaign = useCallback(async () => {
    try {
      const res = await restoreCampaign({
        variables: { ids: [selectedCampaign?._id] },
      })
      if (!res.data?.areRestored) return handleError()

      onCloseModal()
      document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      document.dispatchEvent(new CustomEvent('refetch-archived-campaigns'))
    } catch {
      handleError()
    }
  }, [restoreCampaign, selectedCampaign, handleError])

  const sortedData = useMemo(() => sortBy(data, ['created.at']).reverse(), [
    data,
  ])

  const tableData = useMemo(
    () =>
      sortedData.map((campaign) => {
        const [startDate, endDate] = getCampaignTimeframe(
          campaign.ad_groups as any[]
        )

        const targetsCount =
          campaign.broadcast_targets?.filter(
            (target) => target?.removed?.reason === EnumRemovedReason.NotRemoved
          ).length ?? 0

        return {
          name: {
            value: campaign.name,
            content: campaign.name,
          },
          adGroups: {
            value: campaign.ad_groups?.length ?? 0,
            content: campaign.ad_groups?.length ?? 0,
          },
          broadcastTargets: {
            value: targetsCount,
            content: targetsCount,
          },
          campaignStart: {
            value: startDate,
            content: <InlineDate date={startDate} format="ll" />,
          },
          campaignEnd: {
            value: endDate,
            content: <InlineDate date={endDate} format="ll" />,
          },
          createdAt: {
            value: campaign.created?.at,
            content: <InlineDate date={campaign.created?.at} />,
          },
          archivedAt: {
            value: campaign.archived?.at,
            content: <InlineDate date={campaign.archived?.at} />,
          },
          restore: {
            content: (
              <RestoreAction onClick={() => onSelectCampaign(campaign)} />
            ),
          },
        }
      }),
    [sortedData]
  )

  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Campaign"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.campaigns_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'adGroups',
            label: 'Ad Groups',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'broadcastTargets',
            label: 'Broadcast Targets',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'campaignStart',
            label: 'Start Date',
            isSortable: true,
            alignment: 'right',
            width: 142,
          },
          {
            key: 'campaignEnd',
            label: 'End Date',
            isSortable: true,
            alignment: 'right',
            width: 142,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'archivedAt',
            label: 'Archived At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'restore', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RestoreModal
        target="campaign"
        open={isRestoreModalOpen}
        loading={restoringCampaign}
        onConfirm={onRestoreCampaign}
        itemName={selectedCampaign?.name || ''}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default ArchivedCampaignsListTable
