import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import {
  Informations,
  InformationsSection,
} from '../../../../ui-blocks/information'
import Status from '../../../../ui-blocks/status'
import KPICard from '../../../../ui-blocks/kpi-card'

import Categories from '../../../../components/categories'
import InlineDate from '../../../../components/inline-date'
import PreviewScreen from '../../../../components/preview-screen'
import UploadDuration from '../../../../components/upload/duration'
import UploadThumbnail from '../../../../components/upload/thumbnail'

import {
  EnumCreativeStatus,
  CreativeFullFragment,
} from '../../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'

export interface OverviewSectionProps {
  creative?: CreativeFullFragment
}

const OverviewSection: FC<OverviewSectionProps> = ({ creative }) => {
  const [isPreviewOpen, openPreview] = useState<boolean>(false)

  const intervals = useMemo(getKPIIntervals, [])
  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, creative?.stats || []),
    [intervals, creative]
  )

  return (
    <section>
      <Informations title="Creative Information" tw="mb-14">
        <InformationsSection
          label="PREVIEW"
          css={['width: 19rem;', 'margin-right: 2rem;', 'flex-grow: unset;']}
        >
          <UploadThumbnail
            tw="rounded"
            upload={creative?.upload || undefined}
            onClick={() => openPreview(true)}
          />
        </InformationsSection>
        <div tw="flex flex-col flex-grow">
          <div tw="w-full flex flex-row flex-wrap gap-4">
            <InformationsSection label="STATUS">
              <Status value={creative?.status as EnumCreativeStatus} />
            </InformationsSection>
            <InformationsSection label="TYPE">
              {creative?.upload?.type.toUpperCase()}
            </InformationsSection>
            <InformationsSection label="UPLOAD DATE">
              <InlineDate date={new Date(creative?.upload?.created?.at)} />
            </InformationsSection>
            <InformationsSection label="TIME">
              <UploadDuration upload={creative?.upload as any} />
            </InformationsSection>
            <InformationsSection label="CATEGORY">
              <Categories categoryIds={creative?.categories_ids || []} />
            </InformationsSection>
          </div>
        </div>
      </Informations>
      <KPICard title="Creative Analytics" intervals={stats} />
      {creative && (
        <PreviewScreen
          currentIndex={0}
          open={isPreviewOpen}
          creatives={[creative]}
          onClose={() => openPreview(false)}
        />
      )}
    </section>
  )
}

export default OverviewSection
