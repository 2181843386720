import React, { FC } from 'react'
import Text from './text'

//TODO: Handle disabled label
export const FormLabel: FC<{
  inputId?: string
  label: string
  labelExtra?: string
  disabled?: boolean
  required?: boolean
}> = ({ inputId, label, labelExtra, required }) => {
  return (
    <Text
      as="label"
      tw="flex flex-row items-center justify-between pb-2"
      htmlFor={inputId}
    >
      {typeof label === 'string' ? (
        <Text as="span" preset="caption">
          {label} {!!required && '*'}
        </Text>
      ) : (
        <>
          {label} {!!required && '*'}
        </>
      )}
      {typeof labelExtra === 'string' ? (
        <Text as="span" preset="caption" transform="lowercase">
          {labelExtra}
        </Text>
      ) : (
        labelExtra
      )}
    </Text>
  )
}
