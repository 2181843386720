const $ = process.env

export const NODE_ENV =
  $.REACT_APP_ENVIRONMENT || $.REACT_APP_NODE_ENV || $.NODE_ENV || 'development'

export const API_BASE_URL =
  (NODE_ENV === 'production' && 'https://api.diffuse.tv') ||
  (NODE_ENV === 'staging' && 'https://api.staging.diffuse.tv') ||
  (NODE_ENV === 'next' && 'https://api.next.diffuse.tv') ||
  'http://localhost:4000'

export const PLAYER_BASE_URL =
  (NODE_ENV === 'production' && 'https://player.diffuse.tv') ||
  (NODE_ENV === 'staging' && 'https://player.staging.diffuse.tv') ||
  (NODE_ENV === 'next' && 'https://player.next.diffuse.tv') ||
  'http://localhost:2000'

export const API_GRAPHQL_URL = `${API_BASE_URL}/dashboard/graphql`

export const reCaptchaSiteKey =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6LfCv7QaAAAAAADilt2rDa5_jdMrcoN8OIIbc3Z_'

export const REACT_APP_BASE_URL = $.REACT_APP_BASE_URL || '/'
export const MAPBOX_API_TOKEN =
  $.MAPBOX_API_TOKEN ||
  'pk.eyJ1IjoicmVhbHRpbWVhZHMiLCJhIjoiY2s3Z2kyOWJsMGIwNzNtbzJrczR1bGd2ZyJ9.mWdUsYnFU4GELKOHjwbfaQ'

export const STRIPE_PUBLISHABLE_KEY =
  $.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
  'pk_test_51IGsJuGEJs3DxLFAV3R5u6rWrXsTVaw7XxwmvjcOeJqt7F2H3LHV66YAqUAydZbHq3UFEqpSSNvJZzxuNCp85WMG00bHiNFU4N'

export const SENTRY_DSN =
  $.REACT_APP_SENTRY_DSN ||
  'https://b9191924a5c443fd97bfb486cd151baf@sentry.mosano.eu/3'

export const CRISP_WEBSITE_ID =
  $.REACT_APP_CRISP_WEBSITE_ID || '9b5caf99-1380-45c6-840c-0a68677a5fbe'

export const HOTJAR_SITE_ID =
  (Number($?.REACT_APP_HOTJAR_SITE_ID) > 0 &&
    Number($?.REACT_APP_HOTJAR_SITE_ID)) ||
  (NODE_ENV === 'production' && 2919178) ||
  (NODE_ENV === 'staging' && 2433785) ||
  (NODE_ENV === 'next' && 2919176) ||
  undefined
