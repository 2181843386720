import React, { FC } from 'react'
import 'twin.macro'

import EditNetworkForm from './components/edit-network-form'
import ArchiveNetworkForm from './components/archive-network-form'

import SettingsPanel from '../../../../../components/settings-panel'

import { NetworkFullFragment } from '../../../../../graphql/components'

export interface SettingsSectionProps {
  network?: NetworkFullFragment
  refetchNetwork?: () => Promise<any>
}

const SettingsSection: FC<SettingsSectionProps> = ({
  network,
  refetchNetwork,
}) => (
  <section>
    <SettingsPanel
      sections={[
        {
          title: 'Details',
          content: (
            <EditNetworkForm
              network={network}
              refetchNetwork={refetchNetwork}
            />
          ),
        },
        {
          title: 'Archive',
          content: <ArchiveNetworkForm network={network} />,
        },
      ]}
    />
  </section>
)

export default SettingsSection
