import React, { FC, useMemo, useState, useEffect } from 'react'
import 'twin.macro'

import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ArchivedCreativesListTable from './table'

import PreviewScreen from '../../../../components/preview-screen'

import {
  useCountArchivedCreativesQuery,
  useListArchivedCreativesQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { SearchSettings } from '../../../../utils/use-search'

export interface CreativesSectionProps {
  onSearch?: (settings: SearchSettings) => void
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  searchTextDebounced: string
}

const CreativesSection: FC<CreativesSectionProps> = ({
  onSearch,
  setSearchText,
  searchText,
  searchTextDebounced,
}) => {
  const [previewIndex, setPreviewIndex] = useState<number>(0)
  const [isPreviewOpen, openPreview] = useState<boolean>(false)

  const {
    data: archivedCreativesCountData,
    loading: loadingArchivedCreativesCount,
  } = useCountArchivedCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const count = useMemo(() => archivedCreativesCountData?.creatives || 0, [
    archivedCreativesCountData,
  ])
  const pagination = usePagination(count, 15, searchTextDebounced)
  const {
    data: archivedCreativesData,
    loading: loadingArchivedCreatives,
    refetch: refetchArchivedCreatives,
  } = useListArchivedCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const archivedCreatives = useMemo(
    () => archivedCreativesData?.creatives || [],
    [archivedCreativesData]
  )

  const refetchData = async () => await refetchArchivedCreatives()
  useEffect(() => {
    document.addEventListener('refetch-archived-creatives', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-creatives', refetchData)
    }
  }, [])

  useEffect(() => {
    onSearch?.({
      text: searchText,
      loading: loadingArchivedCreatives && loadingArchivedCreativesCount,
      entries: archivedCreatives.map((creative) => creative.name),
      onSelect: (value) =>
        setSearchText(!Array.isArray(value) ? value || '' : ''),
    })
  }, [
    searchText,
    archivedCreatives,
    loadingArchivedCreatives,
    loadingArchivedCreativesCount,
  ])

  const onOpenPreview = (index: number) => {
    setPreviewIndex(index)
    openPreview(true)
  }

  const onClosePreview = () => {
    setPreviewIndex(0)
    openPreview(false)
  }

  const goToNextCreative = () => {
    if (previewIndex < archivedCreatives.length - 1)
      setPreviewIndex(previewIndex + 1)
  }

  const goToPreviousCreative = () => {
    if (previewIndex > 0) setPreviewIndex(previewIndex - 1)
  }

  return (
    <section>
      <ArchivedCreativesListTable
        data={archivedCreatives}
        loading={loadingArchivedCreatives && loadingArchivedCreativesCount}
        searchQuery={searchTextDebounced}
        onClickThumbnail={onOpenPreview}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
      <PreviewScreen
        open={isPreviewOpen}
        creatives={archivedCreatives}
        currentIndex={previewIndex}
        onClose={onClosePreview}
        onNext={goToNextCreative}
        onPrevious={goToPreviousCreative}
      />
    </section>
  )
}

export default CreativesSection
