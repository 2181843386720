import React, { FC } from 'react'
import 'twin.macro'

import { InputFormik } from '../../../../../../ui-blocks/input'

import CountryInput from '../../../../../../components/country-input'

import FieldsRow from './fields-row'

import { IFormikObject } from '../../../../../../typings'

import { PaymentMethodFormikValues } from '..'

export interface BillingInformationFormProps {
  formik: IFormikObject<PaymentMethodFormikValues>
}

const BillingInformationForm: FC<BillingInformationFormProps> = ({
  formik,
}) => (
  <div tw="max-w-xs pb-4">
    <FieldsRow>
      <InputFormik
        type="text"
        name="company"
        label="Company"
        className="billing-input"
        formik={formik}
      />
    </FieldsRow>
    <FieldsRow>
      <InputFormik
        type="text"
        name="vatNumber"
        label="VAT Number"
        labelExtra="optional"
        className="billing-input"
        formik={formik}
      />
    </FieldsRow>
    <FieldsRow>
      <InputFormik
        type="text"
        name="address"
        label="Address"
        className="billing-input"
        formik={formik}
      />
    </FieldsRow>
    <FieldsRow>
      <InputFormik
        type="text"
        name="zipCode"
        label="ZIP Code"
        className="billing-input"
        formik={formik}
      />
      <InputFormik
        type="text"
        name="city"
        label="City"
        className="billing-input"
        formik={formik}
      />
    </FieldsRow>
    <FieldsRow flex={false}>
      <CountryInput
        showLabel
        error={formik.errors.country}
        success={formik.touched.country && !formik.errors.country}
        initialValue={formik.values.country}
        onSelect={(value) =>
          formik.setValues({ ...formik.values, country: value })
        }
      />
    </FieldsRow>
  </div>
)

export default BillingInformationForm
