import React, { FC, useMemo } from 'react'

import _ from 'lodash'
import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import ManagePlayersModal from './modal'

import {
  useViewNetworkQuery,
  useManagePlayersByNetworkMutation,
} from '../../graphql/components'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

interface ManagePlayersFormikValues {
  players_ids: string[]
}

const managePlayersValidationSchema = object().shape({
  players_ids: array().ensure().of(string()).min(1),
})

export interface ManagePlayersByNetworkProps {
  open?: boolean
  networkId: string
  onClose?: () => void
  refetchData?: () => Promise<any>
}

const ManagePlayersByNetwork: FC<ManagePlayersByNetworkProps> = ({
  open,
  networkId,
  onClose,
  refetchData,
}) => {
  const {
    data: networkData,
    loading: loadingNetwork,
    refetch: refetchNetwork,
  } = useViewNetworkQuery({
    skip: !networkId,
    fetchPolicy: 'cache-first',
    variables: { _id: networkId },
  })

  const network = networkData?.network
  const initialPlayersIds = useMemo(
    () =>
      _.uniq(
        (network?.players?.map((player) => player?.player?._id) || []).filter(
          Boolean
        )
      ),
    [networkData]
  )

  const [
    updateNetwork,
    { loading: updatingNetwork },
  ] = useManagePlayersByNetworkMutation()
  const formik = useFormik<ManagePlayersFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: managePlayersValidationSchema,
    initialValues: { players_ids: initialPlayersIds },
    async onSubmit({ players_ids }) {
      try {
        const selectedIds = _.xor(initialPlayersIds, players_ids)
        const res = await updateNetwork({
          variables: { network_id: networkId, players_ids: selectedIds },
        })
        if (!res.data?.isUpdated) return handleError()

        await refetchData?.()
        await refetchNetwork()
        onCloseModal(false)
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingNetwork) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-network-error-alert',
      message: `We couldn't update this network. Please try again later.`,
    })
  }

  return (
    <ManagePlayersModal
      open={open}
      formik={formik}
      loading={loadingNetwork}
      onClose={onCloseModal}
    />
  )
}

export default ManagePlayersByNetwork
