import React, { FC } from 'react'
import 'twin.macro'

import ButtonLink from '../../../../ui-blocks/button-link'
import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

const PlayersEmpty: FC = () => (
  <div tw="flex flex-col items-center">
    <Illustration name={IllustrationEnum.players_empty_state} tw="mb-10" />
    <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
      Stream in your Players
    </h1>
    <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
      Bind your players to this workspace
    </p>
    <ButtonLink to="/players/bind">Bind</ButtonLink>
  </div>
)

export default PlayersEmpty
