import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from '../../../../ui-blocks/icon'
import Card from '../../../../ui-blocks/card'
import Button from '../../../../ui-blocks/button'

import ErrorMessage from '../../../../components/error-message'

export interface WorkspaceInviteProps {
  logo?: string
  name?: string
  error?: string
  members?: number
  originEmail?: string
  loadingAccept?: boolean
  loadingDecline?: boolean
  onAccept?: () => void
  onDecline?: () => void
}

const WorkspaceInvite: FC<WorkspaceInviteProps> = ({
  logo,
  name,
  error,
  members,
  originEmail,
  loadingAccept,
  loadingDecline,
  onAccept,
  onDecline,
  ...props
}) => (
  <div tw="w-full" {...props}>
    <Card css={['width: 22.5rem;', tw`px-4 pt-4 pb-2`]}>
      <div tw="flex items-center">
        {!!logo && (
          <img
            src={logo}
            alt="Workspace Logo"
            tw="w-16 h-16 border-0 rounded mr-4 flex-shrink-0"
          />
        )}
        <div tw="flex-1">
          <div tw="text-charcoal font-medium">{name}</div>
          <div tw="whitespace-pre-line text-charcoal text-xs">
            {`${members || 0} Member(s)`}
          </div>
          {originEmail && (
            <div tw="whitespace-pre-line text-charcoal text-xs">
              {`Sent by ${originEmail}`}
            </div>
          )}
        </div>
      </div>
      <div tw="mt-6 mb-2 flex flex-row space-x-4">
        {!!onAccept && (
          <Button
            type="button"
            loading={loadingAccept}
            disabled={loadingAccept || loadingDecline}
            iconLeft={<Icon icon="check" tw="text-lg" />}
            onClick={onAccept}
          >
            Accept
          </Button>
        )}
        {!!onDecline && (
          <Button
            secondary
            type="button"
            loading={loadingDecline}
            disabled={loadingAccept || loadingDecline}
            iconLeft={<Icon icon="close" tw="text-lg" />}
            onClick={onDecline}
          >
            Decline
          </Button>
        )}
      </div>
    </Card>
    {!!error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
)

export default WorkspaceInvite
