import React, { FC } from 'react'
import 'twin.macro'

export interface TagProps {
  text: string
}

const Tag: FC<TagProps> = ({ text }) => (
  <div tw="max-w-max text-white text-xs leading-tight bg-dark-blue-gray border rounded h-6 px-2 py-1">
    {text}
  </div>
)

export default Tag
