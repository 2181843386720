import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string } from 'yup'
import Radio from '../../../../ui-blocks/radio'
import { EnumPlayerSettingsOrientation } from '../../../../graphql/components'
import { BindPlayerFormikValues } from '..'
import Checkbox from '../../../../ui-blocks/checkbox'
import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'
import { NODE_ENV } from '../../../../config'
import tw from 'twin.macro'
import CardResourceRadio from '../../../../ui-blocks/card-resource-radio'

export const playerSettingsValidationSchema = object().shape({
  settings: object()
    .shape({
      orientation: string()
        .oneOf([
          EnumPlayerSettingsOrientation.Landscape,
          EnumPlayerSettingsOrientation.Portrait,
          EnumPlayerSettingsOrientation.ReversedLandscape,
          EnumPlayerSettingsOrientation.ReversedPortrait,
        ])
        .required(),
      storage_medium_id: string().required(),
    })
    .required(),
})

const PlayerSettingsStep: FC = () => {
  const { values, setFieldValue } = useFormikContext<BindPlayerFormikValues>()

  const screenOrientations = Object.values(EnumPlayerSettingsOrientation)
  const [isReverse, setIsReverse] = useState<boolean>(false)

  const setOrientation = (
    orientation: EnumPlayerSettingsOrientation,
    reverse: boolean
  ) => {
    const actualOrientation =
      (orientation === EnumPlayerSettingsOrientation.Landscape &&
        reverse &&
        EnumPlayerSettingsOrientation.ReversedLandscape) ||
      (orientation === EnumPlayerSettingsOrientation.Portrait &&
        reverse &&
        EnumPlayerSettingsOrientation.ReversedPortrait) ||
      (orientation === EnumPlayerSettingsOrientation.ReversedLandscape &&
        !reverse &&
        EnumPlayerSettingsOrientation.Landscape) ||
      (orientation === EnumPlayerSettingsOrientation.ReversedPortrait &&
        !reverse &&
        EnumPlayerSettingsOrientation.ReversedPortrait) ||
      orientation
    setFieldValue('settings.orientation', actualOrientation)
  }

  const orientationIllustration = useMemo(() => {
    const orientation = values.settings?.orientation
    const illustration =
      (orientation === EnumPlayerSettingsOrientation.Landscape &&
        IllustrationEnum.landscape) ||
      (orientation === EnumPlayerSettingsOrientation.Portrait &&
        IllustrationEnum.portrait) ||
      (orientation === EnumPlayerSettingsOrientation.ReversedLandscape &&
        IllustrationEnum.reverse_landscape) ||
      (orientation === EnumPlayerSettingsOrientation.ReversedPortrait &&
        IllustrationEnum.reverse_portrait) ||
      IllustrationEnum.landscape
    return illustration
  }, [values?.settings?.orientation])

  const storageCards = useMemo(
    () =>
      [...(values?.specs?.storage || [])]
        .filter(
          (storageAdapter) =>
            // only show ipfs adapters if we're on the development environment
            storageAdapter?.adapter !== 'ipfs' || NODE_ENV === 'development'
        )
        .map((storageAdapter) => ({
          name: 'settings.storage_medium_id',
          value: String(storageAdapter?.id),
          title: `${storageAdapter?.id}`,
          description: `(total ${storageAdapter?.size?.total || 0} MB)`,
        })),
    [values?.specs?.storage]
  )

  return (
    <div tw="max-w-xl">
      <section>
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Settings
        </span>

        <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Orientation
        </span>

        <div tw="mt-2 mb-8 space-y-1">
          {screenOrientations
            .filter((value) => !value.includes('REVERSE'))
            .map((value, index) => (
              <div key={`orientation-${value.toLowerCase()}`}>
                <Radio
                  value={value}
                  label={value}
                  id={`orientation#${index}`}
                  name="settings.orientation"
                  checked={(values.settings?.orientation || '').includes(value)}
                  onChange={() => setOrientation(value, isReverse)}
                />
              </div>
            ))}
          <Checkbox
            label="Reverse (180º)"
            checked={isReverse}
            onChange={() => {
              setOrientation(values?.settings?.orientation, !isReverse)
              setIsReverse(!isReverse)
            }}
          />
        </div>
        <div tw="mt-2 mb-8">
          <Illustration name={orientationIllustration} />
        </div>
      </section>
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Storage
        </span>
        <div tw="mb-8">
          <CardResourceRadio
            cards={storageCards}
            css={tw`flex-row`}
            value={values.settings?.storage_medium_id}
            onChange={(storageAdapter: string) =>
              setFieldValue(
                'settings.storage_medium_id',
                String(storageAdapter)
              )
            }
          />
          {}
        </div>
      </section>
    </div>
  )
}

export default PlayerSettingsStep
