import React, { FC, Fragment } from 'react'
import 'twin.macro'

import Text from '../../../ui-blocks/text'
import Card from '../../../ui-blocks/card'
import Container from '../../../ui-blocks/container'
import Accordion from '../../../ui-blocks/accordion'
import ButtonLink from '../../../ui-blocks/button-link'
import Illustration, { IllustrationEnum } from '../../../ui-blocks/illustration'

import {
  CountItemsQuery,
  EnumWorkspacePlatform_Type,
} from '../../../graphql/components'

import { authentication } from '../../../stores'

export const useOnboardingPage = (
  count?: CountItemsQuery,
  workspaceType?: EnumWorkspacePlatform_Type
) => {
  const noCreatives = (count?.creatives || 0) === 0
  const noNetworks = (count?.networks || 0) === 0
  const noPlayers = (count?.players || 0) === 0
  const noCampaigns = (count?.campaigns || 0) === 0
  return {
    renderOnboardingPage:
      noCreatives ||
      noCampaigns ||
      ((noNetworks || noPlayers) &&
        workspaceType !== EnumWorkspacePlatform_Type.Advertiser),
  }
}

export interface WorkspaceOnboardingPageProps {
  count?: CountItemsQuery
  workspaceType?: EnumWorkspacePlatform_Type
}

const WorkspaceOnboardingPage: FC<WorkspaceOnboardingPageProps> = ({
  count,
  workspaceType,
}) => {
  const onboardingSteps = [
    ...(workspaceType !== undefined &&
    workspaceType !== EnumWorkspacePlatform_Type.Advertiser
      ? [
          {
            title: 'Bind your players',
            done: (count?.players ?? 0) > 0,
            buttonLabel: 'Bind player',
            description:
              'Campaigns need target channels to play content. Connect your displays and set your first player to get to action.',
            linkTo: '/players/bind',
          },
          {
            title: 'Build a network',
            done: (count?.networks ?? 0) > 0,
            buttonLabel: 'Create network',
            description:
              'Networks allows you to group players into target groups so it gets easier to setup multiple campaigns to the same set of players. Create your first network.',
            linkTo: '/networks/create',
          },
        ]
      : []),
    {
      title: 'Upload your creatives',
      done: (count?.creatives ?? 0) > 0,
      buttonLabel: 'Upload creative',
      description:
        'Campaigns need a set of creative content so it can schedule its playback to the target networks and players. Upload some creatives.',
      linkTo: '/creatives',
    },
    {
      title: 'Create your first campaign',
      done: (count?.campaigns ?? 0) > 0,
      buttonLabel: 'Create campaign',
      description:
        'Campaigns allows you to decide where a set of content is going to get played at. Create your first campaign.',
      linkTo: '/campaigns/create',
    },
  ]

  return (
    <Container tw="flex flex-row justify-center">
      <div className="content" tw="w-6/12 mr-12">
        <h1 tw="mb-4 text-4xl font-light">
          Hi {authentication.user_data?.name?.first}! 👋
        </h1>
        <p tw="mb-16">
          Welcome to Diffuse.tv! This is your workspace overview, the place
          where you can find some general stats and recomendations.
        </p>
        <p tw="mb-2 font-medium">Give us content, we give you stats!</p>
        <p>
          We need you to set up your workspace to get full advantage from
          Diffuse.tv.
        </p>

        <div tw="flex justify-center my-16">
          <Illustration name={IllustrationEnum.onboarding_in_progress} />
        </div>
      </div>

      <div className="checklist" tw="w-3/12">
        <Card tw="w-full p-8">
          <h3 tw="text-xl font-medium mb-8">Set up your workspace</h3>
          <Accordion
            steps={onboardingSteps.map(
              ({ description, linkTo, buttonLabel, ...step }) => ({
                ...step,
                content: (
                  <Fragment>
                    <Text as="p" preset="p1" tw="my-4">
                      {description}
                    </Text>
                    <ButtonLink secondary to={linkTo} tw="block w-full">
                      {buttonLabel}
                    </ButtonLink>
                  </Fragment>
                ),
              })
            )}
          />
        </Card>
      </div>
    </Container>
  )
}

export default WorkspaceOnboardingPage
