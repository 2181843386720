import React, { FC } from 'react'

import 'twin.macro'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Icon from '../ui-blocks/icon'
import Input from '../ui-blocks/input'
import Button from '../ui-blocks/button'
import Spacer from '../ui-blocks/spacer'
import ModalBase from '../ui-blocks/modal-base'

import { alertsManager } from '../stores'
import { AlertVariant } from '../stores/alerts-manager'

export interface GetLinkModalProps {
  token: string
  open?: boolean
  onClose?: () => void
}

const GetLinkModal: FC<GetLinkModalProps> = ({ token, open, onClose }) => {
  const { href } = new URL(
    `/auth/networks/${encodeURIComponent(token)}`,
    window.location.origin
  )
  return (
    <ModalBase title="Get link" open={open} onBackdropClick={onClose}>
      <p tw="font-normal text-dark-blue-gray mb-10">
        Workspaces that have access to this link can add your network as a
        broadcast target.
      </p>
      <div tw="flex flex-row items-center">
        <Input readOnly truncate title={href} value={href} />
        <Spacer size="1rem" direction="horizontal" />
        <CopyToClipboard
          text={href}
          onCopy={(_, copied) => {
            alertsManager.emit({
              dismissable: true,
              id: 'copy-to-clipboard-alert',
              variant: copied ? AlertVariant.SUCCESS : AlertVariant.ERROR,
              message: copied
                ? 'Link copied successfully.'
                : 'Failed to copy link.',
            })

            // automatically close if it copied successfully
            if (copied && onClose) {
              onClose()
            }
          }}
        >
          <Button
            type="button"
            tw="flex-shrink-0"
            iconLeft={<Icon icon="clipboard" tw="text-base" />}
          >
            Copy link
          </Button>
        </CopyToClipboard>
      </div>
    </ModalBase>
  )
}

export default GetLinkModal
