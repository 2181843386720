import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from './icon'

export interface PillProps {
  text: string
  disabled?: boolean
  onDelete?: () => void
}

const Pill: FC<PillProps> = ({ text, onDelete, disabled }) => {
  const handleClose = () => {
    if (!disabled) onDelete?.()
  }

  return (
    <div
      css={[
        tw`flex flex-row items-center max-w-max bg-dark-blue-gray text-white rounded h-8 p-2`,
        !!disabled && tw`opacity-75 hover:cursor-not-allowed`,
      ]}
    >
      <span tw="select-none">{text}</span>
      {!!onDelete && (
        <Icon
          icon="close"
          onClick={handleClose}
          tw="text-base ml-1 right-0 hover:cursor-pointer"
        />
      )}
    </div>
  )
}

export default Pill
