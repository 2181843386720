import React, { FC, useState } from 'react'
import tw, { css } from 'twin.macro'

import { InputFormik } from '../../../../../../ui-blocks/input'
import Checkbox from '../../../../../../ui-blocks/checkbox'

import FieldsRow from './fields-row'

import { IFormikObject } from '../../../../../../typings'

import { PaymentMethodFormikValues } from '..'

export interface PaymentMethodFormProps {
  formik: IFormikObject<PaymentMethodFormikValues>
}

const PaymentMethodForm: FC<PaymentMethodFormProps> = ({ formik }) => {
  const [checked, setChecked] = useState(false)
  return (
    <>
      <div
        css={css`
          ${tw`max-w-xs`}
        `}
      >
        <FieldsRow>
          <InputFormik
            type="text"
            name="cardName"
            label="Card Name"
            className="billing-input"
            formik={formik}
          />
        </FieldsRow>
        <FieldsRow>
          <InputFormik
            type="text"
            name="cardNumber"
            label="Card Number"
            className="billing-input"
            maxLength={19}
            formik={formik}
          />
        </FieldsRow>
        <FieldsRow>
          <InputFormik
            type="text"
            name="expMonth"
            label="Month"
            placeholder="MM"
            className="billing-input"
            maxLength={2}
            formik={formik}
          />
          <InputFormik
            type="text"
            name="expYear"
            label="Year"
            placeholder="YY"
            className="billing-input"
            maxLength={2}
            formik={formik}
          />
          <InputFormik
            type="text"
            name="securityCode"
            label="CVC"
            placeholder="3-4 digit"
            pattern="^[0-9]{3,4}$"
            className="billing-input"
            maxLength={4}
            formik={formik}
          />
        </FieldsRow>
        <Checkbox
          name="isPrimary"
          label="Set as primary payment method"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      </div>
    </>
  )
}

export default PaymentMethodForm
