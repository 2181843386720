import React, { FC, useMemo } from 'react'
import 'twin.macro'

import {
  Informations,
  InformationsSection,
} from '../../../../ui-blocks/information'
import Status from '../../../../ui-blocks/status'
import KPICard from '../../../../ui-blocks/kpi-card'

import Address from '../../../../components/address'
import AreaType from '../../../../components/area-type'
import VenueTypes from '../../../../components/venue-types'
import InlineDate from '../../../../components/inline-date'

import {
  EnumPlayerStatus,
  PlayerFullFragment,
} from '../../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'

export interface OverviewSectionProps {
  player?: PlayerFullFragment
}

const OverviewSection: FC<OverviewSectionProps> = ({ player }) => {
  const intervals = useMemo(getKPIIntervals, [])
  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, player?.stats || []),
    [intervals, player]
  )

  return (
    <section>
      <Informations title="Player Information" tw="mb-14">
        <InformationsSection label="STATUS">
          <Status value={player?.status as EnumPlayerStatus} />
        </InformationsSection>
        <InformationsSection label="ADDRESS">
          <Address address={player?.geographics?.address || undefined} />
        </InformationsSection>
        <InformationsSection label="LAST UPDATE">
          <InlineDate date={player?.last_seen_at} />
        </InformationsSection>
        <InformationsSection label="AREA TYPE">
          <AreaType areaTypeId={player?.demographics?.area_type_id} />
        </InformationsSection>
        <InformationsSection label="VENUE TYPES">
          <VenueTypes
            venueTypeIds={player?.demographics?.venue_type_ids || []}
          />
        </InformationsSection>
      </Informations>
      <KPICard title="Player Analytics" intervals={stats} />
    </section>
  )
}

export default OverviewSection
