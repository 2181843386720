import React, { FC, useMemo } from 'react'
import 'twin.macro'

import { useNavigate, useParams } from 'react-router-dom'

import WebhookForm, { WebhookFormikValues } from '../components/webhook-form'

import {
  useViewWebhookQuery,
  useUpdateWebhookByIdMutation,
  EnumWebhookEvent_Types,
} from '../../../../../graphql/components'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

import { trimValues } from '../../../../../utils/data-manipulation'
import LoadingPage from '../../../../../components/loading-page'
import Suspense from '../../../../../ui-blocks/suspense'

const WorkspaceSettingsEditWebhookPage: FC = () => {
  const navigate = useNavigate()
  const { webhook_id, workspace_id } = useParams()

  const [
    updateWebhook,
    { loading: updatingWebhook },
  ] = useUpdateWebhookByIdMutation()
  const { data: webhookData, loading: loadingWebhook } = useViewWebhookQuery({
    variables: { filter: { _id: webhook_id } },
  })

  const onSubmit = async (values: WebhookFormikValues) => {
    try {
      const res = await updateWebhook({
        variables: {
          _id: webhook_id,
          input: trimValues(values, ['name', 'type', 'url', 'event_types']),
        },
      })
      if ((res.errors || []).length > 0) return handleError()
      navigate(`/workspaces/${workspace_id}/settings/webhooks`, {
        state: { refetch: true },
      })
    } catch {
      handleError()
    }
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-webhook-error-alert',
      message: "We couldn't update this webhook. Please try again later.",
    })
  }

  const webhook = useMemo(() => {
    const wh = webhookData?.webhook
    if (!wh) return undefined

    const event_types: EnumWebhookEvent_Types[] = []

    if (wh?.event_types) {
      for (const eventType of wh.event_types) {
        if (eventType) event_types.push(eventType)
      }
    }

    return {
      name: wh?.name,
      type: wh?.type || undefined,
      url: wh?.url,
      event_types,
    }
  }, [webhookData?.webhook])

  return (
    <section>
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Edit webhook</h2>
      <p tw="font-normal text-xs text-dark-blue-gray leading-tight mb-6">
        Edit an existing webhook.
      </p>

      <Suspense ready={!!webhook} fallback={<LoadingPage />}>
        <WebhookForm
          onSubmit={onSubmit}
          disabled={loadingWebhook}
          submitting={updatingWebhook}
          initialValues={webhook}
        />
      </Suspense>
    </section>
  )
}

export default WorkspaceSettingsEditWebhookPage
