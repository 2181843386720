import React, { FC, useMemo } from 'react'

import 'twin.macro'
import { CSSObject } from '@emotion/react'
import Select, {
  NamedProps,
  OptionProps,
  ControlProps,
  ContainerProps,
} from 'react-select'

import Text from './text'

import ErrorMessage from '../components/error-message'

const customStyles = {
  container: (
    provided: CSSObject,
    state: ContainerProps<any, false>
  ): CSSObject => ({
    ...provided,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '1rem',
    fontStyle: 'normal',
    fontFamily: 'Fira Sans',
    backgroundColor: state.selectProps.ghost ? 'transparent' : 'white',
    '::placeholder': {
      color: '#657595',
    },
    '&:hover': {
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    },
  }),
  control: (
    provided: CSSObject,
    state: ControlProps<any, false>
  ): CSSObject => {
    let borderColor = '#e7e8eb'
    let hoverBorderColor = '#bbc2d0'

    if (state.selectProps.ghost) {
      borderColor = 'transparent'
      hoverBorderColor = 'transparent'
    } else if (state.menuIsOpen) {
      borderColor = '#572779'
      hoverBorderColor = '#572779'
    }

    if (state.selectProps.success) {
      borderColor = '#008592'
      hoverBorderColor = '#008592'
    } else if (state.selectProps.error) {
      borderColor = '#c83e4d'
      hoverBorderColor = '#c83e4d'
    }

    return {
      ...provided,
      borderColor,
      boxShadow: undefined,
      borderRadius: '0.25rem',
      backgroundColor: state.selectProps.ghost
        ? 'transparent'
        : provided.backgroundColor,
      '&:hover': {
        borderColor: hoverBorderColor,
      },
    }
  },
  input: (provided: CSSObject): CSSObject => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  valueContainer: (provided: CSSObject): CSSObject => ({
    ...provided,
    padding: '0.5rem 1rem',
  }),
  singleValue: (provided: CSSObject): CSSObject => ({
    ...provided,
    color: '#2e384d',
  }),
  menu: (provided: CSSObject): CSSObject => ({
    ...provided,
    marginTop: '0.125rem',
    borderColor: '#e7e8eb',
    borderRadius: '0.25rem',
  }),
  menuList: (provided: CSSObject): CSSObject => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: CSSObject, state: OptionProps<any, false>): CSSObject => ({
    ...provided,
    color: '#2e384d',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#f2f0f3' : 'transparent',
    '&:active': {
      backgroundColor: '#f2f0f3',
    },
  }),
  indicatorSeparator: (): CSSObject => ({
    display: 'none',
  }),
}

export interface DropdownProps extends NamedProps {
  label?: string
  error?: string
  required?: boolean
  ghost?: boolean
  success?: boolean
}

const Dropdown: FC<DropdownProps> = ({
  id,
  label,
  error,
  required,
  ...props
}) => {
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )

  return (
    <div tw="w-full">
      {label && (
        <Text as="label" preset="caption" tw="pb-2" htmlFor={inputId}>
          {label} {!!required && '*'}
        </Text>
      )}
      <Select
        inputId={inputId}
        error={error}
        styles={customStyles}
        {...props}
      />
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

Dropdown.defaultProps = {
  isClearable: true,
}

export default Dropdown
