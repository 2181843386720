import React, { FC, useEffect, useMemo, useState } from 'react'

import 'react-map-gl'
import tw from 'twin.macro'

import _ from 'lodash'
import moment from 'moment'

import Text from '../../../ui-blocks/text'
import Card from '../../../ui-blocks/card'
import Spacer from '../../../ui-blocks/spacer'
import Spinner from '../../../ui-blocks/spinner'
import SideScroll from '../../../ui-blocks/side-scroll'
import ViewControls from '../../../ui-blocks/view-controls'
import { DateRangePicker } from '../../../ui-blocks/date-picker'

import ViewTab from './components/view-tab'
import ChartView from './components/chart-view'
import HeatmapView from './components/heatmap-view'
import AdGroupTotal from './components/ad-group-total'

import {
  CampaignFullFragment,
  ScansGeoLocationInterval,
  CampaignAdGroupFullFragment,
  useViewCampaignAnalyticsQuery,
} from '../../../graphql/components'

import { ReactComponent as ScansGrayIcon } from '../../../assets/icons/scans/gray.svg'
import { ReactComponent as ScansPurpleIcon } from '../../../assets/icons/scans/purple.svg'
import { ReactComponent as ImpressionsGrayIcon } from '../../../assets/icons/impressions/gray.svg'
import { ReactComponent as ImpressionsPurpleIcon } from '../../../assets/icons/impressions/purple.svg'

import { END_OF_DAY } from '../../../utils/stats-intervals'
import { generateRandomColor } from '../../../utils/data-manipulation'

export type DataColors = Record<
  string,
  {
    hex: string
    name: string
  }
>

export interface CampaignAnalyticsProps {
  campaign: CampaignFullFragment
}

export type ViewType = 'impressions' | 'scans'
const CampaignAnalytics: FC<CampaignAnalyticsProps> = ({
  campaign,
  ...props
}) => {
  const [currentView, setCurrentView] = useState<string>('chart')
  const [currentTab, setCurrentTab] = useState<ViewType>('impressions')

  const creationDate = useMemo(() => moment(campaign.created?.at), [campaign])
  const [interval, setActiveInterval] = useState<ScansGeoLocationInterval>({})

  const {
    data: analyticsData,
    loading: loadingAnalytics,
    refetch: refetchAnalytics,
  } = useViewCampaignAnalyticsQuery({
    skip: !campaign._id,
    fetchPolicy: 'no-cache',
    variables: {
      _id: campaign._id,
      interval: {
        starts_at: interval.starts_at || creationDate,
        ends_at: interval.ends_at || END_OF_DAY,
      },
    },
  })

  useEffect(() => {
    if (!!analyticsData && !interval.starts_at && !interval.ends_at)
      refetchAnalytics()
  }, [interval.starts_at, interval.ends_at])

  const analytics = useMemo(() => analyticsData?.analytics || [], [
    analyticsData,
  ])
  const sortedAnalytics = useMemo(() => _.sortBy(analytics, ['date']), [
    analytics,
  ])

  const adGroups = useMemo(() => campaign?.ad_groups || [], [campaign])
  const colors = useMemo(() => {
    const clrs: Record<string, { name: string; hex: string }> = {}
    const hexs = []

    for (const analyticsDatum of analytics) {
      const adGroupId = analyticsDatum?._id

      if (!adGroupId || !analyticsDatum?.name || clrs[adGroupId]) {
        continue
      }

      const color: { name: string; hex: string } = {
        name: analyticsDatum.name,
        hex: generateRandomColor(hexs),
      }

      hexs.push(color.hex)
      clrs[adGroupId] = color
    }

    for (const adGroup of adGroups) {
      const adGroupId = adGroup?._id

      if (!adGroupId || !adGroupId?.name || clrs[adGroupId]) {
        continue
      }

      const color: { name: string; hex: string } = {
        name: adGroup.name,
        hex: generateRandomColor(hexs),
      }

      hexs.push(color.hex)
      clrs[adGroupId] = color
    }

    return clrs
  }, [adGroups, analytics])

  const totalImpressions = useMemo(
    () =>
      _.sum(
        analytics.map((analytic) =>
          _.sum(
            analytic?.locations?.map((location) => location?.impressions ?? 0)
          )
        )
      ),
    [analytics]
  )
  const totalScans = useMemo(
    () =>
      _.sum(
        analytics.map((analytic) =>
          _.sum(analytic?.locations?.map((location) => location?.scans ?? 0))
        )
      ),
    [analytics]
  )

  const analyticsTabs = [
    {
      key: 'impressions',
      label: 'Impressions',
      value: totalImpressions,
      activeIcon: ImpressionsPurpleIcon,
      inactiveIcon: ImpressionsGrayIcon,
    },
    {
      key: 'scans',
      label: 'Scans',
      value: totalScans,
      activeIcon: ScansPurpleIcon,
      inactiveIcon: ScansGrayIcon,
    },
  ]

  const renderCurrentView = (view: string) =>
    view === currentView ? tw`block` : tw`invisible h-0 overflow-hidden`

  const calcAdGroupTotal = (adGroup: CampaignAdGroupFullFragment) => {
    const relatedAnalytics = analytics.filter(
      (analytic) => analytic?._id === adGroup._id
    )
    return _.sum(
      relatedAnalytics.map((analytic) =>
        _.sum(analytic?.locations?.map((location) => location?.[currentTab]))
      )
    )
  }

  return (
    <Card tw="relative" {...props}>
      {loadingAnalytics && (
        <div tw="flex items-center justify-center w-full h-full bg-white-60 rounded absolute z-50">
          <Spinner />
        </div>
      )}
      <Text
        as="h3"
        preset="p1"
        transform="uppercase"
        tw="text-dark-blue-gray tracking-wider mx-8 mt-8 mb-6"
      >
        Campaign Analytics
      </Text>
      <div tw="flex flex-row items-center mb-10">
        {analyticsTabs.map((tab, index) => (
          <ViewTab
            key={tab.key}
            value={tab.value}
            label={tab.label}
            isSelected={currentTab === tab.key}
            maxWidth={100 / analyticsTabs.length}
            isLast={index === analyticsTabs.length - 1}
            icon={currentTab === tab.key ? tab.activeIcon : tab.inactiveIcon}
            onClick={() => setCurrentTab(tab.key as ViewType)}
          />
        ))}
      </div>
      <div tw="flex flex-row items-center justify-end mx-8 mb-4">
        {!!interval.starts_at && !!interval.ends_at && (
          <Text
            as="p"
            preset="p1"
            tw="flex flex-row items-center text-dark-blue-gray mr-6"
          >
            {`${
              analyticsTabs.find((tab) => tab.key === currentTab)?.label
            } recorded at`}
            <Text as="span" preset="p1" tw="ml-1">
              {`${moment(interval.starts_at).format('ll')} to ${moment(
                interval.ends_at
              ).format('ll')}`}
            </Text>
          </Text>
        )}
        <DateRangePicker
          startDate={interval.starts_at}
          endDate={interval.ends_at}
          minDate={creationDate}
          maxDate={END_OF_DAY}
          onChange={(start, end) =>
            setActiveInterval({
              starts_at: start,
              ends_at: end,
            })
          }
        />
        <Spacer size="3rem" direction="horizontal" />
        <ViewControls
          value={currentView}
          onSelect={setCurrentView}
          options={[
            { id: 'chart', label: 'Lines View' },
            { id: 'heatmap', label: 'Locations View' },
          ]}
        />
      </div>
      <SideScroll tw="px-8 mb-4">
        <Text as="p" preset="p1" tw="text-dark-blue-gray inline-block mr-4">
          Ad Groups
        </Text>
        <AdGroupTotal
          title="All"
          count={
            (currentTab === 'impressions' && totalImpressions) ||
            (currentTab === 'scans' && totalScans) ||
            0
          }
        />
        {adGroups.map((adGroup) => (
          <AdGroupTotal
            title={adGroup.name}
            color={colors[adGroup._id]?.hex || '#000'}
            count={calcAdGroupTotal(adGroup)}
            key={`analytic-${adGroup._id}`}
          />
        ))}
      </SideScroll>
      <div css={renderCurrentView('chart')}>
        <ChartView
          colors={colors}
          yAxis={currentTab}
          analytics={sortedAnalytics}
        />
      </div>
      <div css={renderCurrentView('heatmap')}>
        <HeatmapView
          colors={colors}
          weight={currentTab}
          adGroups={adGroups}
          analytics={sortedAnalytics}
        />
      </div>
    </Card>
  )
}

export default CampaignAnalytics
