import React, { FC } from 'react'

import ModalAction from '../../../../../ui-blocks/modal-action'

import {
  useRevokeWorkspaceInvitesMutation,
  ListInvitesDocument,
} from '../../../../../graphql/components'

import { MutationResult } from '../../../../../typings'

export interface CancelInviteModalProps {
  open: boolean
  inviteId: string
  memberEmail: string
  onClose: (result: MutationResult) => void
}

const CancelInviteModal: FC<CancelInviteModalProps> = ({
  open,
  onClose,
  inviteId,
  memberEmail,
}) => {
  const [
    revokeWorkspaceInvite,
    { loading },
  ] = useRevokeWorkspaceInvitesMutation({
    awaitRefetchQueries: true,
    variables: { ids: [inviteId] },
    refetchQueries: [{ query: ListInvitesDocument }],
  })

  const revokeInvite = async () => {
    const res = await revokeWorkspaceInvite()
    onClose(
      res.data?.cancelledInvite &&
        res.data?.cancelledInvite.length > 0 &&
        !!res.data?.cancelledInvite.filter(
          (invite) => invite._id == inviteId && invite.status == 'REVOKED'
        )
        ? 'success'
        : 'error'
    )
  }

  return (
    <ModalAction
      open={open}
      loading={loading}
      title="Cancel Invite?"
      cancelButtonText="Keep member"
      confirmButtonText="Cancel Invite"
      onCancel={() => onClose(null)}
      onConfirm={revokeInvite}
    >
      <p>
        Are you sure you want to cancel invite sent to <b>{memberEmail}</b>?
        This person will no longer be able to join the workspace unless invited
        again
      </p>
    </ModalAction>
  )
}

export default CancelInviteModal
