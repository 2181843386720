import React, { FC, Fragment, useMemo } from 'react'
import 'twin.macro'

import Search from '../../ui-blocks/search'
import Spinner from '../../ui-blocks/spinner'
import Suspense from '../../ui-blocks/suspense'
import ModalAction from '../../ui-blocks/modal-action'
import PaginationNav from '../../ui-blocks/pagination-nav'
import Illustration, { IllustrationEnum } from '../../ui-blocks/illustration'

import Grid from '../grid'
import LoadingPage from '../loading-page'
import PlayerSelectCard from '../player/select-card'

import {
  useCountPlayersQuery,
  useListPlayersQuery,
} from '../../graphql/components'
import { IFormikObject } from '../../typings'

import usePagination from '../../utils/use-pagination'
import { useSearchTerms } from '../../utils/use-search'

export interface ManagePlayersModalProps {
  open?: boolean
  loading?: boolean
  knownPlayerIds?: string[]
  formik: IFormikObject<any>
  onClose?: () => void
}

const ManagePlayersModal: FC<ManagePlayersModalProps> = ({
  open,
  loading,
  knownPlayerIds,
  formik,
  onClose,
}) => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: playerCountData,
    loading: loadingPlayersCount,
  } = useCountPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const playersCount = useMemo(() => playerCountData?.players || 0, [
    playerCountData,
  ])

  const pagination = usePagination(playersCount, 9, searchTextDebounced)

  const { data: playersData, loading: loadingPlayers } = useListPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const players = useMemo(() => playersData?.players || [], [playersData])
  const renderPlayers = () => (
    <Fragment>
      <Grid rows={3} cols={3} gap="1rem">
        {players.map((player) => (
          <PlayerSelectCard
            name="players_ids"
            player={player}
            formik={formik}
            value={player?._id}
            key={`player-${player._id}`}
            preChecked={knownPlayerIds?.includes(player?._id)}
          />
        ))}
      </Grid>
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </Fragment>
  )

  const renderNoPlayers = () => (
    <div tw="flex flex-col items-center pb-4">
      <Illustration name={IllustrationEnum.players_empty_results} />
      <p tw="font-medium text-charcoal leading-tight mt-6 mb-2">
        No data to show
      </p>
      <span tw="font-normal text-charcoal leading-tight break-words">
        {!!searchTextDebounced
          ? `We can’t find any player for "${searchTextDebounced}". Please check your spelling and try again.`
          : 'We can’t find any player.'}
      </span>
    </div>
  )

  return (
    <ModalAction
      open={open}
      loading={formik.isSubmitting}
      confirmDisabled={!formik.dirty}
      onConfirm={formik.submitForm}
      onBackdropClick={onClose}
      onCancel={onClose}
      confirmButtonText="Accept changes"
      width="68rem"
    >
      <Suspense ready={!loading} fallback={<LoadingPage />}>
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-2">
            Manage players
          </h1>
          <p tw="font-normal text-dark-blue-gray leading-tight mb-10">
            Add or remove players.
          </p>
          <div tw="flex flex-row items-center justify-between mb-6">
            <span tw="font-normal text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
              {`${playersCount} Player(s), ${formik.values.players_ids.length} Selected`}
            </span>
            <div tw="w-full max-w-xs">
              <Search
                value={searchText}
                loading={loadingPlayers && loadingPlayersCount}
                // entries={players.map((player) => player.name || '')}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          </div>
          <div tw="mb-6">
            <Suspense
              ready={!loadingPlayers && !loadingPlayersCount}
              fallback={<Spinner center />}
            >
              <Suspense ready={players.length > 0} fallback={renderNoPlayers()}>
                {renderPlayers()}
              </Suspense>
            </Suspense>
          </div>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default ManagePlayersModal
