import React, { FC, Fragment, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useLocation, useParams } from 'react-router-dom'

import Icon from '../../../../ui-blocks/icon'
import Text from '../../../../ui-blocks/text'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import ButtonLink from '../../../../ui-blocks/button-link'

import WebhookRow from './components/webhook-row'

import { useListWebhooksQuery } from '../../../../graphql/components'

const WorkspaceSettingsWebhooksPage: FC = () => {
  const { state }: any = useLocation()
  const { workspace_id } = useParams()

  const {
    data: webhooksData,
    loading: loadingWebhooks,
    refetch: refetchWebhooks,
  } = useListWebhooksQuery({
    fetchPolicy: 'cache-first',
  })
  useEffect(() => {
    if (state && state.refetch) refetchWebhooks()
  }, [state])

  const webhooks = useMemo(
    () =>
      (webhooksData?.webhooks || []).filter(
        (webhook) => !webhook?.archived?.at
      ),
    [webhooksData]
  )

  return (
    <Fragment>
      <Text as="h2" preset="h4" tw="mb-2">
        Manage webhooks
      </Text>
      <Text as="h3" preset="p2" tw="mb-6">
        Manage webhooks that belong to this workspace.
      </Text>
      <div tw="flex flex-row items-center justify-between mb-4">
        <span tw="font-normal text-dark-blue-gray leading-tight">
          {`${webhooks.length} Webhook(s)`}
        </span>
        <ButtonLink
          className="add-webhook-btn"
          iconLeft={<Icon icon="add" tw="text-base" />}
          to={`/workspaces/${workspace_id}/settings/webhooks/add`}
        >
          Add webhook
        </ButtonLink>
      </div>
      <Suspense ready={!loadingWebhooks} fallback={<Spinner center />}>
        <div className="webhooks-list">
          {webhooks.map((webhook, index) => (
            <WebhookRow
              key={`webhook-${webhook._id}`}
              webhook={webhook}
              isFirst={index === 0}
              refetchData={refetchWebhooks}
            />
          ))}
        </div>
      </Suspense>
    </Fragment>
  )
}

export default WorkspaceSettingsWebhooksPage
