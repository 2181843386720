import { authentication } from './authentication'
import { uploadsManager } from './uploads-manager'
import { alertsManager } from './alerts-manager'

// @ts-ignore
window.stores = {
  authentication,
  uploadsManager,
  alertsManager,
}
export { authentication, uploadsManager, alertsManager }
