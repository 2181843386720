import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import Text from '../../../ui-blocks/text'
import Link from '../../../ui-blocks/link'
import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'

import PasswordRestrictions from '../../../components/password-restrictions'
import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import { useAccountChangePasswordMutation } from '../../../graphql/components'

import { authentication } from '../../../stores'
import { MutationResult } from '../../../typings'

import { passwordSchema } from '../../auth/sign-up'

interface ChangePasswordFormikValues {
  newPassword: string
  currentPassword: string
  confirmPassword: string
}

const changePasswordValidationSchema = object().shape({
  currentPassword: string().required('Current password is required'),
  newPassword: passwordSchema.clone().required('New password is required'),
  confirmPassword: passwordSchema
    .clone()
    .required('Confirm password is required'),
})

const AccountSecurity: FC = () => {
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const [changePassword] = useAccountChangePasswordMutation()
  const formik = useFormik<ChangePasswordFormikValues>({
    validateOnChange: false,
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      newPassword: '',
      currentPassword: '',
      confirmPassword: '',
    },
    async onSubmit({ newPassword, currentPassword, confirmPassword }) {
      if (!authentication.refresh_token) return
      setMutationResult(null)

      if (newPassword === currentPassword) {
        setMutationResult('error')
        setAlertMessage('New password can’t be the same as current password.')
        return
      }
      if (newPassword !== confirmPassword) {
        setMutationResult('error')
        setAlertMessage('Password confirmation doesn’t match new password.')
        return
      }

      try {
        const res = await changePassword({
          variables: {
            newPassword,
            currentPassword,
            refreshToken: authentication.refresh_token,
          },
        })

        if (!res.data?.isUpdated) {
          setMutationResult('error')
          setAlertMessage(
            'An error occurred while changing your password. Please try again.'
          )
          return
        }

        setMutationResult('success')
        setAlertMessage('Password successfully updated.')
      } catch (e) {
        setMutationResult('error')
        if (
          String(e).includes(
            'the provided password does not match the current one'
          )
        ) {
          return setAlertMessage(
            'The provided current password does not match your actual current password. Please check if you typed the password correctly.'
          )
        }
        setAlertMessage(
          'An error occurred while changing your password. Please try again later.'
        )
      }
    },
  })

  return (
    <div tw="max-w-sm">
      {mutationResult && alertMessage && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              message: alertMessage,
              id: 'change-password-alert',
              variant:
                mutationResult === 'success'
                  ? AlertVariant.SUCCESS
                  : AlertVariant.ERROR,
            }}
          />
        </div>
      )}
      <Text as="h2" preset="h4" tw="mb-2">
        Change password
      </Text>
      <Text as="h3" preset="p2" tw="mb-6">
        After a successful password update, you will be redirected to the login
        page where you can log in with your new password.
      </Text>
      <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <div tw="max-w-xs space-y-6 mb-4">
          <InputFormik
            type="password"
            name="currentPassword"
            label="Current Password"
            placeholder="*************"
            formik={formik}
          />
          <InputFormik
            type="password"
            name="newPassword"
            label="New Password"
            placeholder="*************"
            formik={formik}
          />
          <InputFormik
            type="password"
            name="confirmPassword"
            label="Password Confirmation"
            placeholder="*************"
            formik={formik}
          />
        </div>
        <PasswordRestrictions tw="mb-6" />
        <div tw="flex flex-row items-center space-x-2">
          <Button
            type="submit"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
          >
            Update password
          </Button>
          <Link router={{ to: '/auth/forgot-password' }}>
            Forgot my password
          </Link>
        </div>
      </form>
    </div>
  )
}

export default AccountSecurity
