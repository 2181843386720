import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from '../ui-blocks/text'

const PasswordRestrictions: FC = (props) => (
  <div tw="w-full" {...props}>
    <Text as="span" preset="p2" tw="block mb-1">
      Password restrictions:
    </Text>
    <ul
      css={css`
        ${tw`list-disc pl-6`}
        & > li::marker {
          ${tw`text-dark-blue-gray`}
        }
      `}
    >
      <li>
        <Text as="span" preset="p2">
          Your password must have at least 6 characters
        </Text>
      </li>
      <li>
        <Text as="span" preset="p2">
          Your password must have at least one upper case character
        </Text>
      </li>
      <li>
        <Text as="span" preset="p2">
          Your password must have at least one lower case character
        </Text>
      </li>
      <li>
        <Text as="span" preset="p2">
          Your password must have at least one number
        </Text>
      </li>
    </ul>
  </div>
)

export default PasswordRestrictions
