import React, { FC, useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import tw from 'twin.macro'
import { FormikValues } from 'formik'
import { useNavigate } from 'react-router'

import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'

import SetupForm from '../../../components/setup-form'

import PlayerIntroStep, { PlayerIntroDescription } from './steps/1-intro'
import BindPlayerStep, {
  bindPlayerValidationSchema,
} from './steps/2-binding-code'
import PlayerInformationStep, {
  editPlayerValidationSchema,
} from './steps/3-save-player-data'

import {
  EnumPointType,
  useViewPlayerQuery,
  useBindPlayerMutation,
  useUpdatePlayerByIdMutation,
  EnumPlayerSettingsOrientation,
  useCountNetworksQuery,
  useListNetworksQuery,
  useAddPlayerToNetworksMutation,
  useFinishPlayerSetupMutation,
} from '../../../graphql/components'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

import { cleanGraphqlTypenames } from '../../../utils/data-manipulation'
import PlayerLocationStep, {
  playerLocationValidationSchema,
} from './steps/4-geolocation'
import PlayerSettingsStep, {
  playerSettingsValidationSchema,
} from './steps/5-settings'
import usePagination from '../../../utils/use-pagination'
import TargetNetworksStep, {
  targetNetworksValidationSchema,
} from './steps/6-target-networks-step'
import { captureException } from '@sentry/react'

export interface BindPlayerFormikValues {
  id: string
  code: string
  name: string
  settings: {
    debug: boolean
    volume: number
    brightness: number
    orientation: EnumPlayerSettingsOrientation
    storage_medium_id: string
  }
  geographics: {
    location: {
      type: EnumPointType
      coordinates: number[]
    }
    address: {
      street: string
      number: string
      city: string
      state: string
      zip: string
      country_id: string
    }
  }
  demographics: {
    country_id: string
    area_type_id: string
    language_ids: string[]
    venue_type_ids: string[]
  }
  specs?: any
  networks_ids: string[]
}

const BindPlayerPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const viewPlayer = useViewPlayerQuery()

  const [bindPlayer] = useBindPlayerMutation()
  const [updatePlayer] = useUpdatePlayerByIdMutation()
  const [addPlayerToNetworks] = useAddPlayerToNetworksMutation()
  const [finishPlayerSetup] = useFinishPlayerSetupMutation()

  const [initialValues, setInitialValues] = useState<FormikValues>({
    id: '',
    code: '',
    networks_ids: [],
  })

  const urlCode = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('code') ?? ''
  }, [location.search])
  const urlPlayerId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('_id') ?? ''
  }, [location.search])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const {
    data: networkCountData,
    loading: loadingNetworkCountData,
  } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchQuery },
    },
  })

  const networksCount = useMemo(() => networkCountData?.networks || 0, [
    networkCountData,
  ])
  const networksPagination = usePagination(networksCount, 9, searchQuery)

  const { data: networksData, loading: loadingNetworks } = useListNetworksQuery(
    {
      fetchPolicy: 'cache-first',
      variables: {
        limit: networksPagination.limit,
        offset: networksPagination.offset,
        filter: { search: searchQuery },
      },
    }
  )

  const networks = useMemo(() => networksData?.networks || [], [networksData])

  const handleError = (err?: Error) => {
    if (err) {
      captureException(err)
    }

    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'bind-player-error-alert',
      message: "We couldn't bind the player. Please try again later.",
    })
  }

  useEffect(() => {
    if (!urlPlayerId) return
    if (initialValues.id) return
    ;(async () => {
      const queryRes = await viewPlayer.refetch({
        _id: urlPlayerId,
      })

      const playerData = queryRes.data.player

      setInitialValues({
        ...initialValues,
        id: playerData?._id,
        name: playerData?.name,
        settings: cleanGraphqlTypenames(playerData?.settings),
        geographics: cleanGraphqlTypenames(playerData?.geographics),
        demographics: cleanGraphqlTypenames(playerData?.demographics),
        specs: cleanGraphqlTypenames(playerData?.specs),
        networks_ids: [],
      })
    })()
  }, [urlPlayerId])

  const handleBindSubmit = useCallback(
    async (values: BindPlayerFormikValues) => {
      try {
        const bindRes = await bindPlayer({ variables: { token: values.code } })
        if (!bindRes.data?.player?._id) {
          handleError(new Error('no player id'))
          return false
        }

        const queryRes = await viewPlayer.refetch({
          _id: bindRes.data.player._id,
        })

        const playerData = queryRes.data.player

        setInitialValues({
          ...initialValues,
          id: playerData?._id,
          name: playerData?.name,
          settings: cleanGraphqlTypenames(playerData?.settings),
          geographics: cleanGraphqlTypenames(playerData?.geographics),
          demographics: cleanGraphqlTypenames(playerData?.demographics),
          specs: cleanGraphqlTypenames(playerData?.specs),
          networks_ids: [],
        })

        return true
      } catch (err) {
        handleError(err as Error)
        return false
      }
    },
    []
  )

  const handleEditSubmit = async (values: BindPlayerFormikValues) => {
    try {
      const res = await updatePlayer({
        variables: {
          _id: values.id,
          input: {
            name: values.name,
            settings: values.settings,
            geographics: values.geographics,
            demographics: values.demographics,
          },
        },
      })

      if ((res.errors || []).length > 0) {
        handleError(new Error(res.errors?.join()))
        return false
      }
      return true
    } catch (err) {
      handleError(err as Error)
      return false
    }
  }

  const handleFinishSetupSubmit = async (values: BindPlayerFormikValues) => {
    try {
      {
        const res = await addPlayerToNetworks({
          variables: {
            player_id: values?.id,
            network_ids: values?.networks_ids,
          },
        })

        if (res.errors) {
          handleError(new Error(res.errors?.join()))
          return false
        }

        if (
          (
            (Array.isArray(res.data?.wasAddedByNetwork) &&
              res.data?.wasAddedByNetwork) ||
            []
          )
            .join('')
            .match(/false/)
        ) {
          handleError(new Error('unable to join to some networks'))
          return false
        }
      }

      {
        const res = await finishPlayerSetup({
          variables: {
            _id: values?.id,
          },
        })

        if (res.errors || !res.data?.activated) {
          handleError(new Error('not activated after finish'))
          return false
        }
      }

      document.dispatchEvent(new CustomEvent('refetch-players'))
      navigate(`/players/${values?.id}`)

      return true
    } catch (err) {
      handleError(err as Error)
      return false
    }
  }

  return (
    <Container>
      <div css={[tw`w-full self-center`, 'max-width: 68rem;']}>
        <Suspense
          ready={!loadingNetworkCountData && !loadingNetworks}
          fallback={<Spinner center />}
        >
          <SetupForm
            title="Bind Player"
            description="This process binds a freshly installed player to your workspace."
            onSubmit={{
              1: handleBindSubmit,
              2: handleEditSubmit,
              3: handleEditSubmit,
              4: handleEditSubmit,
              5: handleFinishSetupSubmit,
            }}
            initialStepIndex={(!!urlCode && 1) || (!!urlPlayerId && 2) || 0}
            initialValues={initialValues}
            steps={[
              {
                canGoBack: true,
                title: 'Setup your Player',
                content: <PlayerIntroStep />,
                description: <PlayerIntroDescription />,
              },
              {
                canGoBack: true,
                title: 'Connect your Player',
                content: <BindPlayerStep urlCode={urlCode} />,
                validationSchema: bindPlayerValidationSchema,
              },
              {
                canGoBack: false,
                title: 'Player info',
                content: <PlayerInformationStep />,
                validationSchema: editPlayerValidationSchema,
              },
              {
                canGoBack: true,
                title: 'Location',
                content: <PlayerLocationStep />,
                validationSchema: playerLocationValidationSchema,
              },
              {
                canGoBack: true,
                title: 'Settings',
                content: <PlayerSettingsStep />,
                validationSchema: playerSettingsValidationSchema,
              },
              {
                canGoBack: true,
                title: 'Networks',
                content: (
                  <TargetNetworksStep
                    loadingCount={loadingNetworkCountData}
                    loadingNetworks={loadingNetworks}
                    networksCount={networksCount}
                    networks={networks}
                    pagination={networksPagination}
                    onSearch={setSearchQuery}
                  />
                ),
                validationSchema: targetNetworksValidationSchema,
              },
            ]}
          />
        </Suspense>
      </div>
    </Container>
  )
}

export default BindPlayerPage
