import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../../../../ui-blocks/icon'

export interface RevokeAccessActionProps {
  onClick?: () => void
}

const RevokeAccessAction: FC<RevokeAccessActionProps> = ({ onClick }) => (
  <div
    tw="flex flex-row items-center text-dark-blue-gray transition duration-200 space-x-2 hover:(text-purple cursor-pointer)"
    onClick={onClick}
  >
    <Icon icon="circle-remove" tw="text-lg" />
    <span>Revoke Access</span>
  </div>
)

export default RevokeAccessAction
