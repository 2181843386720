import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Icon from '../../../../ui-blocks/icon'
import Button from '../../../../ui-blocks/button'

import useElementStates from '../../../../utils/use-element-states'

interface ButtonProps {
  label?: string
  primary?: boolean
  secondary?: boolean
  menu?: boolean
  danger?: boolean
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  dropdown?: React.ReactNode
}

const addIcon = <Icon icon="add" tw="text-lg" />
const buttons: ButtonProps[][] = [
  [
    { primary: true, label: 'Primary Button' },
    { primary: true, iconLeft: addIcon, label: 'Primary Button' },
    { primary: true, iconRight: addIcon, label: 'Primary Button' },
    {
      primary: true,
      iconLeft: addIcon,
      label: 'Primary Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    {
      primary: true,
      iconRight: addIcon,
      label: 'Primary Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    { primary: true, iconLeft: addIcon },
  ],
  [
    { secondary: true, label: 'Secondary Button' },
    { secondary: true, iconLeft: addIcon, label: 'Secondary Button' },
    { secondary: true, iconRight: addIcon, label: 'Secondary Button' },
    {
      secondary: true,
      iconLeft: addIcon,
      label: 'Secondary Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    {
      secondary: true,
      iconRight: addIcon,
      label: 'Secondary Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    { secondary: true, iconLeft: addIcon },
  ],
  [
    { danger: true, label: 'Danger Button' },
    { danger: true, iconLeft: addIcon, label: 'Danger Button' },
    { danger: true, iconRight: addIcon, label: 'Danger Button' },
    {
      danger: true,
      iconLeft: addIcon,
      label: 'Danger Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    {
      danger: true,
      iconRight: addIcon,
      label: 'Danger Button w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    { danger: true, iconLeft: addIcon },
  ],
  [
    { menu: true, label: 'Menu Button' },
    { menu: true, iconLeft: addIcon, label: 'Menu Button' },
    { menu: true, iconRight: addIcon, label: 'Menu Button' },
    {
      menu: true,
      iconLeft: addIcon,
      label: 'Menu Button  w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    {
      menu: true,
      iconRight: addIcon,
      label: 'Menu Button  w/ dropdown',
      dropdown: <span>Dropdown content</span>,
    },
    { menu: true, iconLeft: addIcon },
  ],
]

const ButtonSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const { states, values } = useElementStates(['loading', 'disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="space-y-4">
        {buttons.map((buttonRow, i) => (
          <div
            key={`button-row#${i}`}
            tw="flex flex-row flex-wrap justify-between gap-4"
          >
            {buttonRow.map(({ label, ...rest }, j) => (
              <Button key={`button#${i}${j}`} {...values} {...rest}>
                {label}
              </Button>
            ))}
          </div>
        ))}
      </div>
    </ElementSection>
  )
}

export default ButtonSection
