import React, { FC, useMemo, useEffect } from 'react'

import tw from 'twin.macro'
import { useParams } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import PageHeader from '../../../ui-blocks/page-header'

// import { Invoice } from '../settings/billing/sections/invoices'
import WorkspaceOnboardingPage, { useOnboardingPage } from './onboarding'

import SummaryCard from './components/summary-card'
import SummaryChart from './components/summary-chart'
// import PaymentFailAlert from './components/payment-fail-alert'

import LoadingPage from '../../../components/loading-page'

import {
  useCountItemsQuery,
  useViewWorkspaceQuery,
  useCountActiveItemsQuery,
  EnumWorkspacePlatform_Type,
} from '../../../graphql/components'

import { ReactComponent as NetworkIcon } from '../../../assets/icons/network.svg'

const WorkspaceSummaryPage: FC = () => {
  const { workspace_id } = useParams()

  const {
    data: itemsCountData,
    loading: loadingItemsCount,
    refetch: refetchItemsCount,
  } = useCountItemsQuery({ fetchPolicy: 'cache-first' })
  const {
    data: activeData,
    loading: loadingActiveData,
    refetch: refetchActiveItems,
  } = useCountActiveItemsQuery({
    fetchPolicy: 'cache-first',
  })

  const refetchData = async () => await refetchItemsCount()

  const {
    data: workspaceData,
    loading: loadingWorkspaceData,
  } = useViewWorkspaceQuery({
    skip: !workspace_id,
    fetchPolicy: 'no-cache',
    variables: { _id: workspace_id },
  })

  const workspace = useMemo(() => workspaceData?.workspace, [workspaceData])

  const workspaceType = useMemo(() => workspace?.platform_type || undefined, [
    workspace,
  ])

  const showFullSummary = useMemo(
    () =>
      workspaceType !== undefined &&
      workspaceType !== EnumWorkspacePlatform_Type.Advertiser,
    [workspaceType]
  )

  useEffect(() => {
    refetchItemsCount()
    refetchActiveItems()
  }, [workspace_id])

  useEffect(() => {
    document.addEventListener('refetch-players', refetchData)
    document.addEventListener('refetch-networks', refetchData)
    document.addEventListener('refetch-creatives', refetchData)
    document.addEventListener('refetch-campaigns', refetchData)
    document.addEventListener('refetch-campaign-ad-groups', refetchData)
    return () => {
      document.removeEventListener('refetch-players', refetchData)
      document.removeEventListener('refetch-networks', refetchData)
      document.removeEventListener('refetch-creatives', refetchData)
      document.removeEventListener('refetch-campaigns', refetchData)
      document.removeEventListener('refetch-campaign-ad-groups', refetchData)
    }
  }, [])

  const { renderOnboardingPage } = useOnboardingPage(
    itemsCountData,
    workspaceType
  )
  // const invoices = useMemo(() => workspace?.invoices?.data || [], [workspace])

  const divStyle = useMemo(
    () =>
      showFullSummary
        ? tw`grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-8 mb-4 lg:mb-8`
        : tw`grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-8 mb-4 lg:mb-8`,
    [showFullSummary]
  )

  return (
    <Suspense
      ready={!loadingItemsCount && !loadingActiveData && !loadingWorkspaceData}
      fallback={<LoadingPage />}
    >
      <Suspense
        ready={!renderOnboardingPage}
        fallback={
          <WorkspaceOnboardingPage
            count={itemsCountData}
            workspaceType={workspaceType}
          />
        }
      >
        <Container>
          {/* <PaymentFailAlert
            workspaceId={workspace_id}
            invoices={invoices as Invoice[]}
          /> */}

          <PageHeader title="Workspace Summary" />

          <div css={divStyle}>
            <div tw="w-full">
              <SummaryCard
                linkTo="/campaigns"
                title="Total Campaigns"
                count={activeData?.count?.campaigns ?? 0}
                icon={<Icon icon="megaphone" tw="text-2xl text-purple" />}
              />
            </div>
            <div tw="w-full">
              <SummaryCard
                linkTo="/creatives"
                title="Total Creatives"
                count={activeData?.count?.creatives ?? 0}
                icon={<Icon icon="play" tw="text-2xl text-purple" />}
              />
            </div>
            {showFullSummary && (
              <>
                <div tw="w-full">
                  <SummaryCard
                    linkTo="/networks"
                    title="Total Networks"
                    count={activeData?.count?.networks ?? 0}
                    icon={
                      <NetworkIcon width={24} height={24} tw="text-purple" />
                    }
                  />
                </div>
                <div tw="w-full">
                  <SummaryCard
                    linkTo="/players"
                    title="Total Players"
                    count={activeData?.count?.players ?? 0}
                    icon={<Icon icon="computer" tw="text-2xl text-purple" />}
                  />
                </div>
              </>
            )}
          </div>
          <div tw="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
            <SummaryChart title="Impressions" metric="reach" />
            <SummaryChart title="Scans" metric="engagement" />
          </div>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default WorkspaceSummaryPage
