import React, { FC } from 'react'
import 'twin.macro'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

export interface CreativesNoResultsProps {
  searchQuery?: string
}

const CreativesNoResults: FC<CreativesNoResultsProps> = ({ searchQuery }) => (
  <div tw="flex flex-col items-center">
    <Illustration name={IllustrationEnum.creatives_empty_results} tw="mb-10" />
    <h2 tw="font-light text-3xl text-charcoal leading-tight">
      No data to show
    </h2>
    <p tw="text-charcoal font-light pt-4 pb-6 w-1/2 text-center">
      {searchQuery
        ? `We can't find any creative for "${searchQuery}". Please check your spelling and try again.`
        : `We can't find any creative.`}
    </p>
  </div>
)

export default CreativesNoResults
