import React, { FC } from 'react'

import 'twin.macro'
import _ from 'lodash'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Status from '../../../../ui-blocks/status'

import useElementStates from '../../../../utils/use-element-states'

import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  EnumCreativeStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
  EnumBroadcastTargetStatus,
} from '../../../../graphql/components'

const StatusSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const {
    states,
    values: { withPopover, ...values },
  } = useElementStates(['compact', 'withPopover'])
  const allStatuses = _.uniq(
    [
      EnumPlayerStatus,
      EnumNetworkStatus,
      EnumCreativeStatus,
      EnumCampaignStatus,
      EnumCampaignAdGroupStatus,
      EnumBroadcastTargetStatus,
    ].reduce<string[]>((acc, value) => [...acc, ...Object.values(value)], [])
  )

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="flex flex-row flex-wrap items-center gap-2">
        {allStatuses.map((value, index) => (
          <Status value={value as any} key={`status#${index}`} {...values}>
            {withPopover && <p>Popover content</p>}
          </Status>
        ))}
      </div>
    </ElementSection>
  )
}

export default StatusSection
