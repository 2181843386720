import React, { FC, Fragment } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import ImageControls from './image-controls'

export interface ImageWrapperProps {
  url: string
  creativeName: string
}

const ImageWrapper: FC<ImageWrapperProps> = ({ url, creativeName }) => (
  <TransformWrapper centerOnInit wheel={{ step: 0.5 }}>
    {({ state, zoomIn, zoomOut, resetTransform }) => (
      <Fragment>
        <TransformComponent>
          <img alt={creativeName} src={url} />
        </TransformComponent>
        <ImageControls
          scale={state.scale}
          onIncrease={() => zoomIn(0.5)}
          onDecrease={() => zoomOut(0.5)}
          onReset={() => resetTransform()}
        />
      </Fragment>
    )}
  </TransformWrapper>
)

export default ImageWrapper
