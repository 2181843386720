import React, { FC } from 'react'
import tw from 'twin.macro'

import Spinner from '../../ui-blocks/spinner'
import LazyLoadImage from '../../ui-blocks/lazy-load-image'

import {
  EnumUploadType,
  EnumUploadStatus,
  UploadMinimumFragment,
} from '../../graphql/components'

import { ReactComponent as VideoIcon } from '../../assets/icons/video.svg'
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg'

export type AspectRatio = '16/9' | '13/9'
export interface UploadThumbnailProps {
  ratio?: AspectRatio
  upload?: UploadMinimumFragment
  onClick?: () => void
}

const UploadThumbnail: FC<UploadThumbnailProps> = ({
  ratio,
  upload,
  onClick,
  ...props
}) => {
  const versions = upload?.versions || []
  const thumbnail = versions.find((v) => v?.name === 'thumbnail-300w')
  const isProcessing =
    upload?.status === EnumUploadStatus.Verifying ||
    upload?.status === EnumUploadStatus.Uploading

  const hasThumbnail = !!thumbnail
  let aspectRatio = tw`aspect-w-16 aspect-h-9`
  if (ratio === '13/9') aspectRatio = tw`aspect-w-13 aspect-h-9`
  return (
    <div
      onClick={hasThumbnail ? onClick : undefined}
      css={[
        aspectRatio,
        'transform: translateZ(0);',
        tw`bg-platinum overflow-hidden`,
        hasThumbnail && !!onClick && tw`hover:cursor-pointer`,
      ]}
      {...props}
    >
      {isProcessing && <Spinner center tw="w-32 h-32 text-white" />}
      {!hasThumbnail && upload?.type === EnumUploadType.Image && <ImageIcon />}
      {!hasThumbnail && upload?.type === EnumUploadType.Video && <VideoIcon />}
      {!isProcessing && hasThumbnail && (
        <LazyLoadImage
          alt={thumbnail?.name}
          src={thumbnail?.public_url || undefined}
          tw="object-cover"
        />
      )}
    </div>
    /*      {hasThumbnail && upload?.type === EnumUploadType.Video && (
        <LazyLoadVideo
          src={thumbnail?.public_url || undefined}
          tw="object-cover"
        />
      )}*/
  )
}

UploadThumbnail.defaultProps = {
  ratio: '16/9',
}

export default UploadThumbnail
