import React, { FC } from 'react'
import 'twin.macro'

import ElementSection, { ElementSectionProps } from './element-section'

import PageHeader from '../../../../ui-blocks/page-header'
import Button from '../../../../ui-blocks/button'

const AvatarSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <div tw="flex flex-col items-center">
        <PageHeader title="Page Header without description" />

        <PageHeader
          title="Page Header with description"
          description="This is the description"
        />

        <PageHeader
          title="Page Header with description"
          description="This page header also has content at the right"
        >
          <Button>Add Resource</Button>
        </PageHeader>

        {/* <PageHeader title="Page Header with Breadcrumbs" /> */}
      </div>
    </ElementSection>
  )
}

export default AvatarSection
