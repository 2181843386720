import React, { FC, useState } from 'react'
import tw from 'twin.macro'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import Text from '../../../../ui-blocks/text'
import Link from '../../../../ui-blocks/link'
import Button from '../../../../ui-blocks/button'
import { InputFormik } from '../../../../ui-blocks/input'
import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'
import {
  ForgotPasswordMethod,
  useAuthForgotPasswordSendCodeMutation,
} from '../../../../graphql/components'
import { authentication } from '../../../../stores'

interface ChooseMethodFormikValues {
  email: string
  // phoneNumber: string
  method: ForgotPasswordMethod
}

const chooseMethodValidationSchema = object().shape({
  method: string().oneOf(Object.values(ForgotPasswordMethod)).required(),
  email: string().when('method', {
    is: ForgotPasswordMethod.Email,
    then: string()
      .trim('Value cannot have leading or trailing white spaces')
      .email('Must be a valid email address')
      .required('Email is required')
      .strict(true),
  }),
  // phoneNumber: string().when('method', {
  //   is: ForgotPasswordMethod.Phone,
  //   then: string()
  //     .trim('Value cannot have leading or trailing white spaces')
  //     .matches(/9[1236]\d{7}/, 'Value must be exactly 9 digits long')
  //     .required('Phone number is required')
  //     .strict(true),
  // }),
})

export interface ChooseMethodStepProps {
  onNext?: (method: ForgotPasswordMethod, contact: string) => void
}

const ChooseMethodStep: FC<ChooseMethodStepProps> = ({ onNext }) => {
  const [hasError, setHasError] = useState<boolean>(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [sendCode] = useAuthForgotPasswordSendCodeMutation()

  const formik = useFormik<ChooseMethodFormikValues>({
    validateOnChange: false,
    validationSchema: chooseMethodValidationSchema,
    initialValues: {
      email: '',
      // phoneNumber: '',
      method: ForgotPasswordMethod.Email,
    },
    async onSubmit({ method, email /* phoneNumber */ }) {
      setHasError(false)
      try {
        const identifier =
          (method === ForgotPasswordMethod.Email && email) ||
          // (method === ForgotPasswordMethod.Phone && phoneNumber) ||
          ''
        const reCaptchaToken = await executeRecaptcha?.(
          'auth/forgot_password/send_code'
        )
        const res = await sendCode({
          variables: { method, identifier },
          context: {
            headers: {
              ...(!!reCaptchaToken
                ? { 'X-Recaptcha-Token': reCaptchaToken }
                : {}),
            },
          },
        })
        if (!res.data?.codeSent) return setHasError(true)
        onNext?.(method, identifier)
      } catch {
        setHasError(true)
      }
    },
  })

  const redirectTo = authentication.is_authenticated
    ? `/workspaces/${authentication.selected_workspace._id}/overview`
    : '/auth/sign-in'
  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      <Text as="h1" preset="h2" tw="mb-1">
        Forgot your password?
      </Text>
      <Text
        as="p"
        preset="p1"
        css={[tw`text-dark-blue-gray`, hasError ? tw`mb-4` : tw`mb-10`]}
      >
        We’ve got you covered. Please let us know who you are so we can send you
        a verification code.
        {/* Choose a method to receive a verification code. */}
      </Text>
      {hasError && (
        <div tw="mb-10">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              id: 'forgot-password-send-code-error-alert',
              message:
                "We couldn't send the code to the specified contact. Please try again later.",
            }}
          />
        </div>
      )}
      {/* <div tw="flex flex-row flex-wrap items-stretch justify-center gap-6 mb-6">
        <CardResourceFormik
          type="radio"
          title="Email"
          name="method"
          value={ForgotPasswordMethod.Email}
          description="Send code to your email"
          icon={<Icon icon="email" tw="text-2xl text-purple" />}
          formik={formik}
        />
        <CardResourceFormik
          type="radio"
          title="Text"
          name="method"
          value={ForgotPasswordMethod.Phone}
          description="Send code to your phone number"
          icon={<Icon icon="message" tw="text-2xl text-purple" />}
          formik={formik}
        />
      </div> */}
      {formik.values.method === ForgotPasswordMethod.Email && (
        <InputFormik
          type="email"
          name="email"
          label="Email Address"
          tw="mb-12"
          formik={formik}
        />
      )}
      {/* {formik.values.method === ForgotPasswordMethod.Phone && (
        <InputFormik
          type="tel"
          name="phoneNumber"
          label="Phone Number"
          tw="mb-12"
          formik={formik}
        />
      )} */}
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
        tw="block mb-6"
      >
        Send verification code
      </Button>
      <Link router={{ to: redirectTo }} tw="p-0 inline-flex">
        Back to {authentication.is_authenticated ? 'overview' : 'sign in'}
      </Link>
    </form>
  )
}

export default ChooseMethodStep
