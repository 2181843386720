import React, { FC, CSSProperties } from 'react'
import tw, { css } from 'twin.macro'

import { TableCellAlignment } from '..'

export interface ColumnCellProps {
  hasVerticalPadding?: boolean
  alignment?: TableCellAlignment
  colSpan?: number
  style?: CSSProperties
}

const ColumnCell: FC<ColumnCellProps> = ({
  hasVerticalPadding,
  alignment,
  children,
  colSpan,
  style,
}) => (
  <td
    role="cell"
    style={style}
    css={css`
      ${hasVerticalPadding && tw`py-4`}
      ${tw`text-charcoal border-b border-t border-platinum px-4`}
      &:first-of-type {
        ${tw`border-l rounded-tl rounded-bl`}
      }
      &:last-of-type {
        ${tw`border-r rounded-tr rounded-br`}
      }
    `}
    colSpan={colSpan || 1}
  >
    <div
      css={[
        tw`flex flex-row items-center justify-start`,
        alignment === 'left' && tw`justify-start`,
        alignment === 'center' && tw`justify-center`,
        alignment === 'right' && tw`justify-end`,
      ]}
    >
      {children}
    </div>
  </td>
)

ColumnCell.defaultProps = {
  hasVerticalPadding: true,
}

export default ColumnCell
