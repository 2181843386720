import React, { FC, Fragment, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

import SharedBy from './components/shared-by'
import RevokeAccessModal from './components/revoke-access-modal'
import RevokeAccessAction from './components/revoke-access-action'

import Table, { TableDataProps } from '../../../../../components/table'

import {
  WorkspaceFullFragment,
  NetworkSharedWithWorkspaces,
  useRevokeWorkspaceAccessMutation,
} from '../../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../../../utils/data-manipulation'
import getInitials from '../../../../../utils/get-initials'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'
import Gravatar from '../../../../../ui-blocks/gravatar'

export interface SharedWorkspace extends WorkspaceFullFragment {
  shared?: NetworkSharedWithWorkspaces
}

export interface SharedWorkspacesListTableProps
  extends TableDataProps<SharedWorkspace> {
  refetchData?: () => Promise<any>
}

const SharedWorkspacesListTable: FC<SharedWorkspacesListTableProps> = ({
  data,
  refetchData,
  ...props
}) => {
  const { network_id } = useParams()
  const [revokeAccess] = useRevokeWorkspaceAccessMutation()

  const [isRevoking, setIsRevoking] = useState<boolean>(false)
  const [isRevokeModalOpen, openRevokeModal] = useState<boolean>(false)
  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<SharedWorkspace | null>(null)

  const onCloseModal = () => {
    openRevokeModal(false)
    setSelectedWorkspace(null)
  }

  const onSelectWorkspace = (workspace: SharedWorkspace) => {
    setSelectedWorkspace(workspace)
    openRevokeModal(true)
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'revoke-workspace-error-alert',
      message:
        "We couldn't revoke this workspace's access. Please try again later.",
    })
  }

  const onRevokeAccess = async () => {
    setIsRevoking(true)
    try {
      const res = await revokeAccess({
        variables: {
          networkId: network_id,
          workspaceId: selectedWorkspace?._id,
        },
      })
      if (!res.data?.isRevoked) return handleError()
      await refetchData?.()
      onCloseModal()
    } catch {
      handleError()
    } finally {
      setIsRevoking(false)
    }
  }

  const tableData = useMemo(
    () =>
      data.map((workspace) => ({
        avatar: {
          content: (
            <Gravatar
              size="2.5rem"
              initials={getInitials(workspace.name)}
              email={workspace.billing_email || undefined}
            />
          ),
        },
        name: {
          value: workspace.name,
          content: workspace.name,
        },
        members: {
          value:
            workspace.members?.filter((member) => member?.removed?.at === null)
              .length ?? 0,
          content:
            workspace.members?.filter((member) => member?.removed?.at === null)
              .length ?? 0,
        },
        invitedAt: {
          value: workspace?.shared?.created?.at,
          content: (
            <SharedBy
              date={workspace?.shared?.created?.at}
              email={workspace?.shared?.sent_by || ''}
            />
          ),
        },
        action: {
          content: (
            <RevokeAccessAction onClick={() => onSelectWorkspace(workspace)} />
          ),
        },
      })),
    [data]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData)),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="workspace"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.ad_groups_empty_results} />
        }
        cols={[
          { key: 'avatar', width: 142 },
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'members',
            label: 'Members',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'invitedAt',
            label: 'Invited On',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'action', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RevokeAccessModal
        loading={isRevoking}
        networkId={network_id}
        open={isRevokeModalOpen}
        workspaceId={selectedWorkspace?._id}
        onConfirm={onRevokeAccess}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default SharedWorkspacesListTable
