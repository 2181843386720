import escapeStringRegexp from 'escape-string-regexp'
import mem from 'mem'

const _regexpFromString = mem(
  (str: string, flags?: string): RegExp => {
    return new RegExp(escapeStringRegexp(str), flags)
  },
  {
    maxAge: 60 * 1000,
    cacheKey: ([str, flags]) => `${str},${flags}`,
  }
)

export function regexpFromString(str: string, flags?: string): RegExp {
  return _regexpFromString(str, flags)
}
