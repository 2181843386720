import React, { FC, useState } from 'react'
import tw from 'twin.macro'

import Popover from './popover'

import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  EnumCreativeStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
  EnumBroadcastTargetStatus,
} from '../graphql/components'

const BLUE_STATUS = [
  EnumPlayerStatus.Activated,
  EnumNetworkStatus.Activated,
  EnumCreativeStatus.Activated,
  EnumCampaignStatus.Activated,
  EnumCampaignAdGroupStatus.Activated,
  EnumBroadcastTargetStatus.Approved,
]

const RED_STATUS = [
  EnumPlayerStatus.Binded,
  EnumCampaignStatus.Pending,
  EnumCampaignAdGroupStatus.Pending,
  EnumBroadcastTargetStatus.Rejected,
]

const GREEN_STATUS = [
  EnumPlayerStatus.Registered,
  EnumCampaignStatus.Waiting,
  EnumCampaignAdGroupStatus.Waiting,
  EnumBroadcastTargetStatus.NotSubmitted,
]

const YELLOW_STATUS = [
  EnumPlayerStatus.Deactivated,
  EnumNetworkStatus.Deactivated,
  EnumCreativeStatus.Deactivated,
  EnumCampaignStatus.Deactivated,
  EnumCampaignAdGroupStatus.Deactivated,
  EnumBroadcastTargetStatus.PendingApproval,
]

const BLACK_STATUS = [
  EnumPlayerStatus.Archived,
  EnumNetworkStatus.Archived,
  EnumCreativeStatus.Archived,
  EnumCampaignStatus.Finished,
  EnumCampaignStatus.Archived,
  EnumCampaignAdGroupStatus.Finished,
  EnumCampaignAdGroupStatus.Archived,
  EnumBroadcastTargetStatus.Revoked,
]

export interface StatusProps {
  id?: string
  compact?: boolean
  value:
    | EnumPlayerStatus
    | EnumNetworkStatus
    | EnumCreativeStatus
    | EnumCampaignStatus
    | EnumCampaignAdGroupStatus
    | EnumBroadcastTargetStatus
}

const Status: FC<StatusProps> = ({ id, compact, children, ...props }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const value = props.value as any
  const colors =
    (BLUE_STATUS.includes(value) && tw`bg-light-blue text-blue`) ||
    (RED_STATUS.includes(value) && tw`bg-light-red text-dark-red`) ||
    (GREEN_STATUS.includes(value) && tw`bg-light-green text-green`) ||
    (YELLOW_STATUS.includes(value) && tw`bg-light-yellow text-dark-yellow`) ||
    (BLACK_STATUS.includes(value) && tw`bg-light-charcoal text-charcoal`) ||
    tw`bg-light-yellow text-dark-yellow`

  return (
    <div tw="relative">
      <div
        id={id}
        onClick={() => !!children && setIsPopoverOpen(true)}
        css={[
          colors,
          'line-height: 0.875rem;',
          'letter-spacing: 0.07rem;',
          !!children && tw`hover:cursor-pointer`,
          tw`text-xs font-medium tracking-wider uppercase border-0 select-none`,
          !compact
            ? tw`flex items-center justify-center text-center rounded`
            : tw`bg-transparent`,
          !compact && 'height: 2.375rem;',
          !compact && 'width: 6.875rem;',
        ]}
      >
        {value.replace('_', ' ')}
      </div>
      {!!children && (
        <Popover open={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
          {children}
        </Popover>
      )}
    </div>
  )
}

export default Status
