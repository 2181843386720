import React, { FC, DetailedHTMLProps, HTMLAttributes } from 'react'
import 'twin.macro'

const ErrorMessage: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
> = ({ children, ...props }) => (
  <span
    tw="font-normal text-xs text-brick-red leading-tight block mt-1"
    {...props}
  >
    {children}
  </span>
)

export default ErrorMessage
