import React, { FC } from 'react'

import ModalAction from '../../../../../ui-blocks/modal-action'

import {
  Webhook,
  useArchiveWebhookMutation,
} from '../../../../../graphql/components'

import {
  AlertVariant,
  alertsManager,
} from '../../../../../stores/alerts-manager'

export interface RemoveWebhookModalProps {
  open?: boolean
  webhook: Webhook
  onClose?: () => void
  refetchData?: () => Promise<any>
}

const RemoveWebhookModal: FC<RemoveWebhookModalProps> = ({
  open,
  webhook,
  onClose,
  refetchData,
}) => {
  const [
    archiveWebhook,
    { loading: removingWebhook },
  ] = useArchiveWebhookMutation()

  const onRemoveWebhook = async () => {
    try {
      const res = await archiveWebhook({ variables: { ids: [webhook?._id] } })
      if (!res.data?.areArchived) return handleError()

      await refetchData?.()
      onClose?.()
    } catch {
      handleError()
    }
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'remove-webhook-error-alert',
      message: "We couldn't remove this webhook. Please try again later.",
    })
  }

  return (
    <ModalAction
      open={open}
      onCancel={onClose}
      onBackdropClick={onClose}
      onConfirm={onRemoveWebhook}
      loading={removingWebhook}
      title={`Webhook: ${webhook ? webhook.name : ''}`}
      content="Are you sure you want to delete this webhook?"
    />
  )
}

export default RemoveWebhookModal
