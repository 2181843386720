import React, { FC, ReactNode } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import Card from '../../../../ui-blocks/card'

export interface SummaryCardProps {
  title: string
  count: number
  linkTo?: string
  icon: ReactNode
}

const SummaryCard: FC<SummaryCardProps> = ({ title, count, linkTo, icon }) => {
  const content = (
    <Card tw="py-4 px-6">
      <div tw="w-12 h-12 mb-4 bg-cultured rounded-full flex justify-center items-center">
        {icon}
      </div>
      <h2 tw="text-charcoal font-light leading-tight text-5xl">{count}</h2>
      <h3 tw="text-charcoal font-normal leading-tight text-base">{title}</h3>
    </Card>
  )
  return !!linkTo ? <Link to={linkTo}>{content}</Link> : content
}

export default SummaryCard
