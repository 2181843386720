import React, { FC, Fragment, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router'

import Button from '../../../../../../ui-blocks/button'

import ArchivePlayerModal from './archive-player-modal'

import {
  PlayerFullFragment,
  useArchivePlayerMutation,
} from '../../../../../../graphql/components'

import { alertsManager } from '../../../../../../stores'
import { AlertVariant } from '../../../../../../stores/alerts-manager'

export interface ArchivePlayerFormProps {
  player?: PlayerFullFragment
}

const ArchivePlayerForm: FC<ArchivePlayerFormProps> = ({ player }) => {
  const navigate = useNavigate()
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)

  const [
    archivePlayer,
    { loading: archivingPlayer },
  ] = useArchivePlayerMutation()

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'archive-player-error-alert',
    })
  }

  const onArchivePlayer = async (reason: string) => {
    try {
      const { data } = await archivePlayer({
        variables: { ids: [player?._id], reason },
      })
      if (!data?.areArchived) {
        return handleError(
          "We couldn't archive this player. Please try again later."
        )
      }
      navigate('/players', { state: { refetch: true } })
    } catch {
      handleError(
        'Something went wrong while trying to archive this player. Please try again later.'
      )
    }
  }

  return (
    <Fragment>
      <span tw="font-medium text-charcoal leading-tight block mb-2">
        Archive
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-10">
        Once you archive a player it will no longer be available.
      </span>
      <Button danger type="button" onClick={() => openWarningModal(true)}>
        Archive player
      </Button>
      <ArchivePlayerModal
        open={isWarningModalOpen}
        loading={archivingPlayer}
        onConfirm={onArchivePlayer}
        playerName={player?.name || ''}
        onClose={() => openWarningModal(false)}
        onCancel={() => openWarningModal(false)}
      />
    </Fragment>
  )
}

export default ArchivePlayerForm
