import React, { FC } from 'react'
import 'twin.macro'

import Text from '../ui-blocks/text'
import ModalAction from '../ui-blocks/modal-action'

export interface ArchiveWarningModalProps {
  target: string
  open?: boolean
  loading?: boolean
  redirectPage?: string
  onCancel: () => void
  onConfirm: () => void
}

const ArchiveWarningModal: FC<ArchiveWarningModalProps> = ({
  target,
  redirectPage,
  children,
  ...props
}) => (
  <ModalAction {...props}>
    <Text as="h3" preset="h3" tw="mb-8 break-words">
      WARNING: Archive {target}
    </Text>
    <Text as="p" preset="h4" tw="mb-4 break-words">
      You are about to archive this {target}. Are you sure you want to do this?
    </Text>
    {children}
    {redirectPage && (
      <Text as="span" preset="p2" tw="mt-2">
        {`NOTE: Once the ${target} has been archived you will be redirected back to the `}
        <b>{redirectPage}</b>
        {' page.'}
      </Text>
    )}
  </ModalAction>
)

export default ArchiveWarningModal
