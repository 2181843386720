import { useEffect } from 'react'

const useKeyPress = (
  targetKey: string,
  callback: (e: KeyboardEvent) => void
) => {
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === targetKey) callback(e)
  }

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [callback])
}

export default useKeyPress
