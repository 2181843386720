import React, { FC, useMemo } from 'react'

import 'twin.macro'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Text from '../../../ui-blocks/text'
import Button from '../../../ui-blocks/button'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { TabPane, Tabs } from '../../../ui-blocks/tabs'

import RevokeForm from './components/revoke-form'
import OverviewSection from './components/overview-section'
import AdGroupsSection from './components/ad-groups-section'
import AnalyticsSection from './components/analytics-section'

import NotFound from '../../../components/not-found'
import LoadingPage from '../../../components/loading-page'

import {
  EnumCampaignStatus,
  EnumBroadcastTargetStatus,
  useViewExternalCampaignQuery,
  // useUpdateCampaignActivationMutation,
} from '../../../graphql/components'

import { getKPIIntervals } from '../../../utils/stats-intervals'

export enum ExternalCampaignPageTabKeys {
  OVERVIEW = 'overview',
  AD_GROUPS = 'ad-groups',
  ANALYTICS = 'analytics',
}

export const externalCampaignPageRoutes = {
  [ExternalCampaignPageTabKeys.OVERVIEW]: '',
  [ExternalCampaignPageTabKeys.AD_GROUPS]: 'ad-groups',
  [ExternalCampaignPageTabKeys.ANALYTICS]: 'analytics',
}

const ViewExternalCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_id, broadcast_target_id } = useParams()

  // const location = useLocation()
  // const shouldRefetch = (location.state || ({} as any)).refetch as boolean
  // const [updateCampaignActivation] = useUpdateCampaignActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])

  const {
    data: campaignData,
    loading: loadingCampaign,
    // refetch: refetchCampaign,
  } = useViewExternalCampaignQuery({
    skip: !campaign_id,
    fetchPolicy: 'cache-first',
    variables: {
      _id: campaign_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a campaign's ad group page if it is archived
  const { campaign } = campaignData || {}
  const broadcast_target = useMemo(
    () =>
      campaign?.broadcast_targets?.find(
        (bt) => String(bt?._id) == broadcast_target_id
      ),
    [campaign]
  )

  // useEffect(() => {
  //   shouldRefetch && refetchCampaign()
  // }, [shouldRefetch])

  // const refetchCampaignListener = async () => await refetchCampaign()
  // useEffect(() => {
  //   document.addEventListener('refetch-networks', refetchCampaignListener)
  //   document.addEventListener(
  //     'refetch-internal-campaign',
  //     refetchCampaignListener
  //   )
  //   document.addEventListener(
  //     'refetch-campaign-ad-groups',
  //     refetchCampaignListener
  //   )
  //   return () => {
  //     document.removeEventListener('refetch-networks', refetchCampaignListener)
  //     document.removeEventListener(
  //       'refetch-internal-campaign',
  //       refetchCampaignListener
  //     )
  //     document.removeEventListener(
  //       'refetch-campaign-ad-groups',
  //       refetchCampaignListener
  //     )
  //   }
  // }, [])

  const handleRevokeSubmission = () => {
    navigate('/campaigns', { replace: true, state: { refetch: true } })
  }

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/campaigns/external/${campaign?._id}/${broadcast_target?._id}/${
        externalCampaignPageRoutes[tabKey as ExternalCampaignPageTabKeys]
      }`
    )
  }

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(
        `/${ExternalCampaignPageTabKeys.AD_GROUPS}`
      ) &&
        ExternalCampaignPageTabKeys.AD_GROUPS) ||
      (location.pathname.endsWith(
        `/${ExternalCampaignPageTabKeys.ANALYTICS}`
      ) &&
        ExternalCampaignPageTabKeys.ANALYTICS) ||
      ExternalCampaignPageTabKeys.OVERVIEW,
    [location]
  )

  if (campaign?.status === EnumCampaignStatus.Archived) {
    return <Navigate to="/campaigns" state={{ replace: true }} />
  }

  if (campaign && !campaign.is_external) {
    return <Navigate to="/campaigns" state={{ replace: true }} />
  }

  // if campaign is not approved nor pending, redirect
  return (
    <Suspense ready={!loadingCampaign} fallback={<LoadingPage />}>
      <Suspense ready={!!campaign} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              {
                label: 'Campaigns',
                linkTo: '/campaigns',
              },
              { label: campaign?.name || '' },
            ]}
          />
          <div tw="w-full flex flex-row items-center justify-between">
            <div tw="flex flex-row items-center mb-6">
              <Text as="h1" preset="h3">
                {campaign?.name}
              </Text>
            </div>

            <div tw="flex flex-row items-center mb-6 space-x-4">
              {(broadcast_target?.status ===
                EnumBroadcastTargetStatus.PendingApproval ||
                broadcast_target?.status ===
                  EnumBroadcastTargetStatus.Approved) && (
                <Button
                  secondary
                  iconLeft={<Icon icon="circle-remove" tw="text-base" />}
                  dropdown={
                    <RevokeForm
                      campaignId={campaign_id}
                      networkId={broadcast_target?.ref_id}
                      onRevoke={handleRevokeSubmission}
                    />
                  }
                >
                  Revoke Submission
                </Button>
              )}

              {broadcast_target?.status ===
                EnumBroadcastTargetStatus.PendingApproval && (
                <Button
                  iconLeft={<Icon icon="document-check" tw="text-base" />}
                  onClick={() =>
                    navigate(
                      `/review-campaign/${campaign?._id}/${broadcast_target?.ref_id}`
                    )
                  }
                >
                  Review
                </Button>
              )}
            </div>
          </div>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane
              key={ExternalCampaignPageTabKeys.OVERVIEW}
              tab="Overview"
              tw="mt-10"
            >
              <OverviewSection campaign={campaign || undefined} />
            </TabPane>
            <TabPane
              key={ExternalCampaignPageTabKeys.AD_GROUPS}
              tab="Ad Groups"
              tw="mt-10"
            >
              <AdGroupsSection
                loading={loadingCampaign}
                campaign={campaign || undefined}
              />
            </TabPane>
            <TabPane
              key={ExternalCampaignPageTabKeys.ANALYTICS}
              tab="Analytics"
              tw="mt-10"
            >
              <AnalyticsSection campaign={campaign || undefined} />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewExternalCampaignPage
