import React, { FC } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router-dom'

import Container from '../../../../../ui-blocks/container'
import SetupCard from '../../../../../ui-blocks/setup-card'
import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

import { EnumWorkspacePlatform_Type } from '../../../../../graphql/components'

export interface InitialSetupProps {
  hasPlayers?: boolean
  hasCreatives?: boolean
  workspaceType?: EnumWorkspacePlatform_Type
  hasNetworks?: boolean
}

const InitialSetup: FC<InitialSetupProps> = ({
  hasPlayers,
  hasCreatives,
  workspaceType,
  hasNetworks,
}) => {
  const navigate = useNavigate()
  return (
    <Container>
      <div tw="flex flex-col items-center justify-center">
        <h1 tw="font-light text-3xl text-charcoal leading-tight self-center mb-4">
          Campaigns Rock
        </h1>
        <p tw="font-normal text-charcoal self-center text-center max-w-xl">
          Get live by setting your campaigns. Make sure you have your Players
          ready and creatives available to play.
        </p>
      </div>
      <div tw="flex flex-col lg:flex-row items-center lg:items-stretch justify-center space-x-0 space-y-8 lg:space-x-8 lg:space-y-0 mt-14">
        {(workspaceType === EnumWorkspacePlatform_Type.Enterprise ||
          (!hasNetworks &&
            workspaceType !== EnumWorkspacePlatform_Type.Advertiser)) && (
          <SetupCard
            title="Set up your diffuse box"
            description="Campaigns need a channel to play. Set your Player to get action."
            disabled={!!hasPlayers}
            Image={<Illustration name={IllustrationEnum.players_empty_state} />}
            onButtonClick={() => navigate('/players/bind')}
            buttonText={
              hasPlayers ? 'You already have a player' : 'Bind a Player'
            }
          />
        )}
        <SetupCard
          title="Feed us with your amazing content"
          description="Your first step to get ready to create a campaign."
          disabled={!!hasCreatives}
          Image={<Illustration name={IllustrationEnum.creatives_empty_state} />}
          onButtonClick={() => navigate('/creatives')}
          buttonText={
            hasCreatives ? 'You already have creatives' : 'Upload Creatives'
          }
        />
      </div>
    </Container>
  )
}

export default InitialSetup
