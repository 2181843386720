import React, { FC } from 'react'
import 'twin.macro'

import Text from '../../../ui-blocks/text'
import ModalAction from '../../../ui-blocks/modal-action'

export interface RestoreModalProps {
  target: string
  itemName: string
  open?: boolean
  loading?: boolean
  onCancel?: () => void
  onConfirm: () => void
}

const RestoreModal: FC<RestoreModalProps> = ({
  target,
  itemName,
  children,
  ...props
}) => (
  <ModalAction width="30rem" {...props}>
    <Text as="h2" preset="h3" tw="mb-6 break-words">
      {`Restore ${target} "${itemName}"`}
    </Text>
    <Text as="p" preset="p2" tw="text-base mb-2 break-words">
      {`Are you sure you wish to restore the ${target} "${itemName}"?`}
    </Text>
    <Text as="p" preset="p2" tw="text-base mb-8">
      {`By proceeding the ${target} will be reactivated.`}
    </Text>
    {children}
  </ModalAction>
)

export default RestoreModal
