import React, { FC, useEffect, useMemo } from 'react'
import 'twin.macro'

import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ArchivedCampaignsListTable from './table'

import {
  useCountArchivedCampaignsQuery,
  useListArchivedCampaignsQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { SearchSettings } from '../../../../utils/use-search'

export interface CampaignsSectionProps {
  onSearch?: (settings: SearchSettings) => void
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  searchTextDebounced: string
}

const CampaignsSection: FC<CampaignsSectionProps> = ({
  onSearch,
  setSearchText,
  searchText,
  searchTextDebounced,
}) => {
  const {
    data: archivedCampaignsCountData,
    loading: loadingArchivedCampaignsCount,
  } = useCountArchivedCampaignsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })
  const count = useMemo(() => archivedCampaignsCountData?.campaigns || 0, [
    archivedCampaignsCountData,
  ])
  const pagination = usePagination(count, 15, searchTextDebounced)

  const {
    data: archivedCampaignsData,
    loading: loadingArchivedCampaigns,
    refetch: refetchArchivedCampaigns,
  } = useListArchivedCampaignsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const archivedCampaigns = useMemo(
    () => archivedCampaignsData?.campaigns || [],
    [archivedCampaignsData]
  )

  const refetchData = async () => await refetchArchivedCampaigns()
  useEffect(() => {
    document.addEventListener('refetch-archived-campaigns', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-campaigns', refetchData)
    }
  }, [])

  useEffect(() => {
    onSearch?.({
      text: searchText,
      loading: loadingArchivedCampaigns && loadingArchivedCampaignsCount,
      entries: archivedCampaigns.map((campaign) => campaign.name),
      onSelect: (value) =>
        setSearchText(!Array.isArray(value) ? value || '' : ''),
    })
  }, [
    searchText,
    archivedCampaigns,
    loadingArchivedCampaigns,
    loadingArchivedCampaignsCount,
  ])

  return (
    <section>
      <ArchivedCampaignsListTable
        data={archivedCampaigns}
        loading={loadingArchivedCampaigns && loadingArchivedCampaignsCount}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default CampaignsSection
