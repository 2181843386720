import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from './icon'
import Card from './card'
import Text from './text'

interface HeaderProps {
  title: string
  disabled?: boolean
  onEdit?: () => void
}

export const Informations: FC<HeaderProps> = ({
  title,
  disabled,
  onEdit,
  children,
  ...props
}) => (
  <Card {...props}>
    <div tw="p-8 w-full text-charcoal tracking-wide overflow-auto">
      <div tw="w-full flex justify-between mb-8">
        <Text
          as="h3"
          preset="p1"
          transform="uppercase"
          tw="text-dark-blue-gray tracking-wider"
        >
          {title}
        </Text>
        {!!onEdit && (
          <button
            onClick={onEdit}
            disabled={disabled}
            css={[
              tw`w-16 text-charcoal flex items-center justify-end focus:outline-none space-x-1`,
              !disabled && tw`hover:text-purple`,
            ]}
          >
            <Icon icon="edit" tw="text-base" />
            <span>Edit</span>
          </button>
        )}
      </div>
      <div tw="w-full flex flex-row flex-wrap gap-4">{children}</div>
    </div>
  </Card>
)

export const InformationsSection: FC<{ label?: string }> = ({
  children,
  label,
  ...props
}) => (
  <section tw="flex flex-col flex-grow" {...props}>
    <label tw="font-medium text-xs text-dark-blue-gray mb-2 uppercase select-none">
      {label}
    </label>
    {children}
  </section>
)
