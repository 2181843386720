import React, { FC } from 'react'

import tw from 'twin.macro'
import { DropzoneRootProps } from 'react-dropzone'

import Card from '../../../../ui-blocks/card'
import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

export interface UploadsAreaProps {
  isDragActive: boolean
  rootProps: DropzoneRootProps
}

const UploadsArea: FC<UploadsAreaProps> = ({ isDragActive, rootProps }) => {
  const hereLink = <span tw="text-purple group-hover:underline">here</span>
  return (
    <div className="group" tw="w-full h-full" {...rootProps}>
      <Card
        css={[
          isDragActive && tw`border-purple`,
          tw`flex flex-col items-center justify-center h-full p-10 text-dark-blue-gray text-center group-hover:cursor-pointer`,
        ]}
      >
        <Illustration name={IllustrationEnum.cloud_upload} tw="mb-4" />
        {isDragActive ? (
          <p>Drop the files {hereLink} ...</p>
        ) : (
          <p>Drag and drop the files here or click {hereLink} to browse</p>
        )}
      </Card>
    </div>
  )
}

export default UploadsArea
