import React, { FC, useMemo } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import Icon from '../../../../../ui-blocks/icon'
import Status from '../../../../../ui-blocks/status'
import Illustration, {
  IllustrationEnum,
} from '../../../../../ui-blocks/illustration'

import SubmittedBy from './submitted-by'

import InlineDate from '../../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../../components/table'

import {
  EnumBroadcastTargetStatus,
  ExternalCampaignListFragment,
} from '../../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
  getCampaignTimeframe,
} from '../../../../../utils/data-manipulation'

const ExternalCampaignsListTable: FC<
  TableDataProps<ExternalCampaignListFragment>
> = ({ data, ...props }) => {
  const tableData = useMemo(
    () =>
      data.map((externalCampaign) => {
        const {
          campaign,
          network_id,
          network_name,
          broadcast_target,
        } = externalCampaign
        const [startDate, endDate] = getCampaignTimeframe(
          campaign?.ad_groups as any[]
        )

        // let campaignLink = ''
        // if (broadcast_target?.status === EnumBroadcastTargetStatus.Approved) {
        //   campaignLink = ``
        // } else if (
        //   broadcast_target?.status === EnumBroadcastTargetStatus.PendingApproval
        // ) {
        //   campaignLink = `/review-campaign/${campaign?._id}/${network_id}`
        // }

        return {
          name: {
            value: campaign?.name || '',
            content: (
              <Link
                to={`/campaigns/external/${campaign?._id}/${broadcast_target?._id}`}
                tw="text-purple"
              >
                {campaign?.name || ''}
              </Link>
            ),
          },
          networkName: {
            value: network_name,
            content: (
              <Link to={`/networks/${network_id}`} tw="text-purple">
                {network_name}
              </Link>
            ),
          },
          status: {
            value: broadcast_target?.status,
            content: (
              <Status
                value={broadcast_target?.status as EnumBroadcastTargetStatus}
              />
            ),
          },
          adGroups: {
            value: campaign?.ad_groups?.length ?? 0,
            content: campaign?.ad_groups?.length ?? 0,
          },
          campaignStart: {
            value: startDate,
            content: <InlineDate date={startDate} format="ll" />,
          },
          campaignEnd: {
            value: endDate,
            content: <InlineDate date={endDate} format="ll" />,
          },
          ownership: {
            value: campaign?.ownership?.workspace_name,
            content: campaign?.ownership?.workspace_name,
          },
          submittedAt: {
            value: broadcast_target?.submitted?.at,
            content: (
              <SubmittedBy broadcastTarget={broadcast_target || undefined} />
            ),
          },
          action: {
            content: (
              <>
                {broadcast_target?.status ===
                  EnumBroadcastTargetStatus.PendingApproval && (
                  <Link
                    to={`/review-campaign/${campaign?._id}/${broadcast_target?._id}`}
                    tw="text-dark-blue-gray transition duration-200 space-x-1 hover:(text-purple cursor-pointer)"
                  >
                    <Icon icon="document-check" tw="text-base" />
                    <span>Review</span>
                  </Link>
                )}
              </>
            ),
          },
        }
      }),
    [data]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData)),
    [tableData]
  )

  return (
    <Table
      itemName="campaign"
      data={chronologicalData}
      emptyStateIllustration={
        <Illustration name={IllustrationEnum.campaigns_empty_results} />
      }
      layout="fluid"
      cols={[
        { key: 'name', label: 'Name', isSortable: true },
        { key: 'networkName', label: 'Network', isSortable: true },
        {
          key: 'status',
          label: 'Status',
          isSortable: true,
          alignment: 'center',
          width: 142,
        },
        {
          key: 'adGroups',
          label: 'Ad Groups',
          isSortable: true,
          alignment: 'right',
          width: 70,
        },
        {
          key: 'campaignStart',
          label: 'Start Date',
          isSortable: true,
          alignment: 'right',
          width: 130,
        },
        {
          key: 'campaignEnd',
          label: 'End Date',
          isSortable: true,
          alignment: 'right',
          width: 130,
        },
        { key: 'ownership', label: 'Workspace', isSortable: true },
        {
          key: 'submittedAt',
          label: 'Submitted On',
          isSortable: true,
          alignment: 'right',
          width: 150,
        },
        {
          key: 'action',
          alignment: 'right',
          width: 100,
        },
      ]}
      {...props}
    />
  )
}

export default ExternalCampaignsListTable
