import React, { FC, Fragment } from 'react'
import 'twin.macro'
import Button from '../../../../../ui-blocks/button'

export const PlanFeatures: FC<{
  activePlayers: number
}> = ({ activePlayers }) => {
  return (
    <Fragment>
      <p tw="font-normal text-xs text-charcoal leading-tight mb-1">
        All the outstanding functionality of the platform
      </p>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-4">
        30€ billed monthly
      </span>
      <Button menu type="button" tw="underline p-0 min-w-auto">
        Change plan
      </Button>
      <p tw="font-normal text-charcoal leading-tight mt-8 mb-1">
        Current active players: {activePlayers}
      </p>
    </Fragment>
  )
}
