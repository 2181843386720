import React, { FC, useState } from 'react'
import tw, { styled } from 'twin.macro'

import Text from '../../../../ui-blocks/text'
import Popover from '../../../../ui-blocks/popover'

import { ReactComponent as CircleInformationFilledIcon } from '../../../../assets/icons/circle_information_filled.svg'

export interface ArchivalReasonPopoverProps {
  reason: string
}

const ArchivalReasonPopover: FC<ArchivalReasonPopoverProps> = ({ reason }) => {
  const [isPopoverOpen, openPopover] = useState<boolean>(false)
  return (
    <div tw="relative">
      <ColoredCircleInformationFilledIcon onClick={() => openPopover(true)} />
      <Popover
        open={isPopoverOpen}
        cardPosition="-2.55rem"
        onClose={() => openPopover(false)}
      >
        <Text as="p" preset="h4" tw="mb-2">
          Why this player was archived:
        </Text>
        <span>{reason}</span>
      </Popover>
    </div>
  )
}

const ColoredCircleInformationFilledIcon = styled(CircleInformationFilledIcon)`
  ${tw`w-4 h-4 text-flickr-blue hover:cursor-pointer`}
  & circle {
    ${tw`fill-current`}
  }
`

export default ArchivalReasonPopover
