import React, { FC, Fragment } from 'react'
import tw from 'twin.macro'

import Text from '../../../../../../../../ui-blocks/text'
import Radio from '../../../../../../../../ui-blocks/radio'
import Textarea from '../../../../../../../../ui-blocks/textarea'

export const OPTIONS: { [key: string]: string } = {
  'not-using': 'I’m not using this player anymore',
  maintenance: 'This player will undergo maintenance',
  other: 'Other',
}

export interface ReasonStepProps {
  reason: string
  option: string
  onChangeReason?: (reason: string) => void
  onSelectOption?: (option: string) => void
}

const ReasonStep: FC<ReasonStepProps> = ({
  reason,
  option,
  onChangeReason,
  onSelectOption,
}) => (
  <Fragment>
    <Text as="h3" preset="h4" tw="mb-4">
      Why are you archiving the player?
    </Text>
    <Text as="h4" preset="p1" tw="text-dark-blue-gray mb-6">
      Check one option to proceed
    </Text>
    {Object.keys(OPTIONS).map((optionKey, index) => (
      <div
        key={`option#${index}`}
        css={[index !== Object.keys(OPTIONS).length - 1 ? tw`mb-3` : tw`mb-6`]}
      >
        <Radio
          name="archive-reason"
          value={optionKey}
          label={OPTIONS[optionKey]}
          checked={optionKey === option}
          onChange={(e) => onSelectOption?.(e.target.value)}
        />
      </div>
    ))}
    {option === 'other' && (
      <Fragment>
        <Textarea
          rows={4}
          value={reason}
          onChange={(e) => onChangeReason?.(e.target.value)}
        />
        <Text as="span" preset="p2" tw="mt-2">
          Add here the reason why you are archiving this player
        </Text>
      </Fragment>
    )}
  </Fragment>
)

export default ReasonStep
