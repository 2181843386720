import React, { FC, HTMLAttributes, DetailedHTMLProps } from 'react'
import tw, { styled } from 'twin.macro'

export interface SpinnerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  center?: boolean
  animated?: boolean
}

const Spinner: FC<SpinnerProps> = ({ center, ...props }) => {
  const content = <ThreeQuarterCircle {...props} />
  return center ? (
    <div tw="flex flex-1 items-center justify-center">{content}</div>
  ) : (
    content
  )
}

const ThreeQuarterCircle = styled.div<{ animated?: boolean }>`
  border-top-color: currentColor;
  border-left-color: currentColor;
  border-bottom-color: currentColor;
  border-right-color: transparent;
  ${tw`w-12 h-12 border-4 rounded-full text-platinum`}
  ${({ animated }) => (animated ? tw`animate-spin` : tw`transform -rotate-45`)}
`

Spinner.defaultProps = {
  center: false,
  animated: true,
}

export default Spinner
