import React, { FC, useMemo, useState } from 'react'

import Dialog from 'rc-dialog'
import tw, { styled } from 'twin.macro'

import Tooltip from '../../../../../ui-blocks/tooltip'

import TargetNetworksStep, {
  targetNetworksValidationSchema,
} from './steps/target-networks'
import ConfirmationStep from './steps/confirmation'

import RestoreModal from '../../restore-modal'

import SetupForm from '../../../../../components/setup-form'

import {
  EnumRemovedReason,
  useListNetworksQuery,
  useRestorePlayerMutation,
  PlayerListFragment,
  useCountNetworksQuery,
} from '../../../../../graphql/components'

import usePagination from '../../../../../utils/use-pagination'

import { ReactComponent as CircleInformationFilledIcon } from '../../../../../assets/icons/circle_information_filled.svg'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

export interface RestorePlayerFormikValues {
  networks_ids: string[]
  campaigns_ids: string[]
}

export interface RestorePlayerModalProps {
  open?: boolean
  player?: PlayerListFragment
  onClose?: () => void
  onCancel?: () => void
}

const RestorePlayerModal: FC<RestorePlayerModalProps> = ({
  open,
  player,
  onClose,
  onCancel,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const {
    data: networkCountData,
    loading: loadingNetworkCountData,
  } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchQuery },
    },
  })

  const networksCount = useMemo(() => networkCountData?.networks || 0, [
    networkCountData,
  ])
  const networksPagination = usePagination(networksCount, 9, searchQuery)

  const { data: networksData, loading: loadingNetworks } = useListNetworksQuery(
    {
      fetchPolicy: 'cache-first',
      variables: {
        limit: networksPagination.limit,
        offset: networksPagination.offset,
        filter: { search: searchQuery },
      },
    }
  )

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'restore-player-error-alert',
      message: "We couldn't restore this player. Please try again later.",
    })
  }

  const [
    restorePlayer,
    { loading: restoringPlayer },
  ] = useRestorePlayerMutation()
  const onRestorePlayer = async (values?: RestorePlayerFormikValues) => {
    try {
      const res = await restorePlayer({
        variables: {
          id: player?._id,
          network_ids: values?.networks_ids ?? [],
        },
      })

      if (!res.data?.isRestored) {
        handleError()
        return false
      }

      document.dispatchEvent(new CustomEvent('refetch-players'))
      document.dispatchEvent(new CustomEvent('refetch-archived-players'))
      onClose?.()
      return true
    } catch {
      handleError()
      return false
    }
  }

  const networks = useMemo(
    () =>
      (networksData?.networks || []).filter((network) => !network.is_external),
    [networksData]
  )

  const previousNetworksIds = useMemo(
    () =>
      networks
        .filter((network) =>
          network.players.find(
            (netPlayer) =>
              netPlayer?.player_id === player?._id &&
              netPlayer?.removed?.reason === EnumRemovedReason.Archived
          )
        )
        .map((network) => network._id),
    [player?._id, networks]
  )

  if (!previousNetworksIds.length) {
    return (
      <RestoreModal
        target="player"
        open={open}
        loading={restoringPlayer}
        itemName={player?.name || ''}
        onCancel={onCancel}
        onConfirm={onRestorePlayer}
      />
    )
  }

  return (
    <Dialog
      visible={open}
      closable={false}
      animation="zoom"
      maskAnimation="fade"
      onClose={onClose}
      focusTriggerAfterClose={false}
      style={{ width: '68rem', maxWidth: '100%' }}
      bodyStyle={{ padding: 0, fontFamily: 'Fira Sans' }}
    >
      <SetupForm
        onCancel={onCancel}
        onSubmit={{ 1: onRestorePlayer }}
        title={`Restore player "${player?.name || ''}"`}
        description="Add the player to your existing networks or campaigns."
        initialValues={{
          networks_ids: previousNetworksIds,
        }}
        extraAside={<SkipAssociationAction onClick={onRestorePlayer} />}
        steps={[
          {
            canGoBack: true,
            disabledOnSchema: true,
            title: 'Add player to networks',
            description: 'Assign the player to one or more networks.',
            validationSchema: targetNetworksValidationSchema,
            content: (
              <TargetNetworksStep
                loadingCount={loadingNetworkCountData}
                loadingNetworks={loadingNetworks}
                networksCount={networksCount}
                previousNetworksIds={previousNetworksIds}
                networks={networks}
                pagination={networksPagination}
                onSearch={setSearchQuery}
              />
            ),
          },
          {
            canGoBack: true,
            title: 'Confirm',
            description:
              'Please confirm the networks and campaigns you wish to assign the player to.',
            content: <ConfirmationStep networks={networks} />,
          },
        ]}
      />
    </Dialog>
  )
}

interface SkipAssociationActionProps {
  onClick?: () => void
}

const SkipAssociationAction: FC<SkipAssociationActionProps> = ({ onClick }) => (
  <div tw="flex flex-row items-center">
    <span
      onClick={onClick}
      tw="mr-2 font-medium leading-tight underline text-charcoal hover:cursor-pointer"
    >
      Skip assignment
    </span>
    <Tooltip content="Click here if you wish to restore the player without assigning it to any network or campaign.">
      <ColoredCircleInformationFilledIcon />
    </Tooltip>
  </div>
)

const ColoredCircleInformationFilledIcon = styled(CircleInformationFilledIcon)`
  ${tw`w-4 h-4 text-flickr-blue hover:cursor-pointer`}
  & circle {
    ${tw`fill-current`}
  }
`

export default RestorePlayerModal
