import React, { FC, ImgHTMLAttributes, DetailedHTMLProps } from 'react'

import { ReactComponent as NoWifiIllustration } from '../assets/illustrations/no_wifi.svg'

export enum IllustrationEnum {
  ad_groups_empty_results = 'ad_groups_empty_results',
  campaigns_empty_state = 'campaigns_empty_state',
  campaigns_empty_results = 'campaigns_empty_results',
  creatives_empty_state = 'creatives_empty_state',
  creatives_empty_results = 'creatives_empty_results',
  networks_empty_state = 'networks_empty_state',
  networks_empty_results = 'networks_empty_results',
  players_setup = 'players_setup',
  players_empty_state = 'players_empty_state',
  players_empty_results = 'players_empty_results',
  members_empty_results = 'members_empty_results',
  onboarding_in_progress = 'onboarding_in_progress',
  cloud_upload = 'cloud_upload',
  credit_card = 'credit_card',
  invoice = 'invoice',
  no_wifi = 'no_wifi',
  landscape = 'landscape',
  reverse_landscape = 'reverse_landscape',
  portrait = 'portrait',
  reverse_portrait = 'reverse_portrait',
}

export interface IllustrationProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  name: IllustrationEnum
}

const Illustration: FC<IllustrationProps> = ({ name, ...props }) =>
  name === IllustrationEnum.no_wifi ? (
    <NoWifiIllustration {...(props as any)} />
  ) : (
    <img
      src={require(`../assets/illustrations/${name}.svg`)}
      alt={`${name} illustration`}
      {...props}
    />
  )

export default Illustration
