import React, { FC, useMemo } from 'react'
import 'twin.macro'

import {
  Informations,
  InformationsSection,
} from '../../../../ui-blocks/information'
import Status from '../../../../ui-blocks/status'
import KPICard from '../../../../ui-blocks/kpi-card'

import InlineDate from '../../../../components/inline-date'

import {
  EnumNetworkStatus,
  NetworkFullFragment,
} from '../../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'

export interface OverviewSectionProps {
  network?: NetworkFullFragment
}

const OverviewSection: FC<OverviewSectionProps> = ({ network }) => {
  const intervals = useMemo(getKPIIntervals, [])
  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, network?.stats || []),
    [intervals, network]
  )

  const playersCount = useMemo(
    () =>
      network?.players.map((player) => player?.player).filter(Boolean).length ??
      0,
    [network]
  )
  return (
    <section>
      <Informations title="Network Information" tw="mb-14">
        <InformationsSection label="STATUS">
          <Status value={network?.status as EnumNetworkStatus} />
        </InformationsSection>
        <InformationsSection label="PLAYERS">
          {playersCount}
        </InformationsSection>
        <InformationsSection label="CREATED AT">
          <InlineDate date={network?.created?.at} />
        </InformationsSection>
      </Informations>
      <KPICard title="Network Analytics" intervals={stats} />
    </section>
  )
}

export default OverviewSection
