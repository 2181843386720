import React, { FC, useEffect, ReactNode, useMemo } from 'react'
import tw, { css } from 'twin.macro'

import ReactCodeInput from 'react-code-input'

import Text from './text'

import ErrorMessage from '../components/error-message'

export interface CodeInputProps {
  length: number
  id?: string
  name?: string
  value?: string
  error?: string
  success?: boolean
  disabled?: boolean
  autoFocus?: boolean
  placeholder?: string
  label?: string | ReactNode
  labelExtra?: string | ReactNode
  onChange?: (value: string) => void
}

const CodeInput: FC<CodeInputProps> = ({
  id,
  name,
  length,
  label,
  error,
  success,
  disabled,
  labelExtra,
  ...props
}) => {
  useEffect(() => {
    const inputContainers = document.getElementsByClassName('react-code-input')
    for (let i = 0; i < inputContainers.length; i++) {
      const container = inputContainers.item(i)
      if (!!container) {
        container.removeAttribute('style')
        const inputs = container.getElementsByTagName('input')
        for (let j = 0; j < inputs.length; j++) {
          inputs.item(j)?.removeAttribute('style')
        }
      }
    }
  }, [])

  const randomId = useMemo(
    () => `code-input-${Math.random().toString(36).substring(2, 9)}`,
    []
  )
  const inputId = id || randomId
  const inputName = name || randomId

  return (
    <div
      css={css`
        ${tw`max-w-max`}

        .react-code-input {
          ${tw`inline-block space-x-4`}

          & > input {
            font-size: 1.75rem;
            font-family: 'Fira Sans';

            ${tw`w-12 h-14 font-light text-charcoal! text-center bg-white! rounded border border-platinum transition duration-200 focus:outline-none focus:border-spanish-violet`}
            ${success && !disabled && tw`border-metallic-seaweed`}
            ${!!error && !disabled && tw`border-brick-red`}
            ${!!disabled && tw`opacity-75 cursor-not-allowed`}
            ${!disabled && tw`hover:border-light-peri`}
          }
        }
      `}
    >
      {label && (
        <Text
          as="label"
          tw="flex flex-row items-center justify-between pb-2"
          htmlFor={inputId}
        >
          {typeof label === 'string' ? (
            <Text as="span" preset="caption">
              {label}
            </Text>
          ) : (
            label
          )}
          {typeof labelExtra === 'string' ? (
            <Text as="span" preset="caption" transform="lowercase">
              {labelExtra}
            </Text>
          ) : (
            labelExtra
          )}
        </Text>
      )}
      <ReactCodeInput
        fields={length}
        name={inputName}
        disabled={disabled}
        inputMode="numeric"
        {...props}
      />
      {!!error && !disabled && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

export default CodeInput
