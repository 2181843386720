import React, { FC, Fragment, useMemo } from 'react'
import without from 'lodash/without'
import tw from 'twin.macro'
import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'
import Text from '../../../../../ui-blocks/text'
import Search from '../../../../../ui-blocks/search'
import Spinner from '../../../../../ui-blocks/spinner'
import Suspense from '../../../../../ui-blocks/suspense'
import PaginationNav from '../../../../../ui-blocks/pagination-nav'
import Grid from '../../../../../components/grid'
import CreativeSelectCard from '../../../../../components/creative/select-card'
import usePagination from '../../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../../utils/use-search'
import {
  OnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscriptionVariables,
  useCountCreativesQuery,
  useListCreativesQuery,
  useOnCreativesStatusChangeSubscription,
} from '../../../../../graphql/components'
import { CreateAdGroupFormikValues } from '..'
import useCallBackOnSubscriptionChange, {
  IUseCallBackOnSubscriptionChange,
} from '../../../../../utils/use-callback-on-subscription-change'

export const creativesValidationSchema = object().shape({
  creatives_ids: array(string()).min(1).required(),
})

const CreativesStep: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateAdGroupFormikValues>()

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const { data: creativesCountData } = useCountCreativesQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: filteredCreativesCountData,
    loading: loadingFilteredCreativesCount,
  } = useCountCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: {
        search: searchTextDebounced,
      },
    },
  })

  const creativesCount = useMemo(
    () => filteredCreativesCountData?.creatives || 0,
    [filteredCreativesCountData]
  )

  const totalCreativesCount = useMemo(
    () => creativesCountData?.creatives || 0,
    [creativesCountData]
  )
  const pagination = usePagination(creativesCount, 6, searchTextDebounced)

  const {
    data: creativesData,
    loading: loadingCreatives,
    refetch: refetchCreatives,
  } = useListCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      offset: pagination.offset,
      limit: pagination.limit,
      filter: { search: searchTextDebounced },
    },
  })

  const handleSelectCreative = (creativeId: string) => {
    if (!values.creatives_ids.includes(creativeId))
      return setFieldValue('creatives_ids', [
        ...values.creatives_ids,
        creativeId,
      ])
    setFieldValue('creatives_ids', without(values.creatives_ids, creativeId))
  }

  const creatives = useMemo(() => creativesData?.creatives || [], [
    creativesData,
  ])

  const allCreativesId = useMemo(
    () => creatives.map((creative) => creative._id),
    [creatives]
  )

  const subscriptionListener: IUseCallBackOnSubscriptionChange<
    OnCreativesStatusChangeSubscription,
    OnCreativesStatusChangeSubscriptionVariables
  > = useMemo(() => {
    return {
      callback: refetchCreatives,
      subscription: useOnCreativesStatusChangeSubscription,
      subscriptionOptions: {
        fetchPolicy: 'no-cache',
        skip: allCreativesId.length === 0,
        variables: {
          _ids: allCreativesId,
        },
      },
    }
  }, [allCreativesId, refetchCreatives])

  useCallBackOnSubscriptionChange(subscriptionListener)

  return (
    <Fragment>
      <div tw="flex flex-wrap items-center justify-between mb-6">
        <p tw="text-dark-blue-gray text-xs pt-3 uppercase">
          {loadingCreatives
            ? 'Loading...'
            : `${totalCreativesCount} Creatives, ${values.creatives_ids.length} Selected`}
        </p>
        <div tw="w-80">
          <Search
            value={searchText}
            loading={loadingCreatives && loadingFilteredCreativesCount}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense
        ready={!loadingCreatives && !loadingFilteredCreativesCount}
        fallback={<Spinner center />}
      >
        <Grid rows={3} cols={2} gap="1rem">
          {creatives?.map((creative) => (
            <CreativeSelectCard
              name="creatives_ids"
              creative={creative}
              value={creative._id}
              key={`creative-${creative._id}`}
              onClick={() => handleSelectCreative(creative._id)}
              checked={values.creatives_ids.includes(creative._id)}
            />
          ))}
        </Grid>
      </Suspense>
      <div tw="flex flex-row items-center justify-between space-x-8 mt-4">
        <Text
          as="span"
          preset="p2"
          css={[tw`underline`, 'text-decoration-style: dashed;']}
        >
          NOTE: At least one creative MUST be selected!
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </Fragment>
  )
}

export default CreativesStep
