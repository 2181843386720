import tw from 'twin.macro'
import React, { FC, useMemo, useState } from 'react'

import ReactMapGL, {
  Layer,
  Source,
  ViewState,
  ScaleControl,
  NavigationControl,
} from 'react-map-gl'

import {
  MAX_ZOOM_LEVEL,
  generateCirclePaint,
  generateHeatmapPaint,
} from './map-style'
import { ViewType, DataColors } from '..'

import {
  ViewCampaignAnalyticsQuery,
  CampaignAdGroupFullFragment,
} from '../../../../graphql/components'

import { MAPBOX_API_TOKEN } from '../../../../config'

export interface HeatmapData
  extends GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  id: string
}

export interface HeatmapViewProps {
  weight: ViewType
  colors: DataColors
  adGroups: CampaignAdGroupFullFragment[]
  analytics: ViewCampaignAnalyticsQuery['analytics']
}

const HeatmapView: FC<HeatmapViewProps> = ({
  weight,
  colors,
  adGroups,
  analytics,
}) => {
  const [viewState, setViewState] = useState<ViewState>({
    zoom: 0,
    latitude: 41.14,
    longitude: -8.68,
  })

  const heatmapData = useMemo(
    () =>
      adGroups.map((adGroup) => {
        const relatedAnalytics =
          analytics?.filter((analytic) => analytic?._id === adGroup._id) || []

        const valuesPerLocation = relatedAnalytics.reduce<
          Record<string, { scans: number; impressions: number }>
        >((acc, analytic) => {
          analytic?.locations?.forEach((location) => {
            const coords = JSON.stringify(
              location?.coordinates || [0, 5 * Math.random()]
            )
            if (!acc[coords]) {
              acc[coords] = {
                scans: location?.scans ?? 0,
                impressions: location?.impressions ?? 0,
              }
            } else {
              acc[coords].scans += location?.scans ?? 0
              acc[coords].impressions += location?.impressions ?? 0
            }
          })
          return acc
        }, {})

        return {
          id: adGroup._id,
          type: 'FeatureCollection',
          features: Object.keys(valuesPerLocation).map(
            (coords) =>
              ({
                type: 'Feature',
                properties: valuesPerLocation[coords],
                geometry: {
                  type: 'Point',
                  coordinates: JSON.parse(coords),
                },
              } as GeoJSON.Feature)
          ),
        } as HeatmapData
      }),
    [adGroups, analytics]
  )

  const maxValue = useMemo(
    () =>
      heatmapData.reduce((heatAcc, source) => {
        const maxWeight = source.features.reduce((featAcc, feature) => {
          const currWeight = feature.properties?.[weight] ?? 0
          return currWeight > featAcc ? currWeight : featAcc
        }, 0)
        return maxWeight > heatAcc ? maxWeight : heatAcc
      }, 0),
    [weight, heatmapData]
  )

  return (
    <div css={[tw`w-full`, 'height: 40rem;']}>
      <ReactMapGL
        width="100%"
        height="100%"
        attributionControl={false}
        mapboxApiAccessToken={MAPBOX_API_TOKEN}
        onViewportChange={setViewState}
        {...viewState}
      >
        {heatmapData.map(({ id, ...source }, index) => {
          if ((source.features || []).length === 0) {
            return null
          }

          return (
            <Source
              data={source}
              type="geojson"
              id={`heatmap#${index}`}
              key={`source#${index}`}
            >
              <Layer
                type="heatmap"
                id={`heat-layer#${index}`}
                source={`heatmap#${index}`}
                maxzoom={MAX_ZOOM_LEVEL}
                paint={generateHeatmapPaint(
                  weight,
                  colors[id]?.hex || '#000',
                  maxValue
                )}
              />
              <Layer
                type="circle"
                id={`circle-layer#${index}`}
                source={`heatmap#${index}`}
                minzoom={MAX_ZOOM_LEVEL - 1}
                paint={generateCirclePaint(
                  weight,
                  colors[id]?.hex || '#000',
                  maxValue
                )}
              />
            </Source>
          )
        })}
        <div tw="top-0 right-0 absolute m-4">
          <NavigationControl captureClick showCompass={false} />
        </div>
        <div tw="right-0 bottom-0 absolute">
          <ScaleControl />
        </div>
      </ReactMapGL>
    </div>
  )
}

export default HeatmapView
