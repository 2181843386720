import React, { FC } from 'react'

import 'twin.macro'
import moment from 'moment'

import Icon from '../../ui-blocks/icon'
import Text from '../../ui-blocks/text'
import Card from '../../ui-blocks/card'

import { Player } from '../../graphql/components'

export interface PendingPlayerProps {
  player: Pick<Player, '_id' | 'name' | 'geographics' | 'created'>
  onEdit?: () => void
}

const PendingPlayer: FC<PendingPlayerProps> = ({ player, onEdit }) => {
  return (
    <Card tw="p-4 min-h-24 min-w-80">
      <Text as="p" preset="h4" tw="mb-1">
        {player.name || 'No name'}
      </Text>
      <div tw="flex flex-row items-center justify-between mt-4">
        <Text as="span" preset="p2">
          {`Connected on ${moment(player.created?.at).format('LLL')}`}
        </Text>
        {!!onEdit && (
          <div
            className="group"
            tw="flex flex-row items-center hover:cursor-pointer"
            onClick={onEdit}
          >
            <Icon
              icon="edit"
              tw="text-lg text-dark-blue-gray transition duration-200 group-hover:text-purple"
            />
            <Text
              as="span"
              preset="p1"
              tw="ml-1 text-dark-blue-gray transition duration-200 group-hover:text-purple"
            >
              Edit
            </Text>
          </div>
        )}
      </div>
    </Card>
  )
}

export default PendingPlayer
