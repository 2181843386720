import React, { FC, useEffect, useRef, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import tw, { css } from 'twin.macro'
import MapGL, {
  Marker,
  ViewState,
  ScaleControl,
  GeolocateControl,
  NavigationControl,
  WebMercatorViewport,
  PointerEvent,
} from 'react-map-gl'

import bbox from '@turf/bbox'
import { Position, lineString } from '@turf/helpers'

import Spacer from './spacer'

import ErrorMessage from '../components/error-message'

import { IMapPoint } from '../typings'

import { MAPBOX_API_TOKEN } from '../config'

const DEFAULT_MAP_HEIGHT = '31.25rem'
const DEFAULT_MAP_BOUNDS_PADDING = 100

export interface MapProps {
  points: IMapPoint[]
  error?: string
  height?: string | number
  averagePoints?: boolean
  onSetPoint?: (point: IMapPoint) => void
}

const Map: FC<MapProps> = ({
  error,
  height,
  points,
  averagePoints,
  onSetPoint,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [viewState, setViewState] = useState<ViewState>({
    zoom: 0,
    latitude: 0,
    longitude: 0,
  })

  useEffect(() => {
    if (!!averagePoints && !onSetPoint && points.length >= 2) {
      const positions = points.map(
        (point) => [point.lng, point.lat] as Position
      )

      const line = lineString(positions)
      const [minLng, minLat, maxLng, maxLat] = bbox(line)
      const view = new WebMercatorViewport({
        ...viewState,
        pitch: 0,
        bearing: 0,
        width: wrapperRef.current?.offsetWidth || 0,
        height: wrapperRef.current?.offsetHeight || 0,
      })

      const bounds = view.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: DEFAULT_MAP_BOUNDS_PADDING }
      )

      setViewState({
        ...viewState,
        zoom: bounds.zoom,
        longitude: bounds.longitude,
        latitude: bounds.latitude,
      })
    }
  }, [points, averagePoints])

  const handleClick = (e: PointerEvent) => {
    if (e.target.className === 'overlays')
      onSetPoint?.({ lng: e.lngLat[0], lat: e.lngLat[1] })
  }

  return (
    <div tw="w-full" ref={wrapperRef}>
      <div
        css={css`
          ${tw`w-full border rounded border-platinum`}
          .mapboxgl-ctrl-bottom-left {
            z-index: unset;
          }
        `}
      >
        <MapGL
          width="100%"
          attributionControl={false}
          height={height || DEFAULT_MAP_HEIGHT}
          mapboxApiAccessToken={MAPBOX_API_TOKEN}
          onViewportChange={setViewState}
          onClick={handleClick}
          {...viewState}
        >
          {points.map((point, index) => (
            <MapMarker key={`marker#${index}`} point={point} />
          ))}
          <div tw="absolute right-0 top-0 mr-2 mt-2">
            <GeolocateControl
              showUserLocation={false}
              onGeolocate={(options: any) => {
                onSetPoint?.({
                  lng: options.coords.longitude,
                  lat: options.coords.latitude,
                })
              }}
            />
            <Spacer size="0.5rem" />
            <NavigationControl captureClick showCompass={false} />
          </div>
          <div tw="absolute right-0 bottom-0">
            <ScaleControl captureClick />
          </div>
        </MapGL>
      </div>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

export interface MapMarkerProps {
  point: IMapPoint
}

export const MapMarker: FC<MapMarkerProps> = ({ point }) => (
  <Marker
    longitude={point.lng}
    latitude={point.lat}
    offsetLeft={-12}
    offsetTop={-40}
  >
    <div title={point.name} tw="flex flex-col items-center">
      <div tw="w-6 h-6 bg-purple rounded-full" />
      <div tw="w-1 h-4 bg-purple rounded-b" />
    </div>
  </Marker>
)

export default Map
