import * as yup from 'yup'
import { ITimetable, ITimetableRule, Weekday } from './types'

export const weekdaySchema = yup.number().min(0).max(6)

export const clockSchema = yup
  .string()
  .required('You must define a valid time')
  .matches(
    /^([0-1][0-9]|2[0-4]):[0-5]\d$/,
    'Please insert a time format between 00:00 and 23:59 '
  )
  .default('')

export const timetableRuleSchema = yup
  .object()
  .shape<ITimetableRule>({
    start: clockSchema,
    end: clockSchema,
    weekdays: yup
      .array()
      .of(weekdaySchema)
      .required('You must select at least one week day')
      .default([])
      .test('unique', 'The weekdays must be unique', (context: Weekday[]) => {
        if (!context) {
          return false
        }
        // ensure there are no repeated values
        for (let i = 0; i < context.length; i++) {
          for (let j = i + 1; j < context.length; j++) {
            if (context[i] === context[j]) {
              return false
            }
          }
        }

        // and that we have at least one weekday set
        return context.length > 0
      }),
    allowed: yup.boolean().required().default(true),
  })
  .test(
    'no same start and end',
    'Start and end time should be different',
    function (timetableRule: ITimetableRule) {
      if (!timetableRule || !timetableRule.start || !timetableRule.end) {
        return false
      }

      if (timetableRule.start === timetableRule.end) {
        return false
      }
      return true
    }
  )

export const timetableSchema = yup
  .object()
  .shape<ITimetable>({
    rules: yup.array().of(timetableRuleSchema).default([]),
    defaultAllowance: yup.boolean().required().default(true),
  })
  .test(
    'must have a rule if default allowance is false',
    'You must add at least one rule if you want to assign a specific activity time',
    (context: ITimetable) => {
      if (context.defaultAllowance) {
        return true
      }
      if (!context || !context.rules) {
        return false
      }

      return context.rules.length !== 0
    }
  )
