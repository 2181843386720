import React, { FC, useEffect, useMemo, useState } from 'react'

import tw from 'twin.macro'
import { useNavigate } from 'react-router'

import { ObjectSchema } from 'yup'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'

import Text from '../../ui-blocks/text'
import Link from '../../ui-blocks/link'
import Card from '../../ui-blocks/card'
import Button from '../../ui-blocks/button'

import StepSection from './components/step-section'
import StepIndicator from './components/step-indicator'

export interface Step {
  title: string
  content: React.ReactNode
  disabledOnSchema?: boolean
  validationSchema?: ObjectSchema
  description?: string | React.ReactNode
  disabledTriggers?: string[]
  subSteps?: string[]
  canGoBack: boolean
}

export interface SetupFormProps {
  title: string
  steps: Step[]
  initialValues: FormikValues
  initialStepIndex?: number
  extraAside?: React.ReactNode
  description?: string | React.ReactNode
  onSubmit?: { [key: number]: (values: any) => Promise<boolean> }
  onCancel?: () => void
}

const SetupForm: FC<SetupFormProps> = ({
  title,
  steps,
  description,
  initialValues,
  initialStepIndex,
  extraAside,
  onSubmit,
  onCancel,
}) => {
  const navigate = useNavigate()

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    initialStepIndex ?? 0
  )
  useEffect(() => setCurrentStepIndex(initialStepIndex ?? 0), [
    initialStepIndex,
  ])

  const handleClickBack = () => {
    if (currentStepIndex === 0) return !!onCancel ? onCancel() : navigate(-1)
    setCurrentStepIndex(currentStepIndex - 1)
  }

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    let canProceed = true
    if (!!onSubmit?.[currentStepIndex]) {
      canProceed = await onSubmit[currentStepIndex](values)
    }

    if (canProceed && currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1)
      helpers.setSubmitting(false)
      helpers.setTouched({})
    }
  }

  const currentStep = useMemo(() => steps[currentStepIndex], [
    steps,
    currentStepIndex,
    steps[currentStepIndex]?.disabledTriggers,
  ])
  const isSubmitDisabled = (values: FormikValues) =>
    !!currentStep.disabledTriggers?.some((trigger) =>
      Array.isArray(values[trigger])
        ? !values[trigger].length
        : !values[trigger]
    )

  return (
    <Card tw="h-full flex flex-row">
      <aside
        css={[
          'flex-basis: 19rem;',
          tw`flex flex-col justify-between flex-grow-0 flex-shrink-0 px-8 py-10 bg-lighter-blue`,
        ]}
      >
        <div tw="w-full">
          <Text as="h1" preset="h3" tw="mb-2">
            {title}
          </Text>
          {typeof description === 'string' ? (
            <Text as="h2" preset="p1" tw="text-dark-blue-gray mb-14">
              {description}
            </Text>
          ) : (
            description
          )}
          {steps.map((step, index) => {
            // we will determine if we can navigate by checking if this is a past step
            // and that all the steps up until the currentStepIndex can go back
            const canNavigate =
              index <= currentStepIndex &&
              steps.slice(index, currentStepIndex + 1).every((s) => s.canGoBack)

            return (
              <StepIndicator
                key={`indicator#${index}`}
                index={index + 1}
                label={step.title}
                subSteps={step.subSteps}
                isLast={index === steps.length - 1}
                state={
                  (index < currentStepIndex && 'done') ||
                  (index === currentStepIndex && 'current') ||
                  'todo'
                }
                onClick={
                  (canNavigate &&
                    (() => {
                      setCurrentStepIndex(index)
                    })) ||
                  undefined
                }
              />
            )
          })}
        </div>
        {!!extraAside && <div tw="mt-20">{extraAside}</div>}
      </aside>
      {!!currentStep && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={currentStep.validationSchema}
        >
          {({ values, isSubmitting, isValidating }) => (
            <Form tw="px-8 py-10 flex flex-col flex-grow justify-between">
              <StepSection
                index={currentStepIndex + 1}
                title={currentStep.title}
                content={currentStep.content}
                description={currentStep.description}
              />
              <div tw="flex flex-row items-center justify-between mt-10">
                <Link
                  onClick={handleClickBack}
                  disabled={isSubmitting || !currentStep.canGoBack}
                  tw="p-0 inline-flex min-w-auto"
                >
                  {currentStepIndex === 0 ? 'Cancel' : 'Back'}
                </Link>
                <Button
                  type="submit"
                  loading={isValidating || isSubmitting}
                  disabled={
                    !currentStep.disabledOnSchema &&
                    !!currentStep.validationSchema &&
                    isSubmitDisabled(values)
                  }
                >
                  {currentStepIndex === steps.length - 1 ? 'Done' : 'Next'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  )
}

export default SetupForm
