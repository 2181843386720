import React, { FC, useState } from 'react'

import tw, { styled } from 'twin.macro'
import Collapsible from 'react-collapsible'

import Icon from '../../../../../ui-blocks/icon'
import Text from '../../../../../ui-blocks/text'

import { ReactComponent as CircleCheckFilledIcon } from '../../../../../assets/icons/circle_check_filled.svg'

export interface ReviewCardProps {
  title: string
  count?: number
  onReview?: (isReviewed: boolean) => void
}

const ReviewCard: FC<ReviewCardProps> = ({
  title,
  count,
  onReview,
  children,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [isReviewed, setIsReviewed] = useState<boolean>(false)

  const handleMarkAsReviewed = () => {
    onReview?.(!isReviewed)
    setIsExpanded(isReviewed)
    setIsReviewed(!isReviewed)
  }

  return (
    <section tw="w-full bg-white border border-platinum rounded" {...props}>
      <div
        css={[
          tw`flex flex-row items-center justify-between w-full px-6 py-4`,
          isExpanded && tw`border-b border-platinum-50`,
        ]}
      >
        <div tw="flex flex-row items-center">
          <Icon
            icon="chevron-down"
            css={[
              tw`text-base transition duration-200 transform text-charcoal`,
              isExpanded ? tw`rotate-180` : tw`rotate-0`,
              !!count ? tw`mr-2` : tw`mr-14`,
            ]}
          />
          {!!count && (
            <Text as="h5" preset="p1" weight="bold" tw="mr-10">
              {count}
            </Text>
          )}
          <Text as="h3" preset="p1">
            {title}
          </Text>
        </div>
        <div
          tw="flex flex-row items-center space-x-2 hover:cursor-pointer"
          onClick={handleMarkAsReviewed}
        >
          {isReviewed ? (
            <ColoredCircleCheckFilledIcon />
          ) : (
            <Icon icon="circle" tw="text-base" />
          )}
          <Text as="span" preset="p1" tw="select-none">
            Mark as reviewed
          </Text>
        </div>
      </div>
      <Collapsible
        open={isExpanded}
        transitionTime={350}
        trigger={<React.Fragment />}
      >
        <div tw="py-8 px-24">{children}</div>
      </Collapsible>
    </section>
  )
}

const ColoredCircleCheckFilledIcon = styled(CircleCheckFilledIcon)`
  ${tw`w-4 h-4 text-charcoal`}
  & circle {
    ${tw`fill-current`}
  }
`

export default ReviewCard
