import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'

import moment from 'moment'
import { Chart, Legend, Tooltip, LineAdvance } from 'bizcharts'

import { DataColors } from '..'

import Text from '../../../../ui-blocks/text'
import ColoredSquare from '../../../../ui-blocks/colored-square'

import { ViewCampaignAnalyticsQuery } from '../../../../graphql/components'

export interface ChartData {
  date: string
  scans: number
  impressions: number
  adGroupId: string
}

export interface ChartViewProps {
  yAxis: string
  colors: DataColors
  analytics: ViewCampaignAnalyticsQuery['analytics']
}

const ChartView: FC<ChartViewProps> = ({ yAxis, colors, analytics }) => {
  const chartData = useMemo(
    () =>
      analytics?.map((analytic) => ({
        adGroupId: analytic?._id,
        date: moment(analytic?.date).utc().format('MMM Do'),
        scans: _.sum(
          analytic?.locations?.map((location) => location?.scans ?? 0)
        ),
        impressions: _.sum(
          analytic?.locations?.map((location) => location?.impressions ?? 0)
        ),
      })) as ChartData[],
    [analytics]
  )

  return (
    <div tw="px-8 pb-8">
      <Chart autoFit height={608} data={chartData}>
        <Legend visible={false} />
        <LineAdvance
          area
          type="smooth"
          position={`date*${yAxis}`}
          color={['adGroupId', (adGroupId) => colors[adGroupId]?.hex || '#000']}
        />
        <Tooltip shared showMarkers>
          {(title, items) => (
            <div tw="py-2">
              <Text as="p" preset="h4" tw="text-xs mb-2">
                {title}
              </Text>
              {!!items && (
                <table>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={`item#${index}`}>
                        <td tw="align-middle">
                          <ColoredSquare
                            size="0.5rem"
                            color={colors[item.name]?.hex || '#000'}
                            tw="rounded-sm mr-1"
                          />
                        </td>
                        <td>{`${colors[item.name].name}:`}</td>
                        <td tw="text-right">{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Tooltip>
      </Chart>
    </div>
  )
}

export default ChartView
