import React, { FC, useMemo } from 'react'
import tw from 'twin.macro'

import Text from '../../../../../ui-blocks/text'

export interface ProgressBarProps {
  count: number
  total: number
}

const ProgressBar: FC<ProgressBarProps> = ({ count, total }) => {
  const barWidth = useMemo(() => (total > 0 ? (count * 100) / total : 0), [
    count,
    total,
  ])
  return (
    <div css={['min-width: 6.875rem;', 'margin-bottom: 0.1875rem;']}>
      <Text as="p" preset="h5" tw="mb-1">
        {`${count}/${total} Sections viewed`}
      </Text>
      <div tw="w-full relative">
        <hr
          css={[
            'height: 0.1875rem;',
            tw`bg-platinum border-none rounded-full absolute left-0 top-0 w-full`,
          ]}
        />
        <hr
          css={[
            'height: 0.1875rem;',
            `width: ${barWidth}%;`,
            tw`bg-purple border-none rounded-full transition-all duration-200 absolute left-0 top-0`,
          ]}
        />
      </div>
    </div>
  )
}

export default ProgressBar
