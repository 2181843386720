import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Pill from '../../../../ui-blocks/pill'

import useElementStates from '../../../../utils/use-element-states'

const PillSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const {
    states,
    values: { dismissable, ...values },
  } = useElementStates(['disabled', 'dismissable'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <Pill
        text="Pill"
        onDelete={
          dismissable ? () => console.log('clicked pill...') : undefined
        }
        {...values}
      />
    </ElementSection>
  )
}

export default PillSection
