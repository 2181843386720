import React, { FC, Fragment, useMemo } from 'react'

import _ from 'lodash'
import tw from 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import Text from '../../../../ui-blocks/text'
import Search from '../../../../ui-blocks/search'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import Grid from '../../../../components/grid'
import PlayerSelectCard from '../../../../components/player/select-card'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

import {
  useCountPlayersQuery,
  useListPlayersQuery,
} from '../../../../graphql/components'

import { CreateNetworkFormikValues } from '..'

export const targetPlayersValidationSchema = object().shape({
  players_ids: array(string()).min(1).required(),
})

const TargetPlayersStep: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateNetworkFormikValues>()

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const { data: countData, loading: loadingCount } = useCountPlayersQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: filteredCountData,
    loading: loadingFilteredCount,
  } = useCountPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const playerCount = useMemo(() => filteredCountData?.players || 0, [
    filteredCountData,
  ])

  const totalPlayerCount = useMemo(() => countData?.players || 0, [countData])

  const pagination = usePagination(playerCount, 6, searchTextDebounced)
  const { data: playersData, loading: loadingPlayers } = useListPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const handleSelectPlayer = (playerId: string) => {
    if (!values.players_ids.includes(playerId))
      return setFieldValue('players_ids', [...values.players_ids, playerId])
    setFieldValue('players_ids', _.without(values.players_ids, playerId))
  }

  const players = useMemo(() => playersData?.players || [], [playersData])
  return (
    <Fragment>
      <div tw="flex flex-wrap items-center justify-between mb-6">
        <p tw="text-dark-blue-gray text-xs uppercase">
          {loadingCount
            ? 'Loading...'
            : `${totalPlayerCount} Players, ${values.players_ids.length} Selected`}
        </p>
        <div style={{ width: 304 }}>
          <Search
            value={searchText}
            loading={loadingPlayers && loadingFilteredCount}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense
        ready={!loadingPlayers && !loadingFilteredCount}
        fallback={<Spinner center />}
      >
        <Grid rows={3} cols={2} gap="1rem">
          {players?.map((player) => (
            <PlayerSelectCard
              name="players_ids"
              player={player}
              value={player._id}
              key={`player-${player._id}`}
              onClick={() => handleSelectPlayer(player._id)}
              checked={values.players_ids.includes(player._id)}
            />
          ))}
        </Grid>
      </Suspense>
      <div tw="flex flex-row items-center justify-between space-x-8 mt-4">
        <Text
          as="span"
          preset="p2"
          css={[tw`underline`, 'text-decoration-style: dashed;']}
        >
          NOTE: At least one player MUST be selected!
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </Fragment>
  )
}

export default TargetPlayersStep
