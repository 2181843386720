import React, { FC } from 'react'
import 'twin.macro'

import InlineDate from '../../../../../components/inline-date'
import NotDefined from '../../../../../components/not-defined'

import { BroadcastTarget } from '../../../../../graphql/components'

export type PartialBroadcastTarget = Pick<
  BroadcastTarget,
  'submitted' | 'submittedBy'
>

export interface SubmittedByProps {
  broadcastTarget?: PartialBroadcastTarget
}

const SubmittedBy: FC<SubmittedByProps> = ({ broadcastTarget, ...props }) =>
  !!broadcastTarget ? (
    <div tw="flex flex-col items-end" {...props}>
      <InlineDate date={broadcastTarget.submitted?.at} />
      {!!broadcastTarget.submittedBy && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
          by {broadcastTarget.submittedBy}
        </span>
      )}
    </div>
  ) : (
    <NotDefined />
  )

export default SubmittedBy
