import { hot } from 'react-hot-loader/root'
import React, { FC } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { Provider as MobXProvider } from 'mobx-react'
import { GlobalStyles } from 'twin.macro'
import Router from './router'
import apolloClient from './graphql/client'
import * as stores from './stores'
import { Elements } from '@stripe/react-stripe-js'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import stripe from './stripe'
import { reCaptchaSiteKey } from './config'

const App: FC<{}> = () => (
  <ApolloProvider client={apolloClient}>
    <MobXProvider {...stores}>
      <GlobalStyles />
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
        <Elements stripe={stripe}>
          <Router />
        </Elements>
      </GoogleReCaptchaProvider>
    </MobXProvider>
  </ApolloProvider>
)

export default process.env.NODE_ENV === 'development' ? hot(App) : App
