import React, { FC, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import { string, object, mixed } from 'yup'

import Icon from '../../../ui-blocks/icon'
import Link from '../../../ui-blocks/link'
import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import {
  EnumWorkspacePlatform_Type,
  useSetupWorkspaceMutation,
} from '../../../graphql/components'

import { authentication } from '../../../stores'
import { trimValues } from '../../../utils/data-manipulation'
import CardResourceRadio from '../../../ui-blocks/card-resource-radio'
import { CardResourceProps } from '../../../ui-blocks/card-resource'
import { ReactComponent as BillboardIcon } from '../../../assets/icons/billboard.svg'
import { ReactComponent as IndoorIcon } from '../../../assets/icons/indoor_audience.svg'

interface CreateWorkspaceFormikValues {
  name: string
  billing_email: string
  platform_type: EnumWorkspacePlatform_Type
}

const getDescriptionForPlatformType = (
  platformType: EnumWorkspacePlatform_Type
) => {
  switch (platformType) {
    case EnumWorkspacePlatform_Type.Advertiser:
      return 'We only plan to set up campaigns'
    case EnumWorkspacePlatform_Type.ContentCreator:
      return 'Influencers, Videographers, Artists'
    case EnumWorkspacePlatform_Type.Enterprise:
      return 'We own Digital Signage Displays'
    case EnumWorkspacePlatform_Type.Venue:
      return 'Advertising Places, Locations'
    default:
      return ''
  }
}

const convertEnumCaseToReadableCase = (
  platformType: EnumWorkspacePlatform_Type
) => {
  return platformType
    .split('_')
    .map(
      (uppercaseWord) =>
        uppercaseWord.charAt(0).toLocaleUpperCase() +
        uppercaseWord.slice(1).toLocaleLowerCase()
    )
    .join(' ')
}

// TODO: Implement the other icons when needed
const getIconForPlatformType = (platformType: EnumWorkspacePlatform_Type) => {
  switch (platformType) {
    case EnumWorkspacePlatform_Type.Advertiser:
      return (
        <IndoorIcon
          width={24}
          height={24}
          title={convertEnumCaseToReadableCase(platformType)}
        />
      )
    case EnumWorkspacePlatform_Type.ContentCreator:
      return undefined
    case EnumWorkspacePlatform_Type.Enterprise:
      return (
        <BillboardIcon
          width={24}
          height={24}
          title={convertEnumCaseToReadableCase(platformType)}
        />
      )
    case EnumWorkspacePlatform_Type.Venue:
      return undefined
    default:
      return undefined
  }
}

const createWorkspaceValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  billing_email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Billing email is required')
    .strict(true),
  platform_type: mixed<EnumWorkspacePlatform_Type>()
    /* TODO: Change this out for Object.values(EnumWorkspacePlatform_Type) when we move to phase 2 */
    .oneOf([
      EnumWorkspacePlatform_Type.Advertiser,
      EnumWorkspacePlatform_Type.Enterprise,
    ])
    .required('Platform type is required')
    .strict(true),
})

const AuthSetupWorkspacePage: FC = () => {
  const navigate = useNavigate()

  const [loggingOut, setLoggingOut] = useState<boolean>(false)
  const [setupError, setSetupError] = useState<boolean>(false)

  /* TODO: Change this out for
  Object.values(
    EnumWorkspacePlatform_Type
  )
  when we move to phase 2
  */
  const platformTypes: CardResourceProps[] = [
    EnumWorkspacePlatform_Type.Advertiser,
    EnumWorkspacePlatform_Type.Enterprise,
  ].map((value, index) => {
    return {
      value: value,
      title: convertEnumCaseToReadableCase(value),
      name: `card-resource-#${index}`,
      description: getDescriptionForPlatformType(value),
      icon: getIconForPlatformType(value),
    }
  })

  const [setupWorkspace] = useSetupWorkspaceMutation()
  const formik = useFormik<CreateWorkspaceFormikValues>({
    validateOnChange: false,
    validationSchema: createWorkspaceValidationSchema,
    initialValues: {
      name: '',
      billing_email: authentication.user?.emails[0]?.address || '',
      platform_type: {} as EnumWorkspacePlatform_Type,
    },
    async onSubmit(values) {
      setSetupError(false)
      const newValues = trimValues(values, ['name'])

      try {
        const res = await setupWorkspace({ variables: newValues })
        if ((res.errors || []).length > 0) return setSetupError(true)

        await authentication.selectWorkspace(res.data?.workspace?._id)
        await authentication.loadUserData()
        navigate(`/workspaces/${res.data?.workspace?._id}/overview`)
      } catch {
        setSetupError(true)
      }
    },
  })

  const setPlatformType = (platformType: string) => {
    const enumValue: EnumWorkspacePlatform_Type | undefined = Object.values(
      EnumWorkspacePlatform_Type
    ).find((value) => value.toString() === platformType)
    formik.setFieldValue('platform_type', enumValue)
  }

  const logout = async () => {
    setLoggingOut(true)
    await authentication.logout()
    setLoggingOut(false)
    navigate('/auth/sign-in')
  }

  const workspaces = authentication?.user_data?.workspaces ?? []
  const userHasWorkspaces = workspaces.length > 0

  return (
    <section>
      {userHasWorkspaces && (
        <div tw="flex justify-start mb-2">
          <Link
            tw="inline-flex p-0"
            onClick={() => navigate(-1)}
            iconLeft={<Icon icon="chevron-left" tw="text-base" />}
          >
            Back
          </Link>
        </div>
      )}

      <h1 tw="text-charcoal font-light leading-tight text-4xl self-start">
        Create your workspace
      </h1>
      <p tw="text-charcoal m-0 mb-10">
        Create a workspace that best suits your needs.
      </p>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        {setupError && (
          <div tw="mb-4">
            <AlertMessage
              alert={{
                variant: AlertVariant.ERROR,
                id: 'create-workspace-error-alert',
                message:
                  "We couldn't create this workspace. Please try again later.",
              }}
            />
          </div>
        )}

        <label tw="block mb-2 text-charcoal font-medium text-sm tracking-wider">
          Name
        </label>
        <InputFormik
          type="text"
          name="name"
          placeholder="ACME, ltd"
          label="You can use your name (best suited for solo) or the name of your company."
          formik={formik}
        />
        <label tw="block mt-8 mb-2 text-charcoal font-medium text-sm tracking-wider">
          Billing Email
        </label>
        <InputFormik
          type="email"
          name="billing_email"
          label="The email you want to see printed on your invoices."
          formik={formik}
        />
        <label tw="block mt-8 mb-2 text-charcoal font-medium text-sm tracking-wider">
          Organization Type
        </label>
        <CardResourceRadio
          cards={platformTypes}
          value={formik.values.platform_type}
          onChange={setPlatformType}
        />

        <Button
          primary
          tw="mt-6"
          type="submit"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          {userHasWorkspaces ? 'Create Workspace' : 'Continue'}
        </Button>
      </form>
      <p tw="text-charcoal mt-10 mb-5">
        You are logged in as <b>{authentication.user?.emails[0]?.address}</b>.
        If you don’t wish to set up a new account, you can sign in with other
        email.
      </p>

      {!userHasWorkspaces && (
        <div tw="flex justify-start">
          <Link onClick={logout} disabled={loggingOut} tw="inline-flex p-0">
            {loggingOut ? 'Signing out...' : 'Sign out'}
          </Link>
        </div>
      )}
    </section>
  )
}

export default AuthSetupWorkspacePage
