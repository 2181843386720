import React, { FC } from 'react'
import 'twin.macro'

import EditAdGroupForm from './components/edit-ad-group-form'
import ArchiveAdGroupForm from './components/archive-ad-group-form'

import SettingsPanel from '../../../../../../components/settings-panel'

import { CampaignAdGroupFullFragment } from '../../../../../../graphql/components'

export interface SettingsSectionProps {
  campaignAdGroup?: CampaignAdGroupFullFragment
  refetchCampaignAdGroup?: () => Promise<any>
}

const SettingsSection: FC<SettingsSectionProps> = ({
  campaignAdGroup,
  refetchCampaignAdGroup,
}) => (
  <section>
    <SettingsPanel
      sections={[
        {
          title: 'Details',
          content: (
            <EditAdGroupForm
              campaignAdGroup={campaignAdGroup}
              refetchCampaignAdGroup={refetchCampaignAdGroup}
            />
          ),
        },
        {
          title: 'Archive',
          content: <ArchiveAdGroupForm campaignAdGroup={campaignAdGroup} />,
        },
      ]}
    />
  </section>
)

export default SettingsSection
