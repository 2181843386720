import React, { FC, Ref, useMemo } from 'react'
import 'twin.macro'
import Text from './text'
import ErrorMessage from '../components/error-message'
import type { IFormikObject } from '../typings'

type InputBaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export interface SliderProps extends Omit<InputBaseProps, 'placeholder'> {
  inputRef?: Ref<HTMLInputElement>
  id?: string
  truncate?: boolean
  initialValue?: string
  hideSteppers?: boolean
  disabled?: boolean
  placeholder?: string
  label?: string | React.ReactNode
  labelExtra?: string | React.ReactNode
  leftLabel?: string
  helperText?: string
  // success?: boolean
  error?: string
  required?: boolean
  ghost?: boolean
}

const Slider: FC<SliderProps> = ({
  id,
  inputRef,
  initialValue,
  label,
  error,
  required,
  disabled,
  // ghost,
  ...props
}) => {
  const inputId = useMemo(
    () => id || `input-${Math.random().toString(36).substring(2, 9)}`,
    [id]
  )

  return (
    <div tw="w-full">
      {label && (
        <Text as="label" preset="caption" tw="pb-2" htmlFor={inputId}>
          {label} {!!required && '*'}
        </Text>
      )}

      <input
        required={required}
        type="range"
        id={inputId}
        ref={inputRef}
        defaultValue={initialValue}
        disabled={disabled}
        tw="rounded-lg appearance-none  bg-cultured h-3 w-full"
        css={`
          // &::-webkit-slider-thumb {
          //   width: 15px;
          //   -webkit-appearance: none;
          //   appearance: none;
          //   height: 15px;
          //   cursor: ew-resize;
          //   background: #2e384d;
          //   // box-shadow: -405px 0 0 400px #6d0fb1;
          //   border-radius: 50%;
          // }

          & {
            -webkit-appearance: none;
            margin: 18px 0;
            width: 100%;
          }
          &:focus {
            outline: none;
          }
          &::-webkit-slider-runnable-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            background: #8a3fc1;
            border-radius: 1.3px;
            border: 0.2px solid #010101;
          }
          &::-webkit-slider-thumb {
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            border: 1px solid #000000;
            height: 36px;
            width: 16px;
            border-radius: 3px;
            background: #ffffff;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -14px;
          }
          &:focus::-webkit-slider-runnable-track {
            background: #572779;
          }
          &::-moz-range-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            background: #8a3fc1;
            border-radius: 1.3px;
            border: 0.2px solid #010101;
          }
          &::-moz-range-thumb {
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            border: 1px solid #000000;
            height: 36px;
            width: 16px;
            border-radius: 3px;
            background: #ffffff;
            cursor: pointer;
          }
          &::-ms-track {
            width: 100%;
            height: 8.4px;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            border-width: 16px 0;
            color: transparent;
          }
          &::-ms-fill-lower {
            background: #2a6495;
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          &::-ms-fill-upper {
            background: #8a3fc1;
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          &::-ms-thumb {
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            border: 1px solid #000000;
            height: 36px;
            width: 16px;
            border-radius: 3px;
            background: #ffffff;
            cursor: pointer;
          }
          &:focus::-ms-fill-lower {
            background: #8a3fc1;
          }
          &:focus::-ms-fill-upper {
            background: #572779;
          }
        `}
        {...props}
      />

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: '0',
}

export default Slider

export const SliderFormik: FC<
  SliderProps & {
    formik: IFormikObject<any>
    name: string
  }
> = ({ formik, ...props }) => {
  const meta = formik.getFieldMeta(props.name)
  return (
    <Slider
      // success={meta.touched && !meta.error}
      error={meta.error}
      value={meta.value}
      {...props}
    />
  )
}
