import React, { FC, useEffect, useMemo, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router-dom'

import Search from '../../ui-blocks/search'
import Suspense from '../../ui-blocks/suspense'
import Container from '../../ui-blocks/container'
import { Tabs, TabPane } from '../../ui-blocks/tabs'

import CampaignsSection from './components/campaigns/section'
import AdGroupsSection from './components/ad-groups/section'
import CreativesSection from './components/creatives/section'
import NetworksSection from './components/networks/section'
import PlayersSection from './components/players/section'

import LoadingPage from '../../components/loading-page'

import { useCountArchivedItemsQuery } from '../../graphql/components'

import { SearchSettings, useSearchTerms } from '../../utils/use-search'

export enum ArchivedItemsPageTabKeys {
  CAMPAIGNS = 'campaigns',
  AD_GROUPS = 'ad-groups',
  CREATIVES = 'creatives',
  NETWORKS = 'networks',
  PLAYERS = 'players',
}

export const archivedItemsPageRoutes = {
  [ArchivedItemsPageTabKeys.CAMPAIGNS]: 'campaigns',
  [ArchivedItemsPageTabKeys.AD_GROUPS]: 'ad-groups',
  [ArchivedItemsPageTabKeys.CREATIVES]: 'creatives',
  [ArchivedItemsPageTabKeys.NETWORKS]: 'networks',
  [ArchivedItemsPageTabKeys.PLAYERS]: 'players',
}

const ArchivedItemsPage: FC = () => {
  const navigate = useNavigate()

  const [search, setSearch] = useState<SearchSettings>({})
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountArchivedItemsQuery({ fetchPolicy: 'cache-first' })
  const itemsCount = useMemo(
    () =>
      (countData?.campaigns ?? 0) +
      (countData?.adGroups ?? 0) +
      (countData?.creatives ?? 0) +
      (countData?.networks ?? 0) +
      (countData?.players ?? 0),
    [countData]
  )

  const refetchData = async () => await refetchCount()
  useEffect(() => {
    document.addEventListener('refetch-archived-campaigns', refetchData)
    document.addEventListener('refetch-archived-ad-groups', refetchData)
    document.addEventListener('refetch-archived-creatives', refetchData)
    document.addEventListener('refetch-archived-networks', refetchData)
    document.addEventListener('refetch-archived-players', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-campaigns', refetchData)
      document.removeEventListener('refetch-archived-ad-groups', refetchData)
      document.removeEventListener('refetch-archived-creatives', refetchData)
      document.removeEventListener('refetch-archived-networks', refetchData)
      document.removeEventListener('refetch-archived-players', refetchData)
    }
  }, [])

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/archived-items/${
        archivedItemsPageRoutes[tabKey as ArchivedItemsPageTabKeys]
      }`
    )
  }

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${ArchivedItemsPageTabKeys.CAMPAIGNS}`) &&
        ArchivedItemsPageTabKeys.CAMPAIGNS) ||
      (location.pathname.endsWith(`/${ArchivedItemsPageTabKeys.AD_GROUPS}`) &&
        ArchivedItemsPageTabKeys.AD_GROUPS) ||
      (location.pathname.endsWith(`/${ArchivedItemsPageTabKeys.CREATIVES}`) &&
        ArchivedItemsPageTabKeys.CREATIVES) ||
      (location.pathname.endsWith(`/${ArchivedItemsPageTabKeys.NETWORKS}`) &&
        ArchivedItemsPageTabKeys.NETWORKS) ||
      (location.pathname.endsWith(`/${ArchivedItemsPageTabKeys.PLAYERS}`) &&
        ArchivedItemsPageTabKeys.PLAYERS) ||
      ArchivedItemsPageTabKeys.CAMPAIGNS,
    [location]
  )

  return (
    <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
      <Container>
        <div tw="flex flex-row items-end mb-10">
          <h1 tw="text-charcoal font-light leading-tight text-3xl">Archived</h1>
          {!!itemsCount && (
            <span tw="text-charcoal text-sm tracking-wide ml-4">
              {`${itemsCount} TOTAL`}
            </span>
          )}
        </div>
        <Tabs
          withTransition={false}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
          tabBarExtraContent={() => (
            <div tw="w-80">
              <Search
                value={search.text}
                loading={search.loading}
                // entries={search.entries}
                onSelect={search.onSelect}
              />
            </div>
          )}
        >
          <TabPane
            key={ArchivedItemsPageTabKeys.CAMPAIGNS}
            tab="Campaigns"
            tw="mt-10"
          >
            <CampaignsSection
              onSearch={setSearch}
              searchText={searchText}
              searchTextDebounced={searchTextDebounced}
              setSearchText={setSearchText}
            />
          </TabPane>
          <TabPane
            key={ArchivedItemsPageTabKeys.AD_GROUPS}
            tab="Ad Groups"
            tw="mt-10"
          >
            <AdGroupsSection
              onSearch={setSearch}
              searchText={searchText}
              searchTextDebounced={searchTextDebounced}
              setSearchText={setSearchText}
            />
          </TabPane>
          <TabPane
            key={ArchivedItemsPageTabKeys.CREATIVES}
            tab="Creatives"
            tw="mt-10"
          >
            <CreativesSection
              onSearch={setSearch}
              searchText={searchText}
              searchTextDebounced={searchTextDebounced}
              setSearchText={setSearchText}
            />
          </TabPane>
          <TabPane
            key={ArchivedItemsPageTabKeys.NETWORKS}
            tab="Networks"
            tw="mt-10"
          >
            <NetworksSection
              onSearch={setSearch}
              searchText={searchText}
              searchTextDebounced={searchTextDebounced}
              setSearchText={setSearchText}
            />
          </TabPane>
          <TabPane
            key={ArchivedItemsPageTabKeys.PLAYERS}
            tab="Players"
            tw="mt-10"
          >
            <PlayersSection
              onSearch={setSearch}
              searchText={searchText}
              searchTextDebounced={searchTextDebounced}
              setSearchText={setSearchText}
            />
          </TabPane>
        </Tabs>
      </Container>
    </Suspense>
  )
}

export default ArchivedItemsPage
