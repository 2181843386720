import React, {
  FC,
  useState,
  useEffect,
  DetailedHTMLProps,
  ImgHTMLAttributes,
} from 'react'
import tw from 'twin.macro'

const LazyLoadImage: FC<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ src, ...props }) => {
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!src) return

    const img = new Image()
    img.src = src
    img.onload = () => {
      setLoaded(true)
    }

    return () => {
      img.remove()
    }
  }, [])

  return (
    <img
      src={src}
      css={[
        tw`transition duration-500`,
        loaded ? tw`opacity-100` : tw`opacity-0`,
      ]}
      {...props}
    />
  )
}

export default LazyLoadImage
