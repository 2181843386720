import React, { FC } from 'react'
import tw, { styled } from 'twin.macro'

import { Alert, AlertVariant as Variant } from '../stores/alerts-manager'

import { ReactComponent as CircleCheckFilledIcon } from '../assets/icons/circle_check_filled.svg'
import { ReactComponent as CircleWarningFilledIcon } from '../assets/icons/circle_warning_filled.svg'

export { Variant as AlertVariant }

export interface AlertComponentProps {
  alert: Alert
  width?: string
}

const AlertComponent: FC<AlertComponentProps> = ({ alert, width }) => {
  const { variant = Variant.SUCCESS, message, actionText, onAction } = alert

  let Icon = ColoredCircleCheckFilledIcon
  if (variant === Variant.ERROR || variant === Variant.WARNING) {
    Icon = ColoredCircleWarningFilledIcon
  }

  let colors = tw`text-metallic-seaweed bg-lighter-green border-metallic-seaweed`
  if (variant === Variant.WARNING) {
    colors = tw`text-golden-brown bg-lighter-yellow border-golden-brown`
  } else if (variant === Variant.ERROR) {
    colors = tw`text-brick-red bg-lighter-red border-brick-red`
  }

  return (
    <div
      css={[
        colors,
        `width: ${width || '25rem'};`,
        tw`flex flex-row items-start border rounded py-2 px-4`,
      ]}
    >
      <Icon width={22} height={22} tw="mt-px flex-shrink-0" />
      <div tw="pl-2">
        {message}
        {!!actionText && (
          <span onClick={onAction} tw="pl-1 text-charcoal cursor-pointer">
            {actionText}
          </span>
        )}
      </div>
    </div>
  )
}

const ColoredCircleCheckFilledIcon = styled(CircleCheckFilledIcon)`
  & circle {
    ${tw`fill-current`}
  }
`
const ColoredCircleWarningFilledIcon = styled(CircleWarningFilledIcon)`
  & circle {
    ${tw`fill-current`}
  }
`

export default AlertComponent
