import React, { FC } from 'react'
import 'twin.macro'

import Tag from '../ui-blocks/tag'
import Dots from '../ui-blocks/dots'

import NotDefined from './not-defined'

import { useListVenueTypesQuery } from '../graphql/components'

export interface VenueTypesProps {
  asPills?: boolean
  venueTypeIds: string[]
}

const VenueTypes: FC<VenueTypesProps> = ({ asPills, venueTypeIds }) => {
  const { data, loading } = useListVenueTypesQuery({
    skip: venueTypeIds.length === 0,
    variables: { filter: { _ids: venueTypeIds } },
  })

  if (loading) return <Dots />
  const venueTypes = data?.venueTypes || []
  if (!venueTypes.length) return <NotDefined />

  const content = asPills
    ? venueTypes.map((venueType) => (
        <Tag key={venueType._id} text={venueType.name} />
      ))
    : venueTypes.map((venue) => venue.name).join(', ')

  return asPills ? (
    <div tw="flex flex-row flex-wrap items-center">{content}</div>
  ) : (
    <span>{content}</span>
  )
}

export default VenueTypes
