import { SubscriptionHookOptions } from '@apollo/client'
import { useEffect } from 'react'

export type IUseCallBackOnSubscriptionChange<T, Y> = {
  callback: () => any
  subscriptionOptions?: SubscriptionHookOptions<T, Y>
  subscription(
    baseOptions?: SubscriptionHookOptions<T, Y>
  ): { data?: any; loading: boolean; error?: any }
}

const useCallBackOnSubscriptionChange = (
  props: IUseCallBackOnSubscriptionChange<any, any>
) => {
  const { callback, subscription, subscriptionOptions } = props
  const { data, loading, error } = subscription(subscriptionOptions)

  useEffect(() => {
    if (data && !loading && !error) {
      const asyncWrapper = async () => {
        await callback()
      }
      asyncWrapper()
    }
  }, [data, loading])
}

export default useCallBackOnSubscriptionChange
