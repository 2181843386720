import React, { FC } from 'react'
import 'twin.macro'

import Button from '../../../../ui-blocks/button'
import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

export interface CreativesEmptyProps {
  onUploadClick: () => void
}

const CreativesEmpty: FC<CreativesEmptyProps> = ({ onUploadClick }) => (
  <div tw="flex flex-col items-center">
    <Illustration name={IllustrationEnum.creatives_empty_state} tw="mb-10" />
    <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
      Upload your content
    </h1>
    <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
      Upload the content you wish to broadcast to your players. Once you have
      your players and campaigns set, it will start to play your creatives.
    </p>
    <Button onClick={onUploadClick}>Upload</Button>
  </div>
)

export default CreativesEmpty
