import React, { FC, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router-dom'

import Button from '../../../../../../ui-blocks/button'

import ArchiveWarningModal from '../../../../../../components/archive-warning-modal'

import {
  CampaignFullFragment,
  useArchiveCampaignMutation,
} from '../../../../../../graphql/components'

import { alertsManager } from '../../../../../../stores'
import { AlertVariant } from '../../../../../../stores/alerts-manager'

export interface ArchiveCampaignFormProps {
  campaign?: CampaignFullFragment
}

const ArchiveCampaignForm: FC<ArchiveCampaignFormProps> = ({ campaign }) => {
  const navigate = useNavigate()
  const [isWarningModalOpen, openWarningModal] = useState<boolean>(false)

  const [
    archiveNetwork,
    { loading: archivingNetwork },
  ] = useArchiveCampaignMutation()

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'archive-network-error-alert',
    })
  }

  const onArchiveCampaign = async () => {
    try {
      const { data } = await archiveNetwork({
        variables: { ids: [campaign?._id] },
      })
      if (!data?.areArchived) {
        return handleError(
          "We couldn't archive this campaign. Please try again later."
        )
      }
      navigate('/campaigns', { state: { refetch: true } })
    } catch {
      handleError(
        'Something went wrong while trying to archive this campaign. Please try again later.'
      )
    }
  }

  return (
    <>
      <span tw="font-medium text-charcoal leading-tight block mb-2">
        Archive
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mb-10">
        Once you archive a campaign it will no longer be available.
      </span>
      <Button danger type="button" onClick={() => openWarningModal(true)}>
        Archive campaign
      </Button>
      <ArchiveWarningModal
        target="campaign"
        redirectPage="Campaigns"
        open={isWarningModalOpen}
        loading={archivingNetwork}
        onConfirm={onArchiveCampaign}
        onCancel={() => openWarningModal(false)}
      />
    </>
  )
}

export default ArchiveCampaignForm
