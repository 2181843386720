import React, { FC, useEffect, useMemo, useState } from 'react'

import tw from 'twin.macro'
import { useNavigate, useParams } from 'react-router'

import Text from '../../../../ui-blocks/text'
import Button from '../../../../ui-blocks/button'
import Suspense from '../../../../ui-blocks/suspense'
import Container from '../../../../ui-blocks/container'
import { InformationsSection } from '../../../../ui-blocks/information'

import ReviewCard from './components/review-card'
import ReviewForm from './components/review-form'
import ProgressBar from './components/progress-bar'
import CreativesTable from './components/creatives-table'

import NotFound from '../../../../components/not-found'
import InlineDate from '../../../../components/inline-date'
import LoadingPage from '../../../../components/loading-page'

import { useViewExternalCampaignQuery } from '../../../../graphql/components'

import { getCampaignTimeframe } from '../../../../utils/data-manipulation'

const ReviewExternalCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_id, network_id } = useParams()
  const [reviewCount, setReviewCount] = useState<number>(0)

  const {
    data: campaignData,
    loading: loadingCampaign,
  } = useViewExternalCampaignQuery({
    skip: !campaign_id,
    fetchPolicy: 'cache-first',
    variables: { _id: campaign_id },
  })

  useEffect(() => {
    if (!campaign_id || !network_id) returnToExternalCampaigns()
  }, [])

  const returnToExternalCampaigns = () => {
    navigate('/campaigns', { replace: true, state: { refetch: true } })
  }

  const handleApproval = () => {
    document.dispatchEvent(new CustomEvent('refetch-external-campaigns'))
    returnToExternalCampaigns()
  }

  const handleReviewSection = (isReviewed: boolean) => {
    setReviewCount((count) => (isReviewed ? count + 1 : count - 1))
  }

  const campaign = useMemo(() => campaignData?.campaign, [campaignData])
  const adGroupsCount = useMemo(() => campaign?.ad_groups?.length ?? 0, [
    campaign,
  ])
  const [campaignStart, campaignEnd] = useMemo(
    () => getCampaignTimeframe((campaign?.ad_groups || []) as any[]),
    [campaign]
  )

  return (
    <Suspense ready={!loadingCampaign} fallback={<LoadingPage />}>
      <Suspense ready={!!campaign} fallback={<NotFound />}>
        <Container>
          <div tw="flex flex-row items-center justify-between mb-10">
            <Text as="h1" preset="h3">
              Approval Review
            </Text>
            <div tw="flex flex-row items-end">
              <ProgressBar count={reviewCount} total={adGroupsCount + 1} />
              <Button
                tw="ml-10"
                dropdown={
                  <ReviewForm
                    networkId={network_id}
                    campaignId={campaign_id}
                    onReject={returnToExternalCampaigns}
                    onApprove={handleApproval}
                  />
                }
              >
                Review Campaign
              </Button>
            </div>
          </div>

          <Text as="h2" preset="h4" tw="mb-6">
            Campaign Information
          </Text>
          <ReviewCard
            title={campaign?.name || ''}
            onReview={handleReviewSection}
            tw="mb-10"
          >
            <div tw="flex flex-row items-center text-charcoal">
              <InformationsSection label="Ad Groups">
                {campaign?.ad_groups?.length ?? 0}
              </InformationsSection>
              <InformationsSection label="Workspace">
                {campaign?.ownership?.workspace_name}
              </InformationsSection>
              <InformationsSection label="Start Date">
                <InlineDate date={campaignStart} />
              </InformationsSection>
              <InformationsSection label="End Date">
                <InlineDate date={campaignEnd} />
              </InformationsSection>
              <InformationsSection label="Created At">
                <InlineDate date={campaign?.created?.at} />
              </InformationsSection>
            </div>
          </ReviewCard>

          <div tw="flex flex-row items-end mb-6">
            <Text as="h2" preset="h4" tw="mr-4">
              Ad Groups
            </Text>
            <Text as="h4" preset="p2" transform="uppercase">
              {`${adGroupsCount} Total`}
            </Text>
          </div>
          {campaign?.ad_groups?.map((adGroup, index) => (
            <ReviewCard
              key={adGroup._id}
              count={index + 1}
              title={adGroup.name}
              onReview={handleReviewSection}
              css={[
                index !== (campaign?.ad_groups || []).length - 1 && tw`mb-4`,
              ]}
            >
              <div tw="flex flex-row items-center text-charcoal mb-8">
                <InformationsSection label="Creatives">
                  {adGroup.creatives?.length ?? 0}
                </InformationsSection>
                <InformationsSection label="Area Type">
                  {adGroup.audience?.area_type}
                </InformationsSection>
                <InformationsSection label="Start Date">
                  <InlineDate date={adGroup.timeframe?.starts_at} />
                </InformationsSection>
                <InformationsSection label="End Date">
                  <InlineDate date={adGroup.timeframe?.starts_at} />
                </InformationsSection>
                <InformationsSection label="Created At">
                  <InlineDate date={adGroup.created?.at} />
                </InformationsSection>
              </div>
              <CreativesTable creatives={adGroup.creatives || []} />
            </ReviewCard>
          ))}
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ReviewExternalCampaignPage
