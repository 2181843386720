import React, { FC } from 'react'
import tw, { TwStyle } from 'twin.macro'

import _ from 'lodash'
import moment from 'moment'

import Icon from '../../../../../ui-blocks/icon'
import Spacer from '../../../../../ui-blocks/spacer'

import { Invoice } from '../sections/invoices'

import { EnumWorkspacePlatform_Type } from '../../../../../graphql/components'

export interface InvoicesTableProps {
  invoices: Invoice[]
  onSelectInvoice: (invoice: Invoice) => void
  platform_type: EnumWorkspacePlatform_Type | string
}

const status: { [key: string]: { style: TwStyle; text: string } } = {
  open: {
    text: 'Due',
    style: tw`py-4 text-golden-brown`,
  },
  draft: {
    text: 'In process',
    style: tw`py-4 text-dark-blue-gray italic`,
  },
  paid: {
    text: 'Paid',
    style: tw`py-4 text-metallic-seaweed`,
  },
}

const InvoicesTable: FC<InvoicesTableProps> = ({
  invoices,
  onSelectInvoice,
  platform_type,
}) => (
  <div tw="w-full">
    <table tw="w-full">
      <thead tw="font-medium text-xs text-dark-blue-gray leading-tight text-left uppercase">
        <tr tw="border-b border-platinum">
          <th tw="pb-4 tracking-widest leading-3 font-medium">Date</th>
          <th tw="pb-4 tracking-widest leading-3 font-medium">
            Subscription Details
          </th>
          <th tw="pb-4 tracking-widest leading-3 font-medium">Payment</th>
          <th tw="pb-4 tracking-widest leading-3 font-medium">Amount</th>
          <th tw="pb-4"></th>
        </tr>
      </thead>
      <tbody tw="font-normal text-charcoal leading-tight">
        {invoices.map((invoice: Invoice) => (
          <tr key={`invoice-${invoice.id}`} tw="border-b border-platinum">
            <td tw="py-4">
              {moment(invoice.created * 1000).format('DD / MM / YYYY')}
            </td>
            <td tw="py-4 flex flex-col">
              <span tw="font-normal leading-snug">
                {_.startCase(_.camelCase(platform_type))}
              </span>
              <span tw="pt-4 text-dark-blue-gray text-xs">Usefull info</span>
            </td>
            <td css={[status[invoice.status].style]}>
              {status[invoice.status].text}
            </td>
            <td tw="py-4 leading-tight">{invoice.total / 100}€</td>
            <td tw="py-4">
              <div tw="flex flex-row items-center justify-end">
                <div
                  onClick={() => onSelectInvoice(invoice)}
                  tw="flex flex-row items-center text-purple cursor-pointer space-x-1"
                >
                  <Icon icon="eye" tw="text-base" />
                  <span>View</span>
                </div>
                <Spacer size="2.125rem" direction="horizontal" />
                {/* <div
                  css={css`
                    ${tw`flex flex-row items-center text-dark-blue-gray cursor-pointer`}
                  `}
                >
                  <Download width={12} height={12} />
                  <a
                    css={css`
                      ${tw`ml-2`}
                    `}
                  >
                    Download PDF
                  </a>
                </div> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {/* <PaginationNav /> */}
  </div>
)

export default InvoicesTable
