import React, { FC, Fragment, useMemo } from 'react'

import 'twin.macro'
import { useParams } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../../ui-blocks/information'
import Icon from '../../../../ui-blocks/icon'
import Status from '../../../../ui-blocks/status'
import KPICard from '../../../../ui-blocks/kpi-card'
import ButtonLink from '../../../../ui-blocks/button-link'

import InlineDate from '../../../../components/inline-date'
import SubmittedBy, {
  PartialBroadcastTarget,
} from '../../list/external/components/submitted-by'

import {
  EnumCampaignStatus,
  CampaignFullFragment,
  EnumBroadcastTargetType,
  EnumBroadcastTargetStatus,
} from '../../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'
import { getCampaignTimeframe } from '../../../../utils/data-manipulation'

export interface OverviewSectionProps {
  campaign?: CampaignFullFragment
}

const OverviewSection: FC<OverviewSectionProps> = ({ campaign }) => {
  const { broadcast_target_id } = useParams()

  const intervals = useMemo(getKPIIntervals, [])
  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, campaign?.stats || []),
    [intervals, campaign]
  )

  const allAdGroups = useMemo(() => campaign?.ad_groups || [], [campaign])
  const [campaignStart, campaignEnd] = useMemo(
    () => getCampaignTimeframe(allAdGroups as any),
    [allAdGroups]
  )

  const broadcastTarget = useMemo(
    () =>
      campaign?.broadcast_targets?.find(
        (target) => target?._id === broadcast_target_id
      ),
    [campaign, broadcast_target_id]
  )

  const broadcastTargetsCount = useMemo(
    () =>
      campaign?.broadcast_targets
        ?.map(
          (target) =>
            target?.player || target?.network || target?.externalNetwork
        )
        .filter(Boolean).length ?? 0,
    [campaign]
  )

  const internalNetworksCount = useMemo(
    () =>
      (campaign?.broadcast_targets || []).filter(
        (target) =>
          target?.type === EnumBroadcastTargetType.Network && !!target.network
      ).length,
    [campaign]
  )

  const externalNetworksCount = useMemo(
    () =>
      (campaign?.broadcast_targets || []).filter(
        (target) =>
          target?.type === EnumBroadcastTargetType.ExternalNetwork &&
          !!target.externalNetwork
      ).length,
    [campaign]
  )

  // const playersCount = useMemo(
  //   () =>
  //     (campaign?.broadcast_targets || []).filter(
  //       (target) => target?.type === EnumBroadcastTargetType.Player && !!target.player
  //     ).length,
  //   [campaign]
  // )

  return (
    <section>
      <Informations title="Campaign Information" tw="mb-14">
        <InformationsSection label="STATUS">
          <Status
            value={
              (!campaign?.is_external
                ? campaign?.status
                : EnumBroadcastTargetStatus.Approved) as any
            }
          >
            {campaign?.status === EnumCampaignStatus.Pending &&
              !campaign?.is_external && (
                <>
                  <p tw="font-medium text-charcoal leading-tight mb-4">
                    Your campaign is still pending.
                  </p>
                  <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
                    You need to add at least one ad group to your campaign to
                    activate it.
                  </span>
                  <ButtonLink
                    iconLeft={<Icon icon="add" tw="text-lg" />}
                    to={`/campaigns/${campaign?._id}/ad-groups/create`}
                  >
                    Add Ad Group
                  </ButtonLink>
                </>
              )}
          </Status>
        </InformationsSection>
        {campaign?.is_external && (
          <Fragment>
            <InformationsSection label="WORKSPACE">
              {campaign.ownership?.workspace_name}
            </InformationsSection>
          </Fragment>
        )}
        <InformationsSection label="AD GROUPS">
          {allAdGroups.length}
        </InformationsSection>
        {!campaign?.is_external && (
          <Fragment>
            <InformationsSection label="BROADCAST TARGETS">
              {broadcastTargetsCount}
            </InformationsSection>
            <InformationsSection label="INTERNAL NETWORKS">
              {internalNetworksCount}
            </InformationsSection>
            <InformationsSection label="EXTERNAL NETWORKS">
              {externalNetworksCount}
            </InformationsSection>
            {/* <InformationsSection label="PLAYERS">
              {playersCount}
            </InformationsSection> */}
          </Fragment>
        )}
        <InformationsSection label="START">
          <InlineDate date={campaignStart} />
        </InformationsSection>
        <InformationsSection label="END">
          <InlineDate date={campaignEnd} />
        </InformationsSection>
        <InformationsSection label="CREATED">
          <InlineDate date={campaign?.created?.at} />
        </InformationsSection>
        {campaign?.is_external && (
          <InformationsSection label="SUBMITTED">
            <SubmittedBy
              broadcastTarget={broadcastTarget as PartialBroadcastTarget}
              tw="items-start"
            />
          </InformationsSection>
        )}
      </Informations>
      <KPICard title="Campaign Analytics" intervals={stats} />
    </section>
  )
}

export default OverviewSection
