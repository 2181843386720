import { useMemo } from 'react'
import {
  useCountNetworksQuery,
  useListNetworksQuery,
} from '../../../../graphql/components'
import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

export const useSearchInternalNetworks = () => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: totalCountData,
    loading: loadingTotalCount,
  } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: countData, loading: loadingCount } = useCountNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const totalNetworksCount = useMemo(() => totalCountData?.networks || 0, [
    totalCountData,
  ])

  const networksCount = useMemo(() => countData?.networks || 0, [countData])

  const pagination = usePagination(networksCount, 9, searchTextDebounced)
  const { data: networksData, loading: loadingNetworks } = useListNetworksQuery(
    {
      fetchPolicy: 'cache-first',
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
        filter: { search: searchTextDebounced },
      },
    }
  )

  const networks = useMemo(() => networksData?.networks || [], [networksData])

  return {
    totalNetworksCount,
    loadingTotalCount,
    networksCount,
    searchText,
    setSearchText,
    searchTextDebounced,
    networks,
    loadingNetworks,
    loadingCount,
    pagination,
  }
}
