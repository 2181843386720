import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import Icon from '../../../../../ui-blocks/icon'
import Text from '../../../../../ui-blocks/text'
import Button from '../../../../../ui-blocks/button'
import Search from '../../../../../ui-blocks/search'

import SharedWorkspacesListTable, { SharedWorkspace } from './table'

import GetLinkModal from '../../../../../components/get-link-modal'

import {
  NetworkFullFragment,
  useListWorkspacesQuery,
} from '../../../../../graphql/components'

import { useSearchTerms } from '../../../../../utils/use-search'
import { regexpFromString } from '../../../../../utils/regexp-from-string'

export interface SharedWorkspacesSectionProps {
  loading?: boolean
  network?: NetworkFullFragment
  refetchNetwork?: () => Promise<any>
}

const SharedWorkspacesSection: FC<SharedWorkspacesSectionProps> = ({
  loading,
  network,
  refetchNetwork,
}) => {
  const [isGetLinkModalOpen, openGetLinkModal] = useState<boolean>(false)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const workspaceIds = useMemo(() => {
    return network?.sharedWithWorkspaces?.reduce(
      (accumulator, sharedWorkspace) => {
        if (!sharedWorkspace?.revoked?.at) {
          accumulator.push(sharedWorkspace?.workspace_id)
        }

        return accumulator
      },
      [] as Array<any>
    )
  }, [network])

  const {
    data: workspacesData,
    loading: loadingWorkspaces,
  } = useListWorkspacesQuery({
    skip: !network?.sharedWithWorkspaces?.length,
    variables: {
      _ids: workspaceIds || [],
    },
  })

  const allWorkspaces = useMemo(
    () =>
      workspacesData?.workspaces?.map((workspace) => ({
        ...workspace,
        shared: network?.sharedWithWorkspaces?.find(
          (shared) =>
            shared?.workspace_id === workspace?._id && !shared?.revoked?.at
        ),
      })) || [],
    [workspacesData, network]
  )

  const filteredWorkspaces = useMemo(
    () =>
      allWorkspaces.filter((workspace) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(workspace.name)
          : workspace
      ),
    [allWorkspaces, searchTextDebounced]
  )

  return (
    <section>
      <div tw="flex flex-row items-center justify-between mb-4">
        <div tw="flex flex-row items-end space-x-4">
          <Text as="p" preset="h4">
            Workspaces
          </Text>
          <Text as="span" preset="p2" transform="uppercase">
            {loadingWorkspaces ? 'Loading...' : `${allWorkspaces.length} TOTAL`}
          </Text>
        </div>
        <div tw="flex flex-row items-center space-x-10">
          <div tw="w-80">
            <Search
              value={searchText}
              loading={loadingWorkspaces}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
          </div>
          <Button
            iconLeft={<Icon icon="link" tw="text-base transform rotate-45" />}
            onClick={() => openGetLinkModal(true)}
          >
            Get Link
          </Button>
        </div>
      </div>
      <SharedWorkspacesListTable
        searchQuery={searchTextDebounced}
        loading={loading || loadingWorkspaces}
        data={filteredWorkspaces as SharedWorkspace[]}
        refetchData={refetchNetwork}
      />
      <GetLinkModal
        open={isGetLinkModalOpen}
        token={network?.invite_token || ''}
        onClose={() => openGetLinkModal(false)}
      />
    </section>
  )
}

export default SharedWorkspacesSection
