import { useCallback, useEffect } from 'react'
import { NODE_ENV, CRISP_WEBSITE_ID } from './config'
import { useForceUpdate } from './utils/use-force-update'

// Typings
type CrispAction = string
type CrispCallback = () => void
type Args = any[]
type CrispPushedInstruction =
  | ['do', CrispAction, Args?]
  | ['on' | 'off', string, CrispCallback]
type Crisp = CrispPushedInstruction[] & {
  is?: (event: string) => any
}

// Hardcoded Feature flag
const isActive = NODE_ENV !== 'development'

export function getCrisp(): Crisp {
  if (!isActive) return []

  // @ts-ignore
  if (typeof window.$crisp?.push != 'function') {
    // @ts-ignore
    window.$crisp = []

    setup()
  }

  // @ts-ignore
  return window.$crisp
}

type UseCrispInterface = {
  isActive: Boolean
  isOpen: Boolean
  open: () => void
  close: () => void
  toggle: () => void
}

export const useCrisp = (): UseCrispInterface => {
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const callback = () => {
      const crisp = getCrisp()

      if (isCrispOpen(crisp)) {
        crisp.push(['do', 'chat:show'])
      } else {
        crisp.push(['do', 'chat:hide'])
      }

      forceUpdate()
    }

    // add callback to crisp
    getCrisp().push(['on', 'chat:opened', callback])
    getCrisp().push(['on', 'chat:closed', callback])

    return () => {
      // remove event listeners
      getCrisp().push(['off', 'chat:opened', callback])
      getCrisp().push(['off', 'chat:closed', callback])
    }
  }, [])

  // check if crisp is open instead of relying on different states
  const isOpen = isCrispOpen()

  const open = useCallback(() => {
    const crisp = getCrisp()
    crisp.push(['do', 'chat:open'])
  }, [isOpen])

  const close = useCallback(() => {
    const crisp = getCrisp()
    crisp.push(['do', 'chat:close'])
  }, [isOpen])

  const toggle = useCallback(() => (isCrispOpen() ? close() : open()), [isOpen])

  return {
    isActive,
    isOpen,
    open,
    close,
    toggle,
  }
}

function isCrispOpen(crisp = getCrisp()) {
  return typeof crisp.is === 'function' ? !!crisp.is('chat:opened') : false
}

async function setup() {
  if (!isActive) return

  // @ts-ignore
  window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID

  // We are good to load Crisp
  const script = document.createElement('script')
  script.src = 'https://client.crisp.chat/l.js'
  script.async = true
  document.head.appendChild(script)

  const crisp = getCrisp()

  // setup crisp
  crisp.push(['do', 'chat:close'])
  crisp.push(['do', 'chat:hide'])

  return new Promise((ful) => {
    // This callback gets executed once $crisp is fully loaded and all methods (not only $crisp.push()) are available
    // @ts-ignore
    window.CRISP_READY_TRIGGER = function () {
      ful(undefined)

      // TODO: setup user data
    }
  })
}
