import React, { FC } from 'react'
import 'twin.macro'

import EditPlayerForm from './components/edit-player-form'
import EditPlayerSettingsForm from './components/edit-player-settings-form'
import ArchivePlayerForm from './components/archive-player-form'

import SettingsPanel from '../../../../../components/settings-panel'

import { PlayerFullFragment } from '../../../../../graphql/components'

export interface SettingsSectionProps {
  player?: PlayerFullFragment
  refetchPlayer?: () => Promise<any>
}

const SettingsSection: FC<SettingsSectionProps> = ({
  player,
  refetchPlayer,
}) => (
  <section>
    <SettingsPanel
      sections={[
        {
          title: 'Details',
          content: (
            <EditPlayerForm player={player} refetchPlayer={refetchPlayer} />
          ),
        },
        {
          title: 'Settings',
          content: (
            <EditPlayerSettingsForm
              player={player}
              refetchPlayer={refetchPlayer}
            />
          ),
        },
        {
          title: 'Archive',
          content: <ArchivePlayerForm player={player} />,
        },
      ]}
    />
  </section>
)

export default SettingsSection
