import React, { FC } from 'react'
import 'twin.macro'

export interface ColoredSquareProps {
  size: number | string
  color: string
}

const ColoredSquare: FC<ColoredSquareProps> = ({ size, color, ...props }) => {
  const finalSize = typeof size === 'number' ? `${size}px` : size
  return (
    <div
      css={[
        `width: ${finalSize};`,
        `height: ${finalSize};`,
        `background-color: ${color};`,
      ]}
      {...props}
    />
  )
}

export default ColoredSquare
