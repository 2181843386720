import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from './text'

import PendingPlayer, { PendingPlayerProps } from '../components/player/pending'

export interface PlayersPendingProps {
  cards: PendingPlayerProps[]
}

const PlayersPending: FC<PlayersPendingProps> = ({ cards }) => (
  <div
    css={css`
      height: 15rem;
      ${tw`bg-white rounded border border-platinum w-full flex`}
    `}
  >
    <div tw="bg-lighter-blue h-full p-8 pr-12 rounded-tl rounded-bl w-full max-w-xs">
      <Text as="h1" preset="p2" transform="uppercase" tw="text-charcoal mb-4">
        Pending Players
      </Text>
      <Text
        as="p"
        preset="p1"
        css={css`
          max-height: 7rem;
          ${tw`text-justify relative overflow-hidden -mr-4 pr-4`}

          &:before {
            content: '...';
            ${tw`absolute right-0 bottom-0`}
          }

          &:after {
            content: '';
            margin-top: 0.2rem;
            ${tw`absolute w-4 h-4 right-0 bg-lighter-blue`}
          }
        `}
      >
        You didn't finish setting up all of your Players. Please complete the
        process to make them available.
      </Text>
    </div>
    <div tw="overflow-auto h-full flex flex-1 flex-row items-center px-8 space-x-8">
      {cards.map((data) => (
        <PendingPlayer key={`pending-player-${data.player._id}`} {...data} />
      ))}
    </div>
  </div>
)

export default PlayersPending
