import React, { FC } from 'react'

import tw from 'twin.macro'
import moment, { Moment } from 'moment'

import Icon from '../ui-blocks/icon'

import NotDefined from './not-defined'

export interface InlineDateProps {
  format?: string
  showIcon?: boolean
  date?: Date | number | Moment
}

const InlineDate: FC<InlineDateProps> = ({
  date,
  format,
  showIcon,
  ...props
}) => {
  const momDate = (!!date && moment(date)) || undefined
  return !!momDate && momDate.isValid() ? (
    <span
      title={momDate.format('LLLL')}
      css={[tw`whitespace-nowrap`, showIcon && tw`flex flex-row items-center`]}
      {...props}
    >
      {showIcon && <Icon icon="clock" tw="text-lg text-charcoal mr-1" />}
      {momDate.format(format || 'lll')}
    </span>
  ) : (
    <NotDefined />
  )
}

export default InlineDate
