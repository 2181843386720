import React, { FC } from 'react'
import 'twin.macro'

export const RemainingTrialTime: FC<{
  remainingDays: number
}> = ({ remainingDays }) => (
  <div tw="flex flex-row font-normal text-xs leading-tight">
    <span tw="text-dark-blue-gray mr-2">Ending: 24 August 2020</span>
    <span tw="text-2xl mb-8 font-light text-charcoal">
      {remainingDays} days left
    </span>
  </div>
)
