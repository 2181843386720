import React, { FC, useMemo } from 'react'

import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Text from '../../../../../ui-blocks/text'
import Radio from '../../../../../ui-blocks/radio'
import Button from '../../../../../ui-blocks/button'
import Spacer from '../../../../../ui-blocks/spacer'
import Textarea from '../../../../../ui-blocks/textarea'

import {
  useRejectExternalCampaignMutation,
  useApproveExternalCampaignMutation,
} from '../../../../../graphql/components'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

interface ReviewCampaignFormikValues {
  submission: 'approve' | 'reject' | ''
  reviewNotes: string
}

const reviewCampaignValidationSchema = object().shape({
  submission: string().required(),
  reviewNotes: string(),
})

export interface ReviewFormProps {
  networkId: string
  campaignId: string
  onReject?: () => void
  onApprove?: () => void
}

const ReviewForm: FC<ReviewFormProps> = ({
  networkId,
  campaignId,
  onReject,
  onApprove,
}) => {
  const [
    rejectCampaign,
    { loading: rejectingCampaign },
  ] = useRejectExternalCampaignMutation()
  const [
    approveCampaign,
    { loading: approvingCampaign },
  ] = useApproveExternalCampaignMutation()

  const handleError = (action: 'approve' | 'reject') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'approve-reject-campaign-error-alert',
      message: `We couldn't ${action} this campaign. Please try again later.`,
    })
  }

  const formik = useFormik<ReviewCampaignFormikValues>({
    validateOnChange: false,
    validationSchema: reviewCampaignValidationSchema,
    initialValues: { submission: '', reviewNotes: '' },
    async onSubmit({ submission, reviewNotes }) {
      try {
        if (submission === 'approve') {
          const res = await approveCampaign({
            variables: { network_id: networkId, campaign_id: campaignId },
          })
          if (!res.data?.isApproved) return handleError(submission)
          onApprove?.()
        } else if (submission === 'reject') {
          const res = await rejectCampaign({
            variables: {
              network_id: networkId,
              campaign_id: campaignId,
              reviewNotes,
            },
          })
          if (!res.data?.isRejected) return handleError(submission)
          onReject?.()
        }
      } catch {
        !!submission && handleError(submission)
      }
    },
  })

  const willReject = useMemo(() => formik.values.submission === 'reject', [
    formik.values.submission,
  ])

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      <Text as="p" preset="h4" tw="mb-6">
        Submit your review
      </Text>
      <Radio
        value="approve"
        name="submission"
        label="Approve Campaign"
        checked={formik.values.submission === 'approve'}
      />
      <Spacer size="0.25rem" />
      <Radio
        value="reject"
        name="submission"
        label="Reject Campaign"
        checked={formik.values.submission === 'reject'}
      />
      <Spacer size="0.5rem" />
      {willReject && (
        <>
          <Text as="p" preset="p1" tw="mb-2">
            Please provide a note about rejection:
          </Text>
          <Textarea rows={4} name="reviewNotes"></Textarea>
        </>
      )}
      <Button
        tw="mt-8"
        type="submit"
        loading={rejectingCampaign || approvingCampaign}
        disabled={
          !formik.values.submission ||
          (willReject && !formik.values.reviewNotes)
        }
      >
        Submit Review
      </Button>
    </form>
  )
}

export default ReviewForm
