import React, { FC, useState } from 'react'

import _ from 'lodash'
import tw from 'twin.macro'

import { useFormik } from 'formik'
import { string, object, array, number } from 'yup'

import Map from '../../../../../../ui-blocks/map'
import Button from '../../../../../../ui-blocks/button'
import Checkbox from '../../../../../../ui-blocks/checkbox'
import CardResourceRadio from '../../../../../../ui-blocks/card-resource-radio'
import { CardResourceProps } from '../../../../../../ui-blocks/card-resource'
import { InputFormik } from '../../../../../../ui-blocks/input'

import CountryInput from '../../../../../../components/country-input'
import ErrorMessage from '../../../../../../components/error-message'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../components/alert-component'

import {
  EnumPointType,
  PlayerFullFragment,
  useListAreaTypesQuery,
  useListVenueTypesQuery,
  useUpdatePlayerByIdMutation,
} from '../../../../../../graphql/components'
import { IMapPoint } from '../../../../../../typings'

import {
  trimValues,
  cleanGraphqlTypenames,
} from '../../../../../../utils/data-manipulation'

import { ReactComponent as BillboardIcon } from '../../../../../../assets/icons/billboard.svg'
import { ReactComponent as IndoorIcon } from '../../../../../../assets/icons/indoor_audience.svg'
import { ReactComponent as OutdoorIcon } from '../../../../../../assets/icons/outdoor_audience.svg'

interface EditPlayerFormikValues {
  name: string
  settings: {
    debug: boolean
    volume: number
    brightness: number
  }
  geographics: {
    location: {
      type: EnumPointType
      coordinates: number[]
    }
    address: {
      street: string
      number: string
      city: string
      state: string
      zip: string
      country_id: string
    }
  }
  demographics: {
    country_id: string
    area_type_id: string
    language_ids: string[]
    venue_type_ids: string[]
  }
}

const editPlayerValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  geographics: object()
    .shape({
      location: object()
        .shape({
          coordinates: array().of(number()).required('Location is required'),
        })
        .required(),
      address: object()
        .shape({
          street: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('Street name is required')
            .strict(true),
          number: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('Suite, Apt. is required'),
          city: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('City is required')
            .strict(true),
          state: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('State is required')
            .strict(true),
          zip: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('ZIP Code is required')
            .strict(true),
          country_id: string().required('Country is required'),
        })
        .required(),
    })
    .required(),
  demographics: object().shape({
    language_ids: array(string()),
    country_id: string().nullable(),
    area_type_id: string().required('Area type is required'),
    venue_type_ids: array(string()),
  }),
})

export interface EditPlayerFormProps {
  player?: PlayerFullFragment
  refetchPlayer?: () => Promise<any>
}

const formikCoordinates = (player: EditPlayerFormikValues): IMapPoint[] => {
  const coords = player?.geographics?.location?.coordinates || []
  return coords.length ? [{ lng: coords[0], lat: coords[1] } as IMapPoint] : []
}

const EditPlayerForm: FC<EditPlayerFormProps> = ({ player, refetchPlayer }) => {
  const [updatePlayerError, setUpdatePlayerError] = useState<string>('')

  const { data: areaTypesData } = useListAreaTypesQuery()
  const { data: venueTypesData } = useListVenueTypesQuery()

  const [updatePlayer] = useUpdatePlayerByIdMutation()
  const formik = useFormik<EditPlayerFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editPlayerValidationSchema,
    initialValues: {
      name: player?.name || '',
      settings: { ...cleanGraphqlTypenames<any>(player?.settings) },
      demographics: { ...cleanGraphqlTypenames<any>(player?.demographics) },
      geographics: {
        location: {
          type: EnumPointType.Point,
          coordinates: player?.geographics?.location?.coordinates as number[],
        },
        address: {
          street: player?.geographics?.address?.street || '',
          number: player?.geographics?.address?.number || '',
          city: player?.geographics?.address?.city || '',
          state: player?.geographics?.address?.state || '',
          zip: player?.geographics?.address?.zip || '',
          country_id: player?.geographics?.address?.country_id || '',
        },
      },
    },
    async onSubmit(values) {
      try {
        setUpdatePlayerError('')
        const newValues = trimValues(values, [
          'name',
          'geographics.address.street',
          'geographics.address.city',
          'geographics.address.state',
          'geographics.address.zip',
        ])

        const res = await updatePlayer({
          variables: { _id: player?._id, input: newValues },
        })
        if (!!(res.errors || []).length) {
          return setUpdatePlayerError(
            "We couldn't update this player. Please try again later."
          )
        }

        await refetchPlayer?.()
      } catch {
        setUpdatePlayerError(
          'Something went wrong while trying to update this player. Please try again later.'
        )
      }
    },
  })

  const setCoordinates = (point: IMapPoint) => {
    formik.setFieldValue('geographics.location', {
      ...formik.values.geographics.location,
      type: EnumPointType.Point,
      coordinates: [point.lng, point.lat],
    })
  }

  const setCountry = (countryId: string) => {
    formik.setFieldValue('geographics.address', {
      ...formik.values.geographics.address,
      country_id: countryId,
    })
  }

  const setAreaType = (areaTypeId: string) => {
    formik.setFieldValue('demographics', {
      ...formik.values.demographics,
      area_type_id: areaTypeId,
    })
  }

  const setVenueType = (venueTypeId: string) => {
    const newVenueTypeIds = [...formik.values.demographics.venue_type_ids]
    const idIndex = newVenueTypeIds.findIndex(
      (venue_type_id) => venue_type_id === venueTypeId
    )

    if (idIndex === -1) newVenueTypeIds.push(venueTypeId)
    else newVenueTypeIds.splice(idIndex, 1)

    formik.setFieldValue('demographics', {
      ...formik.values.demographics,
      venue_type_ids: newVenueTypeIds,
    })
  }

  const isVenueTypeChecked = (venueTypeId: string) =>
    (formik.values.demographics?.venue_type_ids || []).includes(venueTypeId)

  const splitVenueTypes = _.chunk(venueTypesData?.venueTypes || [], 4)
  const areaTypes: CardResourceProps[] = (areaTypesData?.areaTypes || []).map(
    (areaType) => ({
      value: areaType._id,
      title: areaType.name || '',
      description: areaType.description || '',
      icon: (areaType.name?.toLowerCase().includes('indoor') && (
        <IndoorIcon width={24} height={24} title={areaType.name || ''} />
      )) ||
        (areaType.name?.toLowerCase().includes('outdoor') && (
          <OutdoorIcon width={24} height={24} title={areaType.name || ''} />
        )) || (
          <BillboardIcon width={24} height={24} title={areaType.name || ''} />
        ),
    })
  )

  return (
    <form onSubmit={formik.handleSubmit} tw="max-w-xl">
      {!!updatePlayerError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              message: updatePlayerError,
              variant: AlertVariant.ERROR,
              id: 'update-network-error-alert',
            }}
          />
        </div>
      )}
      <section tw="mb-12 max-w-xs">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Information
        </span>
        <InputFormik
          type="text"
          name="name"
          label="Name"
          onChange={formik.handleChange}
          formik={formik}
        />
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Address
        </span>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <InputFormik
              type="text"
              label="Street Name"
              name="geographics.address.street"
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
          <div tw="w-24">
            <InputFormik
              type="text"
              label="Suite, Apt."
              name="geographics.address.number"
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
        </div>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <InputFormik
              type="text"
              label="City"
              name="geographics.address.city"
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
          <div tw="w-32 pr-10">
            <InputFormik
              type="text"
              label="State"
              name="geographics.address.state"
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
          <div tw="w-32">
            <InputFormik
              type="text"
              label="ZIP Code"
              name="geographics.address.zip"
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
        </div>
        <div tw="w-1/2">
          <CountryInput
            showLabel
            onSelect={setCountry}
            error={formik.errors.geographics?.address?.country_id}
            initialValue={formik.values.geographics?.address?.country_id}
            success={
              formik.touched.geographics?.address?.country_id &&
              !formik.errors.geographics?.address?.country_id
            }
          />
        </div>
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Geolocation
        </span>
        <Map
          height="18rem"
          onSetPoint={setCoordinates}
          points={formikCoordinates(formik.values)}
          error={formik.errors.geographics?.location?.coordinates?.toString()}
        />
      </section>
      <section tw="mb-10">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Demographics
        </span>

        <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Area Type
        </span>
        <div tw="text-charcoal text-base mb-6">
          Improve your player's reachability by defining the Area Type.
        </div>
        <CardResourceRadio
          cards={areaTypes}
          onChange={setAreaType}
          value={formik.values.demographics?.area_type_id}
        />
        {!!formik.errors.demographics?.area_type_id && (
          <ErrorMessage tw="mt-2">
            {formik.errors.demographics?.area_type_id}
          </ErrorMessage>
        )}

        <span tw="mt-8 mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Venue Type
        </span>
        <div tw="flex">
          {splitVenueTypes.map((venues, chunkIndex) => (
            <div tw="w-40" key={`venue-types#${chunkIndex}`}>
              {venues.map((venue, venueIndex) => (
                <div
                  key={`venue-${venue._id}`}
                  css={[venueIndex !== venues.length - 1 && tw`mb-1`]}
                >
                  <Checkbox
                    value={venue._id}
                    label={venue.name}
                    checked={isVenueTypeChecked(venue._id)}
                    onChange={() => setVenueType(venue._id)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update player details
      </Button>
    </form>
  )
}

export default EditPlayerForm
