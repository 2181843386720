import React, { FC } from 'react'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Spacer from '../../../../ui-blocks/spacer'
import SearchInput from '../../../../ui-blocks/search'

import useElementStates from '../../../../utils/use-element-states'

const SearchInputSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
}) => {
  const { states, values } = useElementStates(['loading', 'disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <SearchInput loading={values.loading} disabled={values.disabled} />
      <Spacer size="1.5rem" />
      <SearchInput
        placeholder="Placeholder..."
        entries={['entrie#1', 'entrie#2', 'entrie#3']}
        {...values}
      />
      <Spacer size="1.5rem" />
      <SearchInput
        initialValue="Initial value"
        entries={['entrie#1', 'entrie#2', 'entrie#3']}
        {...values}
      />
      <Spacer size="1.5rem" />
      <SearchInput
        filter
        placeholder="With filters"
        entries={['entrie#1', 'entrie#2', 'entrie#3']}
        initialFilters={['entrie#2']}
        {...values}
      />
    </ElementSection>
  )
}

export default SearchInputSection
