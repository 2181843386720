import React, { FC, Fragment, useMemo, useState } from 'react'
import _ from 'lodash'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

import RestoreModal from '../restore-modal'
import RestoreAction from '../restore-action'

import InlineDate from '../../../../components/inline-date'
import Table, { TableDataProps } from '../../../../components/table'

import {
  NetworkListFragment,
  useRestoreNetworksMutation,
} from '../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
} from '../../../../utils/data-manipulation'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

const ArchivedNetworksListTable: FC<TableDataProps<NetworkListFragment>> = ({
  data,
  ...props
}) => {
  const [
    restoreNetwork,
    { loading: restoringNetwork },
  ] = useRestoreNetworksMutation()

  const [isRestoreModalOpen, openRestoreModal] = useState<boolean>(false)
  const [
    selectedNetwork,
    setSelectedNetwork,
  ] = useState<NetworkListFragment | null>(null)

  const onCloseModal = () => {
    openRestoreModal(false)
    setSelectedNetwork(null)
  }

  const onSelectNetwork = (network: NetworkListFragment) => {
    setSelectedNetwork(network)
    openRestoreModal(true)
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'restore-network-error-alert',
      message: "We couldn't restore this network. Please try again later.",
    })
  }

  const onRestoreNetwork = async () => {
    try {
      const res = await restoreNetwork({
        variables: { ids: [selectedNetwork?._id] },
      })
      if (!res.data?.areRestored) return handleError()

      onCloseModal()
      document.dispatchEvent(new CustomEvent('refetch-networks'))
      document.dispatchEvent(new CustomEvent('refetch-archived-networks'))
    } catch {
      handleError()
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((network) => ({
        name: {
          value: network.name,
          content: network.name,
        },
        players: {
          value: network.players.length,
          content: network.players.length,
        },
        createdAt: {
          value: network.created?.at,
          content: <InlineDate date={network.created?.at} />,
        },
        archivedAt: {
          value: network.archived?.at,
          content: <InlineDate date={network.archived?.at} />,
        },
        restore: {
          content: <RestoreAction onClick={() => onSelectNetwork(network)} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Network"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.networks_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'players',
            label: 'Players',
            isSortable: true,
            alignment: 'right',
            width: 70,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'archivedAt',
            label: 'Archived At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'restore', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RestoreModal
        target="network"
        open={isRestoreModalOpen}
        loading={restoringNetwork}
        onConfirm={onRestoreNetwork}
        itemName={selectedNetwork?.name || ''}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default ArchivedNetworksListTable
