import React, { FC } from 'react'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Spacer from '../../../../ui-blocks/spacer'
import ViewControls from '../../../../ui-blocks/view-controls'

import useElementStates from '../../../../utils/use-element-states'

const ViewControlsSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
}) => {
  const { states, values } = useElementStates(['disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <ViewControls
        options={[
          { id: 'value#1', label: 'Value#1' },
          { id: 'value#2', label: 'Value#2' },
          { id: 'value#3', label: 'Value#3' },
          { id: 'value#4', label: 'Value#4' },
        ]}
        {...values}
      />
      <Spacer size="1.5rem" />
      <ViewControls
        value="value#4"
        options={[
          { id: 'value#1', label: 'Value#1' },
          { id: 'value#2', label: 'Value#2' },
          { id: 'value#3', label: 'Value#3' },
          { id: 'value#4', label: 'Value#4' },
        ]}
        {...values}
      />
    </ElementSection>
  )
}

export default ViewControlsSection
