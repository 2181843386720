import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from '../../../ui-blocks/icon'

export interface CreativeSelectorProps {
  currentIndex: number
  totalCreatives: number
  creativeName: string
  onDecrease?: () => void
  onIncrease?: () => void
  onClose?: () => void
}

const CreativeSelector: FC<CreativeSelectorProps> = ({
  currentIndex,
  totalCreatives,
  creativeName,
  onDecrease,
  onIncrease,
  onClose,
}) => (
  <div
    tw="grid gap-4 bg-black-60 font-medium text-white py-6 px-14 absolute top-0 left-0 right-0 z-50"
    css={totalCreatives > 1 ? tw`grid-cols-3` : tw`grid-cols-2`}
  >
    <p tw="truncate">{creativeName}</p>
    {totalCreatives > 1 && (
      <div tw="flex flex-row items-center justify-center space-x-4">
        <Icon
          icon="chevron-left"
          onClick={onDecrease}
          css={[
            tw`text-3xl transition duration-200`,
            currentIndex === 0
              ? tw`text-white-60 cursor-not-allowed`
              : tw`cursor-pointer`,
          ]}
        />
        <p tw="select-none whitespace-nowrap">
          {`${currentIndex + 1} of ${totalCreatives}`}
        </p>
        <Icon
          icon="chevron-right"
          onClick={onIncrease}
          css={[
            tw`text-3xl transition duration-200`,
            currentIndex === totalCreatives - 1
              ? tw`text-white-60 cursor-not-allowed`
              : tw`cursor-pointer`,
          ]}
        />
      </div>
    )}
    <div tw="flex flex-row items-center justify-end">
      <Icon
        icon="close"
        onClick={onClose}
        tw="self-end text-3xl hover:cursor-pointer"
      />
    </div>
  </div>
)

export default CreativeSelector
