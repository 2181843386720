import React, { FC } from 'react'

import 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string, array, number } from 'yup'

import Map from '../../../../ui-blocks/map'
import Input from '../../../../ui-blocks/input'

import CountryInput from '../../../../components/country-input'

import { EnumPointType } from '../../../../graphql/components'
import { IMapPoint } from '../../../../typings'

import { BindPlayerFormikValues } from '..'

export const playerLocationValidationSchema = object().shape({
  geographics: object().shape({
    location: object().shape({
      type: string().oneOf([EnumPointType.Point]).required(),
      coordinates: array().of(number()).required('Location is required'),
    }),
    address: object().shape({
      street: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('Street name is required')
        .strict(true),
      number: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('Suite, Apt. is required'),
      city: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('City is required')
        .strict(true),
      state: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('State is required')
        .strict(true),
      zip: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('ZIP Code is required')
        .strict(true),
      country_id: string().required('Country is required'),
    }),
  }),
})

const formikCoordinates = (player: BindPlayerFormikValues): IMapPoint[] => {
  const coords = player?.geographics?.location?.coordinates || []
  return coords.length ? [{ lng: coords[0], lat: coords[1] } as IMapPoint] : []
}

const PlayerLocationStep: FC = () => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
  } = useFormikContext<BindPlayerFormikValues>()

  const setCoordinates = (point: IMapPoint) => {
    setFieldValue('geographics.location', {
      ...values.geographics.location,
      type: EnumPointType.Point,
      coordinates: [point.lng, point.lat],
    })
  }

  const setCountry = (countryId: string) => {
    setFieldValue('geographics.address', {
      ...values.geographics.address,
      country_id: countryId,
    })
  }

  return (
    <div tw="max-w-xl">
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Address
        </span>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <Input
              type="text"
              label="Street Name"
              name="geographics.address.street"
              value={values?.geographics?.address?.street || ''}
              error={errors.geographics?.address?.street}
              onChange={handleChange}
            />
          </div>
          <div tw="w-24">
            <Input
              type="text"
              label="Suite, Apt."
              name="geographics.address.number"
              value={values?.geographics?.address?.number || ''}
              error={errors.geographics?.address?.number}
              onChange={handleChange}
            />
          </div>
        </div>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <Input
              type="text"
              label="City"
              name="geographics.address.city"
              value={values?.geographics?.address?.city || ''}
              error={errors.geographics?.address?.city}
              onChange={handleChange}
            />
          </div>
          <div tw="w-32 pr-10">
            <Input
              type="text"
              label="State"
              name="geographics.address.state"
              value={values?.geographics?.address?.state || ''}
              error={errors.geographics?.address?.state}
              onChange={handleChange}
            />
          </div>
          <div tw="w-32">
            <Input
              type="text"
              label="ZIP Code"
              name="geographics.address.zip"
              value={values?.geographics?.address?.zip || ''}
              error={errors.geographics?.address?.zip}
              onChange={handleChange}
            />
          </div>
        </div>
        <div tw="w-1/2">
          <CountryInput
            showLabel
            onSelect={setCountry}
            error={errors.geographics?.address?.country_id?.toString() || ''}
            initialValue={values.geographics?.address?.country_id}
          />
        </div>
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Geolocation
        </span>
        <Map
          height="18rem"
          onSetPoint={setCoordinates}
          points={formikCoordinates(values)}
          error={errors.geographics?.location?.coordinates?.toString() || ''}
        />
      </section>
    </div>
  )
}

export default PlayerLocationStep
