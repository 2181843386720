import React, { FC } from 'react'

import tw from 'twin.macro'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import ElementSection, { ElementSectionProps } from './element-section'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

const IllustrationSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
}) => (
  <ElementSection id={id} title={title} description={description}>
    <div tw="grid grid-cols-4 auto-rows-fr gap-4">
      {Object.values(IllustrationEnum).map((name, index) => (
        <CopyToClipboard
          key={`illustration#${index}`}
          text={`<Illustration name={IllustrationEnum.${name}} />`}
        >
          <div
            css={[
              tw`flex items-center justify-center bg-white border rounded border-platinum p-2 transition duration-200 hover:cursor-pointer`,
              '&:hover { box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.101025); }',
            ]}
          >
            <Illustration name={name} tw="max-h-44" />
          </div>
        </CopyToClipboard>
      ))}
    </div>
  </ElementSection>
)

export default IllustrationSection
