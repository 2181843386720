import React, { FC, useMemo } from 'react'
import 'twin.macro'

import {
  Informations,
  InformationsSection,
} from '../../../../../ui-blocks/information'
import Status from '../../../../../ui-blocks/status'
import Button from '../../../../../ui-blocks/button'
import KPICard from '../../../../../ui-blocks/kpi-card'

import Saturation from '../../../../../components/saturation'
import InlineDate from '../../../../../components/inline-date'

import {
  EnumCampaignAdGroupStatus,
  CampaignAdGroupFullFragment,
} from '../../../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../../utils/stats-intervals'
import NotDefined from '../../../../../components/not-defined'

export interface OverviewSectionProps {
  campaignAdGroup?: CampaignAdGroupFullFragment
  onEdit?: () => void
}

const OverviewSection: FC<OverviewSectionProps> = ({
  campaignAdGroup,
  onEdit,
}) => {
  const intervals = useMemo(getKPIIntervals, [])
  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, campaignAdGroup?.stats || []),
    [intervals, campaignAdGroup]
  )

  return (
    <section>
      <Informations title="Ad Group Information" tw="mb-14">
        <InformationsSection label="STATUS">
          <Status value={campaignAdGroup?.status as EnumCampaignAdGroupStatus}>
            {campaignAdGroup?.status === EnumCampaignAdGroupStatus.Pending && (
              <>
                <p tw="font-medium text-charcoal leading-tight mb-4">
                  Your Ad Group is still pending.
                </p>
                <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
                  You need to add a schedule to your Ad Group to activate it.
                </span>
                <Button onClick={onEdit}>Edit Ad Group</Button>
              </>
            )}
          </Status>
        </InformationsSection>
        <InformationsSection label="REDIRECT TO">
          {!!campaignAdGroup?.redirect_to ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={campaignAdGroup?.redirect_to}
              title={campaignAdGroup?.redirect_to}
              tw="text-purple underline"
            >
              URL
            </a>
          ) : (
            <NotDefined />
          )}
        </InformationsSection>
        <InformationsSection label="START">
          <InlineDate date={campaignAdGroup?.timeframe?.starts_at} />
        </InformationsSection>
        <InformationsSection label="END">
          <InlineDate date={campaignAdGroup?.timeframe?.ends_at} />
        </InformationsSection>
        <InformationsSection label="AREA TYPE">
          {campaignAdGroup?.audience?.area_type}
        </InformationsSection>
        <InformationsSection label="CREATIVES">
          {campaignAdGroup?.creatives?.length ?? 0}
        </InformationsSection>
        <InformationsSection label="SATURATION">
          <Saturation saturation={campaignAdGroup?.saturation} />
        </InformationsSection>
      </Informations>
      <KPICard title="Ad Group Analytics" intervals={stats} />
    </section>
  )
}

export default OverviewSection
