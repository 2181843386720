import React, { FC } from 'react'
import 'twin.macro'

import {
  SelectCardFormik,
  SelectCardFormikProps,
} from '../../ui-blocks/select-card'
import Icon from '../../ui-blocks/icon'

import { NetworkListFragment } from '../../graphql/components'

interface NetworkSelectCardProps extends SelectCardFormikProps {
  network: NetworkListFragment
}

const NetworkSelectCard: FC<NetworkSelectCardProps> = ({
  network,
  ...props
}) => (
  <SelectCardFormik {...props}>
    <div tw="flex flex-row items-center justify-between">
      <div tw="flex flex-row items-center truncate">
        <p title={network.name} tw="font-medium text-charcoal truncate">
          {network.name}
        </p>
        {!!network.is_external && (
          <Icon
            icon="link"
            title="This is an external Network"
            tw="text-base text-charcoal ml-1 transform rotate-45"
          />
        )}
      </div>
      <div tw="flex flex-row items-center text-dark-blue-gray ml-2">
        <Icon icon="computer" tw="text-lg mr-1" />
        <span>
          {network.players?.filter((player) => player?.removed?.at === null)
            .length || 0}
        </span>
      </div>
    </div>
  </SelectCardFormik>
)

export default NetworkSelectCard
