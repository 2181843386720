import 'twin.macro'
import React, { FC, useMemo } from 'react'

import Icon from '../../../../ui-blocks/icon'
import Search from '../../../../ui-blocks/search'
import ButtonLink from '../../../../ui-blocks/button-link'
import PageHeader from '../../../../ui-blocks/page-header'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import CampaignAdGroupsListTable from '../../../../components/campaign-ad-group/table'

import { CampaignFullFragment } from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'
import { regexpFromString } from '../../../../utils/regexp-from-string'

export interface AdGroupsSectionProps {
  loading?: boolean
  campaign?: CampaignFullFragment
}

const AdGroupsSection: FC<AdGroupsSectionProps> = ({ loading, campaign }) => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const allAdGroups = useMemo(() => campaign?.ad_groups || [], [campaign])
  const filteredAdGroups = useMemo(
    () =>
      allAdGroups.filter((adGroup) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(adGroup.name)
          : adGroup
      ), // Filter by search query second
    [allAdGroups, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredAdGroups.length,
    15,
    searchTextDebounced
  )
  return (
    <section>
      <PageHeader
        title="Campaign Ad Groups"
        titlePreset="h4"
        description="Ad groups allows you to segment creatives by time, geolocation, and demographics"
      >
        <div tw="max-w-80 justify-self-end">
          <Search
            value={searchText}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>

        {!campaign?.is_external && (
          <ButtonLink
            className="add-ad-group-btn"
            iconLeft={<Icon icon="add" tw="text-lg" />}
            to={`/campaigns/${campaign?._id}/ad-groups/create`}
          >
            Add Ad Group
          </ButtonLink>
        )}
      </PageHeader>

      <CampaignAdGroupsListTable
        loading={loading}
        data={filteredAdGroups}
        searchQuery={searchTextDebounced}
        interactable={!campaign?.is_external}
      />

      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default AdGroupsSection
