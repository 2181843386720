import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import { Tabs, TabPane } from '../../../../ui-blocks/tabs'

import useElementStates from '../../../../utils/use-element-states'

const TabSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const { states, values } = useElementStates(['tabBarExtraContent'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <Tabs
        defaultActiveKey="tab-1"
        tabBarExtraContent={
          values.tabBarExtraContent ? () => <p>extra content</p> : undefined
        }
      >
        <TabPane key="tab-1" tab="Tab 1" tw="mt-5">
          Content#1
        </TabPane>
        <TabPane key="tab-2" tab="Tab 2" tw="mt-5">
          Content#2
        </TabPane>
        <TabPane key="tab-3" tab="Tab 3" tw="mt-5">
          Content#3
        </TabPane>
        <TabPane key="tab-4" tab="Tab 4" tw="mt-5">
          Content#4
        </TabPane>
      </Tabs>
    </ElementSection>
  )
}

export default TabSection
