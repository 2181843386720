import './sentry'
import './crisp'
import './hotjar'
import '@openfonts/fira-sans_all'
import './index.css'
import 'mono-icons/iconfont/icons.css'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './service-worker'
import App from './app'

// render the app
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
