import 'twin.macro'
import React, { FC, useEffect, useState, useMemo } from 'react'

import { useDropzone } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

import Icon from '../../../ui-blocks/icon'
import Search from '../../../ui-blocks/search'
import Button from '../../../ui-blocks/button'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import PageHeader from '../../../ui-blocks/page-header'
import ViewControls from '../../../ui-blocks/view-controls'
import PaginationNav from '../../../ui-blocks/pagination-nav'

import UploadsArea from './components/uploads-area'
import CreativesEmpty from './components/creatives-empty'
import CreativesNoResults from './components/creatives-no-results'

import LoadingPage from '../../../components/loading-page'
import CreativeCard from '../../../components/creative/card'
import PreviewScreen from '../../../components/preview-screen'
import CreativesListTable from '../../../components/creative/table'

import {
  EnumCreativeType,
  useListCreativesQuery,
  useCountCreativesQuery,
  useOnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscription,
  OnCreativesStatusChangeSubscriptionVariables,
} from '../../../graphql/components'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import useCallBackOnSubscriptionChange, {
  IUseCallBackOnSubscriptionChange,
} from '../../../utils/use-callback-on-subscription-change'

import { uploadsManager } from '../../../stores'

const ListCreativesPage: FC = () => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const [view, setView] = useState<string>('card-view')
  const [previewIndex, setPreviewIndex] = useState<number>(0)
  const [isPreviewOpen, openPreview] = useState<boolean>(false)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountCreativesQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: filteredCountData,
    loading: loadingFilteredCount,
    refetch: refetchFilteredCount,
  } = useCountCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: { filter: { search: searchTextDebounced } },
  })

  const creativesCount = useMemo(() => filteredCountData?.creatives || 0, [
    filteredCountData,
  ])

  const totalCreativesCount = useMemo(() => countData?.creatives || 0, [
    countData,
  ])

  const pagination = usePagination(creativesCount, 15, searchTextDebounced)
  const {
    data: creativesData,
    loading: loadingCreatives,
    refetch: refetchCreatives,
  } = useListCreativesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const allCreatives = useMemo(() => creativesData?.creatives || [], [
    creativesData,
  ])

  const allCreativesId = useMemo(
    () => allCreatives.map((creative) => creative._id),
    [allCreatives]
  )

  const refetchData = async () => {
    await refetchCount()
    await refetchFilteredCount()
    await refetchCreatives()
  }

  const subscriptionListener = useMemo(
    () =>
      ({
        callback: refetchData,
        subscription: useOnCreativesStatusChangeSubscription,
        subscriptionOptions: {
          fetchPolicy: 'no-cache',
          skip: allCreativesId.length === 0,
          variables: {
            _ids: allCreativesId,
          },
        },
      } as IUseCallBackOnSubscriptionChange<
        OnCreativesStatusChangeSubscription,
        OnCreativesStatusChangeSubscriptionVariables
      >),
    [allCreativesId, refetchData]
  )

  useCallBackOnSubscriptionChange(subscriptionListener)

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-creatives', refetchData)
    return () => {
      document.removeEventListener('refetch-creatives', refetchData)
    }
  }, [])

  const onOpenPreview = (index: number) => {
    setPreviewIndex(index)
    openPreview(true)
  }

  const onClosePreview = () => {
    setPreviewIndex(0)
    openPreview(false)
  }

  const goToNextCreative = () => {
    if (previewIndex < allCreatives.length - 1)
      setPreviewIndex(previewIndex + 1)
  }

  const goToPreviousCreative = () => {
    if (previewIndex > 0) setPreviewIndex(previewIndex - 1)
  }

  const onDrop = async (acceptedFiles: File[]) => {
    try {
      await Promise.all(
        acceptedFiles.map((file) =>
          uploadsManager.validateAndEnqueueUpload({
            file,
            type: EnumCreativeType.Ad,
          })
        )
      )
    } catch (error) {
      console.error('Upload failed:', error)
    } finally {
      await refetchData()
    }
  }

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    inputRef: uploadInputRef,
  } = useDropzone({
    onDrop,
    accept: 'video/*, image/jpeg, image/png',
  })

  return (
    <Container>
      <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
        <PageHeader
          title="Creatives"
          description={
            loadingCreatives ? 'Loading...' : `${totalCreativesCount} TOTAL`
          }
        >
          {!!totalCreativesCount && (
            <div tw="max-w-80 justify-self-end">
              <Search
                value={searchText}
                loading={loadingCreatives && loadingFilteredCount}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          )}

          {!!totalCreativesCount && (
            <ViewControls
              value={view}
              onSelect={setView}
              options={[
                {
                  id: 'card-view',
                  label: 'Card View',
                  icon: <Icon icon="grid" tw="text-base" />,
                },
                {
                  id: 'list-view',
                  label: 'List View',
                  icon: <Icon icon="three-rows" tw="text-base" />,
                },
              ]}
            />
          )}

          {!!totalCreativesCount && (
            <Button
              tw="justify-self-end"
              iconLeft={<Icon icon="cloud-upload" tw="text-lg" />}
              onClick={() => uploadInputRef.current?.click()}
            >
              Upload
            </Button>
          )}
        </PageHeader>

        <Suspense
          ready={!!totalCreativesCount}
          fallback={
            <CreativesEmpty
              onUploadClick={() => uploadInputRef.current?.click()}
            />
          }
        >
          {view === 'card-view' && (
            <Suspense
              ready={!!allCreatives.length}
              fallback={
                <Suspense ready={!loadingCreatives} fallback={<LoadingPage />}>
                  <CreativesNoResults searchQuery={searchTextDebounced} />
                </Suspense>
              }
            >
              <Suspense ready={!loadingCreatives} fallback={<LoadingPage />}>
                {/* @NOTE: this parent grid is only here to fix Safari's height bug on grids that do not specify a number of rows */}
                <div tw="grid">
                  <div tw="grid auto-rows-fr grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8">
                    <UploadsArea
                      rootProps={getRootProps()}
                      isDragActive={isDragActive}
                    />
                    {allCreatives.map((creative, index) => (
                      <CreativeCard
                        creative={creative}
                        key={`creative-${creative._id}`}
                        onClickThumbnail={() => onOpenPreview(index)}
                      />
                    ))}
                  </div>
                </div>
              </Suspense>
              <PaginationNav
                page={pagination.page}
                count={pagination.count}
                limit={pagination.limit}
                setPage={pagination.setPage}
                tw="mt-4"
              />
            </Suspense>
          )}
          {view === 'list-view' && (
            <div tw="flex flex-col">
              <div tw="w-full h-48 max-w-4xl mb-8 self-center">
                <UploadsArea
                  rootProps={getRootProps()}
                  isDragActive={isDragActive}
                />
              </div>
              <CreativesListTable
                data={allCreatives}
                loading={loadingCreatives && loadingFilteredCount}
                searchQuery={searchTextDebounced}
                onClickThumbnail={onOpenPreview}
              />
              <PaginationNav
                page={pagination.page}
                count={pagination.count}
                limit={pagination.limit}
                setPage={pagination.setPage}
                tw="mt-4"
              />
            </div>
          )}
        </Suspense>
      </Suspense>
      <PreviewScreen
        open={isPreviewOpen}
        creatives={allCreatives}
        currentIndex={previewIndex}
        onClose={onClosePreview}
        onNext={goToNextCreative}
        onPrevious={goToPreviousCreative}
      />
      <input {...getInputProps()} />
    </Container>
  )
}

export default ListCreativesPage
