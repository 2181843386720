import React, { FC } from 'react'
import tw from 'twin.macro'

import Text from '../../../../ui-blocks/text'

import { formatMetrics } from '../../../../utils/formaters'

export interface ViewTabProps {
  value: number
  label: string
  maxWidth: number
  isLast?: boolean
  isSelected?: boolean
  icon: React.ComponentType<any>
  onClick?: () => void
}

const ViewTab: FC<ViewTabProps> = ({
  value,
  label,
  maxWidth,
  isLast,
  isSelected,
  icon: Icon,
  onClick,
}) => (
  <div
    onClick={onClick}
    css={[
      `max-width: ${maxWidth}%;`,
      tw`w-full border-b border-platinum px-8 py-4 bg-transparent transition duration-200 hover:cursor-pointer`,
      ...(isSelected
        ? [tw`bg-light-violet`, 'border-bottom: 1px solid #572779']
        : []),
      !isLast && tw`border-r`,
    ]}
  >
    <Icon width={24} height={24} />
    <Text as="h4" preset="h2">
      {formatMetrics(value)}
    </Text>
    <Text as="p" preset="p2" weight="medium">
      {label}
    </Text>
  </div>
)

export default ViewTab
