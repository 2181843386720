import 'twin.macro'

import moment from 'moment'
import React, { FC, useMemo } from 'react'
import Checkbox from '../../ui-blocks/checkbox'
import { HourPicker } from '../../ui-blocks/hour-picker'
import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../../utils/use-controlled-or-internal-value'
import { timetableRuleSchema } from './schemas'
import { ITimetableRule } from './types'
import { getLocaleWeekday } from './utils'
import { cloneDeep } from 'lodash'
import { FormLabel } from '../../ui-blocks/form-label'
import ErrorMessage from '../error-message'

export interface TimetableInputProps
  extends IUseControlledOrInternalValueProps<ITimetableRule> {
  onSave?: (value: ITimetableRule) => void
  onDelete?: (value: ITimetableRule) => void
  disabled?: boolean
  error?: any
}

export const TimetableRuleInput: FC<TimetableInputProps> = ({
  value: origValue,
  onChange: origOnChange,
  disabled,
  error,
  ...props
}) => {
  const { value, onChange } = useControlledOrInternalValue<ITimetableRule>({
    value: origValue,
    onChange: origOnChange,
  })

  const timetableRule = useMemo(() => timetableRuleSchema.cast(value), [value])

  return (
    <div tw="flex flex-row" {...props}>
      <div tw="flex-1 mb-4">
        <FormLabel label="Week days">Week days</FormLabel>
        <div tw="flex flex-col justify-between">
          {new Array(7).fill(0).map((_, day) => {
            return (
              <div key={day} tw="flex-initial mr-1">
                <Checkbox
                  disabled={disabled}
                  checked={timetableRule.weekdays.includes(day)}
                  label={getLocaleWeekday(day)}
                  onChange={(e) => {
                    const checked = e.target.checked
                    const newTimetableRule = cloneDeep(timetableRule)
                    const index = newTimetableRule.weekdays.indexOf(day)
                    // add
                    if (checked) newTimetableRule.weekdays.push(day)

                    // remove
                    if (!checked) newTimetableRule.weekdays.splice(index, 1)

                    // sort before saving
                    newTimetableRule.weekdays.sort()
                    onChange(newTimetableRule)
                  }}
                ></Checkbox>
              </div>
            )
          })}
        </div>
      </div>

      <div tw="flex-1 flex flex-col justify-start mb-4">
        <div tw="mb-4">
          <HourPicker
            disabled={disabled}
            label="Starts at"
            time={moment(timetableRule.start || '00:00', ['HH:mm'])}
            onChange={(time) => {
              const newTimetableRule = cloneDeep(timetableRule)
              newTimetableRule.start = time?.format('HH:mm') || '00:00'
              onChange(newTimetableRule)
            }}
          />
        </div>

        <div tw="mb-4">
          <HourPicker
            disabled={disabled}
            label="Ends at"
            time={moment(timetableRule.end || '00:00', ['HH:mm'])}
            onChange={(time) => {
              const newTimetableRule = cloneDeep(timetableRule)
              newTimetableRule.end = time?.format('HH:mm') || '00:00'
              onChange(newTimetableRule)
            }}
          />
        </div>
      </div>

      {!!error && (
        <ErrorMessage>
          {typeof error === 'string'
            ? error
            : error?.start ||
              error?.end ||
              error?.weekdays ||
              error?.allowed ||
              undefined}
        </ErrorMessage>
      )}
    </div>
  )
}
