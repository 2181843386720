import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Switch from '../../ui-blocks/switch'
import Status from '../../ui-blocks/status'
import Illustration, { IllustrationEnum } from '../../ui-blocks/illustration'

import Saturation from '../saturation'
import InlineDate from '../inline-date'
import Table, { TableDataProps } from '../table'

import {
  EnumCampaignAdGroupStatus,
  CampaignAdGroupFullFragment,
  useUpdateCampaignAdGroupActivationMutation,
} from '../../graphql/components'

import { sortObjectByKey, groupDataByYear } from '../../utils/data-manipulation'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

export interface CampaignAdGroupsListTableProps
  extends TableDataProps<CampaignAdGroupFullFragment> {
  interactable: boolean
}

const CampaignAdGroupsListTable: FC<CampaignAdGroupsListTableProps> = ({
  data,
  interactable,
  ...props
}) => {
  const [activateAdGroup] = useUpdateCampaignAdGroupActivationMutation()

  const handleError = (activate: boolean) => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'activate-ad-group-error-alert',
      message: `We couldn't ${
        activate ? 'activate' : 'deactivate'
      } this ad group. Please try again later.`,
    })
  }

  const onActivateAdGroup = async (adGroupId: string, activate: boolean) => {
    try {
      const res = await activateAdGroup({
        variables: { ids: [adGroupId], is_active: activate },
      })
      if (!res.data?.areUpdated) return handleError(activate)
      document.dispatchEvent(new CustomEvent('refetch-campaign-ad-groups'))
    } catch {
      handleError(activate)
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((adGroup) => ({
        switch: {
          content: (
            <Switch
              onSelect={(checked) => onActivateAdGroup(adGroup._id, checked)}
              checked={adGroup?.status === EnumCampaignAdGroupStatus.Activated}
              disabled={
                adGroup?.status === EnumCampaignAdGroupStatus.Waiting ||
                adGroup?.status === EnumCampaignAdGroupStatus.Pending ||
                adGroup?.status === EnumCampaignAdGroupStatus.Finished
              }
            />
          ),
        },
        name: {
          value: adGroup.name,
          content: interactable ? (
            <Link
              to={`/campaigns/${adGroup.campaign_id}/ad-groups/${adGroup._id}`}
              tw="text-purple"
            >
              {adGroup.name}
            </Link>
          ) : (
            <span tw="">{adGroup.name}</span>
          ),
        },
        status: {
          value: adGroup?.status,
          content: (
            <Status value={adGroup?.status as EnumCampaignAdGroupStatus} />
          ),
        },
        impressions: {
          value: adGroup.stats?.[0]?.reach ?? 0,
          content: adGroup.stats?.[0]?.reach ?? 0,
        },
        scans: {
          value: adGroup.stats?.[0]?.engagement ?? 0,
          content: adGroup.stats?.[0]?.engagement ?? 0,
        },
        creatives: {
          value: adGroup.creatives?.length ?? 0,
          content: adGroup.creatives?.length ?? 0,
        },
        saturation: {
          value: adGroup.saturation ?? 0,
          content: <Saturation saturation={adGroup.saturation} />,
        },
        areaType: {
          value: adGroup.audience?.area_type,
          content: adGroup.audience?.area_type,
        },
        startDate: {
          value: adGroup.timeframe?.starts_at,
          content: (
            <InlineDate date={adGroup.timeframe?.starts_at} format="ll" />
          ),
        },
        endDate: {
          value: adGroup.timeframe?.ends_at,
          content: <InlineDate date={adGroup.timeframe?.ends_at} format="ll" />,
        },
        createdAt: {
          value: adGroup.created?.at,
          content: <InlineDate date={adGroup.created?.at} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Table
      itemName="Ad Group"
      data={chronologicalData}
      emptyStateIllustration={
        <Illustration name={IllustrationEnum.ad_groups_empty_results} />
      }
      layout="fluid"
      cols={[
        ...(interactable ? [{ key: 'switch', width: 70 }] : []),
        { key: 'name', label: 'Name', isSortable: true },
        {
          key: 'status',
          label: 'Status',
          isSortable: true,
          alignment: 'center',
          width: 142,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          isSortable: true,
          alignment: 'right',
          width: 100,
        },
        {
          key: 'scans',
          label: 'Scans',
          isSortable: true,
          alignment: 'right',
          width: 100,
        },
        {
          key: 'creatives',
          label: 'Creatives',
          isSortable: true,
          alignment: 'right',
          width: 70,
        },
        {
          key: 'saturation',
          label: 'Saturation',
          isSortable: true,
          alignment: 'right',
          width: 70,
        },
        { key: 'areaType', label: 'Area Type', isSortable: true, width: 100 },
        {
          key: 'startDate',
          label: 'Start Date',
          isSortable: true,
          alignment: 'right',
          width: 142,
        },
        {
          key: 'endDate',
          label: 'End Date',
          isSortable: true,
          alignment: 'right',
          width: 142,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          isSortable: true,
          alignment: 'right',
          width: 150,
        },
      ]}
      {...props}
    />
  )
}

export default CampaignAdGroupsListTable
