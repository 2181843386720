import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import Icon from '../../../../../ui-blocks/icon'
import Text from '../../../../../ui-blocks/text'
import Search from '../../../../../ui-blocks/search'
import Button from '../../../../../ui-blocks/button'
import PaginationNav from '../../../../../ui-blocks/pagination-nav'

import PreviewScreen from '../../../../../components/preview-screen'
import CreativesListTable from '../../../../../components/creative/table'
import ManageCreativesModal from '../../../../../components/manage-creatives-modal'

import { CampaignAdGroupFullFragment } from '../../../../../graphql/components'

import usePagination from '../../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../../utils/use-search'
import { regexpFromString } from '../../../../../utils/regexp-from-string'

export interface CreativesSectionProps {
  loading?: boolean
  campaignAdGroup?: CampaignAdGroupFullFragment
  refetchCampaignAdGroup?: () => Promise<any>
}

const CreativesSection: FC<CreativesSectionProps> = ({
  loading,
  campaignAdGroup,
  refetchCampaignAdGroup,
}) => {
  const [
    isManageCreativesModalOpen,
    openManageCreativesModal,
  ] = useState<boolean>(false)

  const [previewIndex, setPreviewIndex] = useState<number>(0)
  const [isPreviewOpen, openPreview] = useState<boolean>(false)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const allCreatives = useMemo(() => campaignAdGroup?.creatives || [], [
    campaignAdGroup,
  ])

  const filteredCreatives = useMemo(
    () =>
      allCreatives.filter((creative) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(creative.name)
          : creative
      ),
    [allCreatives, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredCreatives.length,
    15,
    searchTextDebounced
  )

  const onOpenPreview = (index: number) => {
    setPreviewIndex(index)
    openPreview(true)
  }

  const onClosePreview = () => {
    setPreviewIndex(0)
    openPreview(false)
  }

  const goToNextCreative = () => {
    if (previewIndex < allCreatives.length - 1)
      setPreviewIndex(previewIndex + 1)
  }

  const goToPreviousCreative = () => {
    if (previewIndex > 0) setPreviewIndex(previewIndex - 1)
  }

  return (
    <section>
      <div tw="flex flex-row items-center justify-end mb-4">
        <div tw="w-80">
          <Search
            value={searchText}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
        <Button
          primary
          iconLeft={<Icon icon="add" tw="text-lg" />}
          onClick={() => openManageCreativesModal(true)}
          tw="ml-10"
        >
          Manage Creatives
        </Button>
      </div>
      <CreativesListTable
        loading={loading}
        data={filteredCreatives}
        searchQuery={searchTextDebounced}
        onClickThumbnail={onOpenPreview}
      />
      <div tw="flex flex-row items-center justify-between space-x-8">
        <Text
          as="span"
          preset="p2"
          decoration="underline"
          css={['text-decoration-style: dashed;']}
        >
          NOTE: The number of impressions and scans on the Creatives table refer
          to the overall total number of impressions and scans per creative.
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
          tw="mt-4"
        />
      </div>
      <ManageCreativesModal
        open={isManageCreativesModalOpen}
        adGroupId={campaignAdGroup?._id}
        refetchData={refetchCampaignAdGroup}
        onClose={() => openManageCreativesModal(false)}
      />
      <PreviewScreen
        open={isPreviewOpen}
        creatives={filteredCreatives}
        currentIndex={previewIndex}
        onClose={onClosePreview}
        onNext={goToNextCreative}
        onPrevious={goToPreviousCreative}
      />
    </section>
  )
}

export default CreativesSection
