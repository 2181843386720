import 'twin.macro'
import React, { FC, useMemo, useState } from 'react'

import Icon from '../../../../../../ui-blocks/icon'
import Button from '../../../../../../ui-blocks/button'
import Search from '../../../../../../ui-blocks/search'
import PageHeader from '../../../../../../ui-blocks/page-header'
import PaginationNav from '../../../../../../ui-blocks/pagination-nav'

import NetworksListTable from '../../../../../../components/network/table'
import ManageNetworksModal from '../../../../../../components/manage-networks-modal'

import {
  BroadcastTarget,
  CampaignFullFragment,
} from '../../../../../../graphql/components'

import usePagination from '../../../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../../../utils/use-search'
import { regexpFromString } from '../../../../../../utils/regexp-from-string'

export interface InternalNetworksProps {
  loading?: boolean
  targets: BroadcastTarget[]
  campaign?: CampaignFullFragment
  refetchCampaign?: () => Promise<any>
}

const InternalNetworks: FC<InternalNetworksProps> = ({
  loading,
  targets,
  campaign,
  refetchCampaign,
}) => {
  const [
    isManageNetworksModalOpen,
    openManageNetworksModal,
  ] = useState<boolean>(false)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const allNetworks = useMemo(() => targets.map((target) => target.network), [
    targets,
  ])

  const filteredNetworks = useMemo(
    () =>
      allNetworks.filter((network) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(network?.name || '')
          : network
      ), // Filter by search query second
    [allNetworks, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredNetworks.length,
    15,
    searchTextDebounced
  )

  return (
    <>
      <PageHeader
        title="Workspace Networks"
        titlePreset="h4"
        description={
          targets.length > 0
            ? `${targets.length} workspace's network(s)`
            : `No workspace's networks are target in this campaign`
        }
      >
        <div tw="max-w-80 justify-self-end">
          <Search
            value={searchText}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>

        <Button
          iconLeft={<Icon icon="add" tw="text-lg" />}
          onClick={() => openManageNetworksModal(true)}
        >
          Manage Networks
        </Button>
      </PageHeader>

      <NetworksListTable
        loading={loading}
        searchQuery={searchTextDebounced}
        data={filteredNetworks as any[]}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
      <ManageNetworksModal
        defaultFilter="internal"
        open={isManageNetworksModalOpen}
        campaign={campaign}
        refetchData={refetchCampaign}
        onClose={() => openManageNetworksModal(false)}
      />
    </>
  )
}

export default InternalNetworks
