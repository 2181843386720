import React, { FC } from 'react'

import 'twin.macro'
import { Moment } from 'moment'

import InlineDate from '../../../../../../components/inline-date'

import { authentication } from '../../../../../../stores'

export interface SharedByProps {
  email: string
  date: number | Date | Moment
}

const SharedBy: FC<SharedByProps> = ({ date, email }) => {
  const isLoggedInUser = !!authentication?.user?.emails?.find(
    (userEmail) => userEmail?.address === email
  )
  return (
    <div tw="flex flex-col items-end">
      <InlineDate date={date} />
      {!!email && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
          by {isLoggedInUser ? 'you' : email}
        </span>
      )}
    </div>
  )
}

export default SharedBy
