import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'

import Text from '../../../../ui-blocks/text'
import Switch from '../../../../ui-blocks/switch'
import Suspense from '../../../../ui-blocks/suspense'
import Container from '../../../../ui-blocks/container'
import BreadcrumbNav from '../../../../ui-blocks/breadcrumb-nav'
import { Tabs, TabPane } from '../../../../ui-blocks/tabs'

import OverviewSection from './components/overview-section'
import CreativesSection from './components/creatives-section'
import SettingsSection from './components/settings/section'

import NotFound from '../../../../components/not-found'
import LoadingPage from '../../../../components/loading-page'

import {
  EnumCampaignAdGroupStatus,
  useViewCampaignAdGroupQuery,
  useUpdateCampaignAdGroupActivationMutation,
} from '../../../../graphql/components'

import { getKPIIntervals } from '../../../../utils/stats-intervals'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

export enum AdGroupPageTabKeys {
  OVERVIEW = 'overview',
  CREATIVES = 'creatives',
  SETTINGS = 'settings',
}

export const adGroupPageRoutes = {
  [AdGroupPageTabKeys.OVERVIEW]: '',
  [AdGroupPageTabKeys.CREATIVES]: 'creatives',
  [AdGroupPageTabKeys.SETTINGS]: 'settings',
}

const ViewCampaignAdGroupPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_ad_group_id } = useParams()

  const [updateAdGroupActivation] = useUpdateCampaignAdGroupActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: campaignAdGroupData,
    loading: loadingCampaignAdGroup,
    refetch: refetchCampaignAdGroup,
  } = useViewCampaignAdGroupQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 60 * 1000,
    skip: !campaign_ad_group_id,
    variables: {
      _id: campaign_ad_group_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a campaign's ad group page if it is archived
  const { campaignAdGroup } = campaignAdGroupData || {}
  useEffect(() => {
    if (campaignAdGroup?.status === EnumCampaignAdGroupStatus.Archived)
      navigate(`/campaigns/${campaignAdGroup.campaign_id}`, {
        replace: true,
      })
  }, [campaignAdGroup])

  const handleUpdateError = (action: 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-ad-group-status-error-alert',
      message: `We couldn't ${action} this campaign's ad group. Please try again later.`,
    })
  }

  const onUpdateCampaignAdGroupActivation = async (activate: boolean) => {
    try {
      const res = await updateAdGroupActivation({
        variables: { ids: [campaign_ad_group_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-campaign-ad-groups'))
      await refetchCampaignAdGroup()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/campaigns/${campaignAdGroup?.campaign_id}/ad-groups/${
        campaignAdGroup?._id
      }/${adGroupPageRoutes[tabKey as AdGroupPageTabKeys]}`
    )
  }

  const isSwitchDisabled = useMemo(
    () =>
      campaignAdGroup?.status === EnumCampaignAdGroupStatus.Waiting ||
      campaignAdGroup?.status === EnumCampaignAdGroupStatus.Pending ||
      campaignAdGroup?.status === EnumCampaignAdGroupStatus.Finished ||
      campaignAdGroup?.status === EnumCampaignAdGroupStatus.Archived,
    [campaignAdGroup]
  )

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${AdGroupPageTabKeys.CREATIVES}`) &&
        AdGroupPageTabKeys.CREATIVES) ||
      (location.pathname.endsWith(`/${AdGroupPageTabKeys.SETTINGS}`) &&
        AdGroupPageTabKeys.SETTINGS) ||
      AdGroupPageTabKeys.OVERVIEW,
    [location]
  )

  return (
    <Suspense ready={!loadingCampaignAdGroup} fallback={<LoadingPage />}>
      <Suspense ready={!!campaignAdGroup} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              { label: 'Campaigns', linkTo: '/campaigns' },
              {
                label: campaignAdGroup?.campaign?.name || '',
                linkTo: `/campaigns/${campaignAdGroup?.campaign_id}`,
              },
              { label: campaignAdGroup?.name || '' },
            ]}
          />
          <div tw="flex flex-row items-center mb-6">
            <Switch
              disabled={isSwitchDisabled}
              checked={
                campaignAdGroup?.status === EnumCampaignAdGroupStatus.Activated
              }
              onSelect={onUpdateCampaignAdGroupActivation}
              tw="-ml-2 mr-2"
            />
            <Text as="h1" preset="h3">
              {campaignAdGroup?.name}
            </Text>
          </div>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane
              key={AdGroupPageTabKeys.OVERVIEW}
              tab="Overview"
              tw="mt-10"
            >
              <OverviewSection
                campaignAdGroup={campaignAdGroup || undefined}
                onEdit={() => handleTabClick(AdGroupPageTabKeys.SETTINGS)}
              />
            </TabPane>
            <TabPane
              key={AdGroupPageTabKeys.CREATIVES}
              tab="Creatives"
              tw="mt-10"
            >
              <CreativesSection
                loading={loadingCampaignAdGroup}
                campaignAdGroup={campaignAdGroup || undefined}
                refetchCampaignAdGroup={refetchCampaignAdGroup}
              />
            </TabPane>
            <TabPane
              key={AdGroupPageTabKeys.SETTINGS}
              tab="Settings"
              tw="mt-10"
            >
              <SettingsSection
                campaignAdGroup={campaignAdGroup || undefined}
                refetchCampaignAdGroup={refetchCampaignAdGroup}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewCampaignAdGroupPage
