import React, { FC, useEffect, useState } from 'react'

import 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Text from '../../../ui-blocks/text'
import Link from '../../../ui-blocks/link'
import Button from '../../../ui-blocks/button'
import CodeInput from '../../../ui-blocks/code-input'

import AlertComponent, {
  AlertVariant,
} from '../../../components/alert-component'

import {
  useVerifyEmailCodeMutation,
  useSendEmailVerificationCodeMutation,
} from '../../../graphql/components'

import { authentication } from '../../../stores'
import { tryCatch } from '../../../utils/try-catch'

interface VerifyEmailFormikValues {
  code: string
  email: string
}

const verifyEmailValidationSchema = object().shape({
  code: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(6, 'Value must be exactly 6 characters long')
    .required('Verification code is required'),
  email: string()
    .email('Must be a valid email address')
    .required('Email is required'),
})

const VerifyEmailCode: FC = () => {
  const navigate = useNavigate()
  const { email_index } = useParams()

  const [error, setError] = useState<string>('')
  const [loggingOut, setLoggingOut] = useState<boolean>(false)
  const [emailToVerify, setEmailToVerify] = useState<string>('')

  const [
    sendVerificationEmail,
    { loading: sendingCode },
  ] = useSendEmailVerificationCodeMutation()
  useEffect(() => {
    // Check if the index is a valid integer
    let emailIndex = 0
    try {
      emailIndex = Number.parseInt(email_index)
    } catch {
      return navigate('/')
    }

    // Check if the index is not out of bounds
    const userEmails = authentication.user?.emails || []
    if (emailIndex > userEmails.length - 1) return navigate('/')

    // Check if that email is not already verified
    if (!!userEmails[emailIndex]?.verified_at)
      return navigate('/auth/pending-workspace-invites')
    const userEmail = userEmails[emailIndex]?.address || ''

    setEmailToVerify(userEmail)
    if (!!userEmail) onSendCode(userEmail)
  }, [])

  const [
    verifyEmailCode,
    { loading: verfifyingCode },
  ] = useVerifyEmailCodeMutation()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const formik = useFormik<VerifyEmailFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: verifyEmailValidationSchema,
    initialValues: { email: emailToVerify, code: '' },
    async onSubmit(values) {
      try {
        setError('')
        const token = await executeRecaptcha?.('auth/verify_email/verify_email')
        const { data } = await verifyEmailCode({
          variables: values,
          context: {
            headers: {
              ...(!!token ? { 'X-Recaptcha-Token': token } : {}),
            },
          },
        })

        if (!data?.isCodeValid) {
          return setError(
            'Failed to verify provided code. Please try again and make sure the 6 digit number you typed matches the one provided in the email sent to the specified address.'
          )
        }

        await authentication.loadUserData()
        return navigate('')
      } catch {
        setError(
          "Something went wrong and we couldn't verify your email. Please try again later."
        )
      }
    },
  })

  const onSendCode = async (email?: string) => {
    formik.resetForm({ values: { ...formik.values, code: '' } })

    const token = await executeRecaptcha?.('auth/verify_email/request_email')
    const [res, error] = await tryCatch(
      sendVerificationEmail({
        variables: { email: email || emailToVerify },
        context: {
          headers: {
            ...(!!token ? { 'X-Recaptcha-Token': token } : {}),
          },
        },
      })
    )

    if (!!error || !res?.data?.isCodeSent)
      return setError('Failed to send code to the specified email address.')
    setError('')
  }

  const onSignOut = async () => {
    setLoggingOut(true)
    await authentication.logout()
    setLoggingOut(false)
    navigate('/auth/sign-in', { replace: true })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text as="h1" preset="h2" tw="mb-2">
        Check your inbox!
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray">
        {'We emailed a six-digit code to '}
        <span tw="text-charcoal">{emailToVerify}</span>
        {'.'}
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray mb-10">
        Enter the code bellow to confirm your email address.
      </Text>

      <div tw="mb-8">
        <CodeInput
          length={6}
          label="Verification Code"
          id="verify-email-code-input"
          value={formik.values.code}
          error={formik.errors.code}
          success={formik.touched.code && !formik.errors.code}
          onChange={(code) => formik.setFieldValue('code', code)}
          labelExtra={
            <Link
              secondary
              disabled={sendingCode}
              onClick={() => onSendCode()}
              tw="inline-flex min-h-auto p-0"
            >
              Resend Code
            </Link>
          }
        />
      </div>
      {!!error && (
        <div tw="mb-8">
          <AlertComponent
            width="100%"
            alert={{
              message: error,
              variant: AlertVariant.ERROR,
              id: 'email-verification-error-alert',
            }}
          />
        </div>
      )}

      <Text as="p" preset="p1" tw="text-dark-blue-gray mt-8 mb-4">
        Make sure to keep this window open while you check your inbox.
      </Text>
      <Text as="p" preset="p2" tw="mb-16">
        NOTE: The email may take up to 2 minutes to land on your inbox. Do not
        forget verify your SPAM folder in case you do not see the email before
        that time is up.
      </Text>
      <Button
        tw="block"
        type="submit"
        loading={verfifyingCode}
        disabled={!formik.dirty || formik.values.code.trim().length !== 6}
      >
        Confirm
      </Button>
      <Link disabled={loggingOut} onClick={onSignOut} tw="p-0 inline-flex mt-8">
        Logout
      </Link>
    </form>
  )
}

export default VerifyEmailCode
