import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import OutsideClickHandler from 'react-outside-click-handler'

import Icon from './icon'
import Card from './card'

export interface PopoverProps {
  open?: boolean
  cardPosition?: string
  pointerPosition?: string
  onClose: () => void
}

const Popover: FC<PopoverProps> = ({
  open,
  cardPosition,
  pointerPosition,
  onClose,
  children,
}) => (
  <OutsideClickHandler onOutsideClick={onClose}>
    <div
      css={[
        'width: 25rem;',
        'z-index: 100;',
        tw`absolute mt-6`,
        !open && tw`hidden`,
        !!cardPosition && `left: ${cardPosition};`,
      ]}
    >
      <Card
        css={css`
          ${tw`relative p-4`}
          &::after, &::before {
            content: '';
            bottom: 100%;
            border: solid transparent;
            left: ${pointerPosition || '3rem'};
            ${tw`w-0 h-0 absolute pointer-events-none`}
          }
          &::after {
            border-width: 0.875rem;
            margin-left: -0.875rem;
            border-color: transparent;
            border-bottom-color: white;
          }
          &::before {
            border-width: 1rem;
            margin-left: -1rem;
            border-color: transparent;
            border-bottom-color: #e7e8eb;
          }
        `}
      >
        <div tw="w-full block flex flex-row items-center justify-end pb-2">
          <Icon
            icon="close"
            tw="text-lg text-dark-blue-gray hover:cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div tw="px-6 pb-4 text-left">{children}</div>
      </Card>
    </div>
  </OutsideClickHandler>
)

export default Popover
