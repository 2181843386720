import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { useLocation } from 'react-router'

import Search from '../../../../ui-blocks/search'
import Suspense from '../../../../ui-blocks/suspense'
import Container from '../../../../ui-blocks/container'
import PageHeader from '../../../../ui-blocks/page-header'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ExternalCampaignsListTable from './components/table'
import ExternalCampaignsEmpty from './components/campaigns-empty'

import LoadingPage from '../../../../components/loading-page'

import { useListExternalCampaignsQuery } from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'
import { regexpFromString } from '../../../../utils/regexp-from-string'

const ListExternalCampaignsPage: FC = () => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: externalCampaignsData,
    loading: loadingExternalCampaigns,
    refetch: refetchExternalCampaigns,
  } = useListExternalCampaignsQuery({
    fetchPolicy: 'cache-first',
    variables: {},
  })

  const refetchData = async () => {
    await refetchExternalCampaigns()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-external-campaigns', refetchData)
    return () => {
      document.removeEventListener('refetch-external-campaigns', refetchData)
    }
  }, [])

  const allExternalCampaigns = useMemo(
    () => externalCampaignsData?.campaigns || [],
    [externalCampaignsData]
  )
  const filteredExternalCampaigns = useMemo(
    () =>
      allExternalCampaigns.filter((campaign) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(
              campaign?.campaign?.name || ''
            )
          : campaign
      ), // Filter by search query second
    [allExternalCampaigns, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredExternalCampaigns.length,
    15,
    searchTextDebounced
  )

  const campaignsGroups = useMemo(
    () => _.chunk(filteredExternalCampaigns, pagination.limit),
    [filteredExternalCampaigns, pagination]
  )
  const visibleCampaigns = useMemo(
    () => campaignsGroups[pagination.page] || [],
    [campaignsGroups, pagination]
  )

  return (
    <Suspense ready={!loadingExternalCampaigns} fallback={<LoadingPage />}>
      <Container>
        <PageHeader
          title="External Campaigns"
          description={
            loadingExternalCampaigns
              ? 'Loading...'
              : `${allExternalCampaigns.length} TOTAL`
          }
        >
          {!!allExternalCampaigns && (
            <div tw="max-w-80 justify-self-end">
              <Search
                value={searchText}
                loading={loadingExternalCampaigns}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          )}
        </PageHeader>

        <Suspense
          ready={!!allExternalCampaigns.length}
          fallback={<ExternalCampaignsEmpty />}
        >
          <ExternalCampaignsListTable
            data={visibleCampaigns as any[]}
            loading={loadingExternalCampaigns}
            searchQuery={searchTextDebounced}
          />
          <PaginationNav
            page={pagination.page}
            count={pagination.count}
            limit={pagination.limit}
            setPage={pagination.setPage}
            tw="mt-4"
          />
        </Suspense>
      </Container>
    </Suspense>
  )
}

export default ListExternalCampaignsPage
