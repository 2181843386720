import { useMemo } from 'react'
import {
  useListExternalNetworksQuery,
  useCountExternalNetworksQuery,
} from '../../../../graphql/components'
import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

export const useSearchExternalNetworks = () => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: totalNetworkCountData,
    loading: loadingTotalCount,
  } = useCountExternalNetworksQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: networkCountData,
    loading: loadingNetworkCount,
  } = useCountExternalNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const totalNetworksCount = useMemo(
    () => totalNetworkCountData?.networks || 0,
    [totalNetworkCountData]
  )

  const networksCount = useMemo(() => networkCountData?.networks || 0, [
    networkCountData,
  ])

  const pagination = usePagination(networksCount, 9, searchTextDebounced)

  const {
    data: networksData,
    loading: loadingNetworks,
  } = useListExternalNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const networks = useMemo(() => networksData?.networks || [], [networksData])

  return {
    totalNetworksCount,
    loadingTotalCount,
    networksCount,
    searchText,
    setSearchText,
    searchTextDebounced,
    networks: networks,
    loadingNetworks,
    loadingCount: loadingNetworkCount,
    pagination,
  }
}
