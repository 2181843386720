import React, { FC, Fragment, useEffect } from 'react'

import 'twin.macro'
import _ from 'lodash'

import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import { BindPlayerFormikValues } from '..'
import Grid from '../../../../components/grid'
import NetworkSelectCard from '../../../../components/network/select-card'
import { NetworkListFragment } from '../../../../graphql/components'
import PaginationNav from '../../../../ui-blocks/pagination-nav'
import Search from '../../../../ui-blocks/search'
import Spinner from '../../../../ui-blocks/spinner'
import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'
import Suspense from '../../../../ui-blocks/suspense'

export const targetNetworksValidationSchema = object().shape({
  networks_ids: array(string()).min(0),
})

export interface TargetNetworksStepProps {
  loadingCount: boolean
  loadingNetworks: boolean
  networksCount: number
  networks: NetworkListFragment[]
  pagination: ReturnType<typeof usePagination>
  onSearch?: (query: string) => void
}

const TargetNetworksStep: FC<TargetNetworksStepProps> = ({
  loadingCount,
  loadingNetworks,
  networksCount,
  networks,
  pagination,
  onSearch,
}) => {
  const { values, setFieldValue } = useFormikContext<BindPlayerFormikValues>()

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  useEffect(() => {
    onSearch?.(searchTextDebounced)
  }, [searchTextDebounced])

  const handleSelectNetwork = (networkId: string) => {
    if (!values.networks_ids.includes(networkId))
      return setFieldValue('networks_ids', [...values.networks_ids, networkId])
    setFieldValue('networks_ids', _.without(values.networks_ids, networkId))
  }

  return (
    <Fragment>
      <div tw="flex flex-row items-center justify-between mb-6">
        <p tw="text-dark-blue-gray text-xs uppercase">
          {loadingCount
            ? 'Loading...'
            : `${networksCount} Network(s), ${values.networks_ids.length} Selected`}
        </p>
        <div tw="w-80">
          <Search
            value={searchText}
            loading={loadingNetworks}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense ready={!loadingNetworks} fallback={<Spinner center />}>
        <Grid rows={3} cols={3} gap="1rem">
          {networks.map((network) => (
            <NetworkSelectCard
              name="networks_ids"
              network={network}
              value={network._id}
              key={`network-${network._id}`}
              onClick={() => handleSelectNetwork(network._id)}
              checked={values.networks_ids.includes(network._id)}
            />
          ))}
        </Grid>
        <div tw="flex flex-row items-center justify-between mt-4">
          <PaginationNav
            page={pagination.page}
            count={pagination.count}
            limit={pagination.limit}
            setPage={pagination.setPage}
          />
        </div>
      </Suspense>
    </Fragment>
  )
}

export default TargetNetworksStep
