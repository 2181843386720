import React, { FC, Fragment } from 'react'
import 'twin.macro'

import Text from '../../../../ui-blocks/text'
import { authentication } from '../../../../stores'
import Button from '../../../../ui-blocks/button'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

type SuccessStepProps = {
  email?: string
  password?: string
}

const SuccessStep: FC<SuccessStepProps> = ({ email, password }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()

  return (
    <Fragment>
      <Text as="h1" preset="h2" tw="mb-1">
        Password updated
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray mb-16">
        Your password was successfully updated. For security purposes we have
        sent an email to your account confirming this change.
      </Text>
      <Button
        type="button"
        onClick={async () => {
          try {
            if (email && password) {
              const reCaptchaToken = await executeRecaptcha?.(
                'auth/forgot_password/success'
              )
              await authentication.login(email, password, reCaptchaToken)
            }
          } catch {
            // do nothing
            // console.log(JSON.stringify(err))
          }
          navigate('/auth/pending-workspace-invite')
        }}
      >
        Continue
      </Button>
    </Fragment>
  )
}

export default SuccessStep
