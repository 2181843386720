import React, { FC, useRef, useEffect } from 'react'

import 'twin.macro'
import Player from 'react-player'

export interface VideoWrapperProps {
  url?: string
}

const VideoWrapper: FC<VideoWrapperProps> = ({ url }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const resizeVideo = () => {
    if (!!wrapperRef.current) {
      wrapperRef.current.style.height = `${
        wrapperRef.current.clientWidth * (9 / 16)
      }px`
    }
  }

  useEffect(() => {
    resizeVideo()
    window.addEventListener('resize', resizeVideo)
    return () => {
      window.removeEventListener('resize', resizeVideo)
    }
  }, [])

  return (
    <div ref={wrapperRef} tw="w-full max-w-full max-h-full">
      <Player playing controls width="100%" height="100%" url={url} />
    </div>
  )
}

export default VideoWrapper
