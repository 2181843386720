import React, { FC } from 'react'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Radio from '../../../../ui-blocks/radio'
import Spacer from '../../../../ui-blocks/spacer'
import Checkbox from '../../../../ui-blocks/checkbox'

import useElementStates from '../../../../utils/use-element-states'

const CheckboxRadioSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'checked', 'disabled'])

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <Checkbox
        name="checkbox"
        label="Checkbox"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="0.5rem" />
      <Radio
        name="radio"
        label="Radio"
        error={error ? 'Error message!' : ''}
        {...values}
      />
    </ElementSection>
  )
}

export default CheckboxRadioSection
