import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export interface SearchSettings {
  text?: string
  loading?: boolean
  entries?: string[]
  onSelect?: (v: string | string[]) => void
}

export const useSearchTerms = (
  initialSearchText = '',
  debounceInterval = 300
) => {
  const [searchText, setSearchText] = useState<string>(initialSearchText)

  const isBeingSearched = searchText.replace(/\s/, '').length > 1
  const [searchTextDebounced] = useDebounce(searchText, debounceInterval)

  return {
    isBeingSearched,
    searchText,
    searchTextDebounced,
    setSearchText,
  }
}

export type SearchTerms = ReturnType<typeof useSearchTerms>
