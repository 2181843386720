import React, { FC, Fragment, useMemo, useState } from 'react'

import _ from 'lodash'
import tw from 'twin.macro'

import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import Search from '../ui-blocks/search'
import Spinner from '../ui-blocks/spinner'
import Suspense from '../ui-blocks/suspense'
import Dropdown from '../ui-blocks/dropdown'
import ModalAction from '../ui-blocks/modal-action'
import PaginationNav from '../ui-blocks/pagination-nav'
import Illustration, { IllustrationEnum } from '../ui-blocks/illustration'

import Grid from './grid'
import LoadingPage from './loading-page'
import NetworkSelectCard from './network/select-card'

import {
  EnumBroadcastTargetType,
  useListNetworksQuery,
  useListExternalNetworksQuery,
  useManageBroadcastTargetsByCampaignMutation,
  ViewCampaignQuery,
} from '../graphql/components'

import usePagination from '../utils/use-pagination'
import { useSearchTerms } from '../utils/use-search'

import { alertsManager } from '../stores'
import { AlertVariant } from '../stores/alerts-manager'

const FILTER_OPTIONS = [
  { label: 'All networks', value: 'all' },
  { label: 'Workspace Networks', value: 'internal' },
  { label: 'External Networks', value: 'external' },
]

interface ManageNetworksFormikValues {
  networks_ids: string[]
}

const manageNetworksValidationSchema = object().shape({
  networks_ids: array().ensure().of(string()),
})

export interface ManageNetworksModalProps {
  open?: boolean
  campaign: ViewCampaignQuery['campaign']
  defaultFilter?: string
  onClose?: () => void
  refetchData?: () => Promise<any>
}

const ManageNetworksModal: FC<ManageNetworksModalProps> = ({
  open,
  campaign,
  defaultFilter,
  onClose,
  refetchData,
}) => {
  const [filterBy, setFilterBy] = useState<string>(defaultFilter || 'all')
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const [
    updateCampaign,
    { loading: updatingCampaign },
  ] = useManageBroadcastTargetsByCampaignMutation()

  const {
    data: internalNetworksData,
    loading: loadingInternalNetworks,
  } = useListNetworksQuery({
    skip: defaultFilter === 'external',
    fetchPolicy: 'cache-first',
    variables: { filter: { search: searchTextDebounced } },
  })

  const {
    data: externalNetworksData,
    loading: loadingExternalNetworks,
  } = useListExternalNetworksQuery({
    skip: defaultFilter === 'internal',
    fetchPolicy: 'cache-first',
    variables: { filter: { search: searchTextDebounced } },
  })

  const loadingNetworks = useMemo(
    () => loadingInternalNetworks || loadingExternalNetworks,
    [loadingInternalNetworks, loadingExternalNetworks]
  )
  const internalNetworks = useMemo(() => internalNetworksData?.networks || [], [
    internalNetworksData,
  ])
  const externalNetworks = useMemo(() => externalNetworksData?.networks || [], [
    externalNetworksData,
  ])
  const allNetworks = useMemo(
    () => [...internalNetworks, ...externalNetworks],
    [internalNetworks, externalNetworks]
  )
  const filteredNetworks = useMemo(
    () =>
      allNetworks.filter((network) => {
        if (filterBy === 'internal') {
          return !network.is_external
        } else if (filterBy === 'external') {
          return network.is_external
        }
        return !!network
      }),
    [allNetworks, filterBy]
  )

  const initialNetworksIds = useMemo(
    () =>
      _.uniq(
        (campaign?.broadcast_targets || [])
          .filter(
            (target) =>
              target?.type === EnumBroadcastTargetType.Network ||
              target?.type === EnumBroadcastTargetType.ExternalNetwork
          )
          .map((target) => target?.network?._id || target?.externalNetwork?._id)
          .filter(Boolean)
      ),
    [campaign]
  )

  const selectedFilteredNetworks = useMemo(
    () =>
      filteredNetworks.filter((network) =>
        initialNetworksIds.includes(network._id)
      ),
    [filteredNetworks, initialNetworksIds]
  )

  const pagination = usePagination(
    filteredNetworks.length,
    9,
    searchTextDebounced
  )

  const networkGroups = useMemo(
    () => _.chunk(filteredNetworks, pagination.limit),
    [filteredNetworks, pagination]
  )
  const visibleNetworks = useMemo(() => networkGroups[pagination.page] || [], [
    networkGroups,
    pagination,
  ])

  const formik = useFormik<ManageNetworksFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: manageNetworksValidationSchema,
    initialValues: { networks_ids: initialNetworksIds },
    async onSubmit({ networks_ids }) {
      try {
        const selectedIds = _.xor(initialNetworksIds, networks_ids)
        const targets = allNetworks
          .filter((network) => selectedIds.includes(network._id))
          .map((network) => ({
            ref_id: network._id,
            type: !network.is_external
              ? EnumBroadcastTargetType.Network
              : EnumBroadcastTargetType.ExternalNetwork,
          }))

        const res = await updateCampaign({
          variables: { _id: campaign?._id, targets },
        })
        if (!res.data?.isUpdated) return handleError()

        await refetchData?.()
        onCloseModal(false)
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingCampaign) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-error-alert',
      message: "We couldn't update this campaign. Please try again later.",
    })
  }

  return (
    <ModalAction
      open={open}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      loading={updatingCampaign || formik.isSubmitting}
      confirmButtonText="Accept changes"
      width="68rem"
    >
      <Suspense ready={!!campaign} fallback={<LoadingPage />}>
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-2">
            Manage networks
          </h1>
          <p tw="font-normal text-dark-blue-gray leading-tight mb-10">
            Add or remove networks.
          </p>
          <div tw="flex flex-row items-center justify-between mb-6">
            <span tw="font-normal text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
              {`${filteredNetworks.length} Network(s), ${selectedFilteredNetworks.length} Selected`}
            </span>
            <div tw="flex flex-row flex-grow items-center justify-end">
              <div css={[tw`w-full mr-8`, 'max-width: 12.5rem;']}>
                <Dropdown
                  isClearable={false}
                  options={FILTER_OPTIONS}
                  onChange={(option) => option && setFilterBy(option.value)}
                  value={FILTER_OPTIONS.find(
                    (option) => option.value === filterBy
                  )}
                />
              </div>
              <div tw="w-full max-w-xs">
                <Search
                  value={searchText}
                  loading={loadingNetworks}
                  // entries={filteredNetworks.map((network) => network.name)}
                  onSelect={(value) =>
                    setSearchText(!Array.isArray(value) ? value || '' : '')
                  }
                />
              </div>
            </div>
          </div>
          <div tw="mb-6">
            <Suspense ready={!loadingNetworks} fallback={<Spinner center />}>
              <Suspense
                ready={filteredNetworks.length > 0}
                fallback={
                  <div tw="flex flex-col items-center pb-4">
                    <Illustration
                      name={IllustrationEnum.networks_empty_results}
                    />
                    <p tw="font-medium text-charcoal leading-tight mt-6 mb-2">
                      No data to show
                    </p>
                    <span tw="font-normal text-charcoal leading-tight break-words">
                      {!!searchTextDebounced
                        ? `We can’t find any network for "${searchTextDebounced}". Please check your spelling and try again.`
                        : 'We can’t find any network.'}
                    </span>
                  </div>
                }
              >
                <Fragment>
                  <Grid rows={3} cols={3} gap="1rem">
                    {visibleNetworks.map((network) => (
                      <NetworkSelectCard
                        value={network?._id}
                        network={network}
                        formik={formik}
                        name="networks_ids"
                        key={`network-${network._id}`}
                      />
                    ))}
                  </Grid>
                  <PaginationNav
                    page={pagination.page}
                    count={pagination.count}
                    limit={pagination.limit}
                    setPage={pagination.setPage}
                    tw="mt-4"
                  />
                </Fragment>
              </Suspense>
            </Suspense>
          </div>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default ManageNetworksModal
