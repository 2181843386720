import React, { FC } from 'react'
import 'twin.macro'

export interface FullTableRowProps {
  colSpan: number
}

const FullTableRow: FC<FullTableRowProps> = ({ colSpan, children }) => (
  <tr role="row">
    <td role="cell" tw="p-0" colSpan={colSpan}>
      {children}
    </td>
  </tr>
)

export default FullTableRow
