import React, { FC, Fragment, useEffect } from 'react'
import 'twin.macro'

import { object, string } from 'yup'
import { useFormikContext } from 'formik'

import Text from '../../../../ui-blocks/text'
import CodeInput from '../../../../ui-blocks/code-input'

import { BindPlayerFormikValues } from '..'

export const bindPlayerValidationSchema = object().shape({
  code: string()
    .length(6, 'Value must be exactly 6 characters long')
    .matches(/\d{6}/, 'Must have 6 alpha numeric digits')
    .required('Binding code is required'),
})

export interface BindPlayerStepProps {
  urlCode?: string
}

const BindPlayerStep: FC<BindPlayerStepProps> = ({ urlCode }) => {
  const {
    values,
    errors,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<BindPlayerFormikValues>()

  useEffect(() => {
    if (!!urlCode) setFieldValue('code', urlCode)
  }, [])

  return (
    <Fragment>
      <Text as="h3" preset="h4" tw="mb-2">
        Setting up the Player
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray mb-16">
        In this step we will identify which Player belongs to your workspace.
        Your Player should now be showing a set of 6 characters and changing the
        set from time to time. Please insert those characters on the input below
        and press next.
      </Text>
      <CodeInput
        length={6}
        name="binding-code"
        label="Binding Code"
        error={errors.code}
        value={values.code || urlCode}
        autoFocus={!urlCode}
        disabled={isSubmitting}
        onChange={(code) => setFieldValue('code', code)}
      />
    </Fragment>
  )
}

export default BindPlayerStep
