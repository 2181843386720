import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { useLocation } from 'react-router-dom'

export interface SidebarProps {
  anchors: { id: string; title: string }[]
}

const Sidebar: FC<SidebarProps> = ({ anchors }) => {
  const location = useLocation()
  return (
    <aside
      css={css`
        height: max-content;
        top: calc(49px + 2.5rem);
        ${tw`sticky bg-white rounded border border-platinum shadow-card p-5`}
      `}
    >
      <h2 tw="text-2xl font-medium leading-tight mb-5">Elements</h2>
      <div tw="flex flex-col space-y-4">
        {anchors.map((anchor) => (
          <a
            key={anchor.id}
            href={`#${anchor.id}`}
            css={[
              tw`font-normal leading-tight block`,
              `#${anchor.id}` === location.hash && tw`text-purple`,
            ]}
          >
            {anchor.title}
          </a>
        ))}
      </div>
    </aside>
  )
}

export default Sidebar
