import React, { FC } from 'react'
import { useNavigate } from 'react-router'

import Button, { ButtonProps } from './button'

export interface ButtonLinkProps extends ButtonProps {
  to: string
}

const ButtonLink: FC<ButtonLinkProps> = ({ to, ...props }) => {
  const navigate = useNavigate()
  return <Button onClick={() => navigate(to)} {...props} />
}

export default ButtonLink
