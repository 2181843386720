import React, { FC, useMemo } from 'react'
import 'twin.macro'
import { useFormikContext } from 'formik'

import ItemsCard from '../../../../../../components/items-card'
import { NetworkListFragment } from '../../../../../../graphql/components'
import { RestorePlayerFormikValues } from '..'

export interface ConfirmationStepProps {
  networks: NetworkListFragment[]
}

const ConfirmationStep: FC<ConfirmationStepProps> = ({ networks }) => {
  const { values } = useFormikContext<RestorePlayerFormikValues>()

  const selectedNetworks = useMemo(
    () =>
      networks.filter((network) => values.networks_ids.includes(network._id)),
    [values, networks]
  )

  return (
    <div tw="flex flex-row items-center space-x-4">
      <ItemsCard title="Networks" items={selectedNetworks} />
    </div>
  )
}

export default ConfirmationStep
