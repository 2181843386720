import React, { FC } from 'react'
import tw from 'twin.macro'

import Sidebar from './components/sidebar'
import IconSection from './components/icon-section'
import TextSection from './components/text-section'
import LinkSection from './components/link-section'
import ButtonSection from './components/button-section'
import SwitchSection from './components/switch-section'
import CheckboxRadioSection from './components/checkbox-radio-section'
import InputSection from './components/input-section'
import CodeInputSection from './components/code-input-section'
import SearchInputSection from './components/search-input-section'
import DropdownSection from './components/dropdown-section'
import DatePickerSection from './components/date-picker-section'
import HourPickerSection from './components/hour-picker-section'
import ViewControlsSection from './components/view-controls-section'
import AvatarSection from './components/avatar-section'
import PageHeaderSection from './components/page-header-section'
import AlertSection from './components/alert-section'
import StatusSection from './components/status-section'
import PillSection from './components/pill-section'
import TabSection from './components/tab-section'
import MapSection from './components/map-section'
import IllustrationSection from './components/illustration-section'

import Container from '../../../ui-blocks/container'

const elements = [
  { id: 'icon', title: 'Icon', section: IconSection },
  { id: 'text', title: 'Text', section: TextSection },
  { id: 'link', title: 'Link', section: LinkSection },
  { id: 'button', title: 'Button', section: ButtonSection },
  {
    id: 'checkbox-radio',
    title: 'Checkbox & Radio',
    section: CheckboxRadioSection,
  },
  { id: 'switch', title: 'Switch', section: SwitchSection },
  { id: 'input', title: 'Input', section: InputSection },
  { id: 'code-input', title: 'Code Input', section: CodeInputSection },
  { id: 'search-input', title: 'Search Input', section: SearchInputSection },
  { id: 'dropdown', title: 'Dropdown', section: DropdownSection },
  { id: 'date-picker', title: 'Date Picker', section: DatePickerSection },
  { id: 'hour-picker', title: 'Hour Picker', section: HourPickerSection },
  { id: 'view-controls', title: 'View Controls', section: ViewControlsSection },
  { id: 'avatar', title: 'Avatar', section: AvatarSection },
  { id: 'alert', title: 'Alert', section: AlertSection },
  { id: 'status', title: 'Status', section: StatusSection },
  { id: 'pill', title: 'Pill', section: PillSection },
  { id: 'tabs', title: 'Tabs', section: TabSection },
  { id: 'page-header', title: 'Page Header', section: PageHeaderSection },
  { id: 'map', title: 'Map', section: MapSection },
  { id: 'illustration', title: 'Illustration', section: IllustrationSection },
]

const Elements: FC = () => (
  <Container hasBreakpoints={false}>
    <div css={[tw`grid gap-6`, 'grid-template-columns: 20rem auto;']}>
      <Sidebar anchors={elements.map(({ id, title }) => ({ id, title }))} />
      <section>
        <h1 tw="font-light leading-tight text-4xl mb-4">Elements</h1>
        <p tw="font-normal mb-10">
          Elements are components we can reutilize within the entire web-app.
          This page lists all the components, one by one, along with all the
          possible states.
        </p>
        <div tw="w-full space-y-10">
          {elements.map(({ id, title, section: Section }) => (
            <Section id={id} title={title} key={`component-${id}`} />
          ))}
        </div>
      </section>
    </div>
  </Container>
)

export default Elements
