import React, { FC, Fragment, useMemo, useState } from 'react'
import _ from 'lodash'

import Illustration, {
  IllustrationEnum,
} from '../../../../ui-blocks/illustration'

import RestoreModal from '../restore-modal'
import RestoreAction from '../restore-action'

import InlineDate from '../../../../components/inline-date'
import UploadDuration from '../../../../components/upload/duration'
import UploadThumbnail from '../../../../components/upload/thumbnail'
import Table, { TableDataProps } from '../../../../components/table'

import {
  CreativeListFragment,
  useRestoreCreativesMutation,
} from '../../../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
  getDurationFromUpload,
} from '../../../../utils/data-manipulation'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

export interface ArchivedCreativesListTableProps
  extends TableDataProps<CreativeListFragment> {
  onClickThumbnail?: (index: number) => void
}

const ArchivedCreativesListTable: FC<ArchivedCreativesListTableProps> = ({
  data,
  onClickThumbnail,
  ...props
}) => {
  const [
    restoreCreative,
    { loading: restoringCreative },
  ] = useRestoreCreativesMutation()

  const [isRestoreModalOpen, openRestoreModal] = useState<boolean>(false)
  const [
    selectedCreative,
    setSelectedCreative,
  ] = useState<CreativeListFragment | null>(null)

  const onCloseModal = () => {
    openRestoreModal(false)
    setSelectedCreative(null)
  }

  const onSelectCreative = (creative: CreativeListFragment) => {
    setSelectedCreative(creative)
    openRestoreModal(true)
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'restore-creative-error-alert',
      message: "We couldn't restore this creative. Please try again later.",
    })
  }

  const onRestoreCreative = async () => {
    try {
      const res = await restoreCreative({
        variables: { ids: [selectedCreative?._id] },
      })
      if (!res.data?.areRestored) return handleError()

      onCloseModal()
      document.dispatchEvent(new CustomEvent('refetch-creatives'))
      document.dispatchEvent(new CustomEvent('refetch-archived-creatives'))
    } catch {
      handleError()
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((creative, index) => ({
        preview: {
          content: (
            <div css={['width: 7.5rem;']}>
              <UploadThumbnail
                upload={creative.upload as any}
                onClick={() => onClickThumbnail?.(index)}
              />
            </div>
          ),
        },
        name: {
          value: creative.name,
          content: creative.name,
        },
        uploadType: {
          value: creative.upload?.type,
          content: creative.upload?.type.toUpperCase(),
        },
        uploadTime: {
          value: getDurationFromUpload(creative.upload as any),
          content: <UploadDuration upload={creative.upload as any} />,
        },
        createdAt: {
          value: creative.created?.at,
          content: <InlineDate date={creative.created?.at} />,
        },
        archivedAt: {
          value: creative.archived?.at,
          content: <InlineDate date={creative.archived?.at} />,
        },
        restore: {
          content: <RestoreAction onClick={() => onSelectCreative(creative)} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Fragment>
      <Table
        itemName="Creative"
        data={chronologicalData}
        emptyStateIllustration={
          <Illustration name={IllustrationEnum.creatives_empty_results} />
        }
        layout="fluid"
        cols={[
          { key: 'preview', label: 'Preview', width: 142 },
          { key: 'name', label: 'Name', isSortable: true },
          {
            key: 'uploadType',
            label: 'Type',
            isSortable: true,
            alignment: 'center',
            width: 100,
          },
          {
            key: 'uploadTime',
            label: 'Time',
            isSortable: true,
            alignment: 'right',
            width: 100,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          {
            key: 'archivedAt',
            label: 'Archived At',
            isSortable: true,
            alignment: 'right',
            width: 150,
          },
          { key: 'restore', alignment: 'right', width: 100 },
        ]}
        {...props}
      />
      <RestoreModal
        target="creative"
        open={isRestoreModalOpen}
        loading={restoringCreative}
        onConfirm={onRestoreCreative}
        itemName={selectedCreative?.name || ''}
        onCancel={onCloseModal}
      />
    </Fragment>
  )
}

export default ArchivedCreativesListTable
