import React, { FC, useEffect, useMemo } from 'react'

import 'twin.macro'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import Text from '../../../ui-blocks/text'
import Switch from '../../../ui-blocks/switch'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { TabPane, Tabs } from '../../../ui-blocks/tabs'

import OverviewSection from './components/overview-section'
import AdGroupsSection from './components/ad-groups-section'
import BroadcastTargetsSection from './components/broadcast-targets/section'
import AnalyticsSection from './components/analytics-section'
import SettingsSection from './components/settings/section'

import NotFound from '../../../components/not-found'
import LoadingPage from '../../../components/loading-page'

import {
  EnumCampaignStatus,
  useViewCampaignQuery,
  useUpdateCampaignActivationMutation,
} from '../../../graphql/components'

import { getKPIIntervals } from '../../../utils/stats-intervals'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export enum CampaignPageTabKeys {
  OVERVIEW = 'overview',
  AD_GROUPS = 'ad-groups',
  BROADCAST_TARGETS = 'broadcast-targets',
  ANALYTICS = 'analytics',
  SETTINGS = 'settings',
}

export const campaignPageRoutes = {
  [CampaignPageTabKeys.OVERVIEW]: '',
  [CampaignPageTabKeys.AD_GROUPS]: 'ad-groups',
  [CampaignPageTabKeys.BROADCAST_TARGETS]: 'broadcast-targets',
  [CampaignPageTabKeys.ANALYTICS]: 'analytics',
  [CampaignPageTabKeys.SETTINGS]: 'settings',
}

const ViewCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_id } = useParams()

  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean
  const [updateCampaignActivation] = useUpdateCampaignActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: campaignData,
    loading: loadingCampaign,
    refetch: refetchCampaign,
  } = useViewCampaignQuery({
    skip: !campaign_id,
    fetchPolicy: 'cache-first', // @TODO: check why this is necessary
    variables: {
      _id: campaign_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a campaign's ad group page if it is archived
  const { campaign } = campaignData || {}

  useEffect(() => {
    shouldRefetch && refetchCampaign()
  }, [shouldRefetch])

  const refetchCampaignListener = async () => await refetchCampaign()
  useEffect(() => {
    document.addEventListener('refetch-networks', refetchCampaignListener)
    document.addEventListener(
      'refetch-internal-campaign',
      refetchCampaignListener
    )

    document.addEventListener(
      'refetch-campaign-ad-groups',
      refetchCampaignListener
    )

    return () => {
      document.removeEventListener('refetch-networks', refetchCampaignListener)
      document.removeEventListener(
        'refetch-internal-campaign',
        refetchCampaignListener
      )
      document.removeEventListener(
        'refetch-campaign-ad-groups',
        refetchCampaignListener
      )
    }
  }, [])

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-status-error-alert',
      message: `We couldn't ${action} this campaign. Please try again later.`,
    })
  }

  const handleActivateCampaign = async (activate: boolean) => {
    try {
      const res = await updateCampaignActivation({
        variables: { ids: [campaign_id], is_active: activate },
      })
      if (!res.data?.areUpdated) {
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      }

      document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      await refetchCampaign()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const handleTabClick = (tabKey: string) => {
    navigate(
      `/campaigns/${campaign?._id}/${
        campaignPageRoutes[tabKey as CampaignPageTabKeys]
      }`
    )
  }

  const isSwitchDisabled = useMemo(
    () =>
      campaign?.status === EnumCampaignStatus.Waiting ||
      campaign?.status === EnumCampaignStatus.Pending ||
      campaign?.status === EnumCampaignStatus.Finished ||
      campaign?.status === EnumCampaignStatus.Archived,
    [campaign]
  )

  const activeTabKey = useMemo(
    () =>
      (location.pathname.endsWith(`/${CampaignPageTabKeys.AD_GROUPS}`) &&
        CampaignPageTabKeys.AD_GROUPS) ||
      (location.pathname.endsWith(
        `/${CampaignPageTabKeys.BROADCAST_TARGETS}`
      ) &&
        CampaignPageTabKeys.BROADCAST_TARGETS) ||
      (location.pathname.endsWith(`/${CampaignPageTabKeys.ANALYTICS}`) &&
        CampaignPageTabKeys.ANALYTICS) ||
      (location.pathname.endsWith(`/${CampaignPageTabKeys.SETTINGS}`) &&
        CampaignPageTabKeys.SETTINGS) ||
      CampaignPageTabKeys.OVERVIEW,
    [location]
  )

  if (campaign?.status === EnumCampaignStatus.Archived) {
    return <Navigate to="/campaigns" state={{ replace: true }} />
  }

  if (campaign?.is_external) {
    return <Navigate to="/campaigns" state={{ replace: true }} />
  }

  return (
    <Suspense ready={!loadingCampaign} fallback={<LoadingPage />}>
      <Suspense ready={!!campaign} fallback={<NotFound />}>
        <Container>
          <BreadcrumbNav
            tw="mb-1"
            crumbs={[
              {
                label: 'Campaigns',
                linkTo: '/campaigns',
              },
              { label: campaign?.name || '' },
            ]}
          />
          <div tw="w-full flex flex-row items-center justify-between">
            <div tw="flex flex-row items-center mb-6">
              <Switch
                disabled={isSwitchDisabled}
                checked={campaign?.status === EnumCampaignStatus.Activated}
                onSelect={handleActivateCampaign}
                tw="-ml-2 mr-2"
              />
              <Text as="h1" preset="h3">
                {campaign?.name}
              </Text>
            </div>
          </div>
          <Tabs
            withTransition={false}
            activeKey={activeTabKey}
            onTabClick={handleTabClick}
          >
            <TabPane
              key={CampaignPageTabKeys.OVERVIEW}
              tab="Overview"
              tw="mt-10"
            >
              <OverviewSection campaign={campaign || undefined} />
            </TabPane>
            <TabPane
              key={CampaignPageTabKeys.AD_GROUPS}
              tab="Ad Groups"
              tw="mt-10"
            >
              <AdGroupsSection
                loading={loadingCampaign}
                campaign={campaign || undefined}
              />
            </TabPane>
            <TabPane
              key={CampaignPageTabKeys.BROADCAST_TARGETS}
              tab="Broadcast Targets"
              tw="mt-10"
            >
              <BroadcastTargetsSection
                loading={loadingCampaign}
                campaign={campaign || undefined}
                refetchCampaign={refetchCampaign}
              />
            </TabPane>
            <TabPane
              key={CampaignPageTabKeys.ANALYTICS}
              tab="Analytics"
              tw="mt-10"
            >
              <AnalyticsSection campaign={campaign || undefined} />
            </TabPane>
            <TabPane
              key={CampaignPageTabKeys.SETTINGS}
              tab="Settings"
              tw="mt-10"
            >
              <SettingsSection
                campaign={campaign || undefined}
                refetchCampaign={refetchCampaign}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Suspense>
    </Suspense>
  )
}

export default ViewCampaignPage
