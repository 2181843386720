import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from './icon'

export interface AvatarProps {
  variant?: 'workspace' | 'profile'
  size?: string
  initials?: string
  add?: boolean
  src?: string
  largeFont?: boolean
}

const Avatar: FC<AvatarProps> = ({
  variant = 'workspace',
  size,
  initials,
  add,
  src,
  largeFont,
  ...props
}) => (
  <div
    css={[
      tw`flex items-center justify-center select-none`,
      !!largeFont && tw`font-light text-3xl leading-tight`,
      !!size ? `width: ${size}; height: ${size};` : tw`w-12 h-12`,
      variant === 'workspace'
        ? tw`border-0 rounded bg-dark-blue-gray text-white`
        : tw`border-0 rounded-full bg-cultured text-purple`,
    ]}
    {...props}
  >
    {(!!src && (
      <img
        src={src}
        alt={initials}
        css={[
          tw`flex items-center justify-center w-full h-full object-cover border border-platinum bg-white`,
          variant === 'workspace' ? tw`rounded` : tw`rounded-full`,
        ]}
      />
    )) ||
      (add ? (
        <Icon
          icon="add"
          css={largeFont ? ['font-size: 2rem;'] : ['font-size: 1.25rem;']}
        />
      ) : (
        initials
      ))}
  </div>
)

export default Avatar
