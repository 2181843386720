import React, { FC } from 'react'

import tw from 'twin.macro'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import ElementSection, { ElementSectionProps } from './element-section'

import Icon, { ICON_NAMES } from '../../../../ui-blocks/icon'

const IconSection: FC<ElementSectionProps> = ({ id, title, description }) => (
  <ElementSection id={id} title={title} description={description}>
    <div tw="flex flex-row flex-wrap items-center gap-2">
      {ICON_NAMES.map((icon, index) => (
        <CopyToClipboard key={`icon#${index}`} text={`<Icon icon="${icon}" />`}>
          <div
            title={icon}
            css={[
              tw`flex items-center justify-center bg-white border rounded border-platinum p-4 transition duration-200 hover:cursor-pointer`,
              '&:hover { box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.101025); }',
            ]}
          >
            <Icon icon={icon} tw="text-2xl" />
          </div>
        </CopyToClipboard>
      ))}
    </div>
  </ElementSection>
)

export default IconSection
