import React, { FC, useMemo } from 'react'

import tw from 'twin.macro'
import { useNavigate } from 'react-router'

import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'

import SetupForm, { Step } from '../../../components/setup-form'

import CampaignDetailsStep, {
  campaignDetailsValidationSchema,
} from './steps/details'
import TargetNetworksStep, {
  targetNetworksValidationSchema,
} from './steps/target-networks'
// import TargetPlayersStep, {
//   targetPlayersValidationSchema,
// } from './steps/target-players'

import {
  useCountItemsQuery,
  EnumBroadcastTargetType,
  useCreateCampaignMutation,
  useListExternalNetworksQuery,
  EnumWorkspacePlatform_Type,
} from '../../../graphql/components'

import { alertsManager, authentication } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export interface CreateCampaignFormikValues {
  name: string
  networks_ids: string[]
  external_networks_ids: string[]
  players_ids: string[]
}

const CreateCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { data: countData, loading: loadingCount } = useCountItemsQuery({
    fetchPolicy: 'cache-first',
  })
  const {
    data: externalNetworkData,
    loading: loadingExternalNetwork,
  } = useListExternalNetworksQuery({
    fetchPolicy: 'cache-first',
  })

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-campaign-error-alert',
      message: "We couldn't create the campaign. Please try again later.",
    })
  }

  const [createCampaign] = useCreateCampaignMutation()
  const handleSubmit = async (values: CreateCampaignFormikValues) => {
    try {
      const res = await createCampaign({
        variables: {
          input: {
            name: values.name,
            broadcast_targets: [
              // ...values.players_ids.map((id) => ({
              //   ref_id: id,
              //   type: EnumBroadcastTargetType.Player,
              // })),
              ...values.networks_ids.map((id) => ({
                ref_id: id,
                type: EnumBroadcastTargetType.Network,
              })),
              ...values.external_networks_ids.map((id) => ({
                ref_id: id,
                type: EnumBroadcastTargetType.ExternalNetwork,
              })),
            ],
          },
        },
      })

      if (!res.data?.campaign?._id) {
        handleError()
        return false
      }

      document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      navigate(`/campaigns/${res.data?.campaign?._id}`)
      return true
    } catch {
      handleError()
      return false
    }
  }

  const hasNetworks = useMemo(() => !!countData?.networks, [countData])
  const hasExternalNetworks = useMemo(
    () => (externalNetworkData?.networks || []).length > 0,
    [externalNetworkData]
  )
  const isAdvertiser =
    authentication.selected_workspace.platform_type ===
    EnumWorkspacePlatform_Type.Advertiser
  // const hasPlayers = useMemo(() => !!countData?.players, [countData])

  const steps: Step[] = [
    {
      canGoBack: true,
      title: 'Details',
      description: 'Enter the Campaign details.',
      content: <CampaignDetailsStep />,
      validationSchema: campaignDetailsValidationSchema,
    },
    ...(hasNetworks && !isAdvertiser
      ? [
          {
            canGoBack: true,
            title: 'Target networks',
            description: 'Select one or more networks for your Campaign.',
            content: <TargetNetworksStep external={false} />,
            // disabledTriggers: ['networks_ids'],
            validationSchema: targetNetworksValidationSchema,
          },
        ]
      : []),
    ...(hasExternalNetworks
      ? [
          {
            canGoBack: true,
            title: 'Target external networks',
            description:
              'Select one or more external networks for your Campaign.',
            content: <TargetNetworksStep external={true} />,
            // disabledTriggers: ['networks_ids'],
            validationSchema: targetNetworksValidationSchema,
          },
        ]
      : []),
    // ...(hasPlayers
    //   ? [
    //       {
    //         canGoBack: true,
    //         title: 'Target players',
    //         description: 'Select one or more players for your Campaign.',
    //         content: <TargetPlayersStep />,
    //         disabledTriggers: ['players_ids'],
    //         validationSchema: targetPlayersValidationSchema,
    //       },
    //     ]
    //   : []),
  ]

  return (
    <Container>
      <div css={[tw`w-full self-center`, 'max-width: 68rem;']}>
        <Suspense
          ready={!loadingCount && !loadingExternalNetwork}
          fallback={<Spinner center />}
        >
          <SetupForm
            title="New Campaign"
            description="Create a new campaign and chose your target audience."
            initialValues={{
              name: '',
              networks_ids: [],
              external_networks_ids: [],
            }} //, players_ids: [] }}
            onSubmit={{ [steps.length - 1]: handleSubmit }}
            steps={steps}
          />
        </Suspense>
      </div>
    </Container>
  )
}

export default CreateCampaignPage
