import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../../../components/alert-component'

import {
  CreativeFullFragment,
  useUpdateCreativeByIdMutation,
} from '../../../../../../graphql/components'

import { trimValues } from '../../../../../../utils/data-manipulation'

interface EditCreativeFormikValues {
  name: string
}

const editCreativeValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

export interface EditCreativeFormProps {
  creative?: CreativeFullFragment
  refetchCreative?: () => Promise<any>
}

const EditCreativeForm: FC<EditCreativeFormProps> = ({
  creative,
  refetchCreative,
}) => {
  const [updateCreativeError, setUpdateCreativeError] = useState<string>('')

  const [updateCreative] = useUpdateCreativeByIdMutation()
  const formik = useFormik<EditCreativeFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editCreativeValidationSchema,
    initialValues: { name: creative?.name || '' },
    async onSubmit(values) {
      setUpdateCreativeError('')
      try {
        const newValues = trimValues(values, ['name'])
        const res = await updateCreative({
          variables: { _id: creative?._id, input: newValues },
        })
        if (!!(res.errors || []).length) {
          return setUpdateCreativeError(
            "We couldn't update this creative. Please try again later."
          )
        }
        await refetchCreative?.()
      } catch {
        setUpdateCreativeError(
          'Something went wrong while trying to update this creative. Please try again later.'
        )
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {!!updateCreativeError && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              message: updateCreativeError,
              id: 'update-creative-error-alert',
            }}
          />
        </div>
      )}
      <div tw="max-w-xs mb-10">
        <InputFormik type="text" name="name" label="Name" formik={formik} />
      </div>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update creative details
      </Button>
    </form>
  )
}

export default EditCreativeForm
