import React, { FC, useState } from 'react'
import 'twin.macro'

import moment from 'moment'
import { useParams } from 'react-router-dom'

import { Tabs, TabPane } from '../../../../ui-blocks/tabs'

import UpcomingBill from './components/upcoming-bill'

import Invoices from './sections/invoices'
import PaymentMethods from './sections/payment-methods'

import {
  useCountItemsQuery,
  useViewWorkspaceQuery,
} from '../../../../graphql/components'

import { authentication } from '../../../../stores'
import { PlanFeatures } from './components/plan-features'
import { RemainingTrialTime } from './components/remaining-trial-time'

const WorkspaceSettingsBillingMainPage: FC = () => {
  const { workspace_id } = useParams()
  const [selectedTab, setSelectedTab] = useState<string>('payment-information')

  const count = useCountItemsQuery({ fetchPolicy: 'cache-first' })
  const { data } = useViewWorkspaceQuery({
    skip: !workspace_id,
    fetchPolicy: 'cache-first',
    variables: { _id: workspace_id },
  })

  const upcomingInvoice = data?.workspace?.upcoming_invoice
  const lastChargedInvoice = data?.workspace?.invoices?.data?.find(
    (invoice) => invoice?.status === 'paid'
  )
  const lastFailedPayment = data?.workspace?.invoices?.data?.find(
    (invoice) => invoice?.status === 'open'
  )

  const { is_trial_active, trial_remaining_days } = authentication

  return (
    <div>
      <div tw="flex flex-row mb-14">
        <div tw="w-2/4">
          <h1 tw="font-medium text-xl text-charcoal leading-tight mb-2">
            {is_trial_active ? 'Free Trial' : 'Enterprise'}
          </h1>
          <p tw="font-normal text-charcoal leading-tight mb-2">
            Workspace created at{' '}
            {moment(authentication.selected_workspace.added_at).format('LL')}
          </p>
          {is_trial_active ? (
            <RemainingTrialTime remainingDays={trial_remaining_days} />
          ) : (
            <PlanFeatures activePlayers={count.data?.players || 0} />
          )}
        </div>
        <div tw="w-2/4">
          <UpcomingBill
            hasFailedPayments={!!lastFailedPayment}
            freePlan={is_trial_active || !upcomingInvoice}
            nextChargeAmount={(upcomingInvoice?.amount_due as number) / 100}
            nextChargeDate={
              new Date((upcomingInvoice?.next_payment_attempt as number) * 1000)
            }
            lastChargeAmount={
              !!lastFailedPayment
                ? lastFailedPayment.total
                : lastChargedInvoice?.total
            }
            onDetailsClick={() => setSelectedTab('payment-history')}
          />
        </div>
      </div>
      <Tabs activeKey={selectedTab} onTabClick={setSelectedTab}>
        <TabPane key="payment-information" tab="Payment Information">
          <PaymentMethods />
        </TabPane>
        <TabPane key="payment-history" tab="Payment History">
          <Invoices />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default WorkspaceSettingsBillingMainPage
