import React, { FC, useEffect, useMemo } from 'react'
import 'twin.macro'

import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ArchivedNetworksListTable from './table'

import {
  useCountArchivedNetworksQuery,
  useListArchivedNetworksQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { SearchSettings } from '../../../../utils/use-search'

export interface NetworksSectionProps {
  onSearch?: (settings: SearchSettings) => void
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  searchTextDebounced: string
}

const NetworksSection: FC<NetworksSectionProps> = ({
  onSearch,
  searchText,
  searchTextDebounced,
  setSearchText,
}) => {
  const {
    data: archivedNetworksCountData,
    loading: loadingArchivedNetworksCount,
  } = useCountArchivedNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })
  const count = useMemo(() => archivedNetworksCountData?.networks || 0, [
    archivedNetworksCountData,
  ])
  const pagination = usePagination(count, 15, searchTextDebounced)
  const {
    data: archivedNetworksData,
    loading: loadingArchivedNetworks,
    refetch: refetchArchivedNetworks,
  } = useListArchivedNetworksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const archivedNetworks = useMemo(() => archivedNetworksData?.networks || [], [
    archivedNetworksData,
  ])

  const refetchData = async () => await refetchArchivedNetworks()
  useEffect(() => {
    document.addEventListener('refetch-archived-networks', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-networks', refetchData)
    }
  }, [])

  useEffect(() => {
    onSearch?.({
      text: searchText,
      loading: loadingArchivedNetworks && loadingArchivedNetworksCount,
      entries: archivedNetworks.map((network) => network.name),
      onSelect: (value) =>
        setSearchText(!Array.isArray(value) ? value || '' : ''),
    })
  }, [
    searchText,
    archivedNetworks,
    loadingArchivedNetworks,
    loadingArchivedNetworksCount,
  ])

  return (
    <section>
      <ArchivedNetworksListTable
        data={archivedNetworks}
        loading={loadingArchivedNetworks && loadingArchivedNetworksCount}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default NetworksSection
