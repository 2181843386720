import React, { FC } from 'react'

import CampaignAnalytics from '../../../../components/campaign/analytics'

import { CampaignFullFragment } from '../../../../graphql/components'

export interface AnalyticsSectionProps {
  campaign?: CampaignFullFragment
}

const AnalyticsSection: FC<AnalyticsSectionProps> = ({ campaign }) => (
  <section>{!!campaign && <CampaignAnalytics campaign={campaign} />}</section>
)

export default AnalyticsSection
