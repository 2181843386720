import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../ui-blocks/icon'

export interface ImageControlsProps {
  scale: number
  onReset?: () => void
  onDecrease?: () => void
  onIncrease?: () => void
}

const ImageControls: FC<ImageControlsProps> = ({
  scale,
  onReset,
  onDecrease,
  onIncrease,
}) => (
  <div tw="flex flex-row items-center bg-black-60 font-medium text-white transform -translate-x-2/4 absolute bottom-0 left-2/4 px-3 py-2 mb-6 space-x-5 z-50">
    <Icon
      icon="remove"
      tw="text-xl hover:cursor-pointer"
      onClick={onDecrease}
    />
    <p tw="select-none whitespace-nowrap" onClick={onReset}>
      {`${(scale * 100).toFixed(0)}%`}
    </p>
    <Icon icon="add" tw="text-xl hover:cursor-pointer" onClick={onIncrease} />
  </div>
)

export default ImageControls
