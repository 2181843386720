import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { styled } from 'twin.macro'

export interface GridProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  rows?: number
  cols?: number
  gap?: string | { row?: string; col?: string }
}

const Grid = styled.div<GridProps>`
  display: grid;
  ${({ rows }) =>
    !!rows && `grid-template-rows: repeat(${rows}, minmax(0, 1fr));`}
  ${({ cols }) =>
    !!cols && `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`}
  ${({ gap }) =>
    !!gap &&
    (typeof gap === 'string'
      ? `gap: ${gap};`
      : `
          ${!!gap.row && `row-gap: ${gap.row};`}
          ${!!gap.col && `column-gap: ${gap.col};`}
        `)}
`

export default Grid
