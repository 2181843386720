import React, { FC, useEffect, useMemo } from 'react'
import 'twin.macro'

import PaginationNav from '../../../../ui-blocks/pagination-nav'

import ArchivedPlayersListTable from './table'

import {
  useCountArchivedPlayersQuery,
  useListArchivedPlayersQuery,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { SearchSettings } from '../../../../utils/use-search'

export interface PlayersSectionProps {
  onSearch?: (settings: SearchSettings) => void
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  searchTextDebounced: string
}

const PlayersSection: FC<PlayersSectionProps> = ({
  onSearch,
  searchText,
  searchTextDebounced,
  setSearchText,
}) => {
  const {
    data: archivedPlayersCountData,
    loading: loadingArchivedPlayersCount,
  } = useCountArchivedPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const count = useMemo(() => archivedPlayersCountData?.players || 0, [
    archivedPlayersCountData,
  ])
  const pagination = usePagination(count, 15, searchTextDebounced)

  const {
    data: archivedPlayersData,
    loading: loadingArchivedPlayers,
    refetch: refetchArchivedPlayers,
  } = useListArchivedPlayersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const archivedPlayers = useMemo(() => archivedPlayersData?.players || [], [
    archivedPlayersData,
  ])

  const refetchData = async () => await refetchArchivedPlayers()
  useEffect(() => {
    document.addEventListener('refetch-archived-players', refetchData)
    return () => {
      document.removeEventListener('refetch-archived-players', refetchData)
    }
  }, [])

  useEffect(() => {
    onSearch?.({
      text: searchText,
      loading: loadingArchivedPlayers && loadingArchivedPlayersCount,
      entries: archivedPlayers.map((player) => player?.name || ''),
      onSelect: (value) =>
        setSearchText(!Array.isArray(value) ? value || '' : ''),
    })
  }, [
    searchText,
    archivedPlayers,
    loadingArchivedPlayers,
    loadingArchivedPlayersCount,
  ])

  return (
    <section>
      <ArchivedPlayersListTable
        data={archivedPlayers}
        loading={loadingArchivedPlayers && loadingArchivedPlayersCount}
        searchQuery={searchTextDebounced}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
    </section>
  )
}

export default PlayersSection
