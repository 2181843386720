import React, { useState, FC } from 'react'
import 'twin.macro'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useLocation } from 'react-router-dom'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Link from '../../ui-blocks/link'
import Button from '../../ui-blocks/button'
import { InputFormik } from '../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../components/alert-component'

import { authentication } from '../../stores'

interface SignInFormikValues {
  email: string
  password: string
}

const signInValidationSchema = object().shape({
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
  password: string().required('Password is required'),
})

const AuthSignInPage: FC = () => {
  const navigate = useNavigate()
  const [signInError, setSignInError] = useState('')

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const formik = useFormik<SignInFormikValues>({
    validateOnChange: false,
    validationSchema: signInValidationSchema,
    initialValues: { email: queryParams.get('email') || '', password: '' },
    async onSubmit({ email, password }) {
      try {
        const token = await executeRecaptcha?.('auth/sign_in')
        await authentication.login(email, password, token)
        if (!authentication.has_a_verified_email) {
          navigate('/auth/sign-up/verify-email/0')
        } else {
          navigate('/auth/pending-workspace-invites')
        }
      } catch (err) {
        setSignInError(`${err}`)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <h1 tw="text-charcoal m-0 mb-10 font-light leading-tight text-4xl self-start">
        Sign in
      </h1>
      {signInError && (
        <div tw="mb-8">
          {(signInError.match(`This account is not active`) && (
            <AlertMessage
              width="100%"
              alert={{
                id: 'sign-in-error',
                variant: AlertVariant.ERROR,
                message:
                  'It seems like this account is deactivated. Please contact our support!',
              }}
            />
          )) || (
            <AlertMessage
              width="100%"
              alert={{
                id: 'sign-in-error',
                variant: AlertVariant.ERROR,
                message:
                  'You entered an incorrect email or password. Need an account?',
                actionText: 'Sign up',
                onAction: () => navigate(`/auth/sign-up`),
              }}
            />
          )}
        </div>
      )}
      <div tw="space-y-8 mb-12">
        <InputFormik
          type="email"
          name="email"
          label="Email Address"
          placeholder="your-email@domain.eu"
          formik={formik}
        />
        <InputFormik
          type="password"
          name="password"
          label="Password"
          placeholder="*************"
          labelExtra={
            <Link
              router={{ to: '/auth/forgot-password' }}
              tw="p-0 min-h-auto inline-flex text-xs"
            >
              Forgot Password?
            </Link>
          }
          formik={formik}
        />
      </div>

      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
        tw="block"
      >
        Sign in
      </Button>

      <p tw="inline-block align-baseline text-sm mt-8 text-charcoal">
        Don't have an account yet?{' '}
        <Link
          router={{ to: '/auth/sign-up' }}
          tw="p-0 min-w-auto min-h-auto inline-flex"
        >
          Sign up
        </Link>
      </p>
    </form>
  )
}

export default AuthSignInPage
