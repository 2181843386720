import React, { FC } from 'react'
import tw from 'twin.macro'

import Icon from '../../../../ui-blocks/icon'
import Card from '../../../../ui-blocks/card'
import Text from '../../../../ui-blocks/text'

import {
  useViewWorkspaceQuery,
  WhoAmIUserWorkspaceResult,
} from '../../../../graphql/components'

import getInitials from '../../../../utils/get-initials'
import Gravatar from '../../../../ui-blocks/gravatar'

export interface WorkspaceCardProps {
  isAdded?: boolean
  isRevoked?: boolean
  isSelected?: boolean
  workspace: WhoAmIUserWorkspaceResult
  onClick?: () => void
}

const WorkspaceCard: FC<WorkspaceCardProps> = ({
  isAdded,
  isRevoked,
  isSelected,
  workspace,
  onClick,
}) => {
  const {
    data: workspaceData,
    loading: loadingWorkspace,
  } = useViewWorkspaceQuery({
    skip: !workspace._id,
    variables: { _id: workspace._id },
  })

  return (
    <Card
      onClick={!isAdded && !isRevoked ? onClick : undefined}
      css={[
        tw`flex flex-row items-center relative hover:cursor-pointer p-4`,
        (isAdded || isRevoked) && tw`bg-light-gray hover:cursor-not-allowed`,
        !isAdded && isSelected && tw`border-purple`,
      ]}
    >
      <Gravatar
        size="2.5rem"
        email={workspace.billing_email}
        initials={getInitials(workspace.name)}
        tw="mr-4"
      />
      <div tw="flex flex-col flex-grow">
        <Text as="p" preset="h4" tw="truncate">
          {workspace.name}
        </Text>
        <div tw="flex flex-row items-center justify-between">
          <Text as="span" preset="p2">
            {loadingWorkspace
              ? 'Loading...'
              : `${
                  workspaceData?.workspace?.members?.filter(
                    (member) => member?.removed?.at === null
                  ).length ?? 0
                } Member(s)`}
          </Text>
          {isAdded && (
            <Text
              as="span"
              preset="p2"
              weight="medium"
              transform="uppercase"
              tw="tracking-wider"
            >
              Added
            </Text>
          )}
          {isRevoked && (
            <Text
              as="span"
              preset="p2"
              weight="medium"
              transform="uppercase"
              tw="tracking-wider"
            >
              Revoked
            </Text>
          )}
        </div>
      </div>
      {isSelected && (
        <div tw="flex items-center justify-center absolute bg-purple rounded-full w-4 h-4 top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
          <Icon icon="check" css={[tw`text-white`, 'font-size: 0.625rem;']} />
        </div>
      )}
    </Card>
  )
}

export default WorkspaceCard
