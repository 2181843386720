import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Switch from '../../ui-blocks/switch'
import Status from '../../ui-blocks/status'
import Illustration, { IllustrationEnum } from '../../ui-blocks/illustration'

import AreaType from '../area-type'
import InlineDate from '../inline-date'
import Table, { TableDataProps } from '../table'

import {
  EnumPlayerStatus,
  PlayerListFragment,
  useUpdatePlayerActivationMutation,
} from '../../graphql/components'

import { sortObjectByKey, groupDataByYear } from '../../utils/data-manipulation'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

const PlayersListTable: FC<TableDataProps<PlayerListFragment>> = ({
  data,
  ...props
}) => {
  const [activatePlayer] = useUpdatePlayerActivationMutation()

  const handleError = (activate: boolean) => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'activate-player-error-alert',
      message: `We couldn't ${
        activate ? 'activate' : 'deactivate'
      } this player. Please try again later.`,
    })
  }

  const onActivatePlayer = async (playerId: string, activate: boolean) => {
    try {
      const res = await activatePlayer({
        variables: { ids: [playerId], is_active: activate },
      })
      if (!res.data?.areUpdated) return handleError(activate)
      document.dispatchEvent(new CustomEvent('refetch-players'))
    } catch {
      handleError(activate)
    }
  }

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((player) => ({
        switch: {
          content: (
            <Switch
              checked={player?.status === EnumPlayerStatus.Activated}
              onSelect={(checked) => onActivatePlayer(player._id, checked)}
              disabled={
                player?.status === EnumPlayerStatus.Binded ||
                player?.status === EnumPlayerStatus.Registered
              }
            />
          ),
        },
        name: {
          value: player?.name || '',
          content: (
            <Link to={`/players/${player._id}`} tw="text-purple ">
              {player?.name || ''}
            </Link>
          ),
        },
        status: {
          value: player?.status,
          content: <Status value={player?.status as EnumPlayerStatus} />,
        },
        orientation: {
          value: player.settings?.orientation,
          content: player.settings?.orientation,
        },
        areaType: {
          content: <AreaType areaTypeId={player.demographics?.area_type_id} />,
        },
        createdAt: {
          value: player.created?.at,
          content: <InlineDate date={player.created?.at} />,
        },
      })),
    [sortedData]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Table
      itemName="Player"
      data={chronologicalData}
      emptyStateIllustration={
        <Illustration name={IllustrationEnum.players_empty_results} />
      }
      layout="fluid"
      cols={[
        { key: 'switch', width: 70 },
        { key: 'name', label: 'Name', isSortable: true },
        {
          key: 'status',
          label: 'Status',
          isSortable: true,
          alignment: 'center',
          width: 142,
        },
        {
          key: 'orientation',
          label: 'Orientation',
          isSortable: true,
          alignment: 'center',
          width: 115,
        },
        { key: 'areaType', label: 'Area Type', width: 100 },
        {
          key: 'createdAt',
          label: 'Created At',
          isSortable: true,
          alignment: 'right',
          width: 150,
        },
      ]}
      {...props}
    />
  )
}

export default PlayersListTable
