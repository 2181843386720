import React, { FC, useMemo, useState } from 'react'
import 'twin.macro'

import Icon from '../../../../ui-blocks/icon'
import Button from '../../../../ui-blocks/button'
import Search from '../../../../ui-blocks/search'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import PlayersListTable from '../../../../components/player/table'
import ManagePlayersModal from '../../../../components/manage-players/by-network'

import {
  PlayerFullFragment,
  NetworkFullFragment,
} from '../../../../graphql/components'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'
import { regexpFromString } from '../../../../utils/regexp-from-string'

export interface PlayersSectionProps {
  loading?: boolean
  network?: NetworkFullFragment
  refetchNetwork?: () => Promise<any>
}

const PlayersSection: FC<PlayersSectionProps> = ({
  loading,
  network,
  refetchNetwork,
}) => {
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const [isManagePlayersModalOpen, openManagePlayersModal] = useState<boolean>(
    false
  )

  const allPlayers = useMemo(
    () => (network?.players || []).map((player) => player?.player),
    [network]
  )
  const filteredPlayers = useMemo(
    () =>
      allPlayers.filter((player) =>
        searchTextDebounced
          ? regexpFromString(searchTextDebounced, 'i').test(player?.name || '')
          : player
      ),
    [allPlayers, searchTextDebounced]
  )

  const pagination = usePagination(
    filteredPlayers.length,
    15,
    searchTextDebounced
  )
  return (
    <section>
      <div tw="flex flex-row items-center justify-end mb-4">
        <div tw="w-80">
          <Search
            value={searchText}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
        <Button
          primary
          iconLeft={<Icon icon="add" tw="text-lg" />}
          onClick={() => openManagePlayersModal(true)}
          tw="ml-10"
        >
          Manage Players
        </Button>
      </div>
      <PlayersListTable
        loading={loading}
        searchQuery={searchTextDebounced}
        data={filteredPlayers as PlayerFullFragment[]}
      />
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
        tw="mt-4"
      />
      <ManagePlayersModal
        open={isManagePlayersModalOpen}
        networkId={network?._id}
        refetchData={refetchNetwork}
        onClose={() => openManagePlayersModal(false)}
      />
    </section>
  )
}

export default PlayersSection
