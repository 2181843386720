import React, { FC, Fragment, useState } from 'react'

import tw from 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'

import Icon from '../../../../../ui-blocks/icon'

import RemoveWebhookModal from './remove-webhook-modal'

import { Webhook } from '../../../../../graphql/components'

export interface WebhookRowProps {
  isFirst?: boolean
  webhook: Webhook
  refetchData?: () => Promise<any>
}

const WebhookRow: FC<WebhookRowProps> = ({ isFirst, webhook, refetchData }) => {
  const [isRemoveModalOpen, openRemoveModal] = useState<boolean>(false)

  const navigate = useNavigate()
  const { workspace_id } = useParams()

  const onEditWebhook = () => {
    navigate(
      `/workspaces/${workspace_id}/settings/webhooks/${webhook._id}/edit`
    )
  }

  return (
    <Fragment>
      <div
        css={[
          tw`flex flex-row items-center justify-between font-normal leading-tight border-b border-platinum py-4`,
          isFirst && tw`border-t`,
        ]}
      >
        <span tw="text-charcoal">{webhook?.name}</span>
        <span tw="text-charcoal">{webhook?.url}</span>
        <div tw="flex flex-row items-center space-x-8 text-dark-blue-gray">
          <div
            className="group edit-webhook-btn"
            tw="flex flex-row items-center cursor-pointer space-x-1"
            onClick={onEditWebhook}
          >
            <Icon
              icon="edit"
              tw="text-base transition duration-200 group-hover:text-dark-orchid"
            />
            <span tw="transition duration-200 group-hover:text-dark-orchid">
              Edit
            </span>
          </div>
          <div
            className="group remove-webhook-btn"
            tw="flex flex-row items-center cursor-pointer space-x-1"
            onClick={() => openRemoveModal(true)}
          >
            <Icon
              icon="close"
              tw="text-base transition duration-200 group-hover:text-dark-orchid"
            />
            <span tw="transition duration-200 group-hover:text-dark-orchid">
              Remove
            </span>
          </div>
        </div>
      </div>
      <RemoveWebhookModal
        webhook={webhook}
        open={isRemoveModalOpen}
        refetchData={refetchData}
        onClose={() => openRemoveModal(false)}
      />
    </Fragment>
  )
}

export default WebhookRow
