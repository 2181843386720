import React, { FC, DetailedHTMLProps, HTMLAttributes } from 'react'
import tw from 'twin.macro'

export interface CardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hasShadow?: boolean
  onClick?: () => void
}

const Card: FC<CardProps> = ({ hasShadow, children, ...props }) => (
  <div
    css={[
      tw`bg-white border rounded border-platinum`,
      hasShadow && 'box-shadow: 0px 4px 10px rgba(109, 15, 177, 0.03);',
    ]}
    {...props}
  >
    {children}
  </div>
)

Card.defaultProps = {
  hasShadow: true,
}

export default Card
