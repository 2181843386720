import React, { FC } from 'react'
import 'twin.macro'

import CardResource, { CardResourceProps } from './card-resource'

import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../utils/use-controlled-or-internal-value'

interface IProps<ValueType>
  extends IUseControlledOrInternalValueProps<ValueType> {
  cards?: CardResourceProps[]
}

const CardResourceRadio: FC<IProps<string>> = ({ cards, ...props }) => {
  const { value, onChange } = useControlledOrInternalValue(props)

  return (
    <div tw="flex flex-row flex-wrap items-stretch gap-8">
      {!!cards &&
        cards.map((card, index) => (
          <CardResource
            {...card}
            value={card.value}
            checked={value === card.value}
            onChange={() => onChange(card.value || '')}
            key={`card-resource#${index}`}
          />
        ))}
    </div>
  )
}

export default CardResourceRadio
