import React, { FC, useCallback, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import Switch from '../../ui-blocks/switch'
import Status from '../../ui-blocks/status'
import Illustration, { IllustrationEnum } from '../../ui-blocks/illustration'

import InlineDate from '../inline-date'
import Table, { TableDataProps } from '../table'

import {
  EnumRemovedReason,
  EnumCampaignStatus,
  CampaignListFragment,
  useUpdateCampaignActivationMutation,
} from '../../graphql/components'

import {
  sortObjectByKey,
  groupDataByYear,
  getCampaignTimeframe,
} from '../../utils/data-manipulation'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

const CampaignsListTable: FC<TableDataProps<CampaignListFragment>> = ({
  data,
  ...props
}) => {
  const [activateCampaign] = useUpdateCampaignActivationMutation()

  const handleError = useCallback(
    (activate: boolean) => {
      alertsManager.emit({
        dismissable: true,
        variant: AlertVariant.ERROR,
        id: 'activate-campaign-error-alert',
        message: `We couldn't ${
          activate ? 'activate' : 'deactivate'
        } this campaign. Please try again later.`,
      })
    },
    [alertsManager]
  )

  const onActivateCampaign = useCallback(
    async (campaignId: string, activate: boolean) => {
      try {
        const res = await activateCampaign({
          variables: { ids: [campaignId], is_active: activate },
        })
        if (!res.data?.areUpdated) return handleError(activate)
        document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      } catch {
        handleError(activate)
      }
    },
    [activateCampaign, handleError]
  )

  const sortedData = useMemo(() => _.sortBy(data, ['created.at']).reverse(), [
    data,
  ])
  const tableData = useMemo(
    () =>
      sortedData.map((campaign) => {
        const [startDate, endDate] = getCampaignTimeframe(
          campaign.ad_groups as any[]
        )

        const targetsCount =
          campaign.broadcast_targets?.filter(
            (target) => target?.removed?.reason === EnumRemovedReason.NotRemoved
          ).length ?? 0

        return {
          switch: {
            content: (
              <Switch
                checked={campaign?.status === EnumCampaignStatus.Activated}
                onSelect={(checked) =>
                  onActivateCampaign(campaign._id, checked)
                }
                disabled={
                  campaign?.status === EnumCampaignStatus.Waiting ||
                  campaign?.status === EnumCampaignStatus.Pending ||
                  campaign?.status === EnumCampaignStatus.Finished
                }
              />
            ),
          },
          name: {
            value: campaign.name,
            content: (
              <Link to={`/campaigns/${campaign._id}`} tw="text-purple">
                {campaign.name}
              </Link>
            ),
          },
          status: {
            value: campaign?.status,
            content: <Status value={campaign?.status as EnumCampaignStatus} />,
          },
          adGroups: {
            value: campaign.ad_groups?.length ?? 0,
            content: campaign.ad_groups?.length ?? 0,
          },
          broadcastTargets: {
            value: targetsCount,
            content: targetsCount,
          },
          campaignStart: {
            value: startDate,
            content: <InlineDate date={startDate} format="ll" />,
          },
          campaignEnd: {
            value: endDate,
            content: <InlineDate date={endDate} format="ll" />,
          },
          createdAt: {
            value: campaign.created?.at,
            content: <InlineDate date={campaign.created?.at} />,
          },
        }
      }),
    [sortedData, onActivateCampaign]
  )
  const chronologicalData = useMemo(
    () => sortObjectByKey(groupDataByYear(tableData, 'createdAt.value')),
    [tableData]
  )

  return (
    <Table
      itemName="Campaign"
      data={chronologicalData}
      emptyStateIllustration={
        <Illustration name={IllustrationEnum.campaigns_empty_results} />
      }
      layout="fluid"
      cols={[
        { key: 'switch', width: 70 },
        { key: 'name', label: 'Name', isSortable: true },
        {
          key: 'status',
          label: 'Status',
          isSortable: true,
          alignment: 'center',
          width: 142,
        },
        {
          key: 'adGroups',
          label: 'Ad Groups',
          isSortable: true,
          alignment: 'right',
          width: 70,
        },
        {
          key: 'broadcastTargets',
          label: 'Broadcast Targets',
          isSortable: true,
          alignment: 'right',
          width: 70,
        },
        {
          key: 'campaignStart',
          label: 'Start Date',
          isSortable: true,
          alignment: 'right',
          width: 142,
        },
        {
          key: 'campaignEnd',
          label: 'End Date',
          isSortable: true,
          alignment: 'right',
          width: 142,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          isSortable: true,
          alignment: 'right',
          width: 150,
        },
      ]}
      {...props}
    />
  )
}

export default CampaignsListTable
