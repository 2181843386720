import React, { FC } from 'react'
import tw from 'twin.macro'

export interface DividerProps {
  text?: string
}

const Divider: FC<DividerProps> = ({ text, ...props }) => (
  <div tw="w-full relative py-8" {...props}>
    <hr tw="w-full bg-dark-blue-gray" />
    {!!text && (
      <div
        css={[
          'left: 50%; bottom: 50%;',
          'transform: translate(-50%, 50%);',
          tw`w-32 h-5 bg-cultured rounded flex items-center justify-center absolute`,
        ]}
      >
        <span tw="font-medium text-xs text-dark-blue-gray leading-none select-none">
          {text}
        </span>
      </div>
    )}
  </div>
)

export default Divider
