import React, { FC, useMemo, useState } from 'react'
import tw from 'twin.macro'

import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Chart, LineAdvance } from 'bizcharts'

import Card from '../../../../ui-blocks/card'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import ViewControls from '../../../../ui-blocks/view-controls'

import {
  StatsIntervalInput,
  useGetWorkspaceStatsQuery,
} from '../../../../graphql/components'

const OPTION_TO_DAYS: {
  [optionId: string]: { days: number; label: string }
} = {
  'last-7-days': { days: 7, label: 'Last 7 days' },
  'last-30-days': { days: 30, label: 'Last 30 days' },
}

export type StatsMetric = 'reach' | 'engagement'

export interface SummaryChartProps {
  title: string
  metric?: StatsMetric
}

const SummaryChart: FC<SummaryChartProps> = ({ title, metric = 'reach' }) => {
  const { workspace_id } = useParams()
  const [days, setDays] = useState<number>(7)

  const today = moment()
  const intervals = useMemo(() => {
    const pastDays: StatsIntervalInput[] = []
    for (let i = 0; i < days; i++) {
      pastDays.push({
        starts_at: today.clone().subtract(i, 'days').startOf('day'),
        ends_at: today.clone().subtract(i, 'days').endOf('day'),
      })
    }
    return pastDays.reverse()
  }, [days, today])

  const { data, loading } = useGetWorkspaceStatsQuery({
    fetchPolicy: 'network-only',
    skip: !workspace_id || intervals.length === 0,
    variables: { _id: workspace_id, intervals },
  })

  const stats = useMemo(() => data?.workspace?.stats || [], [data])
  const chartData = useMemo(
    () =>
      stats.map((stat, index) => ({
        value: stat[metric],
        day: moment(intervals[index].starts_at).format('MMM Do'),
      })),
    [stats, metric]
  )

  return (
    <Card tw="py-8 px-6">
      <h3 tw="uppercase text-dark-blue-gray font-normal leading-tight mb-4">
        {title}
      </h3>
      <div tw="flex items-center justify-between mb-10">
        <span tw="text-dark-blue-gray font-normal text-sm leading-tight">
          {`Time played during ${moment()
            .subtract(days - 1, 'days')
            .format('MMM D, YYYY')} to ${moment().format('MMM D, YYYY')}`}
        </span>
        <ViewControls
          value="last-7-days"
          onSelect={(optionId) => setDays(OPTION_TO_DAYS[optionId].days)}
          options={Object.keys(OPTION_TO_DAYS).map((optionKey) => ({
            id: optionKey,
            label: OPTION_TO_DAYS[optionKey].label,
          }))}
        />
      </div>
      <Suspense
        ready={!loading}
        fallback={
          <div
            css={['min-height: 300px;', tw`flex items-center justify-center`]}
          >
            <Spinner />
          </div>
        }
      >
        <Chart autoFit height={300} data={chartData}>
          <LineAdvance
            area
            type="smooth"
            color="#6D0FB1"
            position={`day*value`}
          />
        </Chart>
      </Suspense>
    </Card>
  )
}

export default SummaryChart
