import React, { FC } from 'react'
import 'twin.macro'

const NotDefined: FC = ({ ...props }) => (
  <span tw="not-italic text-dark-red whitespace-nowrap" {...props}>
    not defined
  </span>
)

export default NotDefined
