import React, { FC } from 'react'
import { css } from 'twin.macro'

export interface SideScrollProps {
  hideScrollbar?: boolean
}

const SideScroll: FC<SideScrollProps> = ({
  hideScrollbar,
  children,
  ...props
}) => (
  <div
    css={[
      css`
        overflow-x: scroll;
        white-space: nowrap;
      `,
      hideScrollbar &&
        css`
          /* Hide scrollbar for IE, Edge and Firefox */
          scrollbar-width: none;
          -ms-overflow-style: none;
          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
        `,
    ]}
    {...props}
  >
    {children}
  </div>
)

SideScroll.defaultProps = {
  hideScrollbar: true,
}

export default SideScroll
