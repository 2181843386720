import React, { FC } from 'react'
import 'twin.macro'

import Icon from '../../../ui-blocks/icon'

export interface RestoreActionProps {
  onClick?: () => void
}

const RestoreAction: FC<RestoreActionProps> = ({ onClick }) => (
  <div
    tw="flex flex-row items-center text-dark-blue-gray transition duration-200 hover:(text-purple cursor-pointer)"
    onClick={onClick}
  >
    <Icon icon="refresh" tw="text-base mr-1" />
    <span>Restore</span>
  </div>
)

export default RestoreAction
