import React, { FC, Fragment, useMemo } from 'react'

import 'twin.macro'
import { useParams } from 'react-router'

import Text from '../../../../../../../../ui-blocks/text'

import ItemsCard, {
  AffectedNetwork,
} from '../../../../../../../../components/items-card'

import { useAffectedResourcesByArchiveQuery } from '../../../../../../../../graphql/components'

const InfoStep: FC = () => {
  const { player_id } = useParams()
  const {
    data: resourcesData,
    loading: loadingResources,
  } = useAffectedResourcesByArchiveQuery({
    skip: !player_id,
    fetchPolicy: 'cache-first',
    variables: { _id: player_id },
  })

  const networks = useMemo(() => resourcesData?.resources?.networks || [], [
    resourcesData,
  ])

  return (
    <Fragment>
      <Text as="h3" preset="h4" tw="mb-8">
        By archiving this player, it will no longer be associated to:
      </Text>
      <div tw="flex flex-row items-center space-x-4">
        <ItemsCard
          title="Networks"
          loading={loadingResources}
          items={networks as AffectedNetwork[]}
        />
      </div>
    </Fragment>
  )
}

export default InfoStep
