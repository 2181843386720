import React, { FC, useState } from 'react'

import 'twin.macro'
import delay from 'delay'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import { useLocation, useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Link from '../../../ui-blocks/link'
import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'

import PasswordRestrictions from '../../../components/password-restrictions'
import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import { authentication } from '../../../stores'

interface SignUpFormikValues {
  firstname: string
  lastname: string
  email: string
  password: string
}

export const passwordSchema = string()
  .trim('Value cannot have leading or trailing white spaces')
  .min(6, 'Your password must have at least 6 characters')
  .matches(/[A-Z]/, 'Your password must have at least one upper case character')
  .matches(/[a-z]/, 'Your password must have at least one lower case character')
  .matches(/[0-9]/, 'Your password must have at least one number')
  .strict(true)

const signUpValidationSchema = object().shape({
  firstname: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('First name is required')
    .strict(true),
  lastname: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Last name is required')
    .strict(true),
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
  password: passwordSchema.clone().required('Password is required'),
})

const AuthSignInPage: FC = () => {
  const navigate = useNavigate()
  const [signUpError, setSignUpError] = useState<boolean>(false)

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const formik = useFormik<SignUpFormikValues>({
    validateOnChange: false,
    validationSchema: signUpValidationSchema,
    initialValues: {
      firstname: '',
      lastname: '',
      password: '',
      email: queryParams.get('email') || '',
    },
    async onSubmit({ firstname, lastname, email, password }) {
      setSignUpError(false)
      try {
        const token = await executeRecaptcha?.('auth/sign_in')
        await authentication.signup(firstname, lastname, email, password, token)

        await delay(1)
        navigate('/auth/sign-up/verify-email/0', { replace: true })
      } catch (err) {
        setSignUpError(true)
      }
    },
  })

  return (
    <form
      tw="relative"
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
    >
      <h1 tw="text-charcoal m-0 mb-10 font-light leading-tight text-4xl self-start">
        Sign up
      </h1>
      {signUpError && (
        <div tw="mb-8">
          <AlertMessage
            alert={{
              id: 'sign-up-error-alert',
              variant: AlertVariant.ERROR,
              actionText:
                'If you think that might be the case, click here to recover access to it.',
              message:
                'It seems something went wrong with the registration process. This could happen in a couple of scenarios, your information might be incorrect or the system already contains an account with the same email.',
              onAction: () => navigate('/auth/forgot-password'),
            }}
          />
        </div>
      )}
      <div tw="space-y-8 mb-4">
        <InputFormik
          type="text"
          name="firstname"
          label="First Name"
          placeholder="John"
          formik={formik}
        />
        <InputFormik
          type="text"
          name="lastname"
          label="Last Name"
          placeholder="Doe"
          formik={formik}
        />
        <InputFormik
          type="email"
          name="email"
          label="Email Address"
          placeholder="your-email@domain.eu"
          formik={formik}
        />
        <InputFormik
          type="password"
          name="password"
          label="Password"
          placeholder="*************"
          formik={formik}
        />
      </div>
      <PasswordRestrictions tw="mb-12" />
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
        tw="block"
      >
        Create account
      </Button>
      <p tw="inline-block align-baseline text-sm mt-8 text-charcoal">
        Have an account?{' '}
        <Link
          router={{ to: '/auth/sign-in' }}
          tw="p-0 inline-flex min-w-auto min-h-auto"
        >
          Sign in
        </Link>
      </p>
    </form>
  )
}

export default AuthSignInPage
