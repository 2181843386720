import { action, computed, observable } from 'mobx'
import uuid from 'uuid'
import axios from 'axios'
import Queue from 'p-queue'

import {
  EnumCreativeType,
  PreSignCreativeUploadDocument,
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables,
  NotifyCreativeUploadCompletedDocument,
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables,
  ShredCreativeMutation,
  ShredCreativeDocument,
  ShredCreativeMutationVariables,
} from '../graphql/components'
import apolloClient from '../graphql/client'

export enum UploadState {
  WAITING = 'WAITING',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  DISMISSED = 'DISMISSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  FINALIZING = 'FINALIZING',
}

export interface Upload {
  id: string
  file: File
  type: EnumCreativeType
  state: UploadState
  abort: AbortController
  progress?: number
  data?: any
  presignResData?: any
}

export default class UploadsManager {
  private queue = new Queue({
    concurrency: 2,
    autoStart: true,
    timeout: Infinity,
  })

  @observable public isExpanded = true
  @observable public uploads = new Map<string, Upload>()

  // computed
  @computed public get uploadsRemoteIds() {
    let ids = ''

    this.uploads.forEach((upload) => {
      ids += upload?.presignResData?.upload_id || ''
    })

    return ids
  }

  @computed public get uploadsByState() {
    const uploadsByState: { [index in UploadState]: Upload[] } = {
      WAITING: [],
      UPLOADING: [],
      COMPLETED: [],
      DISMISSED: [],
      FAILED: [],
      CANCELLED: [],
      FINALIZING: [],
    }

    this.uploads.forEach((upload) => {
      uploadsByState[upload.state].push(upload)
    })

    return uploadsByState
  }

  @computed public get uploadsBar(): Upload[] {
    const ups = this.uploadsByState
    return [
      ...ups[UploadState.FAILED],
      ...ups[UploadState.UPLOADING],
      ...ups[UploadState.FINALIZING],
      ...ups[UploadState.WAITING],
      ...ups[UploadState.COMPLETED],
      ...ups[UploadState.CANCELLED],
    ]
  }

  // methods
  @action public triggerExpansion = () => {
    this.isExpanded = !this.isExpanded
  }

  @action public validateAndEnqueueUpload = (upl?: Partial<Upload>) => {
    if (!upl) {
      throw new Error(`Upload argument not provided`)
    }

    if (!upl.file) {
      throw new Error(`Can't find a file to upload`)
    }

    if (!upl.type || !Object.values(EnumCreativeType).includes(upl.type)) {
      throw new Error(`Invalid upload type`)
    }

    const upload: Upload = {
      id: upl.id || uuid.v4(),
      file: upl.file,
      type: upl.type,
      state: UploadState.WAITING,
      abort: new AbortController(),
    }

    if (!this.uploads.has(upload.id)) {
      this.uploads.set(upload.id, upload)
    }

    this.pushToQueue(upload)
  }

  @action private pushToQueue = (upload: Upload) => {
    this.abortUpload(upload)

    this.queue.add(async () => {
      await this.upload(upload)
    })
  }

  @action private abortUpload = (upload: Upload) => {
    if (!upload.abort.signal.aborted) {
      upload.abort.abort()
      upload.abort = new AbortController()
      this.uploads.set(upload.id, upload)
    }
  }

  @action private async upload(upload: Upload): Promise<Upload> {
    try {
      // gwt the url
      const presignRes = await apolloClient.mutate<
        PreSignCreativeUploadMutation,
        PreSignCreativeUploadMutationVariables
      >({
        mutation: PreSignCreativeUploadDocument,
        variables: {
          filename: upload.file.name,
          mimetype: upload.file.type,
          creative_type: upload.type,
        },
      })

      const { presigned_url, creative_id } = presignRes.data?.upload || {}
      if (!presigned_url) {
        throw new Error(`Unable to start the upload`)
      }

      const source = axios.CancelToken.source()
      upload.abort.signal.addEventListener('abort', () => {
        source.cancel()
        upload.state = UploadState.CANCELLED
      })

      // we're going to initiate the upload
      upload.state = UploadState.UPLOADING
      upload.progress = 0
      upload.presignResData = presignRes.data?.upload
      this.uploads.set(upload.id, upload)

      // execute the axios request
      const res = await axios.put(presigned_url, upload.file, {
        headers: {
          'Content-Type': upload.file.type,
        },
        cancelToken: source.token,
        onUploadProgress: (e: any) => {
          const progress = (e?.loaded || 0) / (e?.total || 0)
          if (progress !== upload.progress) {
            upload.progress = progress
            this.uploads.set(upload.id, upload)
          }
        },
      })

      // check if an error came in the graphql response
      if (res?.data?.errors?.length) {
        upload.state = UploadState.FAILED
        console.error('upload error', res.data.errors)
      } else {
        // if it gets here, upload was successful
        upload.state = UploadState.FINALIZING
        upload.data = res.data

        const mutation = await apolloClient.mutate<
          NotifyCreativeUploadCompletedMutation,
          NotifyCreativeUploadCompletedMutationVariables
        >({
          mutation: NotifyCreativeUploadCompletedDocument,
          variables: { creative_id },
        })

        if (mutation.errors?.length) {
          upload.state = UploadState.FAILED
        } else {
          upload.state = UploadState.COMPLETED
        }
      }

      return upload
    } catch (err) {
      // cancelled
      if (upload.abort.signal.aborted) {
        return upload
      }

      // failed
      // check if we're past the pre-signing stage
      if (upload.state === UploadState.UPLOADING) {
        await apolloClient.mutate<
          ShredCreativeMutation,
          ShredCreativeMutationVariables
        >({
          mutation: ShredCreativeDocument,
          variables: {
            id: upload.presignResData.creative_id,
            shred_token: upload.presignResData.shred_token ?? '',
          },
        })
        // Failed to pre-sign
      }

      // otherwise, it has failed
      upload.state = UploadState.FAILED

      throw err
    } finally {
      this.uploads.set(upload.id, upload)
    }
  }

  @action public async retry(id: string): Promise<void> {
    const upload = this.get(id)
    return this.pushToQueue(upload)
  }

  public get(id: string): Upload {
    const upload = this.uploads.get(id)

    if (!upload) {
      throw new Error(`Upload ${id} not found`)
    }

    return upload
  }

  public async cancel(id: string) {
    const upload = this.get(id)

    this.abortUpload(upload)

    if (upload.state === UploadState.CANCELLED) {
      this.dismiss(upload.id)
      return true
    }

    if (upload.presignResData) {
      await apolloClient.mutate<
        ShredCreativeMutation,
        ShredCreativeMutationVariables
      >({
        mutation: ShredCreativeDocument,
        variables: {
          id: upload.presignResData.creative_id,
          shred_token: upload.presignResData.shred_token ?? '',
        },
      })

      delete upload.presignResData
    }

    upload.state = UploadState.CANCELLED

    return true
  }

  public async clear() {
    const uploads = this.uploadsByState

    const uploadsToClear = new Array<Upload>()
      .concat(uploads[UploadState.WAITING])
      .concat(uploads[UploadState.UPLOADING])
      .concat(uploads[UploadState.FINALIZING])
      .map((upload) => this.cancel(upload.id))

    // Cancels all pending uploads asynchronously
    await Promise.all(uploadsToClear)

    // Clears the actual upload manager
    this.uploads.clear()

    // Hide it if shown
    if (this.isExpanded) {
      this.triggerExpansion()
    }
  }

  public dismiss(id: string) {
    this.setState(id, UploadState.DISMISSED)
  }

  public setState(id: string, state: UploadState) {
    this.uploads.set(id, {
      ...this.get(id),
      state,
    })
  }
}

export const uploadsManager = new UploadsManager()

// mock data

// uploadsManager.uploads.set('waiting-1', {
//   id: `waiting-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.WAITING,
// })

// uploadsManager.uploads.set('uploading-1', {
//   id: `uploading-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.UPLOADING,
//   progress: 0.63,
// })

// uploadsManager.uploads.set('uploading-2', {
//   id: `uploading-2`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.UPLOADING,
//   progress: 0.73,
// })

// uploadsManager.uploads.set('failed-1', {
//   id: `failed-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.FAILED,
//   progress: 0.27,
// })

// uploadsManager.uploads.set('cancelled-1', {
//   id: `cancelled-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.CANCELLED,
//   progress: 0.63,
// })
