import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Text from '../../../../ui-blocks/text'

import useElementStates from '../../../../utils/use-element-states'

const headings = [
  { as: 'h1', preset: 'h1', text: 'Heading 1' },
  { as: 'h2', preset: 'h2', text: 'Heading 2' },
  { as: 'h3', preset: 'h3', text: 'Heading 3' },
  { as: 'h4', preset: 'h4', text: 'Heading 4' },
  { as: 'h5', preset: 'h5', text: 'Heading 5' },
  { as: 'h6', preset: 'p1', text: 'Heading 6' },
  { as: 'p', preset: 'p1', text: 'Paragraph 1' },
  { as: 'p', preset: 'p2', text: 'Paragraph 2' },
  { as: 'label', preset: 'caption', text: 'Caption' },
  { as: 'span', preset: 'tag', text: 'Tag' },
]

const TextSection: FC<ElementSectionProps> = ({ id, title, description }) => {
  const { states, values } = useElementStates([
    'left',
    'center',
    'right',
    'justify',
    'light',
    'normal',
    'medium',
    'bold',
    'lowercase',
    'uppercase',
    'capitalize',
    'underline',
    'line-through',
  ])

  const align =
    (values.left && 'left') ||
    (values.center && 'center') ||
    (values.right && 'right') ||
    (values.justify && 'justify') ||
    undefined
  const weight =
    (values.light && 'light') ||
    (values.normal && 'normal') ||
    (values.medium && 'medium') ||
    (values.bold && 'bold') ||
    undefined
  const transform =
    (values.lowercase && 'lowercase') ||
    (values.uppercase && 'uppercase') ||
    (values.capitalize && 'capitalize') ||
    undefined
  const decoration =
    (values.underline && 'underline') ||
    (values['line-through'] && 'line-through') ||
    undefined

  return (
    <ElementSection
      id={id}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      {headings.map((heading, index) => (
        <Text
          key={`heading#${index}`}
          as={heading.as as any}
          preset={heading.preset as any}
          align={align}
          weight={weight}
          transform={transform}
          decoration={decoration}
          tw="mb-4"
        >
          {heading.text}
        </Text>
      ))}
    </ElementSection>
  )
}

export default TextSection
